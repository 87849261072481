import React from 'react';
import { CloseableDialog } from '@clippings/paper';
import { DialogTitle } from 'libs/Components/Modal/DialogTitle/DialogTitle';
import { GeneralInfoDialogForm, GeneralInfoDialogFormProps } from './GeneralInfoDialogForm';
import { useTranslation } from 'react-i18next';

export interface GeneralInfoDialogProps
  extends Omit<GeneralInfoDialogFormProps, 'onCancel' | 'onSuccess'> {
  open: boolean;
  onClose: () => void;
}

export const GeneralInfoDialog: React.FC<GeneralInfoDialogProps> = ({ company, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <CloseableDialog
      open={open}
      onClose={onClose}
      title={
        <DialogTitle
          data-testid="general-info-dialog-title"
          title={t('common.editCompanyDetails')}
        />
      }
      dialogProps={{ PaperProps: { sx: { width: 560 } } }}
    >
      <GeneralInfoDialogForm company={company} onSuccess={onClose} onCancel={onClose} />
    </CloseableDialog>
  );
};
