import i18n, { ReadCallback } from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { merge } from 'lodash';

const loadTranslations =
  (instance: string) => (language: string, _namespace: string, callback: ReadCallback) => {
    Promise.all([
      import(`@clippings/i18n/strings/common.${language}.json`),
      import(`@clippings/i18n/strings/app.${language}.json`),
      import(`@clippings/i18n/strings/${instance}.${language}.json`),
    ])
      .then(([commonStrings, appStrings, instanceString]) => {
        callback(null, merge(commonStrings.default, appStrings.default, instanceString.default));
      })
      .catch(error => {
        callback(error, null);
      });
  };

export function setupI18n(instance: string, userLocale: string) {
  const options = {
    lng: userLocale,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    partialBundledLanguages: true,
    resources: {},
    returnNull: false,
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(intervalPlural)
    .use(resourcesToBackend(loadTranslations(instance)))
    .init(options);
}
