import { QuoteListCardItemViewModel } from './QuoteListItemCard';
import { QuoteWithNotification } from 'libs/api/quotes/types';
import { useMemo } from 'react';

export function useQuoteListItemCardRenderer(items: QuoteWithNotification[]) {
  const viewDataModel = useMemo(
    () => items.map((item, idx) => viewModelMapper(item, idx)),
    [items]
  );

  return { viewDataModel };
}

function viewModelMapper(item: QuoteWithNotification, idx: number): QuoteListCardItemViewModel {
  return {
    id: item.id,
    position: idx,
    number: item.number,
    label: item.name ?? '',
    value: item.total,
    activity: item.latestActivityAt,
    status: item.status,
    salesRep: item.salesRepresentative ?? null,
    newMessages: !!item.newMessages,
  };
}
