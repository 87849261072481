import { ReactNode } from 'react';
import { StatusCodes } from 'http-status-codes';
import { getFormErrorMessage } from '@clippings/paper';
import { getServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';

export function useQuoteError(showErrorBanner: (message: ReactNode) => void) {
  const showError = (err: any) => {
    if (
      err?.response?.status === StatusCodes.UNPROCESSABLE_ENTITY ||
      err?.response?.status === StatusCodes.CONFLICT
    ) {
      const formErrors = getServerFormErrors(err);
      const errorMessage = Object.keys(formErrors)
        .map(key => getFormErrorMessage(formErrors, key))
        .join('. ');

      showErrorBanner(errorMessage);
    }
  };

  return { showError };
}
