import React from 'react';
import { Box, DateTime, DateTimeFormat, Typography } from '@clippings/paper';
import { User } from 'libs/api/iam/types';
import { subMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

export interface UserOnlineStatusProps {
  user: User;
}

// if the user has been active in the last 10 minutes, he/she is considered active
// see https://designtechnologies.atlassian.net/browse/BD-669
const OFFLINE_MINUTES = 10;

export const UserOnlineStatus: React.FC<UserOnlineStatusProps> = ({ user }) => {
  const { t } = useTranslation();

  // This is a timestamp boundary. If user activity is earlier than
  // this, he/she would be considered as offline.
  const offlineBoundary = subMinutes(new Date(), OFFLINE_MINUTES).getTime();

  const lastActiveAt = user.lastActiveAt ? new Date(user.lastActiveAt) : new Date(0);
  const isOnline = lastActiveAt.getTime() > offlineBoundary;

  if (isOnline) {
    return (
      <Typography
        data-testid={`user-online-status-${user.id}`}
        variant="body2"
        component="span"
        display="flex"
        alignItems="center"
      >
        <Box display="inline" color="green" mr={1}>
          ●
        </Box>
        {t('common.active')}
      </Typography>
    );
  }

  if (!user.lastActiveAt) {
    return (
      <Typography data-testid={`user-online-inactive`} variant="body2">
        ---
      </Typography>
    );
  }

  return (
    <>
      <Typography data-testid={`user-online-status-lastLogin-${user.id}`} variant="body2">
        {t('common.lastLogin')}
      </Typography>
      <DateTime
        data-testid={`user-online-lastActiveAt-${user.id}`}
        format={DateTimeFormat.Date}
        date={user.lastActiveAt}
        variant="caption"
        color="secondary"
      />
    </>
  );
};
