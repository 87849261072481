import React, { useEffect, useRef } from 'react';
import { Box, MenuItem, Typography } from '@clippings/paper';
import { useInfiniteHits } from 'react-instantsearch-hooks-web';

export interface BaseAlgoliaValue<Value = unknown> {
  name: string;
  value: Value;
  [key: string]: unknown;
}

export interface AlgoliaCellPopoverContentProps<
  Type = unknown,
  SelectValue extends BaseAlgoliaValue = BaseAlgoliaValue<Type>
> {
  refine: (value: string) => void;
  onClose: () => void;
  onSelect: (value: SelectValue) => void;
}

export function AlgoliaCellPopoverContent<
  Type = unknown,
  Value extends BaseAlgoliaValue = BaseAlgoliaValue<Type>
>(props: AlgoliaCellPopoverContentProps<Type, Value>) {
  const { hits, isLastPage, showMore } = useInfiniteHits<BaseAlgoliaValue<Value>>();
  const { onClose, onSelect, refine } = props;
  const observerElementRef = useRef<HTMLLIElement>();

  useEffect(() => {
    // Add observer to the hits.length - 5 element to load more hits once it is visible
    if (observerElementRef.current) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(observerElementRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <Box sx={{ overflow: 'auto', maxHeight: 200 }}>
      {hits.map((hit, i) => (
        <MenuItem
          ref={ref => {
            if (ref && !isLastPage && hits.length - 5 === i) {
              observerElementRef.current = ref;
            }
          }}
          key={hit.objectID}
          sx={styles.menuItem}
          onClick={() => {
            const value = { name: hit.name, value: hit.value };
            onClose();
            refine('');
            onSelect(value as Value);
          }}
          data-testid={`algolia-item-${hit.objectID}`}
        >
          <Typography variant="subtitle2">{hit.name}</Typography>
        </MenuItem>
      ))}
    </Box>
  );
}

const styles = {
  menuItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};
