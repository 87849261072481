import React from 'react';
import { Box, FlexRow, SampleIcon } from '@clippings/paper';
import { Text } from './Text';

type InfoCellSampleItemProps = {
  sampleName: string;
};

export function InfoCellSampleItem({ sampleName }: InfoCellSampleItemProps) {
  return (
    <FlexRow gap={1} data-testid="sample-item">
      <Box mt="13px">
        <SampleIcon />
      </Box>
      <Text value={sampleName} />
    </FlexRow>
  );
}
