import React, { FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { ChatContextValue } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import { isEmpty } from 'lodash';

const { getStreamUserChatToken, userId, fullName, avatar } = window.visitor;

export const userToConnect = {
  id: String(userId),
  name: fullName,
  image: avatar,
};

type Client = ChatContextValue['client'];
export type ChatClientContextValue = {
  client: Client;
  connected: boolean;
  error?: any;
};

const ChatClientContext = React.createContext<ChatClientContextValue>({} as ChatClientContextValue);

type ChatClientProviderProps = PropsWithChildren<{ chatClient: StreamChat }>;

export const ChatClientProvider: FC<ChatClientProviderProps> = ({ chatClient, children }) => {
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    function connectionChangeHandler({ online = false }) {
      if (!online) {
        setConnected(false);
        return;
      }

      setConnected(true);
    }

    async function connect() {
      if (userToConnect && getStreamUserChatToken) {
        await chatClient.connectUser(userToConnect, getStreamUserChatToken);
      }
    }

    chatClient.on('connection.changed', connectionChangeHandler);

    connect().catch(error => {
      setError(error);
    });

    return () => {
      chatClient.off('connection.changed', connectionChangeHandler);
      chatClient.disconnectUser();
    };
  }, []);

  const value = useMemo(
    () => ({
      client: chatClient,
      connected,
      error,
    }),
    [connected, error]
  );

  return <ChatClientContext.Provider value={value}>{children}</ChatClientContext.Provider>;
};

export function useChatClient() {
  const value = useContext(ChatClientContext);

  if (isEmpty(value)) {
    throw new Error('The useChatChannel hook was called outside of the ChatChannelProvider');
  }

  return value;
}
