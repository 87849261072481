import React from 'react';
import { AddNewLineInput } from 'libs/SalesApp/Quotes/components/AddNewLineInput';
import { CellPopover, TableCell } from '@clippings/paper';
import { useDiscount } from '../../hooks/useDiscount';
import { useTranslation } from 'react-i18next';

export function AddCustomDiscount() {
  const { t } = useTranslation();
  const { inputValue, addDiscountHandler, setInputValue } = useDiscount();
  const onDiscountCreate = (name: string) => addDiscountHandler({ name });

  return (
    <CellPopover infoLabel={t('quotes.pressEnterToAddCustomItem')}>
      <TableCell>
        <AddNewLineInput
          value={inputValue}
          placeholder={t('quotes.addProductPlaceholder')}
          onCreate={onDiscountCreate}
          onChange={e => setInputValue(e.target.value)}
          inputProps={inputProps}
        />
      </TableCell>
    </CellPopover>
  );
}

const inputProps = {
  'data-testid': 'discounts-table-add-input',
};
