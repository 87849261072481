import React, { useMemo } from 'react';
import { AddressContext } from '../AddressContext';
import { AddressDialogHeader } from '../AddressDialogHeader';
import { AddressFormFields } from 'libs/shared/components/address/AddressFormFields';
import { AddressList } from '../AddressList';
import { AddressTypesEnum } from 'libs/Quotes/hooks/useAddresses';
import { Alert, Box, BusinessIcon, getFormErrorMessage } from '@clippings/paper';
import { DeleteDialog } from '../DeleteDialog';
import { FormModal, FormModalProps } from '../../../Components';
import { ModalTitle } from '../ModalTitle';
import { Nullable } from 'libs/Utils';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { SCREENS } from '../hooks/useAddressScreenReducer';
import { TYPE_HANDLERS, usePostSubmitHandler } from '../hooks/usePostSubmitHandler';
import { getEditAddressesDefaultValue } from '../utils';
import { getValidationSchemas } from '../Form/addressValidationSchema';
import { useAddress } from '../hooks/useAddress';
import { useAppConfiguration } from 'libs/providers';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

type DataModel = {
  content: JSX.Element;
  title: JSX.Element;
  formProps: FormModalProps<any>['formProps'];
  actionProps: FormModalProps<any>['actionProps'];
};

export const BillingDialog = () => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();
  const { billingAddressValidationSchema } = useMemo(() => getValidationSchemas(brand), [brand]);

  const {
    quote,
    isBillingModalOpen,
    handleBillingModalClose,
    locationQuery,
    addressSubmitLoading,
    handleQuoteAddressChange,
    billingAddress,
    paymentTermsQuery,
    updateAddressMutationLoading,
  } = useQuoteProvider();

  const {
    currentScreen,
    handleBack,
    handleOpenCreateNewAddress,
    selectedAddress,
    handleOpenEditAddress,
    isDeleteModalOpen,
    openDeleteModal,
    closeDeleteModal,
    hasBack,
    handleEditAddress,
    handleMakeDefault,
    countries,
    states,
    handleDeleteAddress,
    updateAddressFormErrors,
    createAddressFormErrors,
    handleCreateAddress,
    handleOpenList,
  } = useAddress({
    currentQuoteAddress: quote.billingAddress,
    ...billingAddress,
    quoteAddressQuery: null,
    handleQuoteAddressChange,
    locationQuery,
    paymentTermsQuery,
  });
  const { companyAddressQuery } = billingAddress;
  const { onModalClose } = usePostSubmitHandler();

  const handleSelectAddress = () => {
    if (quote.billingAddress === null) {
      const companyAddress = companyAddressQuery.data[0];
      if (!companyAddress) {
        onModalClose(TYPE_HANDLERS.BILLING);
      }
      handleQuoteAddressChange({ billingAddress: { id: companyAddress.id } }, () =>
        onModalClose(TYPE_HANDLERS.BILLING)
      );
      return;
    }
    onModalClose(TYPE_HANDLERS.BILLING);
  };

  /**
   * Provide a default `string` value for the shipping address id and not `undefined`
   * For Controlled components, RHF does not allow `undefined` as a default value.
   * Using `undefined` results in an error in the console.
   * More here -> https://react-hook-form.com/api/usecontroller/controller
   */
  const addressListFormDefaultValues = useMemo(
    () => ({
      billingAddress: { id: quote.billingAddress?.id ? String(quote.billingAddress?.id) : '' },
    }),
    [quote.billingAddress?.id]
  );

  const getDialogData = (): Nullable<DataModel> => {
    if (currentScreen === SCREENS.LIST) {
      const { status } = quote;

      const isQuoteFrozen =
        status === QuoteListItemStatus.Ready || status === QuoteListItemStatus.Approved;
      const companyAddresses = !isQuoteFrozen ? companyAddressQuery.data : [quote.billingAddress];

      return {
        content: (
          <AddressList
            type="billing"
            name="billingAddress[id]"
            isLoading={companyAddressQuery.isFetching}
            companyAddresses={companyAddresses}
            quoteAddresses={[]}
            quoteAddressTitle={t('quotes.addresses.quoteBillingAddress')}
            companyAddressTitle={t('quotes.addresses.companyBillingAddress')}
            defaultValues={addressListFormDefaultValues}
            isFrozen={isQuoteFrozen}
          />
        ),
        title: (
          <AddressDialogHeader icon={<BusinessIcon color="secondary" />}>
            <ModalTitle text={t('quotes.addresses.businessAddress')} />
          </AddressDialogHeader>
        ),
        formProps: {
          defaultValues: addressListFormDefaultValues,
          onSubmit: () => handleSelectAddress(),
        },
        actionProps: {
          submitLabel: t('common.done'),
        },
      };
    }

    if (currentScreen === SCREENS.EDIT_ADDRESS && selectedAddress) {
      const defaultValues = getEditAddressesDefaultValue(selectedAddress, brand);

      return {
        content: (
          <>
            <AddressFormFields type="billing" />
            {updateAddressFormErrors.error && (
              <Box>
                <Alert severity="error">
                  {getFormErrorMessage(updateAddressFormErrors, 'error')}
                </Alert>
              </Box>
            )}
          </>
        ),
        title: (
          <AddressDialogHeader onBack={handleBack}>
            <ModalTitle text={t('quotes.addresses.editAddress')} />
          </AddressDialogHeader>
        ),
        formProps: {
          errors: updateAddressFormErrors,
          defaultValues: {
            ...defaultValues,
            vatNumber: selectedAddress.vatNumber ?? '',
          },
          validationSchema: billingAddressValidationSchema,
          onSubmit: data => handleEditAddress(data),
        },
        actionProps: {
          submitLabel: t('common.done'),
        },
      };
    }

    if (currentScreen === SCREENS.CREATE_ADDRESS) {
      return {
        content: (
          <>
            <AddressFormFields type="billing" />
            {createAddressFormErrors.error && (
              <Box>
                <Alert severity="error">
                  {getFormErrorMessage(createAddressFormErrors, 'error')}
                </Alert>
              </Box>
            )}
          </>
        ),
        title: (
          <AddressDialogHeader onBack={handleBack} icon={<BusinessIcon color="secondary" />}>
            <ModalTitle text={t('quotes.addresses.billingDetails')} />
          </AddressDialogHeader>
        ),
        formProps: {
          errors: createAddressFormErrors,
          validationSchema: billingAddressValidationSchema,
          onSubmit: data => {
            handleCreateAddress(data, AddressTypesEnum.COMPANY, () => handleOpenList('pop'));
          },
        },
        actionProps: {
          submitLabel: t('common.done'),
        },
      };
    }

    /**
     * Throw an error if the screen state is not valid instead of returning `null`
     * to avoid rendering an unwanted state of the dialog to the user.
     * The error boundary will redirect to the generic error page.
     */
    throw new Error(`${BillingDialog.name} component - Invalid screen: ${currentScreen}`);
  };

  const data = useMemo(() => {
    return getDialogData();
  }, [currentScreen, selectedAddress, companyAddressQuery]);

  return (
    <AddressContext.Provider
      value={{
        handleBack,
        handleOpenCreateNewAddress,
        selectedAddress,
        handleOpenEditAddress,
        addressType: 'billing',
        isDeleteModalOpen,
        handleOpenDeleteModal: openDeleteModal,
        handleCloseDeleteModal: closeDeleteModal,
        onDelete: handleDeleteAddress,
        handleMakeDefault,
        hasBack,
        countries,
        states,
        isLoading: billingAddress.isLoading || updateAddressMutationLoading,
      }}
    >
      <FormModal
        fullWidth
        maxWidth="sm"
        title={data?.title}
        open={isBillingModalOpen}
        onClose={handleBillingModalClose}
        actionProps={data?.actionProps ?? null}
        formProps={data?.formProps ?? null}
        formKey={`form-modal-${currentScreen}`}
        submitProps={{
          disabled: addressSubmitLoading,
          'data-testid': 'form-submit',
        }}
        isSubmitting={updateAddressMutationLoading || billingAddress.isLoading}
      >
        {data?.content}
      </FormModal>
      {isDeleteModalOpen && <DeleteDialog />}
    </AddressContext.Provider>
  );
};
