import React, { PropsWithChildren } from 'react';
import { Typography, alpha } from '@clippings/paper';

export function Caption({ children }: PropsWithChildren) {
  return (
    <Typography variant="caption" color={theme => alpha(theme.palette.common.black, 0.6)}>
      {children}
    </Typography>
  );
}
