import React from 'react';
import { User } from 'libs/api/iam/types';
import { UserListItemSimple } from '../../staff/components/UserListItemSimple';
import { getCompanyNameWithCity } from 'libs/shared';

interface UserListItemCompanyProps {
  xs: number;
  user: User;
}

export const UserListItemCompany: React.FC<UserListItemCompanyProps> = ({ xs, user }) => {
  const companyName = user.company?.name ?? '\u00A0';
  const companyNameWithCity = getCompanyNameWithCity(companyName, user.company?.billingAddresses);

  const text = <div data-testid={`user-list-item-company-${user.id}`}>{companyNameWithCity}</div>;

  return <UserListItemSimple text={text} xs={xs} />;
};
