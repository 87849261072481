import React from 'react';
import { TableCell, TableHead, TableRow, useTranslation } from '@clippings/paper';

export function PaymentTermsTableHead() {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell width="30%">{t('companies.discountHeaderAmount')}</TableCell>
        <TableCell width="35%">{t('common.type')}</TableCell>
        <TableCell width="35%" />
      </TableRow>
    </TableHead>
  );
}
