import React from 'react';
import { Box, Checkbox, Controller, useFormContext, useWatch } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export const MakeDefaultSection: React.FC = () => {
  const { t } = useTranslation();
  const shouldSaveAddress = useWatch({ name: 'saveAddress' });
  const { control, setValue } = useFormContext();

  return (
    <Box display="flex" flexDirection="column">
      <Controller
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <Checkbox
              {...field}
              onChange={e => {
                if (!e.target.checked) {
                  setValue('default', false);
                }
                onChange(e);
              }}
              checked={!!value}
              label={t('quotes.addresses.saveThisAddress')}
            />
          );
        }}
        name="saveAddress"
        control={control}
      />
      <Controller
        render={({ field: { value, ...field } }) => {
          return (
            <Checkbox
              {...field}
              label={t('common.makeDefault')}
              disabled={!shouldSaveAddress}
              checked={!!value}
            />
          );
        }}
        name="default"
        control={control}
      />
    </Box>
  );
};
