import { CompanyDiscount, CompanyQuoteDiscount } from 'libs/api/common/types';

export const compareDiscounts = (a: CompanyDiscount, b: CompanyDiscount): number => {
  // products should go first
  if (a.type !== b.type) {
    return a.type === 'product' ? -1 : 1;
  }

  // types are the same
  switch (a.type) {
    case 'product':
      return a.percent! - b.percent!;
    case 'quote':
      return (
        (a as CompanyQuoteDiscount).amountAbove.amount -
        (b as CompanyQuoteDiscount).amountAbove.amount
      );
  }
};
