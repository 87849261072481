import React, { ChangeEvent, useState } from 'react';
import { Avatar, UserAvatar, getUserFullName } from '@clippings/paper';
import { DEBOUNCE_TIMINGS } from 'libs/Constants';
import { QuoteBase } from 'libs/api/quotes/types';
import { UserRole, UserStatus } from 'libs/api/iam/types';
import { debounce } from 'lodash';
import { useListInfiniteUsers } from 'libs/api/iam/hooks';
import { useToggle } from 'libs/shared';
import { useTranslation } from 'react-i18next';

const searchDefaultValue = '';
const usersPerPage = 100;

export function useQuoteCustomerSelect(
  customer: QuoteBase['customer'],
  readonly = false,
  catalogue?: string
) {
  const [searchValue, setSearchValue] = useState(searchDefaultValue);

  const [menuOpen, setMenuOpen] = useToggle();
  const { t } = useTranslation();

  const listUsersQuery = useListInfiniteUsers(
    {
      query: searchValue,
      roles: UserRole.BusinessCustomer,
      statuses: UserStatus.Approved,
      itemsPerPage: usersPerPage,
      activeOnly: true,
      ...(catalogue ? { catalogue: catalogue } : {}),
    },
    { enabled: !readonly }
  );

  function onMenuVisibilityChangeCallback(nextState: boolean) {
    if (!nextState) {
      setSearchValue(searchDefaultValue);
    }
  }

  const searchChangeHandler = debounce(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    DEBOUNCE_TIMINGS.INPUT_DEBOUNCE,
    {
      trailing: true,
    }
  );

  function chipMenuFilterToggleHandler(): void {
    setMenuOpen(!menuOpen);
    onMenuVisibilityChangeCallback(!menuOpen);
  }

  function getChipValue(): string {
    return customer ? getUserFullName(customer) : t('quotes.addUser');
  }

  function getChipAdornmentComponent(): JSX.Element {
    if (!customer) {
      return <Avatar variant="circular" sx={{ width: 20, height: 20 }} />;
    }

    return <UserAvatar user={customer} small />;
  }

  return {
    listUsersQuery,
    searchValue,
    menuOpen,
    searchChangeHandler,
    onMenuVisibilityChangeCallback,
    chipMenuFilterToggleHandler,
    getChipValue,
    getChipAdornmentComponent,
    isLoading: listUsersQuery.isFetching,
  };
}
