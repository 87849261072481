import React from 'react';
import { Box, Divider, Grid, getUserFullName } from '@clippings/paper';
import {
  QuoteAddress,
  QuoteCustomer,
  QuoteDateGridItem,
  QuoteInfoSimpleItem,
  QuoteTitle,
} from './info';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const QuoteInfo = () => {
  const { t } = useTranslation();
  const { quote } = useQuoteProvider();

  const salesRepName = getUserFullName(quote?.salesRepresentative);
  return (
    <>
      <QuoteTitle quoteNumber={quote.number} quoteName={quote.name} />
      <Box
        gap={1}
        display="grid"
        gridAutoColumns="minmax(0, 1fr)"
        gridTemplateRows="1fr"
        gridTemplateAreas={`
          ". . billing shipping"
          ". . billing shipping"`}
      >
        <QuoteCustomer customer={quote?.customer} />
        <QuoteInfoSimpleItem label={t('quotes.pdf.info.salesRep')} value={salesRepName} />
        <Box gridArea="billing">
          <QuoteAddress address={quote.billingAddress} labelText={t('common.billingAddress')} />
        </Box>
        <QuoteAddress address={quote.shippingAddress} labelText={t('common.deliveryAddress')} />
      </Box>

      <Divider className="pdf-divider" />

      <Grid container spacing={2}>
        <QuoteDateGridItem label={t('quotes.pdf.info.date')} date={quote.createdAt} />
        <QuoteDateGridItem label={t('quotes.pdf.info.expiry')} date={quote.expiresAt ?? ''} />
      </Grid>
    </>
  );
};
