import React from 'react';
import { Chip } from '@clippings/paper';
import { DataTestId } from 'libs/shared/types';
import { OrderStatus } from 'libs/api/order/types';
import { useOrderStatusMap } from 'libs/Orders/utils/useOrderStatusMap';
import { useTranslation } from 'react-i18next';

interface OrderStatusChipProps extends DataTestId {
  status: OrderStatus;
}

export const OrderStatusChip: React.FC<OrderStatusChipProps> = ({
  status,
  'data-testid': dataTestId,
}) => {
  const orderStatusMap = useOrderStatusMap();
  const { t } = useTranslation();
  const {
    chip: { labelKey, ...props },
  } = orderStatusMap[status];

  return <Chip data-testid={dataTestId} label={t(labelKey)} {...props} />;
};
