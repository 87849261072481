import React from 'react';
import { Grid, Typography } from '@clippings/paper';

interface UserListItemSimpleProps {
  xs: number;
  text?: React.ReactNode;
}

export const UserListItemSimple: React.FC<UserListItemSimpleProps> = ({ xs, text }) => {
  return (
    <Grid item xs={xs} sx={{ lineBreak: 'anywhere' }}>
      <Typography data-testid="user-list-item-simple" variant="body2" component="div">
        {text ? text : '\u00A0'}
      </Typography>
    </Grid>
  );
};
