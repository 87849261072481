import React, { useMemo, useState } from 'react';
import { DateTimeFormat, useFormatDateTime } from '@clippings/paper';
import { QUOTE_STATUSES } from 'libs/Constants';
import { QuoteListContext } from './QuoteListContext';
import { QuoteListItem, QuoteListItemStatus, QuoteWithNotification } from 'libs/api/quotes/types';
import { QuoteListView } from './QuoteListView';
import {
  archiveQuote as archiveQuoteAction,
  duplicateQuote as duplicateQuoteAction,
  unarchiveQuote as unarchiveQuoteAction,
} from '../quoteActions';
import { getActiveQuotes, getInactiveQuotes } from './quoteListActions';
import { useBanner } from 'libs/Components';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useUnreadChannels } from 'libs/Chat/utils/useUnreadChannels';

const QuotesListContainer = () => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const [filteredQuotes, setFilteredQuotes] = useState<{
    activeQuotes: QuoteListItem[] | null;
    archivedQuotes: QuoteListItem[] | null;
  }>({
    activeQuotes: null,
    archivedQuotes: null,
  });
  const { showErrorBanner } = useBanner();
  const queryClient = useQueryClient();

  useTitle(t('common.quotes'));

  const { channels } = useUnreadChannels();
  const {
    isLoading: activeQuotesLoading,
    isFetchingNextPage: activeLoadMoreLoading,
    fetchNextPage: activeFetchNextPage,
    hasNextPage: activeHasNextPage,
    data: activeQuotesData,
  } = useInfiniteQuery(['activeQuotes'], ({ pageParam }) => getActiveQuotes({ pageParam }), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return allPages.length + 1;
      }
    },
  });

  const activeQuotes = useMemo(() => {
    return (activeQuotesData?.pages ?? []).flatMap(page => page.items);
  }, [activeQuotesData]);

  const {
    isLoading: archivedQuotesLoading,
    isFetchingNextPage: archivedLoadMoreLoading,
    fetchNextPage: archivedFetchNextPage,
    hasNextPage: archivedHasNextPage,
    data: archivedQuotesData,
  } = useInfiniteQuery(['archivedQuotes'], ({ pageParam }) => getInactiveQuotes({ pageParam }), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return allPages.length + 1;
      }
    },
  });

  const archivedQuotes = useMemo(() => {
    return (archivedQuotesData?.pages ?? []).flatMap(page => page.items);
  }, [archivedQuotesData]);

  const handleSettle = async () => {
    await queryClient.invalidateQueries();
    setFilteredQuotes({
      activeQuotes: null,
      archivedQuotes: null,
    });
  };

  const handleMutate = (
    mutatedQuote: QuoteListItem,
    initialQuotes: QuoteListItem[],
    targetQuotes: QuoteListItem[],
    newStatus: QuoteListItemStatus,
    hasMore = false
  ) => {
    const tempInitialQuotes = initialQuotes.filter(
      quote => quote.versionId !== mutatedQuote.versionId
    );

    if (hasMore) {
      const placeholderQuote: any = { number: null };
      tempInitialQuotes.push(placeholderQuote);
    }

    if (targetQuotes.length % 4 === 0) {
      targetQuotes.pop();
    }

    mutatedQuote.status = newStatus;
    mutatedQuote.latestActivityAt = formatDateTime(new Date(), DateTimeFormat.Date);
    targetQuotes.unshift(mutatedQuote);

    return { targetQuotes, tempInitialQuotes };
  };

  const performArchiveQuoteAction = (quote: QuoteListItem) => archiveQuoteAction(quote.versionId);

  const archiveQuote = useMutation(performArchiveQuoteAction, {
    onMutate: async archivedQuote => {
      const { targetQuotes, tempInitialQuotes } = handleMutate(
        archivedQuote,
        activeQuotes,
        archivedQuotes,
        QUOTE_STATUSES.ARCHIVED,
        activeHasNextPage
      );
      setFilteredQuotes({ archivedQuotes: targetQuotes, activeQuotes: tempInitialQuotes });
    },
    onError: () => showErrorBanner(),
    onSettled: async () => handleSettle(),
  });

  const performUnarchiveQuoteAction = (quote: QuoteListItem) =>
    unarchiveQuoteAction(quote.versionId);

  const unarchiveQuote = useMutation(performUnarchiveQuoteAction, {
    onMutate: async archivedQuote => {
      const { targetQuotes, tempInitialQuotes } = handleMutate(
        archivedQuote,
        archivedQuotes,
        activeQuotes,
        QUOTE_STATUSES.DRAFT,
        archivedHasNextPage
      );
      setFilteredQuotes({ archivedQuotes: tempInitialQuotes, activeQuotes: targetQuotes });
    },
    onError: () => showErrorBanner(),
    onSettled: async () => handleSettle(),
  });

  const performDuplicateQuoteAction = (quote: QuoteListItem) =>
    duplicateQuoteAction(quote.versionId);

  const duplicateQuote = useMutation(performDuplicateQuoteAction, {
    onMutate: async duplicatedQuote => {
      const { targetQuotes } = handleMutate(
        { ...duplicatedQuote, number: null } as any,
        [],
        activeQuotes,
        QUOTE_STATUSES.DRAFT
      );
      setFilteredQuotes({ ...filteredQuotes, activeQuotes: targetQuotes });
    },
    onError: () => showErrorBanner(),
    onSettled: async () => handleSettle(),
  });

  const handleArchive = (quote: QuoteListItem) => {
    archiveQuote.mutate(quote);
  };

  const handleUnarchive = (quote: QuoteListItem) => {
    unarchiveQuote.mutate(quote);
  };

  const handleDuplicate = (quote: QuoteListItem) => {
    duplicateQuote.mutate(quote);
  };

  const checkForMessages = (quotes: QuoteListItem[]): QuoteWithNotification[] =>
    quotes?.map(quote => {
      return {
        ...quote,
        newMessages: channels.map(channel => channel.id).includes(quote.number),
      };
    });

  return (
    <QuoteListContext.Provider
      value={{
        activeQuotes: checkForMessages(filteredQuotes.activeQuotes ?? activeQuotes),
        archivedQuotes: checkForMessages(filteredQuotes.archivedQuotes ?? archivedQuotes),
        activeQuotesLoading,
        handleLoadMoreActiveQuotes: activeFetchNextPage,
        activeShowLoadMoreQuotes: Boolean(activeHasNextPage),
        activeLoadMoreLoading,
        archivedQuotesLoading,
        archivedLoadMoreLoading,
        handleLoadMoreArchivedQuotes: archivedFetchNextPage,
        archivedShowLoadMoreQuotes: Boolean(archivedHasNextPage),
        onArchive: handleArchive,
        onUnarchive: handleUnarchive,
        onDuplicate: handleDuplicate,
        disableActions:
          archiveQuote.isLoading || unarchiveQuote.isLoading || duplicateQuote.isLoading,
      }}
    >
      <QuoteListView />
    </QuoteListContext.Provider>
  );
};

export default QuotesListContainer;
