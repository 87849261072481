import React from 'react';
import { Box, Button, Link, Tab, Tabs, Typography } from '@clippings/paper';
import { CompanyDetailsPanel } from 'libs/SalesApp/Companies/details/components/CompanyDetailsPanel/CompanyDetailsPanel';
import { TabPanel } from './TabPanel';
import { UserDetails } from './components/UserDetails';
import { UserPermission, UserStatus } from 'libs/api/iam/types';
import { useAppConfiguration } from 'libs/providers';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useHasPermission } from 'libs/hooks/useHasPermission';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const Account: React.FC = () => {
  const { t } = useTranslation();
  const { data: user, isSuccess } = useCurrentUser();
  const isStaffUser = useHasPermission(UserPermission.CanAccessSalesApp);
  const appConfiguration = useAppConfiguration();

  useTitle(t('common.account'));

  // tab management
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  if (!isSuccess) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={4} p={3}>
      {/* <!-- header --> */}
      <Typography variant="h5" fontWeight="bold">
        {t('common.account')}
      </Typography>

      {/* <!-- tabs --> */}
      <Box borderBottom={1} borderColor="divider">
        <Tabs value={selectedTabIndex} onChange={handleTabChange}>
          <Tab label={t('common.personalInformation')} data-testid="ca-account-user-details-tab" />
          {!isStaffUser && user.status === UserStatus.Approved && user.company && (
            <Tab
              disabled={!user.company}
              label={t('common.companyInformation')}
              data-testid="ca-account-company-details-tab"
            />
          )}
        </Tabs>
      </Box>
      {/* tab panel users */}
      <TabPanel show={selectedTabIndex === 0} data-testid="ca-account-user-details-panel">
        <UserDetails user={user} />
        <Button
          component={Link}
          variant="outlined"
          sx={{ alignSelf: 'end' }}
          data-testid="ca-account-user-request-delete"
          href={`mailto:${appConfiguration.supportEmail}`}
        >
          {t('account.requestDeletion')}
        </Button>
      </TabPanel>
      {/* tab panel company */}
      {!isStaffUser && user.status === UserStatus.Approved && user.company && (
        <TabPanel show={selectedTabIndex === 1} data-testid="ca-account-company-details-panel">
          <CompanyDetailsPanel
            company={user.company}
            showCatalogueSection={false}
            canEditCompany={false}
            canEditAddresses
          />
        </TabPanel>
      )}
    </Box>
  );
};
