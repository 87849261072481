import {
  MutationHandlerPayload,
  QuoteRowMutationOptions,
  QuoteRowMutationPayload,
  useQuoteRowArchive,
  useQuoteRowDuplicate,
  useQuoteRowRestore,
  useQuoteRowUpdate,
} from '../salesQuoteRowMutations';
import { Quote } from 'libs/api/quotes/types';
import { QuoteMutation, QuotesPage } from '../../types';
import { QuoteSnackbarProps } from '../../components/QuoteSnackbar';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { reactQueryKeys } from 'libs/api/quotes/hooks';
import { useBanner } from 'libs/Components';
import { useLoadingQuotes } from './useLoadingQuotes';

export function useQuoteTableMutations(
  page: QuotesPage,
  setSnackbarDetails: (details: QuoteSnackbarProps['details']) => void
) {
  const queryClient = useQueryClient();
  const { showErrorBanner } = useBanner();
  const { loadingQuotes, setLoadingQuotes } = useLoadingQuotes();

  const getQuoteMutateHandler =
    (
      mutationType: QuoteMutation,
      mutation: UseMutationResult<Quote, unknown, QuoteRowMutationPayload, unknown>
    ) =>
    (payload: MutationHandlerPayload) => {
      // duplicated quotes get a top of the table skeleton while the quote itself remains intact
      const isForTop = page === QuotesPage.Active && mutationType === QuoteMutation.Duplicate;

      const { number } = payload;
      const { top, inline } = loadingQuotes;
      let updatedTop = [...top];
      const updatedInline = [...inline];

      if (isForTop) {
        // put on top to keep action order
        updatedTop = [number, ...updatedTop];
      } else {
        updatedInline.push(number);
      }

      setLoadingQuotes({ top: updatedTop, inline: updatedInline });

      mutation.mutate({ ...payload, mutation: mutationType });
    };

  const mutationOptions: QuoteRowMutationOptions = {
    onError: () => showErrorBanner(),
    onSettled: async () => {
      await queryClient.invalidateQueries([reactQueryKeys.getAll]);
    },
    onSuccess: (quote, { mutation }) => {
      if (mutation !== QuoteMutation.Update) {
        setSnackbarDetails({
          quote,
          mutation,
          page,
        });
      }
    },
  };

  const update = useQuoteRowUpdate(mutationOptions);
  const archive = useQuoteRowArchive(mutationOptions);
  const duplicate = useQuoteRowDuplicate(mutationOptions);
  const restore = useQuoteRowRestore(mutationOptions);

  return {
    loadingQuotes,
    setLoadingQuotes,
    onArchive: getQuoteMutateHandler(QuoteMutation.Archive, archive),
    onRestore: getQuoteMutateHandler(QuoteMutation.Restore, restore),
    onDuplicate: getQuoteMutateHandler(QuoteMutation.Duplicate, duplicate),
    onAssigneeChange: getQuoteMutateHandler(QuoteMutation.Update, update),
  };
}
