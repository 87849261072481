import QuoteLabelModal from './QuoteLabelModal';
import React from 'react';
import { Button, FlexRow, LabelIcon, Skeleton, Typography } from '@clippings/paper';
import { useQuoteProvider } from '../providers';
import { useTranslation } from 'react-i18next';

const QUOTE_LABEL_MAXLENGTH = 100;

const QuoteLabelButton = () => {
  const { t } = useTranslation();
  const { quote, handleLabelModalOpen, loading, optimisticLoading } = useQuoteProvider();

  if (loading) {
    return (
      <Skeleton
        width="140px"
        height="30px"
        variant="rectangular"
        sx={{ borderRadius: 4, my: '3px' }}
        data-testid="quote-label-loader"
      />
    );
  }

  const quoteLabel = quote?.name || t('quotes.labelModal.addLabel');

  return (
    <FlexRow justifyContent="flex-start" alignItems="center">
      <Button
        color="secondary"
        disabled={optimisticLoading || !quote.canEditNonContractual}
        startIcon={<LabelIcon key="chip-label-icon" />}
        onClick={handleLabelModalOpen}
        data-testid="change-label-chip"
      >
        <Typography
          fontSize="inherit"
          maxWidth={`${QUOTE_LABEL_MAXLENGTH}ch`}
          overflow="hidden"
          textOverflow="ellipsis"
          title={quoteLabel.length > QUOTE_LABEL_MAXLENGTH ? quoteLabel : undefined}
        >
          {quoteLabel}
        </Typography>
      </Button>
      <QuoteLabelModal />
    </FlexRow>
  );
};

export default QuoteLabelButton;
