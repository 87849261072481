import React from 'react';
import { InfoLine } from 'libs/shared';
import { useDiscountsInfoList } from '../hooks';

type DiscountsInfoListProps = {
  discountsListViewModel: ReturnType<typeof useDiscountsInfoList>['discountsListViewModel'];
};

export function DiscountsInfoList({ discountsListViewModel }: DiscountsInfoListProps) {
  return (
    <>
      {discountsListViewModel.map(({ id, label, value }, i, { length }) => (
        <InfoLine
          data-testid="discounts-info"
          key={id}
          label={label}
          value={value}
          divider={i !== length - 1}
        />
      ))}
    </>
  );
}
