import React, { useRef } from 'react';
import { ListItemIcon, MenuItem, Skeleton, UploadButton, UploadIcon } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { uniqueId } from 'lodash';
import { uploadQuoteItemsXml } from 'libs/api/quotes/actions';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';
import { useXmlImport } from '../hooks';

type QuoteXmlImportMenuItemProps = {
  disabled?: boolean;
  closeMenu: () => void;
};

export function QuoteXmlImportMenuItem({
  closeMenu,
  disabled = false,
}: QuoteXmlImportMenuItemProps) {
  const { t } = useTranslation();
  const { quote } = useQuoteProvider();

  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const loadingMessage = t('quotes.uploadXmlLoadingMessage');
  const { mutate: uploadXml, isLoading } = useXmlImport({
    // always update the latest quote version
    action: async ({ file }) =>
      await uploadQuoteItemsXml({
        file: {
          file: file,
          id: uniqueId(),
        },
        versionId: quote.versionId,
      }),
    uploadErrorCallback: closeMenu,
    loadingMessage,
  });

  function menuItemClickHandler() {
    inputRef.current?.click();
  }

  const inputProps = {
    accept: 'text/xml',
    onChange: uploadXml,
    'data-testid': 'upload-xml-input',
  };

  return (
    <MenuItem
      key="menu-item-xml-import"
      onClick={menuItemClickHandler}
      data-testid="menu-item-xml-import"
      disabled={disabled || isLoading}
    >
      <ListItemIcon>
        {isLoading ? <Skeleton variant="circular" width={24} height={24} /> : <UploadIcon />}
      </ListItemIcon>
      {t('quotes.uploadXml')}
      <UploadButton ref={inputRef} type="input" inputProps={inputProps} />
    </MenuItem>
  );
}
