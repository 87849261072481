import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { APIErrorResponse, ErrorFromResponse, Channel as StreamChannel } from 'stream-chat';
import { Channel } from '../types';
import { Nullable } from 'libs/Utils';
import { Quote } from 'libs/api/quotes/types';
import { useAddToChat } from '../hooks';
import { useChatClient } from './ChatClientProvider';

type MessageError = ErrorFromResponse<APIErrorResponse>;
export type ChatChannelContextValue = {
  error: any;
  channel: StreamChannel | undefined;
  loading: boolean;
  latestMessageError: Nullable<MessageError>;
  setLatestMessageError: React.Dispatch<React.SetStateAction<Nullable<MessageError>>>;
  addToCurrentChannel: () => Promise<void>;
};

const ChatChannelContext = React.createContext<ChatChannelContextValue>(
  {} as ChatChannelContextValue
);

export interface ChatChannelProviderProps extends React.PropsWithChildren {
  number: Quote['number'];
}

export const ChatChannelProvider: FC<ChatChannelProviderProps> = ({ children, number }) => {
  const [latestMessageError, setLatestMessageError] = useState<MessageError | null>(null);
  const { client, connected } = useChatClient();
  const [channel, setChannel] = useState<StreamChannel>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { mutate: addToChat } = useAddToChat();
  const initChannel = async () => {
    const filter = { type: Channel.Messaging, id: { $eq: number.toUpperCase() } };
    const channels = await client.queryChannels(filter);
    const [chatChannel] = channels;

    return chatChannel;
  };

  const connectToChannel = async () => {
    initChannel()
      .then(channel => {
        setChannel(channel);
        setError(null);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addToCurrentChannel = async () => {
    addToChat(number, {
      onSuccess: () => {
        connectToChannel();
      },
    });
  };

  useEffect(() => {
    if (!connected) {
      return;
    }

    connectToChannel();
  }, [number, connected]);

  const chatChannelContextValue: ChatChannelContextValue = useMemo(
    () => ({
      error,
      channel,
      loading,
      latestMessageError,
      setLatestMessageError,
      addToCurrentChannel,
    }),
    [error, channel, loading, latestMessageError]
  );

  return (
    <ChatChannelContext.Provider value={chatChannelContextValue}>
      {children}
    </ChatChannelContext.Provider>
  );
};

export function useChatChannel() {
  const value = useContext(ChatChannelContext);

  if (!value) {
    throw new Error('The useChatChannel hook was called outside of the ChatChannelProvider');
  }

  return value;
}
