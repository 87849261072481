import { CheckoutData } from 'libs/api/order/types';
import { PaymentMethod } from 'libs/api/payment/types';
import { Step } from './config/steps';

export enum PaymentStatus {
  Error = 'error',
  Incomplete = 'incomplete',
  Pending = 'pending',
  Success = 'success',
}

export interface CheckoutState {
  cardComplete: boolean;
  data: CheckoutData;
  error: string;
  hasPONumber: boolean;
  immediatePayment: boolean;
  hasPaymentAmount: boolean;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethod;
  setupReady: boolean;
  signatureModal: boolean;
  stripeSecret?: string;
  step: Step;
  termsAndConditionModal: boolean;
  termsAndConditionModalOpenedFromSign: boolean;
  isPoDocumentRequired: boolean;
  isUploadingPODocument: boolean;
}
