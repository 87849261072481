import React, { FC } from 'react';
import { ErrorLayout, ErrorLayoutProps } from './ErrorLayout';
import { Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export const GenericErrorView: FC<ErrorLayoutProps> = ({ to }) => {
  const { t } = useTranslation();
  return (
    <ErrorLayout to={to}>
      <Typography variant="h3">{t('common.oops')}</Typography>
      <Typography variant="h3">{t('common.somethingWentWrong')}</Typography>
      <Typography color="secondary">{t('common.cannotProcessYourRequestAtTheMoment')}</Typography>
      <Typography color="secondary">{t('common.pleaseRefreshThePageAndTryAgain')}</Typography>
    </ErrorLayout>
  );
};
