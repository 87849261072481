import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from '@clippings/paper';
import { Catalogue } from '../../../../../../api/catalogue/types';
import { Company } from 'libs/api/iam/types';
import { useTranslation } from 'react-i18next';
import { useUpdateCompany } from 'libs/api/iam/hooks';

export interface CatalogueDialogProps {
  company: Company;
  catalogueList: Catalogue[];
  onSuccess: () => void;
  onCancel: () => void;
}

export const CatalogueDialogContent: React.FC<CatalogueDialogProps> = ({
  company,
  catalogueList,
  onSuccess,
  onCancel,
}) => {
  const companyCatalogueSlugs = useMemo(
    () => new Set(company.catalogues.map(c => c.slug)),
    [company]
  );

  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCatalogueSlugs, setSelectedCatalogueSlugs] = useState(companyCatalogueSlugs);

  const updateCompanyMutation = useUpdateCompany({
    onSuccess: () => onSuccess(),
  });

  const hasSelection = selectAll || selectedCatalogueSlugs.size > 0;

  const allCatalogueSlugs = useMemo(
    () => new Set(catalogueList?.map(c => c.slug)),
    [catalogueList]
  );

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setSelectedCatalogueSlugs(allCatalogueSlugs);
  };

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedCatalogueSlugs(prev => {
        const newState = new Set(prev);
        newState.add(value);
        return newState;
      });
    } else {
      setSelectedCatalogueSlugs(prev => {
        const newState = new Set(prev);
        newState.delete(value);
        return newState;
      });
    }
  };

  const handleApply = () =>
    updateCompanyMutation.mutate({
      companyId: company.id,
      company: {
        catalogues: Array.from(selectedCatalogueSlugs),
      },
    });

  return (
    <>
      <Box display="flex" flexDirection="column" data-testid="catalogue-dialog-content">
        <Typography variant="caption" sx={{ mb: 1 }}>
          {t('companies.catalogEditorSubtitle')}
        </Typography>

        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontWeight: 'normal',
            },
          }}
          control={<Switch checked={selectAll} onChange={handleSelectAll} />}
          data-testid="catalogue-dialog-select-all"
          label={t('companies.allCatalogues')}
        />

        {catalogueList?.map(c => {
          return (
            <FormControlLabel
              key={c.slug}
              value={c.slug}
              sx={{
                ml: 1,
                '& .MuiFormControlLabel-label': {
                  fontWeight: 'normal',
                },
              }}
              control={
                <Checkbox
                  checked={selectedCatalogueSlugs.has(c.slug)}
                  onChange={handleSelect}
                  disabled={selectAll}
                  data-testid="catalogue-dialog-select-single"
                />
              }
              label={c.name}
            />
          );
        })}

        {!hasSelection && <Alert severity="error">{t('companies.catalogEditorError')}</Alert>}
      </Box>
      <Box pt={2} display="flex" justifyContent="flex-end">
        <Button
          data-testid="address-dialog-button-cancel"
          color="secondary"
          onClick={() => onCancel()}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus={true}
          onClick={handleApply}
          disabled={
            !hasSelection || updateCompanyMutation.isLoading || updateCompanyMutation.isSuccess
          }
          data-testid="catalogue-dialog-apply"
        >
          {t('common.apply')}
        </Button>
      </Box>
    </>
  );
};
