import React, { FC, PropsWithChildren } from 'react';
import { Brand, FormTextField } from '@clippings/paper';
import { PdfTemplateSelect } from 'libs/shared/components/teams/PdfTemplateSelect';
import { TFunction } from 'i18next';
import { TeamField, teamFieldsConfig } from './teamFieldsConfig';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

const getFormFieldProps = (
  field: TeamField,
  t: TFunction<'translation', undefined, 'translation'>
) => ({
  'data-testid': `team-form-field-${field}`,
  key: field,
  name: field,
  label: t(`teams.table.${field}`),
});

export const TeamFormFields: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();

  const fields: Record<TeamField, JSX.Element | null> = {
    [TeamField.Name]: <FormTextField {...getFormFieldProps(TeamField.Name, t)} />,
    [TeamField.PdfTemplate]: <PdfTemplateSelect {...getFormFieldProps(TeamField.PdfTemplate, t)} />,
  };

  return (
    <>
      {teamFieldsConfig[brand ?? Brand.Moroso].map(field => fields[field])}
      {children}
    </>
  );
};
