import React, { FC, useState } from 'react';
import { ChipFilterProps } from '../types';
import { ChipMenuFilter } from '@clippings/paper';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { StatusCircle } from 'libs/Components';
import { StatusOption } from './StatusOption';
import { quoteStatusMap } from 'libs/Constants';
import { useTranslation } from 'react-i18next';

export const StatusChipFilter: FC<ChipFilterProps<QuoteListItemStatus, QuoteListItemStatus>> = ({
  options,
  onSelect,
  selected,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const getChipValue = () => {
    if (options.length === 1) return t(`quotes.${options[0]}`);

    const [status] = selected;
    if (status) return t(`quotes.${status}`);

    return t('common.status');
  };

  const onToggle = () => setOpen(!open);

  const setSelection = (status?: QuoteListItemStatus) => {
    onToggle();
    onSelect(status);
  };

  return (
    <ChipMenuFilter
      chipProps={{
        adornment: (
          <StatusCircle
            data-testid="status-chip-filter-adorment"
            chipProps={quoteStatusMap[selected[0]]}
          />
        ),
        value: getChipValue(),
        hasArrow: true,
        isOpen: open,
        onToggle: onToggle,
        'data-testid': 'status-chip-filter',
      }}
      clearProps={{
        label: t('common.clear'),
        buttonProps: {
          onClick: () => setSelection(),
        },
      }}
      contentContainerProps={{
        'data-testid': 'status-chip-filter-content',
      }}
      {...rest}
    >
      {options.map(status => (
        <StatusOption
          key={`status-filter-${status}`}
          onSelect={setSelection}
          label={t(`quotes.${status}`)}
          status={status}
          circleProps={{ chipProps: quoteStatusMap[status] }}
        />
      ))}
    </ChipMenuFilter>
  );
};
