import React, { FC, useEffect } from 'react';
import {
  AddIcon,
  Box,
  Button,
  Controller,
  Divider,
  MegaRadioItem,
  RadioGroup,
  Stack,
  Typography,
  useFormContext,
} from '@clippings/paper';
import { AddressListItem } from './AddressListItem';
import { AddressListSkeleton } from './Skeletons';
import { AddressType, BillingAddress, ShippingAddress } from 'libs/api/common/types';
import { AddressTypesEnum } from '../hooks/useAddresses';
import { useAddressContext } from 'libs/Quotes/QuoteAddresses/AddressContext';
import { useBanner } from '../Components/AlertBanner';
import { useTranslation } from 'react-i18next';

export type Addresses = ShippingAddress[] | BillingAddress[];
export type AddressListProps = {
  companyAddresses: Addresses;
  quoteAddresses: Addresses;
  isLoading: boolean;
  name: string;
  quoteAddressTitle: string;
  companyAddressTitle: string;
  defaultValues: { shippingAddress: { id: string } } | { billingAddress: { id: string } };
  type: AddressType;
  isFrozen?: boolean;
};

const isCompanyAddressEditable = (address: ShippingAddress | BillingAddress, isFrozen?: boolean) =>
  isFrozen ? false : !!address.editable;

export const AddressList: FC<AddressListProps> = ({
  type,
  companyAddresses,
  quoteAddresses,
  isLoading,
  name,
  quoteAddressTitle,
  companyAddressTitle,
  defaultValues,
  isFrozen = false,
}) => {
  const { handleOpenCreateNewAddress } = useAddressContext();
  const { showErrorBanner } = useBanner();
  const { control, formState, reset } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      showErrorBanner(t('quotes.addresses.pleaseSelectAddress'));
    }
  }, [formState]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const hasAddresses = companyAddresses?.length > 0 || quoteAddresses?.length > 0;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup {...field} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {type === 'shipping' ? (
            <Box data-testid="address-list-container">
              <Typography variant="body1" color="secondary">
                {quoteAddresses?.length > 0 ? quoteAddressTitle : null}
              </Typography>
              <Stack direction="column">
                {isLoading ? (
                  <AddressListSkeleton />
                ) : (
                  quoteAddresses?.map(address => (
                    <MegaRadioItem
                      value={address.id as number}
                      key={address.id}
                      name="address-radio"
                      data-testid={`quote-address-radio-${address.id}`}
                    >
                      <AddressListItem
                        type={AddressTypesEnum.QUOTE}
                        address={address}
                        hasDefault={false}
                        isEditable
                      />
                    </MegaRadioItem>
                  ))
                )}
              </Stack>
            </Box>
          ) : null}
          <Box data-testid="company-address-list-container">
            <Typography variant="body1" color="secondary">
              {companyAddresses?.length > 0 ? companyAddressTitle : null}
            </Typography>
            <Stack direction="column">
              {isLoading ? (
                <AddressListSkeleton />
              ) : (
                companyAddresses?.map(address => {
                  const addressComponent = (
                    <AddressListItem
                      key={address.id}
                      type={AddressTypesEnum.COMPANY}
                      address={address}
                      hasDelete={companyAddresses?.length > 1}
                      withBorder={type === 'billing'}
                      isEditable={isCompanyAddressEditable(address, isFrozen)}
                    />
                  );

                  if (type === 'billing') {
                    return addressComponent;
                  }

                  return (
                    <MegaRadioItem
                      value={address.id as number}
                      key={address.id}
                      name="address-radio"
                      data-testid={`company-address-radio-${address.id}`}
                    >
                      {addressComponent}
                    </MegaRadioItem>
                  );
                })
              )}
            </Stack>
          </Box>

          {/* for type: "billing", render only if there's no company billing address  */}
          {type === 'shipping' || (type === 'billing' && companyAddresses?.length === 0) ? (
            <>
              {hasAddresses && (
                <Divider>
                  <Typography variant="subtitle1" color="grey.A400" fontWeight="fontWeightMedium">
                    {t('common.or')}
                  </Typography>
                </Divider>
              )}
              <Box>
                <Button
                  color="primary"
                  onClick={() => handleOpenCreateNewAddress()}
                  size="medium"
                  startIcon={<AddIcon />}
                  data-testid="open-create-new-address-button"
                >
                  {t('quotes.addresses.enterAddressManually')}
                </Button>
              </Box>
            </>
          ) : null}
        </RadioGroup>
      )}
    />
  );
};
