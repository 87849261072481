import React from 'react';
import { Grid, GridProps, Typography } from '@clippings/paper';

interface ComponentHeaderProps {
  title: string;
  description?: string;
}

export const ComponentHeader: React.FC<ComponentHeaderProps & GridProps> = ({
  title,
  description,
  ...rest
}) => {
  return (
    <Grid item {...rest}>
      <Typography variant="h6">{title}</Typography>
      {description && (
        <Typography variant="body1" color="secondary">
          {description}
        </Typography>
      )}
    </Grid>
  );
};
