import React from 'react';
import { CommonPaymentRuleCellProps } from '../../paymentTerms.types';
import { CustomPaymentRule } from 'libs/api/common/types';
import { TextField } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

type CustomPaymentRuleCellProps = CommonPaymentRuleCellProps<CustomPaymentRule>;

export function CustomPaymentRuleCell({
  paymentRule,
  onChange,
  'data-testid': dataTestId,
}: CustomPaymentRuleCellProps) {
  const { t } = useTranslation();

  return (
    <TextField
      multiline
      fullWidth
      variant="standard"
      value={paymentRule.dueReason}
      onChange={event => onChange(event.target.value)}
      placeholder={t('paymentTerms.dueReason')}
      InputProps={{
        sx: { py: 0 },
        inputProps: {
          sx: { py: 0 },
          'data-testid': dataTestId,
        },
        disableUnderline: true,
      }}
    />
  );
}
