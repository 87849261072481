import React from 'react';
import classNames from 'classnames';
import { AddProductRow } from '../AddProductRow/AddProductRow';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@clippings/paper';
import { ConfiguredProduct } from '../../../../SalesApp/Quotes/common/types';
import { LineItemVisibleFields } from 'libs/api/configuration/types';
import { OrderProductItem } from 'libs/api/order/types';
import { QuoteItemHiddenDetails } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { QuoteItemRow } from '../QuoteItemRow/QuoteItemRow';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { Readonly, getQuoteItemContractFieldValue } from 'libs/shared';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';
import { makeContractFieldsValues } from '../../utils';
import { useQuoteTableHeadCells, useQuoteTableVisibleFields } from '../../hooks';
import './QuoteTableView.scss';

interface QuoteTableViewProps extends Readonly {
  items: QuoteProductItem[] | OrderProductItem[];
  catalogue?: string;
  readonly: boolean;
  className?: string;
  tableLoading?: boolean;
  textOnly?: boolean;
  hiddenDetailProducts: QuoteItemHiddenDetails[];
  canAddQuoteItem?: boolean;
  hiddenColumns?: LineItemVisibleFields;
  handleDelete?: (id: number) => void;
  handleQuantityUpdate?: (quantity: number, id: number) => void;
  onConfigure?: (configuredProduct: ConfiguredProduct) => void;
  handleOpenPlp?: () => void;
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
  onNoteSave?: UpdateQuoteByKey;
}

export const QuoteTableView: React.FC<QuoteTableViewProps> = ({
  className,
  readonly,
  tableLoading,
  items,
  textOnly = false,
  catalogue = null,
  hiddenDetailProducts,
  canAddQuoteItem = true,
  hiddenColumns,
  handleDelete,
  handleQuantityUpdate,
  onConfigure,
  handleOpenPlp,
  onProductDetailsHide,
  onNoteSave,
}) => {
  const contractFields = getQuoteItemContractFieldValue(items?.[0] ?? null);
  const { lineItemVisibleFields } = useQuoteTableVisibleFields(contractFields, hiddenColumns);

  const { tableHeadCellsMap } = useQuoteTableHeadCells();

  return (
    <Box className={classNames('quote-table', className)}>
      <TableContainer
        component={Box}
        border="1px solid"
        borderColor="grey.A200"
        className="quote-table__container"
      >
        <Table className="quote-table__content" sx={styles.tableSx}>
          <TableHead className="quote-table__head">
            <TableRow>{lineItemVisibleFields.map(field => tableHeadCellsMap[field])}</TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, i) => (
              <QuoteItemRow
                key={`row-item-${i}`}
                textOnly={textOnly}
                readonly={readonly}
                item={item}
                tableLoading={tableLoading}
                detailsHidden={hiddenDetailProducts.some(x => x.id === item.id && x.hidden)}
                lineItemVisibleFields={lineItemVisibleFields}
                contractFieldsValues={makeContractFieldsValues(item)}
                onDeleteItem={handleDelete}
                onQuantityUpdate={handleQuantityUpdate}
                onConfigure={onConfigure}
                onProductDetailsHide={onProductDetailsHide}
                onNoteSave={onNoteSave}
              />
            ))}
            {!readonly && canAddQuoteItem && onConfigure && handleOpenPlp && (
              <AddProductRow
                catalogue={catalogue!}
                isLoading={tableLoading}
                handleOpenPlp={handleOpenPlp}
                onConfigure={onConfigure}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const styles = {
  tableSx: {
    '.MuiTableCell-root': {
      padding: '7px',
    },
  },
};
