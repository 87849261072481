import React from 'react';
import { Button, Skeleton } from '@clippings/paper';
import { QuoteApproveButton } from 'libs/shared/components/FooterButtons/QuoteApproveButton';
import { QuoteCheckoutButton } from 'libs/shared/components/FooterButtons/QuoteCheckoutButton';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { QuoteRenewButton } from 'libs/shared/components/FooterButtons/QuoteRenewButton';
import { QuoteRestoreButton } from 'libs/shared/components/FooterButtons/QuoteRestoreButton';
import { canApproveAndSend, canCheckoutQuote, hasOrderedVersion } from 'libs/shared';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useNavigate } from 'react-router-dom';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useQuoteCheckoutHandler } from 'libs/Quotes/Checkout/hooks';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useRequestQuote } from 'libs/shared/hooks/useRequestQuote';
import { useTranslation } from 'react-i18next';

export const QuoteFooterActionButtons: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser()?.data;
  const { getOrderShowPath } = useOrderPaths();
  const {
    quote,
    loading,
    onUnarchiveQuoteVersion: onUnarchive,
    onRestoreQuoteVersion: onRestoreVersion,
    handleShippingModalOpen,
    handleBillingModalOpen,
  } = useQuoteProvider();
  const navigate = useNavigate();
  const onRequest = useRequestQuote();
  const { quoteCheckoutHandler } = useQuoteCheckoutHandler(
    handleShippingModalOpen,
    handleBillingModalOpen
  );

  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        width={160}
        height={36}
        data-testid="quote-footer-button-loading"
      />
    );
  }

  const { status } = quote;

  if (status === QuoteListItemStatus.Ordered) {
    return (
      <Button
        variant="contained"
        data-testid="track-order"
        onClick={() => {
          // This should be replaced once the order details page is implemented in the sales app
          navigate(getOrderShowPath(quote.number));
        }}
      >
        {t('common.trackOrderInCustomer')}
      </Button>
    );
  }

  if (status === QuoteListItemStatus.Archived) {
    return <QuoteRestoreButton quote={quote} onClick={onUnarchive} />;
  }

  if (status === QuoteListItemStatus.Expired) {
    const quoteHasOrderedVersion = hasOrderedVersion(quote.versions);

    return !quoteHasOrderedVersion ? (
      <QuoteRenewButton quote={quote} onClick={onRestoreVersion} />
    ) : null;
  }

  if (currentUser && canCheckoutQuote(status, currentUser)) {
    return <QuoteCheckoutButton quote={quote} onClick={quoteCheckoutHandler} />;
  }

  if (canApproveAndSend(status)) {
    return <QuoteApproveButton onRequest={onRequest} />;
  }

  return null;
};
