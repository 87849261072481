import { Location } from 'libs/api/location/types';
import { QueryOptions } from 'libs/api/common/types';
import { useListLocations } from 'libs/api/location/hooks';
import { useMemo } from 'react';

export const useCountriesAndStates = (options?: QueryOptions<Location[]>) => {
  const locationsQuery = useListLocations('country,state', options);

  const countries = useMemo(() => {
    return (locationsQuery.data ?? [])
      .filter(location => location.type === 'country')
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [locationsQuery.data]);

  const states = useMemo(() => {
    return (locationsQuery.data ?? [])
      .filter(location => location.type === 'state')
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [locationsQuery.data]);

  const countriesByShortName: Record<string, Location> = useMemo(() => {
    return countries.reduce(
      (acc, country) => ({ ...acc, [String(country.shortName)]: country }),
      {}
    );
  }, [countries]);

  const statesByShortName: Record<string, Location> = useMemo(() => {
    return states.reduce((acc, state) => ({ ...acc, [state.shortName]: state }), {});
  }, [states]);

  return {
    countries,
    states,
    countriesByShortName,
    statesByShortName,
    isLoading: locationsQuery.isLoading,
  };
};
