import React from 'react';
import { AddressTypesEnum } from 'libs/Quotes/hooks/useAddresses';
import { BillingAddress, ShippingAddress } from 'libs/api/common/types';
import { Box, Button, Typography } from '@clippings/paper';
import { ShippingAddressMetadata } from 'libs/Components/ShippingAddressMetadata/ShippingAddressMetadata';
import { useAddressContext } from 'libs/Quotes/QuoteAddresses/AddressContext';
import { useTranslation } from 'react-i18next';

export interface AddressListItemProps {
  address: ShippingAddress | BillingAddress;
  type: AddressTypesEnum;
  hasDelete?: boolean;
  hasDefault?: boolean;
  isEditable?: boolean;
  withBorder?: boolean;
}

export const AddressListItem: React.FC<AddressListItemProps> = ({
  address,
  type,
  hasDelete = true,
  hasDefault = true,
  isEditable,
  withBorder = false,
}) => {
  const { handleOpenEditAddress, handleOpenDeleteModal, handleMakeDefault } = useAddressContext();
  const { id, address: addressName, address2, city, state, country, province, zip } = address;
  const { t } = useTranslation();

  const getAddressContact = () => {
    const contactName = address.contactName ?? '';
    const phone = 'phone' in address && address.phone ? address.phone : '';
    const accessDetails =
      'accessDetails' in address && address.accessDetails ? address.accessDetails : '';

    const addressContact = [contactName, phone, accessDetails].filter(Boolean).join(' ');

    return addressContact;
  };

  const addressContact = getAddressContact();

  const itemTitle = (): string => {
    if ('companyName' in address) {
      return address.companyName ?? '';
    }

    if ('nickname' in address) {
      return address.nickname ?? '';
    }

    return '';
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      flexBasis="100%"
      p={1}
      sx={withBorder ? borderStyles : null}
    >
      <Typography variant="body1">{itemTitle()}</Typography>
      <Box display="flex" flexDirection="column">
        <Typography variant="caption" color="secondary">
          {[addressName, address2, city, state?.name, province, country?.name, zip]
            .filter(Boolean)
            .join(', ')}
        </Typography>
        {addressContact && (
          <Typography variant="caption" color="secondary">
            {t('common.contact')}
            {': '}
            {addressContact}
          </Typography>
        )}
        <ShippingAddressMetadata address={address} />
      </Box>
      {isEditable && (
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          {hasDefault &&
            (address.default ? (
              <Typography data-testid="default" variant="subtitle2" color="grey.A400">
                {t('common.default')}
              </Typography>
            ) : (
              <Button
                data-testid="make-default"
                color="secondary"
                sx={{ m: 0, p: 0, minWidth: 'auto' }}
                onClick={() => handleMakeDefault(address)}
              >
                {t('common.makeDefault')}
              </Button>
            ))}

          <Button
            sx={{ m: 0, p: 0, minWidth: 'auto' }}
            color="secondary"
            onClick={() => handleOpenEditAddress({ ...address, type })}
            data-testid={`edit-button-${id}`}
          >
            {t('common.edit')}
          </Button>
          {hasDelete && (
            <Button
              sx={{ m: 0, p: 0, minWidth: 'auto' }}
              color="secondary"
              data-testid={`delete-button-${id}`}
              onClick={() => handleOpenDeleteModal({ ...address, type })}
            >
              {t('common.delete')}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

const borderStyles = {
  border: 1,
  borderColor: 'primary.main',
  borderRadius: 2,
  p: 2,
  mx: 0,
  my: 0.5,
};
