import React, { ComponentProps, useContext } from 'react';
import { DateTime, DateTimeFormat, Grid, Monetary, Typography } from '@clippings/paper';
import { ListTableRow } from '../../Components/ListTable/ListTableRow';
import { MoreActionsButton } from '../Components/MoreActionsButton';
import { QuoteListContext } from './QuoteListContext';
import { QuoteListGridItemSkeleton } from './QuoteListSkeletonLoading';
import { QuoteWithNotification } from 'libs/api/quotes/types';
import { StatusChip } from 'libs/shared';
import { UserAvatarRow } from 'libs/Components/UserAvatarRow/UserAvatarRow';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './QuoteListGridItem.scss';

interface QuoteListGridItemProps extends ComponentProps<typeof ListTableRow> {
  quote: QuoteWithNotification;
  disableActions: boolean;
}

const QuoteListGridItem: React.FC<QuoteListGridItemProps> = ({
  quote,
  disableActions,
  ...rest
}) => {
  const navigate = useNavigate();
  const { onArchive, onUnarchive, onDuplicate } = useContext(QuoteListContext);
  const { t } = useTranslation();
  if (quote.number === null) {
    return <QuoteListGridItemSkeleton />;
  }

  const {
    number,
    name,
    latestActivityAt,
    salesRepresentative,
    status,
    newMessages,
    total: { amount, currency },
  } = quote;

  const handleClick = () => {
    navigate(path(routes.quotes.show, { number }));
  };

  return (
    <ListTableRow
      alignItems="center"
      coliumns={12}
      onClick={handleClick}
      data-testid={`quote-item-row-${number}`}
      {...rest}
    >
      <Grid item display="flex" flexDirection="column" xs={2}>
        <Typography
          variant="body1"
          fontWeight="fontWeightMedium"
          data-testid={`quote-item-number-${number}`}
        >
          {number}
        </Typography>
        <Typography
          variant="caption"
          color="secondary"
          data-testid={`quote-item-label-${number}`}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Monetary
          fontWeight="fontWeightMedium"
          amount={amount}
          currency={currency}
          data-testid={`quote-item-value-${number}`}
        />
      </Grid>
      <Grid item xs={2}>
        <StatusChip status={status} data-testid={`quote-item-status-${number}`} />
      </Grid>
      <Grid item xs={2}>
        <DateTime
          format={DateTimeFormat.Date}
          date={latestActivityAt}
          data-testid={`quote-item-activity-${number}`}
          variant="body1"
          fontWeight="fontWeightMedium"
        />
        {newMessages && (
          <Typography variant="caption" className="quote-list-grid-item__message">
            {t('common.newMessage')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        {salesRepresentative && (
          <UserAvatarRow user={salesRepresentative} data-testid={`quote-item-avatar-${number}`} />
        )}
      </Grid>
      <Grid item xs={1} justifyContent="right">
        <MoreActionsButton
          disabled={disableActions}
          data-testid={`quote-item-more-${number}`}
          quote={quote}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          onDuplicate={onDuplicate}
        />
      </Grid>
    </ListTableRow>
  );
};

export default QuoteListGridItem;
