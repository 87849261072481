import { AccountStepValues } from '@clippings/paper';
import { guestRegister } from 'libs/api/iam/actions';
import { onRegisterSuccess, useRegisterUtils } from './useRegisterUtils';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useMutation } from '@tanstack/react-query';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';

export const useGuestRegister = () => {
  const {
    activeStep,
    setActiveStep,
    handleSignInClick,
    handleBackToBrowseClick,
    handleBackToAccountDetails,
  } = useRegisterUtils({
    getCatalogue: false,
  });

  const currentUser = useCurrentUser().data;

  const guestRegisterMutation = useMutation(guestRegister, {
    onSuccess: () => onRegisterSuccess(setActiveStep),
  });

  const handleSubmit = (values: AccountStepValues) => guestRegisterMutation.mutate(values);

  const formErrors = useServerFormErrors(guestRegisterMutation.error);

  return {
    activeStep,
    setActiveStep,
    handleSubmit,
    handleSignInClick,
    handleBackToBrowseClick,
    handleBackToAccountDetails,
    formErrors,
    guestRegisterMutation,
    currentUser,
  };
};
