import {
  AddQuoteItemProduct,
  AddQuoteShareesPayload,
  AddSampleToQuotePayload,
  ListProjectsParams,
  Project,
  Quote,
  QuoteDocument,
  QuoteListItem,
  QuoteProductItem,
  QuoteShareesList,
} from './types';
import { AxiosUploadParams } from '../upload/types';
import { Paginated, PaymentRule } from 'libs/api/common/types';
import { QuoteTransitionError } from 'libs/SalesApp/Quotes/types';
import { axios, path } from 'libs/Utils';

export const apiPaths = {
  quoteById: '/api/quote/v1/quotes/:number',
  version: '/api/quote/v1/versions/:id',
  quotes: '/api/quote/v1/quotes',
  update: '/api/quote/v1/versions/:versionId',
  nonContractual: '/api/quote/v1/versions/:versionId/non-contractual',
  updateItemNonContractual: '/api/quote/v1/versions/:id/items/:itemId/non-contractual',
  archive: '/api/quote/v1/versions/:versionId/archive',
  unarchive: '/api/quote/v1/versions/:versionId/unarchive',
  restore: '/api/quote/v1/versions/:versionId/restore',
  duplicate: '/api/quote/v1/versions/:versionId/duplicate',
  items: '/api/quote/v1/versions/:versionId/items',
  samples: '/api/quote/v1/versions/:versionId/samples',
  paymentRules: '/api/quote/v1/versions/:versionId/payment-rules',
  projects: '/api/project/v1/projects',
  createFromXml: '/api/quote/v1/quotes/xml',
  createQuoteItemsFromXml: '/api/quote/v1/versions/:versionId/xml',
  uploadDocument: '/api/quote/v1/quotes/:number/documents',
  deleteDocument: '/api/quote/v1/quotes/:number/documents/:id',
  getSharees: '/api/quote/v1/quotes/:number/sharees',
  addSharees: '/api/quote/v1/quotes/:number/sharees',
  removeSharee: '/api/quote/v1/quotes/:number/sharees/:shareeId',
  create: '/api/quote/v1/quotes',
  requestQuoteApproval: '/api/quote/v1/versions/:id/request',
  getPaymentTerms: '/api/quote/v1/versions/:id/payment-terms',
  quoteShippingAddress: '/api/quote/v1/versions/:id/shipping-address',
  quoteExWorksAddress: '/api/quote/v1/versions/:id/ex-works-address',
  quoteBillingAddress: '/api/quote/v1/versions/:id/billing-address',
  companyBillingAddresses: '/api/iam/v1/companies/:id/billing-addresses',
  companyShippingAddresses: '/api/iam/v1/companies/:id/shipping-addresses',
  editCompanyShippingAddress: '/api/iam/v1/companies/:id/shipping-addresses/:addressId',
  editCompanyBillingAddress: '/api/iam/v1/companies/:id/billing-addresses/:addressId',
  evaluateDeliveryMethods: '/api/delivery/v1/methods/evaluate',
  createItem: '/api/quote/v1/versions/:id/items',
  validateTransition: '/api/quote/v1/versions/:id/transition-check',
};

/**
 *
 * @param params (TODO: Properly type once BE changes are implemented and params are known) Quotes table filters and sort params, e.g. { assignee: 1, order: 'desc' }
 * @returns
 */
export const getQuotes = (params?: Record<string, string | number | undefined | null>) =>
  axios
    .get<Paginated<QuoteListItem>>(apiPaths.quotes, {
      params,
    })
    .then(response => response.data);

export const updateQuote = (versionId: number, quote: Partial<Quote>) => {
  return axios
    .patch<Quote>(path(apiPaths.update, { versionId }), quote)
    .then(response => response.data);
};

export const updateQuoteNonContractual = (versionId: number, quote: Partial<Quote>) => {
  return axios
    .patch<Quote>(path(apiPaths.nonContractual, { versionId }), quote)
    .then(response => response.data);
};

export const updateQuoteItemNonContractual = (
  quoteVersionId: number,
  itemId: number,
  quoteProductItem: Partial<QuoteProductItem>
) => {
  return axios
    .patch<Quote>(
      path(apiPaths.updateItemNonContractual, { id: quoteVersionId, itemId }),
      quoteProductItem
    )
    .then(response => response.data);
};

export const createQuote = () => axios.post<Quote>(apiPaths.quotes).then(response => response.data);

export const archiveQuote = (versionId: number) =>
  axios.post<Quote>(path(apiPaths.archive, { versionId })).then(response => response.data);

export const unarchiveQuote = (versionId: number) =>
  axios.post<Quote>(path(apiPaths.unarchive, { versionId })).then(response => response.data);

export const restoreQuoteVersion = (versionId: number) =>
  axios.post<Quote>(path(apiPaths.restore, { versionId })).then(response => response.data);

export const duplicateQuote = (versionId: number) =>
  axios.post<Quote>(path(apiPaths.duplicate, { versionId })).then(response => response.data);

export const addProductToQuote = (versionId: number, payload: AddQuoteItemProduct) =>
  axios.post<Quote>(path(apiPaths.items, { versionId }), payload).then(response => response.data);

export const addSampleToQuote = (versionId: number, payload: AddSampleToQuotePayload) =>
  axios.post<Quote>(path(apiPaths.samples, { versionId }), payload).then(response => response.data);

export const updateQuotePaymentTerms = (versionId: number, rules: PaymentRule[]) =>
  axios
    .post<PaymentRule[]>(path(apiPaths.paymentRules, { versionId }), rules)
    .then(response => response.data);

export const listProjects = (params?: ListProjectsParams) =>
  axios
    .get<Project[]>(apiPaths.projects, { params: { page: 1, ...params } })
    .then(response => response.data);

export const uploadXml = ({ file, onUploadProgress, signal }: AxiosUploadParams) =>
  axios.post<Quote>(apiPaths.createFromXml, file, {
    headers: multipartTypeHeaders,
    onUploadProgress,
    signal,
  });

export const uploadQuoteItemsXml = ({
  file,
  onUploadProgress,
  signal,
  versionId,
}: AxiosUploadParams & { versionId: number }) =>
  axios.post<Quote>(path(apiPaths.createQuoteItemsFromXml, { versionId }), file, {
    headers: multipartTypeHeaders,
    onUploadProgress,
    signal,
  });

/**
 * Uploads a document. Allowed formats are: .pdf, .doc, .docx, .xls, .xlsx, .txt, .html, .xml, .jpeg, .jpg, .webp, .png, .eps and max file size is 10MB
 */
export const uploadDocument = (
  { file, onUploadProgress, signal }: AxiosUploadParams,
  number: string
) =>
  axios
    .post<QuoteDocument>(path(apiPaths.uploadDocument, { number }), file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      signal,
    })
    .then(response => response.data);

/**
 * Deletes uploaded document
 */
export const deleteDocument = (id: string, number: string) =>
  axios.delete(path(apiPaths.deleteDocument, { id, number })).then(response => response.data);

const multipartTypeHeaders = {
  'Content-Type': 'multipart/form-data',
};

export const addSharees = (number: string, payload: AddQuoteShareesPayload) =>
  axios
    .post<QuoteShareesList>(path(apiPaths.addSharees, { number }), payload)
    .then(response => response.data);

export const removeSharee = (number: string, shareeId: string) =>
  axios.delete(path(apiPaths.removeSharee, { number, shareeId })).then(response => response.data);

export const getSharees = (number: string) =>
  axios
    .get<QuoteShareesList>(path(apiPaths.getSharees, { number }))
    .then(response => response.data);

export const validateTransition = (versionId: number, transition: string) =>
  axios
    .post<QuoteTransitionError[]>(path(apiPaths.validateTransition, { id: versionId }), {
      transition,
    })
    .then(response => response.data);
