import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames';
import {
  ArchiveIcon,
  Badge,
  BusinessIcon,
  EmailIcon,
  GroupsIcon,
  ListSubheader,
  LocalMallIcon,
  ManageAccountsIcon,
  PeopleIcon,
  ReceiptIcon,
  TimerOffOutlinedIcon,
  Typography,
} from '@clippings/paper';
import { NavItem } from 'libs/Components/SideNav/NavItem';
import { PermissionBasedAccess } from 'libs/Components/PermissionBasedAccess/PermissionBasedAccess';
import { SideNav } from 'libs/Components/SideNav/SideNav';
import { UserPermission } from 'libs/api/iam/types';
import { routes } from 'libs/Constants';
import { useInboxCount } from 'libs/SalesApp/Inbox';
import { useTranslation } from 'react-i18next';
import './Navigation.scss';

interface NavigationProps
  extends Omit<ComponentProps<typeof SideNav>, 'className' | 'openPerRouteConfig'> {
  className?: string;
}

const InboxNavItem: FC = () => {
  const { t } = useTranslation();
  const { count, loading } = useInboxCount();

  const countLabel = count > 99 ? '99+' : count;

  return (
    <NavItem
      icon={<EmailIcon />}
      label={t('inbox.inbox')}
      to={routes.salesApp.inbox}
      endIcon={<Badge badgeContent={loading ? '...' : countLabel} color="primary" sx={{ mr: 1 }} />}
    />
  );
};

export const Navigation: FC<NavigationProps> = ({ className = '', ...rest }) => {
  const { t } = useTranslation();

  const staffSection = (
    <>
      <ListSubheader className="navigation__list-section">
        <Typography variant="subtitle2" color="grey.500">
          {t('common.configure')}
        </Typography>
      </ListSubheader>
      <NavItem
        icon={<ManageAccountsIcon />}
        label={t('common.staffUsers')}
        to={routes.salesApp.staffUsers}
      />
      <NavItem icon={<GroupsIcon />} label={t('users.headers.teams')} to={routes.salesApp.teams} />
    </>
  );

  return (
    <SideNav
      className={classNames('navigation', className)}
      {...rest}
      openPerRouteConfig={{
        [routes.salesApp.inbox]: true,
        [routes.salesApp.quotes.active]: true,
        [routes.salesApp.quotes.archived]: true,
        [routes.salesApp.quotes.expired]: true,
        [routes.salesApp.quotes.show]: false,
      }}
    >
      <InboxNavItem />
      <ListSubheader className="navigation__list-section">
        <Typography variant="subtitle2" color="grey.500">
          {t('common.quotes')}
        </Typography>
      </ListSubheader>
      <NavItem
        icon={<ReceiptIcon />}
        label={t('common.active')}
        to={routes.salesApp.quotes.active}
      />
      <NavItem
        icon={<TimerOffOutlinedIcon />}
        label={t('common.expired')}
        to={routes.salesApp.quotes.expired}
      />
      <NavItem
        icon={<ArchiveIcon />}
        label={t('common.archived')}
        to={routes.salesApp.quotes.archived}
      />

      <ListSubheader className="navigation__list-section">
        <Typography variant="subtitle2" color="grey.500">
          {t('common.manage')}
        </Typography>
      </ListSubheader>
      <NavItem
        icon={<LocalMallIcon />}
        label={t('common.orders')}
        to={routes.salesApp.orders.list}
      />
      <NavItem icon={<PeopleIcon />} label={t('common.users')} to={routes.salesApp.users} />
      <NavItem
        icon={<BusinessIcon />}
        label={t('common.companies')}
        to={routes.salesApp.companies}
      />
      <PermissionBasedAccess
        element={staffSection}
        permission={UserPermission.CanAccessStaffUsersManagement}
      />
    </SideNav>
  );
};
