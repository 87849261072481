import qs from 'qs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const parseQuery = (search: string) => {
  return qs.parse(search.slice(1), {
    decoder: value => decodeURIComponent(value),
  }) as Record<string, string>;
};

export const useQueryParams = (): [
  Record<string, string>,
  (params: Record<string, string>) => void
] => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(parseQuery(location.search));

  useEffect(() => {
    setQueryParams(parseQuery(location.search));
  }, [location.search]);

  const setQueryParamsAndNavigate = (params: Record<string, string>) => {
    navigate(`${location.pathname}?${qs.stringify(params)}${location.hash}`);
    setQueryParams(params);
  };

  return [queryParams, setQueryParamsAndNavigate];
};
