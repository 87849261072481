import React from 'react';
import { Grid, GridProps } from '@clippings/paper';

export const Footer: React.FC<GridProps> = ({ children, ...rest }) => {
  return (
    <Grid
      display="flex"
      justifyContent="flex-end"
      flexWrap="wrap"
      alignItems="center"
      borderTop="1px solid"
      borderColor="grey.A200"
      bgcolor="grey.50"
      position="sticky"
      bottom="0"
      mt="auto"
      sx={{ zIndex: theme => theme.zIndex.drawer - 1 }}
      {...rest}
    >
      {children}
    </Grid>
  );
};
