import React, { FC } from 'react';
import { CombinedEmptyTablePlaceholder } from 'libs/Components';
import { EmptyTablePlaceholderProps } from 'libs/Components/EmptyTablePlaceholder/EmptyTablePlaceholder';
import { useTranslation } from 'react-i18next';

export type EmptyTeamsTablePlaceholderProps = {
  filtered: boolean;
  onResetClick: () => void;
  onCreateClick: () => void;
};
export const EmptyTeamsTablePlaceholder: FC<EmptyTeamsTablePlaceholderProps> = ({
  filtered,
  onResetClick,
  onCreateClick,
}) => {
  const { t } = useTranslation();

  const resetPlaceholderProps: EmptyTablePlaceholderProps = {
    onButtonClick: onResetClick,
    title: t('common.noResults'),
    buttonTitle: t('common.reset'),
    containerProps: { 'data-testid': 'empty-table-placeholder-no-teams-reset' },
  };

  const createPlaceholderProps: EmptyTablePlaceholderProps = {
    onButtonClick: onCreateClick,
    title: t('teams.noAddedTeams'),
    buttonTitle: t('teams.createTeam'),
    containerProps: { 'data-testid': 'empty-table-placeholder-no-teams' },
  };

  return (
    <CombinedEmptyTablePlaceholder
      filtered={filtered}
      resetPlaceholderProps={resetPlaceholderProps}
      createPlaceholderProps={createPlaceholderProps}
    />
  );
};
