import { Catalogue, ProductDiscount } from './types';
import { GroupedProduct, PDPOption, PDPRule, Product } from '@clippings/paper';
import { QueryOptions } from '../common/types';
import {
  getCatalogue,
  getGroupedProducts,
  getProduct,
  getProductDiscount,
  getProductOptions,
  getProductRules,
  listCatalogues,
} from './actions';
import { useQuery } from '@tanstack/react-query';

export const useListCatalogues = (options?: QueryOptions<Catalogue[]>) =>
  useQuery(['catalogues'], () => listCatalogues(), {
    ...options,
  });

export const useGetCatalogue = (catalogueSlug: string, options?: QueryOptions<Catalogue>) =>
  useQuery(['catalogues', catalogueSlug], () => getCatalogue(catalogueSlug), {
    ...options,
  });

export const useGetProduct = (
  catalogueSlug: string,
  productId: string,
  options?: QueryOptions<Product>
) =>
  useQuery(['product', catalogueSlug, productId], () => getProduct(catalogueSlug, productId), {
    ...options,
  });

export const useGetProductOptions = (
  catalogueSlug: string,
  productId: string,
  options?: QueryOptions<PDPOption[]>
) =>
  useQuery(
    ['product-options', catalogueSlug, productId],
    () => getProductOptions(catalogueSlug, productId),
    {
      ...options,
    }
  );

export const useGetProductRules = (
  catalogueSlug: string,
  productId: string,
  options?: QueryOptions<PDPRule[]>
) =>
  useQuery(['product-rules', productId], () => getProductRules(catalogueSlug, productId), {
    ...options,
  });

export const useGetProductDiscount = (
  catalogueSlug: string,
  productId: string,
  options?: QueryOptions<[ProductDiscount]>
) =>
  useQuery(
    ['product-discount', catalogueSlug, productId],
    () => getProductDiscount(catalogueSlug, productId),
    {
      ...options,
    }
  );

export const useGetGroupedProducts = (
  catalogueSlug: string,
  groupId: string | number,
  options?: QueryOptions<GroupedProduct[]>
) =>
  useQuery(
    ['grouped-products', catalogueSlug, groupId],
    () => getGroupedProducts(catalogueSlug, groupId),
    {
      ...options,
    }
  );
