import React from 'react';
import { Box } from '@clippings/paper';
import { PropsWithChildren } from 'react';

export function DiscountsDialogContentBox({ children }: PropsWithChildren) {
  return (
    <Box display="grid" gridTemplateColumns="1fr 2fr" gap={4}>
      {children}
    </Box>
  );
}
