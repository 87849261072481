import React from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeComponents } from 'libs/api/payment/types';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

interface CardFormProps {
  populateStripeComponents: (components: StripeComponents) => void;
  onChange: (event: StripePaymentElementChangeEvent) => void;
}

export const CardForm: React.FC<CardFormProps> = ({ populateStripeComponents, onChange }) => {
  populateStripeComponents({ stripe: useStripe(), elements: useElements() });
  return <PaymentElement onChange={onChange} />;
};
