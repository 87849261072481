import { Location } from 'libs/api/location/types';
import { Path, PathValue, UseFormReturn } from '@clippings/paper';
import { useEffect } from 'react';

export const useAddressDefaultCountry = <T extends { country: string }>(
  countries: Location[],
  form: UseFormReturn<T, any>
) => {
  const selectedCountry = form.watch('country' as Path<T>);

  useEffect(() => {
    const defaultCountry = countries.find(x => x.default)?.shortName;

    if (defaultCountry && !selectedCountry) {
      form.setValue('country' as Path<T>, defaultCountry as PathValue<T, Path<T>>);
    }
  }, [countries]);
};
