import React, { ComponentProps, FC } from 'react';
import { AlertSnackbar, ArrowBackIcon, CloseableDialog, PersonAddIcon } from '@clippings/paper';
import { DialogTitle } from 'libs/Components/Modal/DialogTitle/DialogTitle';
import { ListScreen } from './screens/ListScreen';
import {
  QUOTE_SHARE_MODAL_INITIAL_SCREEN,
  QuoteShareModalScreen,
  QuoteShareModalScreenProps,
} from './useQuoteShareUtils';
import { Quote } from 'libs/api/quotes/types';
import { ShareScreen } from './screens/ShareScreen';
import { useQuoteShare } from './QuoteShareProvider';
import { useTranslation } from 'react-i18next';

const modalTitleIconMap: Record<QuoteShareModalScreen, FC<ComponentProps<typeof PersonAddIcon>>> = {
  [QuoteShareModalScreen.LIST]: PersonAddIcon,
  [QuoteShareModalScreen.SHARE]: ArrowBackIcon,
};

const modalScreenMap: Record<QuoteShareModalScreen, FC<QuoteShareModalScreenProps>> = {
  [QuoteShareModalScreen.LIST]: ListScreen,
  [QuoteShareModalScreen.SHARE]: ShareScreen,
};

export type ShareModalProps = {
  quote: Quote;
};

export const ShareModal: FC<ShareModalProps> = ({ quote }) => {
  const { t } = useTranslation();
  const {
    modal: { shareModalOpen, screen, returnHandler, modalCloseHandler },
    snackbarProps,
  } = useQuoteShare();

  const ModalTitleIconComponent = modalTitleIconMap[screen];

  const iconClickHandler = () => {
    if (screen === QuoteShareModalScreen.SHARE) {
      returnHandler();
    }
  };

  const Screen = modalScreenMap[screen];

  return (
    <CloseableDialog
      open={shareModalOpen}
      onClose={modalCloseHandler}
      title={
        <DialogTitle
          title={t('quotes.share.shareQuote')}
          avatarProps={{
            onClick: iconClickHandler,
            sx: {
              cursor: screen === QUOTE_SHARE_MODAL_INITIAL_SCREEN ? 'default' : 'pointer',
            },
          }}
          iconComponent={<ModalTitleIconComponent color="secondary" fontSize="small" />}
        />
      }
      dialogProps={{
        PaperProps: {
          sx: {
            width: '600px',
          },
        },
      }}
    >
      <Screen quote={quote} />
      <AlertSnackbar {...snackbarProps} />
    </CloseableDialog>
  );
};
