import {
  Address,
  BillingAddress,
  DeliveryHoursDate,
  DeliveryHoursEpoch,
  FomcoreShippingAddressMetadata,
  ShippingAddress,
} from './types';
import { Confirmation } from '@clippings/paper';
import { format } from 'date-fns';

export const timeFormat24 = 'HH:mm';
export const timeFormatAmPm = 'hh:mm aa';
export const epochToTime = (epoch: number) => format(new Date(epoch), timeFormatAmPm);

export const isShippingAddress = (address: Address): address is ShippingAddress =>
  !('email' in address);
export const isBillingAddress = (address: Address): address is BillingAddress => 'email' in address;
export const isFomcoreShippingMetadata = (
  metadata: ShippingAddress['metadata']
): metadata is FomcoreShippingAddressMetadata => 'dock' in metadata;

export const parseDeliveryHoursEpochToDate = ([
  from,
  to,
]: DeliveryHoursEpoch): DeliveryHoursDate => [new Date(from), new Date(to)];
export const parseDeliveryHourDateToEpoch = ([from, to]: DeliveryHoursDate): DeliveryHoursEpoch => [
  from.getTime(),
  to.getTime(),
];

export const valueToYesNoTranslation = (value?: boolean | Confirmation) =>
  value === Confirmation.Yes || value === true ? 'common.yes' : 'common.no';
