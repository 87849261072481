import React, { ComponentProps } from 'react';
import { Chip } from '@clippings/paper';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { quoteStatusMap } from 'libs/Constants';
import { useTranslation } from 'react-i18next';

type StatusChipProps = {
  status: QuoteListItemStatus;
} & ComponentProps<typeof Chip>;

export const StatusChip: React.FC<StatusChipProps> = ({ status, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t(quoteStatusMap[status].labelKey)}
      color={quoteStatusMap[status].color}
      {...rest}
    />
  );
};
