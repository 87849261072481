import { Paginated } from 'libs/api/common/types';
import { QUOTES_PAGE_STATUS_MAP, QuotesPage } from '../../SalesApp/Quotes/types';
import { QuoteListItem, QuoteListItemStatus } from 'libs/api/quotes/types';
import { axios } from 'libs/Utils';

export const apiPaths = {
  index: '/api/quote/v1/quotes',
};

type Params = {
  pageParam: number | null;
};

export const getActiveQuotes = ({ pageParam = null }: Params) =>
  getQuotes({ status: QUOTES_PAGE_STATUS_MAP[QuotesPage.Active], pageParam });

export const getInactiveQuotes = ({ pageParam = null }: Params) =>
  getQuotes({ active: false, pageParam });

const getQuotes = ({
  active,
  status,
  pageParam,
}: {
  active?: boolean;
  status?: QuoteListItemStatus[];
  pageParam: number | null;
}) =>
  axios
    .get<Paginated<QuoteListItem>>(apiPaths.index, {
      params: { active, status: status?.join(','), page: pageParam },
    })
    .then(
      response => response.data,
      e => Promise.reject(e)
    );
