import React from 'react';
import classNames from 'classnames';
import { CellPercentageInput, TableCell } from '@clippings/paper';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { TableCellCommonProps, inputProps } from './tableCells.utils';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';

type TaxRateTableCellProps = {
  item: QuoteProductItem;
  handleUpdate: UpdateQuoteByKey;
} & Pick<TableCellCommonProps, 'readonly'>;

export function TaxRateTableCell({ item, readonly, handleUpdate }: TaxRateTableCellProps) {
  const handleTaxPercentChange = (value: number) => {
    handleUpdate('taxPercent', value, item.id);
  };

  const value = item.taxPercent ?? 0;

  return (
    <TableCell
      data-testid={`quote-item-tax-percentage-${item.id}`}
      className={classNames('quote-item-row__cell', 'quote-item-row__cell__tax-rate')}
    >
      <CellPercentageInput
        disabled={readonly}
        inputProps={{ ...inputProps, 'data-testid': `tax-percent-${item.id}` }}
        onBlur={handleTaxPercentChange}
        value={value}
      />
    </TableCell>
  );
}
