import React, { ComponentProps, Fragment, useMemo } from 'react';
import { AppConfiguration } from 'libs/api/configuration/types';
import { TableCell } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

const cellProps: ComponentProps<typeof TableCell>['sx'] = {
  fontSize: theme => theme.typography.pxToRem(14),
  color: theme => theme.palette.secondary.main,
  fontWeight: 600,
  lineHeight: 1.2,
};

export function useQuoteTableHeadCells() {
  const { t } = useTranslation();

  const tableHeadCellsMap: Record<AppConfiguration['lineItemVisibleFields'][number], JSX.Element> =
    useMemo(
      () => ({
        order: (
          <TableCell
            align="center"
            key="quote-table-head-cell-order"
            data-testid="quote-table-head-cell-order"
            sx={{ ...cellProps, width: '80px' }}
          >
            {t('quotes.table.order')}
          </TableCell>
        ),
        info: (
          <Fragment key="quote-table-head-cell-info">
            <TableCell align="center" data-testid="quote-table-head-cell-info">
              {t('quotes.table.items')}
            </TableCell>
            <TableCell />
          </Fragment>
        ),
        leadTime: (
          <TableCell
            key="quote-table-head-cell-leadTime"
            sx={{ ...cellProps, width: '100px' }}
            data-testid="quote-table-head-cell-leadTime"
          >
            {t('quotes.table.leadTime')}
          </TableCell>
        ),
        price: (
          <TableCell
            key="quote-table-head-cell-price"
            sx={{ ...cellProps, width: '120px' }}
            data-testid="quote-table-head-cell-price"
            align="right"
          >
            {t('quotes.table.price')}
          </TableCell>
        ),
        discount: (
          <TableCell
            key="quote-table-head-cell-discount"
            sx={{ ...cellProps, width: '80px' }}
            data-testid="quote-table-head-cell-discount"
            align="right"
          >
            {t('quotes.table.discount')}
          </TableCell>
        ),
        net: (
          <TableCell
            key="quote-table-head-cell-net"
            sx={{ ...cellProps, width: '120px' }}
            data-testid="quote-table-head-cell-net"
            align="right"
          >
            {t('quotes.table.net')}
          </TableCell>
        ),
        quantity: (
          <TableCell
            key="quote-table-head-cell-quantity"
            sx={{ ...cellProps, width: '80px' }}
            data-testid="quote-table-head-cell-quantity"
            align="right"
          >
            {t('quotes.table.quantity')}
          </TableCell>
        ),
        taxRate: (
          <TableCell
            key="quote-table-head-cell-taxRate"
            sx={{ ...cellProps, width: '80px' }}
            data-testid="quote-table-head-cell-taxRate"
            align="right"
          >
            {t('quotes.table.taxRate')}
          </TableCell>
        ),
        subTotal: (
          <TableCell
            key="quote-table-head-cell-subTotal"
            sx={{ ...cellProps, width: '120px' }}
            data-testid="quote-table-head-cell-subTotal"
            align="right"
          >
            {t('quotes.table.subTotal')}
          </TableCell>
        ),
        contractNetPrice: (
          <TableCell
            key="quote-table-head-cell-contractNetPrice"
            sx={{ ...cellProps, minWidth: '90px' }}
            data-testid="quote-table-head-cell-contractNetPrice"
            className="quote-table-head-cell-contractNetPrice"
            align="right"
          >
            {t('quotes.table.contractNetPrice')}
          </TableCell>
        ),
        contractListPrice: (
          <TableCell
            key="quote-table-head-cell-contractListPrice"
            sx={{ ...cellProps, minWidth: '90px' }}
            data-testid="quote-table-head-cell-contractListPrice"
            className="quote-table-head-cell-contractListPrice"
            align="right"
          >
            {t('quotes.table.contractListPrice')}
          </TableCell>
        ),
        contractSubTotalPrice: (
          <TableCell
            key="quote-table-head-cell-contractSubTotalPrice"
            sx={{ ...cellProps, minWidth: '90px' }}
            data-testid="quote-table-head-cell-contractSubTotalPrice"
            className="quote-table-head-cell-contractSubTotalPrice"
            align="right"
          >
            {t('quotes.table.contractSubTotalPrice')}
          </TableCell>
        ),
      }),
      []
    );

  return { tableHeadCellsMap };
}
