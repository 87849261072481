import React, { MutableRefObject } from 'react';
import { Box, Button, useTranslation } from '@clippings/paper';
import { useReactToPrint } from 'react-to-print';

type SaveAdPdfButtonProps = {
  pageRef: MutableRefObject<null>;
  documentTitle: string;
};

export function SaveAsPdfButton({ pageRef, documentTitle }: SaveAdPdfButtonProps) {
  const { t } = useTranslation();
  const saveAsPdfClickHandler = useReactToPrint({
    content: () => pageRef.current,
    documentTitle,
  });

  return (
    <Box displayPrint="none">
      <Button variant="contained" onClick={saveAsPdfClickHandler} sx={buttonSx}>
        {t('quotes.pdf.saveButton')}
      </Button>
    </Box>
  );
}

const buttonSx = {
  position: 'absolute',
  top: '1em',
  right: '2em',
};
