import React from 'react';
import {
  DateTime,
  DateTimeFormat,
  DetailedUserAvatar,
  Grid,
  Monetary,
  Typography,
  getUserFullName,
} from '@clippings/paper';
import { ListTableRow } from 'libs/Components/ListTable/ListTableRow';
import { OrderItem } from 'libs/api/order/types';
import { OrderStatusChip } from 'libs/Orders/components/OrderStatusChip';
import { getCompanyNameWithCity } from 'libs/shared';
import { getOrderIdLabel } from 'libs/Orders';
import { useNavigate } from 'react-router-dom';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';

export interface OrdersListItemProps {
  order: OrderItem;
}

export const OrdersListItem: React.FC<OrdersListItemProps> = ({ order }) => {
  const navigate = useNavigate();
  const { getOrderShowPath } = useOrderPaths();
  const companyName = getCompanyNameWithCity(
    order.customer.company?.name,
    order.customer.company?.billingAddresses
  );

  const clickHandler = () => {
    navigate(getOrderShowPath(order.number));
  };

  return (
    <ListTableRow
      columns={7}
      data-testid={`sa-order-table-items-${order.number}`}
      onClick={clickHandler}
    >
      {/* order # */}
      <Grid item xs={1} data-testid={`sa-order-table-item-number-${order.number}`}>
        <Typography
          data-testid={`sa-order-table-item-number-cell-${order.number}`}
          variant="body2"
          fontWeight="fontWeightMedium"
        >
          {getOrderIdLabel(order.vendorId)}
        </Typography>
        <Typography
          data-testid={`sa-order-table-item-cell-label-${order.number}`}
          variant="caption"
          color="secondary"
          component="div"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {order.label}
        </Typography>
        <Typography
          data-testid={`sa-order-table-item-cell-project-name-${order.number}`}
          variant="caption"
          color="secondary"
          component="div"
        >
          {order.projectName}
        </Typography>
      </Grid>
      <Grid item xs={1} data-testid={`sa-order-table-quote-number-${order.number}`}>
        <Typography
          data-testid={`sa-order-table-quote-number-cell-${order.number}`}
          variant="body2"
          fontWeight="fontWeightMedium"
        >
          {order.number}
        </Typography>
      </Grid>
      {/* customer */}
      <Grid item xs={1} data-testid={`sa-order-table-item-customer-${order.number}`}>
        <Typography
          data-testid={`sa-order-table-item-cell-customer-${order.number}`}
          variant="body2"
          fontWeight="fontWeightMedium"
        >
          {getUserFullName(order.customer)}
        </Typography>
        <Typography
          data-testid={`sa-order-table-item-cell-company-name-${order.number}`}
          variant="caption"
          color="secondary"
        >
          {companyName}
        </Typography>
      </Grid>
      {/* value */}
      <Grid item xs={1} data-testid={`sa-order-table-item-value-${order.number}`}>
        <Monetary
          variant="body2"
          fontWeight="fontWeightMedium"
          amount={order.total.amount}
          currency={order.total.currency}
          data-testid={`sales-order-item-value-${order.number}`}
        />
      </Grid>
      {/* order placed */}
      <Grid item xs={1} data-testid={`sa-order-table-item-date-${order.number}`}>
        <DateTime
          format={DateTimeFormat.Date}
          date={order.createdAt}
          data-testid={`sales-order-item-created-${order.number}`}
          variant="body2"
          fontWeight="fontWeightMedium"
        />
      </Grid>
      {/* status */}
      <Grid item xs={1}>
        <OrderStatusChip
          status={order.status}
          data-testid={`sa-order-table-item-status-${order.number}`}
        />
      </Grid>
      {/* assignee */}
      <Grid
        item
        xs={1}
        data-testid={`sa-order-table-item-assignee-${order.number}`}
        sx={{ '& .MuiChip-root': { border: 0 } }}
      >
        {order.salesRepresentative ? (
          <DetailedUserAvatar
            user={order.salesRepresentative}
            title={getUserFullName(order.salesRepresentative)}
            size="small"
          />
        ) : null}
      </Grid>
    </ListTableRow>
  );
};
