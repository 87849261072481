import React from 'react';
import { List, ListProps, MenuItem, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export interface AutocompleteListBoxProps extends ListProps {
  loading: boolean;
}

export const AutocompleteListBox = React.forwardRef<HTMLUListElement, AutocompleteListBoxProps>(
  ({ children, loading, ...params }, ref) => {
    const { t } = useTranslation();

    return (
      <List {...params} ref={ref} role="list-box">
        {children}
        {loading && (
          <MenuItem>
            <Typography color="secondary">{t('common.loading')}</Typography>
          </MenuItem>
        )}
      </List>
    );
  }
);
