import React from 'react';
import { CustomDeliveryDateFormData } from './CustomDeliveryDateForm';
import { CustomDeliveryDateModal } from './CustomDeliveryDateModal';
import { CustomDeliveryDateModel, Quote } from 'libs/api/quotes/types';
import { DateTimeFormat, LoadingButton, Typography, useFormatDateTime } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { QuoteUpdateOptions } from '../quote.types';
import { Row } from 'libs/shared';
import { useCustomDeliveryDateModal } from './useCustomDeliveryDateModal';
import { useTranslation } from 'react-i18next';

export type CustomDeliveryDateTriggerProps = {
  modalTitle?: string;
  customDeliveryDateModel: Nullable<CustomDeliveryDateModel>;
  readonly?: boolean;
  isLoading?: boolean;
  quoteMetadata?: Quote['metadata'];
  onUpdate?: (newQuote: Partial<Quote>, options?: QuoteUpdateOptions) => void;
};

export function CustomDeliveryDateTrigger({
  modalTitle = '',
  customDeliveryDateModel,
  readonly = false,
  isLoading = false,
  quoteMetadata = {},
  onUpdate,
}: CustomDeliveryDateTriggerProps) {
  const { t } = useTranslation();
  const { isOpen, openModalHandler, closeModalHandler } = useCustomDeliveryDateModal();

  if (readonly) {
    return customDeliveryDateModel?.date ? (
      <ActionTitle customDeliveryDateModel={customDeliveryDateModel} />
    ) : null;
  }

  function submitHandler({ type, date }: CustomDeliveryDateFormData) {
    onUpdate?.(
      {
        metadata: {
          ...quoteMetadata,
          customDeliveryDate: {
            type,
            date,
          },
        },
      },
      { withOptimisticUpdate: false }
    );

    closeModalHandler();
  }

  const defaultValues: CustomDeliveryDateModel = {
    type: customDeliveryDateModel?.type ?? 'arriveBy',
    date: customDeliveryDateModel?.date ? new Date(customDeliveryDateModel.date) : new Date(),
  };

  const buttonText = customDeliveryDateModel?.date ? t('common.change') : modalTitle;

  return (
    <>
      <Row alignItems="center" gap={1}>
        {customDeliveryDateModel?.date ? (
          <ActionTitle customDeliveryDateModel={customDeliveryDateModel} />
        ) : null}
        <LoadingButton
          variant="text"
          onClick={openModalHandler}
          loading={isLoading}
          loadingPosition="end"
          endIcon={<PlaceholderIcon />}
          data-testid="custom-delivery-date-button"
          sx={styles.sx}
        >
          {buttonText}
        </LoadingButton>
      </Row>
      <CustomDeliveryDateModal
        isOpen={isOpen}
        defaultValues={defaultValues}
        title={modalTitle}
        isLoading={isLoading}
        onClose={closeModalHandler}
        onSubmit={submitHandler}
      />
    </>
  );
}

const styles = {
  sx: {
    m: 0,
    p: 0,
    pr: 3,
  },
};

function PlaceholderIcon() {
  return null;
}

type ActionTitleProps = {
  customDeliveryDateModel: CustomDeliveryDateModel;
};

function ActionTitle({ customDeliveryDateModel }: ActionTitleProps) {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const { date, type } = customDeliveryDateModel;

  return (
    <Typography data-testid="custom-delivery-date-title" variant="caption">
      {t('common.requested')} {t('common.to')}{' '}
      <Typography
        data-testid="custom-delivery-date-value"
        component="span"
        variant="caption"
        fontWeight="bold"
      >
        {t(`common.${type}`)} {formatDateTime(new Date(date), DateTimeFormat.Date)}
      </Typography>
    </Typography>
  );
}
