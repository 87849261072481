import React, { useMemo } from 'react';
import { CellConfig, SortableTable, SortingProps } from 'libs/shared';
import { DataTestId } from '@clippings/paper';
import { SortDirection } from 'libs/api/common/types';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { User } from 'libs/api/iam/types';
import { UserListItem } from './UserListItem';
import { useTranslation } from 'react-i18next';

export interface UserListBlockProps extends DataTestId {
  query: UseInfiniteQueryResult<User[]>;
  hideCompanyColumn: boolean;
  placeholder?: React.ReactNode;
  sort: SortingProps;
  onSort: (sort: { sort?: string; order?: SortDirection }) => void;
}

export const UserListBlock: React.FC<UserListBlockProps> = ({
  query,
  hideCompanyColumn,
  placeholder,
  sort,
  'data-testid': dataTestId,
  onSort,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = query;
  const users = React.useMemo(() => {
    return (data?.pages ?? []).flat();
  }, [data]);

  const isEmpty = !users.length && !isLoading;

  const cellConfigFiltered = useMemo(() => {
    return cellConfig.filter(cell => {
      if (hideCompanyColumn) {
        return cell.columnName !== 'company';
      }

      return true;
    });
  }, [hideCompanyColumn]);

  if (isEmpty) {
    return placeholder ? <>{placeholder}</> : null;
  }

  return (
    <SortableTable
      cellConfig={cellConfigFiltered}
      hasNextPage={!!hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      noMoreLabel={t('users.noMoreUsers')}
      onFetchNextPage={fetchNextPage}
      sort={sort}
      tableHeaderColumns={hideCompanyColumn ? 15 : 18}
      data-testid={dataTestId}
      onSort={onSort}
    >
      {users.map(user => (
        <UserListItem hideCompanyColumn={hideCompanyColumn} key={user.id} user={user} />
      ))}
    </SortableTable>
  );
};

export const cellConfig: CellConfig[] = [
  {
    key: 'user-list-table-heading-name',
    columnName: 'name',
    label: 'common.name',
    columns: 3,
  },
  {
    key: 'user-list-table-heading-company',
    columnName: 'company',
    label: 'common.company',
    columns: 3,
  },
  {
    key: 'user-list-table-heading-contacts',
    columnName: 'contacts',
    label: 'common.contacts',
    columns: 5,
  },
  {
    key: 'user-list-table-heading-createdOn',
    columnName: 'createdOn',
    label: 'common.createdOn',
    columns: 2,
  },
  {
    key: 'user-list-table-heading-activity',
    columnName: 'activity',
    label: 'common.activity',
    columns: 2,
  },
  {
    key: 'user-list-table-heading-status',
    columnName: 'status',
    label: 'common.status',
    columns: 2,
  },
];
