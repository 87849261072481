import React, { ComponentType } from 'react';
import { AppProps, bootstrap } from './bootstrap';
import { Nullable } from 'libs/Utils';
import { createRoot } from 'react-dom/client';
import 'libs/Utils/i18n';

export const init = async (...props: Parameters<typeof bootstrapApplication>) => {
  try {
    bootstrapApplication(...props);
  } catch (e) {
    console.error(e);
    window.location.replace(`${window.location.origin}/error`);
  }
};

async function bootstrapApplication(
  element: Nullable<Element>,
  AppComponent: ComponentType<AppProps>
) {
  if (!element) {
    return;
  }

  const { configuration, chatClient, searchProviderConfig, paymentProviderInstance } =
    await bootstrap();

  createRoot(element).render(
    <AppComponent
      configuration={configuration}
      chatClient={chatClient}
      paymentProviderInstance={paymentProviderInstance}
      searchProviderConfig={searchProviderConfig}
    />
  );
}
