import React, { ComponentProps } from 'react';
import { Brand } from '@clippings/paper';
import { CustomDeliveryDateTrigger } from './CustomDeliveryDateTrigger';
import { useAppConfiguration } from 'libs/providers';

type CustomDeliveryDateProps = ComponentProps<typeof CustomDeliveryDateTrigger>;

export function CustomDeliveryDate({
  modalTitle,
  customDeliveryDateModel,
  readonly = false,
  isLoading = false,
  quoteMetadata,
  onUpdate,
}: CustomDeliveryDateProps) {
  const { instance } = useAppConfiguration();

  if (instance === Brand.Fomcore) {
    return (
      <CustomDeliveryDateTrigger
        modalTitle={modalTitle}
        customDeliveryDateModel={customDeliveryDateModel}
        readonly={readonly}
        isLoading={isLoading}
        quoteMetadata={quoteMetadata}
        onUpdate={onUpdate}
      />
    );
  }

  return null;
}
