import React from 'react';
import { InfoCellItemImage } from './InfoCellItemImage';
import { InfoCellItemRenderer, InfoCellItemRendererProps } from './InfoCellItemRenderer';
import { Note, useNoteData } from 'libs/notes';
import { TableCellWithMode } from '../TableCellWithMode';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';
import { buildVariationName } from 'libs/shared';
import { useTranslation } from 'react-i18next';

type InfoTableCellProps = {
  onMakeCustom: (variationName: string, itemId: number) => void;
  onUpdate: UpdateQuoteByKey;
  onNoteSave?: UpdateQuoteByKey;
} & Omit<InfoCellItemRendererProps, 'mode' | 'handleMakeCustom'>;

export function InfoTableCell({
  item,
  readonly,
  loading,
  detailsHidden,
  shouldIncludePropertyCode,
  onMakeCustom,
  onUpdate,
  onConfigure,
  onProductDetailsHide,
  handleUpdate,
  onNoteSave,
}: InfoTableCellProps) {
  const { noteSaveHandler } = useNoteData(item.id, onNoteSave);
  const { t } = useTranslation();

  const handleMakeCustom = () => {
    onMakeCustom(buildVariationName(item), item.id);
  };

  return (
    <>
      <InfoCellItemImage item={item} loading={loading} readonly={readonly} onUpdate={onUpdate} />
      <TableCellWithMode
        active={!!item.custom}
        data-testid={`quote-item-variation-${item.id}`}
        sx={styles.itemInfoCellSx}
      >
        {({ mode }) => (
          <>
            <InfoCellItemRenderer
              item={item}
              detailsHidden={detailsHidden}
              loading={loading}
              mode={mode}
              readonly={readonly}
              shouldIncludePropertyCode={shouldIncludePropertyCode}
              handleMakeCustom={handleMakeCustom}
              handleUpdate={handleUpdate}
              onConfigure={onConfigure}
              onProductDetailsHide={onProductDetailsHide}
            />

            <Note
              value={item.note ?? ''}
              selectorSuffix="quote-item"
              withDivider={item.custom || item.sample}
              readonly={readonly}
              placeholder={item.noteRequired ? t('quotes.noteRequiredEmb') : undefined}
              required={item.noteRequired}
              onSave={noteSaveHandler}
            />
          </>
        )}
      </TableCellWithMode>
    </>
  );
}

const styles = {
  itemInfoCellSx: {
    minWidth: '350px !important',
  },
};
