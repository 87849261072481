import React from 'react';
import { Box, SystemUser, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';
import { userDialogSettingsMap } from '../../utils/user.utils';

type UserDialogTitleProps = {
  userType: SystemUser;
  isEditMode: boolean;
};

export function UserDialogTitle({ userType, isEditMode }: UserDialogTitleProps) {
  const { t } = useTranslation();

  const UserIcon = userDialogSettingsMap[userType].icon;
  const title = isEditMode
    ? t(userDialogSettingsMap[userType].editTranslation)
    : t(userDialogSettingsMap[userType].createTranslation);

  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <Box padding={1} borderRadius="50%" bgcolor="grey.A200" display="flex">
          <UserIcon color="secondary" />
        </Box>
        {title}
      </Box>
      {!isEditMode && (
        <Typography
          data-testid="customer-dialog-email-notification"
          variant="body2"
          color="secondary"
          mt={2}
        >
          {t(userDialogSettingsMap[userType].emailTranslation)}
        </Typography>
      )}
    </Box>
  );
}
