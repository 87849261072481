import { QuoteListItem } from 'libs/api/quotes/types';
import { useState } from 'react';

export function useLoadingQuotes() {
  const [loadingQuotes, setLoadingQuotes] = useState<{
    top: QuoteListItem['number'][];
    inline: QuoteListItem['number'][];
  }>({ top: [], inline: [] });

  return { loadingQuotes, setLoadingQuotes };
}
