import React, { ComponentProps, PropsWithChildren } from 'react';
import {
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FieldValues,
  IconButton,
  useIsMobile,
} from '@clippings/paper';
import { FormModalActions } from './FormModalActions';
import { FormWrapper, FormWrapperProps } from 'libs/shared';
import { Nullable } from 'libs/Utils';

export type FormModalProps<T extends FieldValues> = {
  formProps: Nullable<FormWrapperProps<T>>;
  onClose: () => void;
  actionProps: Nullable<{
    onCancel?: () => void;
    cancelLabel?: string;
    submitLabel: string;
  }>;
  formKey?: string;
  submitProps?: {
    disabled?: boolean;
    'data-testid': string;
  };
  dialogProps?: Omit<DialogProps, 'open'> & { open?: boolean };
  isSubmitting?: boolean;
  title?: JSX.Element | string;
} & Omit<ComponentProps<typeof Dialog>, 'title'>;

export const FormModal = <T extends FieldValues>({
  open,
  onClose,
  formProps,
  actionProps,
  title,
  children,
  dialogProps,
  formKey = '',
  submitProps = { 'data-testid': 'form-submit' },
  isSubmitting = false,
  ...rest
}: PropsWithChildren<FormModalProps<T>>) => {
  const isMobile = useIsMobile();

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile} {...dialogProps} {...rest}>
      <DialogTitle component={Box} sx={styles.dialogTitle}>
        {title}
        <IconButton className="form-modal__close" aria-label="close" onClick={onClose}>
          <CloseIcon data-testid="form-close" />
        </IconButton>
      </DialogTitle>
      {formProps ? (
        <FormWrapper {...formProps} key={formKey}>
          <DialogContent
            sx={{ minWidth: theme => ({ xs: 'auto', md: theme.typography.pxToRem(450) }) }}
          >
            {children}
          </DialogContent>
          <FormModalActions
            actionProps={actionProps}
            submitProps={submitProps}
            isSubmitting={isSubmitting}
          />
        </FormWrapper>
      ) : null}
    </Dialog>
  );
};

const styles = {
  dialogTitle: {
    paddingTop: 3,
    paddingBottom: 0.5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
};
