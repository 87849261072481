import React, { useState } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { ProductCatalog } from './components';
import { ProductHit } from 'libs/Quotes/quote.types';
import { ProductOrder } from '../common/types';
import { useSearchProvider } from 'libs/providers';

export interface ProductListingPageProps {
  catalogueSlug: string;
  onAddProduct?: (product: ProductHit) => void;
  onConfigureProduct?: (product: ProductHit) => void;
  onClose?: () => void;
}

export const ProductListingPage: React.FC<ProductListingPageProps> = ({
  catalogueSlug,
  onAddProduct,
  onConfigureProduct,
  onClose,
}) => {
  const { searchClient, productIndexes } = useSearchProvider();
  const [productOrder, setProductOrder] = useState<ProductOrder>('mostRelevant');

  return (
    <InstantSearch searchClient={searchClient} indexName={productIndexes[productOrder]}>
      <ProductCatalog
        catalogueSlug={catalogueSlug}
        productOrder={productOrder}
        onProductOrderChange={setProductOrder}
        onAddProduct={onAddProduct}
        onConfigureProduct={onConfigureProduct}
        onClose={onClose}
      />
    </InstantSearch>
  );
};
