import React, { useEffect } from 'react';
import { Alert } from '@clippings/paper';
import { useDispatch, useSelector } from 'react-redux';
import './AlertBanner.scss';

interface BannerProps {
  message: React.ReactNode;
  type: 'success' | 'error' | 'info' | 'warning';
  isOpen: boolean;
}

export const AlertBanner: React.FC = () => {
  const dispatch = useDispatch();
  const { message, type, isOpen } = useSelector<{ banner: BannerProps }, BannerProps>(
    state => state.banner
  );

  useEffect(() => {
    let timeId: any;

    if (isOpen) {
      timeId = setTimeout(() => {
        dispatch({ type: 'BANNER_HIDE' });
      }, 3000);
    }

    return () => {
      clearTimeout(timeId);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Alert severity={type} className="alert-banner" data-testid={`alert-${type}`}>
      {message}
    </Alert>
  );
};
