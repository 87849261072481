import React, { useState } from 'react';
import { AddItemSearchTermProvider } from 'libs/Quotes/providers';
import { AlertSnackbar, Box, Brand, Divider, Grid, Mask } from '@clippings/paper';
import { BillingDialog } from 'libs/Quotes/QuoteAddresses/Billing/BillingDialog';
import { CatalogsProvider } from 'libs/catalogs';
import { Chat, ChatChannelProvider, ChatVisibilityProvider } from 'libs/Chat';
import { Column, FooterSticky, getActiveQuoteVersion, getSelectedQuoteVersion } from 'libs/shared';
import { CreateCustomerUserFromQuoteDialog } from './components/CreateCustomerUserFromQuoteDialog';
import { DetailedQuoteActionsStack } from './components/detailedQuoteActionsStack/DetailedQuoteActionsStack';
import { ProductConfiguration, QuoteDetailsBackButton } from './components';
import { ProductConfigurationProvider, QuoteDetailsProvider } from './providers';
import { QuoteDocuments } from 'libs/Quotes/Components/QuoteDocuments/QuoteDocuments';
import { QuoteFooterActionButtons } from './QuoteFooterActionButtons';
import { QuoteFooterDetails } from 'libs/Quotes/QuotesFooter/QuoteFooterDetails';
import { QuoteHeader } from 'libs/Quotes/QuoteHeader';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { QuoteNotes } from './quoteNotes';
import { QuoteShareProvider } from 'libs/Quotes/Components/ShareModal/QuoteShareProvider';
import { QuoteSummary } from './components/QuoteSummary';
import { QuoteTable } from './quoteTable';
import { ShippingDialog } from 'libs/Quotes/QuoteAddresses/Shipping/ShippingDialog';
import { isNewQuote } from './quoteUtils';
import { useAlertSnackbar } from 'libs/hooks/useAlertSnackbar';
import { useAppConfiguration } from 'libs/providers';
import { useQuote, useQuoteQuery, useQuoteUtils } from 'libs/Quotes/hooks';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';
import 'libs/Quotes/quoteView/QuoteView.scss';

export const maskedQuoteStatuses = [
  QuoteListItemStatus.Archived,
  QuoteListItemStatus.Expired,
  QuoteListItemStatus.Ordered,
];

export const QuoteDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { instance } = useAppConfiguration();
  const { showError, showSuccessBanner, number, version, setVersion } = useQuoteUtils();

  const { props: snackbarProps, handleShowSnackbar } = useAlertSnackbar();
  const modalHandlers = useQuote();
  const { isShippingModalOpen, isBillingModalOpen } = modalHandlers;

  const {
    query: { data: quote, isFetching, isError },
    queryKey,
  } = useQuoteQuery(number, version!);

  useTitle(`${t('common.quote')} ${quote.name || ''} #${number.toUpperCase()}`);

  const [isOpenUserDialog, setIsOpenUserDialog] = useState(false);
  const [addItemInputValue, setAddInputValue] = useState('');

  const isNew = isNewQuote(quote);
  const isMaskActive = isNew || maskedQuoteStatuses.includes(quote.status);
  const isReadonly = !quote.editable;

  if (isError) {
    return null;
  }

  const quoteActiveVersion = getActiveQuoteVersion(quote?.versions ?? []);
  const quoteSelectedVersion = getSelectedQuoteVersion(quote?.versions ?? [], quote.versionNumber);
  const isActiveVersionLatestVersion = quoteActiveVersion?.id === quoteSelectedVersion?.id;

  return (
    <CatalogsProvider>
      <QuoteDetailsProvider
        isFetching={isFetching}
        queryKey={queryKey}
        quote={quote}
        setVersion={setVersion}
        showError={showError}
        showSuccessBanner={showSuccessBanner}
        modalHandlers={modalHandlers}
      >
        <QuoteShareProvider quote={quote}>
          <ProductConfigurationProvider quote={quote}>
            <ChatChannelProvider number={number}>
              <AddItemSearchTermProvider value={addItemInputValue} setValue={setAddInputValue}>
                <Box className="quote-view" sx={{ mx: 4 }}>
                  <QuoteDetailsBackButton
                    quoteSelectedVersion={quoteSelectedVersion}
                    quoteActiveVersion={quoteActiveVersion}
                  />
                  <Divider />
                  <ChatVisibilityProvider>
                    <QuoteHeader
                      withXmlImport={instance === Brand.Fomcore && isActiveVersionLatestVersion}
                    />
                    <Column gap={2}>
                      <DetailedQuoteActionsStack
                        onNewCustomerClick={() => setIsOpenUserDialog(true)}
                      />

                      <Mask
                        data-testid="quote-details-tables-mask"
                        active={isMaskActive}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                      >
                        <Grid container spacing={3} mb={2}>
                          <Grid item xs={12}>
                            <QuoteTable />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                          >
                            <QuoteNotes
                              loading={isFetching}
                              readonly={isReadonly}
                              title={t('quotes.teamNotes')}
                              subTitle={t('quotes.internalUseOnly')}
                              type="note"
                            />
                            <QuoteDocuments
                              loading={isFetching}
                              readonly={isReadonly}
                              sx={{ mt: 0 }}
                            />
                            <QuoteNotes
                              loading={isFetching}
                              readonly={isReadonly}
                              inputProps={{
                                'data-testid': 'quote-external-notes',
                              }}
                              title={t('common.externalNotes')}
                              subTitle={t('common.visibleToCustomers')}
                              type="externalNote"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <QuoteSummary
                              quote={quote}
                              loading={isFetching}
                              readonly={isReadonly}
                            />
                          </Grid>
                        </Grid>
                      </Mask>
                    </Column>
                    <FooterSticky
                      disabled={isNew}
                      leftContent={<QuoteFooterDetails withDownloadButton />}
                      rightContent={<QuoteFooterActionButtons />}
                    />
                    <Chat />
                  </ChatVisibilityProvider>
                  <AlertSnackbar data-testid="quote-address-change-snackbar" {...snackbarProps} />
                  {/* keep the same instance of the modals between quote refetching */}
                  {isShippingModalOpen && (
                    <ShippingDialog handleShowSnackbar={handleShowSnackbar} />
                  )}
                  {isBillingModalOpen && <BillingDialog />}
                  {/* include the modals, PLP and PDP only if the quote is not in a readonly mode */}
                  {!isReadonly ? (
                    <>
                      <ProductConfiguration />
                      <CreateCustomerUserFromQuoteDialog
                        isOpen={isOpenUserDialog}
                        setIsOpenUserDialog={setIsOpenUserDialog}
                      />
                    </>
                  ) : null}
                </Box>
              </AddItemSearchTermProvider>
            </ChatChannelProvider>
          </ProductConfigurationProvider>
        </QuoteShareProvider>
      </QuoteDetailsProvider>
    </CatalogsProvider>
  );
};
