import React, { Dispatch, PropsWithChildren, createContext, useContext, useMemo } from 'react';

type ContextValue = {
  value: string;
  setValue: Dispatch<React.SetStateAction<string>>;
};

const AddItemSearchTermContext = createContext<ContextValue>({} as ContextValue);

export function AddItemSearchTermProvider({
  value,
  setValue,
  children,
}: PropsWithChildren<ContextValue>) {
  const contextValue = useMemo(() => ({ value, setValue }), [value, setValue]);

  return (
    <AddItemSearchTermContext.Provider value={contextValue}>
      {children}
    </AddItemSearchTermContext.Provider>
  );
}

export function useAddItemSearchTerm() {
  const context = useContext(AddItemSearchTermContext);

  if (!context) {
    throw new Error('useAddItemSearchTerm must be used within a AddItemSearchTermProvider');
  }

  return context;
}
