import { Location } from './types';
import { axios } from 'libs/Utils';

export const apiPaths = {
  locations: '/api/location/v1/locations',
};

export const listLocations = (types?: string) =>
  axios
    .get<Location[]>(apiPaths.locations, { params: { types } }) //
    .then(response => response.data);
