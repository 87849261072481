import React from 'react';
import { Company } from 'libs/api/iam/types';
import { SalesRepOption, SalesRepSelect } from '../SalesRepSelect';
import { useUpdateCompany } from 'libs/api/iam/hooks';

export interface CompanySalesRepSelectProps {
  company: Company;
}

export const CompanySalesRepSelect: React.FC<CompanySalesRepSelectProps> = ({ company }) => {
  const [salesRep, setSalesRep] = React.useState<SalesRepOption | undefined>(
    company.salesRepresentative && {
      ...company.salesRepresentative,
      team: company.team,
    }
  );

  const updateCompanyMutation = useUpdateCompany();

  const handleAssignSalesRep = (user: SalesRepOption) => {
    updateCompanyMutation.mutate({
      companyId: company.id,
      company: {
        salesRepresentativeId: user.id,
        teamId: user.team ? user.team.id : null,
      },
    });

    setSalesRep(user);
  };

  return <SalesRepSelect salesRep={salesRep} onChange={handleAssignSalesRep} />;
};
