function convertImgToDataURL(url: string): Promise<string> {
  return new Promise(resolve => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      context?.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL();
      resolve(dataURL);
    };

    img.onerror = () => {
      // smallest possible transparent gif
      // see http://probablyprogramming.com/2009/03/15/the-tiniest-gif-ever
      resolve('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
    };
    img.src = url;
  });
}
export default convertImgToDataURL;
