import React, { FC } from 'react';
import { Box } from '@clippings/paper';
import { NewQuoteButton } from '../../Quotes/Components/NewQuoteButton';
import { PageHeader } from 'libs/SalesApp/components/PageHeader';
import { QuotesPage } from './types';
import { QuotesTableContainer } from './QuotesTable/QuotesTableContainer';
import { getPageTitleTranslation } from './quoteUtils';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export interface QuotesProps {
  page: QuotesPage;
}

export const Quotes: FC<QuotesProps> = ({ page }) => {
  const { t } = useTranslation();
  useTitle(
    t('quotes.quotesTableTitle', {
      type: t(getPageTitleTranslation(page)),
    })
  );

  return (
    <Box data-testid="sales-quotes-page" className={`quote-page`}>
      <PageHeader
        data-testid="quote-list-header"
        title={t('quotes.quotesTableTitle', {
          type: t(getPageTitleTranslation(page)),
        })}
        titleTestId="sales-quotes-page-title"
      >
        <NewQuoteButton />
      </PageHeader>
      <QuotesTableContainer page={page} />
    </Box>
  );
};
