import { useBanner } from 'libs/Components';
import { useParams } from 'react-router-dom';
import { useQuoteError } from './useQuoteError';
import { useQuoteVersionNumber } from './useQuoteVersionNumber';

export const useQuoteUtils = () => {
  const { number } = useParams() as { number: string };
  const { showSuccessBanner, showErrorBanner } = useBanner();
  const { showError } = useQuoteError(showErrorBanner);
  const [version, setVersion] = useQuoteVersionNumber();

  return {
    number,
    showSuccessBanner,
    showErrorBanner,
    showError,
    version,
    setVersion,
  };
};
