import React, { FC } from 'react';
import { CheckoutIcon, CheckoutPendingIconSet } from './icons';

export type CheckoutStatusViewType = 'success' | 'error' | 'pending';

export const PaymentStatusIcons: Record<CheckoutStatusViewType, React.ReactElement<FC>> = {
  success: <CheckoutIcon type="success" />,
  error: <CheckoutIcon type="error" />,
  pending: <CheckoutPendingIconSet />,
};
