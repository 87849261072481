import React from 'react';
import { FormModal } from 'libs/Components';
import { Typography } from '@clippings/paper';
import { useAddressContext } from 'libs/Quotes/QuoteAddresses/AddressContext';
import { useTranslation } from 'react-i18next';

export const DeleteDialog = () => {
  const { isDeleteModalOpen, handleCloseDeleteModal, onDelete, selectedAddress, isLoading } =
    useAddressContext();
  const { t } = useTranslation();

  return (
    <FormModal
      open={isDeleteModalOpen}
      onClose={handleCloseDeleteModal}
      formProps={{
        defaultValues: { id: selectedAddress.id },
        onSubmit: onDelete,
      }}
      actionProps={{
        onCancel: handleCloseDeleteModal,
        cancelLabel: t('common.cancel'),
        submitLabel: t('common.delete'),
      }}
      title={<Typography variant="body1">{t('quotes.addresses.deleteConfirm')}</Typography>}
      submitProps={{
        'data-testid': 'delete-modal-submit',
      }}
      isSubmitting={isLoading}
    >
      <Typography variant="body1">{t('quotes.addresses.deletePermanent')}</Typography>
      <input name="id" type="hidden" id="addressId" />
    </FormModal>
  );
};
