import React from 'react';
import { DetailedUserAvatar, MenuItem, getUserFullName } from '@clippings/paper';
import { User } from 'libs/api/iam/types';
import { getCompanyCity } from 'libs/shared';

interface UserMenuItemProps {
  user: User;
  selected: boolean;
  disabled?: boolean;
  itemClickHandler: (user: User) => void;
}

export function UserMenuItem({
  user,
  selected,
  disabled = false,
  itemClickHandler,
}: UserMenuItemProps) {
  const { id, company } = user;
  const captionSuffix = getCompanyCity(company?.billingAddresses);

  return (
    <MenuItem
      key={user.id}
      onClick={() => itemClickHandler(user)}
      selected={selected}
      data-testid={`quote-customer-select-menu-item-${id}`}
      disabled={disabled}
    >
      <DetailedUserAvatar
        user={user}
        title={getUserFullName(user)}
        caption={`${company?.name} (${captionSuffix})`}
        caption2={company?.vendorId}
      />
    </MenuItem>
  );
}
