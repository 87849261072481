import React from 'react';
import classNames from 'classnames';
import { Monetary, TableCell } from '@clippings/paper';
import { TableCellCommonProps } from './tableCells.utils';

type SubTotalTableCellProps = Pick<TableCellCommonProps, 'item'>;

export function SubTotalTableCell({ item }: SubTotalTableCellProps) {
  return (
    <TableCell
      data-testid={`quote-item-subtotal-${item.id}`}
      align="right"
      sx={styles.tableCellSx}
      className={classNames('quote-item-row__cell', 'quote-item-row__cell__sub-total')}
    >
      <Monetary
        fontWeight={600}
        currency={item.subtotal.currency}
        amount={item.subtotal.amount}
        sx={styles.monetarySx}
      />
    </TableCell>
  );
}

const styles = {
  tableCellSx: {
    backgroundColor: 'grey.A100',
  },
  monetarySx: {
    marginTop: '9px',
    fontSize: 'inherit',
  },
};
