import React, { FC } from 'react';
import { Typography } from '@clippings/paper';

interface ModalTitleProps {
  text: string;
}

export const ModalTitle: FC<ModalTitleProps> = ({ text }) => (
  <Typography variant="h4" fontSize="1.25rem">
    {text}
  </Typography>
);
