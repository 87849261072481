import React from 'react';
import { Box, Typography } from '@clippings/paper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ShareScreenTitle: FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography color="secondary" variant="body2">
        {t('quotes.share.invitePeopleToViewAndComment')}
      </Typography>
      <Typography color="secondary" variant="body2">
        {t('quotes.share.anyoneWhoHasAccess')}
      </Typography>
    </Box>
  );
};
