import React from 'react';
import { CreateTeamVariables, Team, TeamFormVariables } from 'libs/api/iam/types';
import { IAM_QUERY_KEYS, useCreateTeam } from 'libs/api/iam/hooks';
import { TeamForm } from './TeamForm';
import { TeamFormBaseProps } from './teamForms.types';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export type TeamCreateFormProps = TeamFormBaseProps & {
  onCreateSuccess: (team: Team) => void;
  onCreateError: (error: any, team: CreateTeamVariables) => void;
};

export const TeamCreateForm: React.FC<TeamCreateFormProps> = ({
  onCreateSuccess,
  onCreateError,
  defaultValues,
  onCancel,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, error, isLoading } = useCreateTeam({
    onSuccess: data => {
      onCreateSuccess(data);
      queryClient.invalidateQueries([IAM_QUERY_KEYS.teams]);
      queryClient.invalidateQueries([IAM_QUERY_KEYS.infiniteTeamsV2]);
    },
    onError: (error, variables) => {
      onCreateError(error, variables);
    },
  });

  const handleFormSubmit = (formData: TeamFormVariables) => {
    return mutate({
      name: formData.name,
      pdfTemplateId: formData.pdfTemplateId,
      membersIds: [],
      companiesIds: [],
    });
  };

  return (
    <TeamForm
      buttonText={t('common.create')}
      loading={isLoading}
      mutationErrors={error}
      defaultValues={defaultValues}
      handleFormSubmit={handleFormSubmit}
      onCancel={onCancel}
    />
  );
};
