import { UserStatus } from 'libs/api/iam/types';
import { useListInfiniteUsersV2 } from 'libs/api/iam/hooks';

export const useInboxCustomer = () => {
  return useListInfiniteUsersV2({
    roles: 'business_customer',
    statuses: UserStatus.Pending,
    size: 3,
  });
};
