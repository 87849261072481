import React from 'react';
import { Address } from 'libs/api/common/types';
import { AddressDetails } from './AddressDetails';
import { Box, CloseableDialog, LoadingButton } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export interface DeleteAddressDialogProps {
  address: Address;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  loading: boolean;
}

export const DeleteAddressDialog: React.FC<DeleteAddressDialogProps> = ({
  address,
  open,
  onClose,
  onDelete,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <CloseableDialog
      open={open}
      onClose={onClose}
      title={t('common.deleteAddress')}
      dialogProps={{ PaperProps: { sx: { width: 560 } } }}
      dialogActions={
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          onClick={onDelete}
          data-testid="confirm-delete-address"
        >
          {t('common.delete')}
        </LoadingButton>
      }
    >
      <Box p={1} border={1} borderColor="grey.A400" borderRadius={2}>
        <AddressDetails address={address} />
      </Box>
    </CloseableDialog>
  );
};
