import { useAvailableCatalogs } from 'libs/catalogs';
import { useGetCompany } from 'libs/api/iam/hooks';

export const useQuoteCatalogueSelect = (companyId?: number, slug?: string) => {
  const { data: company, isFetching } = useGetCompany(Number(companyId), { enabled: !!companyId });
  const { catalogs, isFetching: isListFetching } = useAvailableCatalogs();

  const selectedCatalogue = catalogs?.find(catalogue => catalogue.slug === slug);
  const isDisabled = !!slug && company?.catalogues?.some(catalogue => catalogue.slug === slug);

  return {
    selectedCatalogue,
    availableCatalogues: company?.catalogues ?? [],
    isDisabled: isDisabled || isFetching || isListFetching,
  };
};
