import React from 'react';
import { CreateUserVariables } from 'libs/api/iam/types';
import { CustomerUserFormValues } from 'libs/SalesApp/Users/components/userDialog/UserDialogForm';
import { DialogAction, MultiSelectValue, SystemUser } from '@clippings/paper';
import { UserDialogFormBase } from '../../components/userDialog/UserDialogFormBase';
import { UserDialogFormProps } from '../../utils/user.utils';
import { useTeams } from 'libs/SalesApp/Quotes/hooks/useTeams';
import { useUserHandlers } from 'libs/SalesApp/Users/hooks';

export interface StaffUserFormValues extends CustomerUserFormValues {
  teams: MultiSelectValue[];
}

export const StaffUserDialogForm: React.FC<
  UserDialogFormProps<StaffUserFormValues, CreateUserVariables>
> = ({ action, onCancel, userId, defaultValues = {}, hiddenFields, disabledFields, ...rest }) => {
  const isEditMode = action === DialogAction.Edit;

  const [teams] = useTeams();

  const {
    staffUserUpdateHandler,
    staffUserCreateHandler,
    createUserErrors,
    updateUserErrors,
    isSubmitting,
  } = useUserHandlers({ ...rest });

  const handleSubmit = (formValues: StaffUserFormValues) => {
    if (isEditMode && userId) {
      staffUserUpdateHandler(userId, formValues);
    } else {
      staffUserCreateHandler(formValues);
    }
  };

  return (
    <UserDialogFormBase
      teams={teams}
      createUserErrors={createUserErrors}
      updateUserErrors={updateUserErrors}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      defaultValues={defaultValues}
      action={action}
      type={SystemUser.Staff}
      hiddenFields={hiddenFields}
      disabledFields={disabledFields}
    />
  );
};
