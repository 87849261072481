import React from 'react';
import { CheckIcon, CloseIcon, Typography } from '@clippings/paper';
import { Column, Row } from 'libs/shared';
import { kebabCase, keys } from 'lodash';

export type FeaturesModel = Record<string, boolean>;

type FeaturesBoxProps = {
  title: string;
  featuresModel: FeaturesModel;
  action: JSX.Element;
};

export function FeaturesBox({ title, featuresModel, action }: FeaturesBoxProps) {
  return (
    <Column
      gap={2.5}
      justifyContent="space-evenly"
      p={3}
      border="1px solid"
      borderRadius={1}
      borderColor="grey.A200"
      data-testid={`features-box-${kebabCase(title)}`}
    >
      <Typography fontSize={theme => theme.typography.pxToRem(14)} fontWeight={500}>
        {title}
      </Typography>
      <Column gap={1.5}>
        {keys(featuresModel).map((featureKey, idx) => (
          <Feature key={idx} value={featureKey} enabled={featuresModel[featureKey]} />
        ))}
      </Column>
      <Row pt={2}>{action}</Row>
    </Column>
  );
}

type FeatureProps = {
  value: string;
  enabled: boolean;
};

function Feature({ value, enabled }: FeatureProps) {
  return (
    <Row gap={1.5}>
      {enabled ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
      <Typography fontSize={theme => theme.typography.pxToRem(14)}>{value}</Typography>
    </Row>
  );
}
