import { useState } from 'react';

export function useActionPanelVisibility(readonly: boolean) {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  const showActionPanel = (e: React.MouseEvent<HTMLDivElement>) => {
    if (readonly) {
      return;
    }
    setAnchorElement(e.currentTarget);
  };

  const hideActionPanel = () => setAnchorElement(null);

  return {
    anchorElement,
    showActionPanel,
    hideActionPanel,
  };
}
