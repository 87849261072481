import React, { useState } from 'react';
import { Box } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { CreateUserButton } from 'libs/SalesApp/Users/components/CreateUserButton/CreateUserButton';
import { CustomerUserDialogWrapper } from 'libs/SalesApp/Users/customer/CustomerUserDialogWrapper';
import { DecodedValueMap, useQueryParams } from 'use-query-params';
import { EmptyTablePlaceholder } from 'libs/Components';
import { UserFormFieldName } from 'libs/SalesApp/Users/components/userDialog/UserFormFields';
import { UserListBlock, cellConfig } from 'libs/SalesApp/Users/customer/components/UserListBlock';
import { makeColumns, makeQueryParamsMap, useTableSorting } from 'libs/shared';
import { useListInfiniteUsersV2 } from 'libs/api/iam/hooks';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

const columns = makeColumns(cellConfig);
const queryParamsMap = makeQueryParamsMap(columns);
type UsersQueryParams = Partial<DecodedValueMap<typeof queryParamsMap>>;

export interface UserDetailsPanelProps {
  company: Company;
}

export const UserDetailsPanel: React.FC<UserDetailsPanelProps> = ({ company }) => {
  const { t } = useTranslation();

  useTitle(t('common.users'));

  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams(queryParamsMap);
  const { sort, updateSorting } = useTableSorting<UsersQueryParams>(queryParams, setQueryParams);

  const usersQuery = useListInfiniteUsersV2({
    companyIds: String(company.id),
    sortBy: sort.sort,
    sortOrder: sort.order,
  });

  const placeholder = (
    <Box mt={2}>
      <EmptyTablePlaceholder
        title={t('common.noUserResults')}
        containerProps={{ 'data-testid': 'empty-user-table-placeholder' }}
      />
    </Box>
  );

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" my={2}>
        <CreateUserButton
          data-testid="create-customer-user-button"
          onClick={() => setUserDialogOpen(true)}
          label={t('users.newUser')}
        />
      </Box>
      <UserListBlock
        hideCompanyColumn
        query={usersQuery}
        placeholder={placeholder}
        sort={sort}
        onSort={updateSorting}
      />
      <CustomerUserDialogWrapper
        isUserModalOpen={userDialogOpen}
        setIsUserModalOpen={setUserDialogOpen}
        hiddenFields={[UserFormFieldName.Company]}
        defaultValues={{ company }}
      />
    </Box>
  );
};
