import React from 'react';
import { ChipMenuFilter, MenuItem, Typography } from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

export interface CompanyTypeSelectProps {
  type?: string;
  disabled?: boolean;
  onChange: (type?: string) => void;
}

export const CompanyTypeSelect: React.FC<CompanyTypeSelectProps> = ({
  type,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation();
  const { companyTypes } = useAppConfiguration();

  const [menuOpen, setMenuOpen] = React.useState(false);

  const statusLabel = type ? t(companyTypes.find(x => x.value === type)!.label) : t('common.type');

  const setSelection = (value?: string) => {
    setMenuOpen(false);
    onChange(value);
  };

  return (
    <ChipMenuFilter
      chipProps={{
        isOpen: menuOpen,
        onToggle: setMenuOpen,
        placeholder: t('common.type'),
        value: statusLabel,
        hasArrow: true,
        disabled: disabled,
        'data-testid': 'salesapp-companies-type-filter',
      }}
      clearProps={{
        label: t('common.clear'),
        buttonProps: {
          onClick: () => setSelection(),
        },
      }}
      contentContainerProps={{
        'data-testid': 'salesapp-companies-type-dropdown',
      }}
    >
      {companyTypes
        .map(item => item.value)
        .map(companyType => (
          <MenuItem
            data-testid={`company-type-select-item-${companyType}`}
            key={companyType}
            onClick={() => setSelection(companyType)}
          >
            <Typography data-testid={`company-item-${companyType}`} variant="body2">
              {t(companyTypes.find(x => x.value === companyType)?.label)}
            </Typography>
          </MenuItem>
        ))}
    </ChipMenuFilter>
  );
};
