import React, { ComponentProps } from 'react';
import { Typography } from '@clippings/paper';

interface QuoteInfoLabelProps extends ComponentProps<typeof Typography> {
  bold?: boolean;
  text?: string;
}

export const QuoteInfoLabel = ({ text = '', bold = false, ...rest }: QuoteInfoLabelProps) => (
  <Typography variant="subtitle2" fontSize={8} fontWeight={bold ? 600 : 400} {...rest}>
    {text}
  </Typography>
);
