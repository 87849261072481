import AvatarMenu from './AvatarMenu';
import React from 'react';
import { AppBar, Box, Image, Link, Toolbar, useTheme } from '@clippings/paper';
import { GlobalSearch } from './GlobalSearch';
import { LanguageSelect } from 'libs/Components/LanguageSelect/LanguageSelect';
import { useLanguageChange } from 'libs/shared';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import './Header.scss';

export const Header = () => {
  const {
    images: { logo },
  } = useTheme();
  const returnUrl = useReturnUrl();
  const { handleLanguageSelect, languageSelectAvailable } = useLanguageChange();

  return (
    <AppBar
      elevation={0}
      className="sales-header"
      position="sticky"
      variant="outlined"
      color="inherit"
    >
      <Toolbar>
        <Box minWidth="20rem" flex="1">
          <Box display="flex">
            <Link href={returnUrl}>
              <Image src={logo} alt="logo" height={44} />
            </Link>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <GlobalSearch
            sx={{
              width: '100%',
              flex: 2,
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" flex="1" justifyContent="flex-end" pl={1}>
          {languageSelectAvailable && <LanguageSelect onLanguageSelected={handleLanguageSelect} />}
          <AvatarMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
