import React, { ComponentProps, PropsWithChildren, Ref } from 'react';
import { Box } from '@clippings/paper';

export const Column = React.forwardRef<any, PropsWithChildren<ComponentProps<typeof Box>>>(
  ({ children, ...rest }, ref) => {
    return (
      <Box
        ref={ref as Ref<HTMLDivElement | null> | undefined}
        display="flex"
        flexDirection="column"
        {...rest}
      >
        {children}
      </Box>
    );
  }
);
