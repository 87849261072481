import React, { ComponentProps } from 'react';
import { FlexRow, Theme, Typography, useFormatPrice, useTheme } from '@clippings/paper';
import { Payment } from 'libs/api/common/types';
import { useBuildPercentageLabel, useFormatDetails, useFormatType } from './paymentHelpers';

type StyleMapVariant = Pick<ComponentProps<typeof Typography>, 'variant' | 'color' | 'fontSize'>;
type RowSize = 'small' | 'medium';
type StyleMap = Record<RowSize, StyleMapVariant>;

const getStyles = (pxToRem: Theme['typography']['pxToRem']): StyleMap => ({
  small: {
    variant: 'caption',
    color: 'text.secondary',
    fontSize: pxToRem(10),
  },
  medium: {
    variant: 'body2',
    color: 'grey.600',
    fontSize: {
      xs: pxToRem(12),
      md: pxToRem(14),
    },
  },
});

interface PaymentTermRowProps {
  paymentTerm: Payment;
  idx: number;
  size?: RowSize;
}

export const PaymentTermRow: React.FC<PaymentTermRowProps> = ({
  paymentTerm,
  idx,
  size = 'small',
}) => {
  const {
    typography: { pxToRem },
  } = useTheme();
  const formatPrice = useFormatPrice();

  const buildPercentageLabel = useBuildPercentageLabel();
  const { formatDetails } = useFormatDetails();
  const { formatType } = useFormatType();
  const styles = getStyles(pxToRem)[size];

  return (
    <FlexRow gap={0.5} data-testid={`payment-term-row-${paymentTerm.id}`} mb={0.5}>
      <Typography
        data-testid={`payment-term-option-${idx}`}
        variant={styles.variant}
        fontSize={styles.fontSize}
        fontWeight="fontWeightMedium"
      >
        {buildPercentageLabel(paymentTerm, idx)}{' '}
        {formatPrice(paymentTerm.amount.amount, paymentTerm.amount.currency)}{' '}
        {formatType(paymentTerm)}
      </Typography>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <Typography {...styles}>&bull;</Typography>
      <Typography
        data-testid={`payment-term-row-details-${paymentTerm.id}`}
        variant={styles.variant}
        color={styles.color}
        fontSize={styles.fontSize}
        fontWeight="fontWeightMedium"
      >
        {formatDetails(paymentTerm)}
      </Typography>
    </FlexRow>
  );
};
