import { useToggle } from 'libs/shared';

export function useCustomDeliveryDateModal() {
  const [isOpen, setIsOpen] = useToggle();

  function openModalHandler() {
    setIsOpen(true);
  }

  function closeModalHandler() {
    setIsOpen(false);
  }

  return { isOpen, openModalHandler, closeModalHandler };
}
