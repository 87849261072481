import React, { ReactElement } from 'react';
import { CONFIRM, REVIEW, SETUP, Step } from '../config/steps';
import { Chat, ChatVisibilityProvider } from 'libs/Chat';
import { CheckoutLoading } from './CheckoutLoading';
import { CheckoutModals } from './modals/CheckoutModals';
import { Column, pageViewContainerResponsiveStyles } from 'libs/shared';
import { Confirm, Footer, Header, Review, Setup } from '.';
import { FooterStepReview } from './footerSteps/FooterStepReview';
import { FooterStepSetup } from './footerSteps/FooterStepSetup';
import { Payment } from 'libs/api/common/types';
import { QuoteCheckoutProvider } from '../providers/QuoteCheckoutProvider';

const STEP_MAP: Record<Step, ReactElement> = {
  [SETUP]: <Setup />,
  [REVIEW]: <Review />,
  [CONFIRM]: <Confirm />,
};

type CheckoutProps = {
  payment: Payment;
};

export const Checkout: React.FC<CheckoutProps> = ({ payment }) => {
  return (
    <QuoteCheckoutProvider payment={payment}>
      {step => (
        <>
          <Column
            height="100%"
            paddingTop={pageViewContainerResponsiveStyles.py}
            paddingBottom={0}
            px={pageViewContainerResponsiveStyles.px}
            className="quote-view"
          >
            <ChatVisibilityProvider>
              <Header activeStep={step} />
              {step !== CONFIRM && (
                <Setup
                  containerProps={{
                    display: step === SETUP ? 'grid' : 'none',
                  }}
                />
              )}
              {step !== SETUP && STEP_MAP[step]}
              <Chat />
            </ChatVisibilityProvider>
            <Footer px={footerPx} py={2}>
              {step === SETUP && <FooterStepSetup />}
              {step === REVIEW && <FooterStepReview />}
            </Footer>
          </Column>
          <CheckoutModals />
          <CheckoutLoading />
        </>
      )}
    </QuoteCheckoutProvider>
  );
};

const footerPx = {
  xs: 0,
  md: 2,
};
