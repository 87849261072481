import React, { PropsWithChildren, ReactNode } from 'react';
import { Box } from '@clippings/paper';

type ProductCatalogProps = {
  header: ReactNode;
};

export function ProductCatalogLayout({ header, children }: PropsWithChildren<ProductCatalogProps>) {
  return (
    <Box data-testid="product-catalogue-container">
      <Box
        height={64}
        display="flex"
        alignItems="center"
        borderBottom={theme => `1px solid ${theme.palette.grey.A200}`}
      >
        {header}
      </Box>
      <Box height="calc(100vh - 64px)" display="flex">
        {children}
      </Box>
    </Box>
  );
}
