import { CellConfig } from 'libs/shared';
import { SortDirection } from 'libs/api/common/types';
import { createEnumParam, withDefault } from 'use-query-params';

export const sortOptions: SortDirection[] = ['asc', 'desc'];

export function makeQueryParamsMap(columns: string[]) {
  return {
    order: withDefault(createEnumParam(sortOptions), 'asc'),
    sort: withDefault(createEnumParam(columns), columns[0]),
  };
}

export function makeColumns(cellConfig: CellConfig[]) {
  return cellConfig.map(cell => cell.columnName);
}
