import { Paginated } from 'libs/api/common/types';
import { QuoteListItem } from 'libs/api/quotes/types';
import { QuotesFilters, QuotesPage, QuotesTableSort } from '../types';
import { SearchProps } from 'libs/SalesApp/Header/useSearch';
import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { getQuotes } from 'libs/api/quotes/actions';
import { getQuotesRequestStatusFilter } from '../quoteUtils';
import { reactQueryKeys } from 'libs/api/quotes/hooks';
import { toObjectStringArray } from 'libs/shared/utils';
import { useMemo } from 'react';

export interface UseSalesQuotesHook extends Partial<SearchProps> {
  sort?: QuotesTableSort;
  filters?: QuotesFilters;
  page?: QuotesPage;
  options?: UseInfiniteQueryOptions<Paginated<QuoteListItem>>;
  size?: number;
}

export const useSalesQuotes = ({
  sort,
  filters,
  page,
  query,
  options,
  size,
}: UseSalesQuotesHook = {}) => {
  const reqFilters: QuotesFilters = useMemo(
    () => ({
      ...filters,
      status: getQuotesRequestStatusFilter(filters?.status ?? [], page),
    }),
    [filters, page]
  );

  const quotesInfiniteQuery = useInfiniteQuery<Paginated<QuoteListItem>>(
    [reactQueryKeys.getAll, reqFilters, page, sort, query],
    ({ pageParam }: { pageParam?: string }) => {
      return getQuotes({
        ...sort,
        ...toObjectStringArray(reqFilters),
        page: pageParam ?? 1,
        query: query || undefined,
        size: size || 10,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.currentPage < lastPage.totalPages) {
          return allPages.length + 1;
        }
      },
      select: data => {
        return {
          pageParams: data.pageParams,
          pages: data.pages,
          // @ts-ignore
          totalItems: data.pages?.[0]?.totalItems,
        };
      },
      ...options,
    }
  );

  return quotesInfiniteQuery;
};
