import React from 'react';
import { Grid, GridProps } from '@clippings/paper';

export const ListTableBody: React.FC<GridProps> = ({ children, ...rest }) => {
  return children ? (
    <Grid className="list-table-body" container flexDirection="column" gap={1} {...rest}>
      {children}
    </Grid>
  ) : null;
};
