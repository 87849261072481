import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@clippings/paper';

interface TotalsSummaryRowLabelProps extends ComponentProps<typeof Box> {
  color?: ComponentProps<typeof Typography>['color'];
  typographyProps?: ComponentProps<typeof Typography>;
}

export const TotalsSummaryRowLabel: FC<PropsWithChildren<TotalsSummaryRowLabelProps>> = ({
  children,
  color = 'text.secondary',
  typographyProps,
  ...rest
}) => (
  <Box {...rest}>
    <Typography
      data-testid="totals-summary-row-label-text"
      variant="body1"
      fontWeight={500}
      color={color}
      {...typographyProps}
    >
      {children}
    </Typography>
  </Box>
);
