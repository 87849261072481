import React, { useState } from 'react';
import { AddIcon, Box, Button, Typography } from '@clippings/paper';
import { Address, AddressType } from 'libs/api/common/types';
import { AddressDialog } from './AddressDialog';
import { Company } from 'libs/api/iam/types';
import { EditableAddressItem } from './EditableAddressItem';
import { useTranslation } from 'react-i18next';

export interface AddressColumnProps {
  company: Company;
  addresses: Address[];
  type: AddressType;
  isEditable: boolean;
  canCreateNewAddress: boolean;
  title: string;
}

export const AddressColumn: React.FC<AddressColumnProps> = ({
  company,
  addresses,
  type,
  isEditable,
  canCreateNewAddress,
  title,
}) => {
  const { t } = useTranslation();
  const [showCreateAddressDialog, setShowCreateAddressDialog] = useState(false);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between" minHeight="38px">
        <Typography
          data-testid="address-column-address"
          variant="subtitle2"
          color="grey.500"
          fontWeight="fontWeightBold"
          alignSelf="center"
        >
          {title}
        </Typography>
        {canCreateNewAddress && (
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => setShowCreateAddressDialog(true)}
            data-testid={`add-${type}-address`}
          >
            {t('common.addNew')}
          </Button>
        )}
      </Box>
      {addresses.map(address => (
        <Box key={address.id} border={1} borderColor="grey.A400" borderRadius={2}>
          <EditableAddressItem
            company={company}
            address={address}
            type={type}
            isEditable={isEditable}
          />
        </Box>
      ))}
      <AddressDialog
        company={company}
        type={type}
        makeDefault={addresses.length === 0}
        open={showCreateAddressDialog}
        onClose={() => setShowCreateAddressDialog(false)}
      />
    </Box>
  );
};
