import React from 'react';
import { AddressField } from '../../../shared/components/address/addressFieldConfigs';
import { Box, FormTextField } from '@clippings/paper';
import { FormPhoneField } from 'libs/shared';
import { useTranslation } from 'react-i18next';

export const CustomerPickupForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <FormTextField
        id="contact-name"
        name={AddressField.ContactName}
        variant="standard"
        label={t('common.contactNameOnly')}
        fullWidth
        required
      />
      <FormTextField
        required
        id="address-email"
        name={AddressField.Email}
        label={t('common.email')}
        fullWidth
      />
      <FormPhoneField
        id="phone"
        name={AddressField.Phone}
        label={t('common.phone')}
        fullWidth
        required
      />
    </Box>
  );
};
