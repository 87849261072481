import { QUERY_KEY, SEARCH_ACTION } from './searchReducer';
import { StringParam, useQueryParam } from 'use-query-params';
import { getCleanQueryParamValue } from 'libs/shared';
import { useDispatch, useSelector } from 'react-redux';

export interface SearchProps {
  query: string;
}

export const useSearch = (): [string, (value: string) => void] => {
  const dispatch = useDispatch();
  const { query } = useSelector<{ search: SearchProps }, SearchProps>(state => state.search);

  const [, setQueryParams] = useQueryParam(QUERY_KEY, StringParam);

  const setSearchValue = (value: string) => {
    setQueryParams(getCleanQueryParamValue(value));
    dispatch({ type: SEARCH_ACTION, payload: value });
  };

  return [query, setSearchValue];
};
