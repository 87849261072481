import React from 'react';
import { Company } from 'libs/api/iam/types';
import { DateTime, DateTimeFormat, Grid, LoopIcon, Typography } from '@clippings/paper';
import { Link } from 'react-router-dom';
import { ListTableRow } from 'libs/Components/ListTable/ListTableRow';
import { Row } from 'libs/shared';
import { STATUSES } from 'libs/SalesApp/Companies/components/CompanyFormFields';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

export interface CompanyGridItemProps {
  company: Company;
}

export const CompanyGridItem: React.FC<CompanyGridItemProps> = ({ company }) => {
  const { t } = useTranslation();
  const { id, name, registrationNumber, phone, website, type, createdAt, vendorId } = company;
  const { companyTypes } = useAppConfiguration();

  return (
    <ListTableRow
      columns={5}
      data-testid={`company-list-item-${id}`}
      key={id}
      component={Link}
      to={path(routes.salesApp.company, { companyId: company.id })}
    >
      <Grid item xs={1}>
        <Row alignItems="center" gap={0.5}>
          <Typography
            data-testid={`company-list-item-name-${id}`}
            variant="subtitle2"
            fontWeight="fontWeightMedium"
          >
            {name ?? '\u00A0'}
          </Typography>
          {!vendorId ? (
            <LoopIcon
              color="secondary"
              fontSize="small"
              data-testid={`company-list-item-sync-status-icon-${id}`}
            />
          ) : null}
        </Row>
        <Typography
          data-testid={`company-list-item-registrationNumber-${id}`}
          variant="caption"
          color="secondary"
        >
          {registrationNumber ?? '\u00A0'}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography data-testid={`company-list-item-phone-${id}`} variant="body2">
          {phone}
        </Typography>
        <Typography
          data-testid={`company-list-item-website-${id}`}
          variant="body2"
          sx={{ wordBreak: 'break-all' }}
        >
          {website}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography data-testid={`company-list-item-type-${id}`} variant="body2">
          {type && t(companyTypes.find(x => x.value === type)?.label)}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {createdAt ? (
          <DateTime format={DateTimeFormat.Date} date={createdAt} variant="body2" />
        ) : (
          <Typography data-testid={`company-list-item-createdAt-${id}`} variant="body2">
            ---
          </Typography>
        )}
      </Grid>
      <Grid item xs={1}>
        <Typography data-testid={`company-list-item-type-${id}`} variant="body2">
          {t(STATUSES[company.status].label as 'common.active' | 'common.inactive')}
        </Typography>
      </Grid>
    </ListTableRow>
  );
};
