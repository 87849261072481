import {
  CompanyDiscount,
  CompanyProductDiscount,
  CompanyQuoteDiscount,
} from 'libs/api/common/types';
import { useBanner } from 'libs/Components';
import { useMemo, useState } from 'react';
import { useReplaceCompanyDiscounts } from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

type DiscountsMap = {
  product: CompanyProductDiscount[];
  quote: CompanyQuoteDiscount[];
};

export function useDiscountsManager(
  discounts: CompanyDiscount[],
  companyId: number,
  onSuccess: () => void
) {
  const { t } = useTranslation();
  const { showErrorBanner } = useBanner();

  const replaceCompanyDiscounts = useReplaceCompanyDiscounts({
    onSuccess,
  });

  const discountsMap: DiscountsMap = useMemo(() => {
    return discounts.reduce(
      (acc, discount) => {
        if (discount.type === 'product') {
          acc.product.push(discount as CompanyProductDiscount);
        }

        if (discount.type === 'quote') {
          acc.quote.push(discount as CompanyQuoteDiscount);
        }

        return acc;
      },
      { product: [], quote: [] } as DiscountsMap
    );
  }, [discounts]);

  const [productDiscounts, setProductDiscounts] = useState<CompanyProductDiscount[]>(
    discountsMap.product
  );
  const [quoteDiscounts, setQuoteDiscounts] = useState<CompanyQuoteDiscount[]>(discountsMap.quote);

  const checkDuplicateProductDiscounts = (discounts: CompanyProductDiscount[]) => {
    const discountMap: { [key: string]: CompanyProductDiscount & { hasEmpty?: boolean } } = {};

    for (const discount of discounts) {
      if (!discount.catalogueSlug) {
        continue;
      }

      if (!discountMap[discount.catalogueSlug]) {
        discountMap[discount.catalogueSlug] = {
          ...discount,
          hasEmpty: discount.productCategories.length === 0,
        };
        continue;
      }

      if (discount.productCategories.length === 0) {
        if (discountMap[discount.catalogueSlug].hasEmpty) {
          return true;
        }

        discountMap[discount.catalogueSlug].hasEmpty = true;
        continue;
      }

      const intersect = discountMap[discount.catalogueSlug].productCategories.filter(element =>
        discount.productCategories.includes(element)
      );

      if (intersect.length > 0) {
        return true; // found duplicate
      }

      discountMap[discount.catalogueSlug].productCategories = [
        ...discountMap[discount.catalogueSlug].productCategories,
        ...discount.productCategories,
      ];
    }

    return false;
  };

  const applyHandler = () => {
    const readyProductDiscounts = productDiscounts.map(preprocess);
    const readyQuoteDiscounts = quoteDiscounts.map(preprocess);
    const readyDiscounts = [...readyProductDiscounts, ...readyQuoteDiscounts];

    const invalidAmountError = readyDiscounts.some(
      discount =>
        discount.percent === 0 ||
        discount.percent === null ||
        Number.isNaN(discount.percent) ||
        (discount.type === 'quote' && discount.amountAbove.amount === 0) ||
        (discount.type === 'quote' && Number.isNaN(discount.amountAbove.amount))
    );

    const duplicatedCatalogueError = checkDuplicateProductDiscounts(
      readyProductDiscounts as CompanyProductDiscount[]
    );

    const amountsAboveByCatalogue = quoteDiscounts.reduce(
      (acc: Record<string, number[]>, discount) => ({
        ...acc,
        [discount.catalogueSlug]: [
          ...(acc[discount.catalogueSlug] ?? []),
          discount.amountAbove.amount,
        ],
      }),
      {}
    );

    const duplicatedAmountAboveError = Object.values(amountsAboveByCatalogue).some(
      amountsAbove => new Set(amountsAbove).size !== amountsAbove.length
    );

    if (!invalidAmountError && !duplicatedCatalogueError && !duplicatedAmountAboveError) {
      replaceCompanyDiscounts.mutate({
        companyId,
        discounts: readyDiscounts,
      });
    } else if (invalidAmountError) {
      showErrorBanner(t('common.invalidAmountError'));
    } else if (duplicatedCatalogueError) {
      showErrorBanner(t('common.DUPLICATE_DISCOUNT_CATALOGUE_ERROR'));
    } else if (duplicatedAmountAboveError) {
      showErrorBanner(t('common.duplicateAmountAboveError'));
    }
  };

  return {
    productDiscounts,
    quoteDiscounts,
    isLoading: replaceCompanyDiscounts.isLoading || replaceCompanyDiscounts.isSuccess,
    setProductDiscounts,
    setQuoteDiscounts,
    applyHandler,
  };
}

// We are assigning fake IDs (negative integer values) to the newly added
// (and unsaved) discounts because we are using them as element keys. However,
// before we save them those fake IDs have to be removed.
const preprocess = (discount: CompanyDiscount): CompanyDiscount => {
  // TODO: refactor
  const { id } = discount;
  const num = parseInt(id ?? '-1');
  const newId = Number.isFinite(num) && num < 0 ? undefined : id;
  return { ...discount, id: newId };
};
