import React from 'react';
import { BankTransferPending } from './components/BankTransferPending';
import { Box, Button, Divider, Typography, useFormatPrice } from '@clippings/paper';
import { CONFIRM, SETUP } from 'libs/Quotes/Checkout/config/steps';
import { CardPaymentSuccess } from './components/CardPaymentSuccess';
import { Chat, ChatVisibilityProvider } from 'libs/Chat';
import { Header } from './components/Header';
import { Order } from 'libs/api/order/types';
import { Payment } from 'libs/api/common/types';
import { PaymentDetails } from 'libs/Quotes/Checkout/components/paymentDetails/PaymentDetails';
import { PaymentMethod } from 'libs/api/payment/types';
import { PaymentStatus } from 'libs/Quotes/Checkout/checkout.types';
import { useCheckout } from 'libs/Quotes/Checkout/hooks';
import { useTranslation } from 'react-i18next';

interface OrderPaymentViewProps {
  payment: Payment;
  order: Order;
  number: string;
}

export const OrderPaymentView: React.FC<OrderPaymentViewProps> = ({ payment, order, number }) => {
  const { t } = useTranslation();
  const formatPrice = useFormatPrice();
  /**
   * FIXME: Empty quote id is passed because the "create order" part of this hook is not used.
   * Unfortunately the checkout hook and context encompass multiple concerns
   * and splitting them up is not feasible at the moment
   */
  const checkout = useCheckout(payment, 0);
  const { paymentMethod, cardComplete, step, paymentStatus, error } = checkout.state;
  const { completePayment } = checkout.actions;

  const amount = formatPrice(payment.amount.amount, payment.amount.currency);

  const isSuccess = step === CONFIRM && paymentStatus !== PaymentStatus.Error;

  return (
    <Box height="100%" py={3} px={8}>
      <ChatVisibilityProvider>
        <Header number={number} salesRep={order.salesRepresentative} />
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          my={6}
        >
          {isSuccess && paymentMethod === PaymentMethod.Stripe && (
            <CardPaymentSuccess orderNumber={number} />
          )}

          {isSuccess && paymentMethod === PaymentMethod.BankTransfer && (
            <BankTransferPending orderNumber={number} />
          )}

          {(step === SETUP || (step === CONFIRM && paymentStatus === PaymentStatus.Error)) && (
            <>
              <Box mb={4} display="flex" flexDirection="column" gap={1} alignItems="center">
                <Typography data-testid="pay-order-title" variant="h5" fontWeight="normal">
                  {t('checkout.error.title', { amount })}
                </Typography>
                {error && (
                  <Typography
                    data-testid="pay-order-error"
                    color="error.main"
                    variant="subtitle1"
                    fontWeight="normal"
                  >
                    {error}
                  </Typography>
                )}
              </Box>
              <PaymentDetails
                amount={amount}
                paymentType={checkout.state.paymentMethod}
                setPaymentType={checkout.actions.onPaymentTypeSelect}
                disabled={checkout.loading}
              />

              {paymentMethod === PaymentMethod.Stripe && (
                <Button
                  sx={{ mt: 2 }}
                  onClick={completePayment}
                  data-testid="order-pay-card-button"
                  variant="contained"
                  disabled={!cardComplete || checkout.loading}
                >
                  {t('checkout.pay')} {amount}
                </Button>
              )}

              {paymentMethod === PaymentMethod.BankTransfer && (
                <>
                  <Typography mt={2} mb={1}>
                    {t('orders.onceYouveMadeTheTransfer')}:
                  </Typography>
                  <Button
                    onClick={completePayment}
                    data-testid="order-pay-bank-transfer-button"
                    variant="contained"
                    disabled={checkout.loading}
                  >
                    {t('common.confirmPayment')} {amount}
                  </Button>
                </>
              )}
            </>
          )}
        </Box>

        <Chat />
      </ChatVisibilityProvider>
    </Box>
  );
};
