import React, { PropsWithChildren, forwardRef } from 'react';
import { Box } from '@clippings/paper';
import { Column } from '../../components';
import { pdfContainerCommonStyles } from '../pdfExport.config';

export const PdfExportLayoutContainer = forwardRef<HTMLDivElement, PropsWithChildren>(
  function PdfExportLayoutContainer({ children }, ref) {
    return (
      <Column alignItems="center">
        <Box gap={1} ref={ref} data-testid="pdf-export" sx={boxSx} className="pdf-export-container">
          {children}
        </Box>
      </Column>
    );
  }
);

const boxSx = {
  backgroundColor: 'white',
  ...pdfContainerCommonStyles,
};
