import React from 'react';
import { Catalogue } from 'libs/api/catalogue/types';
import { CompanyQuoteDiscount } from 'libs/api/common/types';
import { FlexColumn } from '@clippings/paper';
import { QuoteDiscountTable } from './QuoteDiscountTable';
import { QuoteDiscountTableBody } from './QuoteDiscountTableBody';
import { QuoteDiscountTableHead } from './QuoteDiscountTableHead';
import { QuoteDiscountsTitle } from './QuoteDiscountsTitle';

type QuoteDiscountsProps = {
  catalogues: Catalogue[];
  quoteDiscounts: CompanyQuoteDiscount[];
  onChange: (discounts: CompanyQuoteDiscount[]) => void;
};

export function QuoteDiscounts({ quoteDiscounts, catalogues = [], onChange }: QuoteDiscountsProps) {
  const createNewDiscountHandler = () => {
    onChange([...quoteDiscounts, createNewDiscount(catalogues[0]?.currency, catalogues[0]?.slug)]);
  };

  return (
    <>
      <QuoteDiscountsTitle />
      <FlexColumn pr={3}>
        <QuoteDiscountTable onCreateNewDiscount={createNewDiscountHandler}>
          <QuoteDiscountTableHead />
          <QuoteDiscountTableBody
            catalogues={catalogues}
            discounts={quoteDiscounts}
            onChange={onChange}
          />
        </QuoteDiscountTable>
      </FlexColumn>
    </>
  );
}

////
let newItemIndex = -1;

const createNewDiscount = (currency: string, catalogueSlug = ''): CompanyQuoteDiscount => {
  // assign every new item a unique, negative index
  const id = String(newItemIndex);
  newItemIndex--;

  return {
    id,
    type: 'quote',
    percent: null,
    amountAbove: {
      amount: 100,
      currency,
    },
    catalogueSlug,
  };
};
