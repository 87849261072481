import { Channel, IChannel } from '../types';
import { ChannelSort, Channel as StreamChannel } from 'stream-chat';
import { useChatClient, userToConnect } from '../providers';
import { useEffect, useState } from 'react';

const filter = { type: Channel.Messaging, members: { $in: [userToConnect.id] } };
const sort: ChannelSort = [{ last_message_at: -1 }];

export const useUnreadChannels = (): {
  channels: IChannel[];
  error: any;
  loading: boolean;
} => {
  const { client, connected } = useChatClient();
  const [loading, setLoading] = useState(true);

  const [channels, setChannels] = useState<StreamChannel[]>([]);
  const [error, setError] = useState<any>(null);

  const checkUnreadChannels = async () => {
    try {
      const channelsResult = await client.queryChannels(filter, sort, {
        watch: true, // this is the default
        state: true,
      });
      const unreadChannels = channelsResult.filter(channel => channel.state.unreadCount > 0);
      setChannels(unreadChannels);
      setLoading(false);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (!connected) {
      return;
    }

    checkUnreadChannels();
    /**
     * @property {number} event.total_unread_count
     * @property {number} event.unread_channels
     */
    client.on(event => {
      if (event.unread_channels !== undefined) {
        checkUnreadChannels();
      }
    });
  }, [connected]);

  return {
    channels: channels as IChannel[],
    error,
    loading,
  };
};
