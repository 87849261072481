import { QuoteProductItem } from 'libs/api/quotes/types';
import { useQuoteProvider } from 'libs/Quotes/providers';

export function useOrdering() {
  const { updateProductItemNonContractualDataHandler } = useQuoteProvider();

  function reorderHandler(item: QuoteProductItem, newOrder: number) {
    updateProductItemNonContractualDataHandler!(
      { id: item.id, position: newOrder - 1 },
      'position'
    );
  }

  return { reorderHandler };
}
