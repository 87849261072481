import React from 'react';
import { ArrowBackIcon, Button, Grid } from '@clippings/paper';
import { ComponentHeader } from './ComponentHeader';
import { PaymentInfoSummary } from './PaymentInfoSummary';
import { PurchaseSummary } from './PurchaseSummary';
import { STEPS_CONFIG } from '../config/steps';
import { useQuoteCheckoutContext } from '../providers';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

const { setup, review } = STEPS_CONFIG;

export const Review = () => {
  const { t } = useTranslation();
  const { data, paymentType, immediatePayment, hasPaymentAmount, onBackStep } =
    useQuoteCheckoutContext();
  const { quote, paymentTermsQuery } = useQuoteProvider();

  return (
    <Grid pb={{ xs: 2, md: 0 }}>
      <Button onClick={onBackStep} color="secondary" sx={{ height: '40px', marginBottom: 1 }}>
        <ArrowBackIcon sx={{ marginRight: 1 }} /> {t(setup.title)}
      </Button>
      <ComponentHeader title={t(review.title)} description={t(review.description)} mb={3} />
      <PaymentInfoSummary
        item={quote}
        payments={paymentTermsQuery.data ?? []}
        poNumber={data.poNumber}
        paymentType={paymentType}
        immediatePayment={immediatePayment}
        hasPaymentAmount={hasPaymentAmount}
        title={t('common.summary')}
        containerProps={{ marginBottom: 3 }}
      />
      <PurchaseSummary hasPaymentAmount={hasPaymentAmount} />
    </Grid>
  );
};
