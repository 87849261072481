import React from 'react';
import { InboxSection } from 'libs/SalesApp/Inbox/InboxSection';
import { Typography } from '@clippings/paper';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useInboxCustomer } from 'libs/SalesApp/Inbox/hooks/useInboxCustomer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CustomerApplicationsSection: React.FC = () => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } = useInboxCustomer();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const users = data ? data.pages.flatMap(page => page) : [];
  const items = users.map(user => ({
    id: user.id,
    content: (
      <Typography
        variant="subtitle2"
        color="text.secondary"
        data-testid={`customer-application-${user.id}`}
      >
        {t('inbox.registrationPending')}
      </Typography>
    ),
    dateTime: user.createdAt,
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    companyName: user.company?.name ?? '',
    onClick: () => {
      navigate(path(routes.salesApp.userDetails, { userId: user.id }));
    },
  }));
  // @ts-ignore
  const title = `${t('inbox.customerApplications')} (${isLoading ? '...' : data?.totalItems})`;

  return (
    <InboxSection
      title={title}
      items={items}
      hasNextPage={hasNextPage ?? false}
      onNextClick={fetchNextPage}
      noResultsMessage={t('inbox.noCustomerApplications')}
      loading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
