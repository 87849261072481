import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Quote } from 'libs/api/quotes/types';
import { User } from 'libs/api/iam/types';
import { path } from 'libs/Utils';
import { reactQueryKeys, useQuoteCreate } from 'libs/api/quotes/hooks';
import { routes } from 'libs/Constants';
import { useBanner } from 'libs/Quotes/Components/AlertBanner';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useIsInSalesApp } from 'libs/shared';
import { useIsMutating } from '@tanstack/react-query';
import { useQuoteError } from 'libs/Quotes/hooks/useQuoteError';
import { userCanAccessSalesApp } from 'libs/Authentication/AuthenticationUtils';

export const onSuccessHandler = ({
  quote,
  currentUser,
  inSalesApp,
  navigate,
}: {
  quote: Quote;
  currentUser?: User;
  inSalesApp: boolean;
  navigate: NavigateFunction;
}) => {
  if (!currentUser) {
    return;
  }
  // In sales app
  if (inSalesApp) {
    navigate(path(routes.salesApp.quotes.show, { number: quote.number }));
    return;
  }
  // Sales person in customer app
  if (userCanAccessSalesApp(currentUser)) {
    window.location.href = `${window.location.origin}/sales/quotes/${quote.number}`;
    return;
  }

  // Default user
  navigate(path(routes.quotes.show, { number: quote.number }));
};

export const useCreateQuote = (): [ReturnType<typeof useQuoteCreate>, boolean] => {
  const { showErrorBanner } = useBanner();
  const { showError } = useQuoteError(showErrorBanner);
  const currentUser = useCurrentUser()?.data;
  const inSalesApp = useIsInSalesApp();
  const navigate = useNavigate();
  const isLoading = Boolean(useIsMutating({ mutationKey: [reactQueryKeys.create] }));
  const onSuccess = (quote: Quote) =>
    onSuccessHandler({ quote, currentUser, inSalesApp, navigate });

  const mutation = useQuoteCreate({
    onSuccess,
    onError: showError,
  });
  return [mutation, isLoading];
};
