import React from 'react';
import { Column, ListCardLoader } from 'libs/shared';
import { OrderItem } from 'libs/api/order/types';
import { OrderListItemCard } from './OrderListItemCard';
import { useOrderListItemCardRenderer } from './useOrderListItemCardRenderer';

type OrderCardListRendererProps = {
  items: OrderItem[];
  isLoading: boolean;
  loadMore?: JSX.Element;
};

export function OrderCardListRenderer({ items, isLoading, loadMore }: OrderCardListRendererProps) {
  const { viewDataModel } = useOrderListItemCardRenderer(items);

  if (isLoading) {
    return <ListCardLoader />;
  }

  return (
    <Column gap={1} data-testid="orders-card-list-renderer">
      {viewDataModel.map(item => {
        return <OrderListItemCard key={item.id} item={item} />;
      })}

      {loadMore}
    </Column>
  );
}
