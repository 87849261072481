import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Box } from '@clippings/paper';
import './LayoutSideNav.scss';

interface LayoutSideNavProps extends ComponentProps<typeof Box> {
  className?: string;
}

export const LayoutSideNav: FC<PropsWithChildren<LayoutSideNavProps>> = ({
  className = '',
  children,
  ...rest
}) => {
  return (
    <Box
      display={{ xs: 'none', md: 'block' }}
      className={classNames('layout-side-nav', className)}
      {...rest}
    >
      {children}
    </Box>
  );
};
