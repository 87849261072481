import React from 'react';
import { DateTime, DateTimeFormat, Grid } from '@clippings/paper';
import { ListTableRow } from 'libs/Components/ListTable/ListTableRow';
import { User } from 'libs/api/iam/types';
import { UserApprovalStatus } from 'libs/SalesApp/Users/staff/components/UserApprovalStatus';
import { UserListItemCompany } from './UserListItemCompany';
import { UserListItemContacts } from 'libs/SalesApp/Users/staff/components/UserListItemContacts';
import { UserListItemMoreActions } from 'libs/SalesApp/Users/staff/components/UserListItemMoreActions';
import { UserListItemName } from './UserListItemName';
import { UserListItemSimple } from 'libs/SalesApp/Users/staff/components/UserListItemSimple';
import { UserOnlineStatus } from 'libs/SalesApp/Users/staff/components/UserOnlineStatus';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useNavigate } from 'react-router-dom';

export interface UserListItemProps {
  user: User;
  hideCompanyColumn: boolean;
}

export const UserListItem: React.FC<UserListItemProps> = ({ user, hideCompanyColumn }) => {
  const navigate = useNavigate();

  const handleUserSelect = () => {
    navigate(path(routes.salesApp.userDetails, { userId: user.id }));
  };

  return (
    <ListTableRow
      data-testid={`customer-user-list-item-container-${user.id}`}
      columns={hideCompanyColumn ? 15 : 18}
      onClick={handleUserSelect}
    >
      <UserListItemName user={user} xs={3} />
      {!hideCompanyColumn && <UserListItemCompany user={user} xs={3} />}
      <UserListItemContacts user={user} xs={5} showCompanyWebsite />
      <UserListItemSimple
        xs={2}
        text={
          user.createdAt ? (
            <DateTime format={DateTimeFormat.Date} date={user.createdAt} variant="body2" />
          ) : (
            '---'
          )
        }
      />
      <Grid item xs={2}>
        <UserOnlineStatus user={user} />
      </Grid>
      <Grid item xs={2}>
        <UserApprovalStatus user={user} />
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="flex-end">
        <UserListItemMoreActions email={user.email} />
      </Grid>
    </ListTableRow>
  );
};
