/**
 * Checks if provided value is null or undefined
 * @param value Any value
 */
export const notEmpty = <TValue>(value: TValue | null | undefined): value is NonNullable<TValue> =>
  !(value === null || value === undefined);

/**
 * Converts obj with array values to obj with stringified values, separated by the specified separator string
 *
 * - Omits invalid properties (anything other than an array WITH values)
 * - Trims empty (null, undefined) and empty string values from array
 */
export const toObjectStringArray = <
  T extends Record<string, (string | number | null)[] | null | undefined>
>(
  obj: T,
  separator = ','
) => {
  return Object.keys(obj).reduce((result, key) => {
    const val = notEmpty(obj[key]) ? obj[key]!.filter(notEmpty).filter(x => x) : [];

    if (val.length > 0) {
      result[key as keyof T] = val.join(separator);
    }

    return result;
  }, {} as Partial<Record<keyof T, string>>);
};

export const isValidType = <T>(value: unknown, options: T[]): value is T =>
  options.includes(value as T);

export const filterNumbers = (values: unknown[]): number[] =>
  values
    .filter(notEmpty)
    .filter(x => x !== '')
    .map(Number)
    .filter(Number.isFinite);

export const preventDefaultPropagation = <Element = HTMLElement>(e: React.MouseEvent<Element>) => {
  e.preventDefault();
  e.stopPropagation();
};

export const removeNullValues = <T extends object>(obj: T) => {
  return Object.keys(obj).reduce((prev, curr) => {
    const currentValue = obj[curr as keyof T];
    return {
      ...prev,
      [curr]: currentValue === null ? undefined : currentValue,
    };
  }, {} as T);
};

export const arrayHasEntities = <T = any>(arr: T[]): boolean => {
  return arr?.length > 0;
};

/**
 * Return null even if the value is empty string
 *
 * When setting a query param value to null, the query param is removed from the URL instead of hanging with empty value, i.e. '&qp='.
 * @param value query param value
 */
export const getCleanQueryParamValue = (value: string | undefined | null) => value || null;

/* eslint-disable @typescript-eslint/no-empty-function */
export const voidFn = () => {};
