import React, { FC } from 'react';
import { Box, Button, Tooltip } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { QuoteTransitionError, QuoteTransitionErrorsEnum } from 'libs/SalesApp/Quotes/types';
import { useIsQuoteDocumentUploading } from 'libs/Quotes/hooks/useIsQuoteDocumentUploading';
import { useQuoteTransitionAction } from 'libs/shared/hooks/useQuoteTransitionAction';
import { useTranslation } from 'react-i18next';

export interface QuoteCheckoutButtonProps {
  quote: Quote;
  onClick: (errors: QuoteTransitionError[]) => void;
}

export const QuoteCheckoutButton: FC<QuoteCheckoutButtonProps> = ({ quote, onClick }) => {
  const { t } = useTranslation();
  const isUploadingDocument = useIsQuoteDocumentUploading(quote.number);

  const { shouldDisableTransitionButton, buildBlockingErrorMessages, errors } =
    useQuoteTransitionAction(quote, 'checkout', [
      QuoteTransitionErrorsEnum.MissingBillingAddress,
      QuoteTransitionErrorsEnum.InvalidShippingAddress,
      QuoteTransitionErrorsEnum.MissingShippingAddress,
    ]);
  const isDisabled = shouldDisableTransitionButton || isUploadingDocument;

  return (
    <Tooltip
      title={buildBlockingErrorMessages()}
      placement="top"
      data-testid="checkout-button-tooltip"
      disableHoverListener={!isDisabled}
    >
      <Box>
        <Button
          disabled={isDisabled}
          variant="contained"
          onClick={() => onClick(errors)}
          data-testid="quote-footer-action-buttons-checkout"
        >
          {t('quotes.footer.actions.checkout')}
        </Button>
      </Box>
    </Tooltip>
  );
};
