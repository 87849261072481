import React from 'react';
import { InboxSection } from 'libs/SalesApp/Inbox/InboxSection';
import { QuoteRequestContent } from 'libs/SalesApp/Inbox/QuoteRequestContent';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useInboxQuotes } from 'libs/SalesApp/Inbox/hooks/useInboxQuotes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const QuoteRequestSection: React.FC = () => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } = useInboxQuotes();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const quotes = data ? data.pages.flatMap(page => page.items) : [];
  const items = quotes.map(quote => ({
    id: quote.id,
    content: <QuoteRequestContent quote={quote} />,
    dateTime: quote.latestActivityAt,
    firstName: quote.customer?.firstName ?? '',
    lastName: quote.customer?.lastName ?? '',
    companyName: quote.customer?.company?.name ?? '',
    onClick: () => {
      navigate(path(routes.salesApp.quotes.show, { number: quote.number }));
    },
  }));

  // @ts-ignore
  const title = `${t('inbox.quoteRequests')} (${isLoading ? '...' : data?.totalItems})`;

  return (
    <InboxSection
      title={title}
      items={items}
      hasNextPage={hasNextPage ?? false}
      onNextClick={fetchNextPage}
      noResultsMessage={t('inbox.noQuoteRequests')}
      loading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
