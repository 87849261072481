import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames';
import { Box, Button } from '@clippings/paper';
import { CheckoutStatusView } from '../CheckoutStatusView/CheckoutStatusView';
import { PaymentDetails } from '../paymentDetails/PaymentDetails';
import { PaymentStatusIcons } from '../PaymentStatusIcons';
import { useQuoteCheckoutContext } from 'libs/Quotes/Checkout/providers';
import { useTranslation } from 'react-i18next';

export type ErrorProps = {
  containerProps?: ComponentProps<typeof Box>;
};

export const Error: FC<ErrorProps> = ({ containerProps }) => {
  const { t } = useTranslation();
  const { error, amount, paymentType, completePayment, setPaymentType } = useQuoteCheckoutContext();

  return (
    <Box
      data-testid="checkout-error-container"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...containerProps}
      className={classNames('checkout-error', containerProps?.className)}
    >
      {/* Status */}
      <CheckoutStatusView
        data-testid="checkout-error-status-view"
        sx={{
          marginBottom: '3em',
        }}
        icon={PaymentStatusIcons.error}
        title={t('checkout.error.title', { amount })}
        subtitle={t('checkout.error.subtitle')}
        error={error}
      />
      <PaymentDetails amount={amount} paymentType={paymentType} setPaymentType={setPaymentType} />
      <Button
        sx={{ marginTop: '1.5rem' }}
        onClick={completePayment}
        data-testid="checkout-error-pay-button"
        variant="contained"
      >
        {t('checkout.pay')} {amount}
      </Button>
    </Box>
  );
};
