import React, { ReactNode } from 'react';
import {
  ChevronRightIcon,
  DateTime,
  DateTimeFormat,
  FlexColumn,
  FlexRow,
  Typography,
} from '@clippings/paper';

export type InboxRowProps = {
  item: InboxRowItem;
};

export type InboxRowItem = {
  id: string | number;
  content: ReactNode;
  dateTime: string;
  firstName: string;
  lastName: string;
  companyName: string;
  onClick: () => void;
};

export function InboxRow({ item }: InboxRowProps) {
  const { onClick, firstName, lastName, companyName, dateTime, content } = item;

  return (
    <FlexColumn
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        border: theme => `1px solid ${theme.palette.grey.A200}`,
        borderRadius: 1,
        p: 2,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <FlexRow justifyContent="space-between" data-testid="inbox-row">
        <FlexRow gap={1} alignItems="center">
          <Typography variant="body1" fontWeight="bold">
            {firstName} {lastName}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            &middot;
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {companyName}
          </Typography>
        </FlexRow>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <DateTime format={DateTimeFormat.Time} date={dateTime} variant="caption" />
          <ChevronRightIcon />
        </Typography>
      </FlexRow>
      {content}
    </FlexColumn>
  );
}
