import { AxiosError } from 'axios';
import { Company } from 'libs/api/iam/types';
import { Confirmation, FomcoreAddressLocation, UnderscoreLocale } from '@clippings/paper';
import { Location } from 'libs/api/location/types';
import { Nullable } from 'libs/Utils';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';

/**
 * This model defines how to report validation errors to the client.
 */
export interface ValidationError {
  /**
   * Unique identifier of the validation error based on the constraint name. Currently we support the following contstraints
   */
  code: string;
  /**
   * Specific field violating the constraint.
   */
  field: string;
  /**
   * Detailed message describing the contraint violation.
   */
  message: string;
}

/**
 * Api Error model defining generic error returned by the API.
 */
export interface ApiError {
  /**
   * The date-time text presentation when the error occurs. The timestamp milliseconds since 1970 text presentation when the error occurs.
   */
  timestamp: number;
  /**
   * Operation status directly mapped to http status codes
   */
  status: number;
  /**
   * The exception stack trace (if configured)
   */
  trace?: string;
  /**
   * The exception message (if configured)
   */
  message?: string;
  /**
   * The URL path to the request that caused the problem
   */
  path: string;
  /**
   * Validation errors if any with details about the concrete violation happened
   */
  errors?: ValidationError[];
}

export type QueryOptions<T> = Omit<
  UseQueryOptions<T, AxiosError<ApiError>, T, any[]>,
  'queryFn' | 'queryKey'
>;

export type InfinitePaginatedQueryOptions<T> = Omit<
  UseInfiniteQueryOptions<Paginated<T>, AxiosError<ApiError>, T[], Paginated<T>, any[]>,
  'queryFn' | 'queryKey'
>;

export type InfiniteQueryOptions<T> = Omit<
  UseInfiniteQueryOptions<T, AxiosError<ApiError>, T, T, any[]>,
  'queryFn' | 'queryKey'
>;

export type MutationOptions<T, V> = Omit<
  UseMutationOptions<T, AxiosError<ApiError>, V>,
  'mutationFn'
>;

export interface Picture {
  /**  Entity identifier. */
  id: number;
  /** Cloudinary `asset_id` - the identifier of the uploaded asset. */
  remoteId: string;
  /** Cloudinary picture version */
  version: number;
  /** The identifier that's used for accessing and delivering the uploaded asset.  If not specified, then cloudinary will generate the Public ID  using random characters, or the original file's filename.  Notes: - The Public ID value should not include a file extension. - Can be up to 255 characters, including non-English characters,    periods (.), forward slashes (/), underscores (_), hyphens (-). - Public ID values cannot begin or end with a space or forward    slash (/). They cannot include the following characters:    ? & # \\ % < > + */
  public_id?: string; // eslint-disable-line camelcase
  /** The height of the provided image resource.    */
  height?: number;
  /** The width of the provided image resource. */
  width?: number;
  /** Format of the picture asset - 'png', 'jpg', ...etc. */
  format?: string;
}

export interface Money {
  amount: number;
  currency: string;
}

export type SortDirection = 'asc' | 'desc';

export interface Paginated<T> {
  currentPage: number;
  size: number;
  sortOrder: SortDirection;
  sortBy: string;
  totalItems: number;
  totalPages: number;
  items: T[];
}

// Payment
type PaymentRuleBaseData = {
  id?: string;
  percentage: Nullable<number>;
};

type BaseCompany = Pick<Company, 'id' | 'name' | 'billingAddresses' | 'vendorId' | 'team'>;

export interface BaseUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  picture?: Picture;
  company?: BaseCompany;
  guest: boolean;
}
export interface CustomPaymentRule extends PaymentRuleBaseData {
  type: 'custom';
  dueReason: string;
}

export interface EventPaymentRule extends PaymentRuleBaseData {
  type: 'event';
  dueEvent: string;
  dueDays: number;
}

export interface DatePaymentRule extends PaymentRuleBaseData {
  type: 'date';
  dueDate: string;
}

export type PaymentRule = CustomPaymentRule | EventPaymentRule | DatePaymentRule;
export enum PaymentStatus {
  Pending = 'pending',
  Paid = 'paid',
  Cancelled = 'cancelled',
}

export interface Payment {
  id: string;
  amount: Money;
  amountWithProcessingFee: Money;
  processingFeePercentage: number;
  paymentRule: PaymentRule;
  status: PaymentStatus;
  dueDate?: string;
  isImmediate: boolean;
  paidOn?: string;
}

/**
 * [min, max]
 */
export type LeadTime = [number, number];

// Address
interface BaseAddress {
  /** Entity identifier.    */
  id?: number;
  /** The name of the person to contact on the provided address. */
  contactName: string;
  /** The street/building number */
  address: string;
  /** For Fomcore only */
  address2?: string;
  /** The city name */
  city: string;

  state?: Location;
  stateId?: Nullable<number>;
  /** The province (if available) */
  province?: Nullable<string>;
  /** The county (if available) */
  county?: string;
  /** The address postal code */
  zip: string;
  country?: Location;
  countryId?: Nullable<number>;

  default?: boolean;
  editable?: boolean;
  companyAddress?: boolean;
}

/**
 * Used within the form component
 */
export type DeliveryHoursDate = [Date, Date];

/**
 * ["11:00", "15:00"] or in date-fns format: ["HH:mm", "HH:mm"]
 */
export type DeliveryHoursEpoch = [number, number];

export type FomcoreShippingAddressMetadata = {
  companyName: string;
  dock: Confirmation;
  attention?: string;
  trackingNotificationEmailAddress: string;
  locationType: FomcoreAddressLocation;
  locationTypeOther?: string;
};

/**
 * This is and address model that includes shipping details.
 */
export interface ShippingAddress extends BaseAddress {
  /** You can use this nickname to save the address,  and reference to it again later. */
  nickname?: string;
  /** Phone number. Parentheses, space and plus sign are allowed. */
  phone?: string;
  /** If you want to specify some edditional shipping details. */
  accessDetails?: string;
  metadata: Record<string, any> | FomcoreShippingAddressMetadata;
  email?: string;
  exWorks?: boolean;
}

/**
 * This is and address model that includes billing details.
 */
export interface BillingAddress extends BaseAddress {
  /** The email that will get the information about the bill. */
  email: string;
  /** The name of the company that will receive the bill. */
  companyName?: string;
  /** The VAT number of the company. */
  vatNumber?: Nullable<string>;
}

export type Address = ShippingAddress | BillingAddress;

export type AddressType = 'shipping' | 'billing';

// Discount
export enum QuoteDiscountType {
  FixedAmount = 'fixedAmount',
  Percentage = 'percentage',
}

export type SalesOrderDiscountBase = {
  id: number;
  name: string;
};
export type SalesQuoteDiscount = SalesOrderDiscountBase & {
  type: QuoteDiscountType;
  value: number;
  price: Money;
};
export type SalesOrderDiscount = SalesOrderDiscountBase & {
  amount: Money;
};

/** Discount applied on all products from a specific catalogue */
export interface CompanyProductDiscount {
  /** uuid */
  id?: string;
  /**  For ProductDiscount this must be 'product' */
  type: 'product';
  /** Float type percentage number from 0 to 100 */
  percent: Nullable<number>;
  catalogueSlug?: string;
  title: string;
  productCategories: string[];
}

/**Discount applied on quote level if the subtotal is >= amountAbove*/
export interface CompanyQuoteDiscount {
  /** uuid */
  id?: string;
  /**  For QuoteDiscount this must be 'quote' */
  type: 'quote';
  /** Float type percentage number from 0 to 100 */
  percent: Nullable<number>;
  catalogueSlug: string;
  amountAbove: Money;
}

export type CompanyDiscount = CompanyProductDiscount | CompanyQuoteDiscount;

export interface ReplaceDiscountsVariables {
  companyId: number;
  discounts: CompanyDiscount[];
}

export interface BaseListQueryParams {
  /** The page number */
  page?: number;
}

/**
 * This interface defines some common query params, that are usually supported
 * by all `listXYX` methods.
 */
export interface ListQueryParams extends BaseListQueryParams {
  /** The number of records per page */
  size?: number;
  /** Sort the records by specified comma-separated property names */
  sortBy?: string;
  /** The sorting order provided by the user or a default one */
  sortOrder?: Nullable<SortDirection>;
  /** Search the entries by some custom-defined criteria */
  query?: string;
}

// Translatable
export interface Translatable {
  locale: UnderscoreLocale;
  text: string;
}

export interface Editable {
  editable: boolean;
}
