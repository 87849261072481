import React, { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { DEBOUNCE_TIMINGS } from '../../Constants';
import { InputAdornment, SearchIcon, TextField } from '@clippings/paper';
import { KeyboardTooltip } from 'libs/Components/KeyboardTooltip';
import { Location, matchRoutes, useLocation } from 'react-router-dom';
import { QUERY_KEY } from './searchReducer';
import { debounce } from 'lodash';
import { getQueryParamFromUrl } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useSearch } from './useSearch';
import { useTranslation } from 'react-i18next';

const translationMap = {
  [routes.salesApp.companies]: 'companies',
  [routes.salesApp.staffUsers]: 'staffUsers',
  [routes.salesApp.users]: 'users',
  [routes.salesApp.orders.list]: 'orders',
  [routes.salesApp.quotes.active]: 'quotes',
  [routes.salesApp.quotes.expired]: 'quotes',
  [routes.salesApp.quotes.archived]: 'quotes',
  [routes.salesApp.teams]: 'teams',
} as const;

const isDisabled = (location: Location): boolean =>
  !matchRoutes(
    Object.keys(translationMap).map(key => ({ path: key })),
    location
  );

export const GlobalSearch: React.FC<ComponentProps<typeof TextField>> = ({ ...rest }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useSearch();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleSearch = useCallback(
    debounce(
      (value: string) => {
        setSearchValue(value);
      },
      DEBOUNCE_TIMINGS.SEARCH_DEBOUNCE_SLOW,
      { trailing: true }
    ),
    []
  );
  const functionCallback = useCallback(() => {
    setSearchValue(getQueryParamFromUrl(QUERY_KEY));
  }, []);

  // Handle search value change on browser back/forward
  useEffect(() => {
    window.addEventListener('popstate', functionCallback);
    return () => {
      window.removeEventListener('popstate', functionCallback);
    };
  }, []);

  // Handle search value change on location pathname change
  useEffect(functionCallback, [location.pathname]);

  // update input value when changing search reducer value since the input is not controlled due to using debounce
  if (inputRef.current && inputRef.current.value !== searchValue) {
    inputRef.current.value = searchValue;
  }

  const handleSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(event.target.value);
  };

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    if (event.key === '/') {
      setTimeout(() => inputRef.current?.focus());
    }
  };

  // The placeholder is determined if the path is defined in transation.
  // If the placeholder is not set, the search field will be disabled.
  const pageName = translationMap[location.pathname as keyof typeof translationMap];
  const placeholder = t(`searchPlaceholders.${pageName}`, '');
  const disabled = isDisabled(location);

  if (disabled) {
    return null;
  }

  return (
    <KeyboardTooltip
      placement="bottom"
      title={t('common.search')}
      keyLabel="/"
      handler={handleKeyboardEvent}
      open={isTooltipOpen}
    >
      <TextField
        size="small"
        color="secondary"
        // We are using inputRef, because we want the filed value to be changed as
        // you type, while the context value is changed, when the user stops typing.
        inputRef={inputRef}
        placeholder={placeholder}
        defaultValue={searchValue}
        type="search"
        onChange={handleSearchValueChanged}
        onFocus={() => setIsTooltipOpen(false)}
        onMouseOver={() => {
          if (!inputRef.current?.matches(':focus')) {
            setIsTooltipOpen(true);
          }
        }}
        onMouseOut={() => setIsTooltipOpen(false)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color={disabled ? 'disabled' : 'inherit'} />
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </KeyboardTooltip>
  );
};
