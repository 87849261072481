import { Translatable } from 'libs/api/common/types';
import { convertDashToUnderscore } from '@clippings/paper';
import { useUserLocale } from './locales';

export const useTranslatable = () => {
  const { userLocale } = useUserLocale();

  return (translatables: Translatable[]) => {
    const currentUserLocale = convertDashToUnderscore(userLocale);
    const translatable =
      translatables.find(v => v.locale === currentUserLocale) ?? translatables[0];

    return translatable ? translatable.text : '';
  };
};
