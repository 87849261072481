import React from 'react';
import { FlexRow, Skeleton } from '@clippings/paper';
import { pdfContainerCommonStyles } from '../pdfExport.config';

export function PdfSkeleton() {
  return (
    <FlexRow justifyContent="center">
      <Skeleton height="84vh" variant="rectangular" sx={skeletonSx} />
    </FlexRow>
  );
}

const skeletonSx = {
  ...pdfContainerCommonStyles,
  margin: 3,
  borderRadius: 4,
};
