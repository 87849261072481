import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

export const TotalsSummaryRowValue: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  ...rest
}) => (
  <div className="totals-summary-row-value" {...rest}>
    {children}
  </div>
);
