import React from 'react';
import { DateTime, DateTimeFormat, Typography } from '@clippings/paper';
import { QuoteVersion } from 'libs/api/quotes/types';
import { useTranslation } from 'react-i18next';

interface QuoteVersionMenuLabelProps {
  currentVersion: QuoteVersion;
}

export const QuoteVersionMenuLabel = ({ currentVersion }: QuoteVersionMenuLabelProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="caption" mr={1} data-testid="quote-version-number">
        {t('quotes.version')} {currentVersion.versionNumber}
      </Typography>
      (
      {currentVersion.active ? (
        <Typography variant="caption" color="secondary" data-testid="quote-version-date">
          {t('quotes.latest')}
        </Typography>
      ) : (
        <DateTime
          format={DateTimeFormat.Date}
          date={currentVersion.createdAt}
          data-testid="quote-version-date"
          variant="caption"
          color="secondary"
        />
      )}
      )
    </>
  );
};
