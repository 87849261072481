import React from 'react';
import { CardSkeleton } from '../CardSkeleton';
import { times } from 'lodash';

export function ListCardLoader() {
  return (
    <>
      {times(3).map(idx => (
        <CardSkeleton key={idx} />
      ))}
    </>
  );
}
