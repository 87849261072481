import React, { ComponentProps } from 'react';
import {
  Brand,
  Button,
  FomcoreAddressLocation,
  Grid,
  GridProps,
  Typography,
} from '@clippings/paper';
import { ComponentGridContainer, DataTestId } from 'libs/shared';
import { ComponentHeader } from './ComponentHeader';
import { Order } from 'libs/api/order/types';
import { Payment } from 'libs/api/common/types';
import { PaymentMethod } from 'libs/api/payment/types';
import { PaymentMethodInfo } from '../utils/paymentMethod';
import { PaymentTermRow } from '../../Components/TotalsSummary/PaymentTermRow';
import { Quote } from 'libs/api/quotes/types';
import { isEmpty } from 'lodash';
import { useAppConfiguration } from 'libs/providers';
import { useIntendedUse } from 'libs/shared/hooks/useIntendedUse';
import { useTranslation } from 'react-i18next';
import { valueToYesNoTranslation } from 'libs/api/common/utils';

type PaymentInfoSummaryProps = {
  item: Quote | Order;
  payments: Payment[];
  poNumber?: string;
  title?: string;
  paymentType?: PaymentMethod;
  immediatePayment?: boolean;
  hasPaymentAmount: boolean;
  containerProps?: GridProps;
  gridXsSize?: number;
  gridItemsSxSize?: number;
  onViewPaymentScheduleClick?: () => void;
};

export const PaymentInfoSummary: React.FC<PaymentInfoSummaryProps> = ({
  item,
  payments,
  poNumber,
  title,
  paymentType,
  immediatePayment,
  hasPaymentAmount,
  containerProps,
  gridXsSize = 12,
  gridItemsSxSize = 6,
  onViewPaymentScheduleClick,
}) => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();
  const { shippingAddress, billingAddress } = item;
  const md = brand === Brand.Fomcore ? 3 : 4;
  const shouldRenderMetadata =
    brand === Brand.Fomcore && shippingAddress?.metadata && !isEmpty(shippingAddress?.metadata);
  const { enabled, getLabel } = useIntendedUse();

  return (
    <ComponentGridContainer
      data-testid={`payment-info-summary${item.id}`}
      {...containerProps}
      padding={styles.gridContainer.padding}
    >
      {title && <ComponentHeader title={title} />}
      <Grid container>
        <Grid xs={gridXsSize} md={md} sx={styles.gridFirst} item>
          <Grid container>
            {shippingAddress ? (
              <>
                {!shippingAddress.exWorks && (
                  <Grid item xs={gridItemsSxSize} md={12}>
                    <Heading marginTop={0} data-testid="payment-info-summary-shipto">
                      {t('common.shipTo')}
                    </Heading>
                    <Info data-testid="payment-info-shipping-nickname">
                      {shippingAddress.nickname}
                    </Info>
                    <Info data-testid="payment-info-shipping-address">
                      {shippingAddress.address}
                    </Info>
                    {shippingAddress.address2 && (
                      <Info data-testid="payment-info-shipping-address2">
                        {shippingAddress.address2}
                      </Info>
                    )}
                    <Info data-testid="payment-info-shipping-city">{shippingAddress.city}</Info>
                    <Info data-testid="payment-info-shipping-zip">{shippingAddress.zip}</Info>
                  </Grid>
                )}

                <Grid item xs={gridItemsSxSize} md={12}>
                  <Heading
                    data-testid="payment-info-heading-primaryContact"
                    marginTop={shippingAddress.exWorks ? 0 : { xs: 0, md: 3 }}
                  >
                    {t('common.primaryContact')}
                  </Heading>
                  <Info data-testid="payment-info-shipping-primaryContact-contactName">
                    {shippingAddress.contactName}
                  </Info>
                  <Info data-testid="payment-info-shipping-primaryContact-email">
                    {shippingAddress.email}
                  </Info>
                  <Info
                    data-testid="payment-info-shipping-primaryContact-phone"
                    marginBottom={{ xs: 3 }}
                  >
                    {shippingAddress.phone}
                  </Info>

                  <Heading data-testid="payment-info-shipping-method">
                    {t('common.shippingMethod')}
                  </Heading>
                  <Info data-testid="payment-info-address">
                    {item.deliveryItems.map(d => d.name).join(',')}
                  </Info>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>

        {shouldRenderMetadata && (
          <Grid xs={gridXsSize} md={md} sx={styles.grid} paddingX={styles.paddingX} item>
            <Grid container>
              <Grid item xs={gridItemsSxSize} md={12}>
                <Heading data-testid="payment-info-heading-companyName">
                  {t('common.companyName')}
                </Heading>
                <Info data-testid="payment-info-shipping-address-companyName">
                  {shippingAddress.metadata?.companyName}
                </Info>
              </Grid>
              <Grid item xs={gridItemsSxSize} md={12}>
                <Heading data-testid="payment-info-heading-location-type">
                  {t('quotes.addresses.locationType')}
                </Heading>
                <Info data-testid="payment-info-address-location-type">
                  {t(
                    `common.locationTypes.${
                      shippingAddress.metadata.locationType as FomcoreAddressLocation
                    }`
                  )}
                </Info>
                {shippingAddress.metadata.locationType === FomcoreAddressLocation.Other &&
                  shippingAddress.metadata.locationTypeOther && (
                    <Info data-testid="payment-info-location-type-other">
                      {shippingAddress.metadata.locationTypeOther}
                    </Info>
                  )}
              </Grid>
              <Grid item xs={gridItemsSxSize} md={12}>
                <Heading data-testid="payment-info-heading-trackingNotificationEmailAddress">
                  {t('common.trackingNotificationEmailAddress')}
                </Heading>
                <Info data-testid="payment-info-shipping-address-trackingNotificationEmailAddress">
                  {shippingAddress.metadata?.trackingNotificationEmailAddress}
                </Info>
              </Grid>
              <Grid item xs={gridItemsSxSize} md={12}>
                <Heading data-testid="payment-info-heading-dock">
                  {t('quotes.addresses.dock')}
                </Heading>
                <Info data-testid="payment-info-shipping-address-has-dock">
                  {t(valueToYesNoTranslation(shippingAddress.metadata.dock))}
                </Info>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid xs={gridXsSize} md={md} item sx={styles.grid} paddingX={styles.paddingX}>
          <Grid container>
            {billingAddress && (
              <>
                <Grid item xs={gridItemsSxSize} md={12}>
                  <Heading marginTop={0} data-testid="payment-info-summary-billTo">
                    {t('common.billTo')}
                  </Heading>
                  <Info data-testid="payment-info-billingCompanyName">
                    {billingAddress.companyName}
                  </Info>
                  <Info data-testid="payment-info-billing-address">{billingAddress.address}</Info>
                  {billingAddress.address2 && (
                    <Info data-testid="payment-info-billing-address2">
                      {billingAddress.address2}
                    </Info>
                  )}
                  <Info data-testid="payment-info-billing-city">{billingAddress.city}</Info>
                  <Info data-testid="payment-info-billing-zip">{billingAddress.zip}</Info>
                </Grid>

                <Grid item xs={gridItemsSxSize} md={12}>
                  <Heading data-testid="payment-info-heading-billing-contact">
                    {t('common.billingContact')}
                  </Heading>
                  <Info data-testid="payment-info-billing-contact-contactName">
                    {billingAddress.contactName}
                  </Info>
                  <Info data-testid="payment-info-billing-contact-email">
                    {billingAddress.email}
                  </Info>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid xs={gridXsSize} md={md} item sx={styles.gridLast} paddingX={styles.paddingX}>
          {hasPaymentAmount && paymentType && (
            <>
              <Heading marginTop={0} data-testid="payment-info-summary-payment-method">
                {t('common.paymentMethod')}
              </Heading>
              <Info data-testid="payment-info-summary-payment-method-value">
                {immediatePayment ? t(PaymentMethodInfo[paymentType].label) : 'Not required'}
              </Info>
            </>
          )}

          {hasPaymentAmount ? (
            <>
              <Heading data-testid="payment-info-summary-payment-terms">
                {t('paymentTerms.paymentTerms')}
              </Heading>
              {payments.map((payment, idx) => (
                <PaymentTermRow
                  key={payment.id}
                  idx={idx + 1}
                  size="medium"
                  paymentTerm={payment}
                />
              ))}
            </>
          ) : null}

          {hasPaymentAmount && onViewPaymentScheduleClick && (
            <Button
              data-testid="view-payment-schedule-button"
              sx={styles.button}
              variant="outlined"
              color="primary"
              onClick={onViewPaymentScheduleClick}
            >
              {t('common.viewPaymentSchedule')}
            </Button>
          )}

          {poNumber && (
            <>
              <Heading data-testid="payment-info-summary-poNumber-title">
                {t('checkout.purchaseOrder.title')}
              </Heading>
              <Info data-testid="payment-info-summary-poNumber">{poNumber}</Info>
            </>
          )}
          {enabled && (
            <>
              <Heading data-testid="payment-info-summary-intendedUse-title">
                {t('intendedUse.label')}
              </Heading>
              <Info data-testid="payment-info-summary-intendedUse">
                {getLabel(item.metadata?.intendedUse)}
              </Info>
            </>
          )}
        </Grid>
      </Grid>
    </ComponentGridContainer>
  );
};

const styles = {
  gridContainer: {
    padding: {
      xs: 2,
      md: 3,
    },
  },
  gridFirst: {
    paddingBottom: {
      xs: 2,
      md: 0,
    },
    borderColor: 'grey.600',
  },
  grid: {
    paddingY: {
      xs: 2,
      md: 0,
    },
    borderLeft: {
      md: '1px solid',
    },
    borderColor: 'grey.600',
  },
  gridLast: {
    paddingTop: {
      xs: 2,
      md: 0,
    },
    borderLeft: {
      md: '1px solid',
    },
    borderBottom: {
      xs: 'none',
    },
    borderColor: 'grey.600',
  },
  paddingX: {
    xs: 0,
    md: 3,
  },
  button: {
    marginTop: 1,
    marginBottom: 2,
    fontWeight: 600,
  },
};

const Heading: React.FC<
  React.PropsWithChildren<DataTestId & ComponentProps<typeof Typography>>
> = ({ children, ...rest }) => (
  <Typography
    fontWeight="fontWeightMedium"
    marginTop={{ xs: 0, md: 3 }}
    marginBottom={{ xs: 0, md: 0.5 }}
    {...rest}
  >
    {children}
  </Typography>
);

const Info: React.FC<React.PropsWithChildren<DataTestId> & ComponentProps<typeof Typography>> = ({
  children,
  ...rest
}) => (
  <Typography color="grey.600" variant="body2" sx={{ wordWrap: 'break-word' }} {...rest}>
    {children}
  </Typography>
);
