import React from 'react';
import { Box, Button, Tooltip } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { useQuoteTransitionAction } from 'libs/shared/hooks/useQuoteTransitionAction';
import { useTranslation } from 'react-i18next';

export interface QuoteRestoreButtonProps {
  quote: Quote;
  onClick: () => void;
}

export const QuoteRenewButton: React.FC<QuoteRestoreButtonProps> = ({ quote, onClick }) => {
  const { t } = useTranslation();
  const { shouldDisableTransitionButton, buildBlockingErrorMessages } = useQuoteTransitionAction(
    quote,
    'renew',
    []
  );

  return (
    <Tooltip
      title={buildBlockingErrorMessages()}
      placement="top"
      data-testid="checkout-button-tooltip"
      disableHoverListener={!shouldDisableTransitionButton}
    >
      <Box>
        <Button
          variant="contained"
          onClick={onClick}
          data-testid="quote-footer-action-buttons-renew"
          disabled={shouldDisableTransitionButton}
        >
          {t('quotes.footer.actions.renew')}
        </Button>
      </Box>
    </Tooltip>
  );
};
