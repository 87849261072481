import React, { PropsWithChildren, SyntheticEvent, useState } from 'react';
import { Box, Tab, Tabs, useTranslation } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { CompanyDetailsPanel } from './CompanyDetailsPanel/CompanyDetailsPanel';
import { UserDetailsPanel } from 'libs/SalesApp/Companies/details/components/UsersDetailsPanel';

type CompanyDetailsTabsProps = {
  company: Company;
};

export function CompanyDetailsTabs({ company }: CompanyDetailsTabsProps) {
  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <>
      <Box borderBottom={1} borderColor="divider" data-testid="company-details-tabs">
        <Tabs value={selectedTabIndex} onChange={handleChange}>
          <Tab label={t('common.details')} />
          <Tab label={t('common.users')} />
        </Tabs>
      </Box>

      <TabPanel value={selectedTabIndex} index={0}>
        <CompanyDetailsPanel
          company={company}
          canEditCompany={company.editable}
          canEditAddresses={company.editable}
        />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <UserDetailsPanel company={company} />
      </TabPanel>
    </>
  );
}

///
type TabPanelProps = {
  index: number;
  value: number;
};

const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({ children, value, index }) =>
  value === index ? <Box role="tabpanel">{children}</Box> : null;
