import React from 'react';
import { BaseUser } from 'libs/api/common/types';
import { ChatButton } from 'libs/Chat';
import { Grid, ShowElement, Skeleton } from '@clippings/paper';
import { HelpLabel } from 'libs/Components';

interface NeedHelpProps {
  toggleChat: () => void;
  salesRep: BaseUser | undefined;
  loading?: boolean;
}

export const NeedHelp: React.FC<NeedHelpProps> = ({ loading, toggleChat, salesRep }) => {
  if (loading) {
    return (
      <Skeleton
        height="40px"
        sx={{ width: { xs: '130px', md: '300px' }, borderRadius: 1 }}
        variant="rectangular"
      />
    );
  }

  return (
    <Grid data-testid="order-need-help" display="flex" alignItems="center" gap={2}>
      {salesRep ? (
        <ShowElement above="md">
          <HelpLabel salesRep={salesRep} />
        </ShowElement>
      ) : null}
      <ChatButton quoteSalesRep={salesRep} onClick={toggleChat} />
    </Grid>
  );
};
