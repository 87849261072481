import React from 'react';
import { DataTestId } from 'libs/shared';
import { Grid, GridProps } from '@clippings/paper';

export const ListTableRow = <T extends React.ElementType>({
  children,
  sx,
  onClick,
  ...rest
}: GridProps<T> & DataTestId) => {
  return (
    <Grid
      className="list-table-row"
      container
      p={2}
      columnSpacing={1}
      flexDirection="row"
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        border: theme => `1px solid ${theme.palette.grey.A200}`,
        borderRadius: 1,
        color: 'inherit',
        textDecoration: 'inherit',
        width: '100%',
        ml: 0,
        ...(onClick && { cursor: 'pointer' }),
        ...sx,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Grid>
  );
};
