import React from 'react';
import { Brand, FormPercentageTextField, FormTextField, MenuItem, yup } from '@clippings/paper';
import { FormPhoneField } from 'libs/shared';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inactive';

export const STATUSES = {
  active: {
    label: 'common.active',
    value: STATUS_ACTIVE,
  },
  inactive: {
    label: 'common.inactive',
    value: STATUS_INACTIVE,
  },
};

const fieldsConfig: Record<Brand, Array<string>> = {
  [Brand.Fomcore]: ['name', 'type', 'phone', 'website', 'email', 'status'],
  [Brand.Sossego]: [
    'name',
    'registrationNumber',
    'type',
    'website',
    'phone',
    'email',
    'creditCardChargeFee',
    'status',
  ],
  [Brand.Moroso]: [
    'name',
    'registrationNumber',
    'taxNumber',
    'taxRate',
    'type',
    'website',
    'phone',
    'email',
    'status',
  ],
};

export const getMetadataValidation = (brand: Brand | null) => {
  if (brand === Brand.Moroso) {
    const metadataFields = {
      priority: yup.string().required('companies.priorityRequired'),
      paymentMethod: yup.string().required('companies.paymentMethodRequired'),
      bankName: yup.string().optional(),
      bankIBAN: yup.string().optional(),
      generalNotes: yup.string().optional(),
      department: yup.string().optional(),
    };

    return yup.object().when('country', {
      is: 'IT',
      then: yup.object().shape(
        {
          pec: yup.string().when('sdi', {
            is: (val: string) => !!val,
            then: yup.string().optional(),
            otherwise: yup.string().required('companies.pecOrSdiRequired'),
          }),
          sdi: yup.string().when('pec', {
            is: (val: string) => !!val,
            then: yup.string().optional(),
            otherwise: yup.string().required('companies.pecOrSdiRequired'),
          }),
          ...metadataFields,
        },
        [['pec', 'sdi']]
      ),
      otherwise: yup.object().shape(metadataFields),
    });
  }

  return yup.object().optional();
};

export const CompanyFormFields: React.FC = () => {
  const { t } = useTranslation();
  const { companyTypes, brand } = useAppConfiguration();

  const fields: Record<string, JSX.Element> = {
    name: <FormTextField key="name" name="name" label={t('common.companyName')} />,
    registrationNumber: (
      <FormTextField
        key="registrationNumber"
        name="registrationNumber"
        label={t('common.registrationNumber')}
      />
    ),
    taxNumber: <FormTextField key="taxNumber" name="taxNumber" label={t('common.vatNumber')} />,
    taxRate: (
      <FormPercentageTextField
        key="taxRate"
        name="taxRate"
        label={t('common.vat')}
        sx={{ mb: 2 }}
      />
    ),
    type: (
      <FormTextField key="type" select name="type" label={t('common.companyType')}>
        {companyTypes.map(companyType => (
          <MenuItem
            data-testid={`company-type-item-${companyType}`}
            key={companyType.value}
            value={companyType.value}
          >
            {t(companyType.label as any)}
          </MenuItem>
        ))}
      </FormTextField>
    ),
    website: <FormTextField key="website" name="website" label={t('common.website')} />,
    creditCardChargeFee: (
      <FormPercentageTextField
        key="creditCardChargeFee"
        name="creditCardChargeFee"
        label={t('common.creditCardChargeFee')}
        inputProps={{
          'data-testid': 'credit-card-charge-fee',
        }}
      />
    ),
    phone: <FormPhoneField key="phone" name="phone" label={t('common.phoneNumber')} />,
    status: (
      <FormTextField key="status" select name="status" label={t('common.status')}>
        {Object.values(STATUSES).map(status => (
          <MenuItem
            data-testid={`company-status-item-${status.value}`}
            key={status.value}
            value={status.value}
          >
            {t(status.label as any)}
          </MenuItem>
        ))}
      </FormTextField>
    ),
  };

  return <>{fieldsConfig[brand ?? Brand.Moroso].map(field => fields[field])}</>;
};
