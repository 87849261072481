import React, { useState } from 'react';
import { Box, SystemUser, Typography } from '@clippings/paper';
import { CreateUserButton } from '../components/CreateUserButton/CreateUserButton';
import { CustomerUserDialogWrapper } from './CustomerUserDialogWrapper';
import { DecodedValueMap, useQueryParams } from 'use-query-params';
import { DividerGrey, makeColumns, makeQueryParamsMap, useTableSorting } from 'libs/shared';
import { EmptyUserTablePlaceholder } from '../components';
import { PageHeader } from 'libs/SalesApp/components/PageHeader';
import { UserListBlock, cellConfig } from './components/UserListBlock';
import { UserPermission, UserStatus } from 'libs/api/iam/types';
import { UserStatusFilter } from './components/UserStatusFilter';
import { useAppConfiguration } from 'libs/providers';
import { useCurrentUser, useListInfiniteUsersV2 } from 'libs/api/iam/hooks';
import { useSearch } from 'libs/SalesApp/Header/useSearch';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

const columns = makeColumns(cellConfig);
const queryParamsMap = makeQueryParamsMap(columns);
type UsersQueryParams = Partial<DecodedValueMap<typeof queryParamsMap>>;

export const CustomerUsersPage: React.FC = () => {
  const { t } = useTranslation();

  useTitle(t('common.users'));

  const currentUser = useCurrentUser()?.data;

  const appConfiguration = useAppConfiguration();
  const [statusFilter, setStatusFilter] = useState<UserStatus | undefined>();
  const [searchValue, setSearchValue] = useSearch();

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams(queryParamsMap);
  const { sort, updateSorting } = useTableSorting<UsersQueryParams>(queryParams, setQueryParams);

  // We're going to use the same `order` and `sort` for both queries
  const pendingUsersQuery = useListInfiniteUsersV2({
    roles: 'business_customer',
    statuses: UserStatus.Pending,
    sortBy: sort.sort,
    sortOrder: sort.order,
  });

  const oldUsersQuery = useListInfiniteUsersV2({
    query: searchValue,
    roles: 'business_customer',
    statuses:
      statusFilter || searchValue ? statusFilter : `${UserStatus.Approved},${UserStatus.Rejected}`,
    sortBy: sort.sort,
    sortOrder: sort.order,
  });

  const showPendingUsers =
    !searchValue &&
    statusFilter === undefined &&
    pendingUsersQuery.data &&
    pendingUsersQuery.data.pages.flat().length > 0;

  const hasFilters = !!statusFilter || !!searchValue;

  const onReset = () => {
    setStatusFilter(undefined);
    setSearchValue('');
  };

  return (
    <Box>
      <PageHeader title={t('common.users')} titleTestId="customer-users-page-title">
        {appConfiguration.enableCreateCustomerUser &&
          currentUser &&
          currentUser.permissions.includes(UserPermission.CanCreateUsers) && (
            <CreateUserButton
              data-testid="create-customer-user-button"
              onClick={() => setIsUserModalOpen(true)}
              label={t('users.newUser')}
            />
          )}
      </PageHeader>
      {/* status filter */}
      <DividerGrey />
      <Box
        data-testid="user-status-filter-container"
        py={1}
        display="flex"
        justifyContent="flex-end"
      >
        <UserStatusFilter value={statusFilter} onChange={setStatusFilter} />
      </Box>
      <DividerGrey sx={{ mb: 1 }} />
      {showPendingUsers && (
        <>
          <Typography variant="subtitle1" fontWeight="fontWeightBold" pt={2}>
            {t('users.newUsers')}
          </Typography>
          <UserListBlock
            hideCompanyColumn={false}
            query={pendingUsersQuery}
            data-testid="customer-users-table-pending-users"
            sort={sort}
            onSort={updateSorting}
          />
        </>
      )}
      {!hasFilters && showPendingUsers && (
        <Typography variant="subtitle1" fontWeight="fontWeightBold" pt={2}>
          {t('common.users')}
        </Typography>
      )}
      <UserListBlock
        hideCompanyColumn={false}
        query={oldUsersQuery}
        placeholder={
          <EmptyUserTablePlaceholder
            filtered={hasFilters}
            onReset={onReset}
            onCreate={() => setIsUserModalOpen(true)}
            type={SystemUser.Customer}
          />
        }
        sort={sort}
        onSort={updateSorting}
      />
      <CustomerUserDialogWrapper
        isUserModalOpen={isUserModalOpen}
        setIsUserModalOpen={setIsUserModalOpen}
      />
    </Box>
  );
};
