import React, { ComponentProps, FC } from 'react';
import { Box, Typography } from '@clippings/paper';

export type CheckoutStatusViewProps = Omit<ComponentProps<typeof Box>, 'title'> & {
  icon: React.ReactNode;
  title: React.ReactNode;
  subtitle: string;
  error?: string;
};
export const CheckoutStatusView: FC<CheckoutStatusViewProps> = ({
  icon,
  title,
  subtitle,
  error,
  ...rest
}) => {
  return (
    <Box
      data-testid="checkout-status-view-container"
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      {...rest}
    >
      <Box sx={{ marginBottom: 3 }}>{icon}</Box>
      {error && (
        <Typography variant="body2" color="error" sx={{ marginBottom: 1 }}>
          {error}
        </Typography>
      )}
      <Typography data-testid="checkout-status-view-title" variant="h5" fontWeight="normal">
        {title}
      </Typography>
      <Typography data-testid="checkout-status-view-subtitle" variant="subtitle1" color="secondary">
        {subtitle}
      </Typography>
    </Box>
  );
};
