import React from 'react';
import { Box, Button, Checkbox, Divider, ShowElement, Typography } from '@clippings/paper';
import { Column, Row } from 'libs/shared';
import { useQuoteCheckoutContext } from '../../providers';
import { useTranslation } from 'react-i18next';

export function FooterStepReview() {
  const { t } = useTranslation();
  const {
    data,
    toggleTermsAndCondition,
    toggleTermsAndConditionModal,
    onSignPayClick,
    immediatePayment,
    amount,
  } = useQuoteCheckoutContext();

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
      <Column>
        <Row alignItems="center" maxHeight="22px">
          <Checkbox
            checked={data.termsAndConditionsAccepted}
            label={t('common.iAgreeTo')}
            sx={{
              fontSize: theme => theme.typography.pxToRem(16),
              margin: '2px 6px 2px',
              padding: '2px',
              '&.MuiButtonBase-root': {
                top: '10px',
              },
            }}
            onClick={toggleTermsAndCondition}
          />
          <Button
            onClick={toggleTermsAndConditionModal}
            data-testid="checkout-overview-terms-conditions"
            variant="text"
            sx={{ fontSize: theme => theme.typography.pxToRem(16), ml: '-18px' }}
          >
            {t('common.termsAndConditions')}
          </Button>
        </Row>
        <Typography variant="caption" color="secondary" ml="30px">
          {t('common.confirmCorrectSelection')}
        </Typography>
      </Column>
      <ShowElement below="md">
        <Divider sx={{ my: 2, borderColor: 'grey.A400' }} />
      </ShowElement>
      <Button
        onClick={onSignPayClick}
        data-testid="checkout-overview-sign-pay"
        sx={{ marginLeft: '1rem', fontSize: '15px' }}
        variant="contained"
      >
        {immediatePayment ? `${t('checkout.signAndPay')} ${amount}` : t('checkout.signAndOrder')}
      </Button>
    </Box>
  );
}
