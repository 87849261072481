import React, { useState } from 'react';
import { TableRow } from '@clippings/paper';

type PaymentRuleTableRowProps = {
  idx: number;
  children: (anchorElement: HTMLElement | null) => React.ReactNode;
};

export function PaymentRuleTableRow({ idx, children }: PaymentRuleTableRowProps) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const showActionPanel = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElement(event.currentTarget);

  const hideActionPanel = () => setAnchorElement(null);

  return (
    <TableRow
      onMouseEnter={showActionPanel}
      onMouseLeave={hideActionPanel}
      data-testid={`row-${idx}`}
    >
      {children(anchorElement)}
    </TableRow>
  );
}
