import React, { FC } from 'react';
import { ChatQuoteSalesRepJoinedMessage } from './ChatQuoteSalesRepJoinedMessage';
import { ChatQuoteStatusChangeMessage } from './ChatQuoteStatusChangeMessage';
import { ChatQuoteVersionMessage } from './ChatQuoteVersionMessage';
import { ChatSystemQuoteMessage, SystemStreamChatGenerics } from 'libs/Chat/types';
import { EventComponentProps } from 'stream-chat-react';
import { QuoteSystemStatusEvent, isQuoteStatusMetaData, isQuoteSystemStatusEvent } from '../types';
import { useTranslation } from 'react-i18next';

export const ChatQuoteSystemMessage: FC<
  EventComponentProps<SystemStreamChatGenerics<ChatSystemQuoteMessage>>
> = props => {
  const { t } = useTranslation();
  const { message } = props;
  const { created_at, metaData, user, id } = message;
  const { versionNumber, type, userName } = metaData;

  if (isQuoteSystemStatusEvent(type)) {
    if (type === QuoteSystemStatusEvent.SalesRepJoined) {
      return (
        <ChatQuoteSalesRepJoinedMessage
          messageId={id}
          label={t('common.userJoined', { user: userName ?? '' })}
        />
      );
    }
    const version = versionNumber ?? 1; // versionNumber field is missing when event is QuoteSystemStatusEvent.Created (quote created)

    return (
      <ChatQuoteVersionMessage
        label={`${t('quotes.version')} ${version}`}
        version={version}
        messageId={id}
      />
    );
  }

  if (isQuoteStatusMetaData(metaData)) {
    return (
      <ChatQuoteStatusChangeMessage
        username={user?.name}
        createdAt={created_at ? new Date(created_at) : undefined}
        messageId={id}
        metaData={metaData}
      />
    );
  }

  return null;
};
