import React from 'react';
import { Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

type TitleProps = {
  quoteLabel: string;
};

export function Title({ quoteLabel }: TitleProps) {
  const { t } = useTranslation();

  return (
    <Typography variant="body1" fontSize={theme => theme.typography.pxToRem(20)} textAlign="center">
      {t('quotes.share.quoteWasSharedWithYou', { name: quoteLabel })}
    </Typography>
  );
}
