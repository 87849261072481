import { isGuestApp } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useLocation } from 'react-router-dom';
import { useShouldRenderPdfWithoutDiscount } from 'libs/shared';

export function useQuoteFooterDownloadButton() {
  const { pathname } = useLocation();
  const { shouldRenderPdfWithoutDiscount } = useShouldRenderPdfWithoutDiscount();

  const pdfRoute = isGuestApp(pathname) ? routes.guestApp.quotes.pdf : routes.quotes.pdf;

  return {
    shouldRenderPdfWithoutDiscount,
    pdfRoute,
  };
}
