import React from 'react';
import { Box, useTheme } from '@clippings/paper';

export const AuthenticationImage = ({ ...rest }) => {
  const {
    images: { authentication },
  } = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(
          ${authentication}
        )`,
      }}
      {...rest}
    />
  );
};
