import React, { useState } from 'react';
import { ChipMenu, Skeleton } from '@clippings/paper';
import { QuoteVersion } from 'libs/api/quotes/types';
import { QuoteVersionMenuLabel } from './QuoteVersionMenuLabel';
import { QuoteVersionSelectItem } from './QuoteVersionSelectItem';
import { StatusChip } from 'libs/shared';
import { orderBy } from 'lodash';
import { useQuoteProvider } from '../providers';

export const QuoteVersionSelect = () => {
  const {
    quote: { createdAt, status, versionNumber, id, versions, active },
    changeQuoteVersion,
    loading,
  } = useQuoteProvider();
  const [isOpen, setIsOpen] = useState(false);

  const currentVersion = {
    createdAt,
    status,
    versionNumber,
    id,
    active,
  };

  const handleChipClick = (isMenuOpened: boolean) => {
    setIsOpen(isMenuOpened);
  };

  const selectVersion = (version: QuoteVersion) => {
    if (version.id === currentVersion.id) {
      return;
    }

    setIsOpen(false);
    changeQuoteVersion(version);
  };

  if (loading) {
    return (
      <Skeleton variant="rectangular" sx={styles.skeletonSx} data-testid="version-select-loader" />
    );
  }

  return (
    <ChipMenu
      isOpen={isOpen}
      adornment={
        <StatusChip
          key="quote-status-chip"
          size="small"
          status={currentVersion.status}
          data-testid="selected-version-status"
        />
      }
      value={<QuoteVersionMenuLabel currentVersion={currentVersion} />}
      onToggle={handleChipClick}
      data-testid="quote-version-select-button"
      sx={styles.chipMenuSx}
      menuProps={styles.chipMenuProps as any}
    >
      {orderBy(versions, ['versionNumber'], ['desc']).map(version => {
        const isCurrentVersion = version.versionNumber === currentVersion.versionNumber;

        return (
          <QuoteVersionSelectItem
            key={`quote-version-${version.versionNumber}`}
            handleClick={selectVersion}
            version={version}
            selected={isCurrentVersion}
            disabled={isCurrentVersion}
          />
        );
      })}
    </ChipMenu>
  );
};

const styles = {
  chipMenuSx: {
    width: {
      xs: '100%',
      md: 'fit-content',
    },
    justifyContent: {
      xs: 'space-between',
      md: 'center',
    },
  },
  chipMenuProps: {
    PaperProps: {
      sx: {
        marginTop: 0.5,
        width: {
          xs: '100%',
          md: 'auto',
        },
      },
    },
  },
  skeletonSx: {
    width: {
      xs: '100%',
      md: '160px',
    },
    height: '32px',
    borderRadius: 4,
  },
};
