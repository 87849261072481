import React, { useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  DeepPartial,
  DialogAction,
  FieldErrorsImpl,
  FormProvider,
  LoadingButton,
  SystemUser,
  UseFormReturn,
  getFormErrorMessage,
  useForm,
  useSyncServerErrors,
  yupResolver,
} from '@clippings/paper';
import { BaseUserFormValues, getUserValidationSchema } from '../../utils/user.utils';
import { TeamV2 } from 'libs/api/iam/types';
import { UserFormFields, UserFormFieldsProps } from './UserFormFields';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

type UserFormErrors = Partial<
  FieldErrorsImpl<{
    [x: string]: any;
  }>
>;

export type UserDialogFormBaseProps<FormValues extends BaseUserFormValues> = {
  handleSubmit: (data: FormValues) => void;
  action: DialogAction;
  onCancel: () => void;
  updateUserErrors: UserFormErrors;
  createUserErrors: UserFormErrors;
  isSubmitting: boolean;
  type: SystemUser;
  defaultValues: DeepPartial<FormValues>;
  teams?: TeamV2[];
} & Pick<UserFormFieldsProps, 'hiddenFields' | 'disabledFields'>;

export function UserDialogFormBase<FormValues extends BaseUserFormValues>({
  onCancel,
  handleSubmit,
  action,
  updateUserErrors,
  createUserErrors,
  isSubmitting,
  type,
  defaultValues,
  teams = [],
  hiddenFields = [],
  disabledFields,
}: UserDialogFormBaseProps<FormValues>) {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();

  const buttonCreateLabel = t(
    type === SystemUser.Customer ? 'forms.createUser' : 'forms.createStaffUser'
  );

  const isEditMode = action === DialogAction.Edit;
  // TODO: pass a single formErrors object and decide in the parent which errors to show
  const formErrors = useMemo(
    () => (isEditMode ? updateUserErrors : createUserErrors),
    [createUserErrors, updateUserErrors]
  );

  const schema = getUserValidationSchema(type, brand);

  const form = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useSyncServerErrors(form, formErrors);

  return (
    <FormProvider {...(form as UseFormReturn<FormValues, any>)} {...{ schema }}>
      <form
        noValidate
        onSubmit={form.handleSubmit(handleSubmit as any)}
        data-testid={`${isEditMode ? 'edit' : 'create'}-user-form`}
      >
        <Box display="flex" flexDirection="column">
          <UserFormFields
            type={type}
            action={action}
            teams={teams}
            hiddenFields={hiddenFields}
            disabledFields={disabledFields}
          />

          {formErrors.error && (
            <Box pb={3}>
              <Alert severity="error">{getFormErrorMessage(formErrors, 'error')}</Alert>
            </Box>
          )}

          <Box pt={2} display="flex" justifyContent="flex-end">
            <Button
              data-testid="address-dialog-button-cancel"
              color="secondary"
              onClick={() => onCancel()}
            >
              {t('common.cancel')}
            </Button>

            <LoadingButton
              data-testid={isEditMode ? 'edit-user-button' : 'create-user-dialog-button'}
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isEditMode ? t('common.done') : buttonCreateLabel}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
