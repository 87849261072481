import React, { FC, useEffect, useState } from 'react';
import { AlertTooltip, AlertTooltipProps, Box } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { isNewQuote } from '../quoteUtils';
import { useTranslation } from 'react-i18next';

// TODO: pass OnClose to children

export interface NewQuoteTooltipProps extends Omit<AlertTooltipProps, 'titleProps'> {
  /**
   * data-testid for the Popper element (the tooltip itself, not the container) of the tooltip component
   */
  popperTestId?: string;
}
export const NewQuoteTooltip: FC<NewQuoteTooltipProps> = ({
  children,
  popperTestId,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <AlertTooltip
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={onClose}
      {...rest}
      PopperProps={{
        //@ts-expect-error data-testid not part of the PopperProps but still gets passed
        'data-testid': popperTestId,
      }}
      titleProps={{
        title: t('quotes.startWithQuote'),
        body: t('quotes.newQuoteRequiredFields'),
      }}
    >
      {/* Wrapping in box for tooltip's event listeners to be passed */}
      <Box onClick={onClose}>{children}</Box>
    </AlertTooltip>
  );
};

export function useNewQuoteTooltip(quote: Quote, loading: boolean) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [viewed, setViewed] = useState({ customer: false, catalogue: false });

  const showCatalogueTooltip =
    tooltipOpen && !viewed.catalogue && !!quote.customer && !quote.catalogue;
  const showCustomerTooltip = tooltipOpen && !viewed.customer && !quote.customer;

  useEffect(() => {
    setTooltipOpen(!loading && isNewQuote(quote));
  }, [quote?.customer, quote?.catalogue, loading]);

  const onTooltipClose = (type: keyof typeof viewed) => () => {
    setViewed({ ...viewed, [type]: true });
    setTooltipOpen(false);
  };

  return { onTooltipClose, showCatalogueTooltip, showCustomerTooltip };
}
