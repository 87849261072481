import React from 'react';
import { Box, UserAvatar, getUserFullName } from '@clippings/paper';
import { SalesRepOption } from './SalesRepSelect';
import { useTranslation } from 'react-i18next';

interface SalesRepSelectLabelProps {
  user?: SalesRepOption;
  noAssigneeText?: string;
  prefix?: string;
}

export const SalesRepSelectLabel: React.FC<SalesRepSelectLabelProps> = ({
  user,
  noAssigneeText,
  prefix,
}) => {
  const { t } = useTranslation();

  if (!user) {
    return (
      <p data-testid="sales-rep-label-not-assigned">{noAssigneeText || t('common.notAssigned')}</p>
    );
  }

  const fullName = getUserFullName(user);

  return (
    <Box display="flex" flexWrap="nowrap" alignItems="center">
      <UserAvatar user={user} small />
      <Box ml={1} whiteSpace="nowrap">
        {prefix}
        <Box display="inline" color="black" ml={0.5} data-testid="sales-rep-label">
          {fullName}
          {user.team && ` (${user.team.name})`}
        </Box>
      </Box>
    </Box>
  );
};
