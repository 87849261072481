import React from 'react';
import { Box, Divider, Grid, Typography } from '@clippings/paper';
import { DataTestId } from 'libs/shared';

export interface InfoLineProps extends DataTestId {
  label: string;
  value?: string | React.ReactNode;
  divider?: boolean;
}

export const InfoLine: React.FC<InfoLineProps> = ({ label, value, divider = true, ...rest }) => (
  <>
    <Grid {...rest} item xs={1}>
      <Typography
        data-testid="info-line-first"
        variant="subtitle2"
        color="text.secondary"
        fontWeight="fontWeightBold"
      >
        {label}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Box data-testid="info-line-second">{value}</Box>
    </Grid>
    {divider && (
      <Grid item xs={4} my={1}>
        <Divider />
      </Grid>
    )}
  </>
);
