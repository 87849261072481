import React from 'react';
import { FormModal } from 'libs/Components';
import { FormTextField, SubmitHandler } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { quoteLabelValidationSchema } from './quoteLabelValidationSchema';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

const QuoteLabelModal: React.FC = () => {
  const { isLabelModalOpen, handleLabelModalClose, handleNonContractualUpdate, quote } =
    useQuoteProvider();
  const { t } = useTranslation();

  const handleSubmit = (data: Partial<Quote>) => {
    handleNonContractualUpdate(data);
    handleLabelModalClose();
  };

  return (
    <FormModal
      onClose={handleLabelModalClose}
      formProps={{
        onSubmit: handleSubmit as SubmitHandler<Partial<Quote>>,
        validationSchema: quoteLabelValidationSchema,
        defaultValues: { name: quote.name ?? '' },
      }}
      actionProps={{
        onCancel: handleLabelModalClose,
        cancelLabel: t('common.cancel'),
        submitLabel: t('common.save'),
      }}
      open={isLabelModalOpen}
      title={t('quotes.labelModal.labelQuote')}
    >
      <FormTextField
        inputProps={{ maxLength: 255 }}
        autoFocus
        fullWidth
        helperText={t('quotes.labelModal.labelYourQuote')}
        placeholder={t('quotes.labelModal.placeholder')}
        name="name"
        label=""
      />
    </FormModal>
  );
};

export default QuoteLabelModal;
