import React from 'react';
import { ConfiguredProduct } from 'libs/SalesApp/Quotes/common/types';
import { InfoCellSampleItem } from 'libs/SalesApp/Quotes/quoteTable/components/tableCells/infoTableCell/InfoCellSampleItem';
import { OrderProductItem } from 'libs/api/order/types';
import { ProductInfo } from 'libs/SalesApp/Quotes/components';
import { ProductVariationName, buildVariationName } from 'libs/shared';
import { QuoteItemHiddenDetails } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { QuoteProductItem } from 'libs/api/quotes/types';

// TODO: refactor - components in Quotes should not depend on SalesApp lib

export type InfoCellItemRendererProps = {
  item: QuoteProductItem | OrderProductItem;
  detailsHidden: boolean;
  readonly: boolean;
  textOnly: boolean;
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
  onConfigure?: (configuredProduct: ConfiguredProduct) => void;
};

export function InfoCellItemRenderer({
  item,
  readonly,
  detailsHidden,
  textOnly,
  onConfigure,
  onProductDetailsHide,
}: InfoCellItemRendererProps) {
  // the item is a sample
  if (item.sample) {
    const { properties, productName, variationName } = item;
    const sampleName = buildVariationName({ properties, productName, variationName });

    return <InfoCellSampleItem sampleName={sampleName} />;
  }

  // the item is custom
  if (item.custom) {
    return (
      <ProductVariationName
        id={item.id}
        productName={item.productName}
        properties={item.properties}
        variationName={item.variationName}
      />
    );
  }

  // regular product item
  return (
    <ProductInfo
      item={item}
      readonly={Boolean(readonly)}
      loading={false}
      textOnly={textOnly}
      detailsHidden={detailsHidden}
      onConfigure={onConfigure}
      onProductDetailsHide={onProductDetailsHide}
    />
  );
}
