import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserPermission } from 'libs/api/iam/types';
import { useCurrentUser } from '../../api/iam/hooks';

export interface PermissionBasedAccessProps {
  /** This is the element to render, if the route is allowed */
  element: React.ReactNode;
  /** If the user has ANY of the provided permissions, then the route will be allowed */
  permission: UserPermission;
  /** Optional redirect if the user is not authorized */
  redirect?: string;
  /** Optional component that will be rendered if the user is not allowed to view the route */
  fallbackElement?: React.ReactNode;
}

export const PermissionBasedAccess: React.FC<PermissionBasedAccessProps> = ({
  element,
  permission,
  redirect,
  fallbackElement,
}) => {
  const { data } = useCurrentUser();

  if (!data) {
    return null;
  }

  const hasPermission = data.permissions.includes(permission);

  if (!hasPermission) {
    return <>{redirect ? <Navigate to={redirect} /> : fallbackElement || null}</>;
  }

  return <>{element}</>;
};
