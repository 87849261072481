import React from 'react';
import classNames from 'classnames';
import { CellMoneyInput, TableCell } from '@clippings/paper';
import {
  TableCellCommonProps,
  inputProps,
} from 'libs/SalesApp/Quotes/quoteTable/components/tableCells/tableCells.utils';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';

type PriceTableCellProps = {
  handleUpdate: UpdateQuoteByKey;
} & Pick<TableCellCommonProps, 'item' | 'readonly'>;

export function PriceTableCell({ item, readonly, handleUpdate }: PriceTableCellProps) {
  const handleCatalogueBasePriceChange = (value: number) => {
    if (Number.isNaN(value)) {
      handleUpdate('catalogueBasePrice', null, item.id);
      return;
    }

    handleUpdate(
      'catalogueBasePrice',
      { amount: value, currency: item.catalogueBasePrice?.currency },
      item.id
    );
  };

  return (
    <TableCell
      data-testid={`quote-item-base-price-${item.id}`}
      align="right"
      className={classNames('quote-item-row__cell', 'quote-item-row__cell__price')}
    >
      <CellMoneyInput
        disabled={readonly}
        inputProps={{ ...inputProps, 'data-testid': 'catalogue-base-price-' + item.id }}
        value={item.catalogueBasePrice?.amount ?? ''}
        onBlur={handleCatalogueBasePriceChange}
        currency={item.catalogueBasePrice?.currency ?? ''}
      />
    </TableCell>
  );
}
