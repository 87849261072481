import React from 'react';
import classNames from 'classnames';
import { Column } from 'libs/shared';
import { CustomDeliveryDate } from './customDeliveryDate';
import { DateTimeFormat, Typography, useFormatDateTime } from '@clippings/paper';
import { DiscountsRow } from './Components/TotalsSummary/DiscountsRow';
import { Payment } from 'libs/api/common/types';
import { PaymentTermRow } from './Components/TotalsSummary/PaymentTermRow';
import { PaymentTerms } from './Components/TotalsSummary/PaymentTerms';
import { Quote } from 'libs/api/quotes/types';
import { QuoteUpdateOptions } from './quote.types';
import {
  SummaryViewItem,
  TotalsSummaryShippingRow,
} from './Components/TotalsSummary/TotalsSummaryShippingRow';
import {
  TotalsSummary,
  TotalsSummarySubTotalRow,
  TotalsSummaryTaxRow,
  TotalsSummaryTotalRow,
} from './Components/TotalsSummary';
import { TotalsSummaryShippingRowEmpty } from './Components/TotalsSummary/TotalsSummaryShippingRowEmpty';
import { useTranslation } from 'react-i18next';

interface SummaryViewProps {
  item: SummaryViewItem;
  className?: string;
  paymentTerms: Payment[];
  paymentTermsLoading: boolean;
  addressSubmitLoading?: boolean;
  readonly?: boolean;
  textOnly?: boolean;
  isLoading?: boolean;
  quoteMetadata?: Quote['metadata'];
  renderPaymentTerms?: boolean;
  handleShippingModalOpen?: () => void;
  onQuoteUpdate?: (newQuote: Partial<Quote>, options?: QuoteUpdateOptions) => void;
}

export const SummaryView: React.FC<SummaryViewProps> = ({
  className,
  item,
  paymentTerms,
  paymentTermsLoading,
  addressSubmitLoading,
  readonly = false,
  textOnly = false,
  isLoading = false,
  quoteMetadata,
  renderPaymentTerms = true,
  handleShippingModalOpen,
  onQuoteUpdate,
  ...rest
}) => {
  const formatDateTime = useFormatDateTime();
  const { t } = useTranslation();

  const shippingRowEmpty = textOnly ? null : (
    <TotalsSummaryShippingRowEmpty
      disabled={addressSubmitLoading}
      hideActions={readonly}
      hasBorder={false}
      onClick={handleShippingModalOpen}
    />
  );
  const shippingRow = item.shippingAddress ? (
    <TotalsSummaryShippingRow
      item={item}
      disabled={addressSubmitLoading}
      hideActions={readonly}
      hasBorder={false}
      onClick={handleShippingModalOpen}
    />
  ) : (
    shippingRowEmpty
  );

  return (
    <TotalsSummary
      border={1}
      borderColor="grey.A200"
      borderRadius={1}
      className={classNames('totals-summary', className)}
      data-testid="totals-summary"
      {...rest}
    >
      <TotalsSummarySubTotalRow subtotal={item.subtotal} label={t('quotes.subTotal')} />
      {item.contractItemsSubTotal && item.contractItemsSubTotal.amount > 0 && (
        <TotalsSummarySubTotalRow
          data-testid={'contract-items-subtotal'}
          subtotal={item.contractItemsSubTotal}
          label={t('common.contractItemsSubTotal')}
        />
      )}
      <Column alignItems="flex-start" pb="12px" borderBottom="1px solid" borderColor="divider">
        {shippingRow}
        <CustomDeliveryDate
          customDeliveryDateModel={item.metadata?.customDeliveryDate ?? null}
          modalTitle={t('quotes.requestCustomDeliveryDate')}
          readonly={readonly}
          isLoading={isLoading}
          quoteMetadata={quoteMetadata}
          onUpdate={onQuoteUpdate}
        />
      </Column>
      <DiscountsRow discounts={item.discountItems} currency={item.total.currency} />
      <TotalsSummaryTaxRow tax={item.tax} />
      <TotalsSummaryTotalRow total={item.total} />
      {renderPaymentTerms ? (
        <PaymentTerms isLoading={paymentTermsLoading}>
          {paymentTerms.map((paymentTerm, idx) => (
            <PaymentTermRow
              size="medium"
              key={paymentTerm.id}
              idx={idx + 1}
              paymentTerm={paymentTerm}
            />
          ))}
        </PaymentTerms>
      ) : null}
      {item.expiresAt && item.status !== 'expired' && (
        <Typography
          sx={{ fontSize: theme => theme.typography.pxToRem(12), mt: 1 }}
          color="primary"
          data-testid="quote-expires-on-summary-view"
        >
          {/* eslint-disable-next-line react/jsx-no-literals */}
          {`${t('common.expiresOn')} ${formatDateTime(
            new Date(item.expiresAt),
            DateTimeFormat.Date
          )}`}
        </Typography>
      )}
    </TotalsSummary>
  );
};
