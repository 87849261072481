import { AxiosProgressEvent } from 'axios';
import { AxiosUploadParams, BrowserUploadedFile, UploadedDocument } from './types';
import { MutationOptions } from 'libs/api/common/types';
import { uploadDocument } from './actions';
import { useCallback, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

export const uploadQueryKeys = {
  uploadDocument: 'upload-document',
};

export const useUploadDocument = <T extends { fileName: string }>(
  {
    uploadFunction,
    queryKey,
    options,
  }: {
    uploadFunction: (uploadParams: AxiosUploadParams, ...args: any[]) => Promise<T>;
    queryKey: string;
    options?: MutationOptions<T, BrowserUploadedFile>;
  },
  ...args: any[]
) => {
  const [progress, setProgress] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  const onUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
    setProgress(percentCompleted);
  }, []);

  const mutation = useMutation(
    [queryKey],
    file => {
      abortControllerRef.current = new AbortController();

      return uploadFunction(
        {
          file,
          onUploadProgress,
          signal: abortControllerRef.current?.signal,
        },
        ...args
      );
    },
    {
      ...options,
      onError(...args) {
        setProgress(0);
        options?.onError?.(...args);
      },
    }
  );

  const reset = () => {
    abortControllerRef.current?.abort();
    mutation.reset();
    setProgress(0);
  };

  return {
    ...mutation,
    abortControllerRef,
    progress,
    setProgress,
    reset,
  };
};

export const useUploadCheckoutDocument = (
  options?: MutationOptions<UploadedDocument, BrowserUploadedFile>
) =>
  useUploadDocument<UploadedDocument>({
    uploadFunction: uploadDocument,
    queryKey: uploadQueryKeys.uploadDocument,
    options,
  });
