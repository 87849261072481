import React, { FC } from 'react';
import { BaseUser } from 'libs/api/common/types';
import { Box, Button, Typography } from '@clippings/paper';
import { FormSkeletonRow } from 'libs/Quotes/QuoteAddresses/Skeletons';
import { Quote } from 'libs/api/quotes/types';
import { QuoteShareModalScreen } from '../useQuoteShareUtils';
import { QuoteSharee } from 'libs/Quotes/Components/ShareModal/components/QuoteSharee';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useQuoteShare } from 'libs/Quotes/Components/ShareModal/QuoteShareProvider';
import { useTranslation } from 'react-i18next';
import { userCanAccessSalesApp } from 'libs/Authentication/AuthenticationUtils';

export const ShareesList: FC<{ quote: Quote }> = ({ quote }) => {
  const { t } = useTranslation();
  const {
    modal: { setScreen },
    sharees: { getShareesQuery, sharees },
    handlers: { onShareeRemoveErrorHandler, onShareeRemoveSuccessHandler },
    owner,
  } = useQuoteShare();
  const { data: currentUser, isLoading: currentUserLoading } = useCurrentUser();

  const isOwner = (sharee: BaseUser) => sharee.id === owner?.id;

  // Only owner and sales app users can remove sharees
  const isRemovable = (sharee: BaseUser) =>
    currentUser ? userCanAccessSalesApp(currentUser) || isOwner(sharee) : false;

  const isLoading = getShareesQuery.isLoading || currentUserLoading;

  const renderSharees = () =>
    sharees.length > 0 ? (
      <Box data-testid="sharees-list" display="flex" flexDirection="column" width="100%" gap={2}>
        {sharees.map(sharee => (
          <QuoteSharee
            isCurrentUser={sharee.id === currentUser?.id}
            isGuest={sharee.guest}
            isOwner={isOwner(sharee)}
            removable={isRemovable(sharee)}
            key={`${sharee.id}-${quote.number}`}
            sharee={sharee}
            onRemoveErrorHandler={onShareeRemoveErrorHandler}
            onRemoveSuccessHandler={onShareeRemoveSuccessHandler}
            quoteNumber={quote.number}
          />
        ))}
      </Box>
    ) : (
      <Box
        data-testid="empty-sharees-list-placeholder"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="body2">{t('quotes.share.notSharedYet')}</Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => setScreen(QuoteShareModalScreen.SHARE)}
        >
          {t('quotes.share.selectPeopleToShareWith')}
        </Button>
      </Box>
    );

  return (
    <Box
      display="flex"
      justifyContent={sharees.length === 0 ? 'center' : 'flex-start'}
      alignItems="center"
      flexDirection="column"
      sx={{ minHeight: '100px', maxHeight: '400px', overflow: 'auto' }}
      gap={2}
    >
      {isLoading ? (
        <>
          <FormSkeletonRow sx={{ mb: 0 }} />
          <FormSkeletonRow sx={{ mb: 0 }} />
          <FormSkeletonRow sx={{ mb: 0 }} />
        </>
      ) : (
        renderSharees()
      )}
    </Box>
  );
};
