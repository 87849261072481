import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, UserAvatar } from '@clippings/paper';
import { User } from 'libs/api/iam/types';
import './UserItem.scss';

interface UserItemProps {
  user: User;
}

export const UserItem: React.FC<UserItemProps> = ({ user }) => (
  <ListItem className="user-item">
    <ListItemAvatar className="user-item__avatar">
      <UserAvatar user={user} />
    </ListItemAvatar>
    <ListItemText
      className="user-item__text"
      primary={`${user.firstName} ${user.lastName}`}
      secondary={user.company?.name}
      primaryTypographyProps={{ variant: 'body2', component: 'span', fontWeight: 'fontWeightBold' }}
      secondaryTypographyProps={{ variant: 'caption', component: 'p' }}
    />
  </ListItem>
);
