import { AutocompleteChangeDetails, AutocompleteInputChangeReason } from '@clippings/paper';
import { BaseUser } from 'libs/api/common/types';
import { DEBOUNCE_TIMINGS } from 'libs/Constants';
import { Quote, QuoteShareesList } from 'libs/api/quotes/types';
import { reactQueryKeys, useAddQuoteSharees, useGetQuoteSharees } from 'libs/api/quotes/hooks';
import { uniqueId } from 'lodash';
import { useAlertSnackbar } from 'libs/hooks/useAlertSnackbar';
import { useCallback, useMemo, useState } from 'react';
import { useCurrentUser, useListInfiniteUsersV2 } from 'libs/api/iam/hooks';
import { useDebounce, useEntityReducer } from 'libs/shared';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export enum QuoteShareModalScreen {
  SHARE = 'share',
  LIST = 'list',
}
export type QuoteShareModalScreenProps = {
  quote: Quote;
};
export type AddShareeErrorCode =
  | 'INVALID_SHAREE_SPECIFIER_ERROR'
  | 'INVALID_SHAREE_EXIST_ERROR'
  | 'INVALID_SHAREE_STAFF_ERROR'
  | 'INVALID_SHAREE_CURRENT_USER_ERROR';

export type ErroneousSharee = {
  id: string;
  email: string;
  message: string;
  code: AddShareeErrorCode;
};
export type ShareeSelectOption = Pick<BaseUser, 'id' | 'email' | 'firstName' | 'lastName'>;

export const QUOTE_SHARE_MODAL_INITIAL_SCREEN = QuoteShareModalScreen.LIST;
export const isShareeSelectOption = (
  sharee: ShareeSelectOption | string
): sharee is ShareeSelectOption => typeof sharee !== 'string' && 'email' in sharee;
const isValidEmail = (email: string) => simpleEmailRegex.test(email);
const simpleEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export type QuoteShareContextState = {
  modal: {
    screen: QuoteShareModalScreen;
    shareModalOpen: boolean;
    modalCloseHandler: () => void;
    returnHandler: () => void;
    doneHandler: () => void;
    setShareModalOpen: (open: boolean) => void;
    setScreen: (screen: QuoteShareModalScreen) => void;
  };
  sharees: {
    sharees: BaseUser[];
    getShareesQuery: ReturnType<typeof useGetQuoteSharees>;
    addShareesMutation: ReturnType<typeof useAddQuoteSharees>;
    selectedSharees: ShareeSelectOption[];
    setSelectedSharees: (sharees: ShareeSelectOption[]) => void;
    erronousSharees: ErroneousSharee[];
  };
  handlers: {
    shareeSelectInputChangeHandler: (
      event: React.SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
      options: ShareeSelectOption[]
    ) => void;
    shareeSelectChangeHandler: (
      event: React.SyntheticEvent,
      value: (string | ShareeSelectOption)[],
      reason: 'createOption' | 'selectOption' | 'removeOption' | 'blur' | 'clear',
      details?: AutocompleteChangeDetails<string | ShareeSelectOption>
    ) => void;
    onShareSuccessHandler: (payload: BaseUser[]) => void;
    onShareeRemoveErrorHandler: () => void;
    onShareeRemoveSuccessHandler: (id: string) => void;
  };
  owner?: BaseUser;
  message: string;
  options: ShareeSelectOption[];
  shareeSelectQuery: string;
  usersQuery: ReturnType<typeof useListInfiniteUsersV2>;
  setMessage: (message: string) => void;
  snackbarProps: ReturnType<typeof useAlertSnackbar>['props'];
};
export type QuoteShareUtilsState = {
  quote?: Quote;
  modalOpen?: boolean;
  initialSelectInputValue?: string;
  initialScreen?: QuoteShareModalScreen;
  initialShareMessage?: string;
  initialCustomShareeOption?: ShareeSelectOption | null;
  initialErronousSharees?: ErroneousSharee[];
  initialSelectedSharees?: ShareeSelectOption[];
};

export const useQuoteShareUtils = (
  {
    quote,
    modalOpen,
    initialSelectInputValue,
    initialScreen,
    initialShareMessage,
    initialCustomShareeOption,
    initialErronousSharees,
    initialSelectedSharees,
  }: QuoteShareUtilsState = {
    modalOpen: false,
    initialSelectInputValue: '',
    initialScreen: QUOTE_SHARE_MODAL_INITIAL_SCREEN,
    initialShareMessage: '',
    initialCustomShareeOption: null,
    initialErronousSharees: [],
    initialSelectedSharees: [],
  }
) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser()?.data;
  const queryClient = useQueryClient();
  const { props: snackbarProps, handleShowSnackbar } = useAlertSnackbar();

  const { replaceAll: setSelectedSharees, state: selectedSharees } =
    useEntityReducer<ShareeSelectOption>(initialSelectedSharees ?? []);
  const { state: erronousSharees, replaceAll: setErronousSharees } =
    useEntityReducer<ErroneousSharee>(initialErronousSharees ?? []);

  const [customShareeOption, setCustomShareeOption] = useState<ShareeSelectOption | null>(
    initialCustomShareeOption ?? null
  );
  const [message, setMessage] = useState(initialShareMessage ?? '');
  const [shareeSelectQuery, setShareeSelectQuery] = useState(initialSelectInputValue ?? '');
  const [screen, setScreen] = useState<QuoteShareModalScreen>(
    initialScreen ?? QUOTE_SHARE_MODAL_INITIAL_SCREEN
  );
  const [shareModalOpen, setShareModalOpen] = useState(modalOpen ?? false);

  const owner = quote?.customer;
  const number = quote?.number ?? '';

  // Debounce the query value to avoid unnecessary requests while updating the select input without delay
  const debouncedSelectQuery = useDebounce(
    shareeSelectQuery,
    DEBOUNCE_TIMINGS.SEARCH_DEBOUNCE_FAST
  );

  // Queries and mutations
  const usersQuery = useListInfiniteUsersV2(
    {
      query: debouncedSelectQuery,
      companyIds: String(owner?.company?.id ?? ''),
    },
    {
      enabled: shareModalOpen,
    }
  );

  const getShareesQuery = useGetQuoteSharees(number, {
    enabled: !!quote && shareModalOpen,
  });

  const addShareesMutation = useAddQuoteSharees(number, {
    onMutate: () => {
      setErronousSharees([]);
    },
    onSuccess: data => {
      const queryKey = [reactQueryKeys.getSharees, number];

      const prevSharees = queryClient.getQueryData<QuoteShareesList>(queryKey)!;

      queryClient.setQueryData<QuoteShareesList>(queryKey, () => data ?? []);

      onShareSuccessHandler(data);

      return { prevSharees };
    },
    onError: (error, variables) => {
      const errors = (error?.response?.data?.errors ?? []) as {
        code: AddShareeErrorCode;
        field: string;
        message: string;
      }[];

      const { sharees } = variables;

      const errSharees: ErroneousSharee[] = [];

      sharees.forEach(sharee => {
        const error = errors.find(e => e.message.includes(sharee.email));

        if (error) {
          const message = t(`common.${error.code}`);

          errSharees.push({
            id: uniqueId(),
            code: error.code,
            email: sharee.email,
            message,
          });
        }
      });

      setErronousSharees(errSharees);
      onShareErrorHandler(error);
    },
  });

  // Handlers
  const cleanup = useCallback(() => {
    setShareeSelectQuery('');
    setSelectedSharees([]);
    setCustomShareeOption(null);
    setErronousSharees([]);
  }, [setShareeSelectQuery, setSelectedSharees, setCustomShareeOption, setErronousSharees]);

  const onShareSuccessHandler = (_sharees: ShareeSelectOption[]) => {
    setScreen(QUOTE_SHARE_MODAL_INITIAL_SCREEN);
    cleanup();

    handleShowSnackbar(t('quotes.share.shareSuccess'), 'success');
  };

  const onShareErrorHandler = (_error: any) => {
    setShareeSelectQuery('');
    handleShowSnackbar(t('quotes.share.shareError'), 'error');
  };

  const modalCloseHandler = () => {
    setShareModalOpen(false);
    cleanup();
    setScreen(QUOTE_SHARE_MODAL_INITIAL_SCREEN);
  };

  const returnHandler = () => {
    cleanup();
    setScreen(QUOTE_SHARE_MODAL_INITIAL_SCREEN);
  };

  const doneHandler = () => {
    cleanup();
    setShareModalOpen(false);
  };

  const onShareeRemoveErrorHandler = () => {
    handleShowSnackbar(t('quotes.share.failedRemovingSharee'), 'error');
  };

  const onShareeRemoveSuccessHandler: QuoteShareContextState['handlers']['onShareeRemoveSuccessHandler'] =
    id => {
      const queryKey = [reactQueryKeys.getSharees, number ?? ''];

      queryClient.setQueryData<QuoteShareesList>(queryKey, prev => {
        // remove sharee from existing list
        const items =
          prev?.filter(prevSharee => {
            return prevSharee.id !== Number(id);
          }) ?? [];

        return items;
      });
    };

  const shareeSelectChangeHandler: QuoteShareContextState['handlers']['shareeSelectChangeHandler'] =
    (_event, value, reason, details) => {
      if (value.every(isShareeSelectOption)) {
        setSelectedSharees(value);
      }

      if (screen === QuoteShareModalScreen.LIST) {
        setScreen(QuoteShareModalScreen.SHARE);
      }

      if (value.length === 0 || reason === 'clear') {
        setScreen(QUOTE_SHARE_MODAL_INITIAL_SCREEN);
      }

      if (
        details &&
        reason === 'removeOption' &&
        isShareeSelectOption(details.option) &&
        erronousSharees.length > 0
      ) {
        setErronousSharees(
          erronousSharees.filter(
            x => isShareeSelectOption(details.option) && x.email !== details.option.email
          )
        );
      }
    };

  const shareeSelectInputChangeHandler: QuoteShareContextState['handlers']['shareeSelectInputChangeHandler'] =
    (_event, value, reason, options) => {
      setShareeSelectQuery(value);

      if (isValidEmail(value) && !options.some(option => option.email === value)) {
        setCustomShareeOption({
          id: Number(uniqueId()),
          email: value,
          firstName: '',
          lastName: '',
        });
      } else if ((!isValidEmail(value) && customShareeOption) || reason === 'reset') {
        setCustomShareeOption(null);
      }
    };

  const sharees = useMemo(() => {
    const sharees = getShareesQuery.data ?? [];
    return owner ? [owner, ...sharees] : sharees;
  }, [owner, getShareesQuery.data]);

  // Data
  const options = useMemo(() => {
    // display custom option (email) over existing users
    if (customShareeOption) {
      return [customShareeOption];
    }

    const usersQueryData = (usersQuery.data?.pages ?? []).flat();
    const shareeQueryData = getShareesQuery.data ?? [];
    const shareeEmails = shareeQueryData.map(x => x.email);
    const filtered = usersQueryData.filter(
      x =>
        !shareeEmails.includes(x.email) && // remove already shared users
        x.email !== currentUser?.email && // remove current user
        x.email !== owner?.email // remove owner
    );

    return filtered;
  }, [customShareeOption, usersQuery.data, getShareesQuery.data]);

  const value: QuoteShareContextState = useMemo(
    () => ({
      modal: {
        setShareModalOpen,
        modalCloseHandler,
        returnHandler,
        doneHandler,
        screen,
        setScreen,
        shareModalOpen,
      },
      sharees: {
        sharees,
        selectedSharees,
        setSelectedSharees,
        getShareesQuery,
        addShareesMutation,
        erronousSharees,
      },
      owner,
      message,
      setMessage,
      shareeSelectQuery,
      usersQuery,
      options,
      snackbarProps,
      handlers: {
        shareeSelectInputChangeHandler,
        shareeSelectChangeHandler,
        onShareSuccessHandler,
        onShareeRemoveErrorHandler,
        onShareeRemoveSuccessHandler,
      },
      cleanup,
    }),
    [
      setShareModalOpen,
      modalCloseHandler,
      returnHandler,
      doneHandler,
      screen,
      setScreen,
      shareModalOpen,
      sharees,
      selectedSharees,
      setSelectedSharees,
      getShareesQuery,
      addShareesMutation,
      erronousSharees,
      owner,
      message,
      setMessage,
      shareeSelectQuery,
      usersQuery,
      options,
      snackbarProps,
      shareeSelectInputChangeHandler,
      shareeSelectChangeHandler,
      onShareSuccessHandler,
      onShareeRemoveErrorHandler,
      onShareeRemoveSuccessHandler,
      cleanup,
    ]
  );

  return value;
};
