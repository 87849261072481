import { getStripePaymentIntent } from './actions';
import { useQuery } from '@tanstack/react-query';

import { PaymentIntentCreated, StripeComponents } from './types';
import { PaymentIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { QueryOptions } from 'libs/api/common/types';
import { useMutation } from '@tanstack/react-query';
import { useRef } from 'react';

export const useStripePaymentIntent = (
  paymentId: string,
  options?: QueryOptions<PaymentIntentCreated>
) => {
  return useQuery(['payment_intent', paymentId], () => getStripePaymentIntent(paymentId), options);
};

type ConfirmPaymentArgs = { stripe: Stripe; elements: StripeElements };

const stripeConfirmPaymentAction = ({ stripe, elements }: ConfirmPaymentArgs) =>
  stripe.confirmPayment({
    elements,
    redirect: 'if_required', // Setting `if_required` requires that you handle successful confirmations for redirect-based and non-redirect based payment methods separately.
  });

const getStripeComponents = (stripeComponents?: StripeComponents) =>
  new Promise<ConfirmPaymentArgs>((resolve, reject) => {
    const { stripe, elements } = stripeComponents || {};
    if (!stripe || !elements) {
      return reject({ message: undefined });
    }
    resolve({ stripe, elements });
  });

export const useStripePayment = () => {
  const stripeComponents = useRef<StripeComponents | undefined>();

  const stripePaymentMutation = useMutation<PaymentIntentResult, { message: string }>(() => {
    return getStripeComponents(stripeComponents.current).then(components =>
      stripeConfirmPaymentAction(components).then(response => {
        if (response.error) {
          return Promise.reject({ message: response.error.message });
        }
        return response;
      })
    );
  });

  const populateStripeComponents = (components: StripeComponents): void => {
    stripeComponents.current = components;
  };

  return {
    populateStripeComponents,
    stripePaymentMutation,
    stripeComponents: stripeComponents.current,
  };
};
