import React, { ReactNode } from 'react';
import { Typography } from '@clippings/paper';

interface TitleProps {
  children: ReactNode;
}

export function Title({ children }: TitleProps): JSX.Element {
  return (
    <Typography
      variant="h3"
      mb={1}
      fontWeight={700}
      fontSize={theme => `${theme.typography.pxToRem(16)}!important`}
    >
      {children}
    </Typography>
  );
}
