import React, { ForwardedRef, forwardRef, useEffect } from 'react';
import { Box, CloseIcon, Drawer, IconButton, Typography } from '@clippings/paper';
import { ChatChannel } from './ChatChannel';
import { useTranslation } from 'react-i18next';

interface ChatDrawerProps {
  onClose: () => void;
  isOpen: boolean;
  className?: string;
}

export const ChatDrawer = forwardRef<HTMLDivElement, ChatDrawerProps>(
  ({ onClose, isOpen, className }, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();

    useEffect(() => {
      const handleKeyPressEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };

      document.addEventListener('keyup', handleKeyPressEscape);

      return () => {
        document.removeEventListener('keyup', handleKeyPressEscape);
      };
    }, []);

    return (
      <Drawer
        variant="persistent"
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          className,
          ref,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="grey.A200"
          px={3}
          py={2}
        >
          <Typography variant="h6" fontWeight="fontWeightMedium">
            {t('common.messages')}
          </Typography>
          <IconButton aria-label="close" onClick={onClose} data-testid="chat-drawer-close-btn">
            <CloseIcon />
          </IconButton>
        </Box>

        {isOpen && <ChatChannel />}
      </Drawer>
    );
  }
);
