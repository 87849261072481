import React, { ComponentProps, useState } from 'react';
import { DataTestId } from 'libs/shared';
import { TextField } from '@clippings/paper';

export interface CompositeDiscountValue {
  title: string;
  percent: number | null;
}

interface CompositeDiscountCellProps
  extends Omit<ComponentProps<typeof TextField>, 'onChange' | 'onBlur'>,
    DataTestId {
  initialValue: string;
  autoFocus?: boolean;
  onBlur?: (value: CompositeDiscountValue) => void;
  onChange?: (value: CompositeDiscountValue) => void;
}

export const CompositeDiscountCell: React.FC<CompositeDiscountCellProps> = ({
  initialValue,
  'data-testid': dataTestId,
  autoFocus = false,
  onBlur,
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [result, setResult] = useState<CompositeDiscountValue>({
    title: initialValue,
    percent: calculateDiscount(initialValue),
  });

  const handleValueChange = (newValue: string) => {
    const filteredValue = newValue.replace(/[^0-9%+,./]/g, '');
    const calculatedPercent = calculateDiscount(filteredValue);
    let filteredTitle;
    let filteredPercent;

    if (calculatedPercent === null) {
      filteredPercent = null;
      filteredTitle = '';
    } else if (calculatedPercent > 100) {
      filteredPercent = 100;
      filteredTitle = '100%';
    } else {
      filteredPercent = calculatedPercent;

      /**
       * We want to allow the user to type or not the percent sign as well the other allowed formats for the discount
       * {number}/number}, {number}+number} and {number}%
       * When saving the data and on blur we will always add the percent sign when the value is a number
       */
      filteredTitle = filteredValue;
    }

    setValue(filteredTitle);

    const result = {
      title: filteredTitle,
      percent: filteredPercent,
    };

    setResult(result);
    onChange?.({ ...result, title: makeDiscountTitle(result.title) });
  };

  const handleBlur = () => {
    const updatedResult = { ...result, title: makeDiscountTitle(result.title) };

    if (result.title !== updatedResult.title) {
      setResult(updatedResult);
      setValue(updatedResult.title);
    }

    onBlur?.(updatedResult);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  return (
    <TextField
      fullWidth
      variant="standard"
      value={value}
      autoFocus={autoFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onChange={e => {
        handleValueChange(e.target.value);
      }}
      InputProps={{
        sx: {
          fontSize: 'inherit',
        },
        disableUnderline: true,
        inputProps: {
          sx: {
            fontWeight: 400,
            py: 0,
            textAlign: 'right',
          },
          'data-testid': dataTestId,
        },
      }}
      {...rest}
    />
  );
};

const calculateDiscount = (title: string) => {
  const stringWithoutPercent = title.replace(/%/g, '');
  const stringWithoutCommas = stringWithoutPercent.replace(/,/g, '.');
  const splitArray = stringWithoutCommas.split(/[+/]/).filter(Boolean);

  if (splitArray.length === 0) {
    return null;
  }

  return parseFloat(
    (
      (1 -
        splitArray.reduce((acc, discount) => {
          return acc * (1 - parseFloat(discount) / 100);
        }, 1)) *
      100
    ).toFixed(2)
  );
};

// supports {number}/number}, {number}+number} and {number}%
function makeDiscountTitle(rawTitle: string): string {
  const titleWithoutPercent = rawTitle.replace(/%/g, '');
  const titleHasSlash = titleWithoutPercent.includes('/');
  const titleHasPlus = titleWithoutPercent.includes('+');

  if (titleHasSlash || titleHasPlus) {
    return titleWithoutPercent;
  }

  return `${titleWithoutPercent.replace(/,/g, '.')}%`;
}
