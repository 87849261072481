import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames';
import { Box } from '@clippings/paper';
import { CheckoutEndStatusLinks } from '../CheckoutEndStatusLinks';
import { CheckoutStatusView } from '../CheckoutStatusView/CheckoutStatusView';
import { PaymentStatusIcons } from '../PaymentStatusIcons';
import { useAppConfiguration } from 'libs/providers/AppConfigurationProvider';
import { useTranslation } from 'react-i18next';
import './Success.scss';

export type SuccessProps = {
  containerProps?: ComponentProps<typeof Box>;
};

export const Success: FC<SuccessProps> = ({ containerProps }) => {
  const { t } = useTranslation();

  return (
    <Box
      data-testid="checkout-success-container"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...containerProps}
      className={classNames('checkout-success', containerProps?.className)}
    >
      <CheckoutStatusView
        data-testid="checkout-success-status-view"
        sx={{
          marginBottom: '3em',
        }}
        icon={PaymentStatusIcons.success}
        title={<StatusTitle />}
        subtitle={t('checkout.success.email')}
      />
      <CheckoutEndStatusLinks />
    </Box>
  );
};

const StatusTitle = () => {
  const appConfiguration = useAppConfiguration();
  const { t } = useTranslation();

  return (
    <>
      {t('checkout.success.complete')}
      <br />
      {t('checkout.success.thankYou', {
        vendor: appConfiguration.tradeName,
      })}
    </>
  );
};
