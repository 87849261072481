import React, { FC } from 'react';
import { Column, ComponentGridContainer, QuoteItemCardRenderer } from 'libs/shared';
import { ComponentHeader } from './ComponentHeader';
import { QuoteSummary } from '../../QuoteSummary';
import { QuoteTableView } from '../../QuoteTable/components/QuoteTableView/QuoteTableView';
import { ShowElement } from '@clippings/paper';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

type PurchaseSummaryProps = {
  hasPaymentAmount: boolean;
};

export const PurchaseSummary: FC<PurchaseSummaryProps> = ({ hasPaymentAmount }) => {
  const { t } = useTranslation();
  const { quote, hiddenDetailProducts, onProductDetailsHide } = useQuoteProvider();

  return (
    <>
      <ComponentGridContainer gap={{ xs: 2, md: 3 }} p={{ xs: 2, md: 3 }}>
        <ComponentHeader title={t('orders.summary')} />
        <ShowElement above="md">
          <>
            <QuoteTableView
              className="quote-view__table"
              readonly
              items={quote.quoteItemProducts}
              hiddenDetailProducts={hiddenDetailProducts}
              onProductDetailsHide={onProductDetailsHide}
            />
            <QuoteSummary renderPaymentTerms={hasPaymentAmount} />
          </>
        </ShowElement>
      </ComponentGridContainer>
      <ShowElement below="md">
        <Column gap={0.5}>
          <QuoteItemCardRenderer items={quote.quoteItemProducts} />
          <QuoteSummary renderPaymentTerms={hasPaymentAmount} />
        </Column>
      </ShowElement>
    </>
  );
};
