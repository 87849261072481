import React from 'react';
import { Button, Grid } from '@clippings/paper';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const CheckoutEndStatusLinks = () => {
  const { t } = useTranslation();
  const { getOrdersListPath } = useOrderPaths();
  const { quote } = useQuoteProvider();

  return (
    <Grid sx={stylesGrid} display="flex" gap={1}>
      <Button
        component={ReactRouterLink}
        to={getOrdersListPath(quote.number)}
        variant="contained"
        data-testid="checkout-pending-view-orders-link"
      >
        {t('common.viewOrders')}
      </Button>
      <Button href="/" variant="outlined" data-testid="checkout-pending-continue-shopping-link">
        {t('common.continueShopping')}
      </Button>
    </Grid>
  );
};

const stylesGrid = {
  marginTop: 3,
};
