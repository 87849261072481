import { PaymentRule } from 'libs/api/common/types';
import { Quote } from 'libs/api/quotes/types';
import { getFormErrorMessage } from '@clippings/paper';
import { useBanner } from 'libs/Components';
import { useEffect, useRef, useState } from 'react';
import { useUpdateQuotePaymentTerms } from 'libs/api/quotes/hooks';

import { StatusCodes } from 'http-status-codes';
import { getServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { translateValidationError, validatePaymentRules } from '../utils/paymentRuleValidation';

export function usePaymentTerms(
  initialPaymentTerms: PaymentRule[],
  quote: Quote,
  updateMutationOnSuccess: () => void,
  isDialogOpen: boolean
) {
  const [paymentTerms, setPaymentTerms] = useState(initialPaymentTerms);
  const newItemIndex = useRef(-1);
  const { showErrorBanner } = useBanner();

  useEffect(() => {
    if (!isDialogOpen) {
      setPaymentTerms(initialPaymentTerms);
    }
  }, [initialPaymentTerms, isDialogOpen]);

  const handleApply = () => {
    const errors = validatePaymentRules(paymentTerms);

    if (errors.length > 0) {
      showErrorBanner(errors.map(translateValidationError).join('. '));

      return;
    }

    updatePaymentTermsMutation.mutate({
      versionId: quote.versionId,
      paymentTerms: paymentTerms.map(paymentRule => ({
        ...paymentRule,
        id: Number(paymentRule.id) < 0 ? undefined : paymentRule.id,
      })),
    });
  };

  const updatePaymentTermsMutation = useUpdateQuotePaymentTerms(quote, {
    onSuccess: updateMutationOnSuccess,
    onError: error => {
      if (error?.response?.data.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        const formErrors = getServerFormErrors(error);
        const message = Object.keys(formErrors)
          .map(key => getFormErrorMessage(formErrors, key))
          .join('. ');

        showErrorBanner(message);
      }
    },
  });

  const handleChange = (index: number, rule: PaymentRule | null) => {
    const newPaymentTerms = [...paymentTerms];

    if (rule === null) {
      newPaymentTerms.splice(index, 1);
    } else {
      newPaymentTerms.splice(index, 1, rule);
    }

    setPaymentTerms(newPaymentTerms);
  };

  const handleAddNewPayment = () => {
    setPaymentTerms(old => [...old, createNewPaymentRule()]);
  };

  const createNewPaymentRule = (): PaymentRule => {
    const id = String(newItemIndex.current);

    newItemIndex.current--;

    return {
      id,
      type: 'event',
      dueEvent: 'on_order',
      dueDays: 0,
      percentage: null,
    };
  };

  return {
    handleApply,
    handleChange,
    handleAddNewPayment,
    paymentTerms,
    setPaymentTerms,
    isLoading: updatePaymentTermsMutation.isLoading,
  };
}
