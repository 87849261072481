import React, { ComponentProps, FC } from 'react';
import { Monetary } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { TotalsSummaryRow } from './TotalsSummaryRow';
import { TotalsSummaryRowLabel } from './TotalsSummaryRowLabel';
import { TotalsSummaryRowValue } from './TotalsSummaryRowValue';
import { convertToPriceObject } from 'libs/Utils';

interface TotalsSummarySubTotalRowProps extends ComponentProps<typeof TotalsSummaryRow> {
  label: string;
  subtotal: Quote['subtotal'];
}

export const TotalsSummarySubTotalRow: FC<TotalsSummarySubTotalRowProps> = ({
  label,
  subtotal,
  ...rest
}) => {
  return (
    <TotalsSummaryRow {...rest}>
      <TotalsSummaryRowLabel>{label}</TotalsSummaryRowLabel>
      <TotalsSummaryRowValue>
        <Monetary fontWeight={600} {...convertToPriceObject(subtotal, subtotal.currency as any)} />
      </TotalsSummaryRowValue>
    </TotalsSummaryRow>
  );
};
