import React from 'react';
import { Company, CreateUserVariables } from 'libs/api/iam/types';
import { FieldValues } from '@clippings/paper';
import { UserDialogScreen } from '../utils/user.utils';
import { isEmpty } from 'lodash';
import { useBanner } from 'libs/Components';
import { useTranslation } from 'react-i18next';

type UserFormValues = Omit<CreateUserVariables, 'companyId'> & { company: Company | undefined };

export const useCreateUserCompanyUtils = () => {
  const { showSuccessBanner } = useBanner();
  const { t } = useTranslation();

  const [companySearchValue, setCompanySearchValue] = React.useState('');
  const [createdCompany, setCreatedCompany] = React.useState<Company | null>(null);
  const [currentScreen, setCurrentScreen] = React.useState<UserDialogScreen>(UserDialogScreen.User);
  const [userFormValues, setUserFormValues] = React.useState<UserFormValues | undefined>(undefined);

  const onCompanyScreenReturn = (createdCompany?: Company) => {
    if (!isEmpty(createdCompany)) {
      setUserFormValues(oldUserFormValues => ({
        ...(oldUserFormValues as CreateUserVariables),
        company: createdCompany,
      }));
    }

    const companyName = userFormValues?.company?.name;
    if (companyName && companySearchValue !== companyName) {
      setUserFormValues(oldUserFormValues => ({
        ...(oldUserFormValues as CreateUserVariables),
        company: undefined,
      }));
    }

    setCurrentScreen(UserDialogScreen.User);
  };

  const onCreateNewCompanySuccess = (company: Company) => {
    setCreatedCompany(company);
    showSuccessBanner(t('companies.companyWasSuccessfullyCreated', { company: company.name }));

    onCompanyScreenReturn(company);
  };

  const onCreateNewCompanyClick = (formValues: FieldValues) => {
    setUserFormValues(formValues as UserFormValues);
    setCurrentScreen(UserDialogScreen.CreateCompany);
  };

  const onUserDialogClose = () => {
    setCompanySearchValue('');
    setCreatedCompany(null);
    setCurrentScreen(UserDialogScreen.User);
    setUserFormValues(undefined);
  };

  return {
    companySearchValue,
    setCompanySearchValue,
    createdCompany,
    setCreatedCompany,
    currentScreen,
    onCreateNewCompanySuccess,
    onCompanyScreenReturn,
    onCreateNewCompanyClick,
    onUserDialogClose,
    userFormValues,
    setUserFormValues,
  } as const;
};
