import React from 'react';
import { BaseUser } from 'libs/api/common/types';
import { Grid, Typography, UserAvatar, getUserFullName } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

type HelpLabelProps = {
  salesRep?: BaseUser;
};

export const HelpLabel: React.FC<HelpLabelProps> = ({ salesRep }) => {
  const { t } = useTranslation();

  if (!salesRep) {
    return (
      <Grid
        data-testid="help-label-missing-salesrep-container"
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <Typography data-testid="help-label-need-help">{t('common.needHelp')}</Typography>
        <Typography data-testid="help-label-send-message">
          {t('common.sendMessageToTeam')}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <UserAvatar user={salesRep} />
      <Grid
        data-testid="help-label-container"
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <Typography data-testid="help-label-text" color={'grey.A400'}>
          {t('common.needHelp')} {t('common.sendMessageTo')}
        </Typography>
        <Typography
          data-testid="help-label-full-username"
          variant="body1"
          fontWeight="fontWeightMedium"
        >
          {getUserFullName(salesRep)}
        </Typography>
      </Grid>
    </>
  );
};
