import React, { ComponentProps } from 'react';
import { Box, Button, Typography } from '@clippings/paper';
import { TotalsSummaryRow } from './TotalsSummaryRow';
import { TotalsSummaryRowLabel } from './TotalsSummaryRowLabel';
import { TotalsSummaryRowValue } from './TotalsSummaryRowValue';
import { useTranslation } from 'react-i18next';

interface TotalsSummaryShippingRowEmptyProps extends ComponentProps<typeof TotalsSummaryRow> {
  onClick?: () => void;
  disabled?: boolean;
  hideActions?: boolean;
}

export const TotalsSummaryShippingRowEmpty = ({
  onClick,
  disabled = false,
  hideActions = false,
  ...rest
}: TotalsSummaryShippingRowEmptyProps) => {
  const { t } = useTranslation();

  return (
    <TotalsSummaryRow width="100%" {...rest}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <TotalsSummaryRowLabel display="flex" justifyContent="center" alignItems="center">
          {t('common.shipping')}
        </TotalsSummaryRowLabel>
        <Typography
          sx={theme => ({ fontSize: 12, fontWeight: 400, color: theme.palette.error.main })}
        >
          {t('quotes.summary.addShippingDetailsToContinue')}
        </Typography>
      </Box>
      <TotalsSummaryRowValue>
        {!hideActions ? (
          <Button
            onClick={onClick}
            variant="contained"
            data-testid="add-shipping-button"
            disabled={disabled}
          >
            {t('common.add')}
          </Button>
        ) : null}
      </TotalsSummaryRowValue>
    </TotalsSummaryRow>
  );
};
