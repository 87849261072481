import { RegisterFormStep, RegisterFormSubmitValues } from '@clippings/paper';
import { register } from 'libs/api/iam/actions';
import { useAppConfiguration } from 'libs/providers';
import { useListLocations } from 'libs/api/location/hooks';
import { useMutation } from '@tanstack/react-query';
import { useRegisterUtils } from './useRegisterUtils';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';

export const useRegister = () => {
  const {
    activeStep,
    setActiveStep,
    handleSignInClick,
    handleBackToBrowseClick,
    handleBackToAccountDetails,
    catalogue,
  } = useRegisterUtils();

  const { companyTypes } = useAppConfiguration();
  const locations = useListLocations();

  const registerMutation = useMutation(register, {
    onSuccess: () => {
      setActiveStep(RegisterFormStep.Confirmation);
    },
  });

  const handleSubmit = (values: RegisterFormSubmitValues) =>
    registerMutation.mutate({ ...values, catalogue: catalogue.data.slug });

  const formErrors = useServerFormErrors(registerMutation.error);

  return {
    activeStep,
    setActiveStep,
    locations,
    companyTypes,
    handleSubmit,
    handleSignInClick,
    handleBackToBrowseClick,
    handleBackToAccountDetails,
    formErrors,
    registerMutation,
  };
};
