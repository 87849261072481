import React from 'react';
import { ArrowForwardIcon, Button } from '@clippings/paper';
import { useQuoteCheckoutContext } from '../../providers';
import { useTranslation } from 'react-i18next';

export function FooterStepSetup() {
  const { t } = useTranslation();
  const { setupReady, isUploadingPODocument, onCompleteStep } = useQuoteCheckoutContext();

  return (
    <Button
      variant="contained"
      onClick={onCompleteStep}
      data-testid="checkout-complete-setup"
      disabled={!setupReady || isUploadingPODocument}
    >
      {t('checkout.reviewOrder.title')}
      <ArrowForwardIcon sx={{ ml: 1 }} />
    </Button>
  );
}
