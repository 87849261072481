export enum ACTIONS {
  OPEN_LABEL_MODAL = 'OPEN_LABEL_MODAL',
  CLOSE_LABEL_MODAL = 'CLOSE_LABEL_MODAL',
  OPEN_SHIPPING_MODAL = 'OPEN_SHIPPING_MODAL',
  CLOSE_SHIPPING_MODAL = 'CLOSE_SHIPPING_MODAL',
  OPEN_BILLING_MODAL = 'OPEN_BILLING_MODAL',
  CLOSE_BILLING_MODAL = 'CLOSE_BILLING_MODAL',
}

export enum ACTION_AFTER_SUBMIT {
  NONE = 'NONE',
  REQUEST = 'REQUEST',
  APPROVE = 'APPROVE',
  CHECKOUT = 'CHECKOUT',
}

export type State = {
  isLabelModalOpen: boolean;
  isShippingModalOpen: boolean;
  isBillingModalOpen: boolean;
  isDetailedShippingModal: boolean;
  actionAfterSubmit: ACTION_AFTER_SUBMIT;
};

export type Action = {
  type: ACTIONS;
  payload?: {
    isDetailedShippingModal?: boolean;
    actionAfterSubmit?: ACTION_AFTER_SUBMIT;
  };
};

export const initialState = {
  isLabelModalOpen: false,
  isShippingModalOpen: false,
  isBillingModalOpen: false,
  isDetailedShippingModal: false,
  actionAfterSubmit: ACTION_AFTER_SUBMIT.NONE,
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.OPEN_LABEL_MODAL:
      return { ...state, isLabelModalOpen: true };
    case ACTIONS.CLOSE_LABEL_MODAL:
      return { ...state, isLabelModalOpen: false };
    case ACTIONS.OPEN_SHIPPING_MODAL:
      return {
        ...state,
        isShippingModalOpen: true,
        isDetailedShippingModal: action.payload?.isDetailedShippingModal || false,
        actionAfterSubmit: action.payload?.actionAfterSubmit || ACTION_AFTER_SUBMIT.NONE,
      };
    case ACTIONS.CLOSE_SHIPPING_MODAL:
      return {
        ...state,
        isShippingModalOpen: false,
        isDetailedShippingModal: false,
      };
    case ACTIONS.OPEN_BILLING_MODAL:
      return {
        ...state,
        isBillingModalOpen: true,
        actionAfterSubmit: action.payload?.actionAfterSubmit || ACTION_AFTER_SUBMIT.NONE,
      };
    case ACTIONS.CLOSE_BILLING_MODAL:
      return { ...state, isBillingModalOpen: false };
    default:
      return state;
  }
};
