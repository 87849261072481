import { TFunction } from '@clippings/paper';
import { camelCase } from 'lodash';

const baseTranslationKey = 'plp.category';

export function translateCategoryLabel(rawLabel: string, translateFunction: TFunction): string {
  const translateKey: any = `${baseTranslationKey}.${camelCase(rawLabel)}`;
  const translation = translateFunction(translateKey);

  return translation === translateKey ? rawLabel : translation;
}
