import React from 'react';

import { InboxSection } from 'libs/SalesApp/Inbox/InboxSection';
import { useMessageItems } from 'libs/SalesApp/Inbox/Messages/useMessageItems';
import { useTranslation } from 'react-i18next';

export const QuoteMessageSection: React.FC = () => {
  const { t } = useTranslation();

  const { items, hasMore, showMore, loading, channels } = useMessageItems();
  const title = `${t('common.messages')} (${loading ? '...' : channels.length})`;

  return (
    <InboxSection
      title={title}
      items={items}
      hasNextPage={hasMore}
      onNextClick={showMore}
      noResultsMessage={t('inbox.noUnreadChatMessages')}
      loading={loading}
      isFetchingNextPage={loading}
    />
  );
};
