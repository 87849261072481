import { useEffect, useMemo } from 'react';
import { useQueryParams } from './useQueryParams';

export const RETURN_URL = 'returnUrl';

export const useReturnUrl = () => {
  const [{ returnUrl }] = useQueryParams();

  useEffect(() => {
    if (returnUrl) {
      sessionStorage.setItem(RETURN_URL, returnUrl);
    }
  }, [returnUrl]);

  const backUrl = useMemo(() => {
    if (returnUrl) {
      return returnUrl;
    }

    const storageReturnUrl = sessionStorage.getItem(RETURN_URL);
    const defaultReturnUrl = getDefaultReturnUrl(window.location.host);

    return storageReturnUrl ?? defaultReturnUrl;
  }, [returnUrl]);

  return backUrl;
};

const returnUrlMap: Record<string, string> = {
  'shop-staging.moroso.it': 'staging2.moroso.it',
  'shop-staging.fomcore.com': 'staging.fomcore.com',
  'shop-staging.sossegodesign.com': 'staging.sossegodesign.com',
};

export function getDefaultReturnUrl(hostUrl: string): string {
  // Development environments
  if (hostUrl.includes('designtechnologies.dev')) {
    return `https://${hostUrl}`;
  }

  // If the host is in the map, return the value from the map. Staging environments
  if (returnUrlMap[hostUrl]) {
    return `https://${returnUrlMap[hostUrl]}`;
  }

  // Production environments
  const host = hostUrl.split('.');
  return `https://${host.length === 3 ? `${host[1]}.${host[2]}` : window.location.host}`;
}
