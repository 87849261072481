import { CheckoutData, SalesOrderDocument, SignatureData } from 'libs/api/order/types';
import { Nullable } from 'libs/Utils';
import { PaymentMethod, StripeComponents } from 'libs/api/payment/types';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

export enum ACTIONS {
  ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS',
  BACK_STEP = 'BACK_STEP',
  COMPLETE_STEP = 'COMPLETE_STEP',
  ERROR_PAYMENT = 'ERROR_PAYMENT',
  SET_CARD_COMPLETE = 'SET_CARD_COMPLETE',
  SET_DATA = 'SET_DATA',
  SET_HAS_PO_NUMBER = 'SET_HAS_PO_NUMBER',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_SIGNATURE_MODAL = 'SET_SIGNATURE_MODAL',
  SET_TERMS_AND_CONDITIONS_MODAL = 'SET_TERMS_AND_CONDITIONS_MODAL',
  SIGN = 'SIGN',
  SUBMIT_SIGNATURE = 'SUBMIT_SIGNATURE',
  SUCCESSFUL_BANK_TRANSFER = 'SUCCESSFUL_BANK_TRANSFER',
  SUCCESSFUL_PAYMENT = 'SUCCESSFUL_PAYMENT',
  SET_IS_UPLOADING_PO_DOCUMENT = 'SET_IS_UPLOADING_PO_DOCUMENT',
  SET_PO_DOCUMENT = 'SET_PO_DOCUMENT',
}

export type ActionTypes =
  | { type: ACTIONS.ACCEPT_TERMS_AND_CONDITIONS }
  | { type: ACTIONS.BACK_STEP }
  | { type: ACTIONS.COMPLETE_STEP }
  | { type: ACTIONS.ERROR_PAYMENT; payload: string }
  | { type: ACTIONS.SET_CARD_COMPLETE; payload: boolean }
  | { type: ACTIONS.SET_DATA; payload: any } //Todo  properly type hint one or more properties of CheckoutData
  | { type: ACTIONS.SET_HAS_PO_NUMBER; payload: boolean }
  | { type: ACTIONS.SET_PAYMENT_METHOD; payload: PaymentMethod }
  | { type: ACTIONS.SET_SIGNATURE_MODAL; payload: boolean }
  | { type: ACTIONS.SET_TERMS_AND_CONDITIONS_MODAL; payload: boolean }
  | { type: ACTIONS.SIGN }
  | { type: ACTIONS.SUBMIT_SIGNATURE; payload: any }
  | { type: ACTIONS.SUCCESSFUL_BANK_TRANSFER }
  | { type: ACTIONS.SUCCESSFUL_PAYMENT }
  | { type: ACTIONS.SET_IS_UPLOADING_PO_DOCUMENT; payload: boolean }
  | { type: ACTIONS.SET_PO_DOCUMENT; payload: Nullable<SalesOrderDocument> };

export type CheckoutActions = {
  acceptTermsAndCondition: () => void;
  onBackStep: () => void;
  onCompleteStep: () => void;
  onPaymentTypeSelect: (paymentMethod: PaymentMethod) => void;
  onSignPayClick: () => void;
  populateStripeComponents: (components: StripeComponents) => void;
  handleCardFormEvents: (event: StripePaymentElementChangeEvent) => void;
  handleSignatureSubmit: (formValues: SignatureData) => void;
  completePayment: () => void;
  setData: (data: Partial<CheckoutData>) => void;
  setHasPONumber: (has: boolean) => void;
  toggleSignatureModal: () => void;
  toggleTermsAndCondition: () => void;
  toggleTermsAndConditionModal: () => void;
  setIsUploadingPODocument: (isUploading: boolean) => void;
  setPODocument: (document: Nullable<SalesOrderDocument>) => void;
};
