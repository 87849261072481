import { ACTION_AFTER_SUBMIT } from '../../hooks/quoteReducer';
import { useQuoteCheckoutHandler } from 'libs/Quotes/Checkout/hooks';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useRequestQuote } from 'libs/shared/hooks/useRequestQuote';

export enum TYPE_HANDLERS {
  BILLING = 'billing',
  SHIPPING = 'shipping',
}

export const usePostSubmitHandler = () => {
  const {
    handleShippingModalClose,
    actionAfterSubmit,
    handleShippingModalOpen,
    handleBillingModalOpen,
    handleBillingModalClose,
  } = useQuoteProvider();

  const onRequest = useRequestQuote();
  const { quoteCheckoutHandler } = useQuoteCheckoutHandler(
    handleShippingModalOpen,
    handleBillingModalOpen
  );

  const handleAfterSubmit = async () => {
    switch (actionAfterSubmit) {
      case ACTION_AFTER_SUBMIT.NONE:
        break;
      case ACTION_AFTER_SUBMIT.REQUEST:
        await onRequest();
        break;
      case ACTION_AFTER_SUBMIT.CHECKOUT:
        quoteCheckoutHandler();
        break;
    }
  };

  const onModalClose = async (type: TYPE_HANDLERS) => {
    await handleAfterSubmit();

    if (type === TYPE_HANDLERS.BILLING) {
      handleBillingModalClose();
    } else {
      handleShippingModalClose();
    }
  };

  return {
    onModalClose,
  };
};
