import React from 'react';
import { Column } from 'libs/shared';
import { Monetary, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

type PaymentTermsSummaryProps = {
  totalAmount: number;
  totalPercentage: number;
  currency: string;
};

export function PaymentTermsSummary({
  totalAmount,
  totalPercentage,
  currency,
}: PaymentTermsSummaryProps) {
  const { t } = useTranslation();
  const termsTotal = Number(((totalPercentage / 100) * totalAmount).toFixed(2));

  return (
    <Column justifyContent="space-between" pt={2}>
      {termsTotal !== totalAmount && (
        <Typography variant="subtitle2" pt="7px" color="error.main">
          {t('paymentTerms.totalPercentageError')}
        </Typography>
      )}
      <Column alignItems="flex-end">
        <Typography variant="subtitle2" fontWeight="bold">
          {t('paymentTerms.paymentTermsTotal')}:
          <Monetary
            amount={termsTotal}
            currency={currency}
            variant="h6"
            // @ts-expect-error due to extending MUI typess
            component="p"
            display="inline-block"
            pl={1}
            color={termsTotal !== totalAmount ? 'error.main' : 'success.main'}
            fontWeight="bold"
          />
        </Typography>
        <Typography textAlign="right" variant="subtitle2" color="text.secondary">
          {t('paymentTerms.quoteTotal')}:
          <Monetary amount={totalAmount} currency={currency} pl={1} display="inline-block" />
        </Typography>
      </Column>
    </Column>
  );
}
