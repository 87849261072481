import React, { PropsWithChildren } from 'react';
import { Box } from '@clippings/paper';

type CardProps = {
  onClick?: () => void;
};

export function Card({ onClick, children }: PropsWithChildren<CardProps>) {
  return (
    <Box
      p={2}
      bgcolor="common.white"
      border="1px solid"
      borderColor="grey.A200"
      borderRadius={1}
      data-testid="quote-item-card"
      onClick={onClick}
    >
      {children}
    </Box>
  );
}
