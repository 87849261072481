import QuoteContext from '../QuoteContext';
import React from 'react';
import { ChatChannelProvider } from 'libs/Chat';
import { Checkout } from './components/Checkout';
import { CheckoutLoading } from './components';
import { Payment } from 'libs/api/common/types';
import { useQuoteCheckout } from '../hooks/useQuoteCheckout';
import { useQuoteCheckoutGuard } from './hooks';
import { useQuoteHiddenDetailProducts } from '../hooks/useQuoteHiddenDetailProducts';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const QuoteCheckoutPage: React.FC = () => {
  const { t } = useTranslation();

  const { isLoading, quote, paymentTermsQuery, immediatePayment, number } = useQuoteCheckout();
  const [hiddenDetailProducts, onProductDetailsHide, onAllProductDetailsHide] =
    useQuoteHiddenDetailProducts(number, 'quote');

  useTitle(t('common.checkout'));
  useQuoteCheckoutGuard(quote?.status, quote?.number, number, isLoading);

  if (isLoading) {
    return <CheckoutLoading />;
  }

  return (
    <QuoteContext.Provider
      // @ts-expect-error: the context is incomplete it will be refactored on smaller parts
      value={{
        quote,
        paymentTermsQuery,
        hiddenDetailProducts,
        onProductDetailsHide,
        onAllProductDetailsHide,
      }}
    >
      <ChatChannelProvider number={number}>
        <Checkout payment={immediatePayment as Payment} />
      </ChatChannelProvider>
    </QuoteContext.Provider>
  );
};
