import { LineItemVisibleFields } from 'libs/api/configuration/types';
import { PdfVariant, noDiscountPdfVariant, useQuotePdfViewModel } from './useQuotePdfViewModel';
import { Quote } from 'libs/api/quotes/types';
import { pdfVersionQueryKey } from './pdfExport.config';
import { useQueryParam, useShouldRenderPdfWithoutDiscount } from 'libs/shared';

const noDiscountVariantHiddenColumns: LineItemVisibleFields = ['discount', 'net'];

export function useQuotePdfExportPage(quote: Quote) {
  const { queryParam: pdfVersionParam } = useQueryParam(pdfVersionQueryKey);
  const viewDataModel = useQuotePdfViewModel(quote, pdfVersionParam as PdfVariant);
  const { shouldRenderPdfWithoutDiscount } = useShouldRenderPdfWithoutDiscount();

  const isNoDiscountVariant = (pdfVersionParam as PdfVariant) === noDiscountPdfVariant;
  const shouldRedirectToUnauthorized = isNoDiscountVariant && !shouldRenderPdfWithoutDiscount;

  const hiddenColumns = isNoDiscountVariant ? noDiscountVariantHiddenColumns : [];

  return { viewDataModel, shouldRedirectToUnauthorized, hiddenColumns };
}
