import React, { useState } from 'react';
import visitor from 'libs/Globals/visitor';
import {
  ArrowDownIcon,
  Box,
  IconButton,
  ListItemIcon,
  LogoutIcon,
  Menu,
  MenuItem,
  UserAvatar,
} from '@clippings/paper';
import { routes } from 'libs/Constants';
import { useTranslation } from 'react-i18next';

const AvatarMenu = ({ ...rest }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box {...rest}>
      <IconButton
        disableRipple
        onClick={handleClick}
        aria-controls={isOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      >
        <UserAvatar
          user={{
            firstName: visitor.firstName,
            lastName: visitor.lastName,
            picture: visitor.avatar,
          }}
        />
        <ArrowDownIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        <MenuItem href={routes.logout} component="a">
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('common.signOut')}
        </MenuItem>
      </Menu>
    </Box>
  );
};

AvatarMenu.propTypes = {};

AvatarMenu.defaultTypes = {};

export default AvatarMenu;
