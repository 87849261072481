import React from 'react';
import { AddressFormFields } from 'libs/shared/components/address/AddressFormFields';
import { AddressTypesEnum } from 'libs/Quotes/hooks/useAddresses';
import { FormCheckboxField } from '@clippings/paper';
import { useAddressContext } from '../AddressContext';
import { useTranslation } from 'react-i18next';

export interface QuoteAddressFormFieldsProps {
  detailed: boolean;
}

export const QuoteAddressFormFields: React.FC<QuoteAddressFormFieldsProps> = ({ detailed }) => {
  const { t } = useTranslation();
  const { selectedAddress } = useAddressContext();

  return (
    <AddressFormFields type="shipping" detailed={detailed}>
      {selectedAddress?.type === AddressTypesEnum.COMPANY && (
        <FormCheckboxField
          key="default"
          name="default"
          label={t('common.makeDefault')}
          {...(selectedAddress.default && {
            disabled: true,
            checked: true,
          })}
        />
      )}
    </AddressFormFields>
  );
};
