import React from 'react';
import { Grid, Typography, alpha } from '@clippings/paper';

type ItemRowProps = {
  label: string;
  value: JSX.Element;
};

export function ItemRow({ label, value }: ItemRowProps) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={5} pr={3}>
        <Typography
          variant="body2"
          fontWeight={500}
          color={theme => alpha(theme.palette.common.black, 0.6)}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {value}
      </Grid>
    </Grid>
  );
}
