import React, { ComponentProps, FC } from 'react';
import { AddIcon, Button } from '@clippings/paper';

export interface CreateUserButtonProps extends Omit<ComponentProps<typeof Button>, 'type'> {
  label: string;
}

export const CreateUserButton: FC<CreateUserButtonProps> = ({ label, ...rest }) => (
  <Button {...rest} variant="contained" startIcon={<AddIcon />}>
    {label}
  </Button>
);
