import { useInboxCustomer } from 'libs/SalesApp/Inbox/hooks/useInboxCustomer';
import { useInboxQuotes } from 'libs/SalesApp/Inbox/hooks/useInboxQuotes';
import { useUnreadChannels } from 'libs/Chat/utils/useUnreadChannels';

export const useInboxCount = () => {
  const { channels, loading } = useUnreadChannels();
  const customerQuery = useInboxCustomer();
  const quoteQuery = useInboxQuotes();

  // @ts-ignore
  const count = channels.length + customerQuery?.data?.totalItems + quoteQuery?.data?.totalItems;

  return {
    count,
    loading: customerQuery.isLoading || quoteQuery.isLoading || loading,
  };
};
