import React, { PropsWithChildren } from 'react';
import { AddNewEntityButton } from 'libs/shared';
import { DataGrid, FlexColumn, useTranslation } from '@clippings/paper';

export interface ProductDiscountProps {
  onCreateNewDiscount: () => void;
}

export const ProductDiscountTable: React.FC<PropsWithChildren<ProductDiscountProps>> = ({
  children,
  onCreateNewDiscount,
}) => {
  const { t } = useTranslation();

  return (
    <FlexColumn gap={2}>
      <DataGrid data-testid="salesapp-companies-editor-product-discounts">{children}</DataGrid>
      <AddNewEntityButton
        data-testid="product-discounts-add-new-button"
        text={`${t('common.addNew')} ${t('companies.productDiscountLabel')}`}
        onClick={onCreateNewDiscount}
      />
    </FlexColumn>
  );
};
