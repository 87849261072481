import React from 'react';
import { ConfiguredProduct } from 'libs/SalesApp/Quotes/common/types';
import { InfoCellCustomItem } from './InfoCellCustomItem';
import { InfoCellSampleItem } from './InfoCellSampleItem';
import { ProductInfo } from 'libs/SalesApp/Quotes/components';
import { QuoteItemHiddenDetails } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { TableCellCommonProps } from '../tableCells.utils';
import { TableCellMode } from '../TableCellWithMode';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';
import { buildVariationName } from 'libs/shared';

export type InfoCellItemRendererProps = {
  mode: TableCellMode;
  detailsHidden: boolean;
  shouldIncludePropertyCode: boolean;
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  handleUpdate: UpdateQuoteByKey;
  handleMakeCustom: () => void;
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
} & TableCellCommonProps;

export function InfoCellItemRenderer({
  item,
  loading,
  readonly,
  mode,
  detailsHidden,
  shouldIncludePropertyCode,
  handleUpdate,
  onConfigure,
  handleMakeCustom,
  onProductDetailsHide,
}: InfoCellItemRendererProps) {
  // the item is a sample
  if (item.sample) {
    const { properties, productName, variationName } = item;
    const sampleName = buildVariationName({ properties, productName, variationName });

    return <InfoCellSampleItem sampleName={sampleName} />;
  }

  // the item is custom
  if (item.custom) {
    return (
      <InfoCellCustomItem
        item={item}
        value={buildVariationName(item, shouldIncludePropertyCode)}
        readonly={readonly}
        mode={mode}
        handleUpdate={handleUpdate}
      />
    );
  }

  // regular product item
  return (
    <ProductInfo
      item={item}
      readonly={readonly}
      loading={loading}
      detailsHidden={detailsHidden}
      onConfigure={onConfigure}
      handleMakeCustom={handleMakeCustom}
      onProductDetailsHide={onProductDetailsHide}
    />
  );
}
