import React, { FC, PropsWithChildren } from 'react';
import { Box, Button, DateTimeFormat, Skeleton, useFormatDateTime } from '@clippings/paper';
import { QUOTE_STATUSES } from 'libs/Constants';
import { QuoteFooterDetailsText } from './QuoteFooterDetailsText';
import { QuoteFooterDownloadButton } from './QuoteFooterDownloadButton';
import { canUndoQuoteVersion, useIsInSalesApp } from 'libs/shared';
import { useQuoteProvider } from '../providers';
import { useTranslation } from 'react-i18next';

type QuoteFooterDetailsProps = {
  withDownloadButton: boolean;
};

const FooterComponentsWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
    {children}
  </Box>
);

export const QuoteFooterDetails = ({ withDownloadButton }: QuoteFooterDetailsProps) => {
  const { quote, loading, onLoadLatestVersion, onDeleteQuoteVersion } = useQuoteProvider();
  const isSalesApp = useIsInSalesApp();
  const formatDateTime = useFormatDateTime();
  const { status, orderedAt, expiresAt, active } = quote;
  const { t } = useTranslation();

  if (loading) {
    return (
      <Skeleton data-testid="quote-footer-details-loading" variant="text" width={300} height={24} />
    );
  }

  if (status === QUOTE_STATUSES.ARCHIVED) {
    return (
      <FooterComponentsWrapper>
        {isSalesApp && <QuoteFooterDownloadButton />}
        <QuoteFooterDetailsText
          data-testid="quote-footer-info-archived"
          text={t('quotes.footer.details.archived')}
        />
      </FooterComponentsWrapper>
    );
  }

  if (!active) {
    return (
      <Box>
        <QuoteFooterDetailsText
          data-testid="quote-footer-info-old"
          text={t('quotes.footer.details.viewingOlderVersion')}
        >
          <Button variant="text" onClick={onLoadLatestVersion}>
            {t('quotes.footer.actions.viewLatestVersion')}
          </Button>
        </QuoteFooterDetailsText>
      </Box>
    );
  }

  if (status === QUOTE_STATUSES.ORDERED) {
    return (
      <FooterComponentsWrapper>
        {isSalesApp && <QuoteFooterDownloadButton />}
        <QuoteFooterDetailsText
          data-testid="quote-footer-info-ordered"
          text={t('quotes.footer.details.orderedOn', {
            date: orderedAt && formatDateTime(new Date(orderedAt), DateTimeFormat.Date),
          })}
        />
      </FooterComponentsWrapper>
    );
  }

  if (status === QUOTE_STATUSES.EXPIRED) {
    return (
      <QuoteFooterDetailsText
        data-testid="quote-footer-info-expired"
        text={t('quotes.footer.details.expiredAt', {
          date: expiresAt && formatDateTime(new Date(expiresAt), DateTimeFormat.Date),
        })}
      />
    );
  }

  return (
    <FooterComponentsWrapper>
      {withDownloadButton ? <QuoteFooterDownloadButton /> : null}
      {canUndoQuoteVersion(quote) && (
        <QuoteFooterDetailsText
          data-testid="quote-footer-info-approved-edited"
          text={t('quotes.footer.details.approvedEdited')}
        >
          <Button variant="text" onClick={onDeleteQuoteVersion}>
            {t('quotes.footer.actions.undoApprovedEdited')}
          </Button>
        </QuoteFooterDetailsText>
      )}
    </FooterComponentsWrapper>
  );
};
