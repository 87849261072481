import React from 'react';
import { Address, AddressType } from 'libs/api/common/types';
import { AddressDetails } from './AddressDetails';
import { Box, Link, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export interface AddressItemProps {
  address: Address;
  type: AddressType;
  onMakeDefault?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const AddressItem: React.FC<AddressItemProps> = ({
  address,
  type,
  onMakeDefault,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={1} flexBasis="100%" p={1}>
      <AddressDetails address={address} />
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        {address.default && (
          <Typography data-testid="address-item-default" variant="body2" color="primary">
            {t('common.default')}
          </Typography>
        )}
        {!address.default && onMakeDefault && (
          <Link
            component="button"
            color="secondary"
            onClick={onMakeDefault}
            data-testid={`make-default-${type}-address-${address.id}`}
          >
            {t('common.makeDefault')}
          </Link>
        )}
        {onEdit && (
          <Link
            component="button"
            color="secondary"
            onClick={onEdit}
            data-testid={`edit-${type}-address-${address.id}`}
          >
            {t('common.edit')}
          </Link>
        )}
        {(!address.default || type === 'shipping') && onDelete && (
          <Link
            component="button"
            color="secondary"
            onClick={onDelete}
            data-testid={`delete-${type}-address-${address.id}`}
          >
            {t('common.delete')}
          </Link>
        )}
      </Box>
    </Box>
  );
};
