import React from 'react';
import classNames from 'classnames';
import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  QuantityInput,
  TableCell,
  Typography,
} from '@clippings/paper';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { TableCellCommonProps } from './tableCells.utils';

type OrderTableCellProps = {
  totalRows: number;
  onReorder: (currentOrder: QuoteProductItem, newOrder: number) => void;
} & TableCellCommonProps;

export function OrderTableCell({
  item,
  readonly,
  loading,
  totalRows,
  onReorder,
}: OrderTableCellProps) {
  const value = item.position + 1;

  return (
    <TableCell
      style={tableCellStyles}
      data-testid={`quote-item-cell-order-${item.id}`}
      className={classNames('quote-item-row__cell', 'quote-item-row__cell__order')}
    >
      {readonly ? (
        <Typography
          className="quote-item-row__order"
          variant="body2"
          color={theme => theme.palette.grey[400]}
          padding="12px 0"
        >
          {value}
        </Typography>
      ) : (
        <QuantityInput
          size="small"
          min={1}
          max={totalRows}
          disabled={loading}
          className="quote-item-row__order"
          initialQuantity={value}
          onQuantityChange={(value: number) => {
            onReorder(item, value);
          }}
          borderless
          LeftIcon={ArrowUpwardIcon}
          RightIcon={ArrowDownwardIcon}
          inputProps={{
            sx: {
              '.MuiInputBase-input': {
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'grey.400',
                padding: '2px 0px !important',
                fontSize: theme => theme.typography.pxToRem(14),
              },
            },
          }}
        />
      )}
    </TableCell>
  );
}

const tableCellStyles: any = {
  padding: '8px 0px',
  textAlign: 'center',
};
