import { AccountStepValues, RegisterFormSubmitValues } from '@clippings/paper';
import {
  BillingAddress,
  CompanyDiscount,
  Paginated,
  PaymentRule,
  ShippingAddress,
} from 'libs/api/common/types';
import {
  Company,
  CreateCompanyVariables,
  CreateTeamVariables,
  CreateUserVariables,
  ListCompaniesParams,
  ListTeamsParams,
  ListUsersParams,
  PdfTemplate,
  Team,
  TeamUpdateVariables,
  TeamV2,
  UpdateCompanyVariables,
  UpdateUserVariables,
  User,
  UserChangePasswordValues,
  UserCredentials,
} from './types';
import { DEFAULT_PAGE_SIZE, axios, path } from 'libs/Utils';

export const apiPaths = {
  register: '/api/iam/v1/register',
  registerGuest: '/api/iam/v1/register-guest',
  login: '/api/iam/v1/login',
  changePassword: '/api/iam/v1/change-password',
  users: '/api/iam/v1/users',
  usersV2: '/api/iam/v2/users',
  teams: '/api/iam/v1/teams',
  teamsV2: '/api/iam/v2/teams',
  team: '/api/iam/v1/teams/:teamId',
  user: '/api/iam/v1/users/:userId',
  me: '/api/iam/v1/me',
  companies: '/api/iam/v1/companies',
  company: '/api/iam/v1/companies/:companyId',
  companyDiscounts: '/api/iam/v1/companies/:companyId/discounts',
  companyPaymentTerms: '/api/iam/v1/companies/:companyId/payment-terms',
  companyShippingAddresses: '/api/iam/v1/companies/:companyId/shipping-addresses',
  companyShippingAddress: '/api/iam/v1/companies/:companyId/shipping-addresses/:addressId',
  companyBillingAddresses: '/api/iam/v1/companies/:companyId/billing-addresses',
  companyBillingAddress: '/api/iam/v1/companies/:companyId/billing-addresses/:addressId',
  resetPassword: '/api/iam/v1/reset-password',
  pdfTemplates: '/api/iam/v2/teams/pdf-templates',
};

export const register = (values: RegisterFormSubmitValues & { catalogue?: string }) =>
  axios.post(apiPaths.register, values).then(response => response.data);

export const guestRegister = (values: AccountStepValues & { catalogue?: string }) =>
  axios.post(apiPaths.registerGuest, values).then(response => response.data);

export const changePassword = (data: UserChangePasswordValues) =>
  axios.post<User>(apiPaths.changePassword, data).then(response => response.data);

export const login = (credential: UserCredentials) => axios.post<User>(apiPaths.login, credential);

export const listUsers = (params?: ListUsersParams) =>
  axios
    .get<User[]>(apiPaths.users, { params: { page: 1, sort: 'firstName', ...params } })
    .then(response => response.data);

export const listUsersV2 = (params?: ListUsersParams) =>
  axios
    .get<Paginated<User>>(apiPaths.usersV2, {
      params: { page: 1, size: DEFAULT_PAGE_SIZE, sort: 'firstName', ...params },
    })
    .then(response => response.data);

export const getCurrentUser = () => axios.get<User>(apiPaths.me).then(response => response.data);

export const createUser = (user: CreateUserVariables) =>
  axios.post<User>(apiPaths.users, user).then(response => response.data);

export const getUser = (userId: number) =>
  axios.get<User>(path(apiPaths.user, { userId })).then(response => response.data);

export const updateUser = (userId: number, params: UpdateUserVariables) =>
  axios.patch<User>(path(apiPaths.user, { userId }), params).then(response => response.data);

export const listCompanies = (params?: ListCompaniesParams) =>
  axios
    .get<Company[]>(apiPaths.companies, { params: { page: 1, ...params } })
    .then(response => response.data);

export const getCompany = (companyId: number) =>
  axios.get<Company>(path(apiPaths.company, { companyId })).then(response => response.data);

export const createCompany = (company: CreateCompanyVariables) =>
  axios.post<Company>(apiPaths.companies, company).then(response => response.data);

export const updateCompany = (companyId: number, company: UpdateCompanyVariables['company']) =>
  axios
    .patch<Company>(path(apiPaths.company, { companyId }), company)
    .then(response => response.data);

export const getCompanyDiscounts = (companyId: number) =>
  axios
    .get<CompanyDiscount[]>(path(apiPaths.companyDiscounts, { companyId }))
    .then(response => response.data);

export const replaceCompanyDiscounts = (companyId: number, discounts: CompanyDiscount[]) =>
  axios
    .put<CompanyDiscount[]>(path(apiPaths.companyDiscounts, { companyId }), discounts)
    .then(response => response.data);

export const getCompanyPaymentTerms = (companyId: number) =>
  axios
    .get<PaymentRule[]>(path(apiPaths.companyPaymentTerms, { companyId }))
    .then(response => response.data);

export const updateCompanyPaymentTerms = (companyId: number, paymentTerms: PaymentRule[]) =>
  axios
    .put<PaymentRule[]>(path(apiPaths.companyPaymentTerms, { companyId }), paymentTerms)
    .then(response => response.data);

export const listCompanyShippingAddresses = (companyId: number) =>
  axios
    .get<ShippingAddress[]>(path(apiPaths.companyShippingAddresses, { companyId }))
    .then(response => response.data);

export const createCompanyShippingAddress = (companyId: number, address: ShippingAddress) =>
  axios
    .post<ShippingAddress>(path(apiPaths.companyShippingAddresses, { companyId }), address)
    .then(response => response.data);

export const updateCompanyShippingAddress = (
  companyId: number,
  addressId: number,
  address: ShippingAddress
) =>
  axios
    .put<ShippingAddress>(path(apiPaths.companyShippingAddress, { companyId, addressId }), address)
    .then(response => response.data);

export const deleteCompanyShippingAddress = (companyId: number, addressId: number) =>
  axios
    .delete<void>(path(apiPaths.companyShippingAddress, { companyId, addressId }))
    .then(response => response.data);

export const listCompanyBillingAddresses = (companyId: number) =>
  axios
    .get<BillingAddress[]>(path(apiPaths.companyBillingAddresses, { companyId }))
    .then(response => response.data);

export const updateCompanyBillingAddress = (
  companyId: number,
  addressId: number,
  address: BillingAddress
) =>
  axios
    .put<BillingAddress>(path(apiPaths.companyBillingAddress, { companyId, addressId }), address)
    .then(response => response.data);

export const resetPassword = (email: string) =>
  axios.post(apiPaths.resetPassword, { email }).then(response => response.data);

export const listTeams = (params?: ListTeamsParams) =>
  axios
    .get<Team[]>(apiPaths.teams, { params: { page: 1, ...params } })
    .then(response => response.data);

export const listTeamsV2 = (params?: ListTeamsParams) =>
  axios
    .get<Paginated<TeamV2>>(apiPaths.teamsV2, {
      params: { page: 1, size: DEFAULT_PAGE_SIZE, ...params },
    })
    .then(response => response.data);

export const getTeam = (teamId: number) =>
  axios.get<Team>(path(apiPaths.team, { teamId })).then(response => response.data);

export const createTeam = (team: CreateTeamVariables) =>
  axios.post<Team>(apiPaths.teams, team).then(response => response.data);

export const updateTeam = ({ teamId, team }: TeamUpdateVariables) =>
  axios.put<Team>(path(apiPaths.team, { teamId }), team).then(response => response.data);

export const listPdfTemplates = () =>
  axios.get<PdfTemplate[]>(apiPaths.pdfTemplates).then(response => response.data);
