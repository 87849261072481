import React from 'react';
import { Box } from '@clippings/paper';
import { MorosoBankDetails } from 'libs/Quotes/PdfExport/components/info/MorosoBankDetails';
import { MorosoExtraPaymentInfo } from 'libs/Quotes/PdfExport/components/info/MorosoExtraPaymentInfo';
import { NoteSection } from 'libs/Quotes/PdfExport/components/NoteSection';
import { PdfLayoutDivider, Title, voidFn } from 'libs/shared';
import { PdfTemplateProps } from 'libs/Quotes/PdfExport/templates/PdfDefaultTemplate';
import { QuoteCompanySameRow } from 'libs/Quotes/PdfExport/components/QuoteCompanySameRow';
import { QuoteDivider } from 'libs/Quotes/PdfExport/components/QuoteDivider';
import { QuoteInfo } from 'libs/Quotes/PdfExport/components';
import { QuoteSummary } from 'libs/Quotes/QuoteSummary';
import { QuoteTableView } from 'libs/Quotes/QuoteTable/components';
import { useTranslation } from 'react-i18next';

export const MorosoPaymentInfoTemplate: React.FC<PdfTemplateProps> = ({
  isLoading,
  hiddenColumns,
  viewDataModel,
  hiddenDetailProducts,
  onProductDetailsHide,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <QuoteCompanySameRow />
      <PdfLayoutDivider />
      <QuoteInfo />
      <PdfLayoutDivider />
      <Title>{t('quotes.pdf.productsTitle')}</Title>
      <QuoteTableView
        className="pdf-table"
        textOnly
        readonly
        canAddQuoteItem={false}
        items={viewDataModel.items}
        catalogue={viewDataModel.catalogue}
        tableLoading={isLoading}
        hiddenDetailProducts={hiddenDetailProducts}
        hiddenColumns={hiddenColumns}
        handleDelete={voidFn}
        handleQuantityUpdate={voidFn}
        onConfigure={voidFn}
        handleOpenPlp={voidFn}
        onNoteSave={voidFn}
        onProductDetailsHide={onProductDetailsHide}
      />
      <MorosoExtraPaymentInfo />
      <QuoteDivider />
      <Box className="pdf-summary" display="flex" justifyContent="space-between">
        <Box>
          <MorosoBankDetails />
          <NoteSection divider />
        </Box>
        <QuoteSummary textOnly readonly />
      </Box>
    </>
  );
};
