import React from 'react';
import { ApplicableDeliveryMethod } from 'libs/api/quotes/types';
import {
  DashLocale,
  MenuItem,
  Skeleton,
  Typography,
  convertDashToUnderscore,
} from '@clippings/paper';
import { Translatable } from 'libs/api/common/types';
import { useUserLocale } from 'libs/Utils/locales';

const getTranslated = (translatables: Translatable[], userLocale: DashLocale) => {
  const currentUserLocale = convertDashToUnderscore(userLocale);
  const translatable = translatables.find(v => v.locale === currentUserLocale) ?? translatables[0];

  return translatable ? translatable.text : '';
};

interface DeliveryMethodsPopoverProps {
  deliveryMethods?: ApplicableDeliveryMethod[];
  onSetDeliveryMethod: (method: ApplicableDeliveryMethod) => void;
}

export const DeliveryMethodsPopover: React.FC<DeliveryMethodsPopoverProps> = ({
  deliveryMethods,
  onSetDeliveryMethod,
}) => {
  const { userLocale } = useUserLocale();

  if (!deliveryMethods) {
    return <Skeleton width="100%" height="54px" variant="rectangular" />;
  }

  return (
    <>
      {deliveryMethods
        .filter(x => x.names.map(x => x.text))
        .map(method => (
          <MenuItem
            key={method.id}
            sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
            onClick={() => onSetDeliveryMethod(method)}
            data-testid={`delivery-method-${method.id}`}
          >
            <Typography data-testid="delivery-method-popover-names" variant="subtitle2">
              {getTranslated(method.names, userLocale)}
            </Typography>
            <Typography
              data-testid="delivery-method-popover-descriptions"
              variant="caption"
              color="text.secondary"
            >
              {getTranslated(method.descriptions, userLocale)}
            </Typography>
          </MenuItem>
        ))}
    </>
  );
};
