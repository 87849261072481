import { AddressTypesEnum } from 'libs/Quotes/hooks/useAddresses';
import { BillingAddress, ShippingAddress } from 'libs/api/common/types';
import { reducer } from './addressReducer';
import { useReducer } from 'react';

export const SCREENS = {
  CREATE_ADDRESS: 'new',
  LIST: 'list',
  DELIVERY_METHOD: 'delivery_method',
  EDIT_ADDRESS: 'edit',
  SAME_AS_BILLING: 'same_as_billing',
  CUSTOMER_PICKUP: 'customer_pickup',
} as const;

export type Address = ShippingAddress | BillingAddress | null;
const getInitialScreen = (isDetailedShippingModal: boolean, currentQuoteAddress: Address) => {
  if (currentQuoteAddress && 'exWorks' in currentQuoteAddress && currentQuoteAddress.exWorks) {
    return SCREENS.CUSTOMER_PICKUP;
  }

  if (isDetailedShippingModal) {
    return SCREENS.EDIT_ADDRESS;
  }

  return SCREENS.LIST;
};

export const useAddressScreenReducer = (data: {
  currentQuoteAddress: Address;
  isDetailedShippingModal: boolean;
}) => {
  const { currentQuoteAddress, isDetailedShippingModal } = data;
  const [{ currentScreen, selectedAddress, isDeleteModalOpen, previousSteps }, dispatch] =
    useReducer(reducer, {
      currentScreen: getInitialScreen(isDetailedShippingModal, currentQuoteAddress),
      previousSteps: [],
      selectedAddress: {
        ...currentQuoteAddress,
        type: currentQuoteAddress?.companyAddress
          ? AddressTypesEnum.COMPANY
          : AddressTypesEnum.QUOTE,
      },
      isDeleteModalOpen: false,
    });

  return {
    currentScreen,
    selectedAddress,
    isDeleteModalOpen,
    previousSteps,
    dispatch,
  };
};
