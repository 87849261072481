import React, { PropsWithChildren } from 'react';
import {
  ThemeProvider as PaperThemeProvider,
  StyledEngineProvider,
  getTheme,
} from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers';

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { brand } = useAppConfiguration();
  const theme = getTheme(brand);

  return (
    <PaperThemeProvider loadFonts theme={theme}>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </PaperThemeProvider>
  );
};
