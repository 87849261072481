import React, { FC } from 'react';
import { AlertSnackbar, Button, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export interface UserAlertSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  onActionClick?: () => void;
}

export const UserAlertSnackbar: FC<UserAlertSnackbarProps> = ({
  open,
  onClose,
  message,
  onActionClick,
}) => {
  const { t } = useTranslation();

  return (
    <AlertSnackbar
      data-testid="user-snackbar-alert"
      open={open}
      onClose={onClose}
      message={
        <Typography
          data-testid="user-snackbar-alert-message"
          sx={{ color: theme => theme.palette.secondary.dark }}
        >
          {message}
        </Typography>
      }
      action={
        onActionClick ? (
          <Button
            data-testid="user-snackbar-alert-configure-button"
            sx={{ color: theme => theme.palette.secondary.dark }}
            onClick={onActionClick}
          >
            {t('common.configure')}
          </Button>
        ) : null
      }
    />
  );
};
