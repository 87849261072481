import React, { useRef } from 'react';
import { AttachFileIcon, UploadButton } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { uniqueId } from 'lodash';
import { uploadXml as uploadXmlAction } from 'libs/api/quotes/actions';
import { useTranslation } from 'react-i18next';
import { useXmlImport } from '../hooks';

export const QuoteXmlImportButton = () => {
  const { t } = useTranslation();

  const loadingMessage = t('quotes.uploadXmlLoadingMessage');
  const { mutate: uploadXml, isLoading: uploading } = useXmlImport({
    action: async ({ file }) =>
      await uploadXmlAction({
        file: {
          file,
          id: uniqueId(),
        },
      }),
    loadingMessage,
  });

  const uploadInputRef = useRef<Nullable<HTMLInputElement>>(null);

  return (
    <UploadButton
      ref={uploadInputRef}
      type="button"
      inputProps={{
        accept: 'xml',
        onChange: uploadXml,
      }}
      buttonProps={{
        disabled: uploading,
        'data-testid': 'xml-upload-button',
        variant: 'outlined',
        color: 'secondary',
        startIcon: <AttachFileIcon />,
      }}
    >
      {t('quotes.uploadXml')}
    </UploadButton>
  );
};
