import React, { useEffect, useState } from 'react';
import { InputAdornment, SearchIcon, TextField, useTranslation } from '@clippings/paper';
import { getCleanQueryParamValue } from 'libs/shared';
import { usePlpSearchQueryParam } from '../../hooks';
import { useSearchBox } from 'react-instantsearch-hooks-web';

export function SearchField() {
  const { t } = useTranslation();
  const { refine } = useSearchBox();
  const [plpQueryParam, setPlpQueryParam] = usePlpSearchQueryParam();

  const [search, setSearch] = useState(plpQueryParam);

  useEffect(() => {
    if (plpQueryParam) {
      refine(plpQueryParam);
    }
  }, []);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
    setPlpQueryParam(getCleanQueryParamValue(value));
    refine(value ?? '');
  };

  return (
    <TextField
      value={search}
      sx={{
        px: 4,
        maxWidth: 688,
        width: '100%',
      }}
      inputProps={{
        sx: {
          py: 1,
        },
        'data-testid': 'plp-search-input',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      placeholder={t('plp.productSearchPlaceholder')}
      onChange={onInputChange}
    />
  );
}
