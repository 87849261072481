import React from 'react';
import { BillingDialog } from './QuoteAddresses/Billing/BillingDialog';
import { ShippingDialog } from './QuoteAddresses/Shipping/ShippingDialog';
import { useQuoteProvider } from './providers';

type QuoteModalsProps = { handleShowSnackbar: any };

export function QuoteModals({ handleShowSnackbar }: QuoteModalsProps) {
  const { isShippingModalOpen, isBillingModalOpen } = useQuoteProvider();

  return (
    <>
      {isShippingModalOpen && <ShippingDialog handleShowSnackbar={handleShowSnackbar} />}
      {isBillingModalOpen && <BillingDialog />}
    </>
  );
}
