import { ListUsersParams } from 'libs/api/iam/types';
import { SingleTeamUser, flattenSalesRepTeams, getAllStaffMembers } from '../quoteUtils';
import { StaffUserPermission } from 'libs/SalesApp/Users/utils/user.utils';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export const useQuoteSalesRepsQueryKey = 'quoteSalesReps';

/**
 * Returns all staff users except ADMIN and ADAPTER
 */
export const useQuoteSalesReps = (): [() => void, UseQueryResult<SingleTeamUser[], unknown>] => {
  const [shouldFetch, setShouldFetch] = useState(false);

  // fetch users
  const listUserParams: ListUsersParams = {
    permissions: StaffUserPermission.IS_STAFF,
  };

  const staffMembersQuery = useQuery(
    [useQuoteSalesRepsQueryKey, listUserParams],
    () => getAllStaffMembers(listUserParams),
    {
      enabled: shouldFetch,
      select: data => data.flatMap(flattenSalesRepTeams),
    }
  );

  return [() => setShouldFetch(true), staffMembersQuery];
};
