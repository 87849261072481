import React from 'react';
import { DateTime, DateTimeFormat, Grid, Monetary, Typography } from '@clippings/paper';
import { ListTable, ListTableProps } from '../../Components/ListTable/ListTable';
import { ListTableBody } from '../../Components/ListTable/ListTableBody';
import { ListTableHeader } from 'libs/Components/ListTable/ListTableHeader';
import { ListTableHeaderCell } from '../../Components/ListTable/ListTableHeaderCell';
import { ListTableRow } from '../../Components/ListTable/ListTableRow';
import { OrderItem } from 'libs/api/order/types';
import { OrderStatusChip } from '../components/OrderStatusChip';
import { Link as RouterLink } from 'react-router-dom';
import { getOrderIdLabel } from '../utils';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useTranslation } from 'react-i18next';

interface OrderListTableProps {
  orders: OrderItem[];
  isLoading: ListTableProps['isLoading'];
  onFetchNextPage: ListTableProps['onFetchNextPage'];
  hasNextPage: ListTableProps['hasNextPage'];
  isFetchingNextPage: ListTableProps['isFetchingNextPage'];
}

export const OrderListTable: React.FC<OrderListTableProps> = ({
  orders,
  isFetchingNextPage,
  hasNextPage,
  isLoading,
  onFetchNextPage,
}) => {
  const { t } = useTranslation();
  const { getOrderShowPath } = useOrderPaths();

  const header = (
    <ListTableHeader columns={12} data-testid="sa-orders-table-header">
      {[
        t('orders.orderNumber'),
        t('orders.orderPlaced'),
        t('common.value'),
        t('common.status'),
      ].map(header => (
        <ListTableHeaderCell
          key={header}
          label={header}
          columns={3}
          data-testid="staff-users-list-heading-name"
        />
      ))}
    </ListTableHeader>
  );

  const renderItem = (order: OrderItem) => (
    <ListTableRow
      key={order.id}
      component={RouterLink}
      to={getOrderShowPath(order.number)}
      role="button"
      data-testid={`order-item-row-${order.number}`}
      columns={12}
    >
      <Grid item xs={3} pl={2}>
        <Typography
          data-testid={`order-item-number-${order.number}`}
          variant="body2"
          fontWeight="fontWeightMedium"
        >
          {getOrderIdLabel(order.vendorId)}
        </Typography>
        <Typography
          variant="caption"
          color="secondary"
          component="div"
          data-testid={`order-item-label-${order.number}`}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {order.label}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <DateTime
          format={DateTimeFormat.Date}
          date={order.createdAt}
          data-testid={`order-item-created-${order.number}`}
          variant="body2"
          fontWeight="fontWeightMedium"
        />
      </Grid>
      <Grid item xs={3}>
        <Monetary
          variant="body2"
          fontWeight="fontWeightMedium"
          amount={order.total.amount}
          currency={order.total.currency}
          data-testid={`order-item-value-${order.number}`}
        />
      </Grid>
      <Grid item xs={3}>
        <OrderStatusChip data-testid={`order-item-status-${order.number}`} status={order.status} />
      </Grid>
    </ListTableRow>
  );

  return (
    <ListTable
      rowHeight={66}
      isLoading={isLoading}
      onFetchNextPage={onFetchNextPage}
      hasNextPage={hasNextPage}
      noMoreLabel={t('orders.noMoreOrders')}
      isFetchingNextPage={isFetchingNextPage}
      data-testid="orders-list-table"
    >
      {header}
      <ListTableBody>{orders.map(renderItem)}</ListTableBody>
    </ListTable>
  );
};
