import React, { useMemo, useState } from 'react';
import { AlertSnackbarProps, Typography } from '@clippings/paper';

export const useAlertSnackbar = (options?: { onClose?: () => void }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<AlertSnackbarProps['severity']>('success');

  const handleShowSnackbar = (message: string, alertSeverity?: AlertSnackbarProps['severity']) => {
    setOpen(true);
    setMessage(message);
    setSeverity(alertSeverity ?? 'success');
  };
  const onClose = () => {
    setOpen(false);
    options?.onClose?.();
  };

  const MessageComponent = useMemo(
    () => <Typography sx={{ color: theme => theme.palette.secondary.dark }}>{message}</Typography>,
    [message]
  );

  const props: AlertSnackbarProps = {
    message: MessageComponent,
    onClose,
    open,
    severity,
  };

  return {
    props,
    handleShowSnackbar,
  };
};
