export const bankTransferData = [
  {
    title: 'checkout.bankTransfer.accountHolder',
    info: 'checkout.instanceBankInfo.accountHolder',
  },
  {
    title: 'checkout.bankTransfer.accountNumber',
    info: 'checkout.instanceBankInfo.accountNumber',
  },
  { title: 'checkout.bankTransfer.bankName', info: 'checkout.instanceBankInfo.bankName' },
  {
    title: 'checkout.bankTransfer.bankAddress',
    info: 'checkout.instanceBankInfo.bankAddress',
  },
  {
    title: 'checkout.bankTransfer.reference',
    info: 'checkout.instanceBankInfo.reference',
  },
  { title: 'checkout.bankTransfer.sortCode', info: 'checkout.instanceBankInfo.sortCode' },
] as const;
