import React, { FC } from 'react';
import { Box, Button, Tooltip } from '@clippings/paper';
import { QuoteTransitionErrorsEnum } from 'libs/SalesApp/Quotes/types';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useQuoteTransitionAction } from 'libs/shared/hooks/useQuoteTransitionAction';
import { useRequestQuote } from 'libs/shared/hooks/useRequestQuote';
import { useTranslation } from 'react-i18next';

export type QuoteApproveButtonProps = {
  onRequest: () => void;
};

const SPECIAL_ERRORS = [
  QuoteTransitionErrorsEnum.MissingShippingAddress,
  QuoteTransitionErrorsEnum.MissingBillingAddress,
];

export const QuoteApproveButton: FC<QuoteApproveButtonProps> = () => {
  const { t } = useTranslation();
  const { quote } = useQuoteProvider();
  const { errors, shouldDisableTransitionButton, buildBlockingErrorMessages } =
    useQuoteTransitionAction(quote, 'approve', SPECIAL_ERRORS);
  const onRequest = useRequestQuote();

  return (
    <Tooltip
      title={buildBlockingErrorMessages()}
      placement="top"
      data-testid="approve-button-tooltip"
      disableHoverListener={!shouldDisableTransitionButton}
    >
      <Box>
        <Button
          variant="contained"
          data-testid="approve-and-send"
          onClick={() => onRequest(errors)}
          disabled={shouldDisableTransitionButton}
        >
          {t('common.approveAndSend')}
        </Button>
      </Box>
    </Tooltip>
  );
};
