import React, { FC } from 'react';

import { AlertSnackbar, Button, Typography } from '@clippings/paper';
import { QuoteListItem } from 'libs/api/quotes/types';
import { QuoteMutation, QuotesPage } from '../types';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface QuoteSnackbarProps {
  open: boolean;
  onClose: () => void;
  details?: {
    mutation: QuoteMutation;
    page: QuotesPage;
    quote: QuoteListItem;
  };
}

const ACTION_TRANSLATIONS_MAP: Record<
  QuoteMutation,
  `common.quote${`${Capitalize<QuoteMutation>}`}d`
> = {
  archive: 'common.quoteArchived',
  duplicate: 'common.quoteDuplicated',
  restore: 'common.quoteRestored',
  update: 'common.quoteUpdated',
};

export const QuoteSnackbar: FC<QuoteSnackbarProps> = ({ details, open, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!details) {
    return null;
  }

  const { quote, mutation, page } = details;
  const actionTranslation = t('common.quoteActionSuccess', {
    action: t(ACTION_TRANSLATIONS_MAP[mutation]),
  });

  const getSectionTranslation = () => {
    let targetSection: QuotesPage = QuotesPage.Active;

    if (mutation === QuoteMutation.Archive && page !== QuotesPage.Archived) {
      targetSection = QuotesPage.Archived;
    }

    return t('common.checkQuoteSection', {
      section: t('quotes.quotesTableTitle', {
        type: t(`common.${targetSection}`),
      }),
    });
  };

  const onViewClick = () => navigate(path(routes.salesApp.quotes.show, { number: quote.number }));

  return (
    <AlertSnackbar
      onClose={onClose}
      data-testid="quotes-action-snackbar"
      open={open}
      message={
        <>
          <Typography
            sx={{ color: theme => theme.palette.secondary.dark }}
            data-testid="quotes-action-snackbar-title"
            fontWeight="fontWeightMedium"
            variant="subtitle2"
          >
            {actionTranslation}
          </Typography>
          &nbsp;
          <Typography
            sx={{ color: theme => theme.palette.secondary.dark }}
            data-testid="quotes-action-snackbar-subtitle"
            variant="body2"
          >
            {getSectionTranslation()}
          </Typography>
        </>
      }
      action={
        <Button
          data-testid="quotes-action-snackbar-button-view"
          onClick={onViewClick}
          variant="text"
        >
          <Typography
            sx={{ color: theme => theme.palette.secondary.dark }}
            fontSize={theme => theme.typography.pxToRem(13)}
            variant="subtitle2"
          >
            {t('common.viewQuote')}
          </Typography>
        </Button>
      }
    />
  );
};
