import React from 'react';
import { Box } from '@clippings/paper';
import { LanguageSelect } from 'libs/Components/LanguageSelect/LanguageSelect';
import { useLanguageChange } from 'libs/shared';

export const HeaderNavigation: React.FC = () => {
  const { handleLanguageSelect, languageSelectAvailable } = useLanguageChange();

  return (
    <Box
      display="flex"
      gap={1}
      sx={theme => ({
        [theme.breakpoints.down('md')]: {
          '#account-popover .MuiPaper-root, #cart-popover .MuiPaper-root': {
            left: '0 !important',
            top: 'var(--app-header-height) !important',
            width: '100% !important',
            maxWidth: 'unset !important',
            borderRadius: '0 !important',
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14) !important',

            '> div': {
              width: '100% !important',
            },
          },
          '[data-open="true"]': {
            color: 'primary.main',
          },
        },
      })}
    >
      {languageSelectAvailable && <LanguageSelect onLanguageSelected={handleLanguageSelect} />}
    </Box>
  );
};
