import React, { FC } from 'react';
import { CatalogsProvider } from 'libs/catalogs';
import { ChatChannelProvider } from '../../Chat';
import { FooterSticky } from 'libs/shared';
import { OrderExportMenu } from '../export';
import { OrderView } from './OrderView';
import { useOrder } from 'libs/api/order/hooks';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export type OrderUseParamProps = {
  number: string;
};

export const OrderDetailsPage: FC = () => {
  const { t } = useTranslation();
  const { number } = useParams() as OrderUseParamProps;
  const orderQuery = useOrder(number);

  useTitle(`${t('common.Order')} ${orderQuery.data?.label || ''} #${number}`);

  return (
    <CatalogsProvider>
      <ChatChannelProvider number={number}>
        <OrderView number={number} orderQuery={orderQuery} />
        <FooterSticky
          leftContent={<OrderExportMenu orderId={number} disabled={orderQuery.isFetching} />}
        />
      </ChatChannelProvider>
    </CatalogsProvider>
  );
};
