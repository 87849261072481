import { PaymentIntent, Stripe, StripeElements } from '@stripe/stripe-js';

export interface PaymentIntentCreated {
  intentObject: PaymentIntent;
}

export enum PaymentMethod {
  Stripe = 'stripe_intents',
  BankTransfer = 'bank_transfer',
}

export interface StripeComponents {
  elements: StripeElements | null;
  stripe: Stripe | null;
  referenceId?: string | null;
}
