import React, { FC, useMemo } from 'react';
import { BaseUser } from 'libs/api/common/types';
import { Box, useTranslation } from '@clippings/paper';
import { DividerGrey } from 'libs/shared';
import { QUOTES_PAGE_STATUS_MAP, QuotesFilters, QuotesPage } from 'libs/SalesApp/Quotes/types';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { SingleTeamUser } from 'libs/SalesApp/Quotes/quoteUtils';
import { StatusChipFilter } from './components/StatusChipFilter';
import { User } from 'libs/api/iam/types';
import { UserChipFilter } from './components/UserChipFilter';
import { notEmpty } from 'libs/shared/utils';
import './FilterStrip.scss';

export interface FilterStripProps {
  page: QuotesPage;
  staffMembers: User[];
  filters: QuotesFilters;
  setFilters: (filters: QuotesFilters) => void;
}

export const FilterStrip: FC<FilterStripProps> = ({ page, staffMembers, filters, setFilters }) => {
  const { t } = useTranslation();
  const pageStatusOptions = QUOTES_PAGE_STATUS_MAP[page];

  const assigneeFilterValues = filters.assignee ?? [];
  const teamsOfUserFilterValues = filters.teamsOfUser ?? [];
  const statusFilterValues = filters.status ?? [];

  const onAssigneeSelect = (assignee?: SingleTeamUser) =>
    setFilters({ assignee: assignee ? [assignee.id] : [] });
  const onTeamsOfUserSelect = (user?: SingleTeamUser) =>
    setFilters({ teamsOfUser: user ? [user.id] : [] });
  const onStatusSelect = (status?: QuoteListItemStatus) =>
    setFilters({ status: status ? [status] : [] });

  const selectedMembers: BaseUser[] = useMemo(() => {
    const filterValues = assigneeFilterValues.filter(notEmpty);
    const members: BaseUser[] = [];

    filterValues.forEach(filterValue => {
      const member = staffMembers.find(x => x.id === filterValue);
      if (member) {
        members.push(member);
      }
    });

    return members;
  }, [staffMembers, assigneeFilterValues]);

  const selectedTeamsOfUser: BaseUser[] = useMemo(() => {
    const filterValues = teamsOfUserFilterValues.filter(notEmpty);
    const members: BaseUser[] = [];

    filterValues.forEach(filterValue => {
      const member = staffMembers.find(x => x.id === filterValue);
      if (member) {
        members.push(member);
      }
    });

    return members;
  }, [staffMembers, teamsOfUserFilterValues]);

  return (
    <>
      <DividerGrey />
      <Box
        data-testid="sales-quotes-filter-strip"
        display="flex"
        justifyContent="flex-end"
        className="filter-strip"
        gap={0.5}
        width="100%"
        py={1}
      >
        <UserChipFilter
          label={t('common.userTeams')}
          data-testid="teamsOfUser-chip-filter"
          selected={selectedTeamsOfUser}
          onSelect={onTeamsOfUserSelect}
          options={staffMembers}
        />
        <UserChipFilter
          data-testid="assignee-chip-filter"
          selected={selectedMembers}
          onSelect={onAssigneeSelect}
          options={staffMembers}
        />
        {pageStatusOptions.length > 1 && (
          <StatusChipFilter
            selected={statusFilterValues}
            onSelect={onStatusSelect}
            options={pageStatusOptions}
          />
        )}
      </Box>
      <DividerGrey sx={{ mb: 1 }} />
    </>
  );
};
