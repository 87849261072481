import React from 'react';
import { InfoCellSampleItem } from 'libs/SalesApp/Quotes/quoteTable/components/tableCells/infoTableCell/InfoCellSampleItem';
import { ProductVariationName } from '../ProductVariationName';
import { QuoteItemViewModel } from './quoteItemCard.types';
import { buildVariationName } from 'libs/shared/quote.utils';
import { useItemFormattedProperties } from 'libs/shared/hooks';

// TODO: refactor - components in Quotes should not depend on SalesApp lib

type QuoteItemInfoRendererProps = {
  item: QuoteItemViewModel;
};

export function QuoteItemInfoRenderer({ item }: QuoteItemInfoRendererProps) {
  const { properties, FormattedPropertiesRenderer } = useItemFormattedProperties(
    item.properties ?? []
  );

  if (item.sample) {
    const { properties, productName, variationName } = item;
    const sampleName = buildVariationName({ properties, productName, variationName });

    return <InfoCellSampleItem sampleName={sampleName} />;
  }

  if (item.custom) {
    return (
      <ProductVariationName
        id={item.id}
        productName={item.productName}
        properties={item.properties}
        variationName={item.variationName}
      />
    );
  }

  return <FormattedPropertiesRenderer properties={properties} textOnly />;
}
