import React from 'react';
import { Breadcrumbs, Link, useTranslation } from '@clippings/paper';
import { Link as RouterLink } from 'react-router-dom';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';

type OrderDetailsBreadcrumbProps = {
  orderNumber: string;
  isLoading: boolean;
  orderIdLabel: string;
};

export function OrderDetailsBreadcrumb({
  orderNumber,
  isLoading,
  orderIdLabel,
}: OrderDetailsBreadcrumbProps) {
  const { t } = useTranslation();
  const { getOrderShowPath, getOrdersListPath } = useOrderPaths();

  return (
    <Breadcrumbs>
      <Link
        underline="none"
        variant="body2"
        to={getOrdersListPath(orderNumber)}
        component={RouterLink}
        color="text.secondary"
      >
        {t('common.orders')}
      </Link>
      <Link
        underline="none"
        variant="body2"
        to={getOrderShowPath(orderNumber)}
        component={RouterLink}
        color="text.primary"
      >
        {t('orders.orderNumber')}
        {isLoading ? null : orderIdLabel}
      </Link>
    </Breadcrumbs>
  );
}
