import DOMPurify from 'dompurify';
import React, { FC } from 'react';
import { Box, Typography } from '@clippings/paper';
import { Modal } from 'libs/Components';
import { ModalProps } from 'libs/Components/Modal/Modal';
import { useTranslation } from 'react-i18next';

export const TermsConditionsModal: FC<
  Omit<ModalProps, 'okLabel' | 'cancelLabel' | 'title' | 'actionButtons'>
> = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Modal
      sx={{ maxHeight: '700px' }}
      okLabel={t('common.iAgree')}
      cancelLabel={t('common.close')}
      title={t('common.termsAndConditions')}
      data-testid="terms-conditions-modal"
      aria-labelledby="terms-conditions-dialog-title"
      actionButtons="default"
      onClose={rest.onClose!}
      onOk={rest.onOk!}
      {...rest}
    >
      <Box sx={{ height: 'auto', overflow: 'auto' }}>
        <Typography
          variant="body1"
          id="terms-conditions-dialog-content"
          className="tc-dialog__content"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t('checkout.termsConditions'), {
              USE_PROFILES: { html: true },
            }),
          }}
        />
      </Box>
    </Modal>
  );
};
