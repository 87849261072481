import React from 'react';
import {
  CloseableDialog,
  DataTestId,
  DialogAction,
  DialogProps,
  SystemUser,
} from '@clippings/paper';
import { STATUS_ACTIVE } from 'libs/SalesApp/Companies/components/CompanyFormFields';
import { User } from 'libs/api/iam/types';
import { UserCompanyDialogTitle } from './UserCompanyDialogTitle';
import {
  UserDialogFormProps,
  UserDialogScreen,
  getUserFormValues,
  userDialogSettingsMap,
} from '../../utils/user.utils';
import { useCreateUserCompany } from '../../providers/CreateUserCompanyProvider';

export type UserCompanyDialogProps = Omit<UserDialogFormProps, 'onCancel' | 'isEditMode'> & {
  isOpen: boolean;
  onClose: () => void;
  user?: User;
  type: SystemUser;
  action: DialogAction;
};

export const UserCompanyDialog: React.FC<UserCompanyDialogProps> = ({
  isOpen,
  onClose,
  action,
  onCreateSuccess,
  onUpdateSuccess,
  type,
  user,
  defaultValues,
  userId,
  hiddenFields,
  disabledFields,
}) => {
  const {
    companySearchValue,
    onUserDialogClose,
    onCreateNewCompanySuccess,
    currentScreen,
    onCompanyScreenReturn,
  } = useCreateUserCompany();

  const onDialogClose = () => {
    onUserDialogClose!();
    onClose();
  };

  const onUserCreateSuccess: UserCompanyDialogProps['onCreateSuccess'] = (user, ...rest) => {
    onUserDialogClose!();
    onCreateSuccess?.(user, ...rest);
  };

  // Form component
  const userDialogFormProps = {
    defaultValues: {
      ...getUserFormValues(type, user),
      ...defaultValues,
    },
    userId: user?.id ?? userId,
    action,
    onCreateSuccess: onUserCreateSuccess,
    onUpdateSuccess,
    onCancel: onDialogClose,
    hiddenFields,
    disabledFields,
  };

  const isCreateCompanyScreen = currentScreen === UserDialogScreen.CreateCompany;
  const UserForm = userDialogSettingsMap[type].form;
  const CompanyForm = userDialogSettingsMap.company.form;

  return (
    <CloseableDialog
      open={isOpen}
      onClose={onDialogClose}
      title={
        <UserCompanyDialogTitle
          userType={type}
          currentScreen={currentScreen}
          onCompanyScreenReturn={onCompanyScreenReturn}
        />
      }
      dialogProps={dialogProps}
    >
      {isCreateCompanyScreen ? (
        <CompanyForm
          onCancel={onClose}
          onSuccess={onCreateNewCompanySuccess!}
          defaultValues={{ name: companySearchValue!, status: STATUS_ACTIVE }}
        />
      ) : (
        <UserForm {...userDialogFormProps} />
      )}
    </CloseableDialog>
  );
};

const dialogProps: Partial<DialogProps> & DataTestId = {
  fullWidth: true,
  maxWidth: 'sm',
};
