import React, { ComponentProps, FC } from 'react';
import { LayoutContainer } from 'libs/Quotes/Components/Layout/LayoutContainer';
import { LayoutContent } from 'libs/Quotes/Components/Layout/LayoutContent';
import { LayoutHeader } from 'libs/Quotes/Components/Layout/LayoutHeader';
import { LayoutSideNav } from 'libs/Quotes/Components/Layout/LayoutSideNav';
import { Outlet } from 'react-router-dom';

type DefaultLayoutProps = {
  header?: JSX.Element;
  contentProps?: ComponentProps<typeof LayoutContent>;
};

// hideSideNav could be used only if navigation is provided
type DefaultLayoutNoNavigationProps = {
  navigation?: undefined;
  hideSideNav?: never;
};

type DefaultLayoutWithNavigationProps = {
  navigation: JSX.Element;
  hideSideNav?: boolean;
};

export const DefaultLayout: FC<
  DefaultLayoutProps & (DefaultLayoutNoNavigationProps | DefaultLayoutWithNavigationProps)
> = ({ hideSideNav = false, navigation, header, contentProps }) => (
  <>
    {header ? <LayoutHeader>{header}</LayoutHeader> : null}
    <LayoutContainer className={!header ? 'no-header' : ''}>
      {!hideSideNav && navigation ? <LayoutSideNav>{navigation}</LayoutSideNav> : null}
      <LayoutContent {...contentProps}>
        <Outlet />
      </LayoutContent>
    </LayoutContainer>
  </>
);
