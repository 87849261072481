import React from 'react';
import { AuthenticationImage } from './AuthenticationImage';
import {
  Avatar,
  Box,
  ChatBubbleIcon,
  LabelIcon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  PersonAddIcon,
  ReceiptIcon,
  ShoppingBagIcon,
  Typography,
} from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export const PromotionPanel = ({ ...rest }) => {
  const { t } = useTranslation();
  const entries = [
    {
      Icon: ShoppingBagIcon,
      title: 'common.register.promotionalLine1',
      description: 'common.register.promotionalLine1Description',
    },
    {
      Icon: ReceiptIcon,
      title: 'common.register.promotionalLine2',
      description: 'common.register.promotionalLine2Description',
    },
    {
      Icon: LabelIcon,
      title: 'common.register.promotionalLine3',
      description: 'common.register.promotionalLine3Description',
    },
    {
      Icon: PersonAddIcon,
      title: 'common.register.promotionalLine4',
      description: 'common.register.promotionalLine4Description',
    },
    {
      Icon: ChatBubbleIcon,
      title: 'common.register.promotionalLine5',
      description: 'common.register.promotionalLine5Description',
    },
  ] as const;

  return (
    <Box position="relative" height="100%" display="flex" justifyContent="center" {...rest}>
      <AuthenticationImage />
      <Box
        sx={theme => ({
          backgroundColor: `${theme.palette.common.black}6`,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backdropFilter: 'blur(5px)',
        })}
      />
      <Box maxWidth="560px" px={6} py={18} position="absolute" top={12}>
        <Typography pb={6} variant="h3" color="common.white">
          {t('common.register.promotionalHeader')}
        </Typography>
        <List>
          {entries.map(entry => (
            <ListItem key={entry.title}>
              <ListItemAvatar>
                <Avatar
                  sx={theme => ({
                    backgroundColor: `${theme.palette.common.black}6`,
                  })}
                >
                  <entry.Icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t(entry.title)}
                secondary={t(entry.description)}
                primaryTypographyProps={{ variant: 'h6', color: 'common.white' }}
                secondaryTypographyProps={{ color: 'common.white' }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};
