import { bannerActions } from '../../actions/bannerActions';

const initialState = {
  isOpen: false,
  type: 'success',
  message: '',
};

function actionReducer(
  state = initialState,
  action: { type?: keyof typeof bannerActions; payload?: object }
) {
  switch (action.type) {
    case 'BANNER_SHOW':
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case 'BANNER_HIDE':
      return {
        ...state,
        ...action.payload,
        isOpen: false,
      };
    default:
      return state;
  }
}

export default actionReducer;
