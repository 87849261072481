import React from 'react';
import { Box, Button, Chip } from '@clippings/paper';
import { translateCategoryLabel } from '../../utils';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch-hooks-web';
import { useTranslation } from 'react-i18next';

export interface FilterControlsProps {
  appliedPriceFilter: string | null;
  appliedLeadTimeFilter: string | null;
  onPriceFilterReset: () => void;
  onLeadTimeFilterReset: () => void;
}

export const FilterControls: React.FC<FilterControlsProps> = ({
  appliedPriceFilter,
  appliedLeadTimeFilter,
  onPriceFilterReset,
  onLeadTimeFilterReset,
}) => {
  const { t } = useTranslation();
  const { items } = useCurrentRefinements();
  const { refine: clearRefinements } = useClearRefinements();

  const clearAllFilters = () => {
    clearRefinements();
    onPriceFilterReset();
    onLeadTimeFilterReset();
  };

  return (
    <Box flexGrow={1} display="flex" flexWrap="wrap">
      {items
        .filter(item => item.attribute === 'category')
        .map(item =>
          item.refinements.map(refinement => (
            <Chip
              sx={{
                mr: 1,
                mt: 2,
              }}
              key={refinement.value}
              label={translateCategoryLabel(refinement.label, t)}
              onDelete={() => item.refine(refinement)}
              data-testid={`category-chip-${refinement.value}`}
            />
          ))
        )}
      {appliedPriceFilter && (
        <Chip
          sx={{
            mr: 1,
            mt: 2,
          }}
          key="price"
          label={appliedPriceFilter}
          onDelete={onPriceFilterReset}
          data-testid="price-chip"
        />
      )}
      {appliedLeadTimeFilter && (
        <Chip
          sx={{
            mr: 1,
            mt: 2,
          }}
          key="lead-time"
          label={appliedLeadTimeFilter}
          onDelete={onLeadTimeFilterReset}
          data-testid="lead-time-chip"
        />
      )}
      {(items.filter(item => item.attribute === 'category').length > 0 ||
        appliedPriceFilter ||
        appliedLeadTimeFilter) && (
        <Button
          sx={{
            height: 32,
            mr: 1,
            mt: 2,
          }}
          variant="text"
          color="secondary"
          onClick={clearAllFilters}
          data-testid="clear-all-button"
        >
          {t('common.clearAll')}
        </Button>
      )}
    </Box>
  );
};
