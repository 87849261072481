import React, { useImperativeHandle, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  reactnumberformat,
  useFormatCurrencySymbol,
  useFormatPrice,
} from '@clippings/paper';
import { DEBOUNCE_TIMINGS } from 'libs/Constants';
import { FilterSection } from './FilterSection';
import { debounce } from 'lodash';
import { useAppConfiguration } from '../../../../../providers';
import { useClearRefinements, useRange } from 'react-instantsearch-hooks-web';
import { useGetCatalogue } from 'libs/api/catalogue/hooks';
import { useTranslation } from 'react-i18next';

const { NumericFormat } = reactnumberformat;

export interface PriceFilterRef {
  reset: () => void;
}

export interface PriceFilterProps {
  catalogueSlug: string;
  onApply: (filter: string | null) => void;
}

export const PriceFilter = React.forwardRef<PriceFilterRef, PriceFilterProps>(
  ({ catalogueSlug, onApply }, ref) => {
    const { t } = useTranslation();
    const { refine } = useRange({
      attribute: `prices.${catalogueSlug}.amount`,
      min: 0,
      max: Number.MAX_SAFE_INTEGER,
    });
    const { refine: clearRefinements } = useClearRefinements({
      includedAttributes: [`prices.${catalogueSlug}.amount`],
    });
    const [selectedRadio, setSelectedRadio] = useState<string | null>(null);
    const [customMin, setCustomMin] = useState(0);
    const [customMax, setCustomMax] = useState(0);
    const { data: catalogue } = useGetCatalogue(catalogueSlug);
    const formatPrice = useFormatPrice();
    const formatCurrencySymbol = useFormatCurrencySymbol();
    const { priceFilterValues } = useAppConfiguration();

    useImperativeHandle(ref, () => ({
      reset: () => {
        setSelectedRadio(null);
        setCustomMin(0);
        setCustomMax(0);
        clearRefinements();
        onApply(null);
      },
    }));

    const formatCataloguePrice = (value: number) =>
      catalogue ? formatPrice(value, catalogue.currency, 0) : '';

    const formatPriceRange = (min: number, max: number, custom?: boolean) => {
      if (!min && max) {
        return `${custom ? t('common.upTo') : t('common.under')} ${formatCataloguePrice(max)}`;
      }

      if (min && !max) {
        return `${t('common.above')} ${formatCataloguePrice(min)}`;
      }

      return `${formatCataloguePrice(min)} - ${formatCataloguePrice(max)}`;
    };

    const handleRadioChange = (value: string) => {
      setSelectedRadio(value);

      if (value && value !== 'custom') {
        const [min, max] = value.split('-');

        setCustomMin(0);
        setCustomMax(0);
        refine([Number(min) || undefined, Number(max) || undefined]);
        onApply(formatPriceRange(Number(min), Number(max)));
      } else if (value === 'custom') {
        clearRefinements();
        onApply(null);
      }
    };

    const handleCustomMinChange = debounce((value: number) => {
      setCustomMin(value);

      if (!value || !customMax || value <= customMax) {
        refine([value || undefined, customMax || undefined]);

        if (value || customMax) {
          onApply(formatPriceRange(value, customMax, true));
        } else {
          onApply(null);
        }
      }
    }, DEBOUNCE_TIMINGS.INPUT_DEBOUNCE);

    const handleCustomMaxChange = debounce((value: number) => {
      setCustomMax(value);

      if (!value || !customMin || value >= customMin) {
        refine([customMin || undefined, value || undefined]);

        if (value || customMin) {
          onApply(formatPriceRange(customMin, value, true));
        } else {
          onApply(null);
        }
      }
    }, DEBOUNCE_TIMINGS.INPUT_DEBOUNCE);

    return (
      <FilterSection label={t('common.price')}>
        <Box px={2} minHeight={200}>
          {catalogue && (
            <RadioGroup
              sx={{
                '& .MuiRadio-root': {
                  p: 1,
                },
                '& span.MuiFormControlLabel-label': {
                  fontSize: theme => theme.typography.pxToRem(14),
                  fontWeight: 'regular',
                },
              }}
              value={selectedRadio}
              onChange={(_event, value) => handleRadioChange(value)}
            >
              {priceFilterValues.map(option => (
                <FormControlLabel
                  key={`${option[0]}-${option[1]}`}
                  value={`${option[0]}-${option[1]}`}
                  label={formatPriceRange(option[0], option[1])}
                  control={<Radio />}
                  data-testid={`price-option-${option[0]}-${option[1]}`}
                />
              ))}
              <FormControlLabel
                value="custom"
                label={t('common.custom')}
                control={<Radio />}
                data-testid="price-option-custom"
              />
            </RadioGroup>
          )}
          {selectedRadio === 'custom' && (
            <Box px={3} pb={2} display="flex" justifyContent="space-between" alignItems="center">
              <NumericFormat
                customInput={TextField}
                sx={{
                  width: 160,
                }}
                inputProps={{
                  sx: {
                    py: 1,
                  },
                  'data-testid': 'price-input-min',
                }}
                placeholder={t('common.low')}
                value={customMin || null}
                onValueChange={values => handleCustomMinChange(values.floatValue || 0)}
                isAllowed={values => values.floatValue !== 0}
                allowNegative={false}
                decimalScale={0}
                thousandSeparator=","
                prefix={catalogue ? formatCurrencySymbol(catalogue.currency) : ''}
              />
              <Typography px={1}>{t('common.to')}</Typography>
              <NumericFormat
                customInput={TextField}
                sx={{
                  width: 160,
                }}
                inputProps={{
                  sx: {
                    py: 1,
                  },
                  'data-testid': 'price-input-max',
                }}
                placeholder={t('common.high')}
                value={customMax || null}
                onValueChange={values => handleCustomMaxChange(values.floatValue || 0)}
                isAllowed={values => values.floatValue !== 0}
                allowNegative={false}
                decimalScale={0}
                thousandSeparator=","
                prefix={catalogue ? formatCurrencySymbol(catalogue.currency) : ''}
              />
            </Box>
          )}
        </Box>
      </FilterSection>
    );
  }
);
