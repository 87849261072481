import React, { FC, useMemo, useState } from 'react';
import { BaseUser } from 'libs/api/common/types';
import { ChipFilterProps } from '../types';
import {
  ChipMenuFilter,
  ChipMenuFilterProps,
  DataTestId,
  InputAdornment,
  SearchIcon,
  TextField,
  UserAvatar,
  getUserFullName,
} from '@clippings/paper';
import { Readonly } from 'libs/shared';
import { SingleTeamUser } from 'libs/SalesApp/Quotes/quoteUtils';
import { User } from 'libs/api/iam/types';
import { UserOption } from './UserOption';
import { preventDefaultPropagation } from 'libs/shared/utils';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

export interface UserFilterProps
  extends ChipFilterProps<SingleTeamUser, BaseUser>,
    Readonly,
    DataTestId {
  onClick?: () => void;
  label?: string;
}

export const UserChipFilter: FC<UserFilterProps> = ({
  options,
  onSelect,
  selected,
  readonly = false,
  onClick,
  label,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const currentUser = useCurrentUser()?.data;
  const { t } = useTranslation();

  const headerProps: ChipMenuFilterProps['header'] = useMemo(
    () => ({
      component: (
        <TextField
          disabled={readonly}
          value={searchValue}
          onClick={e => e.stopPropagation()}
          data-testid="user-filter-search"
          sx={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}
          size="small"
          placeholder={t('common.quickFind')}
          type="search"
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
    }),
    [t, searchValue]
  );

  const filtered = useMemo(() => {
    if (searchValue) {
      return options.filter(
        assignee =>
          getUserFullName(assignee)
            .replace(/\s/g, '')
            .toLowerCase()
            .indexOf(searchValue.toLowerCase().replace(/\s/g, '')) > -1
      );
    }

    return options;
  }, [options, searchValue, currentUser]);

  const getChipValue = () => {
    const { length } = selected;

    if (length === 0) return label ?? t('common.assignee');

    if (length > 1) {
      return t('quotes.peopleSelected', {
        count: length,
      });
    } else {
      const assignee = selected[0];

      return currentUser?.id === assignee.id
        ? t('quotes.assignedToYou')
        : getUserFullName(assignee);
    }
  };

  const getAdornmentComponent = () => {
    if (!selected.length) return null;
    const user = selected[0];

    return <UserAvatar user={user} small />;
  };

  const onFilterToggle = () => {
    setOpen(!open);
  };
  const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    preventDefaultPropagation(e);
    onFilterToggle();
    onClick?.();
  };

  const chipProps: ChipMenuFilterProps['chipProps'] = {
    onClick: handleClickEvent,
    menuProps: {
      open,
      onClick: preventDefaultPropagation,
    },
    disabled: readonly,
    adornment: getAdornmentComponent(),
    isOpen: open,
    value: getChipValue(),
    onToggle: onFilterToggle,
    hasArrow: !readonly && !!options.length,
    'data-testid': rest?.['data-testid'] ?? 'user-chip-filter',
  };
  const containerProps: ChipMenuFilterProps['containerProps'] = {
    onClick: preventDefaultPropagation,
    sx: {
      width: 360,
    },
  };
  const contentContainerProps: ChipMenuFilterProps['contentContainerProps'] = {
    sx: {
      maxHeight: 350,
      overflow: 'auto',
    },
    'data-testid': 'user-chip-filter-content',
  };

  const setSelection = (option?: User) => {
    onFilterToggle();
    onSelect(option);
  };

  const onAssigneeSelect = (singleTeamUser: SingleTeamUser) =>
    setSelection({
      ...singleTeamUser,
      teams: singleTeamUser.team ? [singleTeamUser.team] : [],
    });

  return (
    <ChipMenuFilter
      header={headerProps}
      chipProps={chipProps}
      {...rest}
      containerProps={containerProps}
      contentContainerProps={contentContainerProps}
      clearProps={{
        label: t('common.clear'),
        buttonProps: {
          onClick: () => setSelection(),
        },
      }}
    >
      {filtered.map((assignee, i) => (
        <UserOption
          key={`user-filter-option-${assignee.id}-${assignee.team?.id ?? i}`}
          user={assignee}
          selected={!!selected.find(x => x === assignee)}
          onSelect={onAssigneeSelect}
          index={i}
        />
      ))}
    </ChipMenuFilter>
  );
};
