import React from 'react';
import { IAM_QUERY_KEYS, useUpdateTeam } from 'libs/api/iam/hooks';
import { Team, TeamFormVariables, TeamUpdateVariables, TeamV2 } from 'libs/api/iam/types';
import { TeamForm } from './TeamForm';
import { TeamFormBaseProps } from './teamForms.types';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export type TeamEditFormProps = TeamFormBaseProps & {
  team: TeamV2;
  onUpdateSuccess: (team: Team) => void;
  onUpdateError: (error: any, team: TeamUpdateVariables) => void;
};

export const TeamEditForm: React.FC<TeamEditFormProps> = ({
  onUpdateSuccess,
  onUpdateError,
  onCancel,
  defaultValues,
  team,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, error, isLoading } = useUpdateTeam({
    onSuccess: data => {
      onUpdateSuccess(data);
      queryClient.invalidateQueries([IAM_QUERY_KEYS.teams]);
      queryClient.invalidateQueries([IAM_QUERY_KEYS.infiniteTeamsV2]);
    },
    onError: (error, variables) => {
      onUpdateError(error, variables);
    },
  });

  const handleFormSubmit = (formData: TeamFormVariables) => {
    return mutate({
      teamId: team!.id,
      team: { ...team, name: formData.name, pdfTemplateId: formData.pdfTemplateId },
    });
  };

  return (
    <TeamForm
      buttonText={t('common.update')}
      loading={isLoading}
      mutationErrors={error}
      defaultValues={defaultValues}
      handleFormSubmit={handleFormSubmit}
      onCancel={onCancel}
    />
  );
};
