import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@clippings/paper';
import { useTranslation } from 'react-i18next';

interface ApprovedUserDialogProps {
  fullUserName: string;
  companyName: string;
  open: boolean;
  onClose: () => void;
  onSkip: () => void;
  onSetupCommercialTerms: () => void;
}
export const ApprovedUserDialog: React.FC<ApprovedUserDialogProps> = ({
  fullUserName,
  companyName,
  open = false,
  onClose,
  onSkip,
  onSetupCommercialTerms,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('users.userAndCompanyCreated')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('users.successfullyApprovedUserWithNewCompany', {
            userName: fullUserName,
            companyName: companyName,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSkip} data-testid="skip-button">
          {t('common.skip')}
        </Button>
        <Button
          onClick={onSetupCommercialTerms}
          data-testid="setup-terms-button"
          variant="contained"
        >
          {t('users.setupCommercialTerms')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
