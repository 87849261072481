import React, { ComponentPropsWithRef } from 'react';
import { Link } from 'react-router-dom';
import { ListItemIcon, MenuItem, PictureAsPdfIcon } from '@clippings/paper';

type PdfLinkMenuItemProps = {
  to: ComponentPropsWithRef<typeof Link>['to'];
  label: string;
  onClick: () => void;
};

export function PdfLinkMenuItem({ to, label, onClick }: PdfLinkMenuItemProps) {
  return (
    <MenuItem
      onClick={onClick}
      component={Link}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="download-button-pdf"
      to={to}
    >
      <ListItemIcon key="list-pdf-icon">
        <PictureAsPdfIcon key="pdf-icon" />
      </ListItemIcon>
      {label}
    </MenuItem>
  );
}
