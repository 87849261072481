import { ACTIONS, CheckoutActions } from './types';
import { Brand } from '@clippings/paper';
import { CheckoutData, SalesOrderDocument, SignatureData } from 'libs/api/order/types';
import { CheckoutState } from '../checkout.types';
import { Nullable } from 'libs/Utils';
import { Payment } from 'libs/api/common/types';
import { PaymentMethod } from 'libs/api/payment/types';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { createOrder } from 'libs/api/order/actions';
import { getInitialState, reducer } from './reducer';
import { preparePayment } from 'libs/api/payment/actions';
import { purchaseOrderDocumentConfig } from '../config/config';
import { useAppConfiguration } from 'libs/providers';
import { useMutation } from '@tanstack/react-query';
import { useReducer } from 'react';
import { useStripePayment } from 'libs/api/payment/hooks';

export const useCheckout = (
  payment: Payment,
  quoteId: number
): { state: CheckoutState; actions: CheckoutActions; loading: boolean } => {
  const { instance, paymentMethods } = useAppConfiguration();
  const { populateStripeComponents, stripePaymentMutation, stripeComponents } = useStripePayment();

  const hasPaymentAmount = payment?.amount.amount > 0;

  const [state, dispatch] = useReducer(reducer, {
    ...getInitialState(paymentMethods),
    isPoDocumentRequired:
      purchaseOrderDocumentConfig[instance as Brand & 'core'] === 'purchaseOrderDocumentRequired',
    immediatePayment: !!payment,
    hasPaymentAmount,
  });

  const acceptTermsAndCondition = () => dispatch({ type: ACTIONS.ACCEPT_TERMS_AND_CONDITIONS });
  const errorPayment = (error: string) => {
    dispatch({ type: ACTIONS.ERROR_PAYMENT, payload: error });
  };
  const onBackStep = () => dispatch({ type: ACTIONS.BACK_STEP });
  const onCompleteStep = () => dispatch({ type: ACTIONS.COMPLETE_STEP });
  const onPaymentTypeSelect = (paymentMethod: PaymentMethod) =>
    dispatch({ type: ACTIONS.SET_PAYMENT_METHOD, payload: paymentMethod });
  const onSignPayClick = () => dispatch({ type: ACTIONS.SIGN });
  const setCardComplete = (complete: boolean) =>
    dispatch({ type: ACTIONS.SET_CARD_COMPLETE, payload: complete });
  const setData = (data: Partial<CheckoutData>) => {
    dispatch({ type: ACTIONS.SET_DATA, payload: data });
  };
  const setHasPONumber = (has: boolean) => {
    dispatch({ type: ACTIONS.SET_HAS_PO_NUMBER, payload: has });
  };
  const submitSignature = (data: SignatureData) => {
    dispatch({ type: ACTIONS.SUBMIT_SIGNATURE, payload: data });
  };
  const successfulBankTransfer = () => {
    dispatch({ type: ACTIONS.SUCCESSFUL_BANK_TRANSFER });
  };
  const successfulPayment = () => {
    dispatch({ type: ACTIONS.SUCCESSFUL_PAYMENT });
  };
  const toggleSignatureModal = () =>
    dispatch({ type: ACTIONS.SET_SIGNATURE_MODAL, payload: !state.signatureModal });
  const toggleTermsAndCondition = () =>
    dispatch({
      type: ACTIONS.SET_DATA,
      payload: { termsAndConditionsAccepted: !state.data.termsAndConditionsAccepted },
    });
  const toggleTermsAndConditionModal = () =>
    dispatch({
      type: ACTIONS.SET_TERMS_AND_CONDITIONS_MODAL,
      payload: !state.termsAndConditionModal,
    });

  const setIsUploadingPODocument = (uploading: boolean) =>
    dispatch({
      type: ACTIONS.SET_IS_UPLOADING_PO_DOCUMENT,
      payload: uploading,
    });

  const setPODocument = (poDocument: Nullable<SalesOrderDocument>) =>
    dispatch({
      type: ACTIONS.SET_PO_DOCUMENT,
      payload: poDocument,
    });
  const handleCardFormEvents = (event: StripePaymentElementChangeEvent): void => {
    setCardComplete(event.complete);
  };

  const finalizePayment = (): void => {
    if (!state.immediatePayment) {
      successfulPayment();
      return;
    }

    if (state.paymentMethod === PaymentMethod.BankTransfer) {
      successfulBankTransfer();
    }

    if (state.paymentMethod === PaymentMethod.Stripe) {
      handleStripePayment();
    }
  };

  const preparePaymentMutation = useMutation(
    ({
      paymentId,
      paymentMethod,
      referenceId,
    }: {
      paymentId: string;
      paymentMethod: PaymentMethod;
      referenceId?: string;
    }) => preparePayment(paymentId, paymentMethod, referenceId),
    {
      onSuccess: () => finalizePayment(),
    }
  );

  const createOrderMutation = useMutation(
    (data: CheckoutData) =>
      createOrder({
        ...data,
        referenceId:
          PaymentMethod.Stripe === state.paymentMethod
            ? stripeComponents?.referenceId ?? undefined
            : undefined,
        paymentId: payment?.id,
        paymentMethod: payment?.id ? state.paymentMethod : undefined,
        quoteVersionId: quoteId,
      }),
    {
      onSuccess: () => finalizePayment(),
    }
  );

  const handleStripePayment = () => {
    stripePaymentMutation.mutate(undefined, {
      onError: error => errorPayment(error.message),
      onSuccess: () => successfulPayment(),
    });
  };

  const completePayment = (): void => {
    preparePaymentMutation.mutate({
      paymentId: payment!.id,
      paymentMethod: state.paymentMethod,
      referenceId:
        state.paymentMethod === PaymentMethod.Stripe
          ? stripeComponents?.referenceId ?? undefined
          : undefined,
    });
  };

  const handleSignatureSubmit = (formValues: SignatureData) => {
    submitSignature(formValues);

    createOrderMutation.mutate({ ...state.data, ...formValues });
  };

  return {
    actions: {
      acceptTermsAndCondition,
      onBackStep,
      onCompleteStep,
      onPaymentTypeSelect,
      onSignPayClick,
      populateStripeComponents,
      handleCardFormEvents,
      handleSignatureSubmit,
      completePayment,
      setData,
      setHasPONumber,
      toggleSignatureModal,
      toggleTermsAndCondition,
      toggleTermsAndConditionModal,
      setIsUploadingPODocument,
      setPODocument,
    },
    state,
    loading:
      createOrderMutation.isLoading ||
      stripePaymentMutation.isLoading ||
      preparePaymentMutation.isLoading,
  };
};
