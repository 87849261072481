import React, { FC } from 'react';
import { CombinedEmptyTablePlaceholder } from 'libs/Components';
import { EmptyTablePlaceholderProps } from 'libs/Components/EmptyTablePlaceholder/EmptyTablePlaceholder';
import { useTranslation } from 'react-i18next';

export interface EmptyUserTablePlaceholderProps {
  filtered: boolean;
  onReset: () => void;
  onCreate: () => void;
  isUserAdmin?: boolean;
  type: 'staff' | 'customer';
}
export const EmptyUserTablePlaceholder: FC<EmptyUserTablePlaceholderProps> = ({
  filtered,
  onReset,
  onCreate,
  isUserAdmin,
  type,
}) => {
  const { t } = useTranslation();
  const renderButtonCreate =
    !filtered && (type === 'customer' || (type === 'staff' && isUserAdmin));

  const resetPlaceholderProps: EmptyTablePlaceholderProps = {
    onButtonClick: onReset,
    title: t('common.noResults'),
    buttonTitle: t('common.reset'),
    containerProps: { 'data-testid': 'empty-table-placeholder-no-users-reset' },
  };
  const createPlaceholderProps: EmptyTablePlaceholderProps = {
    onButtonClick: onCreate,
    title: type === 'customer' ? t('users.noAddedUsers') : t('users.noAddedStaffUsers'),
    buttonTitle: renderButtonCreate ? t('forms.createUser') : undefined,
    containerProps: { 'data-testid': 'empty-table-placeholder-title' },
  };

  return (
    <CombinedEmptyTablePlaceholder
      filtered={filtered}
      resetPlaceholderProps={resetPlaceholderProps}
      createPlaceholderProps={createPlaceholderProps}
    />
  );
};
