import { Quote, QuoteVersion } from 'libs/api/quotes/types';
import { QuoteUpdateOptions } from '../quote.types';
import {
  archiveQuote,
  deleteQuoteVersion,
  duplicateQuote,
  getQuoteVersion,
  queryKeys,
  requestQuoteApproval,
  unarchiveQuote,
  updateQuoteVersion as updateQuoteVersionAction,
  updateQuoteVersionNonContractual,
} from '../quoteActions';
import { restoreQuoteVersion } from 'libs/api/quotes/actions';
import { useDefaultQuoteMutations } from './useDefaultQuoteMutations';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuoteItemHandlers } from './usQuoteItemHandlers';

export type NonContractualMutationType = 'position' | 'default';

export const useQuoteHandlers = (
  quote: Quote,
  queryKey: string[],
  setError?: (err: unknown) => void
) => {
  const queryClient = useQueryClient();
  const defaultMutationHandlers = useDefaultQuoteMutations(queryKey, setError);

  const updateMutation = useMutation(
    (data: Partial<Quote>) => updateQuoteVersionAction(quote.versionId, data),
    { ...defaultMutationHandlers, mutationKey: queryKey }
  );

  const updateNonContractualMutation = useMutation(
    (data: Partial<Quote>) => updateQuoteVersionNonContractual(quote.versionId, data),
    { ...defaultMutationHandlers, mutationKey: queryKey }
  );

  const mutationHandlersWithoutOptimisticUpdate = {
    ...defaultMutationHandlers,
    onMutate: async () => {
      const previousQuote = queryClient.getQueryData(queryKey);
      await queryClient.cancelQueries(queryKey);
      return { previousQuote };
    },
  };
  const updateMutationWithoutOptimisticUpdate = useMutation(
    (data: Partial<Quote>) => updateQuoteVersionAction(quote.versionId, data),
    mutationHandlersWithoutOptimisticUpdate
  );

  const updateAddressMutation = useMutation((data: Partial<Quote>) => {
    /**
     * The `versionId` passed through the `data` is used when updating quote level addresses.
     * For company level addresses the `data.versionId` property would be undefined and the
     * `quote.versionId` value would be used instead
     */
    return updateQuoteVersionAction(data.versionId ?? quote.versionId, data);
  }, mutationHandlersWithoutOptimisticUpdate);

  const tableBlockingMutation = useMutation(
    (data: Partial<Quote>) => updateQuoteVersionAction(quote.versionId, data),
    defaultMutationHandlers
  );

  const quoteVersionMutation = useMutation(({ id }: QuoteVersion) => getQuoteVersion(id), {
    onMutate: () => ({ previousQuote: queryClient.getQueryData(queryKey) }),
    onError: defaultMutationHandlers.onError,
    onSuccess: defaultMutationHandlers.onSuccess,
  });

  const handleQuoteAddressChange = (
    data: Partial<Quote>,
    onSuccess?: (data: Quote) => void,
    onError = setError
  ) => {
    updateAddressMutation.mutate(data, {
      onSuccess,
      onError,
    });
  };

  const handleQuoteUpdate = (
    data: Partial<Quote>,
    options: QuoteUpdateOptions = { withOptimisticUpdate: true, nonContractual: false }
  ) => {
    const { withOptimisticUpdate, nonContractual } = options;

    if (withOptimisticUpdate) {
      nonContractual ? updateNonContractualMutation.mutate(data) : updateMutation.mutate(data);
      return;
    }

    updateMutationWithoutOptimisticUpdate.mutate(data);
  };

  const handleChangeQuoteVersion = (data: QuoteVersion) => {
    quoteVersionMutation.mutate(data);
  };

  const unarchiveQuoteVersionMutation = useMutation(
    (quote: Quote) => unarchiveQuote(quote.id),
    defaultMutationHandlers
  );
  const handleUnarchiveQuoteVersion = async () => {
    unarchiveQuoteVersionMutation.mutate(quote);
  };

  const archiveQuoteMutation = useMutation(
    (quote: Quote) => archiveQuote(quote.id),
    defaultMutationHandlers
  );

  const restoreQuoteVersionMutation = useMutation(
    (quote: Quote) => restoreQuoteVersion(quote.id),
    defaultMutationHandlers
  );

  const handleArchiveQuote = async () => {
    archiveQuoteMutation.mutate(quote);
  };

  const handleRestoreQuoteVersion = async () => {
    restoreQuoteVersionMutation.mutate(quote);
  };

  const duplicateQuoteVersionMutation = useMutation(duplicateQuote, {
    onError: setError,
  });

  const handleDuplicateQuote = async (onSuccess: (props: { number: string }) => void) => {
    duplicateQuoteVersionMutation.mutate(quote.id, { onSuccess });
  };

  const deleteQuoteVersionMutation = useMutation((quoteVersion: number) =>
    deleteQuoteVersion(quoteVersion)
  );

  const handleDeleteQuoteVersion = () =>
    deleteQuoteVersionMutation.mutate(quote.id, {
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: ['quote', quote.number] });
      },
    });

  const requestQuoteApprovalMutation = useMutation(quote => requestQuoteApproval(quote.id!), {
    ...defaultMutationHandlers,
    onSuccess: (data, ...rest) => {
      const { versionId } = data;

      /**
       * Invalidate addresses state.
       * This is needed because a new addresses instances are created with different `id`.
       */
      queryClient.invalidateQueries(queryKeys.getBillingAddress(versionId, data.customerCompanyId));
      queryClient.invalidateQueries(queryKeys.getQuoteBillingAddresses(versionId));
      queryClient.invalidateQueries(
        queryKeys.getShippingAddress(versionId, data.customerCompanyId)
      );
      queryClient.invalidateQueries(queryKeys.getQuoteShippingAddresses(versionId));

      defaultMutationHandlers.onSuccess(data, ...rest);
    },
  });

  const handleRequestQuoteApproval = () => {
    requestQuoteApprovalMutation.mutate(quote);
  };

  const {
    handleQuoteItemQuantityUpdate,
    handleQuoteItemDeletion,
    handleQuoteItemUpdateByKey,
    handleMakeCustom,
    updateProductItemNonContractualDataHandler,
    handleQuoteItemUpdateDiscount,
    itemLoading,
  } = useQuoteItemHandlers(quote, queryKey, setError);

  const loading =
    archiveQuoteMutation.isLoading ||
    quoteVersionMutation.isLoading ||
    unarchiveQuoteVersionMutation.isLoading ||
    deleteQuoteVersionMutation.isLoading ||
    requestQuoteApprovalMutation.isLoading ||
    restoreQuoteVersionMutation.isLoading;

  return {
    handleQuoteAddressChange,
    handleQuoteUpdate,
    handleQuoteItemQuantityUpdate,
    handleQuoteItemDeletion,
    handleUnarchiveQuoteVersion,
    handleArchiveQuote,
    handleDuplicateQuote,
    handleDeleteQuoteVersion,
    handleChangeQuoteVersion,
    handleRequestQuoteApproval,
    handleQuoteItemUpdateByKey,
    handleMakeCustom,
    handleRestoreQuoteVersion,
    updateProductItemNonContractualDataHandler,
    handleQuoteItemUpdateDiscount,
    loading,
    updateAddressMutationLoading: updateAddressMutation.isLoading,
    duplicationLoading: duplicateQuoteVersionMutation.isLoading,
    tableLoading: tableBlockingMutation.isLoading || itemLoading,
    optimisticLoading: updateMutation.isLoading,
    updateWithoutOptimisticLoading: updateMutationWithoutOptimisticUpdate.isLoading,
  };
};
