import { AppConfiguration } from './types';
import { Brand } from '@clippings/paper';
import { axios } from 'libs/Utils';

export const apiPaths = {
  configuration: '/configuration.json',
};

export const getAppConfiguration = () =>
  axios.get<AppConfiguration>(apiPaths.configuration).then(response => ({
    ...response.data,
    brand: response.data.instance === 'core' ? null : (response.data.instance as Brand),
  }));
