import {
  BaseUser,
  BillingAddress,
  LeadTime,
  ListQueryParams,
  Money,
  Payment,
  Picture,
  SalesOrderDiscount,
  ShippingAddress,
} from 'libs/api/common/types';
import { ContractFields, CustomDeliveryDateModel, QuoteItemProperty } from '../quotes/types';
import { IntendedUseOptionsType } from 'libs/shared/hooks/useIntendedUse';
import { Nullable } from 'libs/Utils';
import { PaymentMethod } from 'libs/api/payment/types';

export enum OrderStatus {
  Ordered = 'ordered',
  PartConfirmed = 'part_confirmed',
  Confirmed = 'confirmed',
  PartShipped = 'part_shipped',
  Shipped = 'shipped',
  PartDelivered = 'part_delivered',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  InProduction = 'in_production',
  Paid = 'paid',
  Received = 'received',
  Released = 'released',
  ReadyToBeShipped = 'ready_to_be_shipped',
}

type EstimatedDeliveryDate = {
  from: string;
  to: string;
};

export interface OrderItem {
  id: number;
  number: string;
  status: OrderStatus;
  projectName?: string;
  label?: string;
  total: Money;
  createdAt: string;
  customer: BaseUser;
  salesRepresentative?: BaseUser;
  vendorId: Nullable<string>;
}

export interface OrderStatusEntry {
  status: OrderStatus;
  isPassed: boolean;
  date?: string;
}

export interface BaseOrderItem {
  id: number;
  catalogueBasePrice: Money;
  netPrice: Money;
}

export interface OrderProductItem extends BaseOrderItem, ContractFields {
  position: number;
  quantity: number;
  subtotal: Money;
  discountTitle: string;
  sku: string | null;
  vendorSku: string | null;
  productId: string | null;
  productName: string | null;
  variationName: string;
  leadTime: LeadTime | null;
  picture: Picture | null;
  discountPercent: number;
  properties: QuoteItemProperty[] | null;
  taxPercent: number;
  custom: boolean;
  note: Nullable<string>;
  sample: boolean;
}

export type OrderDeliveryItem = {
  id: number;
  catalogueBasePrice: Money;
  netPrice: Money;
  name: string;
};

export type OrderMetadata = {
  /**
   * Example: '2021-09-05'
   */
  staffNeedByDate?: string;
  /**
   * Example: '2021-09-05'
   */
  staffShipByDate?: string;
  customDeliveryDate?: CustomDeliveryDateModel;
  intendedUse?: IntendedUseOptionsType;
};

export interface Order extends OrderItem {
  statuses: OrderStatusEntry[];
  poNumber?: string;
  shippingAddress: ShippingAddress;
  billingAddress: BillingAddress;
  deliveryItems: OrderDeliveryItem[];
  paymentSchedule: Payment[];
  productItems: OrderProductItem[];
  subtotal: Money;
  tax: Money;
  discountItems: SalesOrderDiscount[];
  estimatedDeliveryDate?: EstimatedDeliveryDate;
  metadata: OrderMetadata;
}

export enum SalesOrderDocumentType {
  INVOICE = 'invoice',
  PURCHASE_ORDER = 'purchase_order',
}

export interface SalesOrderDocument {
  type: SalesOrderDocumentType.PURCHASE_ORDER;
  fileName: string;
  downloadUrl: string;
}

export type Document = {
  id: number;
  createdAt: string;
  type: 'invoice';
  downloadUrl: string;
  name?: string;
  fileName: string;
};

export interface SignatureData {
  fullName: string;
  email: string;
  jobPosition: string;
}

export interface CheckoutData extends SignatureData {
  poNumber?: string;
  termsAndConditionsAccepted?: boolean;
  poDocument?: Nullable<SalesOrderDocument>;
}

export interface CreateOrder extends CheckoutData {
  paymentId?: string;
  paymentMethod?: PaymentMethod;
  quoteVersionId: number | string;
  referenceId: string | undefined;
}

export interface ListOrdersParams extends ListQueryParams {
  /** Ability to filter by Company ID */
  companyId?: string[];
  /** Ability to filter by status */
  status?: OrderStatus[];
  teamsOfUser?: number;
}

export interface SalesOrderPatchDto {
  salesRepresentativeId?: number;
}

export interface UpdateSalesOrderVariables {
  orderNumber: string;
  order: SalesOrderPatchDto;
}
