import React, { FC } from 'react';
import { Quote, QuoteDocument } from 'libs/api/quotes/types';
import { UploadedFile, UploadedFileProps, useCloudinaryFileDownload } from '@clippings/paper';
import { useDeleteQuoteDocument } from 'libs/api/quotes/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useQuoteQueryKey } from 'libs/Quotes/hooks';

export type QuoteUploadedFileProps = UploadedFileProps & {
  quoteNumber: string;
  document: QuoteDocument;
};

export const QuoteUploadedFile: FC<QuoteUploadedFileProps> = ({
  quoteNumber,
  document,
  onDownloadError,
  onDownloadSuccess,
  onDeleteSuccess,
  onDeleteError,
  ...rest
}) => {
  const { remoteId, version, fileName, fileSize } = document;
  const queryClient = useQueryClient();
  const quoteQueryKey = useQuoteQueryKey(quoteNumber);
  const { isDownloading, downloadAsync } = useCloudinaryFileDownload(
    onDownloadSuccess,
    onDownloadError
  );

  const { mutate, isLoading: isBeingDeleted } = useDeleteQuoteDocument(quoteNumber, {
    onSuccess: (_, { id }) => {
      onDeleteSuccess();

      const previousQuote = queryClient.getQueryData<Quote>(quoteQueryKey)!;
      queryClient.setQueryData<Partial<Quote>>(quoteQueryKey, old => ({
        ...old,
        documents: old!.documents!.filter(doc => doc.id !== id),
      }));

      return { previousQuote };
    },
    onError: () => {
      onDeleteError();
    },
  });

  const { containerProps } = rest;

  const onDownloadClick = () => {
    downloadAsync({ remoteId, version, fileName });
  };

  const onDeleteClick = () => {
    mutate({ id: document.id });
  };

  return (
    <UploadedFile
      existing
      data-testid={`uploaded-document-${document.id}`}
      fileSize={fileSize}
      fileName={document.fileName}
      progressProps={{ value: 100, sx: { display: 'none' } }}
      onRemove={onDeleteClick}
      loading={isDownloading || isBeingDeleted}
      {...rest}
      containerProps={{
        onClick: onDownloadClick,
        ...containerProps,
      }}
    />
  );
};
