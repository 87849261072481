import React from 'react';
import { Box, Divider, Grid } from '@clippings/paper';
import { QuoteInfoLabel } from './QuoteInfoLabel';
import { QuoteInfoSimpleItem } from './QuoteInfoSimpleItem';
import { useTranslation } from 'react-i18next';

export const ExtraPaymentInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1} data-testid="extra-payment-info">
        <QuoteInfoSimpleItem
          value={t('quotes.pdf.extraPaymentInfo.freightByOceanDescription')}
          label={t('quotes.pdf.extraPaymentInfo.freightByOcean')}
        />
        <QuoteInfoSimpleItem
          value={t('quotes.pdf.extraPaymentInfo.freightByAirDescription')}
          label={t('quotes.pdf.extraPaymentInfo.freightByAir')}
        />
      </Box>
      <Grid container border={1} borderColor="grey.A200" borderRadius={1}>
        <Grid item xs display="flex" flexDirection="column" gap={1}>
          <QuoteInfoLabel bold text={t('quotes.pdf.extraPaymentInfo.wirePayment')} />
          <Box display="flex" flexDirection="column">
            <QuoteInfoLabel text={t('quotes.pdf.extraPaymentInfo.bankName')} />
            <QuoteInfoLabel text={t('quotes.pdf.extraPaymentInfo.routingNumber')} />
            <QuoteInfoLabel text={t('quotes.pdf.extraPaymentInfo.accountNumber')} />
            <QuoteInfoLabel text={t('quotes.pdf.extraPaymentInfo.accountName')} />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs display="flex" flexDirection="column" gap={1}>
          <QuoteInfoLabel bold text={t('quotes.pdf.extraPaymentInfo.checkPayments')} />
          <Box display="flex" flexDirection="column">
            <QuoteInfoLabel text={t('quotes.pdf.extraPaymentInfo.checkBrandName')} />
            <QuoteInfoLabel text={t('quotes.pdf.extraPaymentInfo.attn')} />
            <QuoteInfoLabel text={t('quotes.pdf.extraPaymentInfo.checkAddress')} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
