import React, { FC } from 'react';
import { Link } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { routes } from 'libs/Constants';
import { useTranslation } from 'react-i18next';

export interface DuplicatedQuoteBannerProps {
  type: 'sales' | 'customer';
  number: Quote['number'];
}
export const DuplicatedQuoteBanner: FC<DuplicatedQuoteBannerProps> = ({ type, number }) => {
  const { t } = useTranslation();
  const route = type === 'customer' ? routes.quotes.show : routes.salesApp.quotes.show;

  return (
    <Link
      data-testid="duplicated-quote-link"
      component={RouterLink}
      underline="none"
      to={generatePath(route, { number: number })}
      sx={{ color: theme => theme.palette.secondary.dark }}
    >
      {t('quotes.duplicatedQuoteMessage')}
    </Link>
  );
};
