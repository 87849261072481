import {
  ACTIONS,
  ACTION_AFTER_SUBMIT,
  initialState,
  reducer as quoteReducer,
} from './quoteReducer';
import { useReducer } from 'react';

export const useQuote = () => {
  const [
    {
      isLabelModalOpen,
      isShippingModalOpen,
      isBillingModalOpen,
      isDetailedShippingModal,
      actionAfterSubmit,
    },
    dispatch,
  ] = useReducer(quoteReducer, initialState);

  const openLabelModal = () => {
    dispatch({ type: ACTIONS.OPEN_LABEL_MODAL });
  };

  const closeLabelModal = () => {
    dispatch({ type: ACTIONS.CLOSE_LABEL_MODAL });
  };

  const openShippingModal = (
    detailed = false,
    actionAfterSubmit: ACTION_AFTER_SUBMIT = ACTION_AFTER_SUBMIT.NONE
  ) => {
    const isDetailed = typeof detailed === 'boolean' && detailed;
    dispatch({
      type: ACTIONS.OPEN_SHIPPING_MODAL,
      payload: { isDetailedShippingModal: isDetailed, actionAfterSubmit },
    });
  };

  const closeShippingModal = () => {
    dispatch({ type: ACTIONS.CLOSE_SHIPPING_MODAL });
  };

  const openBillingModal = (actionAfterSubmit: ACTION_AFTER_SUBMIT = ACTION_AFTER_SUBMIT.NONE) => {
    dispatch({ type: ACTIONS.OPEN_BILLING_MODAL, payload: { actionAfterSubmit } });
  };

  const closeBillingModal = () => {
    dispatch({ type: ACTIONS.CLOSE_BILLING_MODAL });
  };

  return {
    isLabelModalOpen,
    openLabelModal,
    closeLabelModal,
    openShippingModal,
    closeShippingModal,
    isShippingModalOpen,
    openBillingModal,
    closeBillingModal,
    isBillingModalOpen,
    isDetailedShippingModal,
    actionAfterSubmit,
  };
};
