import React from 'react';
import { Box, Button, Image, MenuItem, Typography } from '@clippings/paper';
import { CustomItemHit } from '../common/types';
import { getProductListingImageUrl } from '../common/utils';
import { useTranslation } from 'react-i18next';

export interface CustomItemAutocompleteItemProps {
  hit: CustomItemHit;
  onAddItem: (description: string) => void;
  onClose: () => void;
}

export const CustomItemAutocompleteItem: React.FC<CustomItemAutocompleteItemProps> = ({
  hit,
  onAddItem,
  onClose,
}) => {
  const { t } = useTranslation();
  const { name, description } = hit;

  const addCustomItem = () => {
    onClose();
    onAddItem(description);
  };

  return (
    <MenuItem
      disableRipple
      sx={{
        cursor: 'initial',
        userSelect: 'text',
        display: 'flex',
        gap: '4px',
        '&:hover': {
          '.product-row__buttons': {
            visibility: 'visible',
          },
        },
      }}
      data-testid={`product-menu-custom-item-${name}`}
    >
      <Image
        sx={{ userSelect: 'none' }}
        src={getProductListingImageUrl(null, null)}
        height={48}
        width={48}
        alt={hit.name}
      />
      <Box display="flex" flexDirection="column" minWidth={0} flexGrow={1}>
        <Typography variant="subtitle2">{hit.name}</Typography>
      </Box>
      <Box
        className="product-row__buttons"
        display="flex"
        visibility="hidden"
        gap={0.5}
        height={36}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={addCustomItem}
          size="small"
          data-testid={`add-new-custom-item-${hit.name}`}
        >
          {t('common.add')}
        </Button>
      </Box>
    </MenuItem>
  );
};
