import React, { useEffect } from 'react';
import { Brand, FormTextField, MenuItem, useFormContext, useWatch } from '@clippings/paper';
import { departmentItems, priorityItems } from '../utils/constants';
import { isCountryIT } from 'libs/Utils/countries';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

const fieldsConfig: Record<string, Array<string>> = {
  [Brand.Fomcore]: [],
  [Brand.Sossego]: [],
  [Brand.Moroso]: [
    'pec',
    'sdi',
    'priority',
    'paymentMethod',
    'bankName',
    'bankIban',
    'generalNotes',
    'department',
  ],
};

interface MetadataFieldsProps {
  selectedCountry?: string;
}

/**
 * All of the fields here need explicit `required` props because their validation is conditional
 * See paper's FormTextField component for more info
 */
export const MetadataFields: React.FC<MetadataFieldsProps> = ({ selectedCountry }) => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();
  const { trigger } = useFormContext();
  const isItaly = selectedCountry ? isCountryIT(selectedCountry) : false;
  const pec = useWatch({ name: 'metadata.pec' });
  const sdi = useWatch({ name: 'metadata.sdi' });

  useEffect(() => {
    trigger('metadata.sdi');
  }, [pec]);

  useEffect(() => {
    trigger('metadata.pec');
  }, [sdi]);

  const fields: Record<string, JSX.Element | null> = {
    pec: isItaly ? (
      <FormTextField
        key="pec"
        name="metadata.pec"
        label={t('companies.pec')}
        required={!sdi}
        inputProps={{ 'data-testid': 'input-pec' }}
      />
    ) : null,
    sdi: isItaly ? (
      <FormTextField
        key="sdi"
        name="metadata.sdi"
        label={t('companies.sdiCode')}
        required={!pec}
        inputProps={{ 'data-testid': 'input-sdi' }}
      />
    ) : null,
    priority: (
      <FormTextField
        key="priority"
        select
        required
        name="metadata.priority"
        label={t('companies.priority')}
        inputProps={{ 'data-testid': 'input-priority' }}
      >
        {priorityItems.map(priority => (
          <MenuItem
            data-testid={`priority-item-${priority.value}`}
            key={priority.value}
            value={priority.value}
          >
            {t(priority.label)}
          </MenuItem>
        ))}
      </FormTextField>
    ),
    paymentMethod: (
      <FormTextField
        key="paymentMethod"
        name="metadata.paymentMethod"
        label={t('common.paymentMethod')}
        required
      />
    ),
    bankName: (
      <FormTextField
        key="bankName"
        name="metadata.bankName"
        label={t('companies.bankNameForDirectDebit')}
      />
    ),
    bankIban: (
      <FormTextField
        key="bankIban"
        name="metadata.bankIban"
        label={t('companies.bankIbanForDirectDebit')}
      />
    ),
    generalNotes: (
      <FormTextField
        multiline
        key="generalNotes"
        name="metadata.generalNotes"
        label={t('companies.generalNotes')}
      />
    ),
    department: (
      <FormTextField
        key="department"
        select
        name="metadata.department"
        label={t('companies.department')}
      >
        {departmentItems.map(department => (
          <MenuItem
            data-testid={`department-item-${department.value}`}
            key={department.value}
            value={department.value}
          >
            {t(department.label)}
          </MenuItem>
        ))}
      </FormTextField>
    ),
  };

  return <>{(brand ? fieldsConfig[brand] : []).map(field => fields[field])}</>;
};
