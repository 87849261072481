import axios, { AxiosResponse } from 'axios';
import { DashLocale, UnderscoreLocale, convertDashToUnderscore } from '@clippings/paper';
import { routes } from 'libs/Constants';

export function setupAxiosHeaders(userLocale: DashLocale | UnderscoreLocale) {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['X-DT-Language'] = convertDashToUnderscore(userLocale as any);
}

function getResponseHeader(this: any, key: string) {
  return this[key.toLowerCase()];
}

function processAjaxHeaders(response: AxiosResponse) {
  const fakeJqXHR: any = response.headers;
  fakeJqXHR.getResponseHeader = getResponseHeader;
}

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    processAjaxHeaders(response);
    return Promise.resolve(response);
  },
  error => {
    if (error && error.response) {
      processAjaxHeaders(error.response);
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  const isGuest = window.location.pathname.includes(routes.guestApp.index);
  if (!isGuest) {
    return config;
  }

  return { ...config, url: routes.guestApp.index + config.url };
});

export default axios;
