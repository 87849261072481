import React from 'react';
import { Box, Divider } from '@clippings/paper';
import { QuoteInfoLabel } from 'libs/Quotes/PdfExport/components/info';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const NoteSection = ({ divider }: { divider?: boolean }) => {
  const { quote } = useQuoteProvider();
  const { t } = useTranslation();

  if (!quote.externalNote) {
    //Return an empty box so that we preserve the page layout
    return <Box />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={1} mt={1}>
      {divider && <Divider />}
      <QuoteInfoLabel text={t('common.notes')} bold />
      <QuoteInfoLabel text={quote.externalNote} />
    </Box>
  );
};
