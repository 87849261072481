import React, { useEffect } from 'react';
import { ApplicableDeliveryMethod } from 'libs/api/quotes/types';
import {
  Box,
  Controller,
  MegaRadioItem,
  RadioGroup,
  Stack,
  useFormContext,
} from '@clippings/paper';
import { DeliveryMethodsListItem } from './DeliveryMethodsListItem';
import { useBanner } from '../../Components/AlertBanner';
import { useTranslation } from 'react-i18next';

export type DeliveryMethodsListProps = {
  deliveryMethods: ApplicableDeliveryMethod[];
  formDefaultValues: { deliveryRef: string };
};

export const DeliveryMethodsList: React.FC<DeliveryMethodsListProps> = ({
  deliveryMethods,
  formDefaultValues,
}) => {
  const { showErrorBanner } = useBanner();
  const { control, formState, reset } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      showErrorBanner(t('quotes.addresses.pleaseSelectMethod'));
    }
  }, [formState]);

  // reset the form values. Needed to update the correct value for the radio group
  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues.deliveryRef]);

  return (
    <Controller
      control={control}
      name="deliveryRef"
      render={({ field }) => (
        <RadioGroup
          {...field}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
        >
          <Box data-testid="delivery-methods-container">
            <Stack direction="column">
              {deliveryMethods.map(deliveryMethod => (
                <MegaRadioItem
                  value={deliveryMethod.id}
                  key={deliveryMethod.id}
                  name="delivery-method-radio"
                  data-testid={`delivery-method-radio-${deliveryMethod.id}`}
                >
                  <DeliveryMethodsListItem deliveryMethod={deliveryMethod} />
                </MegaRadioItem>
              ))}
            </Stack>
          </Box>
        </RadioGroup>
      )}
    />
  );
};
