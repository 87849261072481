import React, { ComponentProps, PropsWithChildren } from 'react';
import { Box, useIsMobile } from '@clippings/paper';

type ViewContainerProps = PropsWithChildren<ComponentProps<typeof Box>>;

export function ViewContainer({ children, ...props }: PropsWithChildren<ViewContainerProps>) {
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection={isMobile ? 'column' : 'row'}
      gap={2}
      {...props}
    >
      {children}
    </Box>
  );
}
