import React, { FC, useCallback } from 'react';
import { AddIcon, LoadingButton } from '@clippings/paper';
import { useCreateQuote } from '../hooks/useCreateQuote';
import { useTranslation } from 'react-i18next';

export const NewQuoteButton: FC = () => {
  const { t } = useTranslation();
  const [mutation, loading] = useCreateQuote();
  const handleCreateQuote = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  const btnLabel = t('common.newQuote');

  return (
    <LoadingButton
      loading={loading}
      data-testid="btn-new-quote"
      onClick={handleCreateQuote}
      startIcon={<AddIcon />}
      variant="contained"
      color="primary"
    >
      {btnLabel}
    </LoadingButton>
  );
};
