import React from 'react';
import { AddressListItem } from '../AddressListItem';
import { AddressTypesEnum } from '../../hooks/useAddresses';
import { BillingAddress } from 'libs/api/common/types';
import { Box, Checkbox } from '@clippings/paper';
import { useAddressContext } from 'libs/Quotes/QuoteAddresses/AddressContext';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const SameAsBilling = () => {
  const { handleBack } = useAddressContext();
  const { quote } = useQuoteProvider();
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        data-testid="same-as-billing-checkbox"
        onChange={handleBack}
        label={t('quotes.addresses.sameAsBilling')}
        checked
      />
      <Box
        padding={2}
        border="1px solid"
        borderColor="grey.600"
        borderRadius="8px"
        data-testid="same-as-billing-info"
      >
        <AddressListItem
          address={quote.billingAddress as BillingAddress}
          type={AddressTypesEnum.QUOTE}
          hasDelete={false}
          isEditable={false}
        />
      </Box>
    </>
  );
};
