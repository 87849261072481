import { Payment, PaymentStatus } from 'libs/api/common/types';
import { Quote } from 'libs/api/quotes/types';
import { getQuoteVersion } from '../quoteActions';
import { useParams } from 'react-router-dom';
import { usePaymentTermsQuery } from 'libs/api/quotes/hooks';
import { useQuery } from '@tanstack/react-query';

const useQuoteVersion = (id: string) => useQuery(['quote', id], () => getQuoteVersion(Number(id)));

const getImmediatePayablePayment = (paymentTerms: Payment[]): Payment | undefined =>
  paymentTerms.find(p => p.isImmediate && p.status === PaymentStatus.Pending);

interface QuoteNumberParams {
  number: string;
  id: string;
}

export const useQuoteCheckout = () => {
  const { number, id } = useParams<keyof QuoteNumberParams>() as QuoteNumberParams;

  const quoteVersionQuery = useQuoteVersion(id);
  const paymentTermsQuery = usePaymentTermsQuery(Number(id), {
    enabled: !!id,
  });

  const immediatePayment = getImmediatePayablePayment(paymentTermsQuery.data || []);

  return {
    quote: quoteVersionQuery.data as Quote,
    paymentTermsQuery,
    isLoading: quoteVersionQuery.isLoading || paymentTermsQuery.isLoading,
    immediatePayment,
    number,
  };
};
