import React from 'react';
import { DataTestId } from 'libs/shared/types';
import { Grid, GridProps } from '@clippings/paper';

export const ComponentGridContainer: React.FC<GridProps & DataTestId> = ({ children, ...rest }) => (
  <Grid
    container
    padding={3}
    gap={3}
    flexDirection="column"
    {...rest}
    sx={{
      backgroundColor: 'white',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'grey.A200',
      ...rest?.sx,
    }}
  >
    {children}
  </Grid>
);
