import React, { FC, PropsWithChildren } from 'react';
import { ALGOLIA_INITIAL_DISCOUNT_HITS_COUNT } from 'libs/shared';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { useSearchProvider } from 'libs/providers';

export const AddCustomAndPredefinedDiscount: FC<PropsWithChildren> = ({ children }) => {
  const { searchClient, discountIndex } = useSearchProvider();

  if (!discountIndex) {
    return null;
  }

  return (
    <InstantSearch searchClient={searchClient} indexName={discountIndex}>
      <Configure hitsPerPage={ALGOLIA_INITIAL_DISCOUNT_HITS_COUNT} />
      {children}
    </InstantSearch>
  );
};
