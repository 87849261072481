import React, { FC } from 'react';
import { Box, DateTimeFormat, Typography, useFormatDateTime } from '@clippings/paper';
import { Order } from 'libs/api/order/types';
import { useTranslation } from 'react-i18next';

export enum OrderStaffDeliveryDateType {
  ShipBy = 'shipBy',
  NeedBy = 'needBy',
}
export interface OrderStaffDeliveryDatesProps {
  metadata: Order['metadata'];
}
export const OrderStaffDeliveryDates: FC<OrderStaffDeliveryDatesProps> = ({ metadata }) => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const { staffShipByDate, staffNeedByDate } = metadata;

  const dates = [
    {
      date: staffShipByDate ? new Date(staffShipByDate) : undefined,
      type: OrderStaffDeliveryDateType.ShipBy,
      label: t(`common.${OrderStaffDeliveryDateType.ShipBy}`),
    },
    {
      date: staffNeedByDate ? new Date(staffNeedByDate) : undefined,
      type: OrderStaffDeliveryDateType.NeedBy,
      label: t(`common.${OrderStaffDeliveryDateType.NeedBy}`),
    },
  ].filter(x => !!x.date);

  return (
    <Box display="flex" flexDirection="column">
      {dates.map(({ date, label, type }) => {
        const key = `order-staff-delivery-date-${type}`;

        return (
          <Typography
            key={key}
            data-testid={key}
            variant="caption"
            fontWeight="bold"
            color="success.main"
          >
            {/* eslint-disable-next-line react/jsx-no-literals */}
            {`${label} `}
            <Typography
              data-testid="date-value"
              component="span"
              variant="caption"
              fontWeight="bold"
              color="success.main"
            >
              {/* eslint-disable-next-line react/jsx-no-literals */}
              {formatDateTime(date!, DateTimeFormat.Date)}
            </Typography>
          </Typography>
        );
      })}
    </Box>
  );
};
