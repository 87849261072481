import { Catalogue } from 'libs/api/iam/types';
import { Category } from 'libs/api/catalogue/types';
import { CompanyDiscount } from 'libs/api/common/types';
import { useFormatPercentage, useFormatPrice } from '@clippings/paper';
import { useMemo } from 'react';
import { useTranslatable } from 'libs/Utils/useTranslatable';
import { useTranslation } from 'react-i18next';

export type DiscountInfoViewModel = {
  id: string;
  label: string;
  value: string;
};

export function useDiscountsInfoList(
  discounts: CompanyDiscount[],
  categories: Category[],
  catalogues: Catalogue[]
) {
  const { t } = useTranslation();
  const getTranslation = useTranslatable();
  const formatPrice = useFormatPrice();
  const formatPercentage = useFormatPercentage();

  const getValue = (discount: CompanyDiscount, categories: Category[]): string => {
    const { type, percent: rawPercent, catalogueSlug: slug } = discount;
    const catalogue = (catalogues ?? []).find(element => element.slug === slug);
    const percent = formatPercentage(rawPercent!);

    switch (type) {
      case 'product': {
        const name = slug ? catalogue?.name ?? slug : t('companies.allCatalogues');
        const selectedCategories = discount.productCategories.map(id => {
          const category = categories.find((cat: Category) => cat.vendorId === id);

          if (!category) {
            return '';
          }

          return getTranslation(category.name);
        });

        const catalogueName =
          name + (selectedCategories.length > 0 ? ' (' + selectedCategories.join(', ') + ')' : '');

        return t('companies.productDiscountFormat', {
          percent: discount.title ?? percent,
          catalogue: catalogueName,
        });
      }
      case 'quote': {
        if (!catalogue) {
          return '';
        }

        const amountAbove = formatPrice(discount.amountAbove.amount, discount.amountAbove.currency);

        return t('companies.quoteDiscountFormat', { percent, amountAbove });
      }
      default:
        return '';
    }
  };

  const discountsListViewModel: DiscountInfoViewModel[] = useMemo(
    () =>
      (discounts ?? []).map(discount => ({
        id: discount.id!,
        label: t(`companies.${discount.type}DiscountLabel`),
        value: getValue(discount, categories ?? []),
      })),
    [discounts, categories]
  );

  return { discountsListViewModel };
}
