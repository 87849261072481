import React from 'react';
import { Button, DataTestId } from '@clippings/paper';

type AddNewEntityButtonProps = {
  text: string;
  onClick: () => void;
} & DataTestId;

export function AddNewEntityButton({
  text,
  'data-testid': dataTestId,
  onClick,
}: AddNewEntityButtonProps) {
  return (
    <Button variant="outlined" data-testid={dataTestId} onClick={onClick}>
      {text}
    </Button>
  );
}
