import React, { useState } from 'react';
import { CellInput, CellPopover, CellRangeInput, TableCell } from '@clippings/paper';
import { parseTimeRange } from './quoteUtils';
import { useTranslation } from 'react-i18next';

interface TimeRangeCellProps {
  timeRange: [number, number];
  renderTimeRange: (range: [number, number]) => string;
  onBlur?: (valueArray: [number, number]) => void;
}

const TimeRangeInfoLabel: React.FC<{
  renderTimeRange: TimeRangeCellProps['renderTimeRange'];
}> = ({ renderTimeRange }) => {
  const { t } = useTranslation();
  const examples = ['0', '2', '3-3', '6-8', '12-24'];

  return (
    <>
      {t('quotes.table.leadTimeHint')}
      <ul style={{ all: 'unset', paddingLeft: 3, display: 'block' }}>
        {examples.map(example => {
          return (
            <li key={example}>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <strong>"{example}"</strong> - ({renderTimeRange(parseTimeRange(example))})
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const TimeRangeCell: React.FC<TimeRangeCellProps> = ({
  timeRange,
  renderTimeRange,
  onBlur,
}) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const renderedTimeRange = renderTimeRange(timeRange);
  const [lastTimeRangeText, setLastTimeRangeText] = useState(renderedTimeRange);
  const [textValue, setTextValue] = useState(renderedTimeRange);

  if (renderedTimeRange !== lastTimeRangeText) {
    setTextValue(renderedTimeRange);
    setLastTimeRangeText(renderedTimeRange);
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const valueArray = parseTimeRange(e.target.value);

    onBlur?.(valueArray);
    setTextValue(renderTimeRange(valueArray));
    setIsBeingEdited(false);
  };

  const cell = isBeingEdited ? (
    <CellRangeInput
      inputProps={{
        sx: { textAlign: 'right' },
        'data-testid': 'time-range-input',
      }}
      autoComplete="off"
      onBlur={handleBlur}
      forcedValue={timeRange.join('-')}
      autoFocus
    />
  ) : (
    <CellInput
      inputProps={{ sx: { textAlign: 'right' }, 'data-testid': 'time-range-text' }}
      onFocus={() => setIsBeingEdited(true)}
      forcedValue={textValue}
    />
  );

  return (
    <CellPopover infoLabel={<TimeRangeInfoLabel renderTimeRange={renderTimeRange} />}>
      <TableCell>{cell}</TableCell>
    </CellPopover>
  );
};
