import {
  ALLOWED_FILE_EXTENSIONS,
  ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED,
  MAX_FILE_SIZE_BYTES,
} from 'libs/api/upload/actions';
import { AxiosError } from 'axios';
import { BrowserUploadedFile } from 'libs/api/upload/types';
import { ChangeEvent, DragEvent, useRef } from 'react';
import { Nullable } from '@clippings/paper/build/core/utils/types';
import { getFileExtension } from '@clippings/paper';
import { uniqueId } from 'lodash';
import { useAlertSnackbar } from 'libs/hooks/useAlertSnackbar';
import { useEntityReducer } from 'libs/shared';
import { useTranslation } from 'react-i18next';

export const useQuoteDocumentUtils = () => {
  const { t } = useTranslation();
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const { handleShowSnackbar, props: snackbarProps } = useAlertSnackbar();
  const browserUploadedFileManager = useEntityReducer<BrowserUploadedFile>([]);

  const { add: addBrowserDoc, remove: removeBrowserDoc } = browserUploadedFileManager;

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = 'dataTransfer' in e ? e.dataTransfer.files : e.target.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = getFileExtension(file.name);

        const uploadDoc: BrowserUploadedFile = {
          file,
          id: uniqueId(),
        };

        if (
          file.size > MAX_FILE_SIZE_BYTES ||
          !extension ||
          !ALLOWED_FILE_EXTENSIONS.includes(extension.toLowerCase())
        ) {
          handleShowSnackbar(
            t('checkout.purchaseOrder.uploadValidationError', {
              formats: ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED,
              size: '10MB',
            }),
            'error'
          );
          continue;
        }

        addBrowserDoc(uploadDoc);
      }
    }

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const onDocumentRemoveSuccess = () => {
    handleShowSnackbar(t('quotes.upload.documentRemoved'), 'success');
  };
  const onDocumentRemoveError = () => {
    handleShowSnackbar(t('quotes.upload.failedRemovingDocument'), 'error');
  };
  const onDocumentDownloadSuccess = () => {
    handleShowSnackbar(t('common.fileDownloaded'), 'success');
  };
  const onDocumentDownloadError = () => {
    handleShowSnackbar(t('common.fileDownloadFailed'), 'error');
  };
  const onDocumentUploadSuccess = (file: BrowserUploadedFile) => {
    handleShowSnackbar(t('quotes.upload.documentUploaded'), 'success');
    removeBrowserDoc(file);
  };
  const onDocumentUploadError = (e: AxiosError, file: BrowserUploadedFile) => {
    removeBrowserDoc(file);

    if (e.code !== 'ERR_CANCELED') {
      // cancel is available during uploading
      handleShowSnackbar(t('quotes.upload.failedUploadingDocument'), 'error');
    }
  };

  return {
    inputRef,
    handleFileUpload,
    snackbarProps,
    onDocumentDownloadSuccess,
    onDocumentDownloadError,
    onDocumentRemoveSuccess,
    onDocumentRemoveError,
    onDocumentUploadSuccess,
    onDocumentUploadError,
    browserDocs: {
      ...browserUploadedFileManager,
    },
  };
};
