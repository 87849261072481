import { getQueryParamFromUrl } from 'libs/Utils/getQueryParamFromUrl';

export const QUERY_KEY = 'q' as const;
export const SEARCH_ACTION = 'SET_SEARCH' as const;

type StateType = {
  query: string;
};

const initialState: StateType = {
  query: getQueryParamFromUrl(QUERY_KEY),
};

export const searchReducer = (
  state: StateType = initialState,
  action: { type: typeof SEARCH_ACTION; payload: string }
): StateType => {
  switch (action.type) {
    case SEARCH_ACTION:
      return {
        query: action.payload,
      };
    default:
      return state;
  }
};
