import { Address, AddressType } from 'libs/api/common/types';
import { Brand, getAddressValidation, getCompanyValidation, yup } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { isBillingAddress, isShippingAddress } from 'libs/api/common/utils';

const getBaseAddressValues = (address?: Address) => ({
  country: address?.country?.shortName ?? '',
  zip: address?.zip ?? '',
  state: address?.state?.id?.toString(),
  province: address?.province ?? '',
  city: address?.city ?? '',
  address: address?.address ?? '',
  address2: address?.address2 ?? '',
  contactName: address?.contactName ?? '',
});

const getShippingAddressValues = (brand: Brand | null, address?: Address) => ({
  ...(address && isShippingAddress(address)
    ? {
        ...address,
        country: address.country?.shortName,
        state: address.state?.id?.toString(),
        province: address.province,
      }
    : {
        phone: '',
        country: '',
        accessDetails: '',
        nickname: '',
        metadata: {
          ...(brand === Brand.Fomcore && {
            dock: '',
            attention: '',
            trackingNotificationEmailAddress: '',
            companyName: '',
            locationType: '',
            locationTypeOther: '',
          }),
        },
      }),
});

const getBillingAddressValues = (company: Company, address?: Address) => ({
  ...(address &&
    isBillingAddress(address) && {
      email: address?.email ?? '',
      companyName: address?.companyName ?? '',
      vatNumber: company.taxNumber,
    }),
});

export const getCompanyAddressFormData = (
  brand: Brand | null,
  company: Company,
  type: AddressType,
  address?: Address
) => {
  const addressValidation = getAddressValidation(brand);
  const companyValidation = getCompanyValidation(brand);

  const defaultValues = {
    ...getBaseAddressValues(address),
    ...(type === 'shipping' && getShippingAddressValues(brand, address)),
    ...(type === 'billing' && getBillingAddressValues(company, address)),
  };

  const schema = yup.object().shape({
    country: addressValidation.country,
    zip: addressValidation.zipCode,
    state: addressValidation.state,
    province: addressValidation.province,
    city: addressValidation.city,
    address: addressValidation.address,
    contactName: addressValidation.contactName,
    ...(type === 'shipping' && {
      phone: addressValidation.phone,
      accessDetails: addressValidation.accessDetails,
      nickname: addressValidation.nickname,
      metadata: addressValidation.metadata,
    }),
    ...(type === 'billing' && {
      email: addressValidation.email,
      companyName: companyValidation.name,
    }),
  });

  return { schema, defaultValues };
};
