import React from 'react';
import { Box } from '@clippings/paper';
import { DeliveryMethodEvaluationResponse } from 'libs/api/quotes/types';
import { DeliveryMethodsList, DeliveryMethodsListProps } from './DeliveryMethodsList';
import { ShippingTypeSkeleton } from '../Skeletons';
import { UseQueryResult } from '@tanstack/react-query/src/types';

type ShippingTypesProps = {
  evaluateDeliveryMethodsQuery: UseQueryResult<DeliveryMethodEvaluationResponse>;
} & Pick<DeliveryMethodsListProps, 'formDefaultValues'>;

export const DeliveryMethods: React.FC<ShippingTypesProps> = ({
  evaluateDeliveryMethodsQuery,
  formDefaultValues,
}) => {
  const content = evaluateDeliveryMethodsQuery.isLoading ? (
    <ShippingTypeSkeleton />
  ) : (
    <DeliveryMethodsList
      deliveryMethods={evaluateDeliveryMethodsQuery.data?.items ?? []}
      formDefaultValues={formDefaultValues}
    />
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-testid="shipping-types"
      flexDirection="column"
      gap={1}
    >
      {content}
    </Box>
  );
};
