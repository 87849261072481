import React, { PropsWithChildren } from 'react';

type TabPanelProps = {
  activeTab: number;
  idx: number;
};

export function TabPanel({ activeTab, idx, children }: PropsWithChildren<TabPanelProps>) {
  return <>{activeTab === idx ? children : null}</>;
}
