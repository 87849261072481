import React, { FC } from 'react';
import { FlexRow, Typography } from '@clippings/paper';

export const NoResultsMessage: FC<{ message: string }> = ({ message }) => {
  return (
    <FlexRow
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle2" color="text.secondary">
        {message}
      </Typography>
    </FlexRow>
  );
};
