import React from 'react';
import { QuoteItemImage } from 'libs/SalesApp/Quotes/components/QuoteItemImage';
import { TableCell } from '@clippings/paper';
import { TableCellCommonProps } from '../tableCells.utils';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';

type InfoCellItemImageProps = {
  onUpdate: UpdateQuoteByKey;
} & TableCellCommonProps;

export function InfoCellItemImage({ item, loading, readonly, onUpdate }: InfoCellItemImageProps) {
  return (
    <TableCell sx={styles.imageCellSx} data-testid={`quote-item-cell-image-${item.id}`}>
      <QuoteItemImage item={item} loading={loading} readonly={readonly} onUpdate={onUpdate} />
    </TableCell>
  );
}

const styles = {
  imageCellSx: {
    width: '80px',
    p: 1,
    position: 'relative',
    '&:hover': {
      '.quote-item-image__buttons': {
        visibility: 'visible',
      },
    },
  },
};
