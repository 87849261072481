import React from 'react';
import { DataTestId, Monetary, TableCell } from '@clippings/paper';
import { Money } from 'libs/api/common/types';

type ContractValueCellProps = {
  value: Money;
} & DataTestId;

export function ContractValueCell({ value, 'data-testid': dataTestId }: ContractValueCellProps) {
  return (
    <TableCell data-testid={dataTestId} align="right">
      <Monetary
        currency={value.currency}
        amount={value.amount}
        sx={typographySx}
        color="text.secondary"
      />
    </TableCell>
  );
}

const typographySx = {
  marginTop: '9px',
  fontSize: 'inherit',
};
