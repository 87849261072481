import React, { ComponentProps } from 'react';
import classNames from 'classnames';
import { CheckIcon, DateTime, DateTimeFormat, MenuItem, Typography } from '@clippings/paper';
import { QuoteListItemStatus, QuoteVersion } from 'libs/api/quotes/types';
import { quoteStatusMap } from 'libs/Constants';
import { useTranslation } from 'react-i18next';
import './QuoteVersionSelectItem.scss';

interface QuoteVersionSelectItemProps extends ComponentProps<typeof MenuItem> {
  selected: boolean;
  handleClick: (version: QuoteVersion) => void;
  version: QuoteVersion;
}

export const QuoteVersionSelectItem = ({
  selected,
  handleClick,
  version,
  ...rest
}: QuoteVersionSelectItemProps) => {
  const { t } = useTranslation();

  const handleItemSelect = () => {
    handleClick(version);
  };

  return (
    <MenuItem
      onClick={handleItemSelect}
      className="quote-version-select-item"
      data-testid={`quote-version-item-${version.versionNumber}`}
      sx={{ '&.Mui-disabled': { opacity: 1 } }}
      {...rest}
    >
      {selected && (
        <CheckIcon color="primary" className="quote-version-select-item__selected-icon" />
      )}
      <div className="quote-version-select-item__label">
        <Typography className={classNames('quote-version-select-item__version', { selected })}>
          <>
            {t('quotes.version')} {version.versionNumber}{' '}
            {t(quoteStatusMap[version.status as QuoteListItemStatus].labelKey)}
          </>
        </Typography>

        {version.active ? (
          <Typography
            variant="caption"
            color="secondary"
            className="quote-version-select-item__created-at"
          >
            {t('quotes.latest')}
          </Typography>
        ) : (
          <DateTime
            className="quote-version-select-item__created-at-time"
            variant="caption"
            color="secondary"
            format={DateTimeFormat.DateTime}
            date={version.createdAt}
          />
        )}
      </div>
    </MenuItem>
  );
};
