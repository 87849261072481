import { User, UserStatus } from 'libs/api/iam/types';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LocationState {
  user?: User;
}

export const useUserUtils = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const [newlyCreatedUser, setNewlyCreatedUser] = useState<User | null>(null);
  const [newlyUpdatedUser, setNewlyUpdatedUser] = useState<User | null>(
    locationState?.user ?? null
  );

  const navigateToNewlyCreatedUser = () => {
    if (newlyCreatedUser) {
      navigate(path(routes.salesApp.userDetails, { userId: newlyCreatedUser.id }));
    }
  };

  const navigateToNewlyUpdatedUser = () => {
    if (newlyUpdatedUser) {
      navigate(path(routes.salesApp.userDetails, { userId: newlyUpdatedUser.id }));
    }
  };

  const getUpdatedUserSnackbarText = () => {
    const userName = `${newlyUpdatedUser?.firstName} ${newlyUpdatedUser?.lastName}`;

    return newlyUpdatedUser?.status === UserStatus.Approved
      ? t('common.userWasSuccessfullyApproved', {
          userName,
        })
      : t('common.userWasRejected', {
          userName,
        });
  };

  const getCreatedUserSnackbarText = () => {
    return t('common.userWasSuccessfullyCreated', { userName: newlyCreatedUser?.firstName });
  };

  return {
    newlyCreatedUser,
    newlyUpdatedUser,
    setNewlyCreatedUser,
    setNewlyUpdatedUser,
    navigateToNewlyCreatedUser,
    navigateToNewlyUpdatedUser,
    getUpdatedUserSnackbarText,
    getCreatedUserSnackbarText,
  };
};
