import React from 'react';
import { Catalogue, Category } from 'libs/api/catalogue/types';
import { CompanyProductDiscount } from 'libs/api/common/types';
import { FlexColumn } from '@clippings/paper';
import { ProductDiscountTable } from './ProductDiscountTable';
import { ProductDiscountTableBody } from './ProductDiscountTableBody';
import { ProductDiscountTableHead } from './ProductDiscountTableHead';
import { ProductDiscountsTitle } from './ProductDiscountsTitle';

type ProductDiscountsProps = {
  catalogues: Catalogue[];
  categories: Category[];
  productDiscounts: CompanyProductDiscount[];
  onChange: (discounts: CompanyProductDiscount[]) => void;
};

export function ProductDiscounts({
  productDiscounts,
  catalogues = [],
  categories = [],
  onChange,
}: ProductDiscountsProps) {
  const createNewDiscountHandler = () => {
    onChange([...productDiscounts, createNewDiscount(catalogues[0]?.slug)]);
  };

  return (
    <>
      <ProductDiscountsTitle />
      <FlexColumn pr={3}>
        <ProductDiscountTable onCreateNewDiscount={createNewDiscountHandler}>
          <ProductDiscountTableHead />
          <ProductDiscountTableBody
            categories={categories}
            catalogues={catalogues}
            discounts={productDiscounts}
            onChange={onChange}
          />
        </ProductDiscountTable>
      </FlexColumn>
    </>
  );
}

//////
let newItemIndex = -1;

const createNewDiscount = (catalogueSlug = ''): CompanyProductDiscount => {
  // assign every new item a unique, negative index
  const id = String(newItemIndex);
  newItemIndex--;

  return {
    id,
    type: 'product',
    percent: null,
    catalogueSlug,
    title: '',
    productCategories: [],
  };
};
