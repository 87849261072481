import React from 'react';
import { BaseUser, Money } from 'libs/api/common/types';
import {
  Column,
  DividerGrey,
  ItemRow,
  ListCardItemBaseViewModel,
  ListItemCard,
  StatusChip,
} from 'libs/shared';
import { DateTime, DateTimeFormat, Monetary, Typography } from '@clippings/paper';
import { Nullable, path } from 'libs/Utils';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { UserAvatarRow } from 'libs/Components/UserAvatarRow/UserAvatarRow';
import { routes } from 'libs/Constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type QuoteListCardItemViewModel = ListCardItemBaseViewModel & {
  value: Money;
  status: QuoteListItemStatus;
  activity: string;
  salesRep: Nullable<BaseUser>;
  newMessages: boolean;
};

export type QuoteListItemCardProps = {
  item: QuoteListCardItemViewModel;
  actions?: (quote: QuoteListCardItemViewModel) => JSX.Element;
};

export function QuoteListItemCard({ item, actions }: QuoteListItemCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function clickHandler() {
    navigate(path(routes.quotes.show, { number: item.number }));
  }

  return (
    <ListItemCard<QuoteListCardItemViewModel> item={item} actions={actions} onClick={clickHandler}>
      <ItemRow
        label={t('common.value')}
        value={
          <Monetary
            fontWeight="fontWeightMedium"
            amount={item.value.amount}
            currency={item.value.currency}
          />
        }
      />
      <DividerGrey sx={{ my: 1 }} />
      <ItemRow label={t('common.status')} value={<StatusChip status={item.status} />} />
      <DividerGrey sx={{ my: 1 }} />
      <ItemRow
        label={t('common.recentActivity')}
        value={
          <Column>
            <DateTime
              format={DateTimeFormat.Date}
              date={item.activity}
              variant="body1"
              fontWeight="fontWeightMedium"
            />
            {item.newMessages && (
              <Typography variant="caption" className="quote-list-grid-item__message">
                {t('common.newMessage')}
              </Typography>
            )}
          </Column>
        }
      />

      {item.salesRep ? (
        <>
          <DividerGrey sx={{ my: 1 }} />
          <ItemRow label={t('quotes.salesRep')} value={<UserAvatarRow user={item.salesRep} />} />
        </>
      ) : null}
    </ListItemCard>
  );
}
