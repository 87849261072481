import React, { PropsWithChildren } from 'react';
import { DataTestId } from 'libs/shared';
import { ListTable, ListTableBody } from 'libs/Components';
import { ListTableHeader } from 'libs/Components/ListTable/ListTableHeader';
import { SortableTableHeader, SortableTableHeaderProps } from './SortableTableHeader';

const defaultRowHeight = 74;
const defaultTableHeaderColumns = 4;

type SortableTableProps = {
  isLoading: boolean;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  noMoreLabel: string;
  rowHeight?: number;
  tableHeaderColumns?: number;
  onFetchNextPage: () => void;
} & DataTestId &
  SortableTableHeaderProps;

export function SortableTable({
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  noMoreLabel,
  children,
  cellConfig,
  sort,
  rowHeight = defaultRowHeight,
  tableHeaderColumns = defaultTableHeaderColumns,
  'data-testid': dataTestId,
  onSort,
  onFetchNextPage,
}: PropsWithChildren<SortableTableProps>) {
  return (
    <ListTable
      rowHeight={rowHeight}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      noMoreLabel={noMoreLabel}
      data-testid={dataTestId}
      onFetchNextPage={onFetchNextPage}
    >
      <ListTableHeader columns={tableHeaderColumns} data-testid={`${dataTestId}-header`}>
        <SortableTableHeader cellConfig={cellConfig} sort={sort} onSort={onSort} />
      </ListTableHeader>
      <ListTableBody>{children}</ListTableBody>
    </ListTable>
  );
}
