import { QueryOptions } from 'libs/api/common/types';
import { StaffUserPermission } from 'libs/SalesApp/Users/utils/user.utils';
import { User } from 'libs/api/iam/types';
import { getAllStaffMembers } from 'libs/SalesApp/Quotes/quoteUtils';
import { useQuery } from '@tanstack/react-query';

export const useStaffSalesReps = (options?: QueryOptions<User[]>): User[] => {
  const staffMembersQuery = useQuery(
    ['staffMembers'],
    // TODO: Refactor with new permission filter and pagination once BE task is ready (https://designtechnologies.atlassian.net/browse/BD-716)
    () => getAllStaffMembers({ permissions: StaffUserPermission.IS_STAFF }),
    options
  );

  const { data } = staffMembersQuery;

  return data ?? [];
};
