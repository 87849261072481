import React from 'react';
import { Box, Button, yup } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { FormCompanyTypeahead } from '../../components/FormCompanyTypeahead';
import { FormCompanyTypeaheadPaper } from '../../components/FormCompanyTypeaheadPaper';
import { FormWrapper, InfoBox } from 'libs/shared';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { useTranslation } from 'react-i18next';

interface LinkExistingCompanyPanelProps {
  onReject: () => void;
  onSubmit: (company: { company: Company }) => void;
  isSubmitting: boolean;
  errors: unknown;
  canReject: boolean;
}

export const LinkExistingCompanyPanel: React.FC<LinkExistingCompanyPanelProps> = ({
  onReject,
  onSubmit,
  isSubmitting,
  errors,
  canReject,
}) => {
  const { t } = useTranslation();

  const formErrors = useServerFormErrors(errors);

  return (
    <FormWrapper
      onSubmit={onSubmit}
      validationSchema={{
        company: yup.object().nullable().required('common.companyRequired'),
      }}
      errors={formErrors}
    >
      <InfoBox title={t('users.linkThisUserToExistingCompanyProfile')} sx={{ mb: 4 }}>
        <FormCompanyTypeahead
          name="company"
          label={t('common.company')}
          required={true}
          sx={{ width: '400px' }}
          data-testid="company"
          disabled={isSubmitting}
          paperComponentRender={(props, inputValue) => (
            <FormCompanyTypeaheadPaper canCreateCompany searchValue={inputValue} {...props} />
          )}
        />
      </InfoBox>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexWrap="wrap"
        gap={1}
        alignItems="center"
        p={2}
        borderTop="1px solid"
        borderColor="grey.A400"
        bgcolor={theme => `${theme.palette.grey['50']}f0`}
        position="sticky"
        bottom="0"
        mx={-3}
      >
        {canReject && (
          <Button
            type="button"
            disabled={isSubmitting}
            variant="outlined"
            onClick={onReject}
            data-testid="reject-user-button"
          >
            {t('common.rejectUser')}
          </Button>
        )}

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          data-testid="approve-user-button"
        >
          {t('users.approveUserAndLinkToCompany')}
        </Button>
      </Box>
    </FormWrapper>
  );
};
