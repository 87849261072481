import { BaseListQueryParams, BaseUser, SortDirection } from 'libs/api/common/types';
import { BaseTeam, ListTeamsParams, ListUsersParams, Team, User } from 'libs/api/iam/types';
import { QUOTES_PAGE_STATUS_MAP, QuotesFilters, QuotesPage } from './types';
import { Quote, QuoteListItemStatus } from 'libs/api/quotes/types';
import { isNumber } from 'lodash';
import { listTeams, listUsers } from 'libs/api/iam/actions';

export type SingleTeamUser = Omit<User, 'teams'> & { team?: BaseTeam };

export const SALES_FROZEN_QUOTE_STATUSES = [
  QuoteListItemStatus.Approved,
  QuoteListItemStatus.Ordered,
  QuoteListItemStatus.Expired,
  QuoteListItemStatus.Archived,
  QuoteListItemStatus.Requested,
];

export const isFrozenStatus = (status: QuoteListItemStatus) =>
  SALES_FROZEN_QUOTE_STATUSES.includes(status);

export const getPageTitleTranslation = (page: QuotesPage): `quotes.${typeof page}Page` =>
  `quotes.${page}Page`;

export const getOrder = (isCurrent: boolean, currentOrder: SortDirection): SortDirection => {
  if (isCurrent && currentOrder === 'asc') return 'desc';

  return 'asc';
};

/**
 *
 * @param values Quotes status filter values
 * @param page Current quotes page, e.g. 'active'
 * @returns Quotes status filter values according to provided page or for ACTIVE page if no page is provided
 */
export const getQuotesRequestStatusFilter = (
  values: QuotesFilters['status'],
  page?: QuotesPage
): QuotesFilters['status'] => {
  if (values?.length) {
    return values;
  }

  if (page) {
    return QUOTES_PAGE_STATUS_MAP[page];
  }

  return QUOTES_PAGE_STATUS_MAP[QuotesPage.Active];
};

const USERS_API_RESULT_LENGTH = 100;

// TODO: Change with regular pagination once BE has implemented page limit param, e.g. give me 50 users per page
// https://designtechnologies.atlassian.net/browse/BUY-962
export const getAllEntities = async <
  Entity extends Record<string, any>,
  Params extends BaseListQueryParams
>(
  fetchFunction: (params?: Params) => Promise<Entity[]>,
  params?: Params
) => {
  let hasNextPage = true;
  let pageCounter = 0;
  const users: Entity[] = [];
  while (hasNextPage) {
    const result = await fetchFunction({
      ...(params as Params),
      page: ++pageCounter,
      itemsPerPage: USERS_API_RESULT_LENGTH,
    });

    users.push(...result);
    hasNextPage = result.length === USERS_API_RESULT_LENGTH;
  }

  return users;
};

export const getAllStaffMembers = async (params?: ListUsersParams) =>
  getAllEntities<User, ListUsersParams>(listUsers, params);

export const getAllTeams = async (params?: ListTeamsParams) =>
  getAllEntities<Team, ListTeamsParams>(listTeams, params);

export const isNewQuote = (quote: Quote) => !quote.customer || !quote.catalogue;

export function mapBaseUser(user: User): BaseUser {
  const base = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    picture: user.picture,
    guest: user.guest,
  };

  if (!user.company) {
    return base;
  }

  return {
    ...base,
    company: {
      id: user.company?.id,
      name: user.company?.name,
    },
  };
}

/**
 * Returns one or more sales rep objects based on the number of provided rep's teams
 *
 * Each new rep object contains only a single team
 * @param rep Sales Representative (User)
 */
export const flattenSalesRepTeams = (rep: User): SingleTeamUser[] => {
  return rep.teams.length > 0 ? rep.teams?.map?.(team => ({ ...rep, team })) : [rep];
};

export const parseTimeRange = (rangeInput: string): [number, number] => {
  const valueArray = rangeInput.split('-').map((x: string) => parseInt(x));

  if (valueArray.length === 1) {
    valueArray.push(valueArray[0]);
  }

  if (!isNumber(valueArray[1]) || isNaN(valueArray[1])) {
    valueArray.splice(1, 1, valueArray[0]);
  }

  return valueArray as [number, number];
};
