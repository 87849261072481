import { AppType, getCurrentApp, path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useLocation } from 'react-router-dom';

const getOrdersListPath =
  (currentApp: AppType) =>
  (orderNumber?: string): string => {
    const routesByApp: Record<AppType, string> = {
      customer: routes.orders.list,
      sales: routes.salesApp.orders.list,
      guest: routes.guestApp.orders.show,
    };

    if (currentApp === 'guest') {
      return path(routesByApp[currentApp], { number: orderNumber });
    }

    return routesByApp[currentApp];
  };

const getOrderShowPath = (currentApp: AppType) => (orderNumber: string) => {
  const routesByApp: Record<AppType, string> = {
    customer: routes.orders.show,
    sales: routes.salesApp.orders.show,
    guest: routes.guestApp.orders.show,
  };

  return path(routesByApp[currentApp], { number: orderNumber });
};

const getOrderPaymentPath = (currentApp: AppType) => (orderNumber: string, paymentId: string) => {
  const routesByApp: Record<AppType, string> = {
    customer: routes.orders.pay,
    sales: routes.salesApp.orders.pay,
    guest: routes.guestApp.orders.pay,
  };

  return path(routesByApp[currentApp], { number: orderNumber, id: paymentId });
};

export const useOrderPaths = () => {
  const { pathname } = useLocation();
  const currentApp = getCurrentApp(pathname);

  return {
    getOrdersListPath: getOrdersListPath(currentApp),
    getOrderShowPath: getOrderShowPath(currentApp),
    getOrderPaymentPath: getOrderPaymentPath(currentApp),
  };
};
