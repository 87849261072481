import React, { useState } from 'react';
import { ChipMenu, MenuItem, Skeleton } from '@clippings/paper';
import {
  INTENDED_USE_OPTIONS,
  IntendedUseOptionsType,
  useIntendedUse,
} from 'libs/shared/hooks/useIntendedUse';
import { maskedQuoteStatuses } from 'libs/SalesApp/Quotes/QuoteDetailsPage';
import { useQuoteProvider } from '../providers';
import { useTranslation } from 'react-i18next';

export const IntendedUseLabel = () => {
  const { loading, handleQuoteUpdate, quote } = useQuoteProvider();
  const [isOpen, setIsOpen] = useState(false);
  const { enabled, getLabel } = useIntendedUse();
  const handleChipClick = (isMenuOpened: boolean) => {
    setIsOpen(isMenuOpened);
  };
  const { t } = useTranslation();

  const handleItemSelect = (intendedUse: IntendedUseOptionsType) => {
    handleQuoteUpdate(
      { metadata: { ...quote.metadata, intendedUse: intendedUse } },
      { withOptimisticUpdate: false }
    );
    setIsOpen(false);
  };

  if (!enabled) {
    return null;
  }

  if (loading) {
    return (
      <Skeleton
        width="140px"
        height="30px"
        variant="rectangular"
        sx={{ borderRadius: 4, my: '3px' }}
        data-testid="intended-use-loader"
      />
    );
  }

  return (
    <ChipMenu
      disabled={maskedQuoteStatuses.includes(quote.status)}
      variant="outlined"
      color={quote.metadata?.intendedUse ? 'default' : 'error'}
      isOpen={isOpen}
      value={
        quote.metadata?.intendedUse ? getLabel(quote.metadata.intendedUse) : t('intendedUse.label')
      }
      onToggle={handleChipClick}
      data-testid="quote-intended-use-select-button"
      sx={{
        width: {
          xs: '100%',
          md: 'fit-content',
        },
        justifyContent: {
          xs: 'space-between',
          md: 'center',
        },
      }}
      menuProps={{
        open: isOpen,
        PaperProps: {
          sx: {
            marginTop: 0.5,
            width: {
              xs: '100%',
              md: 'auto',
            },
          },
        },
      }}
    >
      {Object.keys(INTENDED_USE_OPTIONS).map(key => (
        <MenuItem
          onClick={() => handleItemSelect(key as IntendedUseOptionsType)}
          selected={quote.metadata?.intendedUse === key}
          value={INTENDED_USE_OPTIONS[key as IntendedUseOptionsType]}
          key={key}
          data-testid={'intended-use-item-' + key}
        >
          {getLabel(key as IntendedUseOptionsType)}
        </MenuItem>
      ))}
    </ChipMenu>
  );
};
