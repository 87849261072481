import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Box } from '@clippings/paper';
import './LayoutHeader.scss';

interface LayoutHeaderProps extends ComponentProps<typeof Box> {
  className?: string;
}

export const LayoutHeader: FC<PropsWithChildren<LayoutHeaderProps>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <Box className={classNames('layout-header', className)} {...rest}>
      {children}
    </Box>
  );
};
