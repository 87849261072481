import React, { MouseEvent, ReactNode, useState } from 'react';
import { ArrowDownIcon, Button, Menu } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { useTranslation } from 'react-i18next';

type EntityDownloadMenuProps = {
  disabled?: boolean;
  buttonDataTestId?: string;
  menuDataTestId?: string;
  children: (props: { handleClose: () => void }) => ReactNode;
};

export function EntityDownloadMenu({
  children,
  buttonDataTestId = '',
  menuDataTestId = '',
  disabled = false,
}: EntityDownloadMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);
  const open = anchorEl != null;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-testid={buttonDataTestId}
        variant="text"
        aria-haspopup="true"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
        onClick={handleClick}
      >
        {t('common.download')}
        <ArrowDownIcon />
      </Button>
      <Menu
        data-testid={menuDataTestId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={menuConfig.anchorOrigin}
        transformOrigin={menuConfig.transformOrigin}
      >
        {children({ handleClose })}
      </Menu>
    </>
  );
}

const menuConfig: any = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};
