import QuoteListGridItem from './QuoteListGridItem';
import React from 'react';
import { Grid } from '@clippings/paper';
import { ListTable, ListTableProps } from 'libs/Components/ListTable/ListTable';
import { ListTableBody } from 'libs/Components/ListTable/ListTableBody';
import { ListTableHeader } from 'libs/Components/ListTable/ListTableHeader';
import { ListTableHeaderCell } from 'libs/Components/ListTable/ListTableHeaderCell';
import { QuoteListItem } from 'libs/api/quotes/types';
import { useTranslation } from 'react-i18next';

interface QuoteListSectionProps extends Omit<ListTableProps, 'rowHeight'> {
  quotes: QuoteListItem[];
  loading: boolean;
  hasMore: boolean;
  onClick: () => void;
  disableActions: boolean;
  hasMoreLoading?: boolean;
}

export const QuoteListSection: React.FC<QuoteListSectionProps> = ({
  quotes,
  loading,
  hasMore,
  onClick,
  disableActions,
  hasMoreLoading,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ListTable
      rowHeight={74}
      isLoading={loading}
      onFetchNextPage={onClick}
      hasNextPage={hasMore}
      noMoreLabel={t('quotes.noMoreQuotes')}
      isFetchingNextPage={hasMoreLoading}
      {...rest}
    >
      <ListTableHeader columns={12} data-testid="quote-grid">
        <ListTableHeaderCell
          data-testid="ca-quotes-table-header-quote-number"
          label={t('quotes.quoteNumber')}
          columns={2}
        />
        <ListTableHeaderCell
          data-testid="ca-quotes-table-header-quote-value"
          label={t('common.value')}
          columns={2}
        />
        <ListTableHeaderCell
          data-testid="ca-quotes-table-header-quote-status"
          label={t('common.status')}
          columns={2}
        />
        <ListTableHeaderCell
          data-testid="ca-quotes-table-header-quote-recent-activity"
          label={t('common.recentActivity')}
          columns={2}
        />
        <ListTableHeaderCell
          data-testid="ca-quotes-table-header-quote-sales-rep"
          label={t('quotes.salesRep')}
          columns={3}
        />
        <Grid item xs={1} />
      </ListTableHeader>

      <ListTableBody>
        {quotes.map(quote => (
          <QuoteListGridItem
            quote={quote}
            key={quote.number}
            data-testid={`quote-list-item-${quote.number}`}
            disableActions={disableActions}
          />
        ))}
      </ListTableBody>
    </ListTable>
  );
};
