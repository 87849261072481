/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { AddressBox } from './AddressBox';
import { Alert, Box, Button, Typography } from '@clippings/paper';
import { BillingAddress } from 'libs/api/common/types';
import { Company } from 'libs/api/iam/types';
import { GeneralCompanyInfoBox } from 'libs/SalesApp/Companies/details/components/CompanyDetailsPanel/components/GeneralCompanyInfoBox';
import { InfoBox } from 'libs/shared';
import { Trans, useTranslation } from 'react-i18next';

interface CreateNewCompanyPanelProps {
  onReject: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  company: Company;
  addresses: BillingAddress[];
  canReject: boolean;
}

export const CreateNewCompanyPanel: React.FC<CreateNewCompanyPanelProps> = ({
  company,
  addresses,
  onReject,
  isSubmitting,
  onSubmit,
  canReject,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <InfoBox
        sx={{ mb: 4 }}
        notification={
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            <Trans t={t} i18nKey={'users.checkCarefullyCompanyInformation'}>
              Please check carefully the <strong>company information</strong> and set the
              <strong>payment terms</strong> and discounts
            </Trans>
          </Alert>
        }
        title={t('common.companyInformation')}
        data-testid="company-info-box"
      >
        <GeneralCompanyInfoBox company={company} />

        <Box width="100%">
          <Typography variant="h6" fontWeight="bold" my={3}>
            {t('companies.addressInformation')}
          </Typography>
          <Typography variant="subtitle2" color="grey.500" fontWeight="fontWeightBold" mb={1}>
            {t('common.billingAddress')}
          </Typography>

          {addresses.map(address => (
            <AddressBox address={address} key={address.id} />
          ))}
        </Box>
      </InfoBox>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexWrap="wrap"
        gap={1}
        alignItems="center"
        p={2}
        borderTop="1px solid"
        borderColor="grey.A400"
        bgcolor={theme => `${theme.palette.grey['50']}f0`}
        position="sticky"
        bottom="0"
        mx={-3}
      >
        {canReject && (
          <Button
            disabled={isSubmitting}
            variant="outlined"
            onClick={onReject}
            data-testid="reject-user-button"
          >
            {t('common.rejectUser')}
          </Button>
        )}

        <Button
          disabled={isSubmitting}
          variant="contained"
          onClick={onSubmit}
          data-testid="approve-user-button"
        >
          {t('users.approveUserAndCreateCompany')}
        </Button>
      </Box>
    </>
  );
};
