import React, { useState } from 'react';
import {
  CellInputWithReadonly,
  CellMoneyInputWithReadonly,
  CellPercentageInputWithReadonly,
  CellSelectInputWithReadonly,
} from '../../components/componentsWithReadOnlyLabel';
import {
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  useFormatPercentage,
  useFormatPrice,
} from '@clippings/paper';
import { QuoteDiscountType, SalesQuoteDiscount } from 'libs/api/common/types';
import { Readonly } from 'libs/shared';
import { RowActionsPanel } from 'libs/shared/components/RowActionsPanel';
import { useTranslation } from 'react-i18next';

const discountTypeLabelMap = {
  [QuoteDiscountType.FixedAmount]: 'common.fixedAmount',
  [QuoteDiscountType.Percentage]: 'common.percentage',
} as const;

export interface DiscountsTableRowProps extends Readonly {
  index: number;
  discount: SalesQuoteDiscount;
  onChange: (discount: SalesQuoteDiscount | null) => void;
}

export const DiscountsTableRow: React.FC<DiscountsTableRowProps> = ({
  index,
  discount,
  readonly = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const formatPercentage = useFormatPercentage();
  const formatPrice = useFormatPrice();

  /**
   * An ugly hack to set discount name for initial quote discount
   * There are several issues with this, from UX perspective and from implementation perspective
   * Discount name is translated based on the discount type discount service does not return type on quote level
   * Here we want to set the discount name based on user input
   */

  discount.name = discount.name || t(`companies.quoteDiscountLabel`);

  const showActionPanel = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElement(event.currentTarget);

  const hideActionPanel = () => setAnchorElement(null);

  const handleDelete = () => onChange(null);

  const handleNameChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmed = event.target.value.trim();
    if (trimmed !== discount.name) {
      onChange({ ...discount, name: trimmed });
    }
  };

  const handleValueChange = (newValue: number) => {
    if (newValue !== discount.value) {
      onChange({ ...discount, value: newValue });
    }
  };

  const handleTypeChange = (newType: string) => {
    if (newType !== discount.type) {
      onChange({ ...discount, type: newType as QuoteDiscountType, value: 1 });
    }
  };

  return (
    <TableRow
      onMouseEnter={showActionPanel}
      onMouseLeave={hideActionPanel}
      data-testid={`discounts-table-row-${index}`}
    >
      <TableCell>
        <CellInputWithReadonly
          data-testid="discounts-table-row-name"
          onBlur={handleNameChange}
          forcedValue={discount.name}
          inputProps={{ 'data-testid': 'discounts-table-name-input' }}
          readonly={readonly}
          readonlyValue={discount.name}
          textAlign="left"
        />
      </TableCell>
      <TableCell>
        <CellSelectInputWithReadonly
          data-testid="discounts-table-row-select"
          value={discount.type}
          onChange={handleTypeChange}
          selectProps={{ 'data-testid': 'discounts-table-type-select' } as any}
          readonly={readonly}
          readonlyValue={`${t(discountTypeLabelMap[discount.type])}`}
        >
          <MenuItem
            value={QuoteDiscountType.FixedAmount}
            data-testid="discounts-table-type-fixedAmount"
          >
            <Typography fontSize="inherit">{t('common.fixedAmount')}</Typography>
          </MenuItem>
          <MenuItem
            value={QuoteDiscountType.Percentage}
            data-testid="discounts-table-type-percentage"
          >
            <Typography data-testid="discounts-table-row-percentage" fontSize="inherit">
              {t('common.percentage')}
            </Typography>
          </MenuItem>
        </CellSelectInputWithReadonly>
      </TableCell>
      <TableCell>
        {discount.type === QuoteDiscountType.FixedAmount && (
          <CellMoneyInputWithReadonly
            value={discount.value}
            currency={discount.price.currency}
            onBlur={handleValueChange}
            inputProps={{
              sx: { textAlign: 'right' },
              'data-testid': 'discounts-table-amount-input',
            }}
            readonly={readonly}
            readonlyValue={formatPrice(discount.value, discount.price.currency)}
            textAlign="left"
            prefix="-"
          />
        )}
        {discount.type === QuoteDiscountType.Percentage && (
          <CellPercentageInputWithReadonly
            value={discount.value}
            onBlur={handleValueChange}
            inputProps={{
              sx: { textAlign: 'right' },
              'data-testid': 'discounts-table-percentage-input',
            }}
            readonly={readonly}
            readonlyValue={formatPercentage(discount.value)}
            textAlign="right"
            prefix="-"
          />
        )}
      </TableCell>
      {!readonly ? <RowActionsPanel anchorElement={anchorElement} onDelete={handleDelete} /> : null}
    </TableRow>
  );
};
