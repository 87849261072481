import React from 'react';
import { FlexColumn, Typography, useTranslation } from '@clippings/paper';

export function ProductDiscountsTitle() {
  const { t } = useTranslation();

  return (
    <FlexColumn>
      <Typography variant="subtitle2">{t('companies.productDiscountsLabel')}</Typography>
      <Typography variant="caption">{t('companies.productDiscountsDescription')}</Typography>
    </FlexColumn>
  );
}
