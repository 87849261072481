import React from 'react';
import { Order } from 'libs/api/order/types';
import { Skeleton } from '@clippings/paper';
import { SummaryView } from '../../Quotes/SummaryView';
import { SummaryViewItem } from 'libs/Quotes/Components/TotalsSummary/TotalsSummaryShippingRow';
import { useOrder } from 'libs/api/order/hooks';

type OrderSummaryProps = {
  number: string;
};

export const OrderSummary: React.FC<OrderSummaryProps> = ({ number }) => {
  const orderQuery = useOrder(number);

  if (!orderQuery.isSuccess) {
    return (
      <Skeleton
        sx={skeletonSx}
        height="250px"
        variant="rectangular"
        data-testid="order-summary-loader"
      />
    );
  }

  return (
    <SummaryView
      item={mapOrderItem(orderQuery.data)}
      paymentTerms={orderQuery.data.paymentSchedule}
      paymentTermsLoading={false}
      readonly
      renderPaymentTerms={orderQuery.data.total.amount > 0}
    />
  );
};

function mapOrderItem(order: Order): SummaryViewItem {
  return {
    ...order,
    contractItemsSubTotal: null,
    discountItems: order.discountItems.map(item => ({
      id: item.id,
      name: item.name,
      price: item.amount,
    })),
    deliveryItems: order.deliveryItems.map(item => ({
      ...item,
      custom: true,
      deliveryRef: '',
      shippingTime: [0, 0],
      tax: { amount: 0, currency: '' },
      taxPercentage: 0,
      type: '' as any, // not used so value doesn't matter
    })),
    metadata: order.metadata?.customDeliveryDate
      ? { customDeliveryDate: order.metadata.customDeliveryDate }
      : null,
  };
}

const skeletonSx = {
  width: {
    xs: '100%',
    md: '500px',
  },
  alignSelf: 'end',
};
