import React, { useState } from 'react';
import axios from 'axios';
import { AuthenticationImage } from '../AuthenticationImage';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { ForgotPasswordForm, ForgotPasswordFormValues, mapApiToFormErrors } from '@clippings/paper';
import { resetPassword } from 'libs/api/iam/actions';
import { routes } from 'libs/Constants';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const ForgotPassword = ({ ...rest }) => {
  const { t } = useTranslation();
  useTitle(t('common.resetPassword'));

  const expiredLinkError = { error: { type: 'server', message: t('common.linkExpired') } };
  const [searchParams] = useSearchParams();
  const isResetLinkExpired = searchParams.get('expired')?.toLowerCase() === '1';
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formResetPasswordErrors, setFormResetPasswordErrors] = useState(
    isResetLinkExpired ? expiredLinkError : {}
  );
  const navigate = useNavigate();
  const resetPasswordMutation = useMutation(resetPassword, {
    onError(error) {
      let errorObject = {};

      if (axios.isAxiosError(error) && error.response) {
        errorObject =
          error.response.status === 429
            ? {
                error: {
                  type: 'server',
                  message: {
                    key: 'common.passwordLinkWasAlreadySentPleaseTryAgain',
                    params: { minutes: 5 },
                  },
                },
              }
            : mapApiToFormErrors(error.response);
      }

      setFormResetPasswordErrors(errorObject);
    },
    onSuccess() {
      setFormResetPasswordErrors({});
      setShowConfirmation(true);
    },
  });

  const handleSignInClick = () => {
    navigate(routes.authentication.login);
  };

  const handleResetPassword = ({ email }: ForgotPasswordFormValues) => {
    resetPasswordMutation.mutate(email);
  };

  return (
    <AuthenticationLayout
      content={
        <ForgotPasswordForm
          onSubmit={handleResetPassword}
          loading={resetPasswordMutation.isLoading || resetPasswordMutation.isSuccess}
          showConfirmation={showConfirmation}
          serverErrors={formResetPasswordErrors}
          onSignInClick={handleSignInClick}
          onResendEmail={handleResetPassword}
          {...rest}
        />
      }
      sidePanel={<AuthenticationImage />}
    />
  );
};
