import React from 'react';
import { BaseUser } from 'libs/api/common/types';
import { Box, Breadcrumbs, Link, Typography } from '@clippings/paper';
import { NeedHelp } from 'libs/Orders/components/NeedHelp';
import { Link as RouterLink } from 'react-router-dom';
import { orderQueryKeys } from 'libs/api/order/hooks';
import { useChatVisibility } from 'libs/Chat';
import { useIsFetching } from '@tanstack/react-query';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  number: string;
  salesRep?: BaseUser;
}

export const Header: React.FC<HeaderProps> = ({ number, salesRep }) => {
  const { t } = useTranslation();
  const isFetching = useIsFetching({ queryKey: orderQueryKeys.getUseOrder(number) });
  const { onToggle: toggleChat } = useChatVisibility();
  const { getOrderShowPath, getOrdersListPath } = useOrderPaths();

  return (
    <Box
      data-testid="order-payment-header"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={2}
    >
      <Breadcrumbs>
        <Link
          underline="none"
          variant="body2"
          to={getOrdersListPath(number)}
          component={RouterLink}
          color="text.secondary"
          data-testid="order-payment-header-link-orders"
        >
          {t('common.orders')}
        </Link>
        <Link
          underline="none"
          variant="body2"
          to={getOrderShowPath(number)}
          component={RouterLink}
          color="text.secondary"
          data-testid="order-payment-header-link-order"
        >
          {t('orders.orderNumber')}
          {number}
        </Link>
        <Typography
          data-testid="order-payment-header-make-payment"
          variant="body2"
          color="text.primary"
        >
          {t('common.makePayment')}
        </Typography>
      </Breadcrumbs>
      <NeedHelp loading={Boolean(isFetching)} toggleChat={toggleChat} salesRep={salesRep} />
    </Box>
  );
};
