import QuoteListHeader from './QuoteListHeader';
import React, { useContext } from 'react';
import { Column } from 'libs/shared';
import { QuoteListActiveQuotesSection } from './QuoteListActiveQuotesSection';
import { QuoteListArchivedQuotesSection } from './QuoteListArchivedQuotesSection';
import { QuoteListContext } from './QuoteListContext';
import { QuoteListMobile } from './QuoteListMobile';
import { ShowElement } from '@clippings/paper';

export const QuoteListView = () => {
  const { archivedQuotes, archivedQuotesLoading } = useContext(QuoteListContext);

  return (
    <Column gap={4} p={{ xs: 2, md: 3 }}>
      <QuoteListHeader />
      <ShowElement below="md">
        <QuoteListMobile hasArchivedQuotes={!archivedQuotesLoading && archivedQuotes?.length > 0} />
      </ShowElement>
      <ShowElement above="md">
        <>
          <QuoteListActiveQuotesSection />
          {!archivedQuotesLoading && archivedQuotes?.length > 0 ? (
            <QuoteListArchivedQuotesSection />
          ) : null}
        </>
      </ShowElement>
    </Column>
  );
};
