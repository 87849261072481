import React from 'react';
import { Box, Chip, Grid, Typography, useTranslation } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { CompanySalesRepSelect } from './CompanySalesRepSelect/CompanySalesRepSelect';
import { SalesRepSelectLabel } from './SalesRepSelectLabel';

type HeaderProps = {
  company: Company;
};

export function Header({ company }: HeaderProps) {
  const { t } = useTranslation();

  return (
    <Grid item xs={4} my={3} display="flex" justifyContent="space-between">
      <Typography variant="h5" fontWeight="bold">
        {company.name}
      </Typography>
      <Box display="flex" flexWrap="nowrap" alignContent="center" gap={1}>
        {!company.editable && (
          <Chip
            variant="outlined"
            label={
              <SalesRepSelectLabel
                prefix={t('common.assignedTo')}
                user={
                  company.salesRepresentative && {
                    ...company.salesRepresentative,
                    team: company.team,
                  }
                }
              />
            }
          />
        )}
        {company.editable && <CompanySalesRepSelect company={company} />}
      </Box>
    </Grid>
  );
}
