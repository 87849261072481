import { Quote } from 'libs/api/quotes/types';
import { queryKeys } from 'libs/Quotes/quoteActions';
import { useQueryClient } from '@tanstack/react-query';
import { useQuoteVersionNumber } from 'libs/Quotes/hooks/useQuoteVersionNumber';

export const onMutateSetQueryDataCallback =
  (data: Partial<Quote>) => (prev?: Partial<Quote> | undefined) => ({
    ...prev,
    ...data,
  });

export const useDefaultQuoteMutations = (queryKey: string[], setError?: (err: unknown) => void) => {
  const queryClient = useQueryClient();
  const [version, setVersion] = useQuoteVersionNumber();

  const handleMutate = async (data: Partial<Quote>) => {
    await queryClient.cancelQueries(queryKey);
    const previousQuote = queryClient.getQueryData<Quote>(queryKey)!;
    queryClient.setQueryData<Partial<Quote>>(queryKey, onMutateSetQueryDataCallback(data));

    return { previousQuote };
  };

  const handleError = (error: unknown, _variables: unknown, context: any) => {
    if (version && version !== context.previousQuote.versionNumber) {
      setVersion(context.previousQuote.versionNumber);
    }
    queryClient.setQueryData(queryKey, context.previousQuote);
    setError?.(error);
  };

  const handleSuccess = (data: Quote, _variables: unknown, context: any) => {
    const isAnotherMutationRunning = queryClient.isMutating({ mutationKey: queryKey }) > 1;

    // Prevent race conditions between optimistic updates and responses
    if (!isAnotherMutationRunning) {
      queryClient.setQueryData(queryKey, data);
    }

    if (version && version !== data.versionNumber) {
      setVersion(data.versionNumber);
    }

    if (context.previousQuote.total.amount !== data.total.amount) {
      queryClient.invalidateQueries(queryKeys.getPaymentTerms(data.versionId));
    }
  };

  return {
    onMutate: handleMutate,
    onError: handleError,
    onSuccess: handleSuccess,
  };
};
