import React from 'react';
import { CatalogsProvider } from 'libs/catalogs';
import { ChatChannelProvider } from 'libs/Chat';
import { GuestQuoteViewContent, RegistrationPromptModal } from './components';
import { QuoteProvider } from 'libs/Quotes/providers';
import { QuoteView } from 'libs/Quotes/quoteView/QuoteView';

export function GuestQuotePage() {
  return (
    <CatalogsProvider>
      <QuoteProvider>
        {number => (
          <>
            <ChatChannelProvider number={number}>
              <QuoteView>
                <GuestQuoteViewContent />
              </QuoteView>
            </ChatChannelProvider>
            <RegistrationPromptModal />
          </>
        )}
      </QuoteProvider>
    </CatalogsProvider>
  );
}
