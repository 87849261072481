import React from 'react';
import { Backdrop, CircularProgress } from '@clippings/paper';

export function CheckoutLoading() {
  return (
    <Backdrop sx={backdropSx} open>
      <CircularProgress />
    </Backdrop>
  );
}

const backdropSx = {
  color: '#fff',
  zIndex: (theme: any) => theme.zIndex.drawer + 1,
};
