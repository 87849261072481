import React, { Fragment } from 'react';
import { ForgotPassword } from './forgotPassword/ForgotPassword';
import { Login } from './Login/Login';
import { NewPassword } from './NewPassword/NewPassword';
import { Register } from './Register/Register';
import { Route } from 'react-router-dom';
import { routes } from 'libs/Constants';

export const authenticationRoutes = (
  <>
    <Route path={routes.authentication.login} element={<Login />} />
    <Route path={routes.authentication.resetPassword} element={<ForgotPassword />} />
    <Route path={routes.authentication.newPassword} element={<NewPassword />} />
    <Route path={routes.authentication.register} element={<Register />} />
  </>
);
