import React, { ComponentProps, FC, ReactNode } from 'react';
import classNames from 'classnames';
import { LinkProps, NavLink, useLocation } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@clippings/paper';
import './NavItem.scss';

type ChildrenFn = ({ isActive }: { isActive: boolean }) => ReactNode;

interface NavItemChildrenProps extends Omit<ComponentProps<typeof ListItem>, 'children'> {
  to?: LinkProps['to'];
  icon?: ReactNode;
  children?: ChildrenFn;
  label: any;
  className?: string;
  pageReload?: any;
  endIcon?: ReactNode;
}

export const NavItem: FC<NavItemChildrenProps> = ({
  children,
  to = '',
  icon = null,
  label,
  className = '',
  pageReload = false,
  endIcon = null,
  ...rest
}) => {
  const location = useLocation();
  const isActive = to === location.pathname;

  return (
    <ListItem className={classNames(className, 'navigation-item')} {...rest}>
      <ListItemButton
        to={to}
        component={pageReload ? 'a' : NavLink}
        className="navigation-item__button"
        selected={isActive}
      >
        {icon && (
          <ListItemIcon sx={{ ...(isActive ? { color: 'primary.main' } : {}) }}>
            {icon}
          </ListItemIcon>
        )}

        <ListItemText
          primary={label}
          primaryTypographyProps={{
            fontSize: 14,
            color: isActive ? 'primary' : 'inherit',
          }}
        />
        {endIcon}
        {children?.({ isActive })}
      </ListItemButton>
    </ListItem>
  );
};
