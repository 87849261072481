import React, { useMemo, useRef, useState } from 'react';
import { CreateUserButton } from '../components/CreateUserButton/CreateUserButton';
import { DecodedValueMap, useQueryParams } from 'use-query-params';
import { DialogAction, SystemUser } from '@clippings/paper';
import { EmptyUserTablePlaceholder } from '../components/EmptyUserTablePlaceholder/EmptyUserTablePlaceholder';
import { PageHeader } from 'libs/SalesApp/components/PageHeader';
import { StaffUserPermission } from '../utils/user.utils';
import { StaffUsersListTable, cellConfig } from './components';
import { User } from 'libs/api/iam/types';
import { UserAlertSnackbar } from '../customer/components/UserAlertSnackbar';
import { UserDialog } from '../components/userDialog/UserDialog';
import {
  makeColumns,
  makeQueryParamsMap,
  useIsCurrentUserAdmin,
  useTableSorting,
} from 'libs/shared';
import { useListInfiniteUsersV2 } from 'libs/api/iam/hooks';
import { useSearch } from '../../Header/useSearch';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useUserUtils } from '../hooks/useUserUtils';

const columns = makeColumns(cellConfig);
const queryParamsMap = makeQueryParamsMap(columns);
type UsersQueryParams = Partial<DecodedValueMap<typeof queryParamsMap>>;

export const StaffUsersPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useSearch();
  const selectedUser = useRef<User | undefined>();
  const selectedDialogMode = useRef<DialogAction>(DialogAction.Create);
  const isAdmin = useIsCurrentUserAdmin();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams(queryParamsMap);
  const { sort, updateSorting } = useTableSorting<UsersQueryParams>(queryParams, setQueryParams);

  const { newlyCreatedUser, newlyUpdatedUser, setNewlyCreatedUser, setNewlyUpdatedUser } =
    useUserUtils();

  useTitle(t('common.staffUsers'));

  const onStaffUserClick = (user: User) => {
    selectedDialogMode.current = DialogAction.Edit;
    selectedUser.current = user;
    setIsUserModalOpen(true);
  };

  const onCreateClick = () => {
    if (!isAdmin) return;

    selectedDialogMode.current = DialogAction.Create;
    selectedUser.current = undefined;
    setIsUserModalOpen(true);
  };

  const onDialogClose = () => {
    selectedUser.current = undefined;
    setIsUserModalOpen(false);
  };

  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useListInfiniteUsersV2({
      query: searchValue,
      permissions: StaffUserPermission.CAN_ACCESS_SALES_APP,
      sortBy: sort.sort,
      sortOrder: sort.order,
    });

  const users = useMemo(() => {
    return (data?.pages ?? []).flat();
  }, [data]);

  return (
    <>
      {/* Created user snackbar */}
      <UserAlertSnackbar
        open={!!newlyCreatedUser}
        onClose={() => setNewlyCreatedUser(null)}
        message={t('common.userWasSuccessfullyCreated', { userName: newlyCreatedUser?.firstName })}
      />
      {/* Updated user snackbar */}
      <UserAlertSnackbar
        open={!!newlyUpdatedUser}
        onClose={() => setNewlyUpdatedUser(null)}
        message={t('users.userUpdatedSuccessfully')}
      />

      <PageHeader title={t('common.staffUsers')} titleTestId="staff-users-page-title">
        {isAdmin && (
          <CreateUserButton
            data-testid="create-staff-user-button"
            onClick={onCreateClick}
            label={t('users.newStaffUser')}
          />
        )}
      </PageHeader>

      {!users.length && !isLoading ? (
        <EmptyUserTablePlaceholder
          filtered={!!searchValue}
          onCreate={onCreateClick}
          onReset={() => setSearchValue('')}
          isUserAdmin={isAdmin}
          type={SystemUser.Staff}
        />
      ) : (
        <StaffUsersListTable
          users={users}
          isLoading={isLoading}
          hasNextPage={!!hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          data-testid="sa-staff-users-table"
          sort={sort}
          onSort={updateSorting}
          onFetchNextPage={fetchNextPage}
          onUserClick={onStaffUserClick}
        />
      )}

      <UserDialog
        type={SystemUser.Staff}
        user={selectedUser.current}
        action={selectedDialogMode.current}
        isOpen={isUserModalOpen}
        onClose={onDialogClose}
        onCreateSuccess={user => {
          setNewlyCreatedUser(user);
          setIsUserModalOpen(false);
        }}
        onUpdateSuccess={user => {
          setNewlyUpdatedUser(user);
          setIsUserModalOpen(false);
        }}
      />
    </>
  );
};
