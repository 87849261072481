import React from 'react';
import { Brand, Typography } from '@clippings/paper';
import { NewQuoteButton } from '../Components/NewQuoteButton';
import { QuoteXmlImportButton } from 'libs/Quotes/xmlImport';
import { Row } from 'libs/shared';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

const QuoteListHeader = () => {
  const { brand } = useAppConfiguration();
  const { t } = useTranslation();

  return (
    <Row justifyContent="space-between" data-testid="quote-list-header">
      <Typography variant="h5">{t('common.quotes')}</Typography>
      <Row gap={2}>
        <NewQuoteButton />
        {brand === Brand.Fomcore ? <QuoteXmlImportButton /> : null}
      </Row>
    </Row>
  );
};

export default QuoteListHeader;
