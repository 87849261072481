import React from 'react';
import { DateTime, DateTimeFormat, Monetary } from '@clippings/paper';
import { DividerGrey, ItemRow, ListCardItemBaseViewModel, ListItemCard } from 'libs/shared';
import { Money } from 'libs/api/common/types';
import { OrderStatus } from 'libs/api/order/types';
import { OrderStatusChip } from 'libs/Orders/components/OrderStatusChip';
import { useNavigate } from 'react-router-dom';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useTranslation } from 'react-i18next';

export type OrderListCardItemViewModel = ListCardItemBaseViewModel & {
  createdAt: string;
  value: Money;
  status: OrderStatus;
  internalNumber: string;
};

export type ListItemCardProps = {
  item: OrderListCardItemViewModel;
};

export function OrderListItemCard({ item }: ListItemCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getOrderShowPath } = useOrderPaths();

  function clickHandler() {
    navigate(getOrderShowPath(item.internalNumber));
  }

  return (
    <ListItemCard<OrderListCardItemViewModel> item={item} onClick={clickHandler}>
      <ItemRow
        label={t('orders.orderPlaced')}
        value={
          <DateTime
            format={DateTimeFormat.Date}
            date={item.createdAt}
            variant="body2"
            fontWeight="fontWeightMedium"
          />
        }
      />
      <DividerGrey sx={{ my: 1 }} />
      <ItemRow
        label={t('common.value')}
        value={
          <Monetary
            fontWeight="fontWeightMedium"
            amount={item.value.amount}
            currency={item.value.currency}
          />
        }
      />
      <DividerGrey />
      <ItemRow label={t('common.status')} value={<OrderStatusChip status={item.status} />} />
    </ListItemCard>
  );
}
