import React, { PropsWithChildren } from 'react';
import { AddNewEntityButton } from 'libs/shared';
import { DataGrid, FlexColumn, useTranslation } from '@clippings/paper';

export interface QuoteDiscountTableProps {
  onCreateNewDiscount: () => void;
}

export const QuoteDiscountTable: React.FC<PropsWithChildren<QuoteDiscountTableProps>> = ({
  children,
  onCreateNewDiscount,
}) => {
  const { t } = useTranslation();

  return (
    <FlexColumn gap={2}>
      <DataGrid data-testid="salesapp-companies-editor-quote-discounts">{children}</DataGrid>
      <AddNewEntityButton
        data-testid="quote-discounts-add-new-button"
        text={`${t('common.addNew')} ${t('companies.quoteDiscountLabel')}`}
        onClick={onCreateNewDiscount}
      />
    </FlexColumn>
  );
};
