import React from 'react';
import { Box, Typography } from '@clippings/paper';
import { PropertyRow, PropertyType } from '../components/PropertyRow';
import { QuoteItemProperty } from 'libs/api/quotes/types';
import { getFormattedProps } from 'libs/shared/quote.utils';
import { useShouldIncludePropertyCode } from './useShouldIncludePropertyCode';

export function useItemFormattedProperties(properties: QuoteItemProperty[]) {
  const formattedProperties = getFormattedProps(properties ?? []);

  return { properties: formattedProperties, FormattedPropertiesRenderer };
}

type FormattedPropertiesRendererProps = {
  properties: ReturnType<typeof getFormattedProps>;
  textOnly: boolean;
};

function FormattedPropertiesRenderer({ properties, textOnly }: FormattedPropertiesRendererProps) {
  const { shouldIncludePropertyCode } = useShouldIncludePropertyCode();

  return (
    <>
      {Object.keys(properties).map(key => {
        return (
          <Box key={key}>
            <Box display="inline">
              <Typography variant="caption" color="text.secondary" display="inline">
                {key}
              </Typography>
              {properties[key].map((property, i) => (
                <PropertyRow
                  key={`property-${i}`}
                  property={property}
                  initialType={textOnly ? PropertyType.Text : PropertyType.Chip}
                  renderPropertyCode={shouldIncludePropertyCode}
                />
              ))}
            </Box>
          </Box>
        );
      })}
    </>
  );
}
