import React from 'react';
import { TableCell, TableHead, TableRow } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export function QuoteDiscountTableHead() {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell width="33%">{t('companies.discountHeaderAmount')}</TableCell>
        <TableCell width="33%">{t('companies.discountHeaderAmountAbove')}</TableCell>
        <TableCell>{t('common.catalogues')}</TableCell>
      </TableRow>
    </TableHead>
  );
}
