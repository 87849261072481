import React from 'react';
import { CloseIcon, IconButton } from '@clippings/paper';

type CloseButtonProps = {
  onClick: () => void;
};

export function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <IconButton
      sx={{
        margin: theme => `-${theme.spacing(1)}`,
      }}
      onClick={onClick}
      data-testid="plp-close-button"
    >
      <CloseIcon />
    </IconButton>
  );
}
