import React, { forwardRef } from 'react';
import { Box, Button, Chip, FlexColumn, Monetary, ShowElement, Typography } from '@clippings/paper';
import { Link } from 'react-router-dom';
import { OrderStatus } from 'libs/api/order/types';
import { Payment, PaymentStatus } from 'libs/api/common/types';
import { PaymentScheduleSkeleton } from './Skeletons';
import {
  useBuildPercentageLabel,
  useFormatDetails,
  useFormatType,
} from 'libs/Quotes/Components/TotalsSummary/paymentHelpers';
import { useOrder } from 'libs/api/order/hooks';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useTranslation } from 'react-i18next';

type OrderPaymentScheduleProps = {
  number: string;
};

export const OrderPaymentSchedule = forwardRef<HTMLDivElement, OrderPaymentScheduleProps>(
  ({ number }, ref) => {
    const { formatType } = useFormatType();
    const orderQuery = useOrder(number);
    const { t } = useTranslation();
    const buildPercentageLabel = useBuildPercentageLabel();
    const { formatDetails } = useFormatDetails();
    const { getOrderPaymentPath } = useOrderPaths();

    const buildChipLabel = (payment: Payment): string =>
      [formatType(payment), formatDetails(payment)].filter(x => !!x).join(' • ');

    if (orderQuery.isFetching) {
      return (
        <FlexColumn ref={ref} gap={2}>
          <PaymentScheduleSkeleton />
        </FlexColumn>
      );
    }

    if (orderQuery.isError) {
      return (
        <FlexColumn ref={ref} gap={2}>
          <Typography variant="h5">{t('checkout.paymentSchedule.title')}</Typography>
          <Typography variant="body1">{t('chat.generalError')}</Typography>
        </FlexColumn>
      );
    }

    return (
      <>
        {orderQuery.isSuccess && orderQuery.data.total.amount > 0 ? (
          <FlexColumn ref={ref} gap={2}>
            <Typography variant="h5">{t('checkout.paymentSchedule.title')}</Typography>
            <Box
              display="grid"
              gridAutoRows={styles.gridContainer.gridAutoRows}
              border="1px solid"
              borderRadius={1}
              bgcolor="common.white"
              borderColor="grey.A200"
              sx={styles.gridContainer.sx}
            >
              {orderQuery.data.paymentSchedule.map((payment, idx) => {
                const isPaid = payment.status === PaymentStatus.Paid;

                return (
                  <Box
                    key={payment.id}
                    display="grid"
                    gridTemplateColumns={styles.gridInner.gridTemplateColumns}
                    columnGap={2}
                    alignItems="center"
                    height="100%"
                    px={2}
                    py={styles.gridInner.py}
                  >
                    <Box sx={styles.rowContainer.sx}>
                      <Typography color="text.secondary" variant="subtitle2">
                        {buildPercentageLabel(payment, idx + 1)}
                      </Typography>
                      <ShowElement below="md">
                        <Box justifySelf="end">
                          <Monetary
                            amount={payment.amount.amount}
                            currency={payment.amount.currency}
                            display="inline"
                            variant="subtitle2"
                          />
                        </Box>
                      </ShowElement>
                    </Box>
                    <Box>
                      <Chip
                        size="small"
                        label={buildChipLabel(payment)}
                        variant={isPaid ? 'filled' : 'outlined'}
                        color={isPaid ? 'success' : 'default'}
                      />
                    </Box>
                    <ShowElement above="md">
                      <Box justifySelf="end">
                        <Monetary
                          amount={payment.amount.amount}
                          currency={payment.amount.currency}
                          display="inline"
                          variant="subtitle2"
                        />
                      </Box>
                    </ShowElement>
                    <Box mt={styles.paid.mt}>
                      {isPaid ? (
                        <Button variant="contained" disabled sx={buttonStyles}>
                          {t('common.paid')}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={buttonStyles}
                          component={Link}
                          disabled={orderQuery.data.status === OrderStatus.Cancelled}
                          to={getOrderPaymentPath(number, payment.id)}
                          data-testid={`make-payment-button-${idx}`}
                        >
                          {t('common.makePayment')}
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </FlexColumn>
        ) : null}
      </>
    );
  }
);

const buttonStyles = { width: 140 };

const styles = {
  gridContainer: {
    sx: {
      '> div:not(:last-child)': { borderBottom: '1px solid', borderColor: 'grey.A200' },
    },
    gridAutoRows: {
      md: '68px',
    },
  },
  gridInner: {
    gridTemplateColumns: {
      sm: '130px 1fr 1fr auto',
    },
    py: {
      xs: 2,
      sm: 0,
    },
  },
  rowContainer: {
    sx: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  paid: {
    mt: {
      xs: 2,
      sm: 0,
    },
  },
};
