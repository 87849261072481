import React from 'react';
import { AlertSnackbar, Button, Typography, useTranslation } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useNavigate } from 'react-router-dom';

type CompanyCreatedSuccessfullyNotificationProps = {
  company: Company | undefined;
  onClose: () => void;
};

export function CompanyCreatedSuccessfullyNotification({
  company,
  onClose,
}: CompanyCreatedSuccessfullyNotificationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO: reconsider this approach
  const navigateToNewlyCreatedCompany = () => {
    if (company) {
      navigate(path(routes.salesApp.company, { companyId: company.id }));
    }
  };

  const isOpen = Boolean(company);

  return (
    <AlertSnackbar
      data-testid="company-tables-snackbar-alert"
      open={isOpen}
      onClose={onClose}
      message={
        <Typography sx={{ color: theme => theme.palette.secondary.dark }}>
          {company &&
            t('companies.companyWasSuccessfullyCreated', {
              company: company.name,
            })}
        </Typography>
      }
      action={
        <Button
          sx={{ color: theme => theme.palette.secondary.dark }}
          onClick={navigateToNewlyCreatedCompany}
          variant="text"
        >
          {t('common.configure')}
        </Button>
      }
    />
  );
}
