import React, { FC } from 'react';
import { Box, Button, PersonAddIcon, Tooltip } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { ShareModal } from 'libs/Quotes/Components/ShareModal/ShareModal';
import { useQuoteShare } from 'libs/Quotes/Components/ShareModal/QuoteShareProvider';
import { useTranslation } from 'react-i18next';

export type ShareModalButtonProps = {
  quote: Quote;
  loading: boolean;
  disabled?: boolean;
};

export const ShareModalButton: FC<ShareModalButtonProps> = ({
  quote,
  loading,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const {
    modal: { setShareModalOpen },
  } = useQuoteShare();

  return (
    <>
      <Tooltip
        title={t('quotes.share.shareDisabledMessage')}
        placement="top"
        disableHoverListener={!disabled}
      >
        <Box>
          <Button
            disabled={loading || disabled}
            data-testid="quote-share-button"
            variant="contained"
            sx={{
              color: theme => theme.palette.common.white,
            }}
            color="secondary"
            startIcon={<PersonAddIcon sx={{ color: theme => theme.palette.common.white }} />}
            onClick={() => setShareModalOpen(true)}
          >
            {t('common.share')}
          </Button>
        </Box>
      </Tooltip>
      <ShareModal quote={quote} />
    </>
  );
};
