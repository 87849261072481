import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Box } from '@clippings/paper';
import './LayoutContent.scss';

export const LayoutContent: FC<PropsWithChildren<ComponentProps<typeof Box>>> = ({
  className = '',
  children,
  ...rest
}): JSX.Element => (
  <Box
    display="grid"
    overflow="auto"
    gridArea="content"
    width="100%"
    height="100%"
    bgcolor="grey.50"
  >
    <Box className={classNames('layout-content', className)} bgcolor="grey.50" {...rest}>
      {children}
    </Box>
  </Box>
);
