import React from 'react';
import { BaseUserFormValues, UserDialogFormProps } from '../../utils/user.utils';
import { Company } from 'libs/api/iam/types';
import { DialogAction, SystemUser } from '@clippings/paper';
import { UserDialogFormBase } from './UserDialogFormBase';
import { useUserHandlers } from 'libs/SalesApp/Users/hooks';

export interface CustomerUserFormValues extends BaseUserFormValues {
  company: Company;
}

export const UserDialogForm: React.FC<UserDialogFormProps> = ({
  action,
  onCancel,
  userId,
  defaultValues = {},
  hiddenFields,
  disabledFields,
  ...rest
}) => {
  const isEditMode = action === DialogAction.Edit;

  const { userCreateHandler, createUserErrors, userUpdateHandler, updateUserErrors, isSubmitting } =
    useUserHandlers({ ...rest });

  const handleSubmit = (data: CustomerUserFormValues) => {
    if (isEditMode && userId) {
      userUpdateHandler(userId, data);
    } else {
      userCreateHandler(data);
    }
  };

  return (
    <UserDialogFormBase
      createUserErrors={createUserErrors}
      updateUserErrors={updateUserErrors}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      defaultValues={defaultValues}
      action={action}
      type={SystemUser.Customer}
      hiddenFields={hiddenFields}
      disabledFields={disabledFields}
    />
  );
};
