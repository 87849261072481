import React, { FC } from 'react';
import { BaseUser } from 'libs/api/common/types';
import { Box, Button, CircularProgress, Typography } from '@clippings/paper';
import { ShareeDetailedUserAvatar } from './ShareeDetailedUserAvatar';
import { useRemoveQuoteSharee } from 'libs/api/quotes/hooks';
import { useTranslation } from 'react-i18next';

export type QuoteShareeProps = {
  quoteNumber: string;
  sharee: BaseUser;
  isOwner?: boolean;
  isGuest?: boolean;
  isCurrentUser?: boolean;
  removable?: boolean;
  onRemoveSuccessHandler: (id: string) => void;
  onRemoveErrorHandler: () => void;
};

export const QuoteSharee: FC<QuoteShareeProps> = ({
  quoteNumber,
  sharee,
  isOwner,
  isGuest,
  isCurrentUser,
  removable,
  onRemoveErrorHandler,
  onRemoveSuccessHandler,
}) => {
  const { t } = useTranslation();

  const { isLoading, mutate } = useRemoveQuoteSharee(quoteNumber, {
    onSuccess: (_, shareeId) => {
      onRemoveSuccessHandler(shareeId);
    },
    onError: () => {
      onRemoveErrorHandler();
    },
  });

  const renderAction = () => {
    if (isOwner) {
      return (
        <Typography variant="body2" fontWeight={500} mr={1}>
          {t('quotes.share.owner')}
        </Typography>
      );
    }

    if (isLoading) {
      return <CircularProgress sx={{ mr: 3 }} size={20} />;
    }

    if (removable) {
      return (
        <Button
          data-testid="button-remove-sharee"
          variant="text"
          color="primary"
          onClick={() => mutate(String(id))}
        >
          {t('quotes.share.remove')}
        </Button>
      );
    }
  };

  const { id } = sharee;

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <ShareeDetailedUserAvatar isCurrentUser={isCurrentUser} user={sharee} />
      <Box display="flex" flexDirection="row" gap={1.5}>
        {isGuest && (
          <Typography
            alignSelf="center"
            component="span"
            sx={{
              fontSize: theme => theme.typography.pxToRem(12),
              color: theme => theme.palette.grey[500],
            }}
          >
            {t('quotes.share.guestAccess')}
          </Typography>
        )}
        {renderAction()}
      </Box>
    </Box>
  );
};
