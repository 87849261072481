import React from 'react';
import { Address, BillingAddress } from 'libs/api/common/types';
import { Box } from '@clippings/paper';
import { QuoteInfoLabel } from './QuoteInfoLabel';
import { useTranslation } from 'react-i18next';

interface QuoteAddressProps {
  labelText: string;
  address: Address | null;
}

export function QuoteAddress({ address, labelText }: QuoteAddressProps) {
  const { t } = useTranslation();
  const isBillingAddress = isBillingAddressTypeGuard(address);

  return (
    <Box>
      <QuoteInfoLabel bold text={labelText} />
      {address ? (
        <>
          <QuoteInfoLabel text={address.contactName} />
          {isBillingAddress ? <QuoteInfoLabel text={address.companyName} /> : null}
          <QuoteInfoLabel text={address.address} />
          {address.address2 ? <QuoteInfoLabel text={address.address2} /> : null}
          {address.city ? <QuoteInfoLabel text={`${address.city}, ${address.zip}`} /> : null}
          {address.state ? <QuoteInfoLabel text={address.state?.name} /> : null}
          {address.country ? <QuoteInfoLabel text={address.country?.name} /> : null}
          {isBillingAddress ? (
            <QuoteInfoLabel text={`${t('quotes.pdf.info.vat')}: ${address?.vatNumber ?? ''}`} />
          ) : null}
        </>
      ) : null}
    </Box>
  );
}

function isBillingAddressTypeGuard(valueToTest: any): valueToTest is BillingAddress {
  return (
    !!valueToTest &&
    typeof valueToTest === 'object' &&
    'vatNumber' in valueToTest &&
    'companyName' in valueToTest
  );
}
