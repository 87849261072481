import React, { useMemo } from 'react';
import { AddIcon, AlertSnackbar, Box, Button } from '@clippings/paper';
import { DividerGrey } from 'libs/shared';
import { EmptyTeamsTablePlaceholder } from '../EmptyTeamsTablePlaceholder/EmptyTeamsTablePlaceholder';
import { ListTable, ListTableBody, ListTableHeader, ListTableHeaderCell } from 'libs/Components';
import { PageHeader } from 'libs/SalesApp/components/PageHeader';
import { TeamCreateForm } from '../TeamDialog/TeamCreateForm';
import { TeamDialog } from '../TeamDialog/TeamDialog';
import { TeamEditForm } from '../TeamDialog/TeamEditForm';
import { TeamsTableItem } from '../TeamsTableItem/TeamsTableItem';
import { useListInfiniteTeamsV2 } from 'libs/api/iam/hooks';
import { useSearch } from 'libs/SalesApp/Header/useSearch';
import { useTeamsTableUtils } from 'libs/SalesApp/Teams/useTeamsTableUtils';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const TeamsTable = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useSearch();
  const { dialogType, snackbarProps, dialogProps, handlers } = useTeamsTableUtils();

  useTitle(t('common.teams'));

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useListInfiniteTeamsV2({
      name: searchQuery,
    });

  const teams = useMemo(() => {
    return (data?.pages ?? []).flat();
  }, [data]);

  const filtersResetHandler = () => {
    setSearchQuery('');
  };

  const filtered = !!searchQuery && teams.length === 0;

  const handleFetchNextPage = () => fetchNextPage();

  const isEmpty = teams.length === 0 && !isLoading;

  return (
    <>
      <Box>
        <PageHeader title={t('teams.teams')} titleTestId="teams-table-title">
          <Button
            data-testid="teams-create-team-button"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handlers.onCreateTeamClick}
          >
            {t('teams.newTeam')}
          </Button>
        </PageHeader>

        <DividerGrey sx={{ mb: 1 }} />

        {isEmpty ? (
          <EmptyTeamsTablePlaceholder
            onCreateClick={handlers.onCreateTeamClick}
            onResetClick={filtersResetHandler}
            filtered={filtered}
          />
        ) : (
          <ListTable
            rowHeight={80}
            isLoading={isLoading}
            onFetchNextPage={handleFetchNextPage}
            hasNextPage={hasNextPage}
            noMoreLabel={t('teams.noMoreTeams')}
            isFetchingNextPage={isFetchingNextPage}
            data-testid="teams-table"
          >
            <ListTableHeader columns={3} data-testid="teams-table-header">
              <ListTableHeaderCell
                data-testid="teams-table-header-name"
                label={t('teams.table.name')}
                columns={1}
              />
              <ListTableHeaderCell
                data-testid="teams-table-header-members-count"
                label={t('teams.table.membersCount')}
                columns={1}
              />
              <ListTableHeaderCell
                data-testid="teams-table-header-companies-count"
                label={t('teams.table.companiesCount')}
                columns={1}
              />
            </ListTableHeader>
            <ListTableBody>
              {teams.map(team => (
                <TeamsTableItem onClick={handlers.onUpdateTeamClick} key={team.id} team={team} />
              ))}
            </ListTableBody>
          </ListTable>
        )}
        <AlertSnackbar data-testid="company-tables-snackbar-alert" {...snackbarProps} />
        <TeamDialog
          open={dialogProps.open}
          title={dialogType === 'create' ? t('teams.createNewTeam') : t('teams.updateTeam')}
          onClose={dialogProps.onClose}
        >
          {dialogType === 'create' ? (
            <TeamCreateForm
              defaultValues={dialogProps.defaultValues}
              onCancel={dialogProps.onClose}
              onCreateError={dialogProps.onCreateError}
              onCreateSuccess={dialogProps.onCreateSuccess}
            />
          ) : (
            <TeamEditForm
              defaultValues={dialogProps.defaultValues}
              team={dialogProps.team!}
              onCancel={dialogProps.onClose}
              onUpdateError={dialogProps.onUpdateError}
              onUpdateSuccess={dialogProps.onUpdateSuccess}
            />
          )}
        </TeamDialog>
      </Box>
    </>
  );
};
