import React, { ComponentProps } from 'react';
import { LinkProps, NavLink, useLocation } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@clippings/paper';

interface MainAppNavItemProps extends ComponentProps<typeof ListItem> {
  to?: LinkProps['to'];
  icon?: React.ReactNode;
  label: React.ReactNode;
  pageReload?: boolean;
}

export const MainAppNavItem: React.FC<MainAppNavItemProps> = ({
  children,
  to = '',
  icon,
  label,
  pageReload,
  ...rest
}) => {
  const location = useLocation();
  const isActive = to === location.pathname;

  return (
    <ListItem {...rest} sx={{ py: 0 }}>
      <ListItemButton
        sx={{ borderRadius: '64px' }}
        component={pageReload ? 'a' : NavLink}
        to={to}
        disableRipple
      >
        {icon && (
          <ListItemIcon
            sx={{
              pr: 1,
              minWidth: 'min-content',
              color: theme =>
                isActive ? theme.palette.primary.main : theme.palette.secondary.main,
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            variant: 'body2',
            color: isActive ? 'primary' : 'secondary',
          }}
        />
        {children}
      </ListItemButton>
    </ListItem>
  );
};
