import { Brand } from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

export type IntendedUseType = {
  enabled: boolean;
  getLabel(key?: IntendedUseOptionsType): string;
};

export const INTENDED_USE_OPTIONS = {
  'C-AIRPORTS': 'airports',
  'C-EDUCATIO': 'education',
  'C-ENTERTAI': 'entertainment',
  EXPO: 'expo',
  FAIREVENTS: 'fairEvents',
  'C-HEALTHCA': 'healthcare',
  'C-HOTELS': 'hospitality',
  'C-MARITIME': 'maritime',
  'C-OFFICECO': 'officeCorporate',
  'C-APARTBUI': 'residential',
  'C-RESTAURA': 'restaurant',
  'C-STORES': 'retailChains',
  PRIVATE: 'shopClient',
  MORSHOW: 'showroom',
} as const;

export type IntendedUseOptionsType = keyof typeof INTENDED_USE_OPTIONS;

export const useIntendedUse = (): IntendedUseType => {
  const { brand } = useAppConfiguration();
  const { t } = useTranslation();

  const enabled = brand === Brand.Moroso;

  const getLabel = (key?: IntendedUseOptionsType): string => {
    if (!key) {
      return '';
    }

    return t(`intendedUse.options.${INTENDED_USE_OPTIONS[key]}`);
  };

  return { enabled, getLabel };
};
