import { ProductHit } from 'libs/Quotes/quote.types';
import { convertDashToUnderscore } from '@clippings/paper';
import { useUserLocale } from 'libs/Utils/locales';

export const useTranslatedProduct = () => {
  const { userLocale } = useUserLocale();

  const translateProductName = (product: ProductHit): string =>
    product.names?.[convertDashToUnderscore(userLocale)] ?? product.name;

  return { translateProductName };
};
