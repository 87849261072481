import React, { ComponentProps, FC, useRef } from 'react';
import classNames from 'classnames';
import { Box } from '@clippings/paper';
import { useResetScroll } from 'libs/hooks/useResetScroll';
import './LayoutContainer.scss';

export const LayoutContainer: FC<ComponentProps<typeof Box>> = ({
  className = '',
  children,
  ...rest
}) => {
  const layoutRef = useRef<HTMLDivElement>();
  useResetScroll(layoutRef);

  return (
    <Box ref={layoutRef} className={classNames('layout-container', className)} {...rest}>
      {children}
    </Box>
  );
};
