import React, { ComponentProps, FC, ReactNode } from 'react';
import classNames from 'classnames';
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@clippings/paper';
import './Modal.scss';

type BaseModalProps = {
  title: ReactNode;
  actionButtons: 'default' | 'none';
  withCloseButton?: boolean;
  dialogTitleProps?: ComponentProps<typeof DialogTitle>;
};

type ModalPropsWithActionButtons = {
  actionButtons: 'default';
  cancelLabel: string;
  okLabel: string;
  onClose: () => void;
  onOk: () => void;
};

type ModalPropsWithoutActionButtons = {
  actionButtons: 'none';
  cancelLabel?: never;
  okLabel?: never;
  onClose?: never;
  onOk?: never;
};

export type ModalProps = Omit<DialogProps, 'title'> &
  BaseModalProps &
  (ModalPropsWithoutActionButtons | ModalPropsWithActionButtons);

/**
 * Basic Modal component
 */
export const Modal: FC<ModalProps> = ({
  cancelLabel,
  okLabel,
  title,
  children,
  className,
  withCloseButton = true,
  actionButtons = 'default',
  onClose,
  onOk,
  dialogTitleProps,
  ...rest
}) => (
  <Dialog
    onClose={onClose}
    className={classNames('modal', className)}
    PaperProps={{
      className: 'modal__content',
      sx: {
        width: '600px',
        padding: '1.5rem',
      },
    }}
    {...rest}
  >
    <DialogTitle
      className="modal__title"
      data-testid="modal-title"
      {...dialogTitleProps}
      sx={{ ...styles.titleSx, ...dialogTitleProps?.sx }}
    >
      {title}
      {withCloseButton ? (
        <IconButton data-testid="modal-btn-close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    {children}
    {actionButtons === 'default' ? (
      <Box marginLeft="auto" marginTop="2rem" display="flex" gap={1}>
        <Button onClick={onClose} data-testid="modal-btn-cancel" variant="text">
          {cancelLabel}
        </Button>
        <Button onClick={onOk} data-testid="modal-btn-agree" variant="contained">
          {okLabel}
        </Button>
      </Box>
    ) : null}
  </Dialog>
);

const styles = {
  titleSx: { padding: 0, display: 'flex', justifyContent: 'space-between', marginBottom: '1.5rem' },
};
