import * as quoteActions from './actions';
import {
  AddQuoteShareesPayload,
  ListProjectsParams,
  Project,
  Quote,
  QuoteDocument,
  QuoteShareesList,
  UpdatePaymentTermsVariables,
} from './types';
import { BrowserUploadedFile } from 'libs/api/upload/types';
import {
  InfiniteQueryOptions,
  MutationOptions,
  Payment,
  PaymentRule,
  QueryOptions,
} from 'libs/api/common/types';
import {
  UseMutationOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { getPaymentTerms, queryKeys } from 'libs/Quotes/quoteActions';
import { listProjects, updateQuotePaymentTerms, uploadDocument } from './actions';
import { useQuoteQueryKey } from '../../Quotes/hooks';
import { useUploadDocument } from 'libs/api/upload/hooks';

export const reactQueryKeys = {
  duplicate: 'sales-quote-duplicate',
  update: 'sales-quote-update',
  create: 'sales-quote-create',
  archive: 'sales-quote-archive',
  unarchive: 'sales-quote-unarchive',
  getAll: 'sales-quotes-getAll',
  restoreVersion: 'sales-quote-restore-version',
  uploadDocument: 'upload-quote-document',
  deleteDocument: 'delete-quote-document',
  addSharees: 'add-quote-sharees',
  removeSharee: 'remove-quote-sharee',
  getSharees: 'get-quote-sharees',
} as const;
export const getUploadQueryKey = (number: string) => `${reactQueryKeys.uploadDocument}-${number}`;

export type QuoteUpdatePayload = {
  versionId: Quote['versionId'];
  payload: Partial<Quote>;
};
export type QuoteMutationOptions<T> = UseMutationOptions<Quote, unknown, T>;
export type CreateQuoteMutationOptions = UseMutationOptions<Quote, unknown>;
export type QuoteUpdateMutationOptions = QuoteMutationOptions<QuoteUpdatePayload>;

export const useQuoteUpdate = (options?: QuoteUpdateMutationOptions) =>
  useMutation(
    [reactQueryKeys.update],
    ({ versionId, payload }) => quoteActions.updateQuote(versionId, payload),
    options
  );

export const useQuoteCreate = (options?: CreateQuoteMutationOptions) =>
  useMutation([reactQueryKeys.create], () => quoteActions.createQuote(), options);

export const useQuoteRestore = (options?: QuoteMutationOptions<Pick<Quote, 'versionId'>>) =>
  useMutation(
    [reactQueryKeys.unarchive],
    quote => quoteActions.unarchiveQuote(quote.versionId),
    options
  );

export const useQuoteDuplicate = (options?: QuoteMutationOptions<Pick<Quote, 'versionId'>>) =>
  useMutation(
    [reactQueryKeys.duplicate],
    quote => quoteActions.duplicateQuote(quote.versionId),
    options
  );

export const useQuoteArchive = (options?: QuoteMutationOptions<Pick<Quote, 'versionId'>>) =>
  useMutation(
    [reactQueryKeys.archive],
    quote => quoteActions.archiveQuote(quote.versionId),
    options
  );

export const useQuoteRestoreVersion = (options?: QuoteMutationOptions<Pick<Quote, 'versionId'>>) =>
  useMutation(
    [reactQueryKeys.restoreVersion],
    quote => quoteActions.restoreQuoteVersion(quote.versionId),
    options
  );

export const useListInfiniteProjects = (
  params?: ListProjectsParams,
  options?: InfiniteQueryOptions<Project[]>
) =>
  useInfiniteQuery(
    ['infinite-projects', params],
    ({ pageParam }) => listProjects({ ...params, page: pageParam ?? 1 }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => (lastPage.length > 0 ? allPages.length + 1 : false),
    }
  );

export const useUpdateQuotePaymentTerms = (
  quote: Quote,
  options?: MutationOptions<PaymentRule[], UpdatePaymentTermsVariables>
) => {
  const queries = useQueryClient();
  const quoteQueryKey = useQuoteQueryKey(quote.number);

  return useMutation(
    ({ versionId, paymentTerms }) => updateQuotePaymentTerms(versionId, paymentTerms),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queries.invalidateQueries(queryKeys.getPaymentTerms(variables.versionId));
        queries.invalidateQueries(quoteQueryKey);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useDeleteQuoteDocument = (
  number: string,
  options?: UseMutationOptions<void, unknown, { id: string }>
) => {
  return useMutation(
    [reactQueryKeys.deleteDocument],
    ({ id }) => quoteActions.deleteDocument(id, number),
    {
      ...options,
    }
  );
};

export const useUploadQuoteDocument = (
  number: string,
  options?: MutationOptions<QuoteDocument, BrowserUploadedFile>
) =>
  useUploadDocument<QuoteDocument>(
    {
      uploadFunction: uploadDocument,
      queryKey: getUploadQueryKey(number),
      options,
    },
    number
  );

export const usePaymentTermsQuery = (
  versionId: Quote['versionId'],
  options?: QueryOptions<Payment[]>
) => useQuery(queryKeys.getPaymentTerms(versionId), () => getPaymentTerms(versionId), options);

export const useGetQuoteSharees = (number: string, options?: QueryOptions<QuoteShareesList>) =>
  useQuery([reactQueryKeys.getSharees, number], () => quoteActions.getSharees(number), options);

export const useRemoveQuoteSharee = (number: string, options?: MutationOptions<void, string>) =>
  useMutation(
    [reactQueryKeys.removeSharee, number],
    shareeId => quoteActions.removeSharee(number, shareeId),
    options
  );

export const useAddQuoteSharees = (
  number: string,
  options?: MutationOptions<QuoteShareesList, AddQuoteShareesPayload>
) =>
  useMutation(
    [reactQueryKeys.addSharees],
    payload => quoteActions.addSharees(number, payload),
    options
  );
