import { Quote } from 'libs/api/quotes/types';
import { useLocalStorage } from 'libs/shared/hooks/useLocalStorage';

const itemsLocalStorageKeyPrefix = 'hpd'; // hidden product details

export type QuoteItemHiddenDetails = {
  id: number;
  hidden: boolean;
};

export const useQuoteHiddenDetailProducts = (
  quoteNumber: Quote['number'],
  location: 'quote' | 'order' | 'checkout'
): [
  QuoteItemHiddenDetails[],
  (item: QuoteItemHiddenDetails) => void,
  (items: QuoteItemHiddenDetails[]) => void
] => {
  const [hiddenItems, setHiddenItems] = useLocalStorage<QuoteItemHiddenDetails[]>({
    key: `${itemsLocalStorageKeyPrefix}-${location}-${quoteNumber}`,
    initialValue: [],
  });

  const onHideAllDetails = (items: { id: number; hidden: boolean }[]) => {
    setHiddenItems(items);
  };

  const onHideDetails = (item: { id: number; hidden: boolean }) => {
    const updatedHiddenItems = [...hiddenItems!];

    const { id } = item;

    const index = updatedHiddenItems.findIndex(item => item.id === id);
    if (index > -1) {
      updatedHiddenItems.splice(index, 1, item);
    } else {
      updatedHiddenItems.push(item);
    }

    setHiddenItems(updatedHiddenItems); // remove nonexistent products before updating
  };

  return [hiddenItems ?? [], onHideDetails, onHideAllDetails];
};
