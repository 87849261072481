import React, { ComponentProps } from 'react';
import { ChatButton, useChatVisibility } from 'libs/Chat';
import { DividerGrey } from 'libs/shared';
import {
  Grid,
  ShowElement,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useIsMobile,
} from '@clippings/paper';
import { HelpLabel } from 'libs/Components';
import { STEPS, STEPS_CONFIG, Step as StepType, isCompleted, isDisabled } from '../config/steps';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  activeStep: StepType;
} & ComponentProps<typeof Grid>;

export const Header: React.FC<HeaderProps> = ({ activeStep, ...rest }) => {
  const { t } = useTranslation();
  const {
    quote: { salesRepresentative },
  } = useQuoteProvider();
  const { onToggle: toggleChat } = useChatVisibility();
  const isMobile = useIsMobile();

  return (
    <>
      <Grid
        data-testid="quotes-checkout-header-container"
        container
        justifyContent="space-between"
        flexWrap="wrap"
        paddingY={styles.containerPaddingY}
        {...rest}
      >
        <Stepper
          data-testid="quotes-checkout-header-stepper"
          activeStep={STEPS.indexOf(activeStep)}
          sx={styles.stepperSx}
          nonLinear
          alternativeLabel={isMobile}
        >
          {STEPS.map(step => (
            <Step
              data-testid={`quotes-checkout-step-${step}`}
              key={step}
              completed={isCompleted(step, activeStep)}
              disabled={isDisabled(step, activeStep)}
              sx={styles.stepSx}
            >
              <StepLabel
                data-testid={`quotes-checkout-step-label-${step}`}
                color="inherit"
                sx={{ marginTop: 0 }}
              >
                {t(STEPS_CONFIG[step].title)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid sx={styles.gridRight}>
          <ShowElement above="md">
            <HelpLabel salesRep={salesRepresentative} />
          </ShowElement>
          <ChatButton
            quoteSalesRep={salesRepresentative}
            onClick={toggleChat}
            iconButtonProps={chatButtonIconButtonProps}
          />
          <ShowElement below="md">
            <Typography
              fontSize={theme => theme.typography.pxToRem(12)}
              color={theme => theme.palette.grey[400]}
            >
              {t('common.needHelp')}
            </Typography>
          </ShowElement>
        </Grid>
      </Grid>
      <DividerGrey sx={{ my: 1 }} />
    </>
  );
};

const chatButtonIconButtonProps = {
  sx: {
    padding: {
      xs: 0,
      md: 1,
    },
    paddingBottom: {
      xs: 1,
    },
  },
};

const styles = {
  containerPaddingY: {
    xs: 0,
    md: 2,
  },
  stepperSx: {
    '.MuiStep-root.MuiStep-alternativeLabel': {
      paddingX: {
        xs: 0,
        md: 1,
      },
    },
    gap: {
      xs: 0,
      md: 3,
    },
    flexGrow: {
      xs: 1,
      md: 0,
    },
  },
  stepSx: {
    '.MuiStepConnector-root span': {
      borderColor: 'transparent',
    },
    'span.MuiStepLabel-alternativeLabel.MuiStepLabel-label': {
      marginTop: 1,
      fontSize: {
        xs: '0.75rem',
        md: '0.875rem',
      },
    },
  },
  gridRight: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    alignItems: 'center',
    gap: { xs: 0, md: 2 },
  },
};
