import { Money } from 'libs/api/common/types';
import { OrderProductItem } from 'libs/api/order/types';
import { QuoteProductItem } from 'libs/api/quotes/types';

export type ContractFieldsValues = {
  contractListPrice: Money;
  contractNetPrice: Money;
  contractSubTotalPrice: Money;
};

export function makeContractFieldsValues(
  item: QuoteProductItem | OrderProductItem
): ContractFieldsValues {
  return {
    contractListPrice: item?.contractListPrice ?? defaultPriceValue,
    contractNetPrice: item.contractNetPrice ?? defaultPriceValue,
    contractSubTotalPrice: item.contractSubtotal ?? defaultPriceValue,
  };
}

export const defaultPriceValue = {
  amount: 0,
  currency: 'USD',
};
