import React from 'react';
import { PaymentRule } from 'libs/api/common/types';
import { PaymentRuleRow } from '../PaymentRuleRow';
import { PaymentRulesTypes } from '../../paymentTerms.types';
import { TableBody } from '@clippings/paper';

type PaymentTermsTableBodyProps = {
  paymentTerms: PaymentRule[];
  paymentTermsTypes: PaymentRulesTypes;
  onChange: (index: number, updatedPaymentRule: PaymentRule | null) => void;
};

export function PaymentTermsTableBody({
  paymentTerms,
  paymentTermsTypes,
  onChange,
}: PaymentTermsTableBodyProps) {
  return (
    <TableBody>
      {paymentTerms.map((rule, index) => (
        <PaymentRuleRow
          key={rule.id}
          index={index}
          paymentRule={rule}
          types={paymentTermsTypes}
          onChange={updatedPaymentRule => onChange(index, updatedPaymentRule)}
        />
      ))}
    </TableBody>
  );
}
