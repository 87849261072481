import React from 'react';
import { Column, QuoteItemCardRenderer, Row } from 'libs/shared';
import { DetailsSkeleton } from './Skeletons';
import { OrderInvoiceMenu } from './OrderInvoiceMenu';
import { QuoteTableView } from '../../Quotes/QuoteTable/components/QuoteTableView/QuoteTableView';
import { ShowElement, Typography } from '@clippings/paper';
import { useOrder } from 'libs/api/order/hooks';
import { useQuoteHiddenDetailProducts } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { useTranslation } from 'react-i18next';

type OrderItemsDetailsProps = {
  number: string;
  className?: string;
  withInvoiceMenu?: boolean;
};

export const OrderItemsDetails: React.FC<OrderItemsDetailsProps> = ({
  number,
  className,
  withInvoiceMenu = true,
}) => {
  const { t } = useTranslation();
  const orderQuery = useOrder(number);

  const [hiddenDetailProducts, onProductDetailsHide] = useQuoteHiddenDetailProducts(
    number,
    'order'
  );

  return (
    <Column gap={2} className={className}>
      <Row justifyContent="space-between" alignItems="center">
        <Typography variant="h5" className="order-details-heading">
          {t('common.orderDetails')}
        </Typography>
        {withInvoiceMenu ? <OrderInvoiceMenu number={number} /> : null}
      </Row>
      {orderQuery.isSuccess ? (
        <>
          <ShowElement below="md">
            <QuoteItemCardRenderer items={orderQuery.data.productItems} />
          </ShowElement>
          <ShowElement above="md">
            <QuoteTableView
              readonly
              hiddenDetailProducts={hiddenDetailProducts}
              items={orderQuery.data.productItems}
              onProductDetailsHide={onProductDetailsHide}
            />
          </ShowElement>
        </>
      ) : (
        <DetailsSkeleton />
      )}
    </Column>
  );
};
