import React from 'react';
import { ApiError } from 'libs/api/common/types';
import { AxiosError } from 'axios';
import {
  Box,
  Brand,
  Button,
  DeepPartial,
  FormProvider,
  LoadingButton,
  useForm,
  useSyncServerErrors,
  yup,
  yupResolver,
} from '@clippings/paper';
import { TeamFormFields } from 'libs/shared/components/teams/TeamFormFields';
import { TeamFormVariables } from 'libs/api/iam/types';
import { useAppConfiguration } from 'libs/providers';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { useTranslation } from 'react-i18next';

export type TeamFormProps = {
  onCancel: () => void;
  defaultValues?: DeepPartial<TeamFormVariables>;
  handleFormSubmit: (values: TeamFormVariables) => Promise<void> | void;
  mutationErrors: AxiosError<ApiError, any> | null;
  loading: boolean;
  buttonText: string;
};

export const TeamForm: React.FC<TeamFormProps> = ({
  onCancel,
  defaultValues = {},
  handleFormSubmit,
  mutationErrors,
  loading,
  buttonText,
}) => {
  const { brand } = useAppConfiguration();
  const { t } = useTranslation();

  const serverErrors = useServerFormErrors(mutationErrors);

  const pdfTemplateValidation =
    brand === Brand.Moroso
      ? yup.string().required('teams.pdfTemplateRequired').nullable()
      : yup.string().nullable();

  const teamValidation = {
    name: yup
      .string()
      .required('common.teamNameRequired')
      .max(255, ({ max }) => ({ key: 'teams.teamNameMaxLength', params: { max } }))
      .trim(),
    pdfTemplateId: pdfTemplateValidation,
  };

  const schema = yup.object().shape(teamValidation);

  const teamForm = useForm<TeamFormVariables>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useSyncServerErrors(teamForm, serverErrors);

  return (
    <FormProvider {...teamForm} {...{ schema }}>
      <form noValidate onSubmit={teamForm.handleSubmit(handleFormSubmit)} data-testid="team-form">
        <Box display="flex" flexDirection="column">
          <TeamFormFields />
        </Box>
        <Box pt={2} display="flex" justifyContent="flex-end">
          <Button data-testid="dialog-button-cancel" color="secondary" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            disabled={!teamForm.formState.isDirty}
            data-testid="dialog-button-submit"
          >
            {buttonText}
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
};
