import React, { useState } from 'react';
import {
  AlertSnackbar,
  EmailIcon,
  ListItemIcon,
  MenuItem,
  MoreActionsButton,
} from '@clippings/paper';
import { preventDefaultPropagation } from 'libs/shared/utils';
import { useBanner } from 'libs/Quotes/Components/AlertBanner';
import { useResetPassword } from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

interface UserListItemMoreActionsProps {
  email: string;
}

export const UserListItemMoreActions: React.FC<UserListItemMoreActionsProps> = ({ email }) => {
  const { t } = useTranslation();
  const { showErrorBanner } = useBanner();
  const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  const [openResetPasswordSuccess, setOpenResetPasswordSuccess] = useState(false);
  const resetPasswordMutation = useResetPassword({
    onSuccess: () => {
      setOpenResetPasswordSuccess(true);
    },
    onError() {
      showErrorBanner(t('common.passwordLinkWasAlreadySentPleaseTryAgain', { minutes: 5 }));
    },
  });

  const handleResetPassword = (event: React.MouseEvent<HTMLElement>) => {
    // Prevent bubbling to the parent that has click on it. Example: UserListItem
    preventDefaultPropagation(event);

    setMoreActionsOpen(false);

    if (email) {
      resetPasswordMutation.mutate(email);
    }
  };
  return (
    <>
      <AlertSnackbar
        onClose={() => setOpenResetPasswordSuccess(false)}
        data-testid="reset-password-success-snackbar"
        open={openResetPasswordSuccess}
        message={t('common.weSentAPasswordResetLink', { email })}
      />
      <MoreActionsButton
        data-testid="user-item-more-button"
        isOpen={moreActionsOpen}
        onToggle={setMoreActionsOpen}
        onClick={preventDefaultPropagation}
        iconButtonProps={{
          sx: {
            marginTop: 1,
            marginRight: 1,
          },
        }}
      >
        <MenuItem onClick={handleResetPassword} data-testid="user-item-more-reset-password-button">
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          {t('users.actions.sendResetPasswordEmail')}
        </MenuItem>
      </MoreActionsButton>
    </>
  );
};
