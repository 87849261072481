import React, { ComponentProps, FC } from 'react';
import { MenuItem, Typography } from '@clippings/paper';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { StatusCircle } from 'libs/Components';

export interface StatusOptionProps {
  status: QuoteListItemStatus;
  onSelect: (status: QuoteListItemStatus) => void;
  label: string;
  circleProps: ComponentProps<typeof StatusCircle>;
}

export const StatusOption: FC<StatusOptionProps> = ({ status, onSelect, label, circleProps }) => (
  <MenuItem data-testid={`status-option-${status}`} onClick={() => onSelect(status)}>
    <StatusCircle {...circleProps} />
    <Typography ml={1}>{label}</Typography>
  </MenuItem>
);
