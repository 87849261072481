import { ACTIONS } from './addressReducer';
import { AddressTypesEnum } from '../../hooks/useAddresses';
import { CountryCodes, Nullable } from 'libs/Utils';
import { Location } from 'libs/api/location/types';
import { QuoteContextState } from 'libs/Quotes/QuoteContext';
import {
  SCREENS,
  useAddressScreenReducer,
} from 'libs/Quotes/QuoteAddresses/hooks/useAddressScreenReducer';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import { getServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { removeNullValues, voidFn } from 'libs/shared/utils';
import { useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// TODO: improve types
export type UseAddressProps = {
  currentQuoteAddress: any;
  quoteAddressQuery: Nullable<UseQueryResult<any>>;
  companyAddressQuery: UseQueryResult<any>;
  locationQuery: UseQueryResult<Location[]>;
  paymentTermsQuery: UseQueryResult<any>;
  handleQuoteAddressChange: QuoteContextState['handleQuoteAddressChange'];
  isDetailedShippingModal?: boolean;
  handlers: any;
};

export const useAddress = ({
  currentQuoteAddress,
  quoteAddressQuery,
  companyAddressQuery,
  locationQuery,
  paymentTermsQuery,
  handleQuoteAddressChange,
  handlers,
  isDetailedShippingModal,
}: UseAddressProps) => {
  const queryClient = useQueryClient();

  const { currentScreen, selectedAddress, isDeleteModalOpen, previousSteps, dispatch } =
    useAddressScreenReducer({
      currentQuoteAddress,
      isDetailedShippingModal: !!isDetailedShippingModal,
    });

  const handleOpenCreateNewAddress = (stepsAction = 'push') => {
    dispatch({ type: ACTIONS.SELECT_ADDRESS, payload: {} });
    dispatch({
      type: ACTIONS.CHANGE_SCREEN,
      payload: { screen: SCREENS.CREATE_ADDRESS, stepsAction },
    });
  };

  const handleOpenList = (stepsAction = 'push') => {
    dispatch({
      type: ACTIONS.CHANGE_SCREEN,
      payload: { screen: SCREENS.LIST, stepsAction },
    });
  };

  const handleOpenCustomerPickup = (stepsAction = 'push') => {
    dispatch({
      type: ACTIONS.CHANGE_SCREEN,
      payload: { screen: SCREENS.CUSTOMER_PICKUP, stepsAction },
    });
  };

  const handleOpenShippingType = (stepsAction = 'push') => {
    dispatch({
      type: ACTIONS.CHANGE_SCREEN,
      payload: { screen: SCREENS.DELIVERY_METHOD, stepsAction },
    });
  };

  const handleOpenEditAddress = (address: any, stepsAction = 'push') => {
    dispatch({ type: ACTIONS.SELECT_ADDRESS, payload: address });
    dispatch({
      type: ACTIONS.CHANGE_SCREEN,
      payload: { screen: SCREENS.EDIT_ADDRESS, stepsAction },
    });
  };

  const handleOpenSameAsBilling = (stepsAction = 'push') => {
    dispatch({
      type: ACTIONS.CHANGE_SCREEN,
      payload: { screen: SCREENS.SAME_AS_BILLING, stepsAction },
    });
  };

  const handleBack = () => {
    dispatch({ type: ACTIONS.GO_BACK });
  };

  const setSelectedAddress = (address: any) => {
    dispatch({ type: ACTIONS.SELECT_ADDRESS, payload: address });
  };

  const openDeleteModal = (address: any) => {
    dispatch({ type: ACTIONS.OPEN_DELETE_MODAL, payload: address });
  };

  const closeDeleteModal = () => {
    dispatch({ type: ACTIONS.CLOSE_DELETE_MODAL });
  };

  const hasBack = previousSteps.length > 0;

  const { handleAddressCreate, handleAddressUpdate, handleAddressDelete } = handlers;

  const countries = useMemo(
    () =>
      locationQuery.data
        ?.filter(x => x.type === 'country')
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [locationQuery]
  );

  const states = useMemo(
    () =>
      locationQuery.data
        ?.filter((x: { type: string }) => x.type === 'state')
        .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name)) ?? [],
    [locationQuery]
  );

  const getCountryIdFromShortName = (shortName: string) => {
    return countries.find((x: { shortName: string }) => x.shortName === shortName)?.id;
  };

  const formatAddressData = (data: any) => {
    const { province, state, ...submitData } = data;

    const addressData = {
      ...submitData,
      country: {
        id: getCountryIdFromShortName(data.country),
      },
      ...(data.country === CountryCodes.US ? { state: { id: Number(state) } } : { province }),
    };
    return removeNullValues(addressData);
  };

  const [createAddressFormErrors, setCreateAddressFormErrors] = useState<any>({});
  const [updateAddressFormErrors, setUpdateAddressFormErrors] = useState<any>({});

  const handleCreateAddress = (data: any, type: AddressTypesEnum, onCreate: () => void) => {
    handleAddressCreate(
      formatAddressData(data),
      type,
      (newAddress: any) => {
        handleQuoteAddressChange(newAddress, () => {
          companyAddressQuery.refetch();
          quoteAddressQuery?.refetch();
          onCreate();
        });
      },
      (errors: unknown) => {
        setCreateAddressFormErrors(getServerFormErrors(errors));
      }
    );
  };

  const handleEditAddress = (data: any, detailed = false) => {
    handleAddressUpdate(
      { ...formatAddressData(data), id: selectedAddress.id, detailed },
      (newAddress: any) => {
        handleQuoteAddressChange(newAddress, () => {
          quoteAddressQuery?.refetch();
          companyAddressQuery.refetch();
          paymentTermsQuery.refetch();
          handleOpenList('pop');
        });
      },
      (errors: unknown) => {
        setUpdateAddressFormErrors(getServerFormErrors(errors));
      },
      selectedAddress.type
    );
  };

  function deleteAddressSuccessCallback() {
    if (selectedAddress.id === currentQuoteAddress?.id) {
      queryClient.invalidateQueries(['quote']);
    }

    quoteAddressQuery?.refetch();
    companyAddressQuery.refetch();
    paymentTermsQuery.refetch();
    closeDeleteModal();
  }

  const handleDeleteAddress = (data: any) => {
    // @ts-expect-error handleAddressDelete is not typed
    handleAddressDelete(data, selectedAddress.type, data => {
      if (selectedAddress.type === AddressTypesEnum.COMPANY) {
        deleteAddressSuccessCallback();

        return;
      }

      // Quote
      handleQuoteAddressChange(data, deleteAddressSuccessCallback);
    });
  };

  const handleMakeDefault = (data: any) => {
    handleAddressUpdate(
      { ...data, default: true },
      () => {
        companyAddressQuery.refetch();
      },
      voidFn,
      AddressTypesEnum.COMPANY
    );
  };

  return {
    formatAddressData,
    currentScreen,
    selectedAddress,
    isDeleteModalOpen,
    handleOpenShippingType,
    handleOpenEditAddress,
    handleOpenCreateNewAddress,
    handleBack,
    handleOpenList,
    setSelectedAddress,
    handleOpenSameAsBilling,
    openDeleteModal,
    closeDeleteModal,
    hasBack,
    handleCreateAddress,
    handleEditAddress,
    handleDeleteAddress,
    handleMakeDefault,
    createAddressFormErrors,
    updateAddressFormErrors,
    states,
    countries,
    handleOpenCustomerPickup,
  };
};
