import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import { Box, InfoOutlinedIcon } from '@clippings/paper';

export type QuoteFooterDetailsTextProps = ComponentProps<typeof Box> & {
  text: string;
};

export const QuoteFooterDetailsText: FC<PropsWithChildren<QuoteFooterDetailsTextProps>> = ({
  text,
  children,
  ...rest
}) => (
  <Box display="flex" alignItems="center" gap={2} fontSize="small" {...rest}>
    <InfoOutlinedIcon fontSize="small" />
    {text}
    {children}
  </Box>
);
