import React, { ComponentProps, FC, useMemo, useState } from 'react';
import { AddItemSearchTermProvider, useQuoteProvider } from '../providers';
import { AddQuoteItemCatalogueProduct } from 'libs/api/quotes/types';
import { Button, Dialog, QuantityInput, Skeleton, useIsMobile, useIsPrint } from '@clippings/paper';
import { PDPContainer } from '../Components/PdpConfigurator/PDPContainer';
import { PRODUCT_MAX_QUANTITY } from 'libs/Utils';
import { ProductHit } from '../quote.types';
import { ProductListingPage } from 'libs/SalesApp/Quotes/productListing';
import { QuoteItemCardRenderer, QuoteItemCardRendererProps, Row } from 'libs/shared';
import { QuoteItemViewModel } from 'libs/shared/components/quoteItemCard/quoteItemCard.types';
import { QuoteTableView } from './components';
import { useProductConfigurator } from '../Components/PdpConfigurator/useProductConfigurator';
import { useTranslation } from 'react-i18next';

export interface QuoteTableProps {
  readonly?: boolean;
  textOnly?: boolean;
  className?: string;
  // TODO: replace with permission when it's available
  canAddQuoteItem?: boolean;
}

export const QuoteTable: FC<QuoteTableProps> = ({
  readonly = false,
  textOnly = false,
  className = '',
  canAddQuoteItem = true,
}) => {
  const { t } = useTranslation();
  const isPrint = useIsPrint();
  const isMobile = useIsMobile();

  const [showPLP, setShowPLP] = useState(false);
  const {
    quote,
    tableLoading,
    loading,
    hiddenDetailProducts,
    onQuoteItemQuantityUpdate,
    onQuoteItemDelete,
    onProductDetailsHide,
    onQuoteItemUpdateByKey,
  } = useQuoteProvider();
  const [inputValue, setInputValue] = useState('');

  const handleQuoteItemQuantityUpdate = (quantity: number, itemId: number) => {
    onQuoteItemQuantityUpdate(quantity, itemId);
  };

  const handleQuoteItemDelete = (itemId: number) => {
    onQuoteItemDelete(itemId);
  };

  const openPLP = () => setShowPLP(true);
  const closePLP = () => setShowPLP(false);

  const {
    addToQuoteMutation,
    updateQuoteItemMutation,
    configuredProduct,
    addSampleHandler,
    setConfiguredProduct,
    resetConfiguredProduct,
    handleUpdateExistingProduct,
    handleAddProductFromPlp,
  } = useProductConfigurator(quote, closePLP);

  const handleAddNewProduct = (payload: AddQuoteItemCatalogueProduct) =>
    addToQuoteMutation.mutate(
      {
        versionId: quote.versionId,
        payload: { ...payload, productId: String(payload.productId) },
      },
      {
        onSuccess: () => {
          resetConfiguredProduct();
          setInputValue('');
          closePLP();
        },
      }
    );

  const handleConfigureProduct = (product: ProductHit) =>
    setConfiguredProduct({
      showGroupedProducts: true,
      productId: product.objectID,
      productName: product.name,
    });

  const editableFields: QuoteItemCardRendererProps['editableFields'] = useMemo(() => {
    if (readonly) {
      return {};
    }

    return {
      quantity: item => (
        <Row maxWidth="160px">
          <QuantityInput
            key={item.id}
            max={PRODUCT_MAX_QUANTITY}
            disabled={loading}
            initialQuantity={Number(item.quantity)}
            onQuantityChange={(quantity: number) => onQuoteItemQuantityUpdate(quantity, item.id)}
            data-testid={`quantity-input-${item.id}`}
          />
        </Row>
      ),
    };
  }, [readonly]);

  if (loading) {
    return (
      <Skeleton
        width="100%"
        height="250px"
        variant="rectangular"
        data-testid="quote-table-loader"
      />
    );
  }

  const plpContainer = !readonly ? (
    <Dialog disablePortal fullScreen open={showPLP}>
      <ProductListingPage
        catalogueSlug={quote.catalogue}
        onAddProduct={handleAddProductFromPlp}
        onConfigureProduct={handleConfigureProduct}
        onClose={closePLP}
      />
    </Dialog>
  ) : null;

  const pdpContainer = !readonly ? (
    <PDPContainer
      configuredProduct={configuredProduct}
      catalogueSlug={quote.catalogue}
      onClose={resetConfiguredProduct}
      onAddToQuote={
        configuredProduct?.isExisting ? handleUpdateExistingProduct : handleAddNewProduct
      }
      submitting={addToQuoteMutation.isLoading || updateQuoteItemMutation.isLoading}
      onChangeProduct={setConfiguredProduct}
      onAddSample={addSampleHandler}
    />
  ) : null;

  if (isMobile && !isPrint) {
    const cardActions = (item: QuoteItemViewModel) => (
      <Button
        variant="outlined"
        sx={styles.buttonSx}
        onClick={() => handleQuoteItemDelete(item.id)}
      >
        {t('common.remove')}
      </Button>
    );

    return (
      <>
        <QuoteItemCardRenderer
          items={quote.quoteItemProducts}
          editableFields={editableFields}
          cardActions={cardActions}
        />
        {pdpContainer}
      </>
    );
  }

  return (
    <AddItemSearchTermProvider value={inputValue} setValue={setInputValue}>
      <QuoteTableView
        className={className}
        textOnly={textOnly}
        items={quote.quoteItemProducts}
        catalogue={quote.catalogue}
        readonly={readonly}
        tableLoading={tableLoading || addToQuoteMutation.isLoading}
        canAddQuoteItem={canAddQuoteItem}
        hiddenDetailProducts={hiddenDetailProducts}
        handleDelete={handleQuoteItemDelete}
        handleQuantityUpdate={handleQuoteItemQuantityUpdate}
        onConfigure={setConfiguredProduct}
        handleOpenPlp={openPLP}
        onProductDetailsHide={onProductDetailsHide}
        onNoteSave={onQuoteItemUpdateByKey}
      />
      {plpContainer}
      {pdpContainer}
    </AddItemSearchTermProvider>
  );
};

const styles = {
  buttonSx: {
    width: '100%',
    borderColor: theme => theme.palette.grey[400],
    color: theme => theme.palette.grey[800],
  } as ComponentProps<typeof Button>['sx'],
};
