import React from 'react';
import { Catalogue, Category } from 'libs/api/catalogue/types';
import { CompanyProductDiscount } from 'libs/api/common/types';
import { NullablePercentageCompanyProductDiscount, ProductDiscountRow } from './ProductDiscountRow';
import { TableBody } from '@clippings/paper';

export type ProductDiscountTableBodyProps = {
  catalogues: Catalogue[];
  categories: Category[];
  discounts: CompanyProductDiscount[];
  onChange: (discounts: CompanyProductDiscount[]) => void;
};

export function ProductDiscountTableBody({
  catalogues,
  categories,
  discounts,
  onChange,
}: ProductDiscountTableBodyProps) {
  const handleChange = (
    index: number,
    discount: NullablePercentageCompanyProductDiscount | null
  ) => {
    const newDiscounts = [...discounts];
    if (discount === null) {
      newDiscounts.splice(index, 1);
    } else {
      newDiscounts.splice(index, 1, discount as CompanyProductDiscount);
    }

    onChange(newDiscounts);
  };

  return (
    <TableBody>
      {discounts.map((discount, index) => (
        <ProductDiscountRow
          key={`${discount.id}`}
          discount={discount}
          catalogues={catalogues}
          categories={categories}
          onChange={newValue => handleChange(index, newValue)}
        />
      ))}
    </TableBody>
  );
}
