import { RegisterFormStep } from '@clippings/paper';
import { lookupCatalogue } from 'libs/api/catalogue/actions';
import { routes } from 'libs/Constants';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import { useState } from 'react';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const onRegisterSuccess = (cb: (step: RegisterFormStep) => void) => {
  cb(RegisterFormStep.Confirmation);
};

export type UseRegisterUtilsProps = { getCatalogue?: boolean };
export const useRegisterUtils = ({ getCatalogue = true }: UseRegisterUtilsProps = {}) => {
  const { t } = useTranslation();
  useTitle(t('common.registration'));

  const [activeStep, setActiveStep] = useState(RegisterFormStep.AccountDetails);

  const returnUrl = useReturnUrl();
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate(routes.login);
  };

  const handleBackToBrowseClick = () => {
    window.location.href = returnUrl;
  };

  const handleBackToAccountDetails = () => setActiveStep(RegisterFormStep.AccountDetails);

  const catalogue = useQuery(['catalogue'], lookupCatalogue, {
    enabled: getCatalogue,
  });

  return {
    activeStep,
    setActiveStep,
    handleSignInClick,
    handleBackToBrowseClick,
    handleBackToAccountDetails,
    catalogue,
  };
};
