import { PaymentIntentCreated, PaymentMethod } from './types';
import { axios, path } from 'libs/Utils';

export const apiPaths = {
  preparePayment: '/api/payment/v1/payments/:paymentId/pay',
  getPaymentIntent: '/api/payment/v1/payments/:paymentId/stripe',
};

export const preparePayment = (
  paymentId: string,
  paymentMethod: PaymentMethod,
  referenceId?: string
) =>
  axios.post(path(apiPaths.preparePayment, { paymentId }), {
    paymentMethod,
    referenceId,
  });

export const getStripePaymentIntent = (paymentId: string) =>
  axios
    .post<PaymentIntentCreated>(path(apiPaths.getPaymentIntent, { paymentId }))
    .then(x => x.data);
