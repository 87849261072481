import React from 'react';
import { Button, DialogActions, LoadingButton, useFormContext } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { useTranslation } from 'react-i18next';

export type FormModalActionsProps = {
  actionProps: Nullable<{
    onCancel?: () => void;
    cancelLabel?: string;
    submitLabel: string;
  }>;
  submitProps?: {
    disabled?: boolean;
    'data-testid': string;
  };
  isSubmitting: boolean;
};

export function FormModalActions({
  actionProps,
  submitProps,
  isSubmitting,
}: FormModalActionsProps) {
  const { t } = useTranslation();
  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <DialogActions
      sx={{
        padding: theme => `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(24)}`,
      }}
    >
      {actionProps?.onCancel && (
        <Button
          variant="text"
          size="medium"
          onClick={actionProps.onCancel}
          data-testid="form-cancel"
          disabled={isSubmitting}
        >
          {actionProps.cancelLabel ?? t('common.cancel')}
        </Button>
      )}
      <LoadingButton
        fullWidth={!actionProps?.onCancel}
        variant="contained"
        color="primary"
        size="medium"
        type="submit"
        loading={isSubmitting}
        disabled={!isValid || isSubmitting}
        {...submitProps}
      >
        {actionProps?.submitLabel ?? t('common.save')}
      </LoadingButton>
    </DialogActions>
  );
}
