import React, { PropsWithChildren } from 'react';
import { Box } from '@clippings/paper';
import { QuoteInfoLabel } from './QuoteInfoLabel';

interface QuoteInfoSimpleItemProps {
  label: string;
  value: string;
}

export function QuoteInfoSimpleItem({
  children,
  label,
  value,
}: PropsWithChildren<QuoteInfoSimpleItemProps>): JSX.Element {
  return (
    <Box>
      <QuoteInfoLabel bold text={label} />
      <QuoteInfoLabel text={value} />
      {children}
    </Box>
  );
}
