import React, { ComponentProps } from 'react';
import { Skeleton } from '@clippings/paper';

const rowStyles = { mb: 2, width: '100%', height: '48px', borderRadius: '4px' };
const itemStyles = { width: '100%', height: '155px', borderRadius: '4px' };
export type SkeletonProps = ComponentProps<typeof Skeleton>;
export const FormSkeletonRow = (props?: SkeletonProps) => (
  <Skeleton variant="rectangular" {...props} sx={{ ...rowStyles, ...props?.sx }} />
);
export const AddressListSkeleton = () => <Skeleton sx={itemStyles} variant="rectangular" />;
export const ShippingTypeSkeleton = () => (
  <>
    <Skeleton sx={itemStyles} variant="rectangular" />
    <Skeleton sx={itemStyles} variant="rectangular" />
  </>
);
