import { DEBOUNCE_TIMINGS } from 'libs/Constants';
import { Quote, QuoteProductItem } from 'libs/api/quotes/types';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';
import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { debounce, isNaN } from 'lodash';
import { useCallback } from 'react';

import { CompositeDiscountValue } from 'libs/SalesApp/Quotes/components/CompositeDiscountCell';
import { NonContractualMutationType } from 'libs/Quotes/hooks/useQuoteHandlers';
import {
  deleteQuoteItem as deleteQuoteItemAction,
  updateQuoteVersionItem,
} from 'libs/Quotes/quoteActions';
import { updateQuoteItemNonContractual } from 'libs/api/quotes/actions';
import { useDefaultQuoteMutations } from './useDefaultQuoteMutations';

export const useQuoteItemHandlers = (
  quote: Quote,
  queryKey: string[],
  setError?: (err: unknown) => void
) => {
  const queryClient = useQueryClient();
  const defaultMutationHandlers = useDefaultQuoteMutations(queryKey, setError);

  //region Mutation Handlers
  const quoteItemProductsOnMutateHandler = async (data: Partial<QuoteProductItem>) => {
    await queryClient.cancelQueries(queryKey);
    const updatedItemIndex = quote.quoteItemProducts.findIndex(x => x.id === data.id);
    const previousQuote = queryClient.getQueryData<Quote>(queryKey)!;

    queryClient.setQueryData<Partial<Quote>>(queryKey, old => ({
      ...old,
      quoteItemProducts: Object.assign([] as QuoteProductItem[], old!.quoteItemProducts, {
        [updatedItemIndex]: { ...old!.quoteItemProducts![updatedItemIndex], ...data },
      }),
    }));

    return { previousQuote };
  };

  const quoteItemPesimisticProductsOnMutateHandler = async () => {
    await queryClient.cancelQueries(queryKey);
    const previousQuote = queryClient.getQueryData<Quote>(queryKey)!;
    return { previousQuote };
  };

  const quoteItemProductsReorderOnMutateHandler = async (data: Partial<QuoteProductItem>) => {
    await queryClient.cancelQueries(queryKey);
    const previousQuote = queryClient.getQueryData<Quote>(queryKey)!;

    queryClient.setQueryData<Partial<Quote>>(queryKey, old => {
      let updatedProducts = Array.from(old!.quoteItemProducts!);
      const idx = updatedProducts.findIndex(x => x.id === data.id);

      const [reorderedItem] = updatedProducts.splice(idx, 1);
      updatedProducts.splice(data.position!, 0, reorderedItem);
      updatedProducts = updatedProducts.map((item, idx) => ({ ...item, position: idx }));

      return { ...old, quoteItemProducts: updatedProducts };
    });

    return { previousQuote };
  };
  //endregion

  //region Mutations
  const itemBlockingMutation = useMutation(
    ({ id, ...data }: Partial<QuoteProductItem> & { id: number }) =>
      updateQuoteVersionItem(quote.versionId, id, data),
    {
      ...defaultMutationHandlers,
      mutationKey: queryKey,
      onMutate: quoteItemProductsOnMutateHandler,
    }
  );
  const deleteMutation = useMutation(
    (quoteItemId: number) => deleteQuoteItemAction(quote.id, quoteItemId),
    {
      onMutate: async (quoteItemId: number) => {
        await queryClient.cancelQueries(queryKey);
        const previousQuote = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (old?: Quote) => {
          if (old) {
            return {
              ...old,
              quoteItemProducts: old.quoteItemProducts.filter(item => item.id !== quoteItemId),
            };
          }
        });

        return { previousQuote };
      },
      onError: setError,
      onSuccess: defaultMutationHandlers.onSuccess,
    }
  );

  const pesimisticBlocingItemMutation = useMutation(
    ({ id, ...data }: Partial<QuoteProductItem> & { id: number }) =>
      updateQuoteVersionItem(quote.versionId, id, data),
    {
      ...defaultMutationHandlers,
      mutationKey: queryKey,
      onMutate: quoteItemPesimisticProductsOnMutateHandler,
    }
  );

  const updateItemPositionMutation = useMutation(
    ({ id, ...data }: Partial<QuoteProductItem>) =>
      updateQuoteItemNonContractual(quote.versionId, id!, data),
    {
      ...defaultMutationHandlers,
      mutationKey: queryKey,
      onMutate: quoteItemProductsReorderOnMutateHandler,
    }
  );

  const updateItemNonContractualMutation = useMutation(
    ({ id, ...data }: Partial<QuoteProductItem>) =>
      updateQuoteItemNonContractual(quote.versionId, id!, data),
    {
      ...defaultMutationHandlers,
      mutationKey: queryKey,
      onMutate: quoteItemProductsOnMutateHandler,
    }
  );

  const debouncedQuoteItemQuantityUpdate = useCallback(
    debounce(async (data: Partial<QuoteProductItem> & { id: number }) => {
      itemBlockingMutation.mutate(data);
    }, DEBOUNCE_TIMINGS.INPUT_DEBOUNCE),
    []
  );
  //endregion

  //region Action Handlers
  const handleQuoteItemUpdateByKey: UpdateQuoteByKey = (key, value, itemId) => {
    itemBlockingMutation.mutate({ id: itemId, [key]: value });
  };

  const handleMakeCustom = (variationName: string, itemId: number) => {
    pesimisticBlocingItemMutation.mutate({ id: itemId, custom: true, variationName });
  };

  const handleQuoteItemUpdateDiscount = (value: CompositeDiscountValue, itemId: number) => {
    itemBlockingMutation.mutate({
      id: itemId,
      discountPercent: value.percent as number,
      discountTitle: value.title,
    });
  };

  const handleQuoteItemQuantityUpdate = async (quantity: number, itemId: number) => {
    const newQuantity = isNaN(quantity) ? 1 : quantity;

    debouncedQuoteItemQuantityUpdate({ quantity: newQuantity, id: itemId });
  };

  const handleQuoteItemDeletion = (quoteItemId: number) => {
    deleteMutation.mutate(quoteItemId);
  };

  const nonContractualDataMutationMap: Record<
    NonContractualMutationType,
    UseMutateFunction<
      Quote,
      unknown,
      Partial<QuoteProductItem>,
      {
        previousQuote: Quote;
      }
    >
  > = {
    default: updateItemNonContractualMutation.mutate,
    position: updateItemPositionMutation.mutate,
  };

  const handleUpdateProductItemNonContractualData = (
    data: Partial<QuoteProductItem>,
    type: NonContractualMutationType = 'default'
  ) => nonContractualDataMutationMap[type](data);
  //endregion

  return {
    handleQuoteItemQuantityUpdate,
    handleQuoteItemDeletion,
    handleQuoteItemUpdateByKey,
    handleMakeCustom,
    updateProductItemNonContractualDataHandler: handleUpdateProductItemNonContractualData,
    handleQuoteItemUpdateDiscount,
    itemLoading:
      deleteMutation.isLoading ||
      itemBlockingMutation.isLoading ||
      pesimisticBlocingItemMutation.isLoading,
  };
};
