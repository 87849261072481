import { AppConfiguration } from 'libs/api/configuration/types';
import { Column } from 'exceljs';
import { Quote, QuoteDeliveryItem, QuoteProductItem } from 'libs/api/quotes/types';
import { SalesQuoteDiscount } from 'libs/api/common/types';
import { TranslationKey } from '../../types/react-i18next';
import {
  convertTimeRange,
  getFormatCurrencySymbol,
  getFormatLeadTime,
  useFormatPrice,
} from '@clippings/paper';
import { makeContractFieldsValues } from '../QuoteTable';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

export type ColumnModel = {
  order: number;
  header: TranslationKey;
  key: string;
  converter?: (item: QuoteProductItem) => number | string;
  column?: Partial<Column>;
};
export type TotalColumn = {
  converter: (quote: Quote) => string;
} & Pick<ColumnModel, 'header' | 'key'>;

type FieldsExcel = Exclude<AppConfiguration['lineItemVisibleFields'][number], 'taxRate'>;

export const ROW_HEIGHT = 120;
export const WIDTH_RATION = 6; // Height is in pixels, width with value 1 respond to 6 px

export const useColumns = (currency: string) => {
  const { t, i18n } = useTranslation();

  const formatLeadTime = getFormatLeadTime(t);
  const formatPrice = useFormatPrice();
  const CURRENCY_SYMBOL = getFormatCurrencySymbol(i18n.language)(currency);

  const PRICE_COLUMN_FORMAT: Partial<Column> = {
    numFmt: `"${CURRENCY_SYMBOL}"###0.00;[Red]\-"${CURRENCY_SYMBOL}"###0.00`, // eslint-disable-line no-useless-escape
    alignment: { horizontal: 'right' },
  };
  const PERCENT_COLUMN_FORMAT: Partial<Column> = {
    // adding quotes to "%" due to the library always multiplying percentages by 100,
    // by adding the quotes, we omit the multiplication and the value remains the same
    numFmt: '#0"%";',
    alignment: { horizontal: 'right' },
  };
  const BIG_COLUMN_FORMAT = {
    width: 300 / WIDTH_RATION,
  };

  const EXCEL_COLUMNS = (
    leadTimeFormat: AppConfiguration['leadTimeFormat'],
    fields: AppConfiguration['lineItemVisibleFields']
  ): ColumnModel[] => {
    const dynamicColumnsConfig: Record<FieldsExcel, ColumnModel[] | ColumnModel> = {
      order: [],
      info: [
        {
          order: 1,
          header: 'common.image',
          key: 'image',
          column: { width: ROW_HEIGHT / WIDTH_RATION },
        },
        {
          order: 2,
          header: 'common.product',
          key: 'product',
          converter: (item: QuoteProductItem) => item.productName,
          column: BIG_COLUMN_FORMAT,
        },
      ],
      leadTime: {
        order: 6,
        header: 'common.leadTime',
        key: 'leadTime',
        converter: (item: QuoteProductItem) =>
          formatLeadTime(convertTimeRange(item.leadTime, 'days', leadTimeFormat), leadTimeFormat),
        column: { alignment: { horizontal: 'right' } },
      },
      price: {
        order: 7,
        header: 'common.price',
        key: 'price',
        column: PRICE_COLUMN_FORMAT,
        converter: (item: QuoteProductItem) =>
          formatPrice(item.catalogueBasePrice.amount, item.catalogueBasePrice.currency),
      },
      discount: {
        order: 8,
        header: 'common.discount',
        key: 'discount',
        converter: (item: QuoteProductItem) => item.discountTitle,
        column: PERCENT_COLUMN_FORMAT,
      },
      net: {
        order: 9,
        header: 'common.netPrice',
        key: 'netPrice',
        column: PRICE_COLUMN_FORMAT,
        converter: (item: QuoteProductItem) =>
          formatPrice(item.netPrice.amount, item.netPrice.currency),
      },
      quantity: {
        order: 10,
        header: 'common.quantity',
        key: 'quantity',
        column: { alignment: { horizontal: 'right' } },
      },
      subTotal: {
        order: 11,
        header: 'common.subTotal',
        key: 'subtotal',
        converter: (item: QuoteProductItem) =>
          formatPrice(item.subtotal.amount, item.subtotal.currency),
        column: PRICE_COLUMN_FORMAT,
      },
      contractListPrice: {
        order: 13,
        header: 'quotes.table.contractListPrice',
        key: 'contractListPrice',
        column: {
          width: 30,
        },
        converter: (item: QuoteProductItem) => {
          const { contractListPrice } = makeContractFieldsValues(item);

          return formatPrice(contractListPrice.amount, contractListPrice.currency);
        },
      },
      contractNetPrice: {
        order: 12,
        header: 'quotes.table.contractNetPrice',
        key: 'contractNetPrice',
        column: {
          width: 30,
        },
        converter: (item: QuoteProductItem) => {
          const { contractNetPrice } = makeContractFieldsValues(item);

          return formatPrice(contractNetPrice.amount, contractNetPrice.currency);
        },
      },
      contractSubTotalPrice: {
        order: 13,
        header: 'quotes.table.contractSubTotalPrice',
        key: 'contractSubTotalPrice',
        column: {
          width: 30,
        },
        converter: (item: QuoteProductItem) => {
          const { contractSubTotalPrice } = makeContractFieldsValues(item);

          return formatPrice(contractSubTotalPrice.amount, contractSubTotalPrice.currency);
        },
      },
    };

    const columns: ColumnModel[] = [
      {
        order: 3,
        header: 'common.brand',
        key: 'brandName',
      },
      {
        order: 4,
        header: 'common.variation',
        key: 'variationName',
        column: BIG_COLUMN_FORMAT,
      },
      {
        order: 5,
        header: 'common.sku',
        key: 'vendorSku',
        column: BIG_COLUMN_FORMAT,
      },
    ];

    (fields as FieldsExcel[]).forEach(field => {
      const column = dynamicColumnsConfig[field];
      if (!column) {
        return;
      }

      if (Array.isArray(column)) {
        columns.push(...column);
        return;
      }

      columns.push(column);
    });

    return orderBy(columns, 'order', 'asc');
  };

  const shippingColumnsModel = {
    header: 'common.shipping',
    key: 'shippingTotal',
    converter: (deliveryItem: QuoteDeliveryItem) => {
      return formatPrice(
        deliveryItem.netPrice?.amount || 0,
        deliveryItem.netPrice?.currency || currency
      );
    },
  };

  const discountsColumnsModel = {
    header: 'common.discounts',
    key: 'discounts',
    converter: (discountItem: SalesQuoteDiscount) => {
      return formatPrice(discountItem.price?.amount || 0, discountItem.price?.currency || currency);
    },
  };

  const paymentTermsColumnsModel = [
    {
      header: 'common.amount',
      key: 'amount',
    },
    {
      header: 'common.type',
      key: 'type',
    },
    {
      header: 'paymentTerms.dueReason',
      key: 'dueEvent',
    },
  ] as const;

  const subTotalColumnsModel = [
    {
      header: 'common.subTotal',
      key: 'subtotal',
      converter: (quote: Quote) => formatPrice(quote.subtotal.amount, quote.subtotal.currency),
    },
  ];

  const TOTAL_COLUMNS: TotalColumn[] = [
    {
      header: 'common.tax',
      key: 'tax',
      converter: (quote: Quote) => formatPrice(quote.tax.amount, quote.tax.currency),
    },
    {
      header: 'common.total',
      key: 'total',
      converter: (quote: Quote) => formatPrice(quote.total.amount, quote.subtotal.currency),
    },
  ];

  return {
    EXCEL_COLUMNS,
    shippingColumnsModel,
    discountsColumnsModel,
    paymentTermsColumnsModel,
    subTotalColumnsModel,
    TOTAL_COLUMNS,
  };
};

export type ColumnsModel = ReturnType<typeof useColumns>;
