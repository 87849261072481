import React, { useRef } from 'react';
import {
  CompanyData,
  PdfExportLayoutContainer,
  PdfLayoutDivider,
  PdfSkeleton,
  SaveAsPdfButton,
  Title,
} from 'libs/shared';
import { FlexColumn, useTranslation } from '@clippings/paper';
import { OrderItemsDetails } from '../OrderDetails/OrderItemsDetails';
import { OrderSummary } from '../OrderDetails/OrderSummary';
import { PaymentInfoSummary } from 'libs/Quotes/Checkout/components/PaymentInfoSummary';
import { getOrderIdLabel } from '../utils';
import { useOrder } from 'libs/api/order/hooks';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import '../../shared/pdfExport/PdfExport.scss';

export function OrderPdfExportPage() {
  const { t } = useTranslation();
  const { number } = useParams() as { number: string };
  const orderQuery = useOrder(number);
  const pageRef = useRef(null);

  const orderIdLabel = getOrderIdLabel(orderQuery.data?.vendorId ?? null);
  const title = `${t('orders.orderNumber')}${orderIdLabel} ${number?.toUpperCase()}`;

  useTitle(title);

  if (orderQuery.isLoading) {
    return <PdfSkeleton />;
  }

  return (
    <PdfExportLayoutContainer ref={pageRef}>
      <SaveAsPdfButton documentTitle={`Order-${number}`} pageRef={pageRef} />
      <FlexColumn gap={1}>
        <CompanyData />
        <PdfLayoutDivider />
        <Title>
          {title} {orderIdLabel}
        </Title>
        {orderQuery.isSuccess ? (
          <PaymentInfoSummary
            gridXsSize={4}
            gridItemsSxSize={12}
            containerProps={paymentInfoContainerProps}
            item={orderQuery.data}
            payments={orderQuery.data.paymentSchedule}
            poNumber={orderQuery.data.poNumber ?? undefined}
            hasPaymentAmount={orderQuery.data.total.amount > 0}
          />
        ) : null}
        <OrderItemsDetails number={number} withInvoiceMenu={false} className="pdf-table" />
        <FlexColumn width="70%" alignSelf="flex-end" className="pdf-summary">
          <OrderSummary number={number} />
        </FlexColumn>
      </FlexColumn>
    </PdfExportLayoutContainer>
  );
}

const paymentInfoContainerProps = {
  className: 'pdf-payment-info',
};
