import { InfinitePaginatedQueryOptions, MutationOptions } from 'libs/api/common/types';
import { ListOrdersParams, Order, OrderItem, UpdateSalesOrderVariables } from './types';
import { getOrder, getOrders, updateSalesOrder } from './actions';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const orderQueryKeys = {
  getUseOrder: (number: Order['number']): ['order', Order['number']] => ['order', number],
  getListInfiniteOrders: (params?: ListOrdersParams): ['orders', ListOrdersParams | undefined] => [
    'orders',
    params,
  ],
};

export const useOrder = (orderId: Order['number']) =>
  useQuery(orderQueryKeys.getUseOrder(orderId), () => getOrder(orderId));

export const useListInfiniteOrders = (
  params?: ListOrdersParams,
  options?: InfinitePaginatedQueryOptions<OrderItem>
) =>
  useInfiniteQuery(
    orderQueryKeys.getListInfiniteOrders(params) as string[],
    ({ pageParam }) => getOrders({ ...params, page: pageParam ?? 1 }),
    {
      ...options,
      select: data => ({
        pageParams: data.pageParams,
        pages: [...data.pages].map(page => page.items),
      }),
      getNextPageParam: ({ currentPage, totalPages }) =>
        currentPage < totalPages ? currentPage + 1 : false,
    }
  );

export const useUpdateSalesOrder = (
  options?: MutationOptions<OrderItem, UpdateSalesOrderVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation(({ orderNumber, order }) => updateSalesOrder(orderNumber, order), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['orders']);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
