import React from 'react';
import { AccordionDetails, Box, FlexColumn } from '@clippings/paper';
import { ChatQuoteSystemMessage } from 'libs/Chat/components/messages/system/quote/ChatQuoteSystemMessage';
import { RegularMessage } from 'libs/SalesApp/Inbox/Messages/RegularMessage';

export const Message = ({ message }: { message: any }) => {
  if (message.type === 'system') {
    return (
      <Box mb={1}>
        <ChatQuoteSystemMessage message={message} />
      </Box>
    );
  }

  return <RegularMessage message={message} />;
};

export const MessagesList = ({ messages }: { messages: any[] }) => {
  return (
    <FlexColumn>
      <AccordionDetails>
        {messages.map(message => (
          <Message key={message.id} message={message} />
        ))}
      </AccordionDetails>
    </FlexColumn>
  );
};
