import React, { FC, PropsWithChildren } from 'react';
import { AddressField, addressFieldsConfigMap } from './addressFieldConfigs';
import {
  Brand,
  Confirmation,
  FomcoreAddressLocation,
  FormTextField,
  MenuItem,
  useFormContext,
  useWatch,
} from '@clippings/paper';
import { FormPhoneField } from '../FormPhoneField';
import { ShippingAddressFormData } from 'libs/types/addressForm';
import { isCountryUS } from 'libs/Utils';
import { renderLoadingInput } from 'libs/shared/quote.utils';
import { useAddressDefaultCountry } from 'libs/shared/hooks/useAddressDefaultCountry';
import { useAppConfiguration } from 'libs/providers';
import { useCountriesAndStates } from 'libs/shared';
import { useTranslation } from 'react-i18next';

export type AddressFormFieldsProps = {
  type: 'shipping' | 'billing' | 'company';
  detailed?: boolean;
};

const getMetadataName = (name: AddressField) => `metadata.${name}`;
const getMandatoryFieldProps = (name: AddressField, isMetadata = false) => ({
  name: isMetadata ? getMetadataName(name) : name,
  key: name,
  fullWidth: true,
});

export const AddressFormFields: FC<PropsWithChildren<AddressFormFieldsProps>> = ({
  type,
  children,
  detailed = true,
}) => {
  const { countries, states, isLoading } = useCountriesAndStates();
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();
  const form = useFormContext<ShippingAddressFormData>();
  const selectedCountry = useWatch({ name: AddressField.Country });
  const locationType: FomcoreAddressLocation | undefined = useWatch({
    name: getMetadataName(AddressField.LocationType),
  });
  const isUSA = isCountryUS(selectedCountry);
  const fieldsConfig = addressFieldsConfigMap[type];
  useAddressDefaultCountry(countries, form);

  const isActiveBrandFomcore = brand === Brand.Fomcore;

  const fields: Record<AddressField, JSX.Element | null> = {
    [AddressField.Email]: (
      <FormTextField {...getMandatoryFieldProps(AddressField.Email)} label={t('common.email')} />
    ),
    [AddressField.Country]: renderLoadingInput(
      <FormTextField
        {...getMandatoryFieldProps(AddressField.Country)}
        required
        select
        label={t('common.country')}
        SelectProps={{
          // @ts-expect-error prop type has no defined data-testid prop
          'data-testid': 'address-country',
        }}
      >
        {countries.map(country => (
          <MenuItem
            data-testid={`country-select-item-${country.shortName}`}
            key={country.id}
            value={country.shortName}
          >
            {country.name}
          </MenuItem>
        ))}
      </FormTextField>,
      isLoading,
      'country-loading'
    ),
    [AddressField.Zip]: (
      <FormTextField
        required={detailed}
        {...getMandatoryFieldProps(AddressField.Zip)}
        label={t('common.zipCode')}
      />
    ),
    [AddressField.State]: isUSA
      ? renderLoadingInput(
          <FormTextField
            {...getMandatoryFieldProps(AddressField.State)}
            label={t('common.state')}
            variant="standard"
            select
            required
            SelectProps={{
              // @ts-expect-error prop type has no defined data-testid prop
              'data-testid': 'address-state',
            }}
          >
            {states.map(state => (
              <MenuItem
                key={state.id}
                value={state.id}
                data-testid={`address-state-option-${state.id}`}
              >
                {state.name}
              </MenuItem>
            ))}
          </FormTextField>,
          isLoading,
          'state-loading'
        )
      : null,
    [AddressField.Province]: !isUSA ? (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.Province)}
        label={t('common.stateProvince')}
      />
    ) : null,
    [AddressField.City]: (
      <FormTextField
        required={detailed}
        {...getMandatoryFieldProps(AddressField.City)}
        label={t('common.city')}
      />
    ),
    [AddressField.Address]: (
      <FormTextField
        required={detailed}
        {...getMandatoryFieldProps(AddressField.Address)}
        label={t('common.address')}
      />
    ),
    [AddressField.Address2]: (
      <FormTextField fullWidth key="address2" name="address2" label={`${t('common.address')} 2`} />
    ),
    [AddressField.ContactName]: (
      <FormTextField
        required={detailed}
        {...getMandatoryFieldProps(AddressField.ContactName)}
        label={t(
          isActiveBrandFomcore && type === 'shipping'
            ? 'common.deliveryContactName'
            : 'common.contactName'
        )}
      />
    ),
    // Fomcore Shipping Address Company Name
    [AddressField.ShippingCompanyName]: (
      <FormTextField
        required={detailed}
        {...getMandatoryFieldProps(AddressField.CompanyName, true)}
        label={t('common.companyName')}
      />
    ),
    [AddressField.AccessDetails]: (
      <FormTextField
        multiline={isActiveBrandFomcore}
        {...getMandatoryFieldProps(AddressField.AccessDetails)}
        label={t('quotes.addresses.accessDetailsLabel')}
        helperText={t('quotes.addresses.accessDetailsHint')}
      />
    ),
    [AddressField.Attention]: (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.Attention, true)}
        label={t('common.attention')}
      />
    ),
    [AddressField.Dock]: (
      <FormTextField
        required={detailed}
        {...getMandatoryFieldProps(AddressField.Dock, true)}
        label={t('quotes.addresses.dock')}
        select
        SelectProps={{
          // @ts-expect-error prop type has no defined data-testid prop
          'data-testid': 'address-dock',
        }}
      >
        {Object.values(Confirmation).map(confirmation => (
          <MenuItem key={confirmation} value={confirmation} data-testid={`dock-${confirmation}`}>
            {t(`common.${confirmation}`)}
          </MenuItem>
        ))}
      </FormTextField>
    ),
    [AddressField.TrackingNotificationEmailAddress]: (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.TrackingNotificationEmailAddress, true)}
        required={detailed}
        label={t('common.trackingNotificationEmailAddress')}
      />
    ),
    [AddressField.LocationType]: (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.LocationType, true)}
        required={detailed}
        label={t('quotes.addresses.locationType')}
        select
        SelectProps={{
          // @ts-expect-error prop type has no defined data-testid prop
          'data-testid': 'address-location-type',
        }}
      >
        {Object.values(FomcoreAddressLocation).map(locationType => (
          <MenuItem
            key={locationType}
            value={locationType}
            data-testid={`location-type-${locationType}`}
          >
            {t(`common.locationTypes.${locationType}`)}
          </MenuItem>
        ))}
      </FormTextField>
    ),
    [AddressField.LocationTypeOther]:
      locationType === FomcoreAddressLocation.Other ? (
        <FormTextField
          {...getMandatoryFieldProps(AddressField.LocationTypeOther, true)}
          required={detailed}
          label={t('quotes.addresses.locationTypeOther')}
        />
      ) : null,
    [AddressField.Phone]: (
      <FormPhoneField
        {...getMandatoryFieldProps(AddressField.Phone)}
        label={t('common.phoneNumber')}
        // quick dirty fix, TODO: Find how to check whether the nested in 'when' condition schema is required
        // Currently, only a regular unconditional schema supports it via 'schema.describe()'
        required={detailed && isActiveBrandFomcore}
      />
    ),
    [AddressField.Nickname]: (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.Nickname)}
        required
        label={t('quotes.addresses.addressNicknameLabel')}
        helperText={t('quotes.addresses.addressNicknameHint')}
      />
    ),
    [AddressField.CompanyName]: (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.CompanyName)}
        label={t('common.companyName')}
      />
    ),
    [AddressField.VatNumber]: (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.VatNumber)}
        label={t('common.vatNumber')}
      />
    ),
    [AddressField.BillingEmail]: (
      <FormTextField
        {...getMandatoryFieldProps(AddressField.Email)}
        required={detailed}
        label={t('quotes.addresses.billingEmailLabel')}
        helperText={t('quotes.addresses.billingEmailHint')}
      />
    ),
  };

  return (
    <>
      {fieldsConfig[brand ?? Brand.Moroso].map(field => fields[field])}
      {children}
    </>
  );
};
