import { Company } from 'libs/api/iam/types';
import { PaymentRule } from 'libs/api/common/types';
import { getFormErrorMessage } from '@clippings/paper';
import { getServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { translateValidationError, validatePaymentRules } from 'libs/SalesApp/Quotes/paymentTerms';
import { useBanner } from 'libs/Components';
import { useState } from 'react';
import { useUpdateCompanyPaymentTerms } from 'libs/api/iam/hooks';

export function usePaymentTermsManager(
  company: Company,
  originalPaymentTerms: PaymentRule[],
  onClose: () => void
) {
  const { showErrorBanner } = useBanner();
  const [paymentTerms, setPaymentTerms] = useState(originalPaymentTerms);

  const updatePaymentTermsMutation = useUpdateCompanyPaymentTerms({
    onSuccess: onClose,
    onError: error => {
      if (error.response?.status === 422) {
        const formErrors = getServerFormErrors(error);
        const message = Object.keys(formErrors)
          .map(key => getFormErrorMessage(formErrors, key))
          .join('. ');

        showErrorBanner(message);
      }
    },
  });

  const handleApply = () => {
    const errors = validatePaymentRules(paymentTerms);

    if (errors.length > 0) {
      showErrorBanner(errors.map(translateValidationError).join('. '));
    } else {
      updatePaymentTermsMutation.mutate({
        companyId: company.id,
        paymentTerms: paymentTerms.map(paymentRule => ({
          ...paymentRule,
          id: Number(paymentRule.id) < 0 ? undefined : paymentRule.id,
        })),
      });
    }
  };

  const handleChange = (index: number, rule: PaymentRule | null) => {
    const newPaymentTerms = [...paymentTerms];

    if (rule === null) {
      newPaymentTerms.splice(index, 1);
    } else {
      newPaymentTerms.splice(index, 1, rule);
    }

    setPaymentTerms(newPaymentTerms);
  };

  const handleAddingNewPaymentRule = () => {
    setPaymentTerms(old => [...old, createNewPaymentRule()]);
  };

  return {
    paymentTerms,
    isLoading: updatePaymentTermsMutation.isLoading || updatePaymentTermsMutation.isSuccess,
    handleApply,
    handleChange,
    handleAddingNewPaymentRule,
  };
}

////
let newItemIndex = -1;

const createNewPaymentRule = (): PaymentRule => {
  const id = String(newItemIndex);

  newItemIndex--;

  return {
    id,
    type: 'event',
    dueEvent: 'on_order',
    dueDays: 0,
    percentage: null,
  };
};
