export default function path(pathParam: string, replaceParams: Record<string, any>) {
  const result = Object.entries(replaceParams).reduce((accumulator, [key, value]) => {
    if (!value || typeof value === 'object') {
      throw new Error('Invalid value');
    }
    return accumulator.replace(`:${key}`, value);
  }, pathParam);

  if (result.match(':')) {
    throw new Error('Invalid or missing key');
  }

  return result;
}
