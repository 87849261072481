import {
  CreateOrder,
  Document,
  ListOrdersParams,
  Order,
  OrderItem,
  SalesOrderPatchDto,
} from './types';
import { Paginated } from 'libs/api/common/types';
import { axios, path } from 'libs/Utils';

export const PAGE_SIZE = 4;

export const apiPaths = {
  orders: '/api/order/v1/sales-orders',
  index: '/api/order/v1/sales-orders/:number',
  createOrder: '/api/order/v1/sales-orders',
  getDocuments: '/api/order/v1/sales-orders/:number/documents',
  order: '/api/order/v1/sales-orders/:orderNumber',
};

export const getOrders = (params: ListOrdersParams) =>
  axios.get<Paginated<OrderItem>>(apiPaths.orders, { params }).then(response => response.data);

export const getOrder = (number: string) =>
  axios.get<Order>(path(apiPaths.index, { number })).then(response => response.data);

export const createOrder = (data: CreateOrder) => axios.post(apiPaths.createOrder, data);

export const getDocuments = (number: string) =>
  axios
    .get<Document[]>(path(apiPaths.getDocuments, { number })) //
    .then(response => response.data);

export const updateSalesOrder = (orderNumber: string, item: SalesOrderPatchDto) =>
  axios
    .patch<OrderItem>(path(apiPaths.order, { orderNumber }), item)
    .then(response => response.data);
