import { DateTimeFormat, Theme, alpha, getFormatDateTime } from '@clippings/paper';
import { QuoteSystemStatusChangeEvent } from 'libs/Chat/components/messages/system/types';
import { StreamMessage } from 'stream-chat-react';
import { differenceInCalendarDays, differenceInCalendarYears } from 'date-fns';
import { useTranslation } from 'react-i18next';

type QuoteStatusMessageColor = Pick<
  Theme['palette'],
  'primary' | 'secondary' | 'success' | 'info' | 'error' | 'warning'
>;

export const QUOTE_STATUS_EVENT_COLOR_MAP: Record<
  QuoteSystemStatusChangeEvent,
  keyof QuoteStatusMessageColor
> = {
  quoteApprove: 'info',
  quoteArchive: 'secondary',
  ordered: 'success',
  quoteRequest: 'warning',
  quoteCancel: 'error',
  quoteExpire: 'error',
  quoteUnarchive: 'secondary',
  versionRestored: 'secondary',
  quoteCreated: 'info',
  versionReady: 'success',
};

export const QUOTE_STATUS_TRANSLATION_MAP = {
  quoteApprove: 'common.approved',
  quoteArchive: 'common.archived',
  quoteRequest: 'common.requested',
  quoteCancel: 'common.cancelled',
  quoteExpire: 'common.expired',
  quoteUnarchive: 'common.restored',
  versionRestored: 'common.renewed',
  quoteCreated: 'common.created',
  versionReady: 'quotes.ready',
} as const;

export const useGetSystemMessageDateTime = () => {
  const { i18n } = useTranslation();

  /**
   * Returns date (Apr 29, 1453), if date is past year.
   *
   * Returns date (Apr 29), if date is past day.
   *
   * Returns time (6:53PM) e.g. if date is today.
   *
   * @param created_at Date created
   */
  const getSystemMessageDateTime = (
    created_at: Exclude<StreamMessage['created_at'], undefined>
  ) => {
    const createdAtDate = new Date(created_at);
    const now = Date.now();
    const format = getFormatDateTime(i18n.language);

    if (differenceInCalendarYears(createdAtDate, now)) {
      return format(createdAtDate, DateTimeFormat.Date);
    }

    if (differenceInCalendarDays(createdAtDate, now)) {
      return format(createdAtDate, DateTimeFormat.Date);
    }

    return format(createdAtDate, DateTimeFormat.Time).replace(' ', '');
  };

  return { getSystemMessageDateTime };
};

export const getQuoteSystemMessageColor = (theme: Theme, event?: QuoteSystemStatusChangeEvent) =>
  event === QuoteSystemStatusChangeEvent.Archived
    ? alpha(theme.palette.common.black, 0.38)
    : theme.palette.common.black;

export const getQuoteStatusBackgroundColor = (theme: Theme, event: QuoteSystemStatusChangeEvent) =>
  alpha(theme.palette[QUOTE_STATUS_EVENT_COLOR_MAP[event]].main, 0.07);
