import { LeadTimeFilterRef } from '../components/filters/LeadTimeFilter';
import { useAppConfiguration } from 'libs/providers';
import { useLeadTimeVisibility } from '@clippings/paper';
import { useRef, useState } from 'react';

export function useLeadTimeFilter() {
  const { instance } = useAppConfiguration();
  const { shouldRenderLeadTimeFields } = useLeadTimeVisibility(instance);
  const [appliedLeadTimeFilter, setAppliedLeadTimeFilter] = useState<string | null>(null);
  const leadTimeFilterRef = useRef<LeadTimeFilterRef>(null);

  function leadTimeFilterResetHandler() {
    if (leadTimeFilterRef.current) {
      leadTimeFilterRef.current.reset();
    }
  }

  return {
    appliedLeadTimeFilter,
    setAppliedLeadTimeFilter,
    leadTimeFilterResetHandler,
    leadTimeFilterRef,
    shouldRenderLeadTimeFields,
  };
}
