import React, { FC } from 'react';
import { AddNewLineInput } from './AddNewLineInput';
import {
  ApplicableDeliveryMethod,
  DeliveryMethodEvaluationResponse,
  Quote,
} from 'libs/api/quotes/types';
import { CellPopover, TableCell } from '@clippings/paper';
import { DeliveryMethodsPopover } from './DeliveryMethodsPopover';
import { evaluateDeliveryMethodsForQuote, queryKeys } from 'libs/Quotes/quoteActions';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export interface AddDeliveryItemCellProps {
  onAddPredefined: (method: ApplicableDeliveryMethod) => void;
  onAdd: (name: string) => void;
  placeholder: string;
  inputValue: string;
  inputChangeHandler: (value: string) => void;
  quote: Quote;
}
export const AddDeliveryItemCell: FC<AddDeliveryItemCellProps> = ({
  onAdd,
  onAddPredefined,
  placeholder,
  inputValue,
  inputChangeHandler,
  quote,
}) => {
  const { t } = useTranslation();

  const deliveryMethods = useQuery<DeliveryMethodEvaluationResponse>(
    queryKeys.getQuoteDeliveryMethods(quote),
    () =>
      evaluateDeliveryMethodsForQuote({
        quoteVersionId: quote.versionId,
        catalogueRef: quote.catalogue,
        deliveryAddress: {
          country: quote.shippingAddress?.country?.name,
          city: quote.shippingAddress?.city,
          state: quote.shippingAddress?.state?.name,
        },
      }),
    { enabled: !!quote.versionId && !!quote.shippingAddress && !!quote.catalogue }
  );

  return (
    <CellPopover
      infoLabel={t('quotes.searchInfoLabel')}
      PopoverContent={({ onClose }) => (
        <DeliveryMethodsPopover
          deliveryMethods={deliveryMethods?.data?.items ?? []}
          onSetDeliveryMethod={method => {
            onClose();
            onAddPredefined(method);
          }}
        />
      )}
    >
      <TableCell>
        <AddNewLineInput
          value={inputValue}
          disabled={!quote.shippingAddress}
          placeholder={placeholder}
          onCreate={onAdd}
          onChange={e => inputChangeHandler(e.target.value)}
          inputProps={{
            'data-testid': 'delivery-items-table-add-input',
          }}
        />
      </TableCell>
    </CellPopover>
  );
};
