import React from 'react';
import { Skeleton } from '@clippings/paper';

export const ChipSkeleton: React.FC = () => (
  <Skeleton width="100px" height="32px" sx={{ borderRadius: '16px' }} variant="rectangular" />
);

export const PaymentInfoSummarySkeleton: React.FC = () => (
  <Skeleton width="100%" height="280px" sx={{ borderRadius: 1 }} variant="rectangular" />
);

export const DetailsSkeleton: React.FC = () => (
  <Skeleton width="100%" height="250px" sx={{ borderRadius: 1 }} variant="rectangular" />
);

export const SalesRepSkeleton: React.FC = () => (
  <Skeleton
    height="40px"
    sx={{ width: { xs: '200px', md: '300px' }, borderRadius: 1 }}
    variant="rectangular"
  />
);

export const InvoiceMenuSkeleton: React.FC = () => (
  <Skeleton width="100px" height="36px" sx={{ borderRadius: 1 }} variant="rectangular" />
);

export const DeliveryTimelineSkeleton: React.FC = () => (
  <Skeleton width="100%" height="200px" sx={{ borderRadius: 1 }} variant="rectangular" />
);

export const PaymentScheduleSkeleton: React.FC = () => (
  <Skeleton width="100%" height="136px" sx={{ borderRadius: 1 }} variant="rectangular" />
);

export const HelpSkeleton: React.FC = () => (
  <Skeleton width="100%" height="84px" sx={{ borderRadius: 1 }} variant="rectangular" />
);
