import React from 'react';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { PromotionPanel } from '../PromotionPanel';
import { RegisterForm, RegisterFormStep } from '@clippings/paper';
import { useRegister } from '../hooks/useRegister';

export const Register = ({ ...rest }) => {
  const {
    activeStep,
    setActiveStep,
    handleSubmit,
    handleSignInClick,
    handleBackToBrowseClick,
    handleBackToAccountDetails,
    formErrors,
    locations,
    companyTypes,
    registerMutation,
  } = useRegister();

  if (locations.isLoading) {
    return null;
  }

  return (
    <AuthenticationLayout
      onBackButtonClick={
        activeStep === RegisterFormStep.CompanyDetails ? handleBackToAccountDetails : undefined
      }
      content={
        <RegisterForm
          loading={registerMutation.isLoading || registerMutation.isSuccess}
          locations={locations.data ?? []}
          companyTypes={companyTypes}
          activeStep={activeStep}
          onActiveStepChange={setActiveStep}
          serverErrors={formErrors}
          onSubmit={handleSubmit}
          onSignInClick={handleSignInClick}
          onBackToBrowseClick={handleBackToBrowseClick}
          {...rest}
        />
      }
      sidePanel={<PromotionPanel />}
    />
  );
};
