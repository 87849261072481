import { Nullable } from 'libs/Utils';
import { SortDirection } from 'libs/api/common/types';
import { useState } from 'react';

export function useTableSorting<QueryParams>(
  queryParams: QueryParams,
  setQueryParams: (params: QueryParams) => void
) {
  const [sort, setSort] = useState<QueryParams>(queryParams);

  const updateSorting = (updated: QueryParams) => {
    setQueryParams(updated);
    setSort(current => ({ ...current, ...updated }));
  };

  return { sort, updateSorting };
}

export type SortingProps = {
  sort?: Nullable<string>;
  order?: Nullable<SortDirection>;
};
