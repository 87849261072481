import React from 'react';
import { DataTestId } from 'libs/shared';
import { Grid, Typography } from '@clippings/paper';

export interface UserListHeadingProps extends DataTestId {
  label: string;
  columns: number;
}

export const ListTableHeaderCell: React.FC<UserListHeadingProps> = ({
  label,
  columns,
  ...rest
}) => (
  <Grid item xs={columns}>
    <Typography
      {...rest}
      variant="body2"
      color="secondary"
      fontWeight="medium"
      lineHeight={theme => theme.typography.pxToRem(24)}
    >
      {label}
    </Typography>
  </Grid>
);
