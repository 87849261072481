import React from 'react';
import { Button, Skeleton } from '@clippings/paper';
import { Link } from 'react-router-dom';
import { QUOTE_STATUSES } from 'libs/Constants';
import { QuoteCheckoutButton } from 'libs/shared/components/FooterButtons/QuoteCheckoutButton';
import { QuoteRenewButton } from 'libs/shared/components/FooterButtons/QuoteRenewButton';
import { QuoteRequestButton } from 'libs/shared/components/FooterButtons/QuoteRequestButton';
import { QuoteRestoreButton } from 'libs/shared/components/FooterButtons/QuoteRestoreButton';
import { canCheckoutQuote, hasOrderedVersion } from 'libs/shared';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useQuoteCheckoutHandler } from '../Checkout/hooks';
import { useQuoteProvider } from '../providers';
import { useRequestQuote } from 'libs/shared/hooks/useRequestQuote';
import { useTranslation } from 'react-i18next';

export const QuoteFooterActionButtons = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser()?.data;

  const {
    quote,
    loading,
    onUnarchiveQuoteVersion,
    onRestoreQuoteVersion,
    handleShippingModalOpen,
    handleBillingModalOpen,
  } = useQuoteProvider();
  const { status, number } = quote;
  const { getOrderShowPath } = useOrderPaths();
  const onRequest = useRequestQuote();
  const { quoteCheckoutHandler } = useQuoteCheckoutHandler(
    handleShippingModalOpen,
    handleBillingModalOpen
  );

  const handleRestoreCurrentVersion = () => {
    if (status === QUOTE_STATUSES.ARCHIVED) {
      onUnarchiveQuoteVersion();
    } else {
      onRestoreQuoteVersion();
    }
  };

  if (loading) {
    return (
      <Skeleton
        data-testid="quote-footer-action-buttons-loading"
        width={140}
        height={36}
        variant="rectangular"
      />
    );
  }

  if (currentUser && canCheckoutQuote(status, currentUser)) {
    return <QuoteCheckoutButton quote={quote} onClick={quoteCheckoutHandler} />;
  }

  if (status === QUOTE_STATUSES.ARCHIVED) {
    return <QuoteRestoreButton quote={quote} onClick={handleRestoreCurrentVersion} />;
  }

  if (status === QUOTE_STATUSES.DRAFT) {
    return <QuoteRequestButton onRequest={onRequest} />;
  }

  if (status === QUOTE_STATUSES.ORDERED) {
    return (
      <Button
        variant="contained"
        component={Link}
        to={getOrderShowPath(number)}
        data-testid="quote-footer-action-buttons-track-order"
      >
        {t('common.trackOrder')}
      </Button>
    );
  }

  const quoteHasOrderedVersion = hasOrderedVersion(quote.versions);

  if (status === QUOTE_STATUSES.EXPIRED && !quoteHasOrderedVersion) {
    return <QuoteRenewButton quote={quote} onClick={handleRestoreCurrentVersion} />;
  }

  return null;
};
