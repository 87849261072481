import React from 'react';
import { Button } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { useQuoteTransitionAction } from 'libs/shared/hooks/useQuoteTransitionAction';
import { useTranslation } from 'react-i18next';

export interface QuoteRestoreButtonProps {
  quote: Quote;
  onClick: () => void;
}

export const QuoteRestoreButton: React.FC<QuoteRestoreButtonProps> = ({ quote, onClick }) => {
  const { t } = useTranslation();
  const { shouldDisableTransitionButton } = useQuoteTransitionAction(quote, 'renew', []);

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      data-testid="quote-footer-action-buttons-restore"
      disabled={shouldDisableTransitionButton}
    >
      {t('quotes.footer.actions.restore')}
    </Button>
  );
};
