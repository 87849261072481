import { Catalogue, Category, GroupedProductsResponse, ProductDiscount } from './types';
import { PDPOption, PDPRule, Product } from '@clippings/paper';
import { axios, path } from 'libs/Utils';

export const apiPaths = {
  catalogues: '/api/catalogue/v1/catalogues',
  catalogue: '/api/catalogue/v1/catalogues/:catalogueSlug',
  product: '/api/catalogue/v1/catalogues/:catalogueSlug/products/:productId',
  productOptions: '/api/catalogue/v1/catalogues/:catalogueSlug/products/:productId/options',
  groupedProducts: '/api/catalogue/v1/catalogues/:catalogue/product-groups/:groupId',
  productDiscounts: '/api/catalogue/v1/catalogues/:catalogueSlug/products/:productId/discounts',
  productRules: '/api/catalogue/v1/products/:productId/rules',
  lookup: '/api/catalogue/v1/catalogues/lookup',
  categories: '/api/catalogue/v1/categories',
};

export const listCatalogues = () =>
  axios.get<Catalogue[]>(apiPaths.catalogues).then(response => response.data);

export const getCatalogue = (catalogueSlug: string) =>
  axios.get<Catalogue>(path(apiPaths.catalogue, { catalogueSlug })).then(response => response.data);

export const getProduct = (catalogueSlug: string, productId: string) =>
  axios
    .get<Product>(path(apiPaths.product, { catalogueSlug, productId }))
    .then(response => response.data);

export const getProductOptions = (catalogueSlug: string, productId: string) =>
  axios
    .get<PDPOption[]>(path(apiPaths.productOptions, { catalogueSlug, productId }))
    .then(response => response.data);

export const getProductRules = (catalogueSlug: string, productId: string) =>
  axios
    .get<PDPRule[]>(path(apiPaths.productRules, { productId }), {
      params: { catalogue: catalogueSlug },
    })
    .then(response => response.data);

export const getProductDiscount = (catalogueSlug: string, productId: string) =>
  axios
    .get<[ProductDiscount]>(path(apiPaths.productDiscounts, { catalogueSlug, productId }))
    .then(response => response.data);

export const getGroupedProducts = (catalogue: string, groupId: string | number) =>
  axios
    .get<GroupedProductsResponse>(path(apiPaths.groupedProducts, { catalogue, groupId }))
    .then(x => x.data.products);

export const lookupCatalogue = () => axios.get(apiPaths.lookup).then(response => response.data);

export const getCategories = (params: { size: number }) =>
  axios
    .get<{ items: Category[] }>(apiPaths.categories, { params })
    .then(response => response.data.items);
