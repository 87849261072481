import React, { ComponentProps } from 'react';
import { Divider } from '@clippings/paper';

type DividerGreyProps = Pick<ComponentProps<typeof Divider>, 'sx'>;

export function DividerGrey({ sx = {} }: DividerGreyProps) {
  return <Divider sx={{ ...sxDefault, ...sx }} />;
}

const sxDefault = { borderColor: 'grey.A200' };
