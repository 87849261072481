import React, { ComponentProps } from 'react';
import { BaseUser } from 'libs/api/common/types';
import { Box, Typography, UserAvatar, getUserFullName } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

interface UserAvatarRowProps extends ComponentProps<typeof Box> {
  user: BaseUser;
}

export const UserAvatarRow: React.FC<UserAvatarRowProps> = ({ user, ...rest }) => {
  const { t } = useTranslation();

  const fullName = getUserFullName(user);

  return (
    <Box display="flex" gap={1} {...rest}>
      {user && <UserAvatar user={user} small />}
      <Typography
        variant="body1"
        fontWeight="fontWeightMedium"
        color={user ? undefined : 'grey.A400'}
      >
        {fullName || t('common.notAssigned')}
      </Typography>
    </Box>
  );
};
