import { useMemo, useReducer } from 'react';

export type EntityReducerAction<T extends { id: string | number }> =
  | {
      type: 'add' | 'remove' | 'update';
      payload: T;
    }
  | {
      type: 'addAtPosition';
      payload: {
        entity: T;
        position: number;
      };
    }
  | {
      type: 'addMany';
      payload: T[];
    }
  | {
      type: 'replaceAll';
      payload: T[];
    };

/**
 * Returns a simple reducer function for entity management via add/remove based on the entity id
 */
export const getEntityReducer = <T extends { id: string | number }>() => {
  const reducer = (state: T[], action: EntityReducerAction<T>) => {
    switch (action.type) {
      case 'add':
        return [...state, action.payload];
      case 'addAtPosition': {
        const newState = [...state];
        newState.splice(action.payload.position, 0, action.payload.entity);
        return newState;
      }
      case 'remove':
        return state.filter(entity => entity.id !== action.payload.id);
      case 'update':
        return state.map(entity => (entity.id === action.payload.id ? action.payload : entity));
      case 'addMany':
        return [...state, ...action.payload];
      case 'replaceAll':
        return action.payload;
      default:
        return state;
    }
  };

  return reducer;
};

/**
 * Returns a reducer and state for entity management via add/remove based on the entity id
 */
export const useEntityReducer = <T extends { id: string | number }>(initialState: T[]) => {
  const reducer = useMemo(() => getEntityReducer<T>(), []);
  const [state, dispatch] = useReducer(reducer, initialState);

  const add = (entity: T) => dispatch({ type: 'add', payload: entity });
  const remove = (entity: T) => dispatch({ type: 'remove', payload: entity });
  const update = (entity: T) => dispatch({ type: 'update', payload: entity });
  const addMany = (entities: T[]) => dispatch({ type: 'addMany', payload: entities });
  const replaceAll = (entities: T[]) => dispatch({ type: 'replaceAll', payload: entities });
  const addAtPosition = (entity: T, position: number) =>
    dispatch({ type: 'addAtPosition', payload: { entity, position } });

  return {
    state,
    add,
    addAtPosition,
    update,
    remove,
    addMany,
    replaceAll,
  };
};
