import React, { ComponentProps } from 'react';
import { CloseableDialog } from '@clippings/paper';
import {
  PaymentTermsDialogContent,
  PaymentTermsDialogContentProps,
} from './PaymentTermsDialogContent';
import { useTranslation } from 'react-i18next';

export type PaymentTermsDialogProps = {
  open: boolean;
} & PaymentTermsDialogContentProps;

export const PaymentTermsDialog: React.FC<PaymentTermsDialogProps> = ({
  company,
  originalPaymentTerms,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <CloseableDialog
      title={t('paymentTerms.paymentTerms')}
      open={open}
      dialogProps={dialogProps}
      onClose={onClose}
    >
      <PaymentTermsDialogContent
        company={company}
        originalPaymentTerms={originalPaymentTerms}
        onClose={onClose}
      />
    </CloseableDialog>
  );
};

const dialogProps: ComponentProps<typeof CloseableDialog>['dialogProps'] = {
  fullWidth: true,
  maxWidth: 'md',
};
