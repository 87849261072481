import React from 'react';
import { DateTime, DateTimeFormat, Grid } from '@clippings/paper';
import { ListTableRow } from 'libs/Components/ListTable/ListTableRow';
import { User } from 'libs/api/iam/types';
import { UserListItemContacts } from './UserListItemContacts';
import { UserListItemMoreActions } from './UserListItemMoreActions';
import { UserListItemName } from './UserListItemName';
import { UserListItemSimple } from './UserListItemSimple';
import { UserListItemTeam } from './UserListItemTeam';
import { UserOnlineStatus } from './UserOnlineStatus';
import { filterNonSystemUserRoles } from '../utils';
import { useTranslation } from 'react-i18next';

export interface StaffUserListItemProps {
  user: User;
  onClick: (user: User) => void;
  disabled: boolean;
}

export const StaffUserListItem: React.FC<StaffUserListItemProps> = ({
  user,
  onClick,
  disabled,
}) => {
  const onRowClick = () => {
    if (disabled) return;

    onClick(user);
  };

  const { t } = useTranslation();
  const { roles } = user;

  const mappedRoles = React.useMemo(
    () =>
      filterNonSystemUserRoles(roles)
        .map(role => t(`roles.${role}`))
        .join(', '),
    [roles]
  );

  return (
    <ListTableRow
      data-testid={`user-list-item-container-${user.id}`}
      columns={19}
      onClick={onRowClick}
      sx={{ cursor: disabled ? 'unset' : 'pointer' }}
    >
      <UserListItemName user={user} xs={3} />
      <UserListItemSimple text={mappedRoles} xs={3} />
      <UserListItemTeam teams={user.teams} xs={3} />
      <UserListItemContacts user={user} xs={5} />
      <UserListItemSimple
        xs={2}
        text={
          user.createdAt ? (
            <DateTime format={DateTimeFormat.Date} date={user.createdAt} variant="body2" />
          ) : (
            '---'
          )
        }
      />
      <Grid item xs={2}>
        <UserOnlineStatus user={user} />
      </Grid>

      <Grid item xs={1} display="flex" justifyContent="flex-end">
        <UserListItemMoreActions email={user.email} />
      </Grid>
    </ListTableRow>
  );
};
