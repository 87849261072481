import React from 'react';
import { Button, LoadingButton, useTranslation } from '@clippings/paper';
import { Row } from 'libs/shared';

type DiscountsDialogActionsProps = {
  isLoading: boolean;
  onApply: () => void;
  onCancel: () => void;
};

export function DiscountsDialogActions({
  isLoading,
  onApply,
  onCancel,
}: DiscountsDialogActionsProps) {
  const { t } = useTranslation();

  return (
    <Row pt={3} justifyContent="flex-end">
      <Button data-testid="discount-dialog-cancel-button" color="secondary" onClick={onCancel}>
        {t('common.cancel')}
      </Button>

      <LoadingButton
        data-testid="discount-dialog-apply-button"
        variant="contained"
        color="primary"
        loading={isLoading}
        onClick={onApply}
      >
        {t('common.apply')}
      </LoadingButton>
    </Row>
  );
}
