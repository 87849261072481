import { FomcoreShippingAddressMetadata } from 'libs/api/common/types';

export const isShippingFormData = (
  formData: AddressFormData
): formData is ShippingAddressFormData => 'metadata' in formData;

export type BaseAddressFormData = {
  contactName: string;
  address: string;
  address2?: string;
  city: string;
  state?: string | null;
  province?: string | null;
  zip: string;
  country: string;
};

export type ShippingAddressFormData = BaseAddressFormData & {
  nickname: string;
  phone?: string;
  accessDetails?: string;
  metadata?: Record<string, any> | FomcoreShippingAddressMetadata;
};
export type BillingAddressFormData = BaseAddressFormData & {
  email: string;
  companyName?: string;
  vatNumber?: string | null;
};
export type AddressFormData = ShippingAddressFormData | BillingAddressFormData;

export interface GeneralInfoFormData {
  name: string;
  registrationNumber: string;
  taxNumber: string;
  type: string;
  website: string;
  phone: string;
  metadata: Record<string, string>;
  creditCardChargeFee: number;
}
