import React from 'react';
import { Nullable } from 'libs/Utils';
import { QuoteItemProperty } from 'libs/api/quotes/types';
import { Typography } from '@clippings/paper';
import { buildVariationName } from '../quote.utils';
import { useShouldIncludePropertyCode } from '../hooks';

type ProductVariationNameProps = {
  id: number;
  properties: Nullable<QuoteItemProperty[]>;
  productName: Nullable<string>;
  variationName: Nullable<string>;
};

export function ProductVariationName({ id, ...rest }: ProductVariationNameProps) {
  const { shouldIncludePropertyCode } = useShouldIncludePropertyCode();

  return (
    <Typography
      sx={{ whiteSpace: 'pre-line' }}
      data-testid={`custom-item-variation-${id}`}
      variant="body2"
    >
      {buildVariationName({ ...rest }, shouldIncludePropertyCode)}
    </Typography>
  );
}
