/**
 * @property {number} window.visitor.userId
 * @property {string} window.visitor.name
 * @property {string} window.visitor.fullName
 * @property {string} window.visitor.avatar
 * @property {string} window.visitor.email
 * @property {string} window.visitor.currency
 * @property {string} window.visitor.getStreamUserToken
 * @property {string} window.visitor.getStreamUserChatToken
 * @property {boolean} window.visitor.isAdmin
 * @property {boolean} window.visitor.isApprovedTradeCustomer
 * @property {boolean} window.visitor.isLoggedIn
 * @property {boolean} window.visitor.isTradeCustomer
 *
 */
export default window.visitor;
