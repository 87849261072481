import React, { FC, PropsWithChildren, useMemo } from 'react';
import { Column, ListCardLoader } from 'libs/shared';
import { DEFAULT_PAGE_SIZE } from 'libs/Utils';
import { EmptyTablePlaceholder } from 'libs/Components';
import { Link, LoadMoreDivider, ShowElement, Typography } from '@clippings/paper';
import { OrderCardListRenderer } from 'libs/Orders/components/OrderCardListRenderer';
import { OrderListTable } from './OrderListTable';
import { useListInfiniteOrders } from 'libs/api/order/hooks';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const OrderList: FC = () => {
  const { t } = useTranslation();
  const returnUrl = useReturnUrl();
  const orders = useListInfiniteOrders({
    size: DEFAULT_PAGE_SIZE,
  });
  useTitle(t('common.orders'));

  const orderItems = useMemo(() => {
    return (orders.data?.pages ?? []).flat();
  }, [orders.data]);

  if (orders.isSuccess && orderItems.length === 0) {
    return (
      <Container>
        <Title />
        <EmptyTablePlaceholder
          title={t('orders.noOrdersMessage')}
          buttonTitle={t('quotes.browseOurProducts')}
          buttonProps={{
            LinkComponent: Link,
            href: returnUrl,
          }}
          containerProps={{ 'data-testid': 'orders-table' }}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Title />
      <Column data-testid="orders-table-container" gap={4}>
        <ShowElement below="md">
          <OrderCardListRenderer
            items={orderItems}
            isLoading={orders.isLoading}
            loadMore={
              orders.isFetchingNextPage ? (
                <ListCardLoader />
              ) : (
                <LoadMoreDivider
                  className="load-more-divider"
                  hasNextPage={orders.hasNextPage}
                  noMoreLabel={t('orders.noMoreOrders')}
                  onClick={orders.fetchNextPage}
                />
              )
            }
          />
        </ShowElement>
        <ShowElement above="md">
          <OrderListTable
            orders={orderItems}
            hasNextPage={orders.hasNextPage}
            isFetchingNextPage={orders.isFetchingNextPage}
            onFetchNextPage={orders.fetchNextPage}
            isLoading={orders.isLoading}
          />
        </ShowElement>
      </Column>
    </Container>
  );
};

function Container({ children }: PropsWithChildren) {
  return (
    <Column gap={{ xs: 3, md: 4 }} p={{ xs: 2, md: 3 }}>
      {children}
    </Column>
  );
}

function Title() {
  const { t } = useTranslation();

  return (
    <Typography data-testid="orders-title" variant="h5">
      {t('common.orders')}
    </Typography>
  );
}
