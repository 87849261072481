import visitor from 'libs/Globals/visitor';
import { AppConfiguration } from 'libs/api/configuration/types';
import {
  UnderscoreLocale,
  convertToSimpleLocale,
  convertUnderscoreToDash,
  localesMap,
} from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers';

export const useUserLocale = () => {
  const { locale } = useAppConfiguration();

  /**
   * Default instance locale which the system will use as fallback if a translation key is not available in the user's selected locale.
   */
  const fallbackLocale = convertUnderscoreToDash(locale);

  /**
   * User's currently selected locale, uses the instance default if it's null.
   */
  const userLocale = convertUnderscoreToDash(visitor.locale as UnderscoreLocale) ?? fallbackLocale;

  return { userLocale, simpleLocale: convertToSimpleLocale(userLocale) };
};

/**
 * Map of the available locales.
 */
export const getAvailableLocalesOptions = (
  supportedLocales: AppConfiguration['supportedLocales']
): Array<{ label: string; value: string }> =>
  supportedLocales.map(locale => ({
    label: localesMap[locale as keyof typeof localesMap]?.nativeName,
    value: convertUnderscoreToDash(locale),
  }));
