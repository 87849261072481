import * as quoteActions from 'libs/api/quotes/actions';
import { Quote } from 'libs/api/quotes/types';
import { QuoteMutation } from '../types';
import { QuoteMutationOptions, reactQueryKeys } from 'libs/api/quotes/hooks';
import { useMutation } from '@tanstack/react-query';

export type QuoteRowMutationPayload = {
  versionId: Quote['versionId'];
  mutation: QuoteMutation;
  payload?: Partial<Quote>;
};
export type MutationHandlerPayload = Pick<Quote, 'versionId' | 'number'> &
  Pick<QuoteRowMutationPayload, 'payload'>;
export type QuoteRowMutationOptions = QuoteMutationOptions<QuoteRowMutationPayload>;

// Mutations specific to Sales App Quotes Table
export const useQuoteRowUpdate = (options: QuoteRowMutationOptions) =>
  useMutation(
    [reactQueryKeys.update],
    ({ versionId, payload }) => quoteActions.updateQuoteNonContractual(versionId, payload!),
    options
  );

export const useQuoteRowRestore = (options: QuoteRowMutationOptions) =>
  useMutation(
    [reactQueryKeys.unarchive],
    ({ versionId }) => quoteActions.unarchiveQuote(versionId),
    options
  );

export const useQuoteRowDuplicate = (options: QuoteRowMutationOptions) =>
  useMutation(
    [reactQueryKeys.duplicate],
    ({ versionId }) => quoteActions.duplicateQuote(versionId),
    options
  );

export const useQuoteRowArchive = (options: QuoteRowMutationOptions) =>
  useMutation(
    [reactQueryKeys.archive],
    ({ versionId }) => quoteActions.archiveQuote(versionId),
    options
  );
