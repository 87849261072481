import React from 'react';
import { FlexColumn, Typography, useTranslation } from '@clippings/paper';

export function QuoteDiscountsTitle() {
  const { t } = useTranslation();

  return (
    <FlexColumn>
      <Typography variant="subtitle2">{t('companies.quoteDiscountsLabel')}</Typography>
      <Typography variant="caption">{t('companies.quoteDiscountsDescription')}</Typography>
    </FlexColumn>
  );
}
