import React, { FC } from 'react';
import {
  ALLOWED_FILE_EXTENSIONS_STRING,
  ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED,
  MAX_FILE_SIZE_BYTES,
} from 'libs/api/upload/actions';
import {
  AlertSnackbar,
  Box,
  DataTestId,
  GridProps,
  Skeleton,
  Typography,
  UploadArea,
  UploadedFileSkeleton,
  alpha,
} from '@clippings/paper';
import { ComponentGridContainer, DividerGrey } from 'libs/shared';
import { QuoteBrowserUploadedFile } from '../QuoteBrowserUploadedFile/QuoteBrowserUploadedFile';
import { QuoteUploadedFile } from '../QuoteUploadedFile/QuoteUploadedFile';
import { useQuoteDocumentUtils } from 'libs/Quotes/hooks/useQuoteDocumentUtils';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export type QuoteDocumentsProps = {
  loading: boolean;
  readonly: boolean;
  hidden?: boolean;
};

export const QuoteDocuments: FC<QuoteDocumentsProps & GridProps & DataTestId> = ({
  loading,
  readonly,
  hidden,
  ...rest
}) => {
  const { t } = useTranslation();
  const { quote } = useQuoteProvider();
  const {
    handleFileUpload,
    inputRef,
    browserDocs,
    snackbarProps,
    onDocumentDownloadError,
    onDocumentDownloadSuccess,
    onDocumentRemoveError,
    onDocumentRemoveSuccess,
    onDocumentUploadError,
    onDocumentUploadSuccess,
  } = useQuoteDocumentUtils();

  if (hidden) {
    return null;
  }

  if (loading) {
    return (
      <>
        <ComponentGridContainer
          {...rest}
          sx={{
            mt: 3,
            ...rest.sx,
          }}
          data-testid="quote-documents-loading"
        >
          <Skeleton width="100%" height="24px" variant="rounded" />
          <Skeleton width="100%" height="80px" variant="rectangular" />
          <DividerGrey />
          <UploadedFileSkeleton />
          <UploadedFileSkeleton />
        </ComponentGridContainer>
      </>
    );
  }

  const documents = quote.documents ?? [];

  return (
    <>
      <ComponentGridContainer
        data-testid="quote-documents"
        {...rest}
        sx={{
          mt: 3,
          ...rest.sx,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="h5"
            fontSize={theme => theme.typography.pxToRem(14)}
            color={theme => alpha(theme.palette.common.black, 0.6)}
          >
            {t('quotes.upload.quoteDocuments')}
          </Typography>
          <Typography
            variant="body2"
            fontSize={theme => theme.typography.pxToRem(14)}
            color={theme => alpha(theme.palette.common.black, 0.38)}
          >
            {t('quotes.upload.attachFiles')}
          </Typography>
        </Box>

        <UploadArea
          disabled={readonly}
          ref={inputRef}
          inputProps={{
            accept: ALLOWED_FILE_EXTENSIONS_STRING,
            multiple: true,
          }}
          onFileUploaded={handleFileUpload}
          onFileDropped={handleFileUpload}
          sizeLimit={MAX_FILE_SIZE_BYTES}
          allowedExtensions={ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED}
        />
        <Box data-testid="quote-documents-list">
          {/* Existing documents */}
          {documents.map((document, index) => (
            <QuoteUploadedFile
              document={document}
              quoteNumber={quote.number}
              key={`quote-document-${document.id}`}
              containerProps={{
                sx: { mt: index === 0 ? 0 : 1 },
              }}
              onDownloadError={onDocumentDownloadError}
              onDownloadSuccess={onDocumentDownloadSuccess}
              onDeleteError={onDocumentRemoveError}
              onDeleteSuccess={onDocumentRemoveSuccess}
            />
          ))}
          {/* Browser documents (in progress) */}
          {browserDocs.state.map(doc => (
            <QuoteBrowserUploadedFile
              quoteNumber={quote.number}
              key={`browser-uploaded-document-${doc.id}`}
              file={doc}
              containerProps={{
                sx: { mt: documents.length === 0 ? 0 : 1 },
              }}
              onUploadSuccess={onDocumentUploadSuccess}
              onUploadError={onDocumentUploadError}
              onRemove={browserDocs.remove}
            />
          ))}
        </Box>
      </ComponentGridContainer>
      <AlertSnackbar data-testid="quote-document-upload-snackbar" {...snackbarProps} />
    </>
  );
};
