import { Quote } from 'libs/api/quotes/types';

export type PdfVariant = 'noDiscount' | 'normal';

export const noDiscountPdfVariant: PdfVariant = 'noDiscount';

export function useQuotePdfViewModel(quote: Quote, pdfVariant: PdfVariant = 'normal') {
  if (!quote) {
    return {
      catalogue: '',
      items: [],
    };
  }

  if (pdfVariant === noDiscountPdfVariant) {
    return {
      catalogue: quote.catalogue ?? '',
      items:
        quote.quoteItemProducts.map(item => {
          return {
            ...item,
            catalogueBasePrice: item.netPrice,
          };
        }) ?? [],
    };
  }

  return {
    catalogue: quote.catalogue,
    items: quote.quoteItemProducts,
  };
}
