import React, { ChangeEvent, useCallback } from 'react';
import { AddNewLineInput } from 'libs/SalesApp/Quotes/components/AddNewLineInput';
import { AlgoliaCellPopoverContent, BaseAlgoliaValue } from './AlgoliaCellPopoverContent';
import { CellInputProps, CellPopover, TableCell } from '@clippings/paper';
import { DEBOUNCE_TIMINGS } from 'libs/Constants';
import { debounce } from 'lodash';
import { useSearchBox } from 'react-instantsearch-hooks-web';

export interface AddAlgoliaItemCellType<Value> {
  infoLabel: string;
  onItemSelect: (item: BaseAlgoliaValue<Value>) => void;
  onItemCreate(name: string): void;
  placeholder: string;
  inputProps?: CellInputProps['inputProps'];
  inputValueChangeHandler: (value: string) => void;
  inputValue: string;
  disabled?: boolean;
  inputDisabled?: boolean;
}

export function AddAlgoliaItemCell<Value = unknown>(props: AddAlgoliaItemCellType<Value>) {
  const {
    infoLabel,
    placeholder,
    inputProps,
    inputValueChangeHandler,
    inputValue,
    onItemCreate,
    onItemSelect,
    disabled,
    inputDisabled,
  } = props;
  const { refine } = useSearchBox();

  const handleValueChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (inputDisabled) {
      return;
    }
    const value = event.target.value;
    inputValueChangeHandler(value);
    debouncedRefineHandler(value);
  };

  const debouncedRefineHandler = useCallback(
    debounce(
      (value: string) => {
        refine(value);
      },
      DEBOUNCE_TIMINGS.SEARCH_DEBOUNCE_FAST,
      {
        trailing: true,
      }
    ),
    [refine]
  );

  const onCustomItemCreate = (name: string) => {
    onItemCreate(name);
    refine('');
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    refine(e?.target.value ?? '');
  };

  return (
    <CellPopover
      infoLabel={infoLabel}
      PopoverContent={(props: { onClose: () => void }) => (
        <AlgoliaCellPopoverContent {...props} refine={refine} onSelect={onItemSelect} />
      )}
    >
      <TableCell>
        <AddNewLineInput
          onFocus={onFocus}
          value={inputValue}
          placeholder={placeholder}
          onCreate={onCustomItemCreate}
          onChange={handleValueChange}
          inputProps={inputProps}
          disabled={disabled}
        />
      </TableCell>
    </CellPopover>
  );
}
