import React, { useMemo } from 'react';
import { Box } from '@clippings/paper';
import {
  EmptyTablePlaceholder,
  ListTable,
  ListTableBody,
  ListTableHeader,
  ListTableHeaderCell,
} from 'libs/Components';
import { OrderStatus } from 'libs/api/order/types';
import { OrdersListItem } from './OrdersListItem';
import { SingleTeamUser } from 'libs/SalesApp/Quotes/quoteUtils';
import { useListInfiniteOrders } from 'libs/api/order/hooks';
import { useTranslation } from 'react-i18next';

export interface OrdersListProps {
  statusFilter?: OrderStatus;
  teamsOfUserFilter?: SingleTeamUser;
  searchValue?: string;
  filtersResetHandler: () => void;
}

export const OrdersList: React.FC<OrdersListProps> = ({
  statusFilter,
  searchValue,
  filtersResetHandler,
  teamsOfUserFilter,
}) => {
  const { t } = useTranslation();
  const { data, hasNextPage, isLoading, fetchNextPage, isFetchingNextPage } = useListInfiniteOrders(
    {
      teamsOfUser: teamsOfUserFilter?.id ?? undefined,
      status: statusFilter ? [statusFilter] : undefined,
      query: searchValue,
    }
  );

  const orders = useMemo(() => {
    return (data?.pages ?? []).flat();
  }, [data]);

  const isResultEmpty = !isLoading && orders.length === 0;

  const isResultEmptyWithFilters =
    !isLoading && orders.length === 0 && (!!searchValue || !!statusFilter);

  if (isResultEmptyWithFilters) {
    return (
      <Box mt={4}>
        <EmptyTablePlaceholder
          onButtonClick={filtersResetHandler}
          title={t('common.noResults')}
          buttonTitle={t('common.reset')}
          containerProps={{ 'data-testid': 'empty-table-placeholder-no-orders-reset' }}
        />
      </Box>
    );
  }

  if (isResultEmpty) {
    return (
      <Box mt={4}>
        <EmptyTablePlaceholder
          title={t('common.noOrderResults')}
          containerProps={{ 'data-testid': 'empty-table-placeholder' }}
        />
      </Box>
    );
  }

  return (
    <ListTable
      rowHeight={74}
      isLoading={isLoading}
      onFetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      noMoreLabel={t('orders.noMoreOrders')}
      isFetchingNextPage={isFetchingNextPage}
    >
      <ListTableHeader columns={7} data-testid="sa-orders-table-header">
        <ListTableHeaderCell
          data-testid="sa-orders-table-header-orderNumber"
          label={t('orders.orderNumber')}
          columns={1}
        />
        <ListTableHeaderCell
          data-testid="sa-orders-table-header-quoteNumber"
          label={t('quotes.quoteNumber')}
          columns={1}
        />
        <ListTableHeaderCell
          data-testid="sa-orders-table-header-customer"
          label={t('common.customer')}
          columns={1}
        />
        <ListTableHeaderCell
          data-testid="sa-orders-table-header-value"
          label={t('common.value')}
          columns={1}
        />
        <ListTableHeaderCell
          data-testid="sa-orders-table-header-orderPlaced"
          label={t('orders.orderPlaced')}
          columns={1}
        />
        <ListTableHeaderCell
          data-testid="sa-orders-table-header-status"
          label={t('common.status')}
          columns={1}
        />
        <ListTableHeaderCell
          data-testid="sa-orders-table-header-assignee"
          label={t('common.assignee')}
          columns={1}
        />
      </ListTableHeader>
      <ListTableBody>
        {orders.map(order => (
          <OrdersListItem key={order.id} order={order} />
        ))}
      </ListTableBody>
    </ListTable>
  );
};
