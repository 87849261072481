import React, { useMemo, useState } from 'react';
import { Catalogue, Category } from 'libs/api/catalogue/types';
import { CatalogueSelect } from '../CatalogueSelect';
import { CompanyProductDiscount } from 'libs/api/common/types';
import {
  CompositeDiscountCell,
  CompositeDiscountValue,
} from '../../../../../Quotes/components/CompositeDiscountCell';
import { MultiSelect, MultiSelectValue, TableCell, TableRow } from '@clippings/paper';
import { RowActionsPanel } from 'libs/shared';
import { useTranslatable } from 'libs/Utils/useTranslatable';

export interface NullablePercentageCompanyProductDiscount
  extends Omit<CompanyProductDiscount, 'percent'> {
  percent: number | null;
}

export interface ProductDiscountRowProps {
  discount: CompanyProductDiscount;
  onChange: (discount: NullablePercentageCompanyProductDiscount | null) => void;
  catalogues: Catalogue[];
  categories: Category[];
}

export const ProductDiscountRow: React.FC<ProductDiscountRowProps> = ({
  discount,
  onChange,
  catalogues,
  categories,
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const showActionPanel = (e: React.MouseEvent<HTMLElement>) => setAnchorElement(e.currentTarget);
  const hideActionPanel = () => setAnchorElement(null);

  const [catalogueSlug, setCatalogueSlug] = useState(discount.catalogueSlug || '');
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    discount.productCategories ?? []
  );
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const handlePercentChange = (newValue: CompositeDiscountValue) => {
    onChange({ ...discount, ...newValue });
  };

  const getTranslation = useTranslatable();

  const handleCatalogueChange = (newValue: string) => {
    setCatalogueSlug(newValue);
    onChange({ ...discount, catalogueSlug: newValue });
  };

  const handleOnChange = (values: string[]) => {
    setSelectedCategories(values);
    onChange({ ...discount, productCategories: values });
  };

  const handleCategoryChange = (values: MultiSelectValue[]) => {
    handleOnChange(values.map(x => String(x.value)));
  };

  const handleDelete = () => {
    onChange(null);
  };

  const handleCategoryDelete = (vendorId: string) => {
    handleOnChange(selectedCategories.filter(catId => catId !== vendorId));
  };

  const handleCategoryChipDelete = (value: MultiSelectValue) => {
    handleCategoryDelete(String(value.id));
  };

  const categoryOptions = categories.map(category => ({
    label: getTranslation(category.name),
    value: category.vendorId,
    id: category.vendorId,
  }));

  const value = useMemo(() => {
    return selectedCategories.map(x => {
      const category = categories.find(cat => cat.vendorId === x)!;

      return {
        id: x,
        value: x,
        label: getTranslation(category?.name ?? []),
      } as MultiSelectValue;
    });
  }, [selectedCategories]);

  return (
    <TableRow
      onMouseEnter={showActionPanel}
      onMouseLeave={hideActionPanel}
      data-testid="discount-table-row"
    >
      <TableCell>
        <CompositeDiscountCell
          initialValue={discount.title}
          autoFocus
          data-testid={`product-discount-${discount.id}-percent`}
          onChange={handlePercentChange}
        />
      </TableCell>
      <TableCell>
        <CatalogueSelect
          value={catalogueSlug}
          catalogues={catalogues}
          onChange={handleCatalogueChange}
        />
      </TableCell>
      <TableCell>
        <MultiSelect
          fullWidth
          value={value}
          options={categoryOptions}
          data-testid={`category-select-${discount.id}`}
          variant="standard"
          SelectProps={{
            onOpen: () => {
              setIsOpenSelect(true);
            },
            onClose: () => {
              setIsOpenSelect(false);
            },
            disableUnderline: true,
          }}
          chipProps={{
            onDelete: handleCategoryChipDelete,
          }}
          chipContainerProps={{
            sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 },
          }}
          withSelectAll
          onChange={handleCategoryChange}
        />
      </TableCell>
      {!isOpenSelect ? (
        <RowActionsPanel anchorElement={anchorElement} onDelete={handleDelete} />
      ) : null}
    </TableRow>
  );
};
