import React, { ComponentProps, PropsWithChildren } from 'react';
import { Box } from '@clippings/paper';

export function Row({ children, ...rest }: PropsWithChildren<ComponentProps<typeof Box>>) {
  return (
    <Box display="flex" flexDirection="row" {...rest}>
      {children}
    </Box>
  );
}
