import { ChatQuoteSystemEvent, isQuoteSystemEvent } from './components/messages/system/types';
import { DefaultGenerics, Channel as StreamChannel } from 'stream-chat';
import { DefaultMessageType, DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { Quote } from 'libs/api/quotes/types';

export enum ChatAction {
  Edit = 'edit',
  Flag = 'flag',
  React = 'react',
}

export interface IChannel extends StreamChannel<DefaultGenerics> {
  id: string;
}
export enum Channel {
  Messaging = 'messaging',
}

// Events
export type ChatSystemEvent = ChatQuoteSystemEvent; // More events to be added later (e.g. Order)

// System Chat
export type MetaData<Type> = {
  type: Type;
};

export type QuoteMetaData<QuoteEvents = ChatQuoteSystemEvent> = MetaData<QuoteEvents> & {
  versionId?: Quote['versionId'];
  versionNumber?: Quote['versionNumber'];
  /**
   * sales rep username (on join)
   */
  userName?: string;
  /**
   * quote number
   */
  number?: string;
};

export type ChatSystemMessage = DefaultMessageType & {
  metaData?: MetaData<ChatSystemEvent>;
};
export type ChatSystemQuoteMessage = DefaultMessageType & {
  metaData: QuoteMetaData;
};

export type SystemStreamChatGenerics<SystemMessage extends DefaultMessageType = ChatSystemMessage> =
  DefaultStreamChatGenerics & {
    messageType: SystemMessage;
  };

export const isSystemQuoteMessage = (
  message: ChatSystemMessage | ChatSystemQuoteMessage
): message is ChatSystemQuoteMessage =>
  message.metaData ? isQuoteSystemEvent(message.metaData.type) : false;
