import { OrderItem } from 'libs/api/order/types';
import { OrderListCardItemViewModel } from './OrderListItemCard';
import { getOrderIdLabel } from 'libs/Orders/utils';
import { useMemo } from 'react';

export function useOrderListItemCardRenderer(items: OrderItem[]) {
  const viewDataModel = useMemo(
    () => items.map((item, idx) => viewModelMapper(item, idx)),
    [items]
  );

  return { viewDataModel };
}

function viewModelMapper(item: OrderItem, idx: number): OrderListCardItemViewModel {
  return {
    id: item.id,
    position: idx,
    number: getOrderIdLabel(item.vendorId),
    label: item.label ?? '',
    createdAt: item.createdAt,
    value: item.total,
    status: item.status,
    internalNumber: item.number,
  };
}
