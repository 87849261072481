import React from 'react';
import { Column } from '../Column';
import { OrderProductItem } from 'libs/api/order/types';
import { QuoteItemCardProps } from './QuoteItemCard';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { useQuoteItemCardRenderer } from './useQuoteItemCardRenderer';

export type QuoteItemCardRendererProps = {
  items: OrderProductItem[] | QuoteProductItem[];
  editableFields?: QuoteItemCardProps['editableFields'];
  cardActions?: QuoteItemCardProps['actions'];
};

export function QuoteItemCardRenderer({
  items,
  editableFields,
  cardActions,
}: QuoteItemCardRendererProps) {
  const { QuoteItemCard, viewDataModel, lineItemVisibleFields } = useQuoteItemCardRenderer(items);

  return (
    <Column gap={0.5} data-testid="quote-item-card-renderer">
      {viewDataModel.map(item => (
        <QuoteItemCard
          key={item.id}
          item={item}
          lineItemVisibleFields={lineItemVisibleFields}
          editableFields={editableFields}
          actions={cardActions}
        />
      ))}
    </Column>
  );
}
