import React from 'react';
import { Quote } from 'libs/api/quotes/types';
import { QuoteInfoSimpleItem } from './QuoteInfoSimpleItem';
import { Typography, getUserFullName } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

interface QuoteCustomerProps {
  customer: Quote['customer'];
}

export function QuoteCustomer({ customer }: QuoteCustomerProps): JSX.Element {
  const { t } = useTranslation();
  const fullName = getUserFullName(customer);

  return (
    <QuoteInfoSimpleItem label={t('quotes.pdf.info.customer')} value={fullName}>
      <Typography variant="caption" fontSize={6}>
        {customer?.email}
      </Typography>
    </QuoteInfoSimpleItem>
  );
}
