import { DeliveryMethodEvaluationResponse, Quote } from 'libs/api/quotes/types';
import { ShippingAddress } from 'libs/api/common/types';
import {
  createCompanyBillingAddress,
  createCompanyShippingAddress,
  createQuoteExWorksAddress,
  createQuoteShippingAddress,
  deleteCompanyShippingAddress,
  deleteQuoteShippingAddress,
  evaluateDeliveryMethodsForQuote,
  getCompanyBillingAddresses,
  getCompanyShippingAddresses,
  getQuoteShippingAddresses,
  queryKeys,
  updateCompanyBillingAddress,
  updateCompanyShippingAddress,
  updateQuoteShippingAddress,
} from 'libs/Quotes/quoteActions';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useQuoteUpdate } from 'libs/api/quotes/hooks';

export type QuoteAddressProps = Pick<
  Quote,
  'catalogue' | 'versionId' | 'customerCompanyId' | 'shippingAddress' | 'quoteItemProducts'
>;

export enum AddressTypesEnum {
  QUOTE = 'quote',
  COMPANY = 'company',
}

const useShippingAddressActions = (quote: QuoteAddressProps, quoteQueryKey: string[]) => {
  const queryClient = useQueryClient();

  const companyShippingAddressQuery = useQuery(
    queryKeys.getShippingAddress(quote.versionId, quote.customerCompanyId),
    () => getCompanyShippingAddresses(quote.customerCompanyId),
    {
      enabled: !!quote.customerCompanyId,
    }
  );

  const quoteShippingAddressQuery = useQuery(
    queryKeys.getQuoteShippingAddresses(quote.versionId),
    () => getQuoteShippingAddresses(quote.versionId),
    {
      enabled: !!quote.versionId,
    }
  );

  const evaluateDeliveryMethodsQuery = useQuery<DeliveryMethodEvaluationResponse>(
    queryKeys.getQuoteDeliveryMethods(quote),
    () =>
      evaluateDeliveryMethodsForQuote({
        quoteVersionId: quote.versionId,
        catalogueRef: quote.catalogue,
        deliveryAddress: {
          country: quote.shippingAddress?.country?.name,
          city: quote.shippingAddress?.city,
          state: quote.shippingAddress?.state?.name,
          county: quote.shippingAddress?.county,
        },
      }),
    {
      enabled: !!quote.versionId,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const quoteShippingAddressCreationMutation = useMutation(data =>
    createQuoteShippingAddress(quote.versionId, data)
  );

  const quoteExWorksAddressCreationMutation = useMutation(data =>
    createQuoteExWorksAddress(quote.versionId, data)
  );

  const companyShippingAddressCreationMutation = useMutation(data =>
    createCompanyShippingAddress(quote.customerCompanyId, data)
  );

  const quoteShippingAddressUpdateMutation = useMutation(data => {
    return updateQuoteShippingAddress(quote.versionId, data);
  });

  const companyShippingAddressUpdateMutation = useMutation(data => {
    return updateCompanyShippingAddress(quote.customerCompanyId, data);
  });

  const quoteShippingAddressDeleteMutation = useMutation(() =>
    deleteQuoteShippingAddress(quote.versionId)
  );

  const companyShippingAddressDeleteMutation = useMutation(data =>
    deleteCompanyShippingAddress(quote.customerCompanyId, data)
  );

  const setDeliveryMutation = useQuoteUpdate({
    onSuccess: data => {
      queryClient.setQueryData(quoteQueryKey, data);
    },
  });

  const mutationMap = {
    quote: {
      create: quoteShippingAddressCreationMutation,
      update: quoteShippingAddressUpdateMutation,
      delete: quoteShippingAddressDeleteMutation,
    },
    company: {
      create: companyShippingAddressCreationMutation,
      update: companyShippingAddressUpdateMutation,
      delete: companyShippingAddressDeleteMutation,
    },
  };

  const onAddressActionSuccess = (
    type: AddressTypesEnum,
    onSuccess: (data: any) => void,
    successData: any
  ) => {
    if (type === AddressTypesEnum.COMPANY) {
      onSuccess({ shippingAddress: successData });
      return;
    }

    // Quote
    onSuccess({ shippingAddress: successData.address, versionId: successData.versionId });
  };

  const handleAddressCreate = (
    data: any,
    type: AddressTypesEnum,
    onSuccess: (data: any) => void,
    onError?: () => void
  ) => {
    mutationMap[type].create.mutate(data, {
      onSuccess: successData => onAddressActionSuccess(type, onSuccess, successData),
      onError,
    });
  };

  const handleAddressUpdate = (
    data: any,
    onSuccess: (data: any) => void,
    onError: () => void,
    type: AddressTypesEnum
  ) => {
    mutationMap[type].update.mutate(data, {
      onSuccess: successData => onAddressActionSuccess(type, onSuccess, successData),
      onError,
    });
  };

  const handleAddressDelete = (
    data: any,
    type: AddressTypesEnum,
    onSuccess: (data: any) => void,
    onError?: () => void
  ) => {
    mutationMap[type].delete.mutate(data, {
      onSuccess,
      onError,
    });
  };

  const handleExWorksCreate = (
    data: any,
    type: AddressTypesEnum,
    onSuccess: (data: { shippingAddress: ShippingAddress; versionId?: number }) => void,
    onError?: () => void
  ) => {
    quoteExWorksAddressCreationMutation.mutate(data, {
      onSuccess: successData => onAddressActionSuccess(type, onSuccess, successData),
      onError,
    });
  };

  const handleDeliveryMethodSelect = (
    data: any,
    onSuccess: (data: any) => void,
    onError?: () => void
  ) => {
    setDeliveryMutation.mutate(
      {
        versionId: quote.versionId,
        payload: {
          deliveryItems: [{ deliveryRef: data.deliveryRef }] as any,
        },
      },
      {
        onSuccess,
        onError,
      }
    );
  };

  const isLoading =
    quoteShippingAddressQuery.isLoading ||
    companyShippingAddressQuery.isLoading ||
    quoteShippingAddressDeleteMutation.isLoading ||
    companyShippingAddressDeleteMutation.isLoading ||
    quoteShippingAddressUpdateMutation.isLoading ||
    quoteShippingAddressCreationMutation.isLoading ||
    companyShippingAddressCreationMutation.isLoading ||
    companyShippingAddressUpdateMutation.isLoading ||
    setDeliveryMutation.isLoading;

  return {
    quoteAddressQuery: quoteShippingAddressQuery,
    companyAddressQuery: companyShippingAddressQuery,
    handlers: {
      handleAddressCreate,
      handleAddressUpdate,
      handleAddressDelete,
      handleExWorksCreate,
    },
    evaluateDeliveryMethodsQuery,
    handleDeliveryMethodSelect,
    isLoading,
  };
};

const useBillingAddressActions = (quote: Quote) => {
  const companyBillingAddressQuery = useQuery(
    queryKeys.getBillingAddress(quote.versionId, quote.customerCompanyId),
    () => getCompanyBillingAddresses(quote.customerCompanyId),
    {
      enabled: !!quote.customerCompanyId,
    }
  );

  const companyBillingAddressCreationMutation = useMutation(data =>
    createCompanyBillingAddress(quote.customerCompanyId, data)
  );

  const companyBillingAddressUpdateMutation = useMutation(data =>
    updateCompanyBillingAddress(quote.customerCompanyId, data)
  );

  const handleAddressCreate = (data: any, onSuccess: (data: any) => void, onError?: () => void) => {
    companyBillingAddressCreationMutation.mutate(data, {
      onSuccess: successData => onSuccess({ billingAddress: successData }),
      onError,
    });
  };

  const handleAddressUpdate = (data: any, onSuccess: (data: any) => void, onError: () => void) => {
    companyBillingAddressUpdateMutation.mutate(data, {
      onSuccess: successData => {
        onSuccess({ billingAddress: successData });
        return;
      },
      onError,
    });
  };

  const isLoading =
    companyBillingAddressQuery.isLoading ||
    companyBillingAddressUpdateMutation.isLoading ||
    companyBillingAddressCreationMutation.isLoading;

  return {
    companyAddressQuery: companyBillingAddressQuery,
    handlers: {
      handleAddressCreate,
      handleAddressUpdate,
    },
    isLoading,
  };
};

export const useAddresses = (quote: Quote, quoteQueryKey: string[]) => {
  return {
    shippingAddress: useShippingAddressActions(quote, quoteQueryKey),
    billingAddress: useBillingAddressActions(quote),
  };
};
