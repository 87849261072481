import React from 'react';
import { Box, useTheme } from '@clippings/paper';
import { QuoteInfoLabel } from '../../../Quotes/PdfExport/components/info';
import { TranslationKey } from 'libs/types/react-i18next';
import { useTranslation } from 'react-i18next';

const companyInfoRows: Array<{ label: TranslationKey; value: TranslationKey }> = [
  { label: 'common.vat', value: 'brandData.vat' },
  { label: 'common.companyRegNo', value: 'brandData.companyRegNumber' },
  { label: 'common.phone', value: 'brandData.phone' },
  { label: 'common.email', value: 'brandData.email' },
];

export function CompanyData() {
  const {
    images: { logo },
  } = useTheme();
  const { t } = useTranslation();

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={1}>
      <Box>
        <img src={logo} height="40px" alt={t('brandData.name')} />
        <QuoteInfoLabel bold text={t('brandData.name')} />
        <QuoteInfoLabel text={t('brandData.address')} />
        <QuoteInfoLabel text={t('brandData.nexus')} />
        <QuoteInfoLabel text={t('brandData.country')} />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={1} alignSelf="baseline">
        {companyInfoRows
          .filter(row => t(row.value))
          .map((row, i) => (
            <React.Fragment key={row.value}>
              <QuoteInfoLabel text={t(row.label)} />
              <QuoteInfoLabel bold={i === 0} text={t(row.value)} />
            </React.Fragment>
          ))}
      </Box>
    </Box>
  );
}
