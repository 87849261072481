import React, { useMemo } from 'react';
import { DataGrid, Skeleton, TableBody, TableCell, TableHead, TableRow } from '@clippings/paper';
import { ItemRow } from './components';
import { NewProductRow } from '../NewProductRow';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { getQuoteItemContractFieldValue } from 'libs/shared';
import {
  makeContractFieldsValues,
  useQuoteTableHeadCells,
  useQuoteTableVisibleFields,
} from 'libs/Quotes/QuoteTable';
import { orderBy, range } from 'lodash';
import { useOrdering } from '../ordering';
import { useProductConfiguration } from '../providers';
import { useQuoteProvider } from 'libs/Quotes/providers';

export const QuoteTable = () => {
  const { openPLP, setConfiguredProduct } = useProductConfiguration();
  const { tableHeadCellsMap } = useQuoteTableHeadCells();
  const { reorderHandler } = useOrdering();
  const {
    quote,
    loading,
    tableLoading,
    isReadonly,
    hiddenDetailProducts,
    onQuoteItemQuantityUpdate,
    onQuoteItemDelete,
    onQuoteItemUpdateByKey,
    onMakeCustom,
    onQuoteItemUpdateDiscount,
    onProductDetailsHide,
  } = useQuoteProvider();
  const { quoteItemProducts } = quote;
  const contractFields = getQuoteItemContractFieldValue(quoteItemProducts?.[0] ?? null);
  const { lineItemVisibleFields } = useQuoteTableVisibleFields(contractFields);

  const orderedQuoteItemProducts = useMemo(
    () => orderBy(quoteItemProducts ? quoteItemProducts : [], 'position'),
    [quoteItemProducts]
  );

  return (
    <DataGrid compact>
      <TableHead>
        {loading && !quoteItemProducts ? (
          <TableRow>
            <TableCell colSpan={9}>
              <Skeleton
                data-testid="loading-table-header"
                variant="rectangular"
                sx={{ height: '32px' }}
              />
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>{lineItemVisibleFields.map(field => tableHeadCellsMap[field])}</TableRow>
        )}
      </TableHead>
      <TableBody sx={styles.tableBodySx}>
        {loading && !quoteItemProducts ? (
          <LoadingRows />
        ) : (
          orderedQuoteItemProducts.map((item: QuoteProductItem) => (
            <ItemRow
              key={`item-row-${item.id}-${item.sku}`}
              item={item}
              totalRows={orderedQuoteItemProducts.length}
              readonly={isReadonly}
              loading={tableLoading}
              detailsHidden={hiddenDetailProducts.some(x => x.id === item.id && x.hidden)}
              lineItemVisibleFields={lineItemVisibleFields}
              contractFieldsValues={makeContractFieldsValues(item)}
              onReorder={reorderHandler}
              onUpdate={onQuoteItemUpdateByKey}
              onMakeCustom={onMakeCustom}
              onQuantityUpdate={onQuoteItemQuantityUpdate}
              onDelete={onQuoteItemDelete}
              onConfigure={setConfiguredProduct}
              onQuoteItemUpdateDiscount={onQuoteItemUpdateDiscount}
              onProductDetailsHide={onProductDetailsHide}
              onNoteSave={onQuoteItemUpdateByKey}
            />
          ))
        )}
        {!isReadonly && !loading ? (
          <NewProductRow
            catalogue={quote.catalogue}
            isLoading={tableLoading}
            lineItemVisibleFields={lineItemVisibleFields}
            onConfigure={setConfiguredProduct}
            onOpenPLP={openPLP}
          />
        ) : null}
      </TableBody>
    </DataGrid>
  );
};

const LoadingRows: React.FC = () => (
  <>
    {range(2).map(key => (
      <TableRow key={key}>
        <TableCell colSpan={9}>
          <Skeleton data-testid="loading-row" variant="rectangular" sx={{ height: '350px' }} />
        </TableCell>
      </TableRow>
    ))}
    <TableRow>
      <TableCell colSpan={9}>
        <Skeleton data-testid="loading-row" variant="rectangular" sx={{ height: '73px' }} />
      </TableCell>
    </TableRow>
  </>
);

const styles = {
  tableBodySx: {
    '.MuiTableCell-root': {
      padding: '9px',
    },
  },
};
