import React, { FC } from 'react';
import { Box, Button, Tooltip } from '@clippings/paper';
import { QuoteTransitionErrorsEnum } from 'libs/SalesApp/Quotes/types';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useQuoteTransitionAction } from 'libs/shared/hooks/useQuoteTransitionAction';
import { useRequestQuote } from 'libs/shared/hooks/useRequestQuote';
import { useTranslation } from 'react-i18next';

export type QuoteRequestButtonProps = {
  onRequest: () => void;
};

const SPECIAL_ERRORS = [
  QuoteTransitionErrorsEnum.MissingShippingAddress,
  QuoteTransitionErrorsEnum.MissingBillingAddress,
];

export const QuoteRequestButton: FC<QuoteRequestButtonProps> = () => {
  const { t } = useTranslation();
  const { quote } = useQuoteProvider();
  const { errors, shouldDisableTransitionButton, buildBlockingErrorMessages } =
    useQuoteTransitionAction(quote, 'request', SPECIAL_ERRORS);
  const onRequest = useRequestQuote();

  return (
    <Tooltip
      title={buildBlockingErrorMessages()}
      placement="top"
      data-testid="request-button-tooltip"
      disableHoverListener={!shouldDisableTransitionButton}
    >
      <Box>
        <Button
          disabled={shouldDisableTransitionButton}
          variant="contained"
          onClick={() => onRequest(errors)}
          data-testid="quote-footer-action-buttons-request"
        >
          {t('quotes.footer.actions.request')}
        </Button>
      </Box>
    </Tooltip>
  );
};
