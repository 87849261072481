import React, { FC } from 'react';
import { DetailedUserAvatar, MenuItem, getUserFullName } from '@clippings/paper';
import { SingleTeamUser } from 'libs/SalesApp/Quotes/quoteUtils';

export interface UserOptionProps {
  user: SingleTeamUser;
  onSelect: (user: SingleTeamUser) => void;
  selected?: boolean;
  index?: number;
}
export const UserOption: FC<UserOptionProps> = ({ user, onSelect, selected, index }) => (
  <MenuItem
    onClick={() => onSelect(user)}
    selected={selected}
    data-testid={`user-option-${index}-${user.id}`}
  >
    <DetailedUserAvatar user={user} title={getUserFullName(user)} caption={user.team?.name ?? ''} />
  </MenuItem>
);
