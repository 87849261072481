import React, { useMemo } from 'react';
import {
  Box,
  Button,
  FormProvider,
  LoadingButton,
  getCompanyValidation,
  useForm,
  useSyncServerErrors,
  yup,
  yupResolver,
} from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import {
  CompanyFormFields,
  getMetadataValidation,
} from 'libs/SalesApp/Companies/components/CompanyFormFields';
import { GeneralInfoFormData } from 'libs/types/addressForm';
import { MetadataFields } from 'libs/SalesApp/Companies/components/MetadataFields';
import { useAppConfiguration } from 'libs/providers';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { useTranslation } from 'react-i18next';
import { useUpdateCompany } from 'libs/api/iam/hooks';

export interface GeneralInfoDialogFormProps {
  company: Company;
  onCancel: () => void;
  onSuccess: () => void;
}

export const GeneralInfoDialogForm: React.FC<GeneralInfoDialogFormProps> = ({
  company,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();

  const updateCompanyMutation = useUpdateCompany({
    onSuccess,
  });

  const serverErrors = useServerFormErrors(updateCompanyMutation.error);

  const defaultValues = useMemo(() => {
    return {
      name: company.name || '',
      registrationNumber: company.registrationNumber || '',
      creditCardChargeFee: company.creditCardChargeFee || 0,
      taxNumber: company.taxNumber || '',
      type: company.type || '',
      website: company.website || '',
      phone: company.phone || '',
      metadata: company.metadata,
      status: company.status,
      taxRate: company.taxRate || '',
    };
  }, [company]);

  const { companyValidation, metadataValidation } = useMemo(
    () => ({
      companyValidation: getCompanyValidation(brand),
      metadataValidation: getMetadataValidation(brand),
    }),
    [brand]
  );

  const schema = yup.object().shape({
    name: companyValidation.name,
    registrationNumber: companyValidation.registrationNumber,
    taxNumber: companyValidation.taxNumber,
    type: companyValidation.type,
    website: companyValidation.website,
    phone: companyValidation.phone,
    creditCardChargeFee: yup.string().nullable(),
    metadata: metadataValidation,
    status: yup.string().required('common.required'),
    //Stupid hack for allowing an empty input
    taxRate: yup
      .number()
      .transform(value => (isNaN(value) ? 0 : value))
      .nullable(),
  });

  const generalInfoForm = useForm<GeneralInfoFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useSyncServerErrors(generalInfoForm, serverErrors);

  const handleFormSubmit = (formData: GeneralInfoFormData) => {
    updateCompanyMutation.mutate({
      companyId: company.id,
      company: {
        ...formData,
        creditCardChargeFee: isNaN(formData.creditCardChargeFee)
          ? 0
          : Number(formData.creditCardChargeFee),
      },
    });
  };

  return (
    <FormProvider {...generalInfoForm} {...{ schema }}>
      <form noValidate onSubmit={generalInfoForm.handleSubmit(handleFormSubmit)}>
        <Box display="flex" flexDirection="column">
          <CompanyFormFields />
          <MetadataFields />
        </Box>
        <Box pt={2} display="flex" justifyContent="flex-end">
          <Button
            data-testid="sa-company-general-info-cancel-button"
            color="secondary"
            onClick={() => onCancel()}
          >
            {t('common.cancel')}
          </Button>
          <LoadingButton
            data-testid="sa-company-general-info-done-button"
            loading={updateCompanyMutation.isLoading || updateCompanyMutation.isSuccess}
            type="submit"
            variant="contained"
          >
            {t('common.done')}
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
};
