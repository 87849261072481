import React from 'react';
import { CellInput } from '@clippings/paper';
import { TableCellCommonProps } from '../tableCells.utils';
import { TableCellMode } from '../TableCellWithMode';
import { Text } from './Text';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';

type InfoCellCustomItemProps = Pick<TableCellCommonProps, 'item' | 'readonly'> & {
  mode: TableCellMode;
  value: string;
  handleUpdate: UpdateQuoteByKey;
};

export function InfoCellCustomItem({
  item,
  readonly,
  mode,
  value,
  handleUpdate,
}: InfoCellCustomItemProps) {
  if (mode === 'view') {
    return <Text value={value} />;
  }

  const handleVariationNameChange = (event: React.FocusEvent<HTMLInputElement>) => {
    handleUpdate('variationName', event.target?.value.trim(), item.id);
  };

  return (
    <CellInput
      disabled={readonly}
      inputProps={{
        sx: { marginTop: '9px' },
        'data-testid': `variation-name-${item.id}`,
      }}
      multiline
      forcedValue={value}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        handleVariationNameChange(event);
      }}
    />
  );
}
