import React from 'react';
import { Box, FlexColumn, Skeleton, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

interface PaymentTermsProps {
  isLoading: boolean;
  children: React.ReactNode;
}

export const PaymentTerms: React.FC<PaymentTermsProps> = ({ children, isLoading }) => {
  const { t } = useTranslation();

  return (
    <FlexColumn gap={0.5}>
      <Typography
        sx={{ fontSize: theme => theme.typography.pxToRem(16) }}
        variant="caption"
        fontWeight="fontWeightMedium"
      >
        {t('paymentTerms.paymentTerms')}
      </Typography>
      <Box>
        {isLoading ? (
          <Skeleton variant="rectangular" data-testid="payment-terms-skeleton" />
        ) : (
          children
        )}
      </Box>
    </FlexColumn>
  );
};
