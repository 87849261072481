import React, { FC } from 'react';
import { ChatQuoteSystemMessage } from './quote/ChatQuoteSystemMessage';
import {
  ChatSystemQuoteMessage,
  SystemStreamChatGenerics,
  isSystemQuoteMessage,
} from 'libs/Chat/types';
import { EventComponent, EventComponentProps } from 'stream-chat-react';

export const ChatSystemMessage: FC<EventComponentProps<SystemStreamChatGenerics>> = props => {
  if (isSystemQuoteMessage(props.message)) {
    return (
      <ChatQuoteSystemMessage
        // more info on the assertion:
        // https://github.com/clippings/design-technologies/pull/10124#discussion_r999533946
        // https://github.com/clippings/design-technologies/pull/10124#discussion_r999639117
        {...(props as EventComponentProps<SystemStreamChatGenerics<ChatSystemQuoteMessage>>)}
      />
    );
  }

  return <EventComponent {...props} />;
};
