import React, { useMemo } from 'react';
import { Address, AddressType } from 'libs/api/common/types';
import { AddressFormData, isShippingFormData } from 'libs/types/addressForm';
import { AddressFormFields } from 'libs/shared/components/address/AddressFormFields';
import {
  Box,
  Button,
  FormProvider,
  LoadingButton,
  useForm,
  useSyncServerErrors,
  yupResolver,
} from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { Location } from 'libs/api/location/types';
import { getCompanyAddressFormData } from 'libs/SalesApp/Companies/utils/addressValidationSchema';
import { getConditionalAddressProps } from 'libs/SalesApp/utils/formData';
import { getServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { isBillingAddress, isShippingAddress } from 'libs/api/common/utils';
import { useAddressDefaultCountry } from 'libs/shared/hooks/useAddressDefaultCountry';
import { useAppConfiguration } from 'libs/providers';
import {
  useCreateCompanyBillingAddress,
  useCreateCompanyShippingAddress,
  useUpdateCompanyBillingAddress,
  useUpdateCompanyShippingAddress,
} from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

export interface AddressDialogFormProps {
  company: Company;
  editedAddress?: Address;
  makeDefault?: boolean;
  type: AddressType;
  onSuccess: () => void;
  onCancel: () => void;
  countries: Location[];
  countriesByShortName: Record<string, Location>;
  states: Location[];
}

export const AddressDialogForm: React.FC<AddressDialogFormProps> = ({
  company,
  editedAddress,
  makeDefault = false,
  type,
  onSuccess,
  onCancel,
  countries,
  countriesByShortName,
  states,
}) => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();

  const createShippingAddressMutation = useCreateCompanyShippingAddress({
    onSuccess: () => onSuccess(),
  });

  const createBillingAddressMutation = useCreateCompanyBillingAddress({
    onSuccess: () => onSuccess(),
  });

  const updateShippingAddressMutation = useUpdateCompanyShippingAddress({
    onSuccess: () => onSuccess(),
  });

  const updateBillingAddressMutation = useUpdateCompanyBillingAddress({
    onSuccess: () => onSuccess(),
  });

  const serverErrors = useMemo(() => {
    const error = {
      // In practice it will always be only one of them
      ...createShippingAddressMutation.error,
      ...createBillingAddressMutation.error,
      ...updateShippingAddressMutation.error,
      ...updateBillingAddressMutation.error,
    };

    return getServerFormErrors(error);
  }, [
    createShippingAddressMutation.error,
    createBillingAddressMutation.error,
    updateShippingAddressMutation.error,
    updateBillingAddressMutation.error,
  ]);

  const { schema, defaultValues } = getCompanyAddressFormData(brand, company, type, editedAddress);

  const addressForm = useForm<AddressFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useAddressDefaultCountry(countries, addressForm);
  useSyncServerErrors(addressForm, serverErrors);

  const handleCreateAddress = (newAddress: Address) => {
    if (isShippingAddress(newAddress)) {
      createShippingAddressMutation.mutate({
        companyId: company.id,
        address: { ...newAddress, default: makeDefault },
      });
    }

    if (isBillingAddress(newAddress)) {
      createBillingAddressMutation.mutate({
        companyId: company.id,
        address: { ...newAddress, default: makeDefault },
      });
    }
  };

  const handleUpdateAddress = (updatedAddress: Address) => {
    if (editedAddress?.id && isShippingAddress(updatedAddress)) {
      updateShippingAddressMutation.mutate({
        companyId: company.id,
        addressId: editedAddress?.id,
        address: updatedAddress,
      });
    }

    if (editedAddress?.id && isBillingAddress(updatedAddress)) {
      updateBillingAddressMutation.mutate({
        companyId: company.id,
        addressId: editedAddress?.id,
        address: updatedAddress,
      });
    }
  };

  const handleFormSubmit = (formData: AddressFormData) => {
    const { country, state, province, ...rest } = formData;
    const payload: Address = {
      ...rest,
      ...getConditionalAddressProps(countriesByShortName, states, country, state, province),
      metadata: isShippingFormData(formData)
        ? {
            ...formData.metadata,
          }
        : {},
    };

    if (editedAddress) {
      handleUpdateAddress(payload);
      return;
    }

    handleCreateAddress(payload);
  };

  const isSubmitting =
    createShippingAddressMutation.isLoading ||
    createBillingAddressMutation.isLoading ||
    updateShippingAddressMutation.isLoading ||
    updateBillingAddressMutation.isLoading ||
    createShippingAddressMutation.isSuccess ||
    createBillingAddressMutation.isSuccess ||
    updateShippingAddressMutation.isSuccess ||
    updateBillingAddressMutation.isSuccess;

  return (
    <FormProvider {...addressForm} {...{ schema }}>
      <form
        noValidate
        onSubmit={addressForm.handleSubmit(handleFormSubmit)}
        data-testid={`${editedAddress ? 'edit' : 'create'}-${type}-address-form`}
      >
        <Box display="flex" flexDirection="column">
          <AddressFormFields type={type} />
        </Box>
        <Box pt={2} display="flex" justifyContent="flex-end">
          <Button data-testid="address-dialog-button-cancel" color="secondary" onClick={onCancel}>
            {t('common.cancel')}
          </Button>

          <LoadingButton
            data-testid="address-dialog-button-save"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
};
