import React, { ComponentProps, PropsWithChildren } from 'react';
import { Button, EditIcon, Grid, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export type EditButtonProps = {
  label?: string;
} & ComponentProps<typeof Button>;

export type InfoBoxProps = {
  title: string;
  notification?: React.ReactNode;
  editButtonProps?: EditButtonProps;
} & ComponentProps<typeof Grid>;

export const InfoBox: React.FC<PropsWithChildren<InfoBoxProps>> = ({
  title,
  editButtonProps,
  notification,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      p={2}
      mt={1}
      columns={4}
      border="1px solid"
      borderColor="grey.A200"
      bgcolor="common.white"
      borderRadius={2}
      {...rest}
    >
      {notification}
      <Grid item xs={4} mb={3} display="flex" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        {editButtonProps && (
          <Button
            variant="text"
            startIcon={<EditIcon />}
            data-testid="info-box-edit-button"
            {...editButtonProps}
          >
            {editButtonProps.label ? editButtonProps.label : t('common.edit')}
          </Button>
        )}
      </Grid>
      {children}
    </Grid>
  );
};
