import React from 'react';
import { Box, Typography, useFormatPrice } from '@clippings/paper';
import { CardForm } from './CardForm';
import { Elements } from '@stripe/react-stripe-js';
import { StripeComponents } from 'libs/api/payment/types';
import { usePayment } from 'libs/providers';
import { useQuoteCheckoutContext } from 'libs/Quotes/Checkout/providers';
import { useStripePaymentIntent } from 'libs/api/payment/hooks';
import { useTranslation } from 'react-i18next';

export const CardFormWrapper: React.FC = () => {
  const { instance } = usePayment();
  const formatPrice = useFormatPrice();
  const { t } = useTranslation();
  const { payment, populateStripeComponents, handleCardFormEvents } = useQuoteCheckoutContext();
  const { data, isFetching, isSuccess } = useStripePaymentIntent(payment?.id, {
    enabled: !!payment,
  });

  if (isFetching || !isSuccess) {
    return null;
  }

  const handlePopulateStripeComponents = (stripeComponents: StripeComponents) =>
    populateStripeComponents({ ...stripeComponents, referenceId: data.intentObject.id });

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      sx={{ wordWrap: 'break-word' }}
      paddingTop={1}
    >
      {payment.processingFeePercentage > 0 && (
        <Typography
          variant="body1"
          fontWeight="fontWeightMedium"
          minWidth="100%"
          width="min-content"
          paddingBottom={1}
        >
          {t('checkout.creditCardChargeFeeDisclaimer', {
            percentage: payment.processingFeePercentage,
            total: formatPrice(
              payment.amountWithProcessingFee.amount,
              payment.amountWithProcessingFee.currency
            ),
          })}
        </Typography>
      )}
      <Elements
        key={data.intentObject.id}
        stripe={instance}
        options={{
          clientSecret: data.intentObject.client_secret ?? undefined,
        }}
      >
        <CardForm
          populateStripeComponents={handlePopulateStripeComponents}
          onChange={handleCardFormEvents}
        />
      </Elements>
    </Box>
  );
};
