import React from 'react';
import { SignatureModal } from './SignatureModal/SignatureModal';
import { TermsConditionsModal } from './TermsConditionsModal';
import { useQuoteCheckoutContext } from '../../providers';

export function CheckoutModals() {
  const {
    termsAndConditionModal,
    signatureModal,
    loading,
    data,
    handleSignatureSubmit,
    acceptTermsAndCondition,
    toggleSignatureModal,
    toggleTermsAndConditionModal,
  } = useQuoteCheckoutContext();

  return (
    <>
      <TermsConditionsModal
        open={termsAndConditionModal}
        onClose={toggleTermsAndConditionModal}
        onOk={acceptTermsAndCondition}
      />
      <SignatureModal
        onCancel={toggleSignatureModal}
        onSubmit={handleSignatureSubmit}
        open={signatureModal}
        loading={loading}
        data={data}
      />
    </>
  );
}
