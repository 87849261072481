import React, { ComponentProps, useState } from 'react';
import { CatalogueDialog } from './CatalogueDialog';
import { Company } from 'libs/api/iam/types';
import { Grid } from '@clippings/paper';
import { InfoBox, InfoLine } from 'libs/shared';
import { useTranslation } from 'react-i18next';

export interface CatalogueInfoProps {
  company: Company;
  isEditable?: boolean;
  boxProps?: ComponentProps<typeof Grid>;
}

export const CatalogueInfo: React.FC<CatalogueInfoProps> = ({
  company,
  isEditable = true,
  boxProps = {},
}) => {
  const [catalogueDialogOpen, setCatalogueDialogOpen] = useState(false);
  const catalogues = company.catalogues.map(catalogue => catalogue.name);
  const { t } = useTranslation();

  return (
    <>
      <InfoBox
        title={t('common.catalogue')}
        editButtonProps={
          isEditable
            ? {
                onClick: () => setCatalogueDialogOpen(true),
                'aria-label': 'edit-catalogues-info',
              }
            : undefined
        }
        {...boxProps}
      >
        <InfoLine
          data-testid="catalogue-info-infoline-createdOn"
          label={t('companies.catalogueAccess')}
          value={catalogues.join(', ')}
          divider={false}
        />
      </InfoBox>
      <CatalogueDialog
        company={company}
        open={catalogueDialogOpen}
        onClose={() => setCatalogueDialogOpen(false)}
      />
    </>
  );
};
