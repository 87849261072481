import React, { FC } from 'react';
import { Box, useTranslation } from '@clippings/paper';
import { OrderStatus } from 'libs/api/order/types';
import { OrderStatusSelect } from './OrdersStatusSelect';
import { SingleTeamUser } from 'libs/SalesApp/Quotes/quoteUtils';
import { User } from 'libs/api/iam/types';
import { UserChipFilter } from 'libs/SalesApp/Quotes/QuotesTable/components';

export type OrdersFilterStripProps = {
  staffMembers: User[];
  onStatusSelect: (status?: OrderStatus) => void;
  onTeamsOfUserSelect: (user?: SingleTeamUser) => void;
  filters: {
    status?: OrderStatus;
    teamsOfUser?: SingleTeamUser;
  };
};
export const OrdersFilterStrip: FC<OrdersFilterStripProps> = ({
  filters,
  staffMembers,
  onStatusSelect,
  onTeamsOfUserSelect,
}) => {
  const { t } = useTranslation();
  const selectedTeamsOfUser: User[] = staffMembers.filter(x => x.id === filters.teamsOfUser?.id);

  return (
    <Box py={1} display="flex" justifyContent="flex-end" gap={2}>
      <UserChipFilter
        label={t('common.userTeams')}
        data-testid="sa-orders-filter-on-teams-of-user"
        onSelect={onTeamsOfUserSelect}
        selected={selectedTeamsOfUser}
        options={staffMembers}
      />
      <OrderStatusSelect status={filters?.status} onChange={onStatusSelect} />
    </Box>
  );
};
