import React, { useMemo } from 'react';
import { Button, useTranslation } from '@clippings/paper';
import { FeaturesBox, FeaturesModel } from './FeaturesBox';
import { Modal } from 'libs/Components';
import { Row } from 'libs/shared';
import { Title } from './Title';
import { keys } from 'lodash';
import { routes } from 'libs/Constants';
import { useRegistrationPromptModal } from './useRegistrationPromptModal';

export function RegistrationPromptModal() {
  const { t } = useTranslation();
  const { quoteNumber, isModalOpen, continueAsGuestClickHandler } = useRegistrationPromptModal();

  const userFeaturesTranslated: Record<'registered' | 'guest', FeaturesModel> = useMemo(
    () => ({
      registered: keys(registeredUserFeatures).reduce((acc, key: any) => {
        acc[t(key)] = registeredUserFeatures[key];

        return acc;
      }, {} as FeaturesModel),
      guest: keys(guestUserFeatures).reduce((acc, key: any) => {
        acc[t(key)] = guestUserFeatures[key];

        return acc;
      }, {} as FeaturesModel),
    }),
    []
  );

  return (
    <Modal
      open={isModalOpen}
      withCloseButton={false}
      actionButtons="none"
      data-testid="registration-prompt-modal"
      maxWidth="md"
      PaperProps={styles.modalPaperProps}
      sx={styles.modalSx}
      dialogTitleProps={styles.dialogTitleProps}
      title={<Title quoteLabel={quoteNumber ?? ''} />}
    >
      <Row
        flexDirection={styles.featuresWrapperFlexDirection as any}
        justifyContent="center"
        gap={4}
      >
        <FeaturesBox
          title={t('common.registeredUser')}
          featuresModel={userFeaturesTranslated.registered}
          action={
            <Button
              variant="contained"
              fullWidth
              href={routes.authentication.register}
              data-testid="registration-prompt-signup-button"
            >
              {t('common.signUp')}
            </Button>
          }
        />
        <FeaturesBox
          title={t('common.guestUser')}
          featuresModel={userFeaturesTranslated.guest}
          action={
            <Button
              variant="outlined"
              fullWidth
              data-testid="registration-prompt-continue-as-guest-button"
              onClick={continueAsGuestClickHandler}
            >
              {t('quotes.share.continueAsGuest')}
            </Button>
          }
        />
      </Row>
    </Modal>
  );
}

/**
 * Keys are the i18n translation keys.
 * The values are booleans that indicate if the feature is available or not.
 */
const registeredUserFeatures: FeaturesModel = {
  'quotes.share.addRemoveProducts': true,
  'quotes.share.addRemoveQuoteLabel': true,
  'quotes.share.changeVersionsAndRestore': true,
  'quotes.share.useMessages': true,
  'quotes.share.changeBillingDetails': true,
  'quotes.share.changeShippingDetails': true,
  'quotes.share.addShippingMethods': true,
  'quotes.share.makeARequest': true,
  'common.checkout': true,
};

const guestUserFeatures: FeaturesModel = {
  'quotes.share.addRemoveProducts': false,
  'quotes.share.addRemoveQuoteLabel': true,
  'quotes.share.changeVersionsAndRestore': true,
  'quotes.share.useMessages': true,
  'quotes.share.changeBillingDetails': true,
  'quotes.share.changeShippingDetails': true,
  'quotes.share.addShippingMethods': true,
  'quotes.share.makeARequest': true,
  'common.checkout': true,
};

const styles = {
  dialogTitleProps: { sx: { display: 'flex', justifyContent: 'center' } },
  modalSx: {
    backdropFilter: 'blur(6px)',
  },
  modalPaperProps: {
    className: 'modal__content',
    sx: {
      width: '720px',
      p: 5,
    },
  },
  featuresWrapperFlexDirection: { xs: 'column', md: 'row' },
};
