import React from 'react';
import { FullAccessBanner } from '.';
import { QuoteActionsStack } from 'libs/Quotes/QuoteActionsStack';
import { QuoteHeader } from 'libs/Quotes/QuoteHeader';
import { QuoteSummary } from 'libs/Quotes/QuoteSummary';
import { QuoteTable } from 'libs/Quotes/QuoteTable/QuoteTable';
import { ViewContainer } from 'libs/Quotes/quoteView/ViewContainer';
import { useQuoteProvider } from 'libs/Quotes/providers';

export function GuestQuoteViewContent() {
  const { isReadonly } = useQuoteProvider();

  return (
    <>
      <FullAccessBanner />
      <QuoteHeader withActions={false} />
      <QuoteActionsStack />
      <QuoteTable readonly canAddQuoteItem={false} />
      <ViewContainer justifyContent="flex-end">
        <QuoteSummary readonly={isReadonly} />
      </ViewContainer>
    </>
  );
}
