import React from 'react';
import { ArchiveIcon, ListItemIcon, MenuItem } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { useQuoteTransitionAction } from 'libs/shared/hooks/useQuoteTransitionAction';
import { useTranslation } from 'react-i18next';

export interface ArchiveQuoteButtonProps {
  quote: Quote;
  onClick: (mouseEvent: any) => void;
}

export const ArchiveQuoteButton: React.FC<ArchiveQuoteButtonProps> = ({ quote, onClick }) => {
  const { t } = useTranslation();
  const { shouldDisableTransitionButton } = useQuoteTransitionAction(quote, 'archive', []);

  return (
    <MenuItem
      onClick={onClick}
      key="menu-item-archive"
      data-testid="archive-quote"
      disabled={shouldDisableTransitionButton}
    >
      <ListItemIcon key="list-archive-icon">
        <ArchiveIcon key="archive-icon" />
      </ListItemIcon>
      {t('quotes.archiveQuote')}
    </MenuItem>
  );
};
