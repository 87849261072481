import React from 'react';
import { EntityDownloadMenu, PdfLinkMenuItem } from 'libs/shared';
import { isGuestApp, path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type OrderExportMenuProps = {
  orderId: string;
  disabled?: boolean;
};

export function OrderExportMenu({ orderId, disabled = false }: OrderExportMenuProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const orderPdfRoute = isGuestApp(pathname) ? routes.guestApp.orders.pdf : routes.orders.pdf;
  const linkTo = path(orderPdfRoute, {
    number: orderId,
  });

  return (
    <EntityDownloadMenu
      disabled={disabled}
      buttonDataTestId="footer-download-button"
      menuDataTestId="footer-download-menu"
    >
      {({ handleClose }) => (
        <PdfLinkMenuItem to={linkTo} label={t('common.pdf')} onClick={handleClose} />
      )}
    </EntityDownloadMenu>
  );
}
