import React, { FC, useContext, useEffect } from 'react';
import { MessageContext, MessageSimple, MessageUIComponentProps } from 'stream-chat-react';
import { useChatChannel } from '../../../providers';

export const ChatMessageSimple: FC<MessageUIComponentProps> = props => {
  const { setLatestMessageError } = useChatChannel();
  const messageContext = useContext(MessageContext);
  const message = messageContext?.message;

  useEffect(() => {
    if (message?.error) {
      setLatestMessageError(message.error);
    }
  }, [message]);

  return <MessageSimple {...props} />;
};
