/* eslint-disable @typescript-eslint/no-empty-function */
import { QuoteListItem } from 'libs/api/quotes/types';
import { createContext } from 'react';

export const QuoteListContext = createContext({
  activeQuotes: [] as QuoteListItem[],
  archivedQuotes: [] as QuoteListItem[],
  activeQuotesLoading: true,
  archivedQuotesLoading: true,
  handleLoadMoreActiveQuotes: () => {},
  handleLoadMoreArchivedQuotes: () => {},
  activeShowLoadMoreQuotes: false,
  archivedShowLoadMoreQuotes: false,
  activeLoadMoreLoading: false,
  archivedLoadMoreLoading: false,
  onArchive: (_quote: QuoteListItem) => {},
  onUnarchive: (_quote: QuoteListItem) => {},
  disableActions: false,
  onDuplicate: (_quote: QuoteListItem) => {},
});
