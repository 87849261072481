import React, { useMemo } from 'react';
import { AddressFormFields } from 'libs/shared/components/address/AddressFormFields';
import {
  Box,
  Button,
  DeepPartial,
  FormProvider,
  FormTextField,
  LoadingButton,
  getAddressValidation,
  getCompanyValidation,
  useForm,
  useSyncServerErrors,
  yup,
  yupResolver,
} from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import {
  CompanyFormFields,
  getMetadataValidation,
} from 'libs/SalesApp/Companies/components/CompanyFormFields';
import { MetadataFields } from 'libs/SalesApp/Companies/components/MetadataFields';
import { getConditionalAddressProps } from 'libs/SalesApp/utils/formData';
import { useAddressDefaultCountry } from 'libs/shared/hooks/useAddressDefaultCountry';
import { useAppConfiguration } from 'libs/providers';
import { useCountriesAndStates } from 'libs/shared';
import { useCreateCompany } from 'libs/api/iam/hooks';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { useTranslation } from 'react-i18next';

export type CompanyCreateFormData = {
  name: string;
  registrationNumber: string;
  taxNumber: string;
  type: string;
  website: string;
  phone: string;
  contactName: string;
  email: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  province: string;
  zip: string;
  country: string;
  metadata: Record<string, string>;
  status: string;
  taxRate?: number;
};

export type CompanyCreateFormProps = {
  onCancel: () => void;
  onSuccess: (company: Company) => void;
  defaultValues?: DeepPartial<CompanyCreateFormData>;
};

export const CompanyCreateForm: React.FC<CompanyCreateFormProps> = ({
  onCancel,
  onSuccess,
  defaultValues = {},
}) => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();
  const { countries, countriesByShortName, states } = useCountriesAndStates();

  const createCompanyMutation = useCreateCompany({
    onSuccess: data => {
      onSuccess(data);
    },
  });

  const serverErrors = useServerFormErrors(createCompanyMutation.error);

  const addressValidation = useMemo(() => getAddressValidation(brand), [brand]);
  const companyValidation = useMemo(() => getCompanyValidation(brand), [brand]);
  const metadataValidation = useMemo(() => getMetadataValidation(brand), [brand]);

  const schema = yup.object().shape({
    name: companyValidation.name,
    registrationNumber: companyValidation.registrationNumber,
    taxNumber: companyValidation.taxNumber,
    type: companyValidation.type,
    website: companyValidation.website,
    phone: companyValidation.phone,
    contactName: addressValidation.contactName,
    email: addressValidation.email,
    address: addressValidation.address,
    address2: addressValidation.address2,
    city: addressValidation.city,
    state: addressValidation.state,
    province: addressValidation.province,
    zip: addressValidation.zipCode,
    country: addressValidation.country,
    metadata: metadataValidation,
    status: yup.string().required('common.required'),
  });

  const createCompanyForm = useForm<CompanyCreateFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useAddressDefaultCountry(countries, createCompanyForm);

  useSyncServerErrors(createCompanyForm, serverErrors);

  const selectedCountry = createCompanyForm.watch('country');

  const handleFormSubmit = (formData: CompanyCreateFormData) => {
    return createCompanyMutation.mutate({
      name: formData.name,
      registrationNumber: formData.registrationNumber,
      taxNumber: formData.taxNumber,
      type: formData.type,
      website: formData.website,
      phone: formData.phone,
      metadata: formData.metadata,
      status: formData.status,
      taxRate: formData.taxRate,
      billingAddresses: [
        {
          default: true,
          contactName: formData.contactName,
          email: formData.email,
          address: formData.address,
          address2: formData.address2,
          city: formData.city,
          zip: formData.zip,
          companyName: formData.name,
          vatNumber: formData.taxNumber,
          ...getConditionalAddressProps(
            countriesByShortName,
            states,
            formData.country,
            formData.state,
            formData.province
          ),
        },
      ],
    });
  };

  return (
    <FormProvider {...createCompanyForm} {...{ schema }}>
      <form
        noValidate
        onSubmit={createCompanyForm.handleSubmit(handleFormSubmit)}
        data-testid="sa-create-company-form"
      >
        <Box display="flex" flexDirection="column">
          <CompanyFormFields />
          <AddressFormFields type="company" />
          <FormTextField name="email" label={t('common.billingEmail')} />
          <MetadataFields selectedCountry={selectedCountry} />
        </Box>
        <Box pt={2} display="flex" justifyContent="flex-end">
          <Button data-testid="dialog-button-cancel" color="secondary" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            loading={createCompanyMutation.isLoading || createCompanyMutation.isSuccess}
            type="submit"
            variant="contained"
            data-testid="dialog-button-create"
          >
            {t('forms.createCompany')}
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  );
};
