import React, { PropsWithChildren, createContext, useContext } from 'react';
import { useCreateUserCompanyUtils } from '../hooks/useCreateUserCompanyUtils';

export type CreateUserCompanyContextValue = ReturnType<typeof useCreateUserCompanyUtils>;

const CreateUserCompanyContext = createContext<CreateUserCompanyContextValue>(
  {} as CreateUserCompanyContextValue
);

export const CreateUserCompanyProvider = ({
  children,
  ...rest
}: PropsWithChildren<CreateUserCompanyContextValue>) => (
  <CreateUserCompanyContext.Provider value={rest}>{children}</CreateUserCompanyContext.Provider>
);

export const useCreateUserCompany = () => {
  const context = useContext(CreateUserCompanyContext);

  if (!context) {
    throw new Error('useCreateUserCompany must be used within a CreateUserCompanyProvider');
  }

  return context;
};
