import React, { PropsWithChildren, ReactNode } from 'react';
import { ArrowBackIcon, Box, IconButton } from '@clippings/paper';
import { useAddressContext } from './AddressContext';

interface AddressDialogHeaderProps {
  icon?: ReactNode;
  onBack?: () => void;
}

export const AddressDialogHeader = ({
  onBack,
  icon,
  children,
}: PropsWithChildren<AddressDialogHeaderProps>) => {
  const { hasBack } = useAddressContext();

  const iconWrapper = hasBack ? (
    <Box borderRadius="50%" bgcolor="grey.A200">
      <IconButton aria-label="back" onClick={onBack}>
        <ArrowBackIcon color="secondary" />
      </IconButton>
    </Box>
  ) : (
    <Box padding={1} borderRadius="50%" bgcolor="grey.A200" display="flex">
      {icon}
    </Box>
  );

  return (
    <Box display="flex" gap={1} alignItems="center">
      <Box borderRadius="50%" bgcolor="grey.A200">
        {iconWrapper}
      </Box>
      {children}
    </Box>
  );
};
