import React, { FC, useEffect } from 'react';
import { ErrorLayoutProps } from './ErrorLayout';
import { GenericErrorView } from './GenericErrorView';
import { Navigate, useLocation } from 'react-router-dom';

type GenericErrorProps = ErrorLayoutProps;

export const GenericError: FC<GenericErrorProps> = ({ to }) => {
  const location = useLocation();

  useEffect(() => {
    // Wipe out the state from the history entry so that refreshing the page doesn't work
    window.history.replaceState(null, '');
  }, []);

  if (!location.state?.redirectedByErrorHandling) {
    return <Navigate to={to} />;
  }

  return <GenericErrorView to={to} />;
};
