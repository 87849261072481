import { AppType, getCurrentApp } from 'libs/Utils';
import { QUOTE_STATUSES, routes } from 'libs/Constants';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export function useQuoteCheckoutGuard(
  quoteStatus: QuoteListItemStatus,
  quoteNumber: string,
  number: string,
  isLoading: boolean
) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // redirect to the orders page if the quote is ordered
    if (!isLoading && quoteStatus === QUOTE_STATUSES.ORDERED) {
      const route = getRedirectRouteForOrderedQuotes(pathname);

      navigate(generatePath(route, { number }), { replace: true });
    }
  }, [quoteStatus, isLoading]);

  useEffect(() => {
    /**
     * redirect if the quote number in the url
     * does not match the quote number from the fetched version, taken from the version id in the url
     */
    if (!isLoading && quoteNumber !== number) {
      const route = getRedirectRouteForMismatchedQuoteNumbers(pathname);

      navigate(generatePath(route), { replace: true });
    }
  }, [isLoading, quoteNumber, number]);

  return null;
}

////

const redirectRoutesForOrderedQuotesByApp: Record<AppType, string> = {
  customer: routes.orders.show,
  sales: routes.salesApp.orders.show,
  guest: routes.guestApp.orders.show,
};

function getRedirectRouteForOrderedQuotes(pathname: string): string {
  const currentApp = getCurrentApp(pathname);

  return redirectRoutesForOrderedQuotesByApp[currentApp];
}

const redirectRoutesForMismatchedQuoteNumbersByApp: Record<AppType, string> = {
  customer: routes.orders.list,
  sales: routes.salesApp.orders.list,
  /**
   * Redirect to the error page if there's a mismatch between the quote number in the url and the fetched quote number
   * for the guest app. Listing of the orders is not available for the guest app.
   */
  guest: routes.accessDenied,
};

function getRedirectRouteForMismatchedQuoteNumbers(pathname: string): string {
  const currentApp = getCurrentApp(pathname);

  return redirectRoutesForMismatchedQuoteNumbersByApp[currentApp];
}
