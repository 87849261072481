import React, { useState } from 'react';
import { DialogAction, SystemUser } from '@clippings/paper';
import { InfoBox, InfoLine } from 'libs/shared';
import { User } from 'libs/api/iam/types';
import { UserDialog } from 'libs/SalesApp/Users';
import { getUserRole } from 'libs/SalesApp/Users/utils';
import { useTranslation } from 'react-i18next';

interface UserInfoBoxProps {
  user: User;
  isEditable?: boolean;
}

export const UserInfoBox: React.FC<UserInfoBoxProps> = ({ user, isEditable = false }) => {
  const { t } = useTranslation();
  const [openEditCustomerUserDialog, setOpenEditCustomerUserDialog] = useState(false);
  const onEdit = () => {
    setOpenEditCustomerUserDialog(true);
  };

  const onDialogClose = () => setOpenEditCustomerUserDialog(false);

  return (
    <>
      <InfoBox
        title={t('common.generalInformation')}
        editButtonProps={
          isEditable
            ? {
                onClick: onEdit,
                'aria-label': 'edit-general-info',
              }
            : undefined
        }
        data-testid="user-general-info"
      >
        <InfoLine
          data-testid="user-info-line-firstName"
          label={t('common.firstName')}
          value={user.firstName}
        />
        <InfoLine
          data-testid="user-info-line-lastName"
          label={t('common.lastName')}
          value={user.lastName}
        />
        <InfoLine
          data-testid="user-info-line-position"
          label={t('common.position')}
          value={user.jobTitle}
        />
        <InfoLine data-testid="user-info-line-email" label={t('common.email')} value={user.email} />
        <InfoLine
          data-testid="user-info-line-phoneNumber"
          label={t('common.phoneNumber')}
          value={user.phone}
        />
        <InfoLine
          data-testid="user-info-line-role"
          label={t('common.systemRole')}
          value={t(`roles.${getUserRole(user)}`)}
          divider={false}
        />
      </InfoBox>
      <UserDialog
        type={SystemUser.Customer}
        action={DialogAction.Edit}
        user={user}
        defaultValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          company: user.company,
          phone: user.phone ?? '',
          jobTitle: user.jobTitle ?? '',
        }}
        onUpdateSuccess={onDialogClose}
        isOpen={openEditCustomerUserDialog}
        onClose={onDialogClose}
      />
    </>
  );
};
