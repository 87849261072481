import React from 'react';
import { FlexRow, Monetary, Typography } from '@clippings/paper';
import { QuoteListItem } from 'libs/api/quotes/types';
import { useTranslation } from 'react-i18next';

export type QuoteRequestContentProps = {
  quote: QuoteListItem;
};

export function QuoteRequestContent({ quote }: QuoteRequestContentProps) {
  const { number, total } = quote;
  const { t } = useTranslation();

  return (
    <FlexRow alignItems="center" gap="4px" data-testid={`requested-quote-${number}`}>
      <Typography variant="body2" color="secondary">
        {t('inbox.requestedAQuote')}
      </Typography>
      <Typography variant="body2" fontWeight="bold">
        #{number}
      </Typography>
      <Typography variant="body2" color="secondary">
        {t('inbox.price')}
      </Typography>
      <Monetary amount={total.amount} currency={total.currency} fontWeight="bold" />
    </FlexRow>
  );
}
