import { QuoteListItemStatus } from 'libs/api/quotes/types';

export const QUOTE_STATUSES: Record<string, QuoteListItemStatus> = {
  DRAFT: QuoteListItemStatus.Draft,
  REQUESTED: QuoteListItemStatus.Requested,
  ORDERED: QuoteListItemStatus.Ordered,
  EXPIRED: QuoteListItemStatus.Expired,
  APPROVED: QuoteListItemStatus.Approved,
  ARCHIVED: QuoteListItemStatus.Archived,
  READY: QuoteListItemStatus.Ready,
};

export const quoteStatusMap = {
  draft: {
    labelKey: 'quotes.draft',
    color: 'secondary',
  },
  requested: {
    labelKey: 'quotes.requested',
    color: 'warning',
  },
  approved: {
    labelKey: 'quotes.approved',
    color: 'info',
  },
  ready: {
    labelKey: 'quotes.ready',
    color: 'success',
  },
  expired: {
    labelKey: 'quotes.expired',
    color: 'error',
  },
  ordered: {
    labelKey: 'quotes.ordered',
    color: 'success',
  },
  archived: {
    labelKey: 'quotes.archived',
    color: 'default',
  },
} as const;
