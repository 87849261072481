import {
  BaseUser,
  BillingAddress,
  LeadTime,
  ListQueryParams,
  Money,
  PaymentRule,
  Picture,
  SalesQuoteDiscount,
  ShippingAddress,
  Translatable,
} from 'libs/api/common/types';
import { IntendedUseOptionsType } from 'libs/shared/hooks/useIntendedUse';
import { Location } from 'libs/api/location/types';
import { Nullable } from 'libs/Utils';

export type Address = {
  id: number;
  contactName: string;
  address: string;
  city: string;
  state?: Location;
  province?: string;
  zip: string;
  country: Location;
  email: string;
  companyName?: string;
  vatNumber?: string;
};

export type Tax = {
  type: string;
  price: Money;
};

type ShippingTime = [number, number];

export type QuoteDeliveryItem = {
  id: number;
  name: string;
  deliveryRef: string | null;
  custom: boolean;
  tax: Money;
  netPrice: Money;
  taxPercentage: number;
  shippingTime: LeadTime;
  type: 'automatic' | 'manual';
};

export interface DeliveryMethod {
  id: string;
  names: Translatable[];
  descriptions: Translatable[];
}

export interface AutomaticDeliveryMethod extends DeliveryMethod {
  type: 'automatic';
  price: Money;
  shippingTime: ShippingTime;
}

export interface ManualDeliveryMethod extends DeliveryMethod {
  type: 'manual';
  price: Money;
}

export type ApplicableDeliveryMethod = AutomaticDeliveryMethod | ManualDeliveryMethod;

export interface DeliveryMethodEvaluationAddress {
  country: string;
  city?: string;
  state?: string;
  county?: string;
}

export interface DeliveryMethodEvaluationResponse {
  items: ApplicableDeliveryMethod[];
}

export enum QuoteListItemStatus {
  Draft = 'draft',
  Requested = 'requested',
  Ordered = 'ordered',
  Expired = 'expired',
  Approved = 'approved',
  Ready = 'ready',
  Archived = 'archived',
}

export interface Project {
  id: number;
  name: string;
  companyId: number;
  vendorId: string;
}

export interface ListProjectsParams extends ListQueryParams {
  /** Filter projects by one or more company ids separated by comma */
  companyIds?: string;
  /** Filter projects by one or more vendorId separated by comma */
  vendorId?: string;
  /** Filter projects name */
  name?: string;
  /** How many projects per page. Defaults to 20 */
  itemsPerPage?: number;
}

export interface QuoteBase {
  id: number;
  number: string;
  status: QuoteListItemStatus;
  name?: string;
  catalogue: string;
  customer?: BaseUser;
  salesRepresentative?: BaseUser;
  total: Money;
  versionId: number;
  canArchive: boolean;
  canRestore: boolean;
  canRenew: boolean;
  canEditNonContractual: boolean;
  userActions: UserActions[];
}

export interface QuoteListItem extends QuoteBase {
  latestActivityAt: string;
  editable: boolean;
  project: Project | null;
  customerCompanyId: number;
}

export interface QuoteWithNotification extends QuoteListItem {
  newMessages?: boolean;
}

// https://designtechnologies.stoplight.io/docs/design-technologies/e421d6da29587-quote-dto
export interface Quote extends QuoteListItem {
  activeVersion: boolean;
  versions: Quote[];
  versionNumber: number;
  status: QuoteListItemStatus;
  createdAt: string;
  expiresAt?: string;
  orderNumber?: string;
  active: boolean;
  shippingAddress: ShippingAddress | null;
  billingAddress: BillingAddress | null;
  subtotal: Money;
  contractItemsSubTotal: Nullable<Money>;
  tax: Money;
  totalBeforeTaxes: Money;
  orderedAt?: string;
  quoteItemProducts: QuoteProductItem[];
  deliveryItems: QuoteDeliveryItem[];
  discountItems: SalesQuoteDiscount[];
  note?: string;
  externalNote?: string;
  metadata: Nullable<QuoteMetadata>;
  documents: QuoteDocument[];
  visibleForCustomer?: boolean;
}

export type UserActions = 'CAN_SHARE_QUOTE' | 'CAN_EDIT_ITEMS' | 'CAN_DUPLICATE_QUOTE';

export type QuoteMetadata = {
  customDeliveryDate?: CustomDeliveryDateModel;
  intendedUse?: IntendedUseOptionsType;
};

export type QuoteItemProperty = {
  code: string;
  name: string;
  value: string;
  priceGroupVendorId?: string;
  priceGroupName?: string;
  priceGroupWeight?: number;
};

export interface ContractFields {
  contractListPrice: Nullable<Money>;
  contractNetPrice: Nullable<Money>;
  contractSubtotal: Nullable<Money>;
}

export interface QuoteProductItem extends ContractFields {
  id: number;
  position: number;
  parentQuoteId: string;
  discountPercent: number;
  discountTitle: string;
  catalogueBasePrice: Money;
  quantity: number;
  netPrice: Money;
  subtotal: Money;
  productId: string | null;
  productBaseId: number;
  productName: string;
  variationName: string | null;
  updatedAt: string;
  leadTime: LeadTime | null;
  picture: Picture | null;
  brandName: string;
  sku: string;
  vendorSku: string;
  width: string;
  depth: string;
  height: string;
  properties: QuoteItemProperty[] | null;
  taxPercent?: number;
  custom?: boolean;
  isCataloguePicture?: boolean;
  note: Nullable<string>;
  completed: boolean;
  noteRequired: boolean;
  sample: boolean;
}

export interface AddSampleToQuotePayload {
  id: number;
}

export interface AddQuoteItemCatalogueProduct {
  productId: string;
  quantity: number;
  catalogue: string;
  sku: string;
  custom?: false;
}

export interface AddQuoteItemCustomProduct {
  custom: true;
  quantity: 1;
  catalogue: string;
  variationName: string;
}

export type AddQuoteItemProduct = AddQuoteItemCatalogueProduct | AddQuoteItemCustomProduct;

export interface UpdatePaymentTermsVariables {
  versionId: number;
  paymentTerms: PaymentRule[];
}

export interface QuoteVersion {
  id: number;
  createdAt: string;
  status: string;
  versionNumber: number;
  active: boolean;
}

export type CustomDeliveryDateType = 'arriveBy' | 'shipBy';

export type CustomDeliveryDateModel = {
  type: CustomDeliveryDateType;
  date: Date;
};

export interface QuoteDocument {
  /**
   * clippings/file-name.pdf
   */
  remoteId?: string;
  /**
   * file-name.pdf
   */
  fileName: string;
  /**
   * 497f6eca-6276-4993-bfeb-53cbbbba6f08
   */
  id: string;
  /**
   * 1, 2, 3 etc.
   */
  version: number;
  /**
   * In bytes, e.g. 123456
   */
  fileSize: number;
}

export type QuoteSharee = {
  email: string;
};

export type AddQuoteShareesPayload = {
  message: string;
  sharees: QuoteSharee[];
};

export type QuoteShareesList = BaseUser[];
