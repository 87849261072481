import React from 'react';
import { CreateCustomerUserDialog } from '../../Users/customer/components/CreateCustomerUserDialog';
import { User } from 'libs/api/iam/types';
import { mapBaseUser } from 'libs/SalesApp/Quotes/quoteUtils';
import { useQuoteDetails } from 'libs/SalesApp/Quotes/providers';

export type CreateCustomerUserFromQuoteDialogProps = {
  isOpen: boolean;
  setIsOpenUserDialog: (isOpen: boolean) => void;
};

export const CreateCustomerUserFromQuoteDialog: React.FC<
  CreateCustomerUserFromQuoteDialogProps
> = ({ setIsOpenUserDialog, isOpen }) => {
  const { handleQuoteUpdate } = useQuoteDetails();

  const handleCustomerCreateSuccess = (user: User) => {
    handleQuoteUpdate({ customer: mapBaseUser(user) }, { withOptimisticUpdate: false });
    setIsOpenUserDialog(false);
  };

  return (
    <CreateCustomerUserDialog
      isOpen={isOpen}
      onClose={() => setIsOpenUserDialog(false)}
      onCreateSuccess={handleCustomerCreateSuccess}
    />
  );
};
