import { CreateUserVariables, UpdateUserVariables, User, UserStatus } from 'libs/api/iam/types';
import { CustomerUserFormValues } from '../components/userDialog/UserDialogForm';
import { MutationOptions } from 'libs/api/common/types';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { StaffUserFormValues } from '../staff/components/StaffUserDialogForm';
import { useCreateUser, useUpdateTeam, useUpdateUser } from 'libs/api/iam/hooks';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { userRolesSystemRoleMap } from '../utils/user.utils';

const invalidateUsersQueries = (queryClient: QueryClient, userId?: number) =>
  Promise.all([
    queryClient.invalidateQueries(['users', userId]),
    queryClient.invalidateQueries(['infinite-users']),
  ]);

export const useUserHandlers = ({
  onCreateSuccess,
  onUpdateSuccess,
}: {
  onCreateSuccess?: MutationOptions<User, CreateUserVariables>['onSuccess'];
  onUpdateSuccess?: MutationOptions<User, UpdateUserVariables>['onSuccess'];
}) => {
  const queryClient = useQueryClient();

  const updateTeamMutation = useUpdateTeam();
  const createUserMutation = useCreateUser();
  const updateUserMutation = useUpdateUser();

  const userUpdateHandler = (id: number, values: CustomerUserFormValues) => {
    const { company, systemRole, ...rest } = values;

    updateUserMutation.mutate(
      {
        id,
        roles: systemRole ? userRolesSystemRoleMap[systemRole] : undefined,
        companyId: company.id,
        ...rest,
      },
      {
        onSuccess: (user, variables, context) => {
          invalidateUsersQueries(queryClient, variables.id).then(() => {
            onUpdateSuccess?.(user, variables, context);
          });
        },
      }
    );
  };

  const userCreateHandler = (values: CustomerUserFormValues) => {
    const { company, systemRole, ...formValues } = values;

    if (company) {
      createUserMutation.mutate(
        {
          status: UserStatus.Approved,
          companyId: company.id,
          roles: systemRole ? userRolesSystemRoleMap[systemRole] : undefined,
          ...formValues,
        },
        {
          onSuccess: (...args) => {
            invalidateUsersQueries(queryClient).then(() => {
              onCreateSuccess?.(...args);
            });
          },
        }
      );
    }
  };

  // Staff handlers
  const staffUserCreateHandler = (values: StaffUserFormValues) => {
    const { teams, systemRole, ...rest } = values;
    createUserMutation.mutate(
      {
        roles: systemRole ? userRolesSystemRoleMap[systemRole] : undefined,
        teamsIds: teams.map(x => Number(x.id)),
        ...rest,
      },
      {
        onSuccess: (user, ...rest) => {
          invalidateUsersQueries(queryClient).then(() => {
            onCreateSuccess?.(user, ...rest);
          });
        },
      }
    );
  };

  const staffUserUpdateHandler = (id: number, values: Omit<StaffUserFormValues, 'company'>) => {
    const { systemRole, teams, ...rest } = values;

    updateUserMutation.mutate(
      {
        id,
        roles: systemRole ? userRolesSystemRoleMap[systemRole] : undefined,
        teamsIds: teams.map(x => Number(x.id)),
        ...rest,
      },
      {
        onSuccess: (user, variables, context) => {
          invalidateUsersQueries(queryClient, variables.id).then(() => {
            onUpdateSuccess?.(user, variables, context);
          });
        },
      }
    );
  };

  const updateUserErrors = useServerFormErrors(updateUserMutation.error);
  const createUserErrors = useServerFormErrors(createUserMutation.error);

  const isSubmitting =
    createUserMutation.isLoading ||
    updateUserMutation.isLoading ||
    updateTeamMutation.isLoading ||
    updateTeamMutation.isSuccess ||
    createUserMutation.isSuccess ||
    updateUserMutation.isSuccess;

  return {
    createUserMutation,
    createUserErrors,
    updateUserMutation,
    updateUserErrors,
    userUpdateHandler,
    userCreateHandler,
    staffUserCreateHandler,
    staffUserUpdateHandler,
    isSubmitting,
  };
};
