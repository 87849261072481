import React, { ComponentProps } from 'react';
import { QuoteActionsStackContainer } from 'libs/Quotes/QuoteActionsStack';
import { Skeleton } from '@clippings/paper';

export function DetailedQuoteActionsStackLoading() {
  return (
    <QuoteActionsStackContainer data-testid="quote-action-stack-loader">
      <ChipMenuLoader />
      <ChipMenuLoader />
      <ChipMenuLoader />
      <ChipMenuLoader />
      <ChipMenuLoader sx={{ borderRadius: 4, ml: 'auto' }} />
      <ChipMenuLoader sx={{ borderRadius: 4, ml: 0.5 }} />
    </QuoteActionsStackContainer>
  );
}

const ChipMenuLoader: React.FC<ComponentProps<typeof Skeleton>> = props => (
  <Skeleton width="140px" height="32px" variant="rectangular" sx={{ borderRadius: 4 }} {...props} />
);
