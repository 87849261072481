import React, { PropsWithChildren } from 'react';
import { CloseableDialog, DialogProps } from '@clippings/paper';
import { DialogTitle } from 'libs/Components/Modal/DialogTitle/DialogTitle';

export interface TeamDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const TeamDialog: React.FC<PropsWithChildren<TeamDialogProps>> = ({
  open,
  onClose,
  title,
  children,
}) => {
  const onDialogClose: DialogProps['onClose'] = (_e, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <CloseableDialog
      data-testid="team-dialog"
      open={open}
      onClose={onDialogClose as any}
      title={<DialogTitle data-testid="team-dialog-title" title={title} />}
      dialogProps={{
        PaperProps: { sx: { width: 450 } },
      }}
    >
      {children}
    </CloseableDialog>
  );
};
