import React, {
  PropsWithChildren,
  RefObject,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useDropdown } from '@clippings/paper';
import { useLocation } from 'react-router-dom';

type ChatVisibilityContextValue = {
  elementRef: RefObject<HTMLDivElement>;
  onClose: () => void;
  onToggle: () => void;
  isOpen: boolean;
};

const ChatVisibilityContext = createContext({} as ChatVisibilityContextValue);

export function ChatVisibilityProvider({ children }: PropsWithChildren) {
  const location = useLocation();
  const isOpenInitial = location.hash.includes('chat');

  const drawerRef: RefObject<HTMLDivElement> = useRef(null);
  const {
    handleClose: onClose,
    handleToggle: onToggle,
    isOpen,
  } = useDropdown(drawerRef, isOpenInitial);

  const value = useMemo(() => {
    const context: ChatVisibilityContextValue = {
      elementRef: drawerRef,
      onClose,
      onToggle,
      isOpen,
    };

    return context;
  }, [onClose, onToggle, isOpen, drawerRef]);

  return <ChatVisibilityContext.Provider value={value}>{children}</ChatVisibilityContext.Provider>;
}

export function useChatVisibility() {
  const context = useContext(ChatVisibilityContext);

  if (!context) {
    throw new Error('useChatVisibility must be used within a ChatVisibilityProvider');
  }

  return context;
}
