import React from 'react';
import { Column, ListCardLoader } from 'libs/shared';
import { QuoteListItem } from 'libs/api/quotes/types';
import { QuoteListItemCard, QuoteListItemCardProps } from './QuoteListItemCard';
import { useQuoteListItemCardRenderer } from './useQuoteListItemCardRenderer';

type QuoteCardListRendererProps = {
  items: QuoteListItem[];
  isLoading: boolean;
  cardActions?: QuoteListItemCardProps['actions'];
  loadMore?: JSX.Element;
};

export function QuoteCardListRenderer({
  items,
  isLoading,
  cardActions,
  loadMore,
}: QuoteCardListRendererProps) {
  const { viewDataModel } = useQuoteListItemCardRenderer(items);

  if (isLoading) {
    return <ListCardLoader />;
  }

  return (
    <Column gap={1} data-testid="quote-card-list-renderer">
      {viewDataModel.map(item => {
        return <QuoteListItemCard key={item.number} item={item} actions={cardActions} />;
      })}

      {loadMore}
    </Column>
  );
}
