import React from 'react';
import { ArrowBackIcon, Box, Button, Skeleton } from '@clippings/paper';
import { QUOTES_STATUS_PAGE_MAP } from '../types';
import { QuoteListItemStatus, QuoteVersion } from 'libs/api/quotes/types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { getPageTitleTranslation } from '../quoteUtils';
import { routes } from 'libs/Constants';
import { useTranslation } from 'react-i18next';

interface QuoteDetailsBackButtonProps {
  quoteSelectedVersion: QuoteVersion | undefined;
  quoteActiveVersion: QuoteVersion | undefined;
}

export function QuoteDetailsBackButton({
  quoteSelectedVersion,
  quoteActiveVersion,
}: QuoteDetailsBackButtonProps): JSX.Element {
  const { t } = useTranslation();

  const quoteSelectedStatus =
    (quoteSelectedVersion?.status as QuoteListItemStatus) ?? QuoteListItemStatus.Draft;

  function getStatus() {
    if (
      quoteSelectedStatus === QuoteListItemStatus.Expired &&
      quoteSelectedVersion?.versionNumber !== quoteActiveVersion?.versionNumber
    ) {
      return (quoteActiveVersion?.status as QuoteListItemStatus) ?? QuoteListItemStatus.Draft;
    }

    return quoteSelectedStatus;
  }

  const status = getStatus();
  const route = routes.salesApp.quotes[QUOTES_STATUS_PAGE_MAP[status]];
  const translationKey = getPageTitleTranslation(QUOTES_STATUS_PAGE_MAP[status]);

  return (
    <Box sx={boxStyles}>
      {quoteSelectedVersion && quoteSelectedVersion.status ? (
        <Button
          component={ReactRouterLink}
          to={route}
          data-testid="quote-details-back-button"
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          {t('quotes.quotesTableTitle', {
            type: t(translationKey),
          })}
        </Button>
      ) : (
        <Skeleton
          width="140px"
          height="30px"
          variant="rectangular"
          sx={skeletonStyles}
          data-testid="back-button-loader"
        />
      )}
    </Box>
  );
}

const boxStyles = {
  py: 1,
};

const skeletonStyles = {
  borderRadius: 4,
  my: '3px',
};
