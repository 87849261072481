import QuoteContext, { QuoteContextState } from '../QuoteContext';
import React, { ReactNode, useContext } from 'react';
import { DuplicatedQuoteBanner } from '../Components/DuplicatedQuoteBanner';
import { Quote, QuoteVersion } from 'libs/api/quotes/types';
import { getActiveQuoteVersion, voidFn } from 'libs/shared';
import { restoreQuoteVersion } from 'libs/Quotes/quoteActions';
import { useAddresses } from 'libs/Quotes/hooks/useAddresses';
import { useDefaultQuoteMutations } from 'libs/Quotes/hooks/useDefaultQuoteMutations';
import { useListLocations } from 'libs/api/location/hooks';
import { useMutation } from '@tanstack/react-query';
import { usePaymentTermsQuery } from 'libs/api/quotes/hooks';
import { useQuote, useQuoteQuery, useQuoteUtils } from 'libs/Quotes/hooks';
import { useQuoteHandlers } from 'libs/Quotes/hooks/useQuoteHandlers';
import { useQuoteHiddenDetailProducts } from '../hooks/useQuoteHiddenDetailProducts';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

type QuoteContextType = QuoteContextState;

type QuoteProviderProps = {
  children: (number: string, quote?: Quote) => ReactNode;
};

export const useQuoteProviderValue = () => {
  const { t } = useTranslation();

  const {
    isLabelModalOpen,
    openLabelModal,
    closeLabelModal,
    isShippingModalOpen,
    isDetailedShippingModal,
    openShippingModal,
    closeShippingModal,
    isBillingModalOpen,
    openBillingModal,
    closeBillingModal,
    actionAfterSubmit,
  } = useQuote();
  const { number, version, showError, showSuccessBanner, setVersion } = useQuoteUtils();
  const {
    query: { data: quote, isError, isFetching },
    queryKey,
  } = useQuoteQuery(number, version as number);
  const paymentTermsQuery = usePaymentTermsQuery(quote.versionId, {
    enabled: !!quote.id,
  });

  useTitle(`${t('common.quote')}${quote.name ? ` ${quote.name}` : ''} #${number.toUpperCase()}`);

  const isReadonly = !quote.editable;

  const { shippingAddress, billingAddress } = useAddresses(quote, queryKey);
  const locationQuery = useListLocations('country,state', {
    enabled: isBillingModalOpen || isShippingModalOpen,
  });

  const [hiddenDetailProducts, onProductDetailsHide, onAllProductDetailsHide] =
    useQuoteHiddenDetailProducts(quote.number, 'quote');

  const {
    loading: handlersLoading,
    tableLoading,
    optimisticLoading,
    updateWithoutOptimisticLoading,
    updateAddressMutationLoading,
    handleQuoteAddressChange,
    handleQuoteUpdate,
    handleQuoteItemQuantityUpdate,
    handleQuoteItemDeletion,
    handleUnarchiveQuoteVersion,
    handleArchiveQuote,
    handleDuplicateQuote,
    handleDeleteQuoteVersion,
    handleChangeQuoteVersion,
    handleRequestQuoteApproval,
    handleQuoteItemUpdateByKey,
  } = useQuoteHandlers(quote, queryKey, showError);

  const changeQuoteVersion = (data: QuoteVersion) => {
    setVersion(data.versionNumber);
    handleChangeQuoteVersion(data);
  };

  const handleDuplicateQuoteAction = () => {
    handleDuplicateQuote(({ number }) =>
      showSuccessBanner(<DuplicatedQuoteBanner number={number} type="customer" />)
    );
  };

  const defaultMutationHandlers = useDefaultQuoteMutations(queryKey);

  const restoreQuoteVersionMutation = useMutation(
    quote => restoreQuoteVersion(quote.versionId!),
    defaultMutationHandlers
  );
  const handleRestoreQuoteVersion = async () => {
    restoreQuoteVersionMutation.mutate(quote);
  };

  const loadLatestVersionHandler = () => {
    const activeVersion =
      getActiveQuoteVersion(quote.versions) ??
      ({
        versionNumber: quote.versionNumber,
      } as QuoteVersion);

    changeQuoteVersion(activeVersion);
  };

  const loading = handlersLoading || isFetching || restoreQuoteVersionMutation.isLoading;

  const addressSubmitLoading =
    shippingAddress.isLoading || billingAddress.isLoading || tableLoading;

  const handleNonContractualUpdate = (data: any, options: any) => {
    handleQuoteUpdate(data, {
      ...(options ?? { withOptimisticUpdate: true }),
      nonContractual: true,
    });
  };

  const value: QuoteContextType = {
    number,
    isError,
    loading,
    quote,
    queryKey,
    isReadonly,
    isLabelModalOpen,
    tableLoading,
    optimisticLoading,
    updateWithoutOptimisticLoading,
    isShippingModalOpen,
    isBillingModalOpen,
    locationQuery,
    paymentTermsQuery,
    addressSubmitLoading,
    shippingAddress,
    billingAddress,
    updateAddressMutationLoading,
    hiddenDetailProducts,
    isDetailedShippingModal,
    handleQuoteUpdate,
    handleNonContractualUpdate,
    handleLabelModalClose: closeLabelModal,
    handleLabelModalOpen: openLabelModal,
    onQuoteItemQuantityUpdate: handleQuoteItemQuantityUpdate,
    onQuoteItemDelete: handleQuoteItemDeletion,
    onLoadLatestVersion: loadLatestVersionHandler,
    onUnarchiveQuoteVersion: handleUnarchiveQuoteVersion,
    onRestoreQuoteVersion: handleRestoreQuoteVersion,
    onDuplicateQuote: handleDuplicateQuoteAction,
    onDeleteQuoteVersion: handleDeleteQuoteVersion,
    changeQuoteVersion,
    onArchiveQuote: handleArchiveQuote,
    handleShippingModalClose: closeShippingModal,
    handleShippingModalOpen: openShippingModal,
    handleBillingModalClose: closeBillingModal,
    handleBillingModalOpen: openBillingModal,
    handleQuoteAddressChange,
    onProductDetailsHide,
    onAllProductDetailsHide,
    handleRequestQuoteApproval,
    onQuoteItemUpdateByKey: handleQuoteItemUpdateByKey,
    actionAfterSubmit,
    handleShippingAddressUpdate: voidFn,
    handleShippingAddressCreate: voidFn,
    handleBillingAddressCreate: voidFn,
    quoteShippingAddressQuery: voidFn,
    shippingAddressQuery: voidFn,
    handleBillingAddressUpdate: voidFn,
    handleBillingAddressDelete: voidFn,
    quoteBillingAddressQuery: voidFn,
    handleShippingAddressDelete: voidFn,
    addressCreationMutation: voidFn,
    billingAddressQuery: voidFn,
    onMakeCustom: voidFn,
    onQuoteItemUpdateDiscount: voidFn,
  };

  return value;
};

export function QuoteProvider({ children }: QuoteProviderProps) {
  const value = useQuoteProviderValue();

  if (value.isError) {
    return null;
  }

  return (
    <QuoteContext.Provider value={value}>
      {children(value.number, value?.quote)}
    </QuoteContext.Provider>
  );
}

export function useQuoteProvider() {
  const context = useContext(QuoteContext);

  if (!context) {
    throw new Error('useQuoteProvider must be used within a QuoteProvider');
  }

  return context;
}
