import React from 'react';
import { SortDirection } from 'libs/api/common/types';
import { SortableTableHeaderCell } from './SortableTableHeaderCell';
import { SortingProps } from 'libs/shared/hooks';
import { useTranslation } from 'react-i18next';

export type CellConfig = {
  key: string;
  columnName: string;
  label: any;
  columns: number;
};

export type SortableTableHeaderProps = {
  readonly cellConfig: CellConfig[];
  sort: SortingProps;
  onSort: (sort: { sort?: string; order?: SortDirection }) => void;
};

export function SortableTableHeader({ cellConfig, sort, onSort }: SortableTableHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      {cellConfig.map(cell => (
        <SortableTableHeaderCell
          {...cell}
          label={t(cell.label)}
          currentSort={sort}
          onSort={onSort}
        />
      ))}
    </>
  );
}
