import { PaymentMethod } from 'libs/api/payment/types';
import { TranslationKey } from '../../../types/react-i18next';

export const PaymentMethodInfo = {
  [PaymentMethod.Stripe]: {
    label: 'checkout.card.label' as TranslationKey,
  },
  [PaymentMethod.BankTransfer]: {
    label: 'checkout.bankTransfer.label' as TranslationKey,
  },
};
