import React, { ComponentProps, FC } from 'react';
import { Box, Button, Typography } from '@clippings/paper';
import { DataTestId } from 'libs/shared/types';

export interface EmptyTablePlaceholderProps {
  onButtonClick?: () => void;
  title: string;
  buttonTitle?: string;
  containerProps?: ComponentProps<typeof Box> & DataTestId;
  buttonProps?: ComponentProps<typeof Button>;
}
export const EmptyTablePlaceholder: FC<EmptyTablePlaceholderProps> = ({
  title,
  buttonTitle,
  onButtonClick,
  containerProps,
  buttonProps,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    bgcolor="common.white"
    border="1px solid"
    borderColor="grey.A200"
    borderRadius={1}
    textAlign="center"
    py={3}
    {...containerProps}
  >
    <Box>
      <Typography data-testid="empty-table-placeholder-title" color="secondary" variant="subtitle2">
        {title}
      </Typography>
      {buttonTitle && (
        <Button
          data-testid="empty-table-placeholder-button"
          sx={{ mt: 1.5 }}
          variant="text"
          onClick={onButtonClick}
          {...buttonProps}
        >
          {buttonTitle}
        </Button>
      )}
    </Box>
  </Box>
);
