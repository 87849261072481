import React from 'react';
import { DataTestId, FlexColumn, ThemMui, Typography } from '@clippings/paper';

type TextProps = {
  value: string;
} & DataTestId;

export function Text({ value, 'data-testid': dataTestId }: TextProps) {
  return (
    // we need the Column to mimic the height of the CellInput
    <FlexColumn position="relative" sx={columnSx}>
      <Typography variant="body2" sx={textStyles} data-testid={dataTestId}>
        {value}
      </Typography>
    </FlexColumn>
  );
}

const columnSx = {
  verticalAlign: 'top',
  padding: '4px 0 5px',
  marginTop: '9px',
};

const textStyles = {
  lineHeight: '1.4375em',
  fontSize: (theme: ThemMui) => theme.typography.pxToRem(14),
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
};
