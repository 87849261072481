import algoliasearch from 'algoliasearch';
import visitor from 'libs/Globals/visitor';
import { AppConfiguration } from 'libs/api/configuration/types';
import { ComponentProps } from 'react';
import { PaymentProvider, SearchProvider } from 'libs/providers';
import { StreamChat } from 'stream-chat';
import { UnderscoreLocale, convertUnderscoreToDash } from '@clippings/paper';
import { getAppConfiguration } from 'libs/api/configuration/actions';
import { loadStripe } from '@stripe/stripe-js';
import { setupAxiosHeaders } from 'libs/Utils/axios';
import { setupI18n } from 'libs/Utils/i18n';
import 'libs/Utils/i18n';

export type AppProps = {
  configuration: AppConfiguration;
  chatClient: StreamChat;
  searchProviderConfig: Pick<ComponentProps<typeof SearchProvider>, 'searchClient'>;
  paymentProviderInstance: ComponentProps<typeof PaymentProvider>['instance'];
};

export const bootstrap = async (): Promise<AppProps> => {
  const configuration = await getAppConfiguration();
  const locale = (visitor.locale as UnderscoreLocale) ?? configuration.locale;

  setupAxiosHeaders(locale);
  setupI18n(configuration.instance, convertUnderscoreToDash(locale));

  const chatClient = new StreamChat(configuration.getstreamKey);
  const algoliaConfig = {
    hosts: [
      {
        url: window.location.host + '/api/search',
      },
    ],
    headers: {
      'content-type': 'application/json',
    },
  };
  const searchClient = algoliasearch('id', 'key', algoliaConfig);

  const searchProviderConfig = {
    searchClient,
  };

  const paymentProviderInstance = loadStripe(configuration.stripePublicKey);

  return { configuration, chatClient, searchProviderConfig, paymentProviderInstance };
};
