import React from 'react';
import { Button, SortIcon } from '@clippings/paper';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const filterRegularProductItems = (quoteItemProducts: QuoteProductItem[] = []) =>
  quoteItemProducts.filter(x => !x.custom);

export const ToggleQuoteProductDetailsButton = () => {
  const { t } = useTranslation();
  const { quote, hiddenDetailProducts, onAllProductDetailsHide, optimisticLoading } =
    useQuoteProvider();

  const nonCustomProducts = filterRegularProductItems(quote?.quoteItemProducts);

  if (nonCustomProducts.length === 0) {
    return null;
  }

  const allNonCustomProductsHidden =
    hiddenDetailProducts.length === nonCustomProducts.length &&
    hiddenDetailProducts.every(x => x.hidden);

  const toggleItemDetailsHide = () => {
    onAllProductDetailsHide(
      nonCustomProducts.map(item => ({ id: item.id, hidden: !allNonCustomProductsHidden }))
    );
  };

  return (
    <Button
      data-testid={allNonCustomProductsHidden ? 'btn-show-all-details' : 'btn-hide-all-details'}
      startIcon={<SortIcon />}
      variant="text"
      disabled={optimisticLoading}
      sx={{
        color: theme => theme.palette.secondary.main,
        fontSize: theme => theme.typography.pxToRem(13),
        marginLeft: 0.5,
      }}
      onClick={toggleItemDetailsHide}
    >
      {allNonCustomProductsHidden ? t('common.showAllDetails') : t('common.hideAllDetails')}
    </Button>
  );
};
