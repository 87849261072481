import { CreateTeamVariables, Team, TeamUpdateVariables, TeamV2 } from 'libs/api/iam/types';
import { useAlertSnackbar } from 'libs/hooks/useAlertSnackbar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useTeamsTableUtils = () => {
  const { t } = useTranslation();
  const { props: snackbarProps, handleShowSnackbar } = useAlertSnackbar();
  const [dialogType, setDialogType] = useState<'create' | 'update'>('create');
  const [selectedTeam, setSelectedTeam] = useState<TeamV2 | undefined>();
  const [createTeamDialogOpen, setCreateTeamDialogOpen] = useState(false);

  const onCreateTeamClick = () => {
    setCreateTeamDialogOpen(true);
    setDialogType('create');
    setSelectedTeam(undefined);
  };

  const onUpdateTeamClick = (team: TeamV2) => {
    setCreateTeamDialogOpen(true);
    setDialogType('update');
    setSelectedTeam(team);
  };

  const closeCreateTeamDialog = () => setCreateTeamDialogOpen(false);

  const onCreateSuccess = (team: Team) => {
    closeCreateTeamDialog();
    handleShowSnackbar(t('teams.teamCreateSuccess', { name: team.name }), 'success');
  };

  const onUpdateSuccess = (team: Team) => {
    closeCreateTeamDialog();
    handleShowSnackbar(t('teams.teamUpdateSuccess', { name: team.name }), 'success');
  };

  const onCreateError = (_error: any, team: CreateTeamVariables) => {
    handleShowSnackbar(t('teams.teamCreateError', { name: team.name }), 'error');
  };

  const onUpdateError = (_error: any, team: TeamUpdateVariables) => {
    handleShowSnackbar(t('teams.teamUpdateError', { name: team.team.name }), 'error');
  };

  const dialogProps = {
    open: createTeamDialogOpen,
    defaultValues: {
      name: selectedTeam?.name,
      pdfTemplateId: selectedTeam?.pdfTemplateId || null,
    },
    onClose: closeCreateTeamDialog,
    onCreateSuccess,
    onUpdateSuccess,
    onCreateError,
    onUpdateError,
    team: selectedTeam,
  };

  return {
    snackbarProps,
    dialogProps,
    dialogType,
    handlers: {
      onCreateTeamClick,
      onUpdateTeamClick,
    },
  };
};
