import QuotesListContainer from 'libs/Quotes/List/QuotesListContainer';
import React, { StrictMode } from 'react';
import { AccessDenied } from 'libs/Components/Errors/AccessDenied';
import { Account } from 'libs/Account/Account';
import { DefaultLayout } from 'libs/DefaultLayout';
import { GenericError } from 'libs/Components/Errors/GenericError';
import { Header } from 'libs/Quotes/Components/Header/Header';
import { MainAppNavigation } from 'libs/Components/SideNav/MainAppNavigation';
import { Navigate, Route } from 'react-router-dom';
import { OrderDetailsPage } from 'libs/Orders/OrderDetails/OrderDetailsPage';
import { OrderList } from 'libs/Orders/OrderList/OrderList';
import { OrderPaymentPage } from 'libs/Orders/OrderPayment/OrderPaymentPage';
import { OrderPdfExportPage } from 'libs/Orders';
import { QuoteCheckoutPage } from 'libs/Quotes/Checkout';
import { QuoteDetailsPage } from 'libs/Quotes/QuoteDetailsPage';
import { QuotePdfExportPage } from 'libs/Quotes/PdfExport/QuotePdfExportPage';
import { SentryRoutes } from 'libs/Components/Errors/Sentry/SentryRoutes';
import { authenticationRoutes } from 'libs/Authentication';
import { routes } from 'libs/Constants';

export const CustomerRouter = () => {
  return (
    <StrictMode>
      <SentryRoutes>
        {authenticationRoutes}
        <Route path={routes.quotes.pdf} element={<QuotePdfExportPage />} />
        <Route path={routes.orders.pdf} element={<OrderPdfExportPage />} />
        <Route
          path={routes.home}
          element={<DefaultLayout header={<Header />} navigation={<MainAppNavigation />} />}
        >
          <Route index element={<Navigate to={routes.quotes.list} replace={true} />} />
          <Route path={routes.orders.list} element={<OrderList />} />
          <Route path={routes.orders.show} element={<OrderDetailsPage />} />
          <Route path={routes.orders.pay} element={<OrderPaymentPage />} />
          <Route path={routes.quotes.list} element={<QuotesListContainer />} />
          <Route path={routes.quotes.show} element={<QuoteDetailsPage />} />
          <Route path={routes.quotes.checkout} element={<QuoteCheckoutPage />} />
          <Route path={routes.account.show} element={<Account />} />
        </Route>
        <Route
          path={routes.home}
          element={
            <DefaultLayout header={<Header />} navigation={<MainAppNavigation />} hideSideNav />
          }
        >
          <Route path={routes.accessDenied} element={<AccessDenied to={routes.home} />} />
          <Route path={routes.genericError} element={<GenericError to={routes.home} />} />
        </Route>
      </SentryRoutes>
    </StrictMode>
  );
};
