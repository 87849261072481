import React from 'react';
import {
  Box,
  Chip,
  Monetary,
  ShowElement,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@clippings/paper';
import { ComponentGridContainer } from 'libs/shared';
import { ComponentHeader } from './ComponentHeader';
import { Payment } from 'libs/api/common/types';
import {
  useBuildPercentageLabel,
  useFormatDetails,
  useFormatType,
} from '../../Components/TotalsSummary/paymentHelpers';
import { useQuoteCheckoutContext } from '../providers';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const PaymentSchedule: React.FC = () => {
  const { paymentTermsQuery } = useQuoteProvider();
  const { payment } = useQuoteCheckoutContext();
  const { t } = useTranslation();
  const buildPercentageLabel = useBuildPercentageLabel();
  const { formatDetails } = useFormatDetails();
  const { formatType } = useFormatType();

  const buildLabel = (payment: Payment): string => {
    const type = formatType(payment);
    const details = formatDetails(payment);
    if (!details) {
      return type;
    }

    return `${type} - ${details}`;
  };

  return (
    <ComponentGridContainer padding={{ xs: 2, md: 3 }} gap={{ xs: 1, md: 3 }}>
      <ComponentHeader title={t('checkout.paymentSchedule.title')} />

      <Table
        sx={{
          '& .MuiTableRow-root:last-child': {
            '.MuiTableCell-root': {
              borderBottom: 'none',
            },
          },
        }}
      >
        <TableBody>
          {paymentTermsQuery?.data?.map((payment: Payment, index: number) => {
            const paymentChip = (
              <Chip
                data-testid={`payment-chip-${payment.id}`}
                label={buildLabel(payment)}
                variant="outlined"
                size="small"
                color={index === 0 ? 'success' : 'default'}
              />
            );

            return (
              <TableRow key={payment.id}>
                <TableCell align="left" width="15%" sx={{ paddingLeft: 0 }}>
                  <Typography
                    data-testid={`payment-percentage-label-${payment.id}`}
                    variant="body2"
                  >
                    {buildPercentageLabel(payment, index + 1)}{' '}
                  </Typography>
                  <ShowElement below="md">
                    <Box mt={1}>{paymentChip}</Box>
                  </ShowElement>
                </TableCell>
                <ShowElement above="md">
                  <TableCell align="left" size="small">
                    {paymentChip}
                  </TableCell>
                </ShowElement>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Monetary
                    amount={payment.amount.amount}
                    currency={payment.amount.currency}
                    data-testid={`payment-amount-${payment.id}`}
                    fontWeight={600}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {payment && (
            <TableRow data-testid={`payment-schedule-payment-row-${payment.id}-${payment.status}`}>
              <TableCell colSpan={3} align="right" sx={{ paddingRight: 0 }}>
                <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                  <Typography
                    data-testid={`payment-due-label-${payment.id}`}
                    variant="body2"
                    fontWeight={600}
                  >
                    {t('common.paymentDue')}
                  </Typography>
                  &nbsp;
                  <Typography
                    data-testid={`payment-due-date-label-${payment.id}`}
                    variant="body2"
                    fontWeight={600}
                    marginRight={4}
                    color={payment.isImmediate ? 'success.main' : 'primary.main'}
                  >
                    {formatDetails(payment, 'common.today')}
                  </Typography>
                  <Monetary
                    amount={payment.amount.amount}
                    currency={payment.amount.currency}
                    data-testid={`payment-due-amount-label-${payment.id}`}
                    fontWeight={600}
                  />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ComponentGridContainer>
  );
};
