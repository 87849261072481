import axios from 'axios';
import { mapApiToFormErrors } from '@clippings/paper';
import { useMemo } from 'react';

export const getServerFormErrors = (error: unknown) => {
  return axios.isAxiosError(error) && error.response ? mapApiToFormErrors(error.response) : {};
};

export const useServerFormErrors = (error: unknown) => {
  const errors = useMemo(() => getServerFormErrors(error), [error]);
  return errors;
};
