import React from 'react';
import { CatalogsProvider } from 'libs/catalogs';
import { ChatChannelProvider } from 'libs/Chat';
import { QuoteProvider } from 'libs/Quotes/providers';
import { QuoteShareProvider } from './Components/ShareModal/QuoteShareProvider';
import { QuoteView } from './quoteView/QuoteView';
import { QuoteViewContent } from './quoteView/QuoteViewContent';

export const QuoteDetailsPage = () => {
  return (
    <CatalogsProvider>
      <QuoteProvider>
        {(number, quote) => (
          <QuoteShareProvider quote={quote}>
            <ChatChannelProvider number={number}>
              <QuoteView>
                <QuoteViewContent />
              </QuoteView>
            </ChatChannelProvider>
          </QuoteShareProvider>
        )}
      </QuoteProvider>
    </CatalogsProvider>
  );
};
