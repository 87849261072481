import React from 'react';
import {
  AddIcon,
  Avatar,
  Box,
  Button,
  Chip,
  Monetary,
  TuneIcon,
  Typography,
  convertTimeRange,
  useFormatLeadTime,
} from '@clippings/paper';
import { ProductHit, ProductType } from 'libs/Quotes/quote.types';
import { getProductListingImageUrl } from '../../../common/utils';
import { useAppConfiguration } from 'libs/providers';
import { useTranslatedProduct } from '../../../hooks';
import { useTranslation } from 'react-i18next';

export interface ProductCardProps {
  product: ProductHit;
  catalogueSlug: string;
  onAddProduct?: (product: ProductHit) => void;
  onConfigureProduct?: (product: ProductHit) => void;
  renderLeadTime: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  catalogueSlug,
  onAddProduct,
  onConfigureProduct,
  renderLeadTime,
}) => {
  const { t } = useTranslation();
  const { leadTimeFormat } = useAppConfiguration();
  const formatLeadTime = useFormatLeadTime();

  const handleAddToQuoteClick = () => {
    if (onAddProduct) {
      onAddProduct(product);
    }
  };

  const handleConfigureClick = () => {
    if (onConfigureProduct) {
      onConfigureProduct(product);
    }
  };

  const getLeadTimeLabel = () => {
    if (product.leadTime && product.leadTime[catalogueSlug]) {
      const { min, max } = product.leadTime[catalogueSlug];

      return formatLeadTime(convertTimeRange([min, max], 'days', leadTimeFormat), leadTimeFormat);
    }

    return null;
  };

  const inStock = product.inStock && product.inStock[catalogueSlug];
  const leadTimeLabel = getLeadTimeLabel();
  const { translateProductName } = useTranslatedProduct();
  const productName = translateProductName(product);

  return (
    <Box
      sx={{
        '&:hover': {
          '& .product-card-actions': {
            visibility: 'visible',
          },
        },
      }}
      mr={2}
      mb={2}
      display="flex"
      flexDirection="column"
    >
      <Box
        sx={{
          isolation: 'isolate',
        }}
        width="100%"
        display="grid"
      >
        <Box gridRow={1} gridColumn={1} bgcolor="grey.A100" display="flex" alignItems="center">
          <Avatar
            sx={{
              width: '100%',
              height: 'unset',
              mixBlendMode: 'multiply',
            }}
            variant="square"
            src={getProductListingImageUrl(product.coverRemoteId, product.coverVersion)}
            alt={productName}
          >
            <Box />
          </Avatar>
        </Box>
        <Box gridRow={1} gridColumn={1} p={2} display="flex" justifyContent="flex-end">
          {(inStock || (renderLeadTime && leadTimeLabel)) && (
            <Chip
              sx={{
                isolation: 'isolate',
                backgroundColor: theme => theme.palette.common.white,
                borderColor: theme => theme.palette.grey.A200,
                '& .MuiChip-label': {
                  fontWeight: theme => theme.typography.fontWeightMedium,
                  fontSize: theme => theme.typography.pxToRem(12),
                  color: theme =>
                    inStock ? theme.palette.success.main : theme.palette.text.secondary,
                },
              }}
              size="small"
              variant="outlined"
              label={inStock ? t('common.variantsInStock') : leadTimeLabel}
            />
          )}
        </Box>
        <Box
          gridRow={1}
          gridColumn={1}
          p={2}
          display="flex"
          alignItems="flex-end"
          visibility="hidden"
          className="product-card-actions"
        >
          <Box
            sx={{
              isolation: 'isolate',
              backgroundColor: theme => theme.palette.common.white,
            }}
            width="100%"
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            border={theme => `1px solid ${theme.palette.grey.A200}`}
            borderRadius="4px"
          >
            {product.type === ProductType.Product && (
              <Button
                sx={{
                  minWidth: '50%',
                }}
                startIcon={<TuneIcon />}
                color="secondary"
                onClick={handleConfigureClick}
                data-testid={`configure-${product.objectID}`}
              >
                {t('common.configure')}
              </Button>
            )}
            <Button
              sx={{
                minWidth: '50%',
              }}
              startIcon={<AddIcon />}
              onClick={handleAddToQuoteClick}
              data-testid={`add-${product.objectID}`}
            >
              {t('common.addToQuote')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box width="100%" pt={1}>
        <Typography
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          variant="subtitle2"
          title={productName}
        >
          {productName}
        </Typography>
        <Typography
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          variant="body2"
          color="secondary"
          title={product.vendorSkus?.[catalogueSlug] ?? product?.skus[catalogueSlug]}
        >
          {product.vendorSkus?.[catalogueSlug] ?? product?.skus[catalogueSlug]}
        </Typography>
        {product.prices && product.prices[catalogueSlug] && (
          <Monetary
            amount={product.prices[catalogueSlug].amount}
            currency={product.prices[catalogueSlug].currency}
            variant="subtitle2"
          />
        )}
      </Box>
    </Box>
  );
};
