export const routes = {
  home: '/',
  login: '/login',
  logout: '/logout',
  register: '/register',
  authentication: {
    login: '/login',
    resetPassword: '/reset-password',
    register: '/register',
    newPassword: '/new-password',
  },
  quotes: {
    list: '/quotes',
    show: '/quotes/:number',
    pdf: '/quotes/:number/:id/pdf',
    checkout: '/quotes/:number/checkout/:id',
  },
  orders: {
    list: '/orders',
    show: '/orders/:number',
    pay: '/orders/:number/pay/:id',
    pdf: '/orders/:number/pdf',
  },
  accessDenied: '/access-denied',
  genericError: '/something-went-wrong',
  account: { show: '/account' },
  guestApp: {
    index: '/guest',
    auth: {
      register: '/guest/register',
    },
    quotes: {
      show: '/guest/quotes/:number',
      checkout: '/guest/quotes/:number/checkout/:id',
      pdf: '/guest/quotes/:number/:id/pdf',
    },
    orders: {
      show: '/guest/orders/:number',
      pay: '/guest/orders/:number/pay/:id',
      pdf: '/guest/orders/:number/pdf',
    },
  },
  salesApp: {
    // don't forget to add your searchable page name in
    // web/jsx/SalesApp/Header/GlobalSearch.tsx
    index: '/sales',
    messages: '/messages',
    inbox: '/sales/inbox',
    quotes: {
      active: '/sales/quotes/active',
      expired: '/sales/quotes/expired',
      archived: '/sales/quotes/archived',
      show: '/sales/quotes/:number',
      checkout: '/sales/quotes/:number/checkout/:id',
    },
    orders: {
      list: '/sales/orders',
      show: '/sales/orders/:number',
      pay: '/sales/orders/:number/pay/:id',
    },
    users: '/sales/users',
    userDetails: '/sales/users/:userId',
    staffUsers: '/sales/staffUsers',
    companies: '/sales/companies',
    teams: '/sales/teams',
    company: '/sales/companies/:companyId',
  },
  product: '/products/:slug',
  search: '/search',
} as const;
