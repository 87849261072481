import React from 'react';
import {
  Box,
  ChipButton,
  Grid,
  GridProps,
  ReceiptLongIcon,
  Skeleton,
  useIsMobile,
} from '@clippings/paper';
import { ToggleQuoteProductDetailsButton } from 'libs/SalesApp/Quotes/components/ToggleQuoteProductDetailsButton';
import { useQuoteProvider } from './providers';
import { useTranslation } from 'react-i18next';

export const menuStyle = {
  '& .MuiTypography-caption': {
    lineHeight: 1,
  },
};

export const QuoteActionsStackContainer: React.FC<GridProps> = ({ children }) => (
  <Grid container gap={1} flexDirection="row" justifyContent="space-between">
    {children}
  </Grid>
);

export const QuoteActionsStack = () => {
  const { loading, handleBillingModalOpen, quote, addressSubmitLoading, isReadonly } =
    useQuoteProvider();
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  if (loading) {
    return (
      <Box display="flex" gap={1} data-testid="quote-action-stack-loader">
        <Skeleton sx={styles.skeletonSx} variant="rectangular" />
      </Box>
    );
  }

  const disabled = isReadonly || addressSubmitLoading;

  return (
    <Box display="flex" justifyContent="space-between">
      <ChipButton
        adornment={<ReceiptLongIcon key="billing-address" color="secondary" />}
        placeholder={t('quotes.addresses.addBillingDetails')}
        value={quote.billingAddress?.companyName}
        label={t('quotes.addresses.billTo')}
        hasArrow={!!quote.billingAddress?.companyName}
        disabled={disabled}
        onClick={() => handleBillingModalOpen()}
        data-testid="change-billing-address"
        sx={styles.chipButtonSx}
      />
      {!isMobile && <ToggleQuoteProductDetailsButton />}
    </Box>
  );
};

export const styles = {
  chipButtonSx: {
    width: {
      xs: '100%',
      md: 'fit-content',
    },
    justifyContent: {
      xs: 'space-between',
      md: 'center',
    },
  },
  skeletonSx: {
    width: {
      xs: '100%',
      md: '140px',
    },
    height: '32px',
    borderRadius: 4,
  },
};
