import { ChangeEvent } from 'react';
import { useBanner } from '../../Components/AlertBanner';
import { useMutation } from '@tanstack/react-query';
import { useQuoteError } from 'libs/Quotes/hooks';

type Props = {
  action: ({ file }: { file: File }) => any;
  loadingMessage?: string;
  uploadErrorCallback?: () => void;
};

export const useXmlImport = ({ action, loadingMessage, uploadErrorCallback }: Props) => {
  const { showErrorBanner, showSuccessBanner } = useBanner();
  const { showError } = useQuoteError(showErrorBanner);

  const onFileUploadHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file: File = event.target.files[0];

      if (file.type !== 'text/xml') {
        return;
      }

      !!loadingMessage && showSuccessBanner(loadingMessage);
      event.target.value = '';

      return action({ file });
    }
  };

  return useMutation(onFileUploadHandler, {
    onSuccess: response => {
      if (!response) {
        return;
      }
    },
    onError: err => {
      showError(err);
      uploadErrorCallback?.();
    },
  });
};
