import { ACTION_AFTER_SUBMIT } from '../../hooks/quoteReducer';
import { AppType, getCurrentApp } from 'libs/Utils';
import { QuoteTransitionError, QuoteTransitionErrorsEnum } from 'libs/SalesApp/Quotes/types';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'libs/Constants';
import { useQuoteProvider } from 'libs/Quotes/providers';

export function useQuoteCheckoutHandler(
  openShippingModal: (isDetailed: boolean, actionAfterSubmit: ACTION_AFTER_SUBMIT) => void,
  openBillingModal: (actionAfterSubmit: ACTION_AFTER_SUBMIT) => void
) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { quote } = useQuoteProvider();

  const navigateCheckout = () =>
    navigate(
      generatePath(getCheckoutRoute(pathname), {
        number: quote.number,
        id: quote.id?.toString(),
      })
    );

  const quoteCheckoutHandler = (errors: QuoteTransitionError[] = []) => {
    if (errors.some(err => err.error === QuoteTransitionErrorsEnum.MissingBillingAddress)) {
      openBillingModal(ACTION_AFTER_SUBMIT.CHECKOUT);
      return;
    }

    if (errors.some(err => err.error === QuoteTransitionErrorsEnum.MissingShippingAddress)) {
      openShippingModal(false, ACTION_AFTER_SUBMIT.CHECKOUT);
      return;
    }

    if (errors.some(err => err.error === QuoteTransitionErrorsEnum.InvalidShippingAddress)) {
      openShippingModal(true, ACTION_AFTER_SUBMIT.CHECKOUT);
      return;
    }

    navigateCheckout();
  };

  return { quoteCheckoutHandler };
}

//////
const checkoutRoutesByApp: Record<AppType, string> = {
  customer: routes.quotes.checkout,
  sales: routes.salesApp.quotes.checkout,
  guest: routes.guestApp.quotes.checkout,
};

function getCheckoutRoute(pathname: string): string {
  const currentApp = getCurrentApp(pathname);

  return checkoutRoutesByApp[currentApp];
}
