import React, { FC, PropsWithChildren, createContext, useContext } from 'react';
import { AppConfiguration } from 'libs/api/configuration/types';
import { isEmpty } from 'lodash';

export const AppConfigurationContext = createContext<AppConfiguration>({} as AppConfiguration);

type AppConfigurationProviderProps = PropsWithChildren<{ configuration: AppConfiguration }>;

export const AppConfigurationProvider: FC<AppConfigurationProviderProps> = ({
  configuration,
  children,
}) => {
  return (
    <AppConfigurationContext.Provider value={configuration}>
      {children}
    </AppConfigurationContext.Provider>
  );
};

export const useAppConfiguration = () => {
  const value = useContext(AppConfigurationContext);

  if (isEmpty(value)) {
    throw new Error('useAppConfiguration must be used within a AppConfigurationProvider');
  }

  return value;
};
