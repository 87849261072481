import React from 'react';
import { Avatar, Box, Button, CheckIcon, Grid, Typography } from '@clippings/paper';
import { Link as RouterLink } from 'react-router-dom';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import { useTranslation } from 'react-i18next';

interface CardPaymentSuccessProps {
  orderNumber: string;
}

export const CardPaymentSuccess: React.FC<CardPaymentSuccessProps> = ({ orderNumber }) => {
  const { t } = useTranslation();
  const { getOrderShowPath } = useOrderPaths();
  const returnUrl = useReturnUrl();

  return (
    <>
      <Box mb={3}>
        <Avatar sx={{ bgcolor: 'success.main' }}>
          <CheckIcon />
        </Avatar>
      </Box>
      <Typography variant="h5" fontWeight="normal">
        {t('common.paymentReceived')}
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={6}>
        {t('checkout.success.email')}
      </Typography>
      <Grid display="flex" gap={1}>
        <Button
          component={RouterLink}
          to={getOrderShowPath(orderNumber)}
          variant="contained"
          data-testid="view-order-link"
        >
          {t('common.viewOrder')}
        </Button>
        <Button href={returnUrl} variant="outlined" data-testid="continue-shopping-link">
          {t('common.continueShopping')}
        </Button>
      </Grid>
    </>
  );
};
