import { Address, AddressType } from 'libs/api/common/types';
import { AddressTypesEnum } from 'libs/Quotes/hooks/useAddresses';
import { Location } from 'libs/api/location/types';
import { createContext, useContext } from 'react';

export type AddressWithType = Address & { type: AddressTypesEnum };

export type AddressContextState = {
  handleOpenShippingType?: (stepsAction?: 'push' | 'pop' | 'noop') => void;
  handleOpenCreateNewAddress: (stepsAction?: 'push' | 'pop' | 'noop') => void;
  handleOpenEditAddress: (address: AddressWithType) => void;
  handleOpenSameAsBilling?: (stepsAction?: 'push' | 'pop' | 'noop') => void;
  handleBack: () => void;
  selectedAddress: AddressWithType;
  addressType: AddressType;
  handleOpenDeleteModal: (address: AddressWithType) => void;
  handleCloseDeleteModal: () => void;
  isDeleteModalOpen: boolean;
  onDelete: (data?: any) => void;
  handleMakeDefault: (address: Address) => void;
  hasBack: boolean;
  countries: Location[];
  states: Location[];
  isLoading: boolean;
};

export const AddressContext = createContext<AddressContextState>({} as AddressContextState);

export function useAddressContext() {
  const context = useContext(AddressContext);

  if (!context) {
    throw new Error('useAddressContext should be used within an AddressContext provider');
  }

  return context;
}
