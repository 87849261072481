import React, { ComponentProps, FC } from 'react';
import { Box, Chip, ThemMui } from '@clippings/paper';

type ChipProps = ComponentProps<typeof Chip>;

interface StatusCircleProps extends ComponentProps<typeof Box> {
  chipProps?: ChipProps;
}

const getStatusColor = (theme: ThemMui, props?: ChipProps) => {
  if (!props || props.color === 'default' || !theme.palette[props.color!].main) {
    return theme.palette.grey[300];
  }

  if ('sx' in props) {
    return (props.sx as any).bgcolor;
  }

  return theme.palette[props.color!].main;
};

export const StatusCircle: FC<StatusCircleProps> = ({ chipProps, ...rest }) => {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: 50,
        backgroundColor: (theme: ThemMui) => getStatusColor(theme, chipProps),
      }}
      {...rest}
    />
  );
};
