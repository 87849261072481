import React from 'react';
import { CloseableDialog, DialogProps } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { CompanyCreateForm, CompanyCreateFormProps } from './CompanyCreateForm';
import { DialogTitle } from 'libs/Components/Modal/DialogTitle/DialogTitle';
import { STATUS_ACTIVE } from 'libs/SalesApp/Companies/components/CompanyFormFields';
import { useTranslation } from 'react-i18next';

export interface CreateCompanyDialogProps extends Omit<CompanyCreateFormProps, 'onCancel'> {
  open: boolean;
  onClose: () => void;
  onSuccess: (company: Company) => void;
}

export const CompanyCreateDialog: React.FC<CreateCompanyDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const handleSuccess = (company: Company) => {
    onSuccess(company);
    onClose();
  };

  const onDialogClose: DialogProps['onClose'] = (_e, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <CloseableDialog
      open={open}
      // TODO: Fix props in paper
      onClose={onDialogClose as any}
      title={
        <DialogTitle
          data-testid="create-company-dialog-title"
          title={t('forms.createNewCompany')}
        />
      }
      dialogProps={dialogProps}
    >
      <CompanyCreateForm
        onSuccess={handleSuccess}
        onCancel={onClose}
        defaultValues={{ status: STATUS_ACTIVE }}
      />
    </CloseableDialog>
  );
};

const dialogProps = {
  PaperProps: { sx: { width: 560 } },
};
