import { User, UserPermission } from '../api/iam/types';
import { routes } from '../Constants';

export const userCanAccessSalesApp = (user: User) =>
  user.permissions.includes(UserPermission.CanAccessSalesApp);

export const getAfterLoginRedirectUrl = (user: User, redirectUrl?: string) => {
  const isStaffUser = userCanAccessSalesApp(user);

  if (isStaffUser) {
    return redirectUrl ?? routes.salesApp.index;
  } else {
    return !redirectUrl || redirectUrl.includes('/sales/') ? routes.quotes.list : redirectUrl;
  }
};
