import { toNumber } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export function useRegistrationPromptModal() {
  const { number } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(!!toNumber(searchParams.get('withPrompt')));

  function continueAsGuestClickHandler() {
    setIsModalOpen(false);
    setSearchParams({});
  }

  return { quoteNumber: number, isModalOpen, continueAsGuestClickHandler };
}
