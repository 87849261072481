import React, { ComponentProps } from 'react';
import { Category } from 'libs/api/catalogue/types';
import { CloseableDialog } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { CompanyDiscount } from 'libs/api/common/types';
import { DiscountDialogContent, DiscountDialogContentProps } from './DiscountsDialogContent';
import { useTranslation } from 'react-i18next';

export interface DiscountsDialogProps
  extends Omit<DiscountDialogContentProps, 'onSuccess' | 'onCancel'> {
  company: Company;
  discounts: CompanyDiscount[];
  categories: Category[];
  open: boolean;
  onClose: () => void;
}

export const DiscountsDialog: React.FC<DiscountsDialogProps> = ({
  company,
  discounts,
  categories,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <CloseableDialog
      title={t('common.discounts')}
      open={open}
      dialogProps={dialogProps}
      onClose={onClose}
    >
      <DiscountDialogContent
        categories={categories}
        discounts={discounts}
        company={company}
        onSuccess={onClose}
        onCancel={onClose}
      />
    </CloseableDialog>
  );
};

const dialogProps: ComponentProps<typeof CloseableDialog>['dialogProps'] = {
  maxWidth: 'lg',
  fullWidth: true,
  keepMounted: false,
};
