import React, { FC } from 'react';
import { Box, Button } from '@clippings/paper';
import { ListScreenTitle } from 'libs/Quotes/Components/ShareModal/components/ListScreenTitle';
import { QuoteShareModalScreenProps } from '../useQuoteShareUtils';
import { ShareeSelect } from '../components/ShareeSelect';
import { ShareesList } from 'libs/Quotes/Components/ShareModal/components/ShareesList';
import { useQuoteShare } from '../QuoteShareProvider';
import { useTranslation } from 'react-i18next';

export const ListScreen: FC<QuoteShareModalScreenProps> = ({ quote }) => {
  const { t } = useTranslation();
  const {
    modal: { doneHandler },
  } = useQuoteShare();

  return (
    <>
      <ListScreenTitle />
      <ShareeSelect />
      <ShareesList quote={quote} />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          data-testid="list-screen-done"
          variant="contained"
          color="primary"
          onClick={doneHandler}
        >
          {t('common.done')}
        </Button>
      </Box>
    </>
  );
};
