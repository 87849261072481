import React, { ComponentProps, FC } from 'react';
import { Quote } from 'libs/api/quotes/types';
import { TaxRow } from './TaxRow';
import { TotalsSummaryRow } from './TotalsSummaryRow';
import { TotalsSummaryRowLabel } from './TotalsSummaryRowLabel';
import { TotalsSummaryRowValue } from './TotalsSummaryRowValue';

interface TotalsSummaryTaxRowProps extends ComponentProps<typeof TotalsSummaryRow> {
  tax: Quote['tax'];
}

export const TotalsSummaryTaxRow: FC<TotalsSummaryTaxRowProps> = ({ tax, ...rest }) => {
  return (
    <TotalsSummaryRow {...rest}>
      <TaxRow tax={tax}>
        {(getLabel, getTaxValue) => (
          <>
            <TotalsSummaryRowLabel>{getLabel()}</TotalsSummaryRowLabel>
            <TotalsSummaryRowValue>{getTaxValue()}</TotalsSummaryRowValue>
          </>
        )}
      </TaxRow>
    </TotalsSummaryRow>
  );
};
