import React, { ComponentProps, useEffect } from 'react';
import { Box, Tooltip } from '@clippings/paper';

export interface KeyboardTooltipProps extends ComponentProps<typeof Tooltip> {
  /** The title, or the hint for the key pressed. */
  title: string;
  /** This is user-friendly description of your key, that is displayed to the user */
  keyLabel: string;
  /**
   * This is a custom keyboard handler that would be triggered on keypress.
   */
  handler: (event: KeyboardEvent) => void;
  children: React.ReactElement;
}

export const KeyboardTooltip: React.FC<KeyboardTooltipProps> = ({
  title,
  keyLabel,
  handler,
  children,
  ...rest
}) => {
  const keyboardWrapper = (event: KeyboardEvent) => {
    const tagName = (event.target as HTMLElement).tagName;
    // prevent triggering the handler from input field or text area
    if (tagName !== 'INPUT' && tagName !== 'TEXTAREA') {
      handler(event);
    }
  };

  // install/uninstall the global key handler
  useEffect(() => {
    window.addEventListener('keydown', keyboardWrapper);

    return () => {
      window.removeEventListener('keydown', keyboardWrapper);
    };
  });

  return (
    <Tooltip
      {...rest}
      title={
        <Box display="flex" gap={1} alignItems="center">
          {title}
          <Box bgcolor="secondary.light" p="2px 10px" borderRadius={1}>
            {keyLabel}
          </Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};
