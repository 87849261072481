import React from 'react';
import { ChatDrawer } from './components/ChatDrawer';
import { useChatVisibility } from './providers';

export function Chat() {
  const { isOpen, onClose, elementRef } = useChatVisibility();

  return (
    <ChatDrawer
      ref={elementRef}
      onClose={onClose}
      isOpen={isOpen}
      className="quote-view__chat-drawer"
    />
  );
}
