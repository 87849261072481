import React, { FC } from 'react';
import { AutocompleteRenderGetTagProps, Chip, Tooltip, UserAvatar } from '@clippings/paper';
import { ShareeSelectOption } from '../useQuoteShareUtils';
import { getShareeAvatarProps } from './ShareeDetailedUserAvatar';

export type ShareeSelectTagChipProps = {
  getTagProps: AutocompleteRenderGetTagProps;
  tag: ShareeSelectOption;
  errorMessage?: string;
  index: number;
};
export const ShareeSelectTagChip: FC<ShareeSelectTagChipProps> = ({
  tag,
  getTagProps,
  errorMessage,
  index,
}) => {
  const hasError = !!errorMessage;

  return (
    <Tooltip
      title={errorMessage}
      placement="top"
      data-testid={`tooltip-${tag.id}`}
      disableHoverListener={!hasError}
    >
      <Chip
        icon={<UserAvatar small user={getShareeAvatarProps(tag).user} />}
        variant="outlined"
        label={tag.email}
        data-testid={`sharee-select-tag-chip-${tag.id}`}
        {...getTagProps({ index })}
        key={`${tag.id}-${tag.email}`}
        sx={{
          borderColor: hasError ? 'error.main' : undefined,
        }}
      />
    </Tooltip>
  );
};
