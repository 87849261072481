import React from 'react';
import { ActionsPanel, DeleteIcon, IconButton } from '@clippings/paper';

export interface RowActionsPanelProps {
  anchorElement: HTMLElement | null;
  onDelete: () => void;
}

export const RowActionsPanel: React.FC<RowActionsPanelProps> = ({ anchorElement, onDelete }) => (
  <ActionsPanel anchorElement={anchorElement}>
    <IconButton
      size="small"
      onClick={onDelete}
      //The idea behind this, is to prevent the blur event on the table cell inputs. This way we don't edit deleted items
      onMouseDown={e => e.preventDefault()}
      data-testid="delete-row-button"
    >
      <DeleteIcon fontSize="small" color="secondary" />
    </IconButton>
  </ActionsPanel>
);
