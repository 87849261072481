import React, { ComponentProps, PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box, ChevronRightIcon, Drawer, IconButton, List } from '@clippings/paper';
import { KeyboardTooltip } from 'libs/Components/KeyboardTooltip';
import { matchRoutes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SideNav.scss';

export interface SideNavProps extends ComponentProps<typeof Drawer> {
  openPerRouteConfig: Record<string, boolean>;
}

export const SideNav: React.FC<PropsWithChildren<SideNavProps>> = ({
  children,
  className,
  openPerRouteConfig,
  ...rest
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const getShouldBeOpenBasedOnRoute = (): boolean => {
    for (const [path, openSidebar] of Object.entries(openPerRouteConfig)) {
      if (matchRoutes([{ path }], location)) {
        return openSidebar;
      }
    }

    return true;
  };
  const openBasedOnRoute = getShouldBeOpenBasedOnRoute();

  const [hasPreferenceForClosed, setHasPreferenceForClosed] = useState(false);
  const [open, setOpen] = useState(openBasedOnRoute);

  useEffect(() => {
    if (!open && hasPreferenceForClosed) return;
    setOpen(openBasedOnRoute);
  }, [location]);

  const handleToggle = () => {
    // Having a preference for closed sidebar = being on a page that has open sidebar by default and closing it
    setHasPreferenceForClosed(openBasedOnRoute === true && open);
    setOpen(!open);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'BracketLeft') {
      handleToggle();
    }
  };

  return (
    <Box className={classNames('side-nav', className, { open })}>
      <KeyboardTooltip
        placement="right"
        title={open ? t('common.collapse') : t('common.expand')}
        keyLabel="["
        handler={handleKeyDown}
      >
        <IconButton
          data-testid="navigation-toggle"
          aria-label="toggle-navigation"
          className="side-nav__toggle"
          onClick={handleToggle}
          color="primary"
          size="small"
        >
          <ChevronRightIcon data-testid="toggle" className="side-nav__toggle-icon" />
        </IconButton>
      </KeyboardTooltip>
      <Drawer
        className="side-nav__drawer-container"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          className: 'side-nav__drawer',
        }}
        variant="permanent"
        open={open}
        {...rest}
      >
        <List className="side-nav__drawer-content" data-testid="sidenav-content">
          {children}
        </List>
      </Drawer>
    </Box>
  );
};
