import React, { ComponentProps, MouseEvent, useState } from 'react';
import { ArchiveQuoteButton } from 'libs/Quotes/Components/ArchiveQuoteButton';
import {
  CopyAllIcon,
  ListItemIcon,
  MenuItem,
  MoreActionsButton as MoreActions,
} from '@clippings/paper';
import { QUOTE_STATUSES } from 'libs/Constants';
import { Quote, QuoteListItem } from 'libs/api/quotes/types';
import { QuoteXmlImportMenuItem } from 'libs/Quotes/xmlImport';
import { UnarchiveQuoteButton } from 'libs/Quotes/Components/UnarchiveQuoteButton';
import { preventDefaultPropagation } from 'libs/shared';
import { useQuoteUserActions } from '../hooks';
import { useTranslation } from 'react-i18next';

type MoreActionsButtonProps = Omit<
  ComponentProps<typeof MoreActions>,
  'isOpen' | 'onToggle' | 'children'
> & {
  quote: QuoteListItem;
  disabled: boolean;
  onArchive: (quote: QuoteListItem) => void;
  onUnarchive: (quote: QuoteListItem) => void;
  onDuplicate: (quote: QuoteListItem) => void;
  withXmlImport?: boolean;
};

const statuses = [
  QUOTE_STATUSES.DRAFT,
  QUOTE_STATUSES.APPROVED,
  QUOTE_STATUSES.READY,
  QUOTE_STATUSES.REQUESTED,
] as const;

export const MoreActionsButton: React.FC<MoreActionsButtonProps> = ({
  quote,
  disabled,
  onArchive,
  onUnarchive,
  onDuplicate,
  withXmlImport = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { hasQuotePermission } = useQuoteUserActions(quote.userActions);
  const canDuplicateQuote = hasQuotePermission('CAN_DUPLICATE_QUOTE');

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    preventDefaultPropagation<HTMLDivElement>(event);
  };

  const handleClose = (event: MouseEvent<HTMLLIElement>) => {
    preventDefaultPropagation<HTMLLIElement>(event);
    setIsOpen(false);
  };

  function eventHandler(handler: (quote: QuoteListItem) => void) {
    return (event: MouseEvent<HTMLLIElement>) => {
      handler(quote);
      handleClose(event);
    };
  }

  return (
    <MoreActions
      onClick={handleClick}
      /**
       * We want control over 'onClick' handler.
       * There are cases when we want to let the event bubble up
       * and a custom onClick handler could be provided through props.
       */
      {...rest}
      onToggle={setIsOpen}
      disabled={disabled}
      isOpen={isOpen}
    >
      {canDuplicateQuote ? (
        <MenuItem
          onClick={eventHandler(onDuplicate)}
          key="menu-item-duplicate"
          data-testid="duplicate-quote"
        >
          <ListItemIcon key="list-copy-icon">
            <CopyAllIcon key="copy-icon" />
          </ListItemIcon>
          {t('quotes.duplicateQuote')}
        </MenuItem>
      ) : null}
      {quote.status === QUOTE_STATUSES.ARCHIVED && (
        <UnarchiveQuoteButton quote={quote as Quote} onClick={eventHandler(onUnarchive)} />
      )}
      {statuses.includes(quote.status) && (
        <ArchiveQuoteButton quote={quote as Quote} onClick={eventHandler(onArchive)} />
      )}
      {withXmlImport ? (
        <QuoteXmlImportMenuItem disabled={disabled} closeMenu={() => setIsOpen(false)} />
      ) : null}
    </MoreActions>
  );
};
