import React from 'react';
import { Configure, Index, useHits, useInstantSearch } from 'react-instantsearch-hooks-web';
import { ConfiguredProduct, CustomItemHit } from '../common/types';
import { Hit } from 'react-instantsearch-core';
import { HitsList } from './HitsList';
import { ProductHit } from 'libs/Quotes/quote.types';
import { Quote } from 'libs/api/quotes/types';
import { useIsInSalesApp } from 'libs/shared';
import { useSearchProvider } from 'libs/providers';

const MAX_RESULTS = 4;

export type ProductsAutocompletePopoverContentProps = {
  onClose: () => void;
  quote: Quote;
  onAddItem: (data: ProductHit) => void;
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  onAddCustomItem: (value: string) => void;
  catalogue?: string;
};

export const ProductsAutocompletePopoverContent: React.FC<
  ProductsAutocompletePopoverContentProps
> = ({ onClose, quote, onConfigure, onAddItem, onAddCustomItem, catalogue }) => {
  const { productIndexes } = useSearchProvider();

  return (
    <Index
      key={`algolia-index-${productIndexes.mostRelevant}`}
      indexName={productIndexes.mostRelevant!}
    >
      <Configure hitsPerPage={MAX_RESULTS} filters={`catalogues:${catalogue}`} />
      <InnerContent
        onAddItem={onAddItem}
        onClose={onClose}
        onConfigure={onConfigure}
        onAddCustomItem={onAddCustomItem}
        quote={quote}
        catalogue={catalogue}
      />
    </Index>
  );
};

const InnerContent: React.FC<ProductsAutocompletePopoverContentProps> = ({
  onClose,
  quote,
  onConfigure,
  onAddItem,
  onAddCustomItem,
  catalogue,
}) => {
  const { hits } = useHits<Hit<ProductHit | CustomItemHit>>();
  const { status } = useInstantSearch();
  const isSalesApp = useIsInSalesApp();
  const isLoading = status === 'loading';

  return (
    <>
      <HitsList
        onAddItem={onAddItem}
        onClose={onClose}
        onConfigure={onConfigure}
        onAddCustomItem={onAddCustomItem}
        quote={quote}
      />
      {!isLoading && isSalesApp && (
        <CustomIndexContent
          onAddItem={onAddItem}
          onClose={onClose}
          onConfigure={onConfigure}
          onAddCustomItem={onAddCustomItem}
          quote={quote}
          hitsCount={MAX_RESULTS - hits.length}
          catalogue={catalogue}
        />
      )}
    </>
  );
};

const CustomIndexContent: React.FC<
  ProductsAutocompletePopoverContentProps & { hitsCount: number }
> = ({ onClose, quote, onConfigure, onAddItem, onAddCustomItem, catalogue, hitsCount }) => {
  const { customItemsIndex } = useSearchProvider();
  if (!customItemsIndex) {
    return null;
  }

  return (
    <Index key={`algolia-index-${customItemsIndex}`} indexName={customItemsIndex!}>
      <Configure hitsPerPage={hitsCount} filters={`catalogues:${catalogue}`} />
      <HitsList
        key={`hits-list-${customItemsIndex}`}
        onAddItem={onAddItem}
        onClose={onClose}
        onConfigure={onConfigure}
        onAddCustomItem={onAddCustomItem}
        quote={quote}
      />
    </Index>
  );
};
