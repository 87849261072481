import React, { useMemo } from 'react';
import { CellSelectInput, MenuItem, TableCell, Typography } from '@clippings/paper';
import { CustomPaymentRule, DatePaymentRule, EventPaymentRule } from 'libs/api/common/types';
import { PaymentRuleRowCommonProps, PaymentRulesTypes } from '../../paymentTerms.types';
import { getTomorrow } from '../../utils';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

type RuleTypeCellProps = { types: PaymentRulesTypes } & PaymentRuleRowCommonProps;

export function PaymentRuleTypeCellRenderer({
  paymentRule,
  types,
  onChange,
  index,
}: RuleTypeCellProps) {
  const { t } = useTranslation();
  const { paymentTermsEvents } = useAppConfiguration();

  const selectedType = useMemo(() => {
    switch (paymentRule.type) {
      case 'event':
        return paymentRule.dueEvent;
      case 'custom':
      case 'date':
        return paymentRule.type;
      default:
        return '';
    }
  }, [paymentRule]);

  const handleTypeChange = (value: string) => {
    const { id, percentage } = paymentRule;

    const basePayload = {
      id,
      percentage,
    };

    if (value === 'custom') {
      const payload: CustomPaymentRule = {
        ...basePayload,
        type: value,
        dueReason: (paymentRule as CustomPaymentRule)?.dueReason ?? '',
      };

      onChange(payload);

      return;
    }

    if (value === 'date') {
      const payload: DatePaymentRule = {
        ...basePayload,
        type: value,
        dueDate: (paymentRule as DatePaymentRule).dueDate ?? getTomorrow(),
      };

      onChange(payload);

      return;
    }

    if (new Set(paymentTermsEvents).has(value)) {
      const payload: EventPaymentRule = {
        type: 'event',
        ...basePayload,
        dueEvent: value,
        dueDays: (paymentRule as EventPaymentRule)?.dueDays ?? 0,
      };

      onChange(payload);
    }
  };

  return (
    <TableCell>
      <CellSelectInput
        value={selectedType}
        onChange={handleTypeChange}
        selectProps={{
          disableUnderline: true,
          inputProps: {
            'data-testid': `type-input-${index}`,
          },
        }}
      >
        {paymentTermsEvents.map(event => (
          <MenuItem key={event} value={event}>
            <Typography textTransform="capitalize">
              {t(`paymentTerms.eventPaymentRuleDue.${event}` as any)}
            </Typography>
          </MenuItem>
        ))}

        {types.map(type => (
          <MenuItem key={type.value} value={type.value}>
            <Typography textTransform="capitalize">{t(type.translationKey)}</Typography>
          </MenuItem>
        ))}
      </CellSelectInput>
    </TableCell>
  );
}
