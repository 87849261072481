import React from 'react';
import { Button, Grid } from '@clippings/paper';
import { CheckoutStatusView } from 'libs/Quotes/Checkout/components';
import { PaymentStatusIcons } from 'libs/Quotes/Checkout/components/PaymentStatusIcons';
import { Link as RouterLink } from 'react-router-dom';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import { useTranslation } from 'react-i18next';

interface BankTransferPendingProps {
  orderNumber: string;
}

export const BankTransferPending: React.FC<BankTransferPendingProps> = ({ orderNumber }) => {
  const { t } = useTranslation();
  const returnUrl = useReturnUrl();
  const { getOrderShowPath } = useOrderPaths();

  return (
    <>
      <CheckoutStatusView
        icon={PaymentStatusIcons.pending}
        title={t('checkout.pending.title')}
        subtitle={t('checkout.pending.subtitle')}
      />
      <Grid display="flex" gap={1} mt={6}>
        <Button
          component={RouterLink}
          to={getOrderShowPath(orderNumber)}
          variant="contained"
          data-testid="view-order-link"
        >
          {t('common.viewOrder')}
        </Button>
        <Button href={returnUrl} variant="outlined" data-testid="continue-shopping-link">
          {t('common.continueShopping')}
        </Button>
      </Grid>
    </>
  );
};
