import React, { KeyboardEvent } from 'react';
import { CellInput, CellInputProps } from '@clippings/paper';

interface AddNewLineInputProps extends CellInputProps {
  value: string;
  onCreate: (value: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const AddNewLineInput = React.forwardRef<HTMLInputElement, AddNewLineInputProps>(
  ({ onCreate, value, ...rest }, ref) => {
    const addNewLine = () => {
      const trimmed = value.trim();

      if (trimmed) {
        onCreate(trimmed);
      }
    };

    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
        addNewLine();
      }
    };

    return (
      <CellInput value={value} autoComplete="off" onKeyDown={handleKeyDown} {...rest} ref={ref} />
    );
  }
);
