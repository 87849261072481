import React from 'react';
import { Category } from 'libs/api/catalogue/types';
import { Company } from 'libs/api/iam/types';
import { CompanyDiscount } from 'libs/api/common/types';
import { DiscountsDialogActions } from './DiscountsDialogActions';
import { DiscountsDialogContentBox } from './DiscountsDialogContentBox';
import { DiscountsDialogLoading } from './DiscountsDialogLoading';
import { ProductDiscounts } from '../productDiscount/ProductDiscounts';
import { QuoteDiscounts } from '../quoteDiscount/QuoteDiscounts';
import { useDiscountsManager } from '../../hooks';
import { useListCatalogues } from 'libs/api/catalogue/hooks';

export type DiscountDialogContentProps = {
  company: Company;
  discounts: CompanyDiscount[];
  categories: Category[];
  onSuccess: () => void;
  onCancel: () => void;
};

export const DiscountDialogContent: React.FC<DiscountDialogContentProps> = ({
  discounts,
  company,
  categories,
  onSuccess,
  onCancel,
}) => {
  const { data: catalogues, isSuccess } = useListCatalogues();

  const {
    productDiscounts,
    quoteDiscounts,
    isLoading,
    applyHandler,
    setProductDiscounts,
    setQuoteDiscounts,
  } = useDiscountsManager(discounts, company.id, onSuccess);

  if (!isSuccess) {
    return <DiscountsDialogLoading />;
  }

  return (
    <>
      <DiscountsDialogContentBox>
        <ProductDiscounts
          productDiscounts={productDiscounts}
          catalogues={catalogues}
          categories={categories}
          onChange={setProductDiscounts}
        />
        <QuoteDiscounts
          catalogues={catalogues}
          quoteDiscounts={quoteDiscounts}
          onChange={setQuoteDiscounts}
        />
      </DiscountsDialogContentBox>
      <DiscountsDialogActions isLoading={isLoading} onApply={applyHandler} onCancel={onCancel} />
    </>
  );
};
