import React from 'react';
import { InfinitePaginatedQueryOptions } from 'libs/api/common/types';
import { TeamV2 } from 'libs/api/iam/types';
import { useListInfiniteTeamsV2 } from 'libs/api/iam/hooks';

/**
 * Returns all teams
 */
export const useTeams = (options?: InfinitePaginatedQueryOptions<TeamV2>): [TeamV2[], boolean] => {
  const teamsQuery = useListInfiniteTeamsV2({}, options);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } =
    teamsQuery;

  if (!isFetchingNextPage && hasNextPage) {
    fetchNextPage();
  }

  const teams = React.useMemo(() => {
    return (data?.pages ?? []).flat();
  }, [data]);

  return [teams, isLoading || isFetching];
};
