import { useRef, useState } from 'react';

export const useQuoteSummaryState = () => {
  const [inputValue, setInputValue] = useState('');
  const newItemIndex = useRef(-1);

  return {
    inputValue,
    setInputValue,
    newItemIndex,
  };
};
