import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { ProductCard } from './ProductCard';
import { ProductHit } from 'libs/Quotes/quote.types';
import { useCurrentRefinements, useInfiniteHits } from 'react-instantsearch-hooks-web';

type ProductsListProps = {
  catalogueSlug: string;
  shouldRenderLeadTimeFields: boolean;
  appliedPriceFilter: Nullable<string>;
  appliedLeadTimeFilter: Nullable<string>;
  onAddProduct?: (product: ProductHit) => void;
  onConfigureProduct?: (product: ProductHit) => void;
};

export function ProductsList({
  catalogueSlug,
  shouldRenderLeadTimeFields,
  appliedPriceFilter,
  appliedLeadTimeFilter,
  onAddProduct,
  onConfigureProduct,
}: ProductsListProps) {
  const { hits, isLastPage, showMore } = useInfiniteHits<ProductHit>();
  const { items: refinementsByAttribute } = useCurrentRefinements();

  const topElementRef = useRef<HTMLDivElement>(null);
  const [bottomElement, setBottomElement] = useState<HTMLDivElement | null>(null);

  const appliedCategoryFilter = useMemo(() => {
    return refinementsByAttribute
      .filter(item => item.attribute === 'category')
      .flatMap(item => item.refinements.map(refinement => refinement.value))
      .join(',');
  }, [refinementsByAttribute]);

  useEffect(() => {
    if (topElementRef.current) {
      topElementRef.current.scrollIntoView();
    }
  }, [appliedCategoryFilter, appliedPriceFilter, appliedLeadTimeFilter]);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isLastPage) {
          showMore();
        }
      });
    });

    if (bottomElement) {
      observer.observe(bottomElement);
    }

    return () => observer.disconnect();
  }, [bottomElement, isLastPage, showMore]);

  return (
    <Grid flexGrow={1} pl={7} pr={3} container display="flex" flexWrap="wrap" overflow="auto">
      {/* // TODO: refactor the way new data is fetched. Create generic component that would observe the scroll position and will invoke a callback. */}
      <div ref={topElementRef} aria-hidden="true" />
      {hits.map(hit => (
        <Grid key={hit.objectID} item xs={12} md={6} lg={4} xl={3}>
          <ProductCard
            product={hit}
            catalogueSlug={catalogueSlug}
            onAddProduct={onAddProduct}
            onConfigureProduct={onConfigureProduct}
            renderLeadTime={shouldRenderLeadTimeFields}
          />
        </Grid>
      ))}
      <div ref={setBottomElement} aria-hidden="true" />
    </Grid>
  );
}
