import { QuoteMetaData } from 'libs/Chat/types';

export enum QuoteSystemStatusEvent {
  NewVersion = 'newVersion', // src/Buy/Messaging/Client/NewVersionChatMessage.php
  SalesRepJoined = 'joined', // src/Buy/Messaging/Client/SalesRepresentativeJoinedChatMessage.php
}
export enum QuoteSystemStatusChangeEvent {
  Ordered = 'ordered', // src/Buy/Messaging/Client/PlacedOrderChatMessage.php

  // src/Buy/Messaging/Client/ChangeStatusChatMessage.php
  Requested = 'quoteRequest',
  Approved = 'quoteApprove',
  Cancelled = 'quoteCancel',
  Expired = 'quoteExpire',
  Archived = 'quoteArchive',
  Unarchived = 'quoteUnarchive',
  Created = 'quoteCreated', // src/Buy/Messaging/Client/CreateQuoteChatMessage.php
  Ready = 'versionReady',

  VersionRestored = 'versionRestored',
}
export type ChatQuoteSystemEvent = QuoteSystemStatusChangeEvent | QuoteSystemStatusEvent;

export const quoteStatusChangeEvents = Object.values(QuoteSystemStatusChangeEvent);
export const quoteEvents = Object.values(QuoteSystemStatusEvent);

export const quoteSystemEvents = [...quoteStatusChangeEvents, ...quoteEvents];

// type guards
export const isQuoteStatusMetaData = (
  metaData: QuoteMetaData
): metaData is QuoteMetaData<QuoteSystemStatusChangeEvent> =>
  quoteStatusChangeEvents.includes(metaData.type as QuoteSystemStatusChangeEvent);

export const isQuoteMetaData = (
  metaData: QuoteMetaData
): metaData is QuoteMetaData<ChatQuoteSystemEvent> =>
  quoteSystemEvents.includes(metaData.type as ChatQuoteSystemEvent);

export const isQuoteSystemEvent = (event: unknown): event is ChatQuoteSystemEvent =>
  quoteSystemEvents.includes(event as ChatQuoteSystemEvent);

export const isQuoteSystemStatusEvent = (event: unknown): event is QuoteSystemStatusEvent =>
  quoteEvents.includes(event as QuoteSystemStatusEvent);
