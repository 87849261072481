import { useCallback, useEffect, useState } from 'react';

export const readFromLocalStorage = <T>(key: string, initialValue: T): T => {
  const item = window.localStorage.getItem(key);

  if (item === null) return initialValue;

  try {
    return JSON.parse(item) as T;
  } catch (e) {
    return item as unknown as T;
  }
};

export const writeToLocalStorage =
  <T>(key: string, setStoredValue: (value: T) => void) =>
  (value: T) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    } catch (e) {
      console.error(`Could not write to local storage for key ${key}:`, e);
    }
  };

export const useLocalStorage = <T>({
  key,
  initialValue,
}: {
  key: string;
  initialValue: T;
}): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(readFromLocalStorage(key, initialValue));

  useEffect(() => {
    const value = readFromLocalStorage(key, initialValue);

    if (value !== storedValue) {
      setStoredValue(value);
    }
  }, [key]);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readFromLocalStorage(key, initialValue));
  }, [key]);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [handleStorageChange]);

  const writer = useCallback(writeToLocalStorage<T>(key, setStoredValue), [key]);

  return [storedValue, writer];
};
