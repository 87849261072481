import React, { ComponentProps } from 'react';
import { Avatar, Box, BusinessIcon, Typography } from '@clippings/paper';
import { DataTestId } from '@clippings/paper/build/components/shared/types';

export interface DialogTitleProps extends ComponentProps<typeof Box> {
  title: string;
  iconComponent?: React.ReactNode;
  avatarProps?: ComponentProps<typeof Avatar>;
  titleProps?: ComponentProps<typeof Typography>;
}

export const DialogTitle: React.FC<DialogTitleProps & DataTestId> = ({
  title,
  iconComponent,
  avatarProps,
  titleProps,
  ...rest
}) => (
  <Box display="flex" alignItems="center" {...rest}>
    <Avatar
      data-testid="dialog-title-avatar"
      {...avatarProps}
      sx={{ backgroundColor: theme => theme.palette.grey.A200, ...avatarProps?.sx }}
    >
      {iconComponent ?? <BusinessIcon color="secondary" />}
    </Avatar>
    <Typography data-testid="dialog-title" variant="h6" pl={2} {...titleProps}>
      {title}
    </Typography>
  </Box>
);
