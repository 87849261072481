import React, { Suspense, lazy } from 'react';
import reducers from 'libs/Account/reducers';
import store from 'libs/Account/store';
import { AppConfigurationProvider, PaymentProvider, SearchProvider } from 'libs/providers';
import { AppProps } from '../bootstrap';
import { ChatClientProvider } from 'libs/Chat';
import { ErrorBoundary, ThemeProvider } from 'libs/Components';
import { GuestRouter } from './GuestRouter';
import { Provider } from 'react-redux';
import { QueryClient } from '@tanstack/query-core';
import { QueryParamProvider } from 'use-query-params';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactQueryProvider } from 'libs/providers/ReactQueryProvider';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter as Router } from 'react-router-dom';

const AlertBanner = lazy(() =>
  import('libs/Quotes/Components/AlertBanner').then(module => ({ default: module.AlertBanner }))
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const commonStore = store(reducers);

export function GuestApp({
  chatClient,
  configuration,
  paymentProviderInstance,
  searchProviderConfig,
}: AppProps) {
  return (
    <AppConfigurationProvider configuration={configuration}>
      <SearchProvider {...searchProviderConfig}>
        <PaymentProvider instance={paymentProviderInstance}>
          <ChatClientProvider chatClient={chatClient}>
            <ThemeProvider>
              <Router>
                <Provider store={commonStore}>
                  <ReactQueryProvider client={queryClient}>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <ErrorBoundary>
                        <GuestRouter />
                        <Suspense fallback={null}>
                          <AlertBanner />
                        </Suspense>
                      </ErrorBoundary>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </QueryParamProvider>
                  </ReactQueryProvider>
                </Provider>
              </Router>
            </ThemeProvider>
          </ChatClientProvider>
        </PaymentProvider>
      </SearchProvider>
    </AppConfigurationProvider>
  );
}
