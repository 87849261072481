import React from 'react';
import { Box, Monetary, Typography } from '@clippings/paper';
import { Money } from 'libs/api/common/types';
import { Row } from 'libs/shared';
import { TotalsSummaryRowLabel } from './TotalsSummaryRowLabel';
import { TotalsSummaryRowValue } from './TotalsSummaryRowValue';
import { useTranslation } from 'react-i18next';

export interface DiscountRowItem {
  id: number;
  name: string;
  price?: Money;
}

interface DiscountsRowProps {
  currency: string;
  discounts: DiscountRowItem[];
}

export const DiscountsRow: React.FC<DiscountsRowProps> = ({ currency, discounts }) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderBottom="1px solid"
      borderColor={theme => `${theme.palette.common.black}2`}
      sx={{ py: 1.5 }}
    >
      <Box display="flex" justifyContent="space-between">
        <TotalsSummaryRowLabel data-testid="discounts-row-summary-row-label">
          {t('common.discounts')}
        </TotalsSummaryRowLabel>
        <TotalsSummaryRowValue>
          {discounts.length === 0 && (
            <Monetary
              data-testid="discounts-row-value"
              variant="subtitle2"
              fontSize={theme => theme.typography.pxToRem(16)}
              fontWeight={600}
              currency={currency}
              amount={0}
            />
          )}
        </TotalsSummaryRowValue>
      </Box>
      {discounts.length > 0 &&
        discounts.map(discount => (
          <Row justifyContent="space-between" key={discount.id}>
            <Typography data-testid="discounts-row-name" variant="caption" color="secondary">
              {discount.name}
            </Typography>
            {discount.price?.amount !== undefined && (
              <Monetary
                data-testid="discounts-row-value"
                variant="subtitle2"
                fontSize={theme => theme.typography.pxToRem(16)}
                fontWeight={600}
                currency={currency}
                amount={discount.price?.amount}
              />
            )}
          </Row>
        ))}
    </Box>
  );
};
