import React from 'react';
import { Box } from '@clippings/paper';
import { CompanyData, PdfLayoutDivider, Title, voidFn } from 'libs/shared';
import { ExtraPaymentInfo } from 'libs/Quotes/PdfExport/components/info/ExtraPaymentInfo';
import { LineItemVisibleFields } from 'libs/api/configuration/types';
import { NoteSection } from 'libs/Quotes/PdfExport/components/NoteSection';
import { QuoteInfo } from 'libs/Quotes/PdfExport/components';
import { QuoteItemHiddenDetails } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { QuoteSummary } from 'libs/Quotes/QuoteSummary';
import { QuoteTableView } from 'libs/Quotes/QuoteTable/components';
import { useTranslation } from 'react-i18next';

export interface PdfTemplateProps {
  isLoading: boolean;
  shouldRenderExtraPaymentInfo?: boolean;
  hiddenColumns: LineItemVisibleFields;
  hiddenDetailProducts: QuoteItemHiddenDetails[];
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
  viewDataModel: any;
}

export const PdfDefaultTemplate: React.FC<PdfTemplateProps> = ({
  isLoading,
  shouldRenderExtraPaymentInfo,
  hiddenColumns,
  viewDataModel,
  hiddenDetailProducts,
  onProductDetailsHide,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <CompanyData />
      <PdfLayoutDivider />
      <QuoteInfo />
      <PdfLayoutDivider />
      <Title>{t('quotes.pdf.productsTitle')}</Title>
      <QuoteTableView
        className="pdf-table"
        textOnly
        readonly
        canAddQuoteItem={false}
        items={viewDataModel.items}
        catalogue={viewDataModel.catalogue}
        tableLoading={isLoading}
        hiddenDetailProducts={hiddenDetailProducts}
        hiddenColumns={hiddenColumns}
        handleDelete={voidFn}
        handleQuantityUpdate={voidFn}
        onConfigure={voidFn}
        handleOpenPlp={voidFn}
        onNoteSave={voidFn}
        onProductDetailsHide={onProductDetailsHide}
      />
      <Box className="pdf-summary" display="flex" justifyContent="space-between">
        <NoteSection />
        <QuoteSummary textOnly readonly />
      </Box>
      {shouldRenderExtraPaymentInfo ? <ExtraPaymentInfo /> : null}
    </>
  );
};
