import React from 'react';
import { Box } from '@clippings/paper';
import { DataTestId } from 'libs/shared/types';

interface TabPanelProps {
  show: boolean;
  children?: React.ReactNode;
}

export const TabPanel: React.FC<TabPanelProps & DataTestId> = ({ show, children, ...rest }) =>
  show ? (
    <Box role="tabpanel" display="flex" flexDirection="column" gap={2} {...rest}>
      {children}
    </Box>
  ) : null;
