import React from 'react';
import { AddQuoteItemCatalogueProduct } from 'libs/api/quotes/types';
import { Dialog } from '@clippings/paper';
import { PDPContainer } from 'libs/Quotes/Components/PdpConfigurator/PDPContainer';
import { ProductHit } from 'libs/Quotes/quote.types';
import { ProductListingPage } from '../productListing';
import { useAddItemSearchTerm } from 'libs/Quotes/providers';
import { useProductConfiguration, useQuoteDetails } from '../providers';
import { useTranslatedProduct } from '../hooks';
import 'libs/Quotes/quoteView/QuoteView.scss';

export function ProductConfiguration() {
  const { quote } = useQuoteDetails();
  const {
    configuredProduct,
    showPLP,
    addToQuoteMutation,
    updateQuoteItemMutation,
    addSampleHandler,
    handleAddProductFromPlp,
    handleUpdateExistingProduct,
    setConfiguredProduct,
    resetConfiguredProduct,
    closePLP,
  } = useProductConfiguration();

  const { setValue: setAddInputValue } = useAddItemSearchTerm();

  const { translateProductName } = useTranslatedProduct();

  const handleConfigureProduct = (product: ProductHit) =>
    setConfiguredProduct({
      showGroupedProducts: true,
      productId: product.objectID,
      productName: translateProductName(product),
    });

  const handleAddCatalogueProduct = (payload: AddQuoteItemCatalogueProduct) =>
    addToQuoteMutation.mutate(
      {
        versionId: quote.versionId,
        payload: { ...payload, productId: String(payload.productId) },
      },
      {
        onSuccess: () => {
          resetConfiguredProduct();
          setAddInputValue('');

          if (showPLP) {
            closePLP();
          }
        },
      }
    );

  return (
    <>
      <Dialog disablePortal fullScreen open={showPLP}>
        {showPLP && (
          <ProductListingPage
            catalogueSlug={quote.catalogue}
            onAddProduct={handleAddProductFromPlp}
            onConfigureProduct={handleConfigureProduct}
            onClose={closePLP}
          />
        )}
      </Dialog>
      <PDPContainer
        configuredProduct={configuredProduct}
        catalogueSlug={quote.catalogue}
        onClose={resetConfiguredProduct}
        onAddToQuote={
          configuredProduct?.isExisting ? handleUpdateExistingProduct : handleAddCatalogueProduct
        }
        submitting={addToQuoteMutation.isLoading || updateQuoteItemMutation.isLoading}
        onChangeProduct={setConfiguredProduct}
        onAddSample={addSampleHandler}
      />
    </>
  );
}
