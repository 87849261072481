import React from 'react';
import { CloseableDialog, LoadingButton, Typography } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

interface RejectCustomerConfirmationDialogProps {
  fullUserName: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
}

export const RejectCustomerConfirmationDialog: React.FC<RejectCustomerConfirmationDialogProps> = ({
  fullUserName,
  open = false,
  onClose,
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  return (
    <CloseableDialog
      open={open}
      onClose={onClose}
      title={t('common.rejectUser')}
      dialogProps={{
        'data-testid': 'reject-user-dialog',
        'aria-labelledby': 'alert-dialog-title',
        'aria-describedby': 'alert-dialog-description',
      }}
      dialogActions={
        <LoadingButton
          onClick={onSubmit}
          data-testid="dialog-reject-button"
          variant="contained"
          loading={isSubmitting}
        >
          {t('common.reject')}
        </LoadingButton>
      }
    >
      <Typography variant="subtitle2">
        {t('users.areYouSureYouWantToRejectUser', {
          name: fullUserName,
        })}
      </Typography>

      <Typography variant="body2" color="secondary">
        {t('users.youCanStillApproveThemInTheFutureIfNeeded')}
      </Typography>
    </CloseableDialog>
  );
};
