import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { QUOTE_STATUSES, routes } from 'libs/Constants';
import { StatusCodes } from 'http-status-codes';
import { getQuote } from 'libs/Quotes/quoteActions';
import { useQuery } from '@tanstack/react-query';
import { useQuoteQueryKey } from 'libs/Quotes/hooks/useQuoteQueryKey';

export const queryFn = (quoteNumber: string, version: number) => getQuote(quoteNumber, { version });
export const onErrorHandler = (error: AxiosError, navigate: NavigateFunction) => {
  if (error?.response?.status === StatusCodes.NOT_FOUND) {
    navigate(routes.genericError, {
      replace: true,
      state: { redirectedByErrorHandling: true },
    });
  }
};

export function useQuoteQuery(quoteNumber: string, version: number) {
  const navigate = useNavigate();
  const queryKey = useQuoteQueryKey(quoteNumber);

  const quoteQuery = useQuery(queryKey, () => queryFn(quoteNumber, version), {
    initialData: {
      number: quoteNumber,
      status: QUOTE_STATUSES.DRAFT,
    },
    onError: (error: AxiosError) => {
      onErrorHandler(error, navigate);
    },
  });

  return { query: quoteQuery, queryKey };
}
