import React, { ReactNode, createContext, useContext } from 'react';
import { CheckoutState } from '../checkout.types';
import { Payment, PaymentStatus } from 'libs/api/common/types';
import { PaymentMethod } from 'libs/api/payment/types';
import { useCheckout } from '../hooks';
import { useFormatPrice } from '@clippings/paper';
import { useQuoteProvider } from 'libs/Quotes/providers';

type UseCheckoutType = ReturnType<typeof useCheckout>;

export type QuoteCheckoutState = UseCheckoutType['state'] &
  UseCheckoutType['actions'] & {
    loading: boolean;
    payment: Payment;
    amount?: string;
    hasPaymentAmount: boolean;
    paymentType: PaymentMethod;
    setPaymentType: (paymentType: PaymentMethod) => void;
    setPaymentStatus: (status: PaymentStatus) => void;
    setPaymentError: (error: string) => void;
  };

export const QuoteCheckoutContext = createContext<QuoteCheckoutState>({} as QuoteCheckoutState);

type QuoteCheckoutProviderProps = {
  payment: Payment;
  children: (step: CheckoutState['step']) => ReactNode;
};

export function QuoteCheckoutProvider({ payment, children }: QuoteCheckoutProviderProps) {
  const { quote } = useQuoteProvider();
  const formatPrice = useFormatPrice();

  const { state, actions, loading } = useCheckout(payment, quote.id);
  const amount = payment ? formatPrice(payment.amount.amount, payment.amount.currency) : '';

  const value: QuoteCheckoutState = {
    ...state,
    ...actions,
    hasPaymentAmount: state.hasPaymentAmount,
    payment,
    loading,
    amount,
    paymentType: state.paymentMethod,
    setPaymentType: actions.onPaymentTypeSelect,
    setPaymentError: () => null,
    setPaymentStatus: () => null,
  };

  return (
    <QuoteCheckoutContext.Provider value={value}>
      {children(state.step)}
    </QuoteCheckoutContext.Provider>
  );
}

export function useQuoteCheckoutContext() {
  const context = useContext(QuoteCheckoutContext);

  if (!context) {
    throw new Error('useQuoteCheckoutContext must be used within a CheckoutProvider');
  }

  return context;
}
