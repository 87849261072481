import React from 'react';
import { BankTransfer } from '../BankTransfer';
import {
  Brand,
  FormControl,
  FormLabel,
  Grid,
  GridProps,
  RadioGroup,
  Typography,
} from '@clippings/paper';
import { CardFormWrapper } from '../Stripe/CardFormWrapper';
import { ComponentGridContainer } from 'libs/shared';
import { ComponentHeader } from '../ComponentHeader';
import { PaymentMethod } from 'libs/api/payment/types';
import { PaymentRadioLabel } from './PaymentRadioLabel';
import { QuoteCheckoutState } from '../../providers';
import { useAppConfiguration } from '../../../../providers';
import { useTranslation } from 'react-i18next';

export type PaymentDetailsProps = {
  paymentType: QuoteCheckoutState['paymentType'];
  amount: QuoteCheckoutState['amount'];
  containerProps?: GridProps;
  disabled?: boolean;
  setPaymentType: QuoteCheckoutState['setPaymentType'];
};

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  amount,
  paymentType,
  containerProps,
  disabled,
  setPaymentType,
}) => {
  const { t } = useTranslation();
  const { paymentMethods, instance } = useAppConfiguration();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPaymentType(e.target.value as PaymentMethod);

  const paymentTypesMap: Record<PaymentMethod, React.ReactElement> = {
    [PaymentMethod.Stripe]: <CardFormWrapper />,
    [PaymentMethod.BankTransfer]:
      instance === Brand.Moroso ? (
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('checkout.paymentMethodDetails.title')}
        </Typography>
      ) : (
        <BankTransfer amount={amount || ''} />
      ),
  };

  return (
    <ComponentGridContainer {...containerProps}>
      <ComponentHeader title={t('checkout.paymentDetails')} />

      <Grid container gap={3}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{ paddingY: 1 }}>
            {paymentMethods.length > 1 && (
              <FormLabel id="payment-method-radio-buttons-group-label">
                {t('checkout.chosePaymentMethod')}
              </FormLabel>
            )}
            <RadioGroup
              aria-labelledby="payment-method-radio-buttons-group-label"
              defaultValue={paymentMethods[0]}
              name="payment-method-radio-buttons-group"
              value={paymentType}
              onChange={handleChange}
            >
              {paymentMethods.map(method => {
                return (
                  <PaymentRadioLabel
                    type={method}
                    selected={paymentType}
                    disabled={disabled}
                    key={method}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item flexGrow={1}>
          {paymentTypesMap[paymentType]}
        </Grid>
      </Grid>
    </ComponentGridContainer>
  );
};
