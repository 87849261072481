import React from 'react';
import { Grid, Typography } from '@clippings/paper';
import { ListTableRow } from 'libs/Components/ListTable/ListTableRow';
import { TeamV2 } from 'libs/api/iam/types';

export interface TeamsTableItemProps {
  team: TeamV2;
  onClick: (team: TeamV2) => void;
}

export const TeamsTableItem: React.FC<TeamsTableItemProps> = ({ team, onClick }) => {
  const { id, name, membersCount, companiesCount } = team;

  return (
    <ListTableRow columns={3} data-testid={`teams-table-item-${id}`} onClick={() => onClick(team)}>
      <Grid item xs={1}>
        <Typography data-testid="teams-item-name" variant="subtitle2" fontWeight="fontWeightMedium">
          {name ?? '\u00A0'}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography data-testid="teams-item-members-count" variant="body2">
          {membersCount}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography data-testid="teams-item-companies-count" variant="body2">
          {companiesCount}
        </Typography>
      </Grid>
    </ListTableRow>
  );
};
