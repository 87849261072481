import {
  BaseListQueryParams,
  BaseUser,
  BillingAddress,
  Editable,
  ListQueryParams,
  PaymentRule,
  Picture,
  ShippingAddress,
} from 'libs/api/common/types';

//https://designtechnologies.atlassian.net/wiki/spaces/EN/pages/701104156/Roles
export enum UserRole {
  /**
   *  Default role, every user should have this
   */
  Login = 'ROLE_LOGIN',
  /**
   * Login privileges
   */
  User = 'ROLE_USER',
  /**
   * Administrative user, has access to everything
   */
  Admin = 'ROLE_ADMIN',
  /**
   * User who works directly with customers but also manages their team of sales reps/agency.
   */
  SalesManager = 'ROLE_SALES_MANAGER',
  /**
   * Internal brand users who manage data in their 3rd party systems - CRM/ERP. They sales rep and sales rep managers.
   */
  BackOffice = 'ROLE_BACK_OFFICE',
  /**
   * User who works directly with customers.
   */
  SalesRep = 'ROLE_SALES_REP',

  /**
   * Special role for a customer who can checkout quotes like a sales rep
   */
  ConsolidateRetailer = 'ROLE_CONSOLIDATE_RETAILER',

  /**
   * User who interacts with the platform to gather information about a product and/or create quotes.
   */
  BusinessCustomer = 'ROLE_BUSINESS_CUSTOMER',
  /**
   * An admin who has switched to another user
   */
  PreviousAdmin = 'PREVIOUS_ADMIN',
  // Moroso-specific roles
  A_And_D = 'ROLE_A_AND_D',
  // Fomcore-specific roles
  CSR = 'ROLE_CSR',
  CSRLeader = 'ROLE_CSR_LEADER',
  RegionalSalesDirector = 'ROLE_REGIONAL_SALES_DIRECTOR',
  RepGroup = 'ROLE_REP_GROUP',
}

export interface User extends BaseUser, Editable {
  status: UserStatus;
  phone?: string;
  company?: Company;
  jobTitle?: string;
  locale?: string;
  roles: Array<UserRole>;
  catalogue: Catalogue;
  teams: Array<BaseTeam>;
  createdAt: string;
  updatedAt: string;
  lastActiveAt: string;
  permissions: UserPermission[];
  billingAddresses?: BillingAddress[];
}

export type UserInput = {
  catalogueSlug?: string;
  pictureId?: number;
  firstName?: string;
  lastName?: string;
  status?: UserStatus;
  phone?: string;
  companyId?: number;
  jobTitle?: string;
  locale?: string;
  roles?: Array<UserRole>;
  catalogue?: Array<Catalogue>;
  picture?: Picture;
};

export interface UpdateUserVariables extends UserInput {
  id: number;
  teamsIds?: number[];
}

export interface LineManager {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Catalogue {
  id: number;
  name: string;
  slug: string;
  currency: string;
}

export interface Company extends Editable {
  id: number;
  name: string;
  website?: string;
  taxNumber?: string;
  registrationNumber?: string;
  salesRepresentative?: BaseUser;
  team?: BaseTeam;
  type: string;
  metadata: Record<string, string>;
  catalogues: Catalogue[];
  phone: string;
  createdAt?: string;
  updatedAt?: string;
  billingAddresses?: BillingAddress[];
  shippingAddresses?: ShippingAddress[];
  vendorId?: string;
  creditCardChargeFee: number;
  editable: boolean;
  status: 'active' | 'inactive';
  taxRate?: number;
}

export interface CreateCompanyVariables {
  name: string;
  website: string;
  registrationNumber: string;
  taxNumber?: string;
  salesRepresentativeId?: number;
  teamId?: number | null;
  type?: string;
  catalogues?: string[];
  phone: string;
  billingAddresses: BillingAddress[];
  shippingAddress?: ShippingAddress[];
  metadata: Record<string, string>;
  creditCardChargeFee?: number;
  status: string;
  taxRate?: number;
}

export interface UpdateCompanyVariables {
  companyId: number;
  company: Partial<CreateCompanyVariables>;
}

export interface BaseTeam {
  id: number;
  name: string;
  pdfTemplateId?: 'default';
}

export interface PdfTemplate {
  id: string;
  name: string;
}

export interface Team extends BaseTeam {
  members: User[];
  companies: Company[];
}

export interface TeamV2 extends BaseTeam {
  membersCount: number;
  companiesCount: number;
}

export interface CreateTeamVariables extends Omit<BaseTeam, 'id' | 'pdfTemplateId'> {
  membersIds?: number[];
  companiesIds?: number[];
  pdfTemplateId?: string | null;
}

export type TeamFormVariables = Pick<CreateTeamVariables, 'name' | 'pdfTemplateId'>;

export interface TeamUpdateVariables {
  teamId: number;
  team: CreateTeamVariables;
}

export interface ListTeamsParams extends BaseListQueryParams {
  /** Filter by team name */
  name?: string;
}

export interface UpdatePaymentTermsVariables {
  companyId: number;
  paymentTerms: PaymentRule[];
}

export interface CreateShippingAddressVariables {
  companyId: number;
  address: ShippingAddress;
}

export interface CreateBillingAddressVariables {
  companyId: number;
  address: BillingAddress;
}

export interface UpdateShippingAddressVariables {
  companyId: number;
  addressId: number;
  address: ShippingAddress;
}

export interface UpdateBillingAddressVariables {
  companyId: number;
  addressId: number;
  address: BillingAddress;
}

export interface DeleteShippingAddressVariables {
  companyId: number;
  addressId: number;
}

export interface DeleteBillingAddressVariables {
  companyId: number;
  addressId: number;
}

export interface ListCompaniesParams extends ListQueryParams {
  /** Filter by company type */
  type?: string;
}

export interface ListUsersParams extends ListQueryParams {
  /** Filter users by one or more roles separated by comma */
  roles?: string;
  /** Filter users by one or more company ids separated by comma */
  companyIds?: string;
  /** Filter users by one or more statuses separated by comma */
  statuses?: string;
  /** Filter users by one or more permissions separated by comma */
  permissions?: string;
  /** How many users per page. Defaults to 20 */
  itemsPerPage?: number;
  /** Filter users by one or more team ids separated by comma */
  teamIds?: string;
  /** Filter users by vendor id */
  vendorId?: string;
  /** Only returns users from active companies */
  activeOnly?: boolean;
}

export interface CreateUserVariables {
  companyId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  status?: UserStatus;
  roles?: UserRole[];
  teamsIds?: number[];
}

export enum UserStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface UserCredentials {
  email: string;
  password: string;
}

export interface UserChangePasswordValues {
  password: string;
  token: string;
}

export enum UserPermission {
  Login = 'PERMISSION_LOGIN',
  CanUploadPictures = 'PERMISSION_CAN_UPLOAD_PICTURES',
  CanAccessAllProjects = 'PERMISSION_CAN_ACCESS_ALL_PROJECTS',
  CanAccessAllUsers = 'PERMISSION_CAN_ACCESS_ALL_USERS',
  AccessUnlistedProducts = 'PERMISSION_ACCESS_UNLISTED_PRODUCTS',
  CanEditImageTypes = 'PERMISSION_CAN_EDIT_IMAGE_TYPES',
  CanAccessLogs = 'PERMISSION_CAN_ACCESS_LOGS',
  CanAccessSalesApp = 'PERMISSION_CAN_ACCESS_SALES_APP',
  CanEditAdminUsers = 'PERMISSION_CAN_EDIT_ADMIN_USERS',
  CanViewUsers = 'PERMISSION_CAN_VIEW_USERS',
  CanEditUsers = 'PERMISSION_CAN_EDIT_USERS',
  CanViewCustomers = 'PERMISSION_CAN_VIEW_CUSTOMERS',
  CanEditCustomers = 'PERMISSION_CAN_EDIT_CUSTOMERS',
  CanViewTeams = 'PERMISSION_CAN_VIEW_TEAMS',
  CanEditTeams = 'PERMISSION_CAN_EDIT_TEAMS',
  CanAssignTeamToCompany = 'PERMISSION_CAN_ASSIGN_TEAM_TO_COMPANY',
  CanAssignSalesRepToCompany = 'PERMISSION_CAN_ASSIGN_SALES_REP_TO_COMPANY',
  CanEditQuotes = 'PERMISSION_CAN_EDIT_QUOTES',
  CanViewOrders = 'PERMISSION_CAN_VIEW_ORDERS',
  CanEditOrders = 'PERMISSION_CAN_EDIT_ORDERS',
  CanConfirmManualPayments = 'PERMISSION_CAN_CONFIRM_MANUAL_PAYMENTS',
  CanViewProjects = 'PERMISSION_CAN_VIEW_PROJECTS',
  CanEditProjects = 'PERMISSION_CAN_EDIT_PROJECTS',
  AssignToQuote = 'PERMISSION_ASSIGN_TO_QUOTE',
  CanAccessEmailTester = 'PERMISSION_CAN_ACCESS_EMAIL_TESTER',
  CanEditCategories = 'PERMISSION_CAN_EDIT_CATEGORIES',
  CanViewCategories = 'PERMISSION_CAN_VIEW_CATEGORIES',
  SkipAdapterSync = 'PERMISSION_SKIP_ADAPTER_SYNC',
  CanCreateCompanies = 'PERMISSION_CAN_CREATE_COMPANIES',
  CanCreateUsers = 'PERMISSION_CAN_CREATE_USERS',
  CanCheckoutQuotes = 'PERMISSION_CAN_CHECKOUT_QUOTES',
  CanAccessStaffUsersManagement = 'PERMISSION_CAN_ACCESS_STAFF_USERS_MANAGEMENT',
}
