import { Company } from 'libs/api/iam/types';
import { useState } from 'react';
import { useToggle } from 'libs/shared';

export function useCompaniesPage() {
  const [createCompanyDialogOpen, setCreateCompanyDialogOpen] = useState(false);
  const [newlyCreatedCompany, setNewlyCreatedCompany] = useState<Company | undefined>(undefined);
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useToggle(false);

  const companyCreateSuccessHandler = (company: Company) => {
    setNewlyCreatedCompany(company);
    setIsCreateUserDialogOpen(true);
  };

  return {
    createCompanyDialogOpen,
    newlyCreatedCompany,
    isCreateUserDialogOpen,
    companyCreateSuccessHandler,
    setIsCreateUserDialogOpen,
    setCreateCompanyDialogOpen,
    setNewlyCreatedCompany,
  };
}
