import React from 'react';
import {
  Box,
  Button,
  CloseableDialog,
  Typography,
  WarningAmberOutlinedIcon,
  alpha,
} from '@clippings/paper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface MakeCustomConfirmationDialogProps {
  onMakeCustom: () => void;
  onClose: () => void;
  open: boolean;
}
export const MakeCustomConfirmationDialog: FC<MakeCustomConfirmationDialogProps> = ({
  onMakeCustom,
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  return (
    <CloseableDialog
      open={open}
      onClose={onClose}
      title={`${t('common.makeCustom')}?`}
      dialogProps={{
        'data-testid': 'confirm-make-custom-dialog',
        'aria-labelledby': 'make-custom-dialog-title',
        'aria-describedby': 'make-custom-dialog-description',
      }}
      dialogActions={
        <Button onClick={onMakeCustom} data-testid="button-make-custom" variant="contained">
          {t('common.makeCustom')}
        </Button>
      }
    >
      <Box
        display="flex"
        flexDirection="row"
        p={1.5}
        borderRadius={0.5}
        width={300}
        sx={{ backgroundColor: theme => alpha(theme.palette.warning.main, 0.1) }}
      >
        <Box>
          <WarningAmberOutlinedIcon fontSize="small" color="warning" />
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-between" pl={1}>
          <Typography
            data-testid="make-custom-dialog-title"
            fontWeight={500}
            sx={{
              fontSize: theme => theme.typography.pxToRem(14),
              color: theme => theme.palette.grey[900],
              mb: 0.5,
            }}
          >
            {t('common.actionUndone')}
          </Typography>
          <Typography
            data-testid="make-custom-dialog-description"
            sx={{
              fontSize: theme => theme.typography.pxToRem(12),
              color: theme => theme.palette.grey[900],
            }}
          >
            {t('common.addItemAgain')}
          </Typography>
        </Box>
      </Box>
    </CloseableDialog>
  );
};
