import React, { ComponentProps } from 'react';
import { Button, Divider, Paper } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export interface FormCompanyTypeaheadPaperProps extends ComponentProps<typeof Paper> {
  onCreateNewCompanyClick?: () => void;
  searchValue?: string;
  canCreateCompany?: boolean;
}

export const createNewCompanyBtnDataTestId = 'button-form-company-create-new-company';

export const FormCompanyTypeaheadPaper: React.FC<FormCompanyTypeaheadPaperProps> = ({
  children,
  onCreateNewCompanyClick,
  searchValue,
  canCreateCompany,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Paper {...rest}>
      {children}
      {canCreateCompany && onCreateNewCompanyClick && (
        <>
          <Divider />
          <Button
            data-testid={createNewCompanyBtnDataTestId}
            onMouseDown={e => e.preventDefault()}
            onClick={onCreateNewCompanyClick}
            sx={{ fontSize: theme => theme.typography.pxToRem(14) }}
          >
            {t('forms.createNewCompany')}
            {searchValue && ` "${searchValue}"`}
          </Button>
        </>
      )}
    </Paper>
  );
};
