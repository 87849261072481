import React from 'react';
import { Button, FlexRow, LoadingButton } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

type PaymentTermsDialogActionsProps = {
  isLoading: boolean;
  onApply: () => void;
  onCancel: () => void;
};

export function PaymentTermsDialogActions({
  isLoading,
  onApply,
  onCancel,
}: PaymentTermsDialogActionsProps) {
  const { t } = useTranslation();

  return (
    <FlexRow pt={2} justifyContent="flex-end">
      <Button data-testid="payment-terms-cancel-button" color="secondary" onClick={onCancel}>
        {t('common.cancel')}
      </Button>

      <LoadingButton
        data-testid="payment-terms-apply-button"
        loading={isLoading}
        variant="contained"
        onClick={onApply}
      >
        {t('common.apply')}
      </LoadingButton>
    </FlexRow>
  );
}
