import React, { FC } from 'react';
import { Box, FormTextField, Typography, yup } from '@clippings/paper';
import { CheckoutIcon } from '../../icons';
import { FormModal } from 'libs/Components';
import { SignatureData } from 'libs/api/order/types';
import { useTranslation } from 'react-i18next';
import './SignatureModal.scss';

export type SignatureModalProps = {
  open: boolean;
  onSubmit: (data: SignatureData) => void;
  onCancel: () => void;
  loading: boolean;
  data: SignatureData;
};

const signatureValidationSchema: Record<keyof SignatureData, yup.AnySchema> = {
  fullName: yup.string().required(),
  email: yup.string().emailAddress().required(),
  jobPosition: yup.string(),
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <Box
      data-testid="dialog-title-container"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex="auto"
      className="signature-dialog-title-box"
      marginBottom={6}
    >
      <CheckoutIcon type="signature" />
      <Typography
        data-testid="signature-dialog-title"
        className="signature-dialog__title"
        variant="h5"
      >
        {t('checkout.signature.title')}
      </Typography>
      <Typography
        color="secondary"
        data-testid="signature-dialog-subtitle"
        className="signature-subtitle"
        variant="subtitle2"
      >
        {t('checkout.signature.subtitle')}
      </Typography>
    </Box>
  );
};

export const SignatureModal: FC<SignatureModalProps> = ({
  onSubmit,
  onCancel,
  open,
  loading,
  data,
}) => {
  const { t } = useTranslation();
  return (
    <FormModal<SignatureData>
      open={open}
      onClose={onCancel}
      data-testid="signature-modal"
      aria-labelledby="signature-dialog-title"
      title={<Title />}
      formProps={{
        validationSchema: signatureValidationSchema,
        onSubmit,
        defaultValues: data,
      }}
      actionProps={{
        onCancel,
        cancelLabel: t('common.cancel'),
        submitLabel: t('checkout.completeOrder'),
      }}
      isSubmitting={loading}
    >
      <FormTextField
        placeholder=""
        id="signature-fullName"
        name="fullName"
        variant="standard"
        label={t('common.fullName')}
        fullWidth
      />
      <FormTextField
        placeholder=""
        type="email"
        id="signature-workEmail"
        name="email"
        variant="standard"
        label={t('common.workEmail')}
        fullWidth
      />
      <FormTextField
        placeholder=""
        id="signature-jobPosition"
        name="jobPosition"
        variant="standard"
        label={t('common.jobPosition')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
    </FormModal>
  );
};
