import React from 'react';
import {
  AssignmentIcon,
  LogoutIcon,
  PersonIcon,
  ShoppingBagIcon,
  Skeleton,
} from '@clippings/paper';
import { MainAppNavItem } from './MainAppNavItem';
import { SideNav } from 'libs/Components/SideNav/SideNav';
import { UserItem } from './UserItem';
import { routes } from 'libs/Constants';
import { useCurrentUser } from '../../api/iam/hooks';
import { useTranslation } from 'react-i18next';

export const MainAppNavigation = () => {
  const { t } = useTranslation();
  const userQuery = useCurrentUser();

  return (
    <SideNav
      openPerRouteConfig={{
        [routes.quotes.show]: false,
        [routes.orders.show]: false,
      }}
    >
      {!userQuery.isSuccess ? (
        <Skeleton variant="text" height={68} />
      ) : (
        <UserItem user={userQuery.data} />
      )}
      <MainAppNavItem
        icon={<ShoppingBagIcon />}
        label={t('common.quotes')}
        to={routes.quotes.list}
        data-testid="nav-quotes-item"
      />
      <MainAppNavItem
        icon={<AssignmentIcon />}
        label={t('common.orders')}
        to={routes.orders.list}
        data-testid="nav-orders-item"
      />
      <MainAppNavItem
        icon={<PersonIcon />}
        label={t('common.account')}
        to={routes.account.show}
        data-testid="nav-account-item"
      />
      <MainAppNavItem
        icon={<LogoutIcon />}
        label={t('common.signOut')}
        to={routes.logout}
        data-testid="nav-sign-out-item"
        pageReload
      />
    </SideNav>
  );
};
