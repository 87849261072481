import React, { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { QuotesFilters, QuotesTableSort } from '../../types';

export type QuotesTableContextState = {
  filters: QuotesFilters;
  sort: QuotesTableSort;
  setFilters: (filters: QuotesFilters) => void;
  clearFilters: () => void;
  setSort: (sort: QuotesTableSort) => void;
};

export const SalesQuotesTableContext = createContext<QuotesTableContextState>(
  {} as QuotesTableContextState
);

type QuotesTableProviderProps = {
  filters: QuotesFilters;
  sort: QuotesTableSort;
  updateSorting: (sort: QuotesTableSort) => void;
  updateFilters: (filters: QuotesFilters) => void;
  clearFilters: () => void;
};

export function QuotesTableProvider({
  children,
  sort,
  filters,
  clearFilters,
  updateFilters,
  updateSorting,
}: PropsWithChildren<QuotesTableProviderProps>) {
  const value = useMemo(
    () => ({
      filters,
      sort,
      clearFilters,
      setSort: updateSorting,
      setFilters: updateFilters,
    }),
    [filters, sort, clearFilters, updateSorting, updateFilters]
  );

  return (
    <SalesQuotesTableContext.Provider value={value}>{children}</SalesQuotesTableContext.Provider>
  );
}

export function useQuotesTable() {
  const context = useContext(SalesQuotesTableContext);

  if (!context) {
    throw new Error('useQuotesTableProvider must be used within a QuotesTableProvider');
  }

  return context;
}
