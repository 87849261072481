import React, { useState } from 'react';
import {
  ArrowDownIcon,
  Button,
  DownloadIcon,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@clippings/paper';
import { InvoiceMenuSkeleton } from './Skeletons';
import { getDocuments } from '../../api/order/actions';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

type OrderInvoiceMenuProps = {
  number: string;
};

export const OrderInvoiceMenu: React.FC<OrderInvoiceMenuProps> = ({ number }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const documentQuery = useQuery(['documents', number], () => getDocuments(number));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!documentQuery.isSuccess) {
    return <InvoiceMenuSkeleton />;
  }

  if (documentQuery.data.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        id="invoice-button"
        aria-controls={open ? 'invoice-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        endIcon={<ArrowDownIcon />}
        color="secondary"
      >
        {t('common.invoice')}
      </Button>
      <Menu
        id="invoice-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'invoice-button',
        }}
      >
        {documentQuery.data.map(document => {
          return (
            <MenuItem
              key={document.id}
              component={Link}
              download
              href={document.downloadUrl}
              onClick={handleClose}
            >
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <Typography variant="body1">{document.fileName}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
