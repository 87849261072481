import React, { ComponentProps, FC, useRef, useState } from 'react';
import { CloseIcon, Divider, InputBase, ThemMui, Typography } from '@clippings/paper';
import { Column, preventDefaultPropagation } from 'libs/shared';
import { useTranslation } from 'react-i18next';

const emptyNote = '';

export type NoteProps = {
  value?: string;
  withDivider?: boolean;
  readonly?: boolean;
  onSave?: (note: string) => void;
  inputProps?: ComponentProps<typeof InputBase>['inputProps'];
  selectorSuffix?: string;
  placeholder?: string;
  required?: boolean;
};

export const Note: FC<NoteProps> = ({
  value = emptyNote,
  onSave,
  readonly = false,
  withDivider = false,
  inputProps = noteInputProps,
  selectorSuffix = 'note',
  required = false,
  placeholder,
}) => {
  const { t } = useTranslation();
  const { isClearNoteVisible, clearNoteVisibilityEventHandler } = useClearNoteVisibility();
  const [note, setNote] = useState(value);
  const [lastNote, setLastNode] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  if (value !== lastNote) {
    setNote(value);
    setLastNode(value);
  }

  if (readonly) {
    return <NoteReadOnly value={note} />;
  }

  function changeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setNote(event.target.value);
  }

  function blurHandler() {
    clearNoteVisibilityEventHandler('blur');

    saveNote(note);
  }

  function clearNoteClickHandler(event: React.MouseEvent<SVGSVGElement>) {
    preventDefaultPropagation<SVGSVGElement>(event);

    setNote(emptyNote);
    saveNote(emptyNote);

    setTimeout(() => inputRef.current?.focus());
  }

  function saveNote(note: string) {
    if (note === value) {
      return;
    }

    onSave?.(note);
  }

  return (
    <Column position="relative" mt={1} data-testid={`quote-note-container-${selectorSuffix}`}>
      {withDivider ? <Divider sx={dividerStyles} data-testid="quote-note-divider" /> : null}
      <InputBase
        inputProps={inputProps}
        inputRef={inputRef}
        placeholder={placeholder ?? t('quotes.addNote')}
        multiline
        fullWidth
        value={note}
        sx={{ ...textAreaStyles, color: required && !note ? 'red' : fontColor }}
        onFocus={() => clearNoteVisibilityEventHandler('focus')}
        onChange={changeHandler}
        onBlur={blurHandler}
      />

      {note && isClearNoteVisible ? (
        <CloseIcon
          onMouseDown={clearNoteClickHandler}
          sx={iconStyles}
          data-testid="quote-note-clear-button"
        />
      ) : null}
    </Column>
  );
};

export const NoteReadOnly: FC<Pick<NoteProps, 'value'>> = ({ value = emptyNote }) => {
  return (
    <Typography
      variant="body2"
      mt={1}
      sx={defaultTextStyles}
      data-testid="quote-note-text-readonly"
    >
      {value}
    </Typography>
  );
};

function useClearNoteVisibility() {
  const [visible, setVisible] = useState(false);

  function clearNoteVisibilityEventHandler(eventType: 'focus' | 'blur') {
    if (visible && eventType === 'focus') {
      return;
    }

    setVisible(eventType === 'focus');
  }

  return { isClearNoteVisible: visible, clearNoteVisibilityEventHandler };
}

const noteInputProps = { 'data-testid': 'quote-note-input' };

const fontColor = 'rgba(0, 0, 0, 0.6)';

const defaultTextStyles = {
  fontSize: (theme: ThemMui) => theme.typography.pxToRem(12),
  fontStyle: 'italic',
  color: fontColor,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};

const textAreaStyles = {
  ...defaultTextStyles,
  width: '95%',
};

const dividerStyles = {
  marginBottom: 1,
};

const iconStyles = {
  position: 'absolute',
  right: 0,
  marginTop: (theme: ThemMui) => theme.typography.pxToRem(6),
  fontSize: 14,
  fill: 'rgba(0, 0, 0, 0.3)',
  cursor: 'pointer',
};
