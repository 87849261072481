import React from 'react';
import { ListItemIcon, MenuItem, UnarchiveIcon } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { useQuoteTransitionAction } from 'libs/shared/hooks/useQuoteTransitionAction';
import { useTranslation } from 'react-i18next';

export interface UnarchiveQuoteButtonProps {
  quote: Quote;
  onClick: (mouseEvent: any) => void;
}

export const UnarchiveQuoteButton: React.FC<UnarchiveQuoteButtonProps> = ({ quote, onClick }) => {
  const { t } = useTranslation();
  const { shouldDisableTransitionButton } = useQuoteTransitionAction(quote, 'renew', []);

  return (
    <MenuItem
      onClick={onClick}
      key="menu-item-unarchive"
      data-testid="unarchive-quote"
      disabled={shouldDisableTransitionButton}
    >
      <ListItemIcon key="list-unarchive-icon">
        <UnarchiveIcon key="unarchive-icon" />
      </ListItemIcon>
      {t('common.restoreQuote')}
    </MenuItem>
  );
};
