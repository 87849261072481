import React, { PropsWithChildren, createContext, useContext } from 'react';
import { Quote } from 'libs/api/quotes/types';
import { QuoteShareContextState, useQuoteShareUtils } from './useQuoteShareUtils';

const QuoteShareContext = createContext<QuoteShareContextState>(null!);

export type QuoteShareProviderProps = {
  quote: Quote | undefined;
};
export function QuoteShareProvider({
  children,
  quote,
}: PropsWithChildren<QuoteShareProviderProps>) {
  const value = useQuoteShareUtils({ quote });
  return <QuoteShareContext.Provider value={value}>{children}</QuoteShareContext.Provider>;
}

export function useQuoteShare() {
  const context = useContext(QuoteShareContext);

  if (!context) {
    throw new Error('useQuoteShare must be used within a QuoteShareProvider');
  }

  return context;
}
