import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { PRODUCT_MAX_QUANTITY } from 'libs/Utils';
import { QuantityInput, TableCell, Typography } from '@clippings/paper';
import { TableCellCommonProps } from './tableCells.utils';
import { isEqual } from 'lodash';

type QuantityTableCellProps = {
  onQuantityUpdate: (quantity: number, id: number) => void;
} & TableCellCommonProps;

export function QuantityTableCell({
  item,
  readonly,
  loading,
  onQuantityUpdate,
}: QuantityTableCellProps) {
  const [quantityCellKey, setQuantityCellKey] = useState<string>(uniqueId());

  useEffect(() => {
    setQuantityCellKey(uniqueId());
  }, [item]);

  const handleQuoteItemQuantityUpdate = (quantity: number) => {
    if (isEqual(quantity, item.quantity)) {
      return;
    }

    onQuantityUpdate(quantity, item.id);
  };

  return (
    <TableCell
      data-testid={`quote-item-quantity-${item.id}`}
      className={classNames('quote-item-row__cell', 'quote-item-row__cell__quantity')}
      align="center"
    >
      {readonly ? (
        <Typography
          data-testid={`quote-item-quantity-readonly-${item.id}`}
          variant="body2"
          sx={{ marginTop: '9px', fontSize: theme => theme.typography.pxToRem(14) }}
        >
          {item.quantity}
        </Typography>
      ) : (
        <QuantityInput
          key={quantityCellKey} // force rerender due to the nature of 'initialQuantity' and QuantityInput
          max={PRODUCT_MAX_QUANTITY}
          disabled={loading}
          className="quote-item-row__quantity"
          initialQuantity={item.quantity}
          onQuantityChange={handleQuoteItemQuantityUpdate}
          data-testid={`quantity-input-${item.id}`}
          inputProps={{
            sx: {
              '.MuiInputBase-input': {
                fontSize: theme => theme.typography.pxToRem(14),
                minWidth: '26px !important',
              },
            },
          }}
        />
      )}
    </TableCell>
  );
}
