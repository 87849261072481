import React from 'react';
import { Divider } from '@clippings/paper';

export function PdfLayoutDivider() {
  return <Divider sx={dividerSx} className="pdf-divider" />;
}

const dividerSx = {
  bgcolor: 'common.black',
};
