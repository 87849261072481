import React from 'react';
import { Box, Button, ChevronLeftIcon, Image, Link, Typography, useTheme } from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers/AppConfigurationProvider';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import { useTranslation } from 'react-i18next';

interface AuthenticationLayoutProps {
  content: React.ReactNode;
  sidePanel: React.ReactNode;
  onBackButtonClick?: () => void;
}

export const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({
  content,
  sidePanel,
  onBackButtonClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    images: { logo },
  } = useTheme();
  const appConfiguration = useAppConfiguration();
  const returnUrl = useReturnUrl();

  return (
    <Box display="flex" height="100%">
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100vh"
        {...rest}
      >
        {onBackButtonClick && (
          <Button
            variant="text"
            size="large"
            aria-label={t('common.back')}
            onClick={onBackButtonClick}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: theme => theme.palette.secondary.main,
            }}
            startIcon={<ChevronLeftIcon />}
          >
            {t('common.back')}
          </Button>
        )}
        <Box width="100%" py={6} textAlign="center">
          <Link href={returnUrl}>
            <Image src={logo} alt="logo" height={44} />
          </Link>
        </Box>
        <Box width="100%" flexGrow={1} display="flex" flexDirection="column" px={3} overflow="auto">
          <Box width="100%" m="auto" maxWidth="500px" pb={3}>
            {content}
          </Box>
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <Typography variant="body2" color="secondary" textAlign="center" py={3}>
            &copy; {appConfiguration.tradeName} {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
      <Box display={{ md: 'block', sm: 'none', xs: 'none' }} width="50%">
        {sidePanel}
      </Box>
    </Box>
  );
};
