import React, { PropsWithChildren } from 'react';
import { Row } from '../Row';
import { Typography } from '@clippings/paper';

type RowItemValueProps = {
  label: string;
  value: string;
  valueWeight?: 'fontWeightMedium' | 'fontWeightRegular';
};

export function LabelContainer({
  label,
  children,
}: PropsWithChildren<Pick<RowItemValueProps, 'label'>>) {
  return (
    <Row justifyContent="space-between" alignItems="center">
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
      {children}
    </Row>
  );
}

export function RowItemValue({
  label,
  value,
  valueWeight = 'fontWeightRegular',
}: RowItemValueProps) {
  return (
    <LabelContainer label={label}>
      <Typography variant="caption" fontWeight={valueWeight}>
        {value}
      </Typography>
    </LabelContainer>
  );
}
