import React, { FC } from 'react';
import { Box } from '@clippings/paper';
import { EmptyTablePlaceholder } from 'libs/Components';
import { EmptyTablePlaceholderProps } from 'libs/Components/EmptyTablePlaceholder/EmptyTablePlaceholder';

export interface CombinedEmptyTablePlaceholderProps {
  filtered?: boolean;
  resetPlaceholderProps: EmptyTablePlaceholderProps;
  createPlaceholderProps: EmptyTablePlaceholderProps;
}

export const CombinedEmptyTablePlaceholder: FC<CombinedEmptyTablePlaceholderProps> = ({
  filtered,
  resetPlaceholderProps,
  createPlaceholderProps,
}) => (
  <Box mt={2}>
    {filtered ? (
      <EmptyTablePlaceholder
        {...resetPlaceholderProps}
        containerProps={{
          'data-testid': 'empty-table-placeholder-reset',
          ...resetPlaceholderProps.containerProps,
        }}
      />
    ) : (
      <EmptyTablePlaceholder
        {...createPlaceholderProps}
        containerProps={{
          'data-testid': 'empty-table-placeholder-create',
          ...createPlaceholderProps.containerProps,
        }}
      />
    )}
  </Box>
);
