import React from 'react';
import { AddAlgoliaItemCell } from 'libs/SalesApp/Quotes/components/AddQuoteAlgoliaItem';
import { AddCustomAndPredefinedDiscount } from './AddCustomAndPredefinedDiscount';
import { AddCustomDiscount } from './AddCustomDiscount';
import { Brand, DataGrid, TableBody, TableCell, TableHead, TableRow } from '@clippings/paper';
import { DiscountsTableRow } from './DiscountsTableRow';
import { Readonly } from 'libs/shared';
import { useAppConfiguration, useSearchProvider } from 'libs/providers';
import { useDiscount } from '../../hooks/useDiscount';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const DiscountsTable: React.FC<Readonly> = ({ readonly = false }) => {
  const { t } = useTranslation();
  const { discountIndex } = useSearchProvider();
  const { quote } = useQuoteProvider();
  const { brand } = useAppConfiguration();
  const {
    inputValue,
    setInputValue,
    discountChangeHandler,
    discountCreateHandler,
    discountSelectHandler,
  } = useDiscount();

  if (readonly && quote.discountItems.length === 0) {
    return null;
  }

  return (
    <DataGrid compact data-testid="discounts-table">
      <TableHead>
        <TableRow>
          <TableCell data-testid={`quote-discounts-name-${quote.number}`} width="60%">
            {t('quotes.discounts.table.discountName')}
          </TableCell>
          <TableCell
            data-testid={`quote-discounts-type-${quote.number}`}
            width="25%"
            align={readonly ? 'right' : 'left'}
          >
            {t('quotes.discounts.table.type')}
          </TableCell>
          <TableCell
            data-testid={`quote-discounts-amount-${quote.number}`}
            width="15%"
            align="right"
          >
            {t('quotes.discounts.table.amount')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {quote.discountItems.map((discount, index) => (
          <DiscountsTableRow
            key={discount.id}
            index={index}
            discount={discount}
            readonly={readonly}
            onChange={discount => discountChangeHandler(index, discount)}
          />
        ))}
        {!readonly ? (
          <TableRow>
            {discountIndex && brand === Brand.Fomcore ? (
              <AddCustomAndPredefinedDiscount>
                <AddAlgoliaItemCell<number>
                  inputValue={inputValue}
                  inputValueChangeHandler={setInputValue}
                  placeholder={t('quotes.addProductPlaceholder')}
                  inputProps={{ 'data-testid': 'add-discount-input' }}
                  infoLabel={t('quotes.searchInfoLabelNoCustom')}
                  onItemCreate={discountCreateHandler}
                  onItemSelect={discountSelectHandler}
                  inputDisabled
                />
              </AddCustomAndPredefinedDiscount>
            ) : (
              <AddCustomDiscount />
            )}
            <TableCell />
            <TableCell />
          </TableRow>
        ) : null}
      </TableBody>
    </DataGrid>
  );
};
