import QuoteContext from '../QuoteContext';
import React, { useRef } from 'react';
import { Brand } from '@clippings/paper';
import { CatalogsProvider } from 'libs/catalogs';
import { MorosoPaymentInfoTemplate } from 'libs/Quotes/PdfExport/templates/MorosoPaymentInfoTemplate';
import { PdfDefaultTemplate } from 'libs/Quotes/PdfExport/templates/PdfDefaultTemplate';
import { PdfExportLayoutContainer, PdfSkeleton, SaveAsPdfButton } from 'libs/shared';
import { Quote } from 'libs/api/quotes/types';
import { getQuoteVersion } from '../quoteActions';
import { routes } from 'libs/Constants';
import { useAppConfiguration } from 'libs/providers';
import { useBanner } from 'libs/Components';
import { useNavigate, useParams } from 'react-router-dom';
import { usePaymentTermsQuery } from 'libs/api/quotes/hooks';
import { useQuery } from '@tanstack/react-query';
import { useQuoteHiddenDetailProducts } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { useQuotePdfExportPage } from './useQuotePdfExportPage';
import { useQuoteQueryKey } from 'libs/Quotes/hooks';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';
import '../../shared/pdfExport/PdfExport.scss';

export enum PdfTemplateIds {
  Default = 'default',
  Gulf = 'gulf',
}

export const QuotePdfExportPage = () => {
  const { t } = useTranslation();

  const pdfPage = useRef(null);
  const { number, id } = useParams() as { number: string; id: string };
  const { showErrorBanner } = useBanner();
  const { instance } = useAppConfiguration();
  const navigate = useNavigate();

  useTitle(`${t('common.quote')} #${number?.toUpperCase()}`);

  const queryKey = useQuoteQueryKey(number);

  const { data: quote, isLoading } = useQuery([queryKey], () => getQuoteVersion(Number(id)), {
    onError: showErrorBanner,
  });

  const TEMPLATE_MAP = {
    [PdfTemplateIds.Default]: PdfDefaultTemplate,
    [PdfTemplateIds.Gulf]: MorosoPaymentInfoTemplate,
  };

  const { shouldRedirectToUnauthorized, hiddenColumns, viewDataModel } = useQuotePdfExportPage(
    quote as Quote
  );

  const [hiddenDetailProducts, onProductDetailsHide, onAllProductDetailsHide] =
    useQuoteHiddenDetailProducts(quote?.number ?? '', 'quote');

  const paymentTermsQuery = usePaymentTermsQuery(Number(id));

  if (isLoading) {
    return <PdfSkeleton />;
  }

  // redirect to 401 if the user is trying to access the 'noDiscount' variant through the URL
  if (shouldRedirectToUnauthorized) {
    navigate(routes.accessDenied, {
      replace: true,
    });

    return null;
  }

  const shouldRenderExtraPaymentInfo = instance === Brand.Sossego;
  const SelectedTemplate =
    TEMPLATE_MAP[quote?.customer?.company?.team?.pdfTemplateId ?? PdfTemplateIds.Default];

  return (
    <CatalogsProvider>
      <QuoteContext.Provider
        // @ts-expect-error: the context is incomplete it will be refactored on smaller parts
        value={{
          loading: isLoading,
          quote: quote as Quote,
          paymentTermsQuery,
          hiddenDetailProducts,
          onProductDetailsHide,
          onAllProductDetailsHide,
        }}
      >
        <SaveAsPdfButton documentTitle={`Quote-${number}`} pageRef={pdfPage} />
        <PdfExportLayoutContainer ref={pdfPage}>
          <SelectedTemplate
            isLoading={isLoading}
            hiddenColumns={hiddenColumns}
            viewDataModel={viewDataModel}
            hiddenDetailProducts={hiddenDetailProducts}
            onProductDetailsHide={onProductDetailsHide}
            shouldRenderExtraPaymentInfo={shouldRenderExtraPaymentInfo}
          />
        </PdfExportLayoutContainer>
      </QuoteContext.Provider>
    </CatalogsProvider>
  );
};
