import { ACTIONS, ActionTypes } from './types';
import { CONFIRM, SETUP, backStep, forwardStep } from '../config/steps';
import { CheckoutState, PaymentStatus } from '../checkout.types';
import { PaymentMethod } from 'libs/api/payment/types';

export const reducer = (state: CheckoutState, action: ActionTypes): CheckoutState => {
  const { data, setupReady, step } = state;

  switch (action.type) {
    case ACTIONS.ACCEPT_TERMS_AND_CONDITIONS: {
      return {
        ...state,
        termsAndConditionModal: false,
        signatureModal: state.termsAndConditionModalOpenedFromSign,
        data: { ...data, termsAndConditionsAccepted: true },
      };
    }
    case ACTIONS.BACK_STEP: {
      return { ...state, step: backStep(step) };
    }
    case ACTIONS.COMPLETE_STEP: {
      if (step === SETUP && !setupReady) {
        return state;
      }
      return { ...state, step: forwardStep(step) };
    }
    case ACTIONS.ERROR_PAYMENT: {
      return {
        ...state,
        paymentStatus: PaymentStatus.Error,
        step: CONFIRM,
        error: action.payload,
      };
    }
    case ACTIONS.SET_CARD_COMPLETE: {
      const newState = { ...state, cardComplete: action.payload };
      return { ...newState, setupReady: isSetupReady(newState) };
    }
    case ACTIONS.SET_DATA: {
      const newState = { ...state, data: { ...data, ...action.payload } };
      return { ...newState, setupReady: isSetupReady(newState) };
    }
    case ACTIONS.SET_PAYMENT_METHOD: {
      const newState = {
        ...state,
        paymentMethod: action.payload,
        data: {
          ...data, //Todo check is it forgotten
        },
      };
      return { ...newState, setupReady: isSetupReady(newState) };
    }

    case ACTIONS.SET_TERMS_AND_CONDITIONS_MODAL: {
      return { ...state, termsAndConditionModal: action.payload };
    }
    case ACTIONS.SET_HAS_PO_NUMBER: {
      if (action.payload) {
        const newState = { ...state, hasPONumber: true };
        return { ...newState, setupReady: isSetupReady(newState) };
      }
      const newState = { ...state, hasPONumber: false, data: { ...data, poNumber: '' } };
      return { ...newState, setupReady: isSetupReady(newState) };
    }
    case ACTIONS.SET_SIGNATURE_MODAL: {
      return { ...state, signatureModal: action.payload };
    }
    case ACTIONS.SIGN: {
      if (data.termsAndConditionsAccepted) {
        return { ...state, signatureModal: true };
      }
      return { ...state, termsAndConditionModal: true, termsAndConditionModalOpenedFromSign: true };
    }
    case ACTIONS.SUBMIT_SIGNATURE: {
      return { ...state, data: { ...data, ...action.payload }, signatureModal: false };
    }
    case ACTIONS.SUCCESSFUL_BANK_TRANSFER: {
      return {
        ...state,
        paymentStatus: PaymentStatus.Pending,
        step: CONFIRM,
      };
    }
    case ACTIONS.SUCCESSFUL_PAYMENT: {
      return {
        ...state,
        paymentStatus: PaymentStatus.Success,
        step: CONFIRM,
      };
    }

    case ACTIONS.SET_IS_UPLOADING_PO_DOCUMENT: {
      const newState = {
        ...state,
        isUploadingPODocument: action.payload,
      };
      return { ...newState, setupReady: isSetupReady(newState) };
    }
    case ACTIONS.SET_PO_DOCUMENT: {
      const newState = { ...state, data: { ...data, poDocument: action.payload } };
      return {
        ...newState,
        setupReady: isSetupReady(newState),
      };
    }
    default: {
      return state;
    }
  }
};

////
const isSetupReady = (state: CheckoutState): boolean => {
  const {
    paymentMethod,
    hasPONumber,
    cardComplete,
    immediatePayment,
    hasPaymentAmount,
    data: { poNumber, poDocument },
    isUploadingPODocument,
    isPoDocumentRequired,
  } = state;

  if (
    (isPoDocumentRequired && !poDocument) ||
    (hasPONumber && !poNumber) ||
    isUploadingPODocument
  ) {
    return false;
  }

  if (!hasPaymentAmount || !immediatePayment || paymentMethod === PaymentMethod.BankTransfer) {
    return true;
  }

  return hasPaymentAmount && cardComplete;
};

export const getInitialState = (paymentMethods: PaymentMethod[]): CheckoutState => ({
  cardComplete: false,
  data: {
    termsAndConditionsAccepted: false,
    poNumber: '',
    fullName: '',
    email: '',
    jobPosition: '',
    poDocument: null,
  },
  error: '',
  hasPONumber: true,
  immediatePayment: true,
  hasPaymentAmount: true,
  paymentMethod: paymentMethods[0],
  paymentStatus: PaymentStatus.Incomplete,
  setupReady: false,
  signatureModal: false,
  stripeSecret: undefined,
  step: SETUP,
  termsAndConditionModal: false,
  termsAndConditionModalOpenedFromSign: false,
  isPoDocumentRequired: false,
  isUploadingPODocument: false,
});
