import React, { PropsWithChildren } from 'react';
import {
  DeepPartial,
  FieldErrors,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  ValidationMode,
  useForm,
  useSyncServerErrors,
  yup,
  yupResolver,
} from '@clippings/paper';
import { removeNullValues } from 'libs/shared';

export type FormWrapperProps<T extends FieldValues = FieldValues> = {
  defaultValues?: DeepPartial<T>;
  validationSchema?: Record<keyof T, yup.AnySchema>;
  errors?: FieldErrors;
  mode?: keyof ValidationMode;
  onSubmit: SubmitHandler<T>;
  onError?: SubmitErrorHandler<T>;
};

export function FormWrapper<T extends FieldValues = FieldValues>(
  props: PropsWithChildren<FormWrapperProps<T>>
) {
  const {
    defaultValues,
    errors,
    validationSchema,
    mode = 'onSubmit',
    children,
    onSubmit,
    onError,
  } = props;
  const schema = validationSchema && yup.object().shape(validationSchema);

  const methods = useForm<T>({
    defaultValues: defaultValues ? removeNullValues(defaultValues) : undefined,
    resolver: schema && yupResolver(schema),
    mode,
  });

  useSyncServerErrors(methods, errors);

  return (
    <FormProvider {...methods} {...{ schema }}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
}
