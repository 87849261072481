import React, { FC } from 'react';
import { Box, LoadingButton, TextField } from '@clippings/paper';
import { QuoteShareModalScreenProps } from '../useQuoteShareUtils';
import { ShareScreenTitle } from '../components/ShareScreenTitle';
import { ShareeSelect } from '../components/ShareeSelect';
import { useQuoteShare } from '../QuoteShareProvider';
import { useTranslation } from 'react-i18next';

export const ShareScreen: FC<QuoteShareModalScreenProps> = () => {
  const { t } = useTranslation();
  const {
    message,
    setMessage,
    sharees: { selectedSharees, addShareesMutation, erronousSharees },
  } = useQuoteShare();

  const sendClickHandler = () => {
    addShareesMutation.mutate({
      sharees: selectedSharees.map(sharee => ({ email: sharee.email })),
      message,
    });
  };

  const textFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  return (
    <>
      <ShareScreenTitle />
      <ShareeSelect openOnFocus />
      <TextField
        rows={4}
        value={message}
        onChange={textFieldChangeHandler}
        fullWidth
        multiline
        placeholder={t('quotes.share.addMessage')}
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <LoadingButton
          data-testid="quote-share-send"
          disabled={erronousSharees.length > 0}
          loading={addShareesMutation.isLoading}
          variant="contained"
          color="primary"
          onClick={sendClickHandler}
        >
          {t('quotes.share.send')}
        </LoadingButton>
      </Box>
    </>
  );
};
