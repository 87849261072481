import React from 'react';
import { Brand, useFormatPercentage } from '@clippings/paper';
import { Company, UserPermission } from 'libs/api/iam/types';
import { InfoLine } from 'libs/shared';
import { STATUSES } from 'libs/SalesApp/Companies/components/CompanyFormFields';
import { departmentItems, priorityItems } from '../../../../utils/constants';
import { useAppConfiguration } from 'libs/providers';
import { useHasPermission } from 'libs/hooks/useHasPermission';
import { useTranslation } from 'react-i18next';

interface GeneralCompanyInfoBoxProps {
  company: Company;
}

export const GeneralCompanyInfoBox: React.FC<GeneralCompanyInfoBoxProps> = ({ company }) => {
  const { t } = useTranslation();
  const { instance, companyTypes } = useAppConfiguration();
  const isStaffUser = useHasPermission(UserPermission.CanAccessSalesApp);
  const formatPercentage = useFormatPercentage();

  const statusLabel = STATUSES[company.status].label;

  return (
    <>
      <InfoLine
        data-testid="general-company-info-company"
        label={t('common.company')}
        value={company.name}
      />
      {instance !== Brand.Fomcore && (
        <InfoLine
          data-testid="general-company-info-registrationNumber"
          label={t('common.registrationNumber')}
          value={company.registrationNumber}
        />
      )}
      {instance !== Brand.Sossego && instance !== Brand.Fomcore && (
        <InfoLine
          data-testid="general-company-info-vatNumber"
          label={t('common.vatNumber')}
          value={company.taxNumber}
        />
      )}
      {instance === Brand.Moroso && (
        <InfoLine
          data-testid="general-company-info-vatNumber"
          label={t('common.vat')}
          value={company.taxRate ? `${company.taxRate}%` : ''}
        />
      )}
      <InfoLine
        data-testid="general-company-info-type"
        label={t('common.type')}
        value={t(companyTypes.find(x => x.value === company.type)?.label)}
      />
      <InfoLine
        data-testid="general-company-info-website"
        label={t('common.website')}
        value={company.website}
      />
      <InfoLine
        data-testid="general-company-info-status"
        label={t('common.status')}
        value={t(statusLabel as 'common.active' | 'common.inactive')}
      />
      <InfoLine
        data-testid="general-company-info-phoneNumber"
        label={t('common.phoneNumber')}
        value={company.phone}
        divider={isStaffUser}
      />
      {instance === Brand.Moroso && (
        <>
          <InfoLine
            data-testid="general-company-info-pec"
            label={t('companies.pec')}
            value={company.metadata.pec}
          />
          <InfoLine
            data-testid="general-company-info-sdi"
            label={t('companies.sdiCode')}
            value={company.metadata.sdi}
          />
          <InfoLine
            data-testid="general-company-info-priority"
            label={t('companies.priority')}
            value={t(priorityItems.find(x => x.value === company.metadata.priority)?.label)}
          />
          <InfoLine
            data-testid="general-company-info-paymentMethod"
            label={t('common.paymentMethod')}
            value={company.metadata.paymentMethod}
          />
          <InfoLine
            data-testid="general-company-info-bankName"
            label={t('companies.bankNameForDirectDebit')}
            value={company.metadata.bankName}
          />
          <InfoLine
            data-testid="general-company-info-bankIban"
            label={t('companies.bankIbanForDirectDebit')}
            value={company.metadata.bankIban}
          />
          <InfoLine
            data-testid="general-company-info-generalNotes"
            label={t('companies.generalNotes')}
            value={company.metadata.generalNotes}
          />
          <InfoLine
            data-testid="general-company-info-department"
            label={t('companies.department')}
            value={t(departmentItems.find(x => x.value === company.metadata.department)?.label)}
            divider
          />
        </>
      )}
      {company.vendorId && isStaffUser ? (
        <InfoLine
          data-testid="general-company-info-vendorId"
          label={t('common.externalId')}
          value={company.vendorId}
        />
      ) : null}
      {instance === Brand.Sossego && (
        <InfoLine
          data-testid="general-company-info-creditCardChargeFee"
          label={t('common.creditCardChargeFee')}
          value={formatPercentage(company.creditCardChargeFee)}
        />
      )}
    </>
  );
};
