import React, { useState } from 'react';
import {
  CellInputWithReadonly,
  CellMoneyInputWithReadonly,
  CellPercentageInputWithReadonly,
  TimeRangeCellWithReadonly,
} from './componentsWithReadOnlyLabel';
import { QuoteDeliveryItem } from 'libs/api/quotes/types';
import { ReadOnlyLabelTypography } from 'libs/Components';
import { Readonly, RowActionsPanel } from 'libs/shared';
import {
  TableCell,
  TableRow,
  convertTimeRange,
  convertWeeksToDays,
  useFormatLeadTime,
  useFormatPercentage,
  useFormatPrice,
} from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

export interface DeliveryItemsTableRowProps extends Readonly {
  index: number;
  deliveryItem: QuoteDeliveryItem;
  onChange: (discount: QuoteDeliveryItem | null) => void;
}

export const DeliveryItemsTableRow: React.FC<DeliveryItemsTableRowProps> = ({
  index,
  readonly = false,
  deliveryItem,
  onChange,
}) => {
  const { t } = useTranslation();

  const { leadTimeFormat } = useAppConfiguration();
  const formatLeadTime = useFormatLeadTime();

  const formatPercentage = useFormatPercentage();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const formatPrice = useFormatPrice();

  const showActionPanel = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElement(event.currentTarget);

  const hideActionPanel = () => setAnchorElement(null);

  const handleDelete = () => onChange(null);

  const handleNameChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value !== deliveryItem.name) {
      onChange({ ...deliveryItem, name: event.target.value, deliveryRef: null, custom: true });
    }
  };

  const handleShippingTimeChange = (shippingTime: [number, number]) => {
    if (shippingTime.toString() !== deliveryItem.shippingTime.toString()) {
      let timeValueInDays;

      if (leadTimeFormat === 'weeks') {
        timeValueInDays = convertWeeksToDays(shippingTime);
      }

      onChange({
        ...deliveryItem,
        shippingTime: timeValueInDays ?? shippingTime,
        deliveryRef: null,
        custom: true,
      });
    }
  };

  const handleAmountChange = (value: number) => {
    if (value !== deliveryItem.netPrice.amount) {
      onChange({
        ...deliveryItem,
        netPrice: { ...deliveryItem.netPrice, amount: value || 0 },
        deliveryRef: null,
        custom: true,
      });
    }
  };

  const handleTaxChange = (value: number) => {
    if (value !== deliveryItem.taxPercentage) {
      onChange({ ...deliveryItem, taxPercentage: value || 0, deliveryRef: null, custom: true });
    }
  };

  const renderShippingTime = (shippingTime: [number, number]) => {
    if (shippingTime[0] === 0 && !shippingTime[1]) {
      return t('common.instant');
    }

    return formatLeadTime(shippingTime, leadTimeFormat);
  };

  const shippingTimeRange = convertTimeRange(deliveryItem.shippingTime, 'days', leadTimeFormat);

  return (
    <TableRow
      onMouseEnter={showActionPanel}
      onMouseLeave={hideActionPanel}
      data-testid={`delivery-items-table-row-${index}`}
    >
      <TableCell>
        <CellInputWithReadonly
          onBlur={handleNameChange}
          forcedValue={deliveryItem.name}
          inputProps={{ 'data-testid': 'delivery-items-table-name-input' }}
          readonly={readonly}
          readonlyValue={deliveryItem.name}
          textAlign="left"
        />
      </TableCell>
      <TimeRangeCellWithReadonly
        timeRange={shippingTimeRange}
        renderTimeRange={renderShippingTime}
        onBlur={handleShippingTimeChange}
        readonly={readonly}
        labelComponent={
          <TableCell sx={{ textAlign: 'right' }}>
            <ReadOnlyLabelTypography
              data-testid="delivery-items-table-row-shipping-time"
              value={renderShippingTime(shippingTimeRange)}
            />
          </TableCell>
        }
      />
      <TableCell>
        <CellPercentageInputWithReadonly
          value={deliveryItem.taxPercentage}
          inputProps={{
            sx: { textAlign: 'right' },
            'data-testid': 'delivery-items-table-tax-input',
          }}
          onBlur={handleTaxChange}
          readonly={readonly}
          readonlyValue={`${formatPercentage(deliveryItem.taxPercentage)}`}
        />
      </TableCell>
      <TableCell>
        <CellMoneyInputWithReadonly
          value={deliveryItem.netPrice.amount}
          currency={deliveryItem.netPrice.currency}
          onBlur={handleAmountChange}
          inputProps={{
            sx: { textAlign: 'right' },
            'data-testid': 'delivery-items-table-amount-input',
          }}
          readonlyValue={formatPrice(deliveryItem.netPrice.amount, deliveryItem.netPrice.currency)}
          readonly={readonly}
        />
      </TableCell>
      {!readonly ? <RowActionsPanel anchorElement={anchorElement} onDelete={handleDelete} /> : null}
    </TableRow>
  );
};
