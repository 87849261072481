import React from 'react';
import { BillingAddress } from 'libs/api/common/types';
import { Company, User, UserPermission } from 'libs/api/iam/types';
import {
  ContractFields,
  Quote,
  QuoteItemProperty,
  QuoteListItemStatus,
  QuoteProductItem,
  QuoteVersion,
} from 'libs/api/quotes/types';
import { FormSkeletonRow } from 'libs/Quotes/QuoteAddresses/Skeletons';
import { Nullable } from 'libs/Utils';
import { OrderProductItem } from 'libs/api/order/types';
import { QUOTE_STATUSES } from 'libs/Constants';
import { isEmpty } from 'lodash';

export function getActiveQuoteVersion(quoteVersions: QuoteVersion[]) {
  return quoteVersions.find(version => version.active);
}

export function getSelectedQuoteVersion(
  quoteVersions: QuoteVersion[],
  quoteVersionNumber: Quote['versionNumber']
) {
  return quoteVersions.find(version => version.versionNumber === quoteVersionNumber);
}

type BuildVariationNameProps = Pick<QuoteProductItem, 'properties' | 'variationName'> & {
  productName: string | null;
};

export const buildVariationName = (
  { properties, productName, variationName }: BuildVariationNameProps,
  shouldIncludePropertyCode = false
) =>
  variationName
    ? variationName
    : properties
        ?.filter(property => !isEmpty(property.value))
        ?.reduce(
          (accumulator, property) =>
            `${accumulator} \n ${property.name}: ${
              shouldIncludePropertyCode ? `${property.code} ${property.value}` : property.value
            }`,
          productName ?? ''
        ) ?? '';

const isPriceGroup = (code: string, priceGroupVendorId?: string) => priceGroupVendorId === code;

export const getFormattedProps = (properties: QuoteItemProperty[]) => {
  const formattedProps: {
    [k: string]: {
      value: string;
      isPriceGroup: boolean;
      code: string;
    }[];
  } = {};

  properties
    // we don't want to display properties with empty values
    ?.filter(prop => !isEmpty(prop.value))
    .forEach(property => {
      const value = {
        value: property.value,
        code: property.code,
        isPriceGroup: isPriceGroup(property.code, property.priceGroupVendorId),
      };
      if (formattedProps[property.name]) {
        formattedProps[property.name].push(value);
      } else {
        formattedProps[property.name] = [value];
      }
    });

  return formattedProps;
};

export function hasOrderedVersion(versions: QuoteVersion[]) {
  return versions.some(version => version.status === 'ordered');
}

export function getCompanyCity(billingAddresses: Company['billingAddresses']): string {
  let city = '';

  if (billingAddresses && billingAddresses.length > 0) {
    city = billingAddresses[0]?.city;
  }

  return city;
}

export function getCompanyNameWithCity(companyName = '', billingAddresses?: BillingAddress[]) {
  const companyCity = getCompanyCity(billingAddresses);

  return `${companyName} ${companyCity ? `(${companyCity})` : ''}`;
}

export const canCheckoutQuote = (quoteStatus: QuoteListItemStatus, user: User) =>
  ((quoteStatus === QuoteListItemStatus.Approved || quoteStatus === QuoteListItemStatus.Ready) &&
    user.permissions.includes(UserPermission.CanCheckoutQuotes)) ??
  false;

export const canApproveAndSend = (quoteStatus: QuoteListItemStatus) =>
  quoteStatus === QuoteListItemStatus.Requested || quoteStatus === QuoteListItemStatus.Draft;

export const renderLoadingInput = (input: JSX.Element, isLoading: boolean, key: string) =>
  isLoading ? <FormSkeletonRow key={key} /> : input;

export function getQuoteItemContractFieldValue(
  item?: QuoteProductItem | OrderProductItem
): Nullable<ContractFields> {
  if (!item) {
    return null;
  }

  const { contractListPrice, contractNetPrice, contractSubtotal } = item;

  return { contractListPrice, contractNetPrice, contractSubtotal };
}

export const canUndoQuoteVersion = (quote: Quote) => {
  return (
    quote.status === QUOTE_STATUSES.DRAFT &&
    !!quote.versions.find(
      v =>
        v.versionNumber === quote.versionNumber - 1 &&
        (v.status === QUOTE_STATUSES.APPROVED || v.status === QUOTE_STATUSES.READY)
    )
  );
};
