import React, { ComponentProps } from 'react';
import { Box, Checkbox, FormControlLabel } from '@clippings/paper';
import { FilterSection } from './FilterSection';
import { translateCategoryLabel } from '../../utils';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { useTranslation } from 'react-i18next';

export const CategoryFilter: React.FC = () => {
  const { t } = useTranslation();
  const { items, refine } = useRefinementList({
    attribute: 'category',
    sortBy: ['name:asc'],
    limit: Infinity,
  });

  return (
    <FilterSection label={t('common.categories')}>
      <Box px={2} display="flex" flexDirection="column" height={200} overflow="auto">
        {items.map(item => {
          const label = translateCategoryLabel(item.label, t);

          return (
            <FormControlLabel
              sx={formControlLabelSx}
              key={item.value}
              label={label}
              control={<Checkbox checked={item.isRefined} onChange={() => refine(item.value)} />}
              data-testid={`category-option-${item.value}`}
            />
          );
        })}
      </Box>
    </FilterSection>
  );
};

const formControlLabelSx: ComponentProps<typeof FormControlLabel>['sx'] = {
  '& .MuiFormControlLabel-root': {
    m: 0,
  },
  '& .MuiFormControlLabel-label': {
    fontSize: theme => theme.typography.pxToRem(14),
    fontWeight: 'regular',
  },
};
