import React from 'react';
import { DateTime, DateTimeFormat, FlexRow, UserAvatar } from '@clippings/paper';
import { FC } from 'react';
import { FormatMessageResponse } from 'stream-chat';

export const RegularMessage: FC<{ message: FormatMessageResponse }> = ({ message }) => {
  const names = message?.user?.name?.split(' ') || [];
  const firstName = names[0];
  const lastName = names.slice(1).join(' ');

  return (
    <FlexRow
      key={message.id}
      gap={2}
      p={1}
      mb={1}
      sx={{
        backgroundColor: 'grey.A100',
      }}
    >
      <UserAvatar
        user={{
          firstName: firstName,
          lastName: lastName,
        }}
        small
      />
      {message.text}

      <DateTime
        format={DateTimeFormat.DateTime}
        date={message.created_at}
        variant="caption"
        ml={'auto'}
      />
    </FlexRow>
  );
};
