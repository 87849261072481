import { TranslationKey } from 'libs/types/react-i18next';

export const priorityItems: Array<{ value: string; label: TranslationKey }> = [
  { value: 'Important', label: 'companies.priorities.important' },
  { value: 'Standard', label: 'companies.priorities.standard' },
  { value: 'Occasional', label: 'companies.priorities.occasional' },
];

export const departmentItems: Array<{ value: string; label: TranslationKey }> = [
  { value: 'Sales', label: 'companies.departments.sales' },
  { value: 'Logistics', label: 'companies.departments.logistics' },
  { value: 'Accounting', label: 'companies.departments.accounting' },
  { value: 'Management', label: 'companies.departments.management' },
];
