import { ACTION_AFTER_SUBMIT } from './hooks/quoteReducer';
import { AddressField } from '../shared/components/address/addressFieldConfigs';
import { AddressTypesEnum } from './hooks/useAddresses';
import { CompositeDiscountValue } from '../SalesApp/Quotes/components/CompositeDiscountCell';
import {
  DeliveryMethodEvaluationResponse,
  Quote,
  QuoteProductItem,
  QuoteVersion,
} from 'libs/api/quotes/types';
import { NonContractualMutationType } from './hooks/useQuoteHandlers';
import { Payment, ShippingAddress } from 'libs/api/common/types';
import { QuoteItemHiddenDetails } from './hooks/useQuoteHiddenDetailProducts';
import { QuoteUpdateOptions } from './quote.types';
import { UpdateQuoteByKey } from '../SalesApp/Quotes/types';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import { createContext } from 'react';

export interface SnackbarStateProps {
  handleShowSnackbar: (message: string) => void;
}

type AddressMutationCallback<T, U> = (
  data: T,
  type: AddressTypesEnum,
  onSuccess: (response: U) => void,
  onError: () => void
) => void;

type UpdateAddressMutationCallback<T, U> = (
  data: T,
  onSuccess: (response: U) => void,
  onError: () => void,
  type: AddressTypesEnum
) => void;

type AddressType = {
  quoteAddressQuery: UseQueryResult<any>;
  companyAddressQuery: UseQueryResult<any>;
  handlers: {
    handleAddressCreate: AddressMutationCallback<any, any>;
    handleAddressDelete: AddressMutationCallback<any, any>;
    handleAddressUpdate: UpdateAddressMutationCallback<any, any>;
  };
  isLoading: boolean;
};

type MutationCallback<T, U> = (
  data: T,
  onSuccess: (response: U) => void,
  onError?: () => void
) => void;

type ShippingAddressType = AddressType & {
  evaluateDeliveryMethodsQuery: UseQueryResult<DeliveryMethodEvaluationResponse>;
  handleDeliveryMethodSelect: (data: any, onSuccess: (data: any) => void) => void;
  handlers: AddressType['handlers'] & {
    handleExWorksCreate: AddressMutationCallback<
      {
        [AddressField.Email]: string;
        [AddressField.ContactName]: string;
        [AddressField.Phone]: string;
      },
      { shippingAddress: ShippingAddress }
    >;
  };
};

type BillingAddressType = {
  companyAddressQuery: UseQueryResult<any, unknown>;
  handlers: {
    handleAddressCreate: (data: any, onSuccess: (data: any) => void, onError?: () => void) => void;
    handleAddressUpdate: (
      data: any,
      onSuccess: (data: any) => void,
      onError: () => void,
      type: any
    ) => void;
  };
  isLoading: boolean;
};

export type QuoteContextState = {
  number: string;
  isError?: boolean;
  quote: Quote;
  queryKey: string[];
  loading: boolean;
  isReadonly: boolean;
  handleQuoteUpdate: (data: Partial<Quote>, options?: QuoteUpdateOptions) => void;
  handleNonContractualUpdate: (data: Partial<Quote>, options?: QuoteUpdateOptions) => void;
  isLabelModalOpen: boolean;
  handleLabelModalClose: () => void;
  handleLabelModalOpen: () => void;
  onQuoteItemQuantityUpdate: (quantity: number, itemId: number) => Promise<void>;
  updateProductItemNonContractualDataHandler?: (
    data: Partial<QuoteProductItem>,
    type?: NonContractualMutationType
  ) => void;
  onQuoteItemDelete: (itemId: number) => void;
  onLoadLatestVersion: () => void;
  onUnarchiveQuoteVersion: () => Promise<void>;
  onRestoreQuoteVersion: () => Promise<void>;
  onQuoteItemUpdateByKey: UpdateQuoteByKey;
  onMakeCustom: (variationName: string, itemId: number) => void;
  onQuoteItemUpdateDiscount: (value: CompositeDiscountValue, itemId: number) => void;
  onDuplicateQuote: () => void;
  onDeleteQuoteVersion: () => void;
  tableLoading: boolean;
  changeQuoteVersion: (data: QuoteVersion) => void;
  optimisticLoading: boolean;
  updateWithoutOptimisticLoading: boolean;
  onArchiveQuote: () => Promise<void>;
  isShippingModalOpen: boolean;
  handleShippingModalClose: () => void;
  handleShippingModalOpen: (isDetailed?: boolean, actionAfterSubmit?: ACTION_AFTER_SUBMIT) => void;
  isBillingModalOpen: boolean;
  handleBillingModalClose: () => void;
  handleBillingModalOpen: (actionAfterSubmit?: ACTION_AFTER_SUBMIT) => void;
  addressCreationMutation: any;
  handleShippingAddressCreate: () => void;
  handleBillingAddressCreate: () => void;
  handleShippingAddressUpdate: () => void;
  handleBillingAddressUpdate: () => void;
  shippingAddressQuery: any;
  quoteShippingAddressQuery: any;
  billingAddressQuery: any;
  quoteBillingAddressQuery: any;
  handleShippingAddressDelete: () => void;
  handleBillingAddressDelete: () => void;
  handleQuoteAddressChange: MutationCallback<any, any>;
  locationQuery: any;
  paymentTermsQuery: UseQueryResult<Payment[], unknown>;
  addressSubmitLoading: boolean;
  shippingAddress: ShippingAddressType;
  billingAddress: BillingAddressType;
  updateAddressMutationLoading: boolean;
  hiddenDetailProducts: QuoteItemHiddenDetails[];
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
  onAllProductDetailsHide: (items: QuoteItemHiddenDetails[]) => void;
  isDetailedShippingModal: boolean;
  handleRequestQuoteApproval: () => void;
  actionAfterSubmit?: ACTION_AFTER_SUBMIT;
};

const QuoteContext = createContext<QuoteContextState>(null!);

export default QuoteContext;
