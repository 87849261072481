import { SyntheticEvent, useState } from 'react';

export function useTabs() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  function tabChangeHandler(_event: SyntheticEvent, tabIdx: number) {
    setSelectedTabIndex(tabIdx);
  }

  return { selectedTabIndex, tabChangeHandler };
}
