import React, { useState } from 'react';
import { Address, AddressType } from 'libs/api/common/types';
import { AddressDialog } from './AddressDialog';
import { AddressItem } from './AddressItem';
import { Company } from 'libs/api/iam/types';
import { DeleteAddressDialog } from './DeleteAddressDialog';
import { isBillingAddress, isShippingAddress } from 'libs/api/common/utils';
import {
  useDeleteCompanyShippingAddress,
  useUpdateCompanyBillingAddress,
  useUpdateCompanyShippingAddress,
} from 'libs/api/iam/hooks';

export interface EditableAddressItemProps {
  company: Company;
  address: Address;
  type: AddressType;
  isEditable: boolean;
}

export const EditableAddressItem: React.FC<EditableAddressItemProps> = ({
  company,
  address,
  type,
  isEditable,
}) => {
  const [showEditAddressDialog, setShowEditAddressDialog] = useState(false);
  const [showDeleteAddressDialog, setShowDeleteAddressDialog] = useState(false);
  const updateShippingAddressMutation = useUpdateCompanyShippingAddress();
  const updateBillingAddressMutation = useUpdateCompanyBillingAddress();

  const deleteShippingAddressMutation = useDeleteCompanyShippingAddress({
    onSuccess: () => setShowDeleteAddressDialog(false),
  });

  const handleMakeDefault = () => {
    if (address.id && isShippingAddress(address)) {
      updateShippingAddressMutation.mutate({
        companyId: company.id,
        addressId: address.id,
        address: { ...address, default: true },
      });
    }

    if (address.id && isBillingAddress(address)) {
      updateBillingAddressMutation.mutate({
        companyId: company.id,
        addressId: address.id,
        address: { ...address, default: true },
      });
    }
  };

  const handleDelete = () => {
    if (address.id && isShippingAddress(address)) {
      deleteShippingAddressMutation.mutate({
        companyId: company.id,
        addressId: address.id,
      });
    }
  };

  const isDeletingAddress =
    deleteShippingAddressMutation.isLoading || deleteShippingAddressMutation.isSuccess;
  const isShipping = isShippingAddress(address);

  return (
    <>
      <AddressItem
        address={address}
        type={type}
        onMakeDefault={isEditable ? handleMakeDefault : undefined}
        onEdit={isEditable ? () => setShowEditAddressDialog(true) : undefined}
        onDelete={isEditable && isShipping ? () => setShowDeleteAddressDialog(true) : undefined}
      />
      <AddressDialog
        company={company}
        editedAddress={address}
        type={type}
        open={showEditAddressDialog}
        onClose={() => setShowEditAddressDialog(false)}
      />
      <DeleteAddressDialog
        loading={isDeletingAddress}
        address={address}
        open={showDeleteAddressDialog}
        onClose={() => setShowDeleteAddressDialog(false)}
        onDelete={handleDelete}
      />
    </>
  );
};
