import i18next from 'i18next';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

export const useBanner = () => {
  const dispatch = useDispatch();

  const showSuccessBanner = (message: ReactNode) => {
    dispatch({
      type: 'BANNER_SHOW',
      payload: { message, type: 'success' },
    });
  };

  const showErrorBanner = (message?: ReactNode) => {
    dispatch({
      type: 'BANNER_SHOW',
      payload: { message: message || i18next.t('common.somethingWentWrong'), type: 'error' },
    });
  };

  return {
    showSuccessBanner,
    showErrorBanner,
  };
};
