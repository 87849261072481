import React from 'react';
import { Box } from '@clippings/paper';
import { QuoteInfoLabel } from './QuoteInfoLabel';

export const MorosoExtraPaymentInfo = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      data-testid="extra-payment-info"
      className="extra-payment-info"
    >
      <QuoteInfoLabel bold text="Payment conditions" />
      <QuoteInfoLabel text="Prices are in Euros, net, discounted & ex-works from our Moroso factory in Udine, Italy." />
      <QuoteInfoLabel text="Prices reflect packaging in carton boxes suitable for sea freight." />
      <QuoteInfoLabel text="Order will be put into production once down payment is received." />
      <QuoteInfoLabel
        text="Delivery time is approximately 7 working weeks from the payment receipt EXCLUDING public holidays and
moroso factory closure for winter & summer holidays."
      />
      <QuoteInfoLabel
        text="If you require Airfreight, there is an 8% surcharge which will be added to the total invoice value to cover the cost for wooden
crating."
      />
      <QuoteInfoLabel text="PLEASE NOTE: Moroso does not offer a palletizing service, only wooden crating with the 8% surcharge." />
      <QuoteInfoLabel text="The Pick-up address of the goods: MOROSO / Via Nazionale 60 / 33010 Cavalicco / Udine / ITALY" />
    </Box>
  );
};
