import reducers from './reducers';
import thunk from 'redux-thunk';
import { Reducer, StoreEnhancer, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';

const middleware = [thunk];
let enhancer: StoreEnhancer;

if (process.env.NODE_ENV !== 'production') {
  enhancer = composeWithDevTools(applyMiddleware(...middleware));
} else {
  enhancer = applyMiddleware(...middleware);
}

export type StoreState = ReturnType<typeof reducers>;

const store = (reducers: Reducer<StoreState>) => createStore(reducers, enhancer);

export default store;
