import React from 'react';
import { BackButton, CompanyDetailsTabs, Header } from './components';
import { useGetCompany } from 'libs/api/iam/hooks';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const CompanyDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { data: company } = useGetCompany(Number(companyId || '0'));

  useTitle(company?.name || t('common.companies'));

  if (!company) {
    return null;
  }

  return (
    <>
      <BackButton />
      <Header company={company} />
      <CompanyDetailsTabs company={company} />
    </>
  );
};
