import { AxiosUploadParams, UploadedDocument } from './types';
import { axios } from 'libs/Utils';

export const ALLOWED_FILE_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.txt',
  '.html',
  '.xml',
  '.jpeg',
  '.jpg',
  '.webp',
  '.png',
  '.eps',
];

export const ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED = ALLOWED_FILE_EXTENSIONS.join(', ');
export const ALLOWED_FILE_EXTENSIONS_STRING = ALLOWED_FILE_EXTENSIONS.join(',');

export const MAX_FILE_SIZE_BYTES = 10000000; // 10mb
export const apiPaths = {
  upload: '/api/upload/v1/documents',
};

/**
 * Uploads a document. Allowed formats are: pdf,doc,docx,xls,xlsx,txt,html,xml,jpeg,webp,png and max file size is 10MB
 */
export const uploadDocument = ({ file, onUploadProgress, signal }: AxiosUploadParams) =>
  axios
    .post<UploadedDocument>(apiPaths.upload, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      signal,
    })
    .then(response => response.data);
