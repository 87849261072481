import i18next from 'i18next';
import { yup } from '@clippings/paper';

export const quoteLabelValidationSchema: Record<string, yup.AnySchema> = {
  name: yup.string().max(255, ({ max }) => ({
    key: 'common.lastNameMaxLength',
    params: {
      field: i18next.t('quotes.labelModal.label'),
      charCount: max,
    },
  })),
};
