import React from 'react';
import { Box, Typography, useTranslation } from '@clippings/paper';

export function Title() {
  const { t } = useTranslation();

  return (
    <Box width={320} px={3} py={2}>
      <Typography variant="h5" fontWeight="medium">
        {t('plp.productCatalogue')}
      </Typography>
    </Box>
  );
}
