import React, { ComponentProps, useState } from 'react';
import { Company } from 'libs/api/iam/types';
import { Grid, Skeleton, useFormatPercentage } from '@clippings/paper';
import { InfoBox, InfoLine } from 'libs/shared';
import { PaymentRule } from 'libs/api/common/types';
import { PaymentTermsDialog } from './paymentTermsDialog/PaymentTermsDialog';
import { useGetCompanyPaymentTerms } from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

export interface PaymentTermsInfoProps {
  company: Company;
  isEditable?: boolean;
  boxProps?: ComponentProps<typeof Grid>;
}

export const PaymentTermsInfo: React.FC<PaymentTermsInfoProps> = ({
  company,
  isEditable = true,
  boxProps,
}) => {
  const { t } = useTranslation();
  const { data: paymentTerms } = useGetCompanyPaymentTerms(company.id);
  const [showPaymentTermsDialog, setShowPaymentTermsDialog] = useState(false);
  const formatPercentage = useFormatPercentage();

  const formatDueEvent = (dueEvent: string) =>
    t(`paymentTerms.eventPaymentRuleDue.${dueEvent}` as any, dueEvent);

  const formatPaymentRule = (rule: PaymentRule): string => {
    const percentage = formatPercentage(rule.percentage!);

    switch (rule.type) {
      case 'custom':
        return t('paymentTerms.ruleFormat.custom', {
          percentage,
          dueReason: rule.dueReason,
        });
      case 'event':
        return rule.dueDays === 0
          ? t('paymentTerms.ruleFormat.event_days_zero', {
              percentage,
              dueEvent: formatDueEvent(rule.dueEvent),
            })
          : t('paymentTerms.ruleFormat.event_days_other', {
              percentage,
              dueEvent: formatDueEvent(rule.dueEvent),
              dueDays: rule.dueDays,
            });
      default:
        return '';
    }
  };

  if (!paymentTerms) {
    return <Skeleton height="200px" />;
  }

  return (
    <>
      <InfoBox
        title={t('paymentTerms.paymentTerms')}
        editButtonProps={
          isEditable
            ? {
                onClick: () => setShowPaymentTermsDialog(true),
                'aria-label': 'edit-payment-terms',
              }
            : undefined
        }
        {...boxProps}
      >
        {paymentTerms.map((rule, index, { length }) => (
          <InfoLine
            data-testid="payment-terms-info-paymentNumber"
            key={index}
            label={t('paymentTerms.paymentNumber', { number: index + 1 })}
            value={formatPaymentRule(rule)}
            divider={index !== length - 1}
          />
        ))}
      </InfoBox>
      <PaymentTermsDialog
        company={company}
        originalPaymentTerms={paymentTerms}
        open={showPaymentTermsDialog}
        onClose={() => setShowPaymentTermsDialog(false)}
      />
    </>
  );
};
