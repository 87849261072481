import React from 'react';
import { Box, useTranslation } from '@clippings/paper';
import { CellConfig, SortableTable, SortingProps } from 'libs/shared';
import { Company } from 'libs/api/iam/types';
import { CompanyGridItem } from './CompanyGridItem';
import { EmptyTablePlaceholder } from 'libs/Components';
import { SortDirection } from 'libs/api/common/types';

type CompaniesGridProps = {
  companies: Company[];
  isLoading: boolean;
  hasFilters: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  sort: SortingProps;
  onSort: (sort: { sort?: string; order?: SortDirection }) => void;
  onReset: () => void;
  setCreateCompanyDialogOpen: (open: boolean) => void;
  handleFetchNextPage: () => void;
};

export function CompaniesGrid({
  companies,
  isLoading,
  hasFilters,
  hasNextPage,
  isFetchingNextPage,
  sort,
  onSort,
  onReset,
  setCreateCompanyDialogOpen,
  handleFetchNextPage,
}: CompaniesGridProps) {
  const { t } = useTranslation();
  const isEmpty = companies.length === 0 && !isLoading;

  if (isEmpty) {
    return (
      <Box mt={4}>
        {hasFilters ? (
          <EmptyTablePlaceholder
            onButtonClick={onReset}
            title={t('common.noResults')}
            buttonTitle={t('common.reset')}
            containerProps={{ 'data-testid': 'empty-table-placeholder-no-companies-reset' }}
          />
        ) : (
          <EmptyTablePlaceholder
            onButtonClick={() => setCreateCompanyDialogOpen(true)}
            title={t('companies.noAddedCompanies')}
            buttonTitle={t('forms.createCompany')}
            containerProps={{ 'data-testid': 'empty-table-placeholder-no-companies' }}
          />
        )}
      </Box>
    );
  }

  return (
    <SortableTable
      cellConfig={cellConfig}
      hasNextPage={!!hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      tableHeaderColumns={5}
      noMoreLabel={t('companies.noMoreCompanies')}
      onFetchNextPage={handleFetchNextPage}
      rowHeight={80}
      sort={sort}
      onSort={onSort}
      data-testid="sa-company-table"
    >
      {companies.map(company => (
        <CompanyGridItem key={company.id} company={company} />
      ))}
    </SortableTable>
  );
}

export const cellConfig: CellConfig[] = [
  {
    key: 'sa-companies-table-header-company-name',
    columnName: 'name',
    label: 'common.company',
    columns: 1,
  },
  {
    key: 'sa-companies-table-header-company-contacts',
    columnName: 'phone',
    label: 'common.contacts',
    columns: 1,
  },
  {
    key: 'sa-companies-table-header-contacts',
    columnName: 'type',
    label: 'common.type',
    columns: 1,
  },
  {
    key: 'sa-companies-table-header-created-on',
    columnName: 'createdAt',
    label: 'common.createdOn',
    columns: 1,
  },
  {
    key: 'sa-companies-table-header-status',
    columnName: 'status',
    label: 'common.status',
    columns: 1,
  },
];
