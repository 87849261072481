import React, { FC } from 'react';
import { Box, Button, Image, useTheme } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export interface ErrorLayoutProps extends React.PropsWithChildren {
  to: string;
}

export const ErrorLayout: FC<ErrorLayoutProps> = ({ children, to }) => {
  const { t } = useTranslation();
  const { images } = useTheme();

  return (
    <Box display="flex" height="100%" maxWidth="1120px" px={3} m="auto">
      <Box width="50%" display="flex" flexDirection="column" justifyContent="center">
        <Box>
          {children}
          <Button variant="outlined" sx={{ mt: 3 }} href={to} data-testid="go-home-button">
            {t('common.goToHomepage')}
          </Button>
        </Box>
      </Box>
      <Box width="50%">
        <Image
          sx={{ objectFit: 'contain', width: '100%', height: '100%' }}
          src={images.error}
          alt=""
        />
      </Box>
    </Box>
  );
};
