import React, { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { isEmpty } from 'lodash';

type PaymentProviderContextType = {
  instance: Promise<Stripe | null>;
};

const PaymentProviderContext = createContext<PaymentProviderContextType>(
  {} as PaymentProviderContextType
);

type PaymentProviderProps = PropsWithChildren<Pick<PaymentProviderContextType, 'instance'>>;

export function PaymentProvider({ instance, children }: PaymentProviderProps) {
  const value = useMemo(() => ({ instance }), [instance]);

  return (
    <PaymentProviderContext.Provider value={value}>{children}</PaymentProviderContext.Provider>
  );
}

export function usePayment() {
  const value = useContext(PaymentProviderContext);

  if (isEmpty(value)) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }

  return value;
}
