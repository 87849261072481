import React from 'react';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { Box, CircularProgress, GuestRegisterForm } from '@clippings/paper';
import { PromotionPanel } from '../PromotionPanel';
import { useGuestRegister } from 'libs/Authentication/hooks/useGuestRegister';

export const GuestRegister = () => {
  const {
    activeStep,
    handleSubmit,
    handleSignInClick,
    handleBackToBrowseClick,
    guestRegisterMutation,
    formErrors,
    currentUser,
  } = useGuestRegister();

  const renderContent = () => {
    if (!currentUser) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress data-testid="loading-indicator" />
        </Box>
      );
    }

    return (
      <GuestRegisterForm
        loading={guestRegisterMutation.isLoading || guestRegisterMutation.isSuccess}
        activeStep={activeStep}
        serverErrors={formErrors}
        onSubmit={handleSubmit}
        onSignInClick={handleSignInClick}
        onBackToBrowseClick={handleBackToBrowseClick}
        defaultValues={{
          email: currentUser.email,
        }}
      />
    );
  };

  return <AuthenticationLayout content={renderContent()} sidePanel={<PromotionPanel />} />;
};
