import React, { ComponentProps, ReactNode, useState } from 'react';
import { TableCell } from '@clippings/paper';

export type TableCellMode = 'view' | 'edit';

type TableCellWithModeProps = {
  children: ({ mode }: { mode: TableCellMode }) => ReactNode;
  active?: boolean;
} & Omit<ComponentProps<typeof TableCell>, 'onClick' | 'children'>;

export function TableCellWithMode({ active = false, children, ...rest }: TableCellWithModeProps) {
  const [mode, setMode] = useState<TableCellMode>('view');

  function mouseEnterHandler() {
    if (mode === 'edit') {
      return;
    }

    setMode('edit');
  }

  let props = {};

  if (active) {
    props = {
      onMouseEnter: mouseEnterHandler,
    };
  }

  return (
    <TableCell {...props} {...rest}>
      {children({ mode })}
    </TableCell>
  );
}
