import React, { PropsWithChildren } from 'react';
import {
  ThemeProvider as PaperThemeProvider,
  StyledEngineProvider,
  getTheme,
} from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers';

const themeConfig: any = {
  typography: {
    fontFamily: 'Roboto, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
};

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { brand } = useAppConfiguration();
  const theme = getTheme(brand, themeConfig);

  return (
    <PaperThemeProvider theme={theme} loadFonts>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </PaperThemeProvider>
  );
};
