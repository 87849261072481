import React from 'react';
import { Backdrop, CircularProgress } from '@clippings/paper';
import { useQuoteCheckoutContext } from '../providers';

export function CheckoutLoading() {
  const { loading } = useQuoteCheckoutContext();

  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress />
    </Backdrop>
  );
}
