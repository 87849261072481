import React, { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StatusCodes } from 'http-status-codes';
import { routes } from 'libs/Constants';
import { useBanner } from 'libs/Components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface ReactQueryProviderProps {
  client: QueryClient;
}

export const ReactQueryProvider: FC<PropsWithChildren<ReactQueryProviderProps>> = ({
  client,
  children,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showErrorBanner } = useBanner();

  const handleQueryError = (error: any) => {
    if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
      navigate(routes.authentication.login);
      return;
    }

    if (error?.response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      navigate(routes.genericError, { replace: true, state: { redirectedByErrorHandling: true } });
      return;
    }

    if (error?.response?.status === StatusCodes.FORBIDDEN) {
      navigate(routes.accessDenied, { replace: true, state: { redirectedByErrorHandling: true } });
    }
  };

  const handleMutationError = (error: any) => {
    if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
      navigate(routes.authentication.login);
      return;
    }

    if (error?.response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      showErrorBanner(t('common.cannotProcessYourRequestAtTheMoment'));
      return;
    }

    if (error?.response?.status === StatusCodes.FORBIDDEN) {
      showErrorBanner(t('common.accessDenied'));
    }
  };

  client.getQueryCache().config.onError = handleQueryError;
  client.getMutationCache().config.onError = handleMutationError;

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
