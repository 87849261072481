import React from 'react';
import { Box, Tab, Tabs } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

interface ShippingTabsProps {
  onClick: (action: string) => void;
  value: number;
}

export const ShippingTabs: React.FC<ShippingTabsProps> = ({ onClick, value }) => {
  const { t } = useTranslation();

  const handleTabChange = (clickedValue: number) => {
    if (value === clickedValue) {
      return;
    }

    onClick('noop');
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '16px' }}>
      <Tabs value={value} aria-label="shipping tabs">
        <Tab
          data-testid="address-tab"
          label={t('common.address')}
          sx={{ flexGrow: 1 }}
          onClick={() => handleTabChange(0)}
        />
        <Tab
          label={t('quotes.addresses.customerPickup')}
          sx={{ flexGrow: 1 }}
          onClick={() => handleTabChange(1)}
          data-testid="customer-pickup-tab"
        />
      </Tabs>
    </Box>
  );
};
