import React from 'react';
import { CommonPaymentRuleCellProps } from '../../paymentTerms.types';
import { DatePaymentRule } from 'libs/api/common/types';
import { DatePicker } from '@clippings/paper';
import { getTomorrow } from '../../utils';
import { isValid } from 'date-fns';

type DatePaymentRuleCellProps = CommonPaymentRuleCellProps<DatePaymentRule>;

export function DatePaymentRuleCell({
  paymentRule,
  onChange,
  'data-testid': dataTestId,
}: DatePaymentRuleCellProps) {
  const tomorrow = getTomorrow();
  const initialDate = paymentRule.dueDate ? new Date(paymentRule.dueDate) : tomorrow;

  function changeHandler(value: Date) {
    const isDateValid = isValid(value);

    onChange(isDateValid ? value.toISOString() : null);
  }

  return (
    <DatePicker
      minDate={tomorrow}
      initialValue={initialDate}
      onChange={changeHandler}
      disablePast
      inputProps={{
        ...inputPropsDefault,
        'data-testid': dataTestId,
      }}
    />
  );
}

const inputPropsDefault = {
  fullWidth: true,
  variant: 'standard',
  sx: {
    padding: '0!important',
    '.MuiInputBase-root:before': {
      border: 'none!important',
    },
    '.MuiInputBase-root:after': {
      border: 'none!important',
    },
    '.MuiInputBase-input': {
      padding: 0,
    },
  },
};
