import React from 'react';
import { Catalogue } from 'libs/api/catalogue/types';
import { CellSelectInput, MenuItem, Typography } from '@clippings/paper';

export interface CatalogueSelectProps {
  catalogues: Catalogue[];
  value: string;
  onChange: (value: string) => void;
}

export const CatalogueSelect: React.FC<CatalogueSelectProps> = ({
  catalogues,
  value,
  onChange,
}) => (
  <CellSelectInput value={value} onChange={onChange}>
    {catalogues.map(catalogue => (
      <MenuItem key={catalogue.id} value={catalogue.slug}>
        <Typography textTransform="capitalize">{catalogue.name}</Typography>
      </MenuItem>
    ))}
  </CellSelectInput>
);
