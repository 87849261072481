import React from 'react';
import { AccountBalanceIcon, CreditCardIcon, FormControlLabel, Radio } from '@clippings/paper';
import { PaymentMethod } from 'libs/api/payment/types';
import { PaymentMethodInfo } from '../../utils/paymentMethod';
import { useTranslation } from 'react-i18next';

export const PaymentRadioLabel: React.FC<{
  type: PaymentMethod;
  selected: PaymentMethod;
  disabled?: boolean;
}> = ({ type, selected, disabled }) => {
  const { t } = useTranslation();

  const style =
    type === selected
      ? {
          border: '1px solid',
          borderColor: 'primary.main',
        }
      : {
          backgroundColor: 'grey.100',
          color: 'grey.500',
        };

  return (
    <FormControlLabel
      value={type}
      control={<Radio data-testid={`radio-button-${type}`} />}
      disabled={disabled && type !== selected}
      sx={{
        flexGrow: 1,
        display: 'flex',
        marginY: 1,
        marginX: 0,
        borderRadius: 1,
        pointerEvents: disabled ? 'none' : 'auto',
        ...style,
        '.MuiFormControlLabel-label': {
          display: 'flex',
          flexGrow: 1,
          padding: 1,
          gap: 1,
        },
      }}
      label={
        <>
          {IconMap[type]}
          <span>{t(PaymentMethodInfo[type].label)}</span>
        </>
      }
    />
  );
};

const IconMap = {
  [PaymentMethod.BankTransfer]: <AccountBalanceIcon fontSize={'small'} />,
  [PaymentMethod.Stripe]: <CreditCardIcon fontSize={'small'} />,
};
