import React, { FC } from 'react';
import { Skeleton } from '@clippings/paper';
import './QuoteTableRow/QuoteTableRow.scss';

export interface QuotesTableRowSkeletonProps {
  height?: number;
}

export const QuotesTableRowSkeleton: FC<QuotesTableRowSkeletonProps> = ({ height = 75 }) => (
  <Skeleton
    height={height}
    variant="rectangular"
    sx={{
      width: 'calc(100% + 8px)',
      marginLeft: '-8px',
    }}
    data-testid="sales-quotes-table-row-skeleton"
  />
);
