import React from 'react';
import { PaymentRulePercentageCellRenderer } from './cells/PaymentRulePercentageCellRenderer';
import { PaymentRuleRowCommonProps, PaymentRulesTypes } from '../paymentTerms.types';
import { PaymentRuleTableRow } from './PaymentRuleTableRow';
import { PaymentRuleTypeCellRenderer } from './cells/PaymentRuleTypeCellRenderer';
import { PaymentRuleValueCellRenderer } from './cells/PaymentRuleValueCellRenderer';
import { RowActionsPanel } from 'libs/shared';

type PaymentRuleRowProps = { types: PaymentRulesTypes } & PaymentRuleRowCommonProps;

export const PaymentRuleRow: React.FC<PaymentRuleRowProps> = ({ types, ...rest }) => {
  const { index, onChange } = rest;

  const handleDelete = () => onChange(null);

  return (
    <PaymentRuleTableRow idx={index}>
      {anchorElement => (
        <>
          <PaymentRulePercentageCellRenderer {...rest} />
          <PaymentRuleTypeCellRenderer types={types} {...rest} />
          <PaymentRuleValueCellRenderer {...rest} />
          <RowActionsPanel anchorElement={anchorElement} onDelete={handleDelete} />
        </>
      )}
    </PaymentRuleTableRow>
  );
};
