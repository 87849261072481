import React, { useState } from 'react';
import {
  Box,
  CellInput,
  CellPopover,
  CellRangeInput,
  TableCell,
  Typography,
  convertTimeRange,
  convertWeeksToDays,
  useFormatLeadTime,
} from '@clippings/paper';
import { LeadTime } from '../../api/common/types';
import { parseTimeRange } from './quoteUtils';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

interface LeadTimeCellProps {
  leadTime: [number, number] | null;
  onBlur?: (valueArray: LeadTime) => void;
  disabled?: boolean;
  className?: string;
}

const examples = ['0', '2', '3-3', '6-8', '12-24'];

const LeadTimeInfoLabel: React.FC = () => {
  const { leadTimeFormat } = useAppConfiguration();
  const formatLeadTime = useFormatLeadTime();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography>{t('quotes.table.leadTimeHint')}</Typography>
      <ul>
        {examples.map((example: string) => {
          return (
            <li key={example}>
              <Typography>
                <>
                  {/* eslint-disable-next-line react/jsx-no-literals */}
                  <strong>"{example}"</strong> - (
                  {formatLeadTime(parseTimeRange(example), leadTimeFormat)})
                </>
              </Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export const LeadTimeCell: React.FC<LeadTimeCellProps> = ({
  leadTime,
  onBlur,
  disabled = false,
  className,
}) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const { leadTimeFormat } = useAppConfiguration();
  const formatLeadTime = useFormatLeadTime();
  const convertedLeadTime = convertTimeRange(leadTime, 'days', leadTimeFormat);

  const parsedLeadTime = formatLeadTime(convertedLeadTime, leadTimeFormat);
  const [lastLeadTimeText, setLastLeadTimeText] = useState(parsedLeadTime);
  const [textValue, setTextValue] = useState(parsedLeadTime);

  if (parsedLeadTime !== lastLeadTimeText) {
    setTextValue(parsedLeadTime);
    setLastLeadTimeText(parsedLeadTime);
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      setTextValue(parsedLeadTime);
      setIsBeingEdited(false);
      return;
    }

    const timeRange = parseTimeRange(value);
    let timeValueInDays;

    // Convert to days for the API call if the format is `weeks`
    if (leadTimeFormat === 'weeks') {
      timeValueInDays = convertWeeksToDays(timeRange);
    }

    onBlur?.(timeValueInDays ?? timeRange);
    setTextValue(formatLeadTime(timeRange, leadTimeFormat));
    setIsBeingEdited(false);
  };

  const cell = isBeingEdited ? (
    <CellRangeInput
      inputProps={{
        sx: { marginTop: '9px', textAlign: 'right' },
        'data-testid': 'lead-time-number-input',
      }}
      autoComplete="off"
      onBlur={handleBlur}
      forcedValue={convertedLeadTime?.join('-') ?? ''}
      autoFocus
    />
  ) : (
    <CellInput
      disabled={disabled}
      inputProps={{ sx: { marginTop: '9px' }, 'data-testid': 'lead-time-text-input' }}
      onFocus={() => setIsBeingEdited(true)}
      forcedValue={textValue}
    />
  );

  return (
    <CellPopover infoLabel={<LeadTimeInfoLabel />}>
      <TableCell className={className} sx={{ width: '120px' }}>
        {cell}
      </TableCell>
    </CellPopover>
  );
};
