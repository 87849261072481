import React, { useImperativeHandle, useMemo, useState } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@clippings/paper';
import { FilterSection } from './FilterSection';
import { leadTimeOptionsDays, leadTimeOptionsWeeks } from '../../../common/constants';
import { useAppConfiguration } from 'libs/providers';
import {
  useClearRefinements,
  useNumericMenu,
  useToggleRefinement,
} from 'react-instantsearch-hooks-web';
import { useTranslation } from 'react-i18next';

const daysInAWeek = 7;

export interface LeadTimeFilterRef {
  reset: () => void;
}

export interface LeadTimeFilterProps {
  catalogueSlug: string;
  onApply: (filter: string | null) => void;
}

export const LeadTimeFilter = React.forwardRef<LeadTimeFilterRef, LeadTimeFilterProps>(
  ({ catalogueSlug, onApply }, ref) => {
    const { leadTimeFormat } = useAppConfiguration();
    const leadTimeOptions = leadTimeFormat === 'days' ? leadTimeOptionsDays : leadTimeOptionsWeeks;

    const { t } = useTranslation();
    const { refine: refineByInStock } = useToggleRefinement({
      attribute: `inStock.${catalogueSlug}`,
      on: true,
    });

    const { items, refine: refineByMaxLeadTime } = useNumericMenu({
      attribute: `leadTime.${catalogueSlug}.max`,
      items: leadTimeOptions.map(option => {
        const value = leadTimeFormat === 'weeks' ? option * daysInAWeek : option;
        return {
          label: String(option),
          end: value,
        };
      }),
    });

    const { refine: clearInStockRefinement } = useClearRefinements({
      includedAttributes: [`inStock.${catalogueSlug}`],
    });
    const { refine: clearMaxLeadTimeRefinement } = useClearRefinements({
      includedAttributes: [`leadTime.${catalogueSlug}.max`],
    });
    const [selectedRadio, setSelectedRadio] = useState<string | null>(null);

    const itemValuesByLabel: Record<string, string> = useMemo(() => {
      return items.reduce(
        (acc, item) => ({
          ...acc,
          [item.label]: item.value,
        }),
        {}
      );
    }, [items]);

    useImperativeHandle(ref, () => ({
      reset: () => {
        setSelectedRadio(null);
        clearInStockRefinement();
        clearMaxLeadTimeRefinement();
        onApply(null);
      },
    }));

    const handleRadioChange = (value: string) => {
      setSelectedRadio(value);
      // When Dobri wrote this code, only God and him knew how it worked
      // Now, only God knows it :(
      if (value === 'in-stock') {
        refineByInStock({ isRefined: false });
        clearMaxLeadTimeRefinement();
        onApply(t('common.inStock'));
      } else if (value) {
        refineByMaxLeadTime(itemValuesByLabel[value]);
        refineByInStock({ isRefined: true });
        const label = getLabelForLeadTime(value);

        onApply(label);
      }
    };

    const getLabelForLeadTime = (value: string) => {
      return leadTimeFormat === 'weeks'
        ? t('common.upToWeeks', { weeks: value })
        : t('common.upToDays', { days: value });
    };

    return (
      <FilterSection label={t('common.estimatedLeadTime')}>
        <Box px={2}>
          <RadioGroup
            sx={{
              '& .MuiRadio-root': {
                p: 1,
              },
              '& span.MuiFormControlLabel-label': {
                fontSize: theme => theme.typography.pxToRem(14),
                fontWeight: 'regular',
              },
            }}
            value={selectedRadio}
            onChange={(_event, value) => handleRadioChange(value)}
          >
            <FormControlLabel
              value="in-stock"
              label={t('common.inStock')}
              control={<Radio />}
              data-testid="lead-time-option-in-stock"
            />
            {items.map(item => {
              const label = getLabelForLeadTime(item.label);

              return (
                <FormControlLabel
                  key={item.label}
                  value={item.label}
                  label={label}
                  control={<Radio />}
                  data-testid={`lead-time-option-${item.label}-days`}
                />
              );
            })}
          </RadioGroup>
        </Box>
      </FilterSection>
    );
  }
);
