import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  ALLOWED_FILE_EXTENSIONS,
  ALLOWED_FILE_EXTENSIONS_STRING,
  ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED,
  MAX_FILE_SIZE_BYTES,
} from 'libs/api/upload/actions';
import {
  AttachFileIcon,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  UploadButton,
  UploadedFile,
  getFileExtension,
} from '@clippings/paper';
import { ComponentGridContainer } from 'libs/shared';
import { ComponentHeader } from './ComponentHeader';
import { Nullable } from 'libs/Utils';
import { SalesOrderDocumentType } from 'libs/api/order/types';
import { uniqueId } from 'lodash';
import { useBanner } from 'libs/Components';
import { useQuoteCheckoutContext } from '../providers';
import { useTranslation } from 'react-i18next';
import { useUploadCheckoutDocument } from 'libs/api/upload/hooks';

enum PurchaseOrderChoice {
  Has = 'has_po',
  No = 'no_po',
}

export const PurchaseOrder: React.FC = ({ ...rest }) => {
  const {
    setData,
    data,
    hasPONumber,
    setHasPONumber,
    setPODocument,
    setIsUploadingPODocument,
    isUploadingPODocument,
    isPoDocumentRequired,
  } = useQuoteCheckoutContext();
  const { showErrorBanner } = useBanner();
  const { t } = useTranslation();

  const uploadInputRef = useRef<Nullable<HTMLInputElement>>(null);
  const [uploadedDocument, setUploadedDocument] = useState<Nullable<File>>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasPONumber(e.target.value === PurchaseOrderChoice.Has);
  };

  const handlePONumberChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setData({ poNumber: e.target.value });

  const { progress, mutate, reset, setProgress } = useUploadCheckoutDocument({
    onMutate: () => setIsUploadingPODocument(true),
    onSuccess: ({ fileName, downloadUrl }) => {
      setPODocument({
        type: SalesOrderDocumentType.PURCHASE_ORDER,
        fileName,
        downloadUrl,
      });
    },
    onSettled: () => {
      setIsUploadingPODocument(false);
    },
  });

  const handleUploadedDocumentRemove = async () => {
    if (uploadInputRef.current?.files?.length) {
      uploadInputRef.current.value = '';
    }

    if (isUploadingPODocument) {
      reset();
    }

    setUploadedDocument(null);
    setIsUploadingPODocument(false);
    setPODocument(null);
    setProgress(0);
  };

  const onFileUploadHandler = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        const file: File = event.target.files[0];
        const extension = getFileExtension(file.name);

        if (
          file.size > MAX_FILE_SIZE_BYTES ||
          !extension ||
          !ALLOWED_FILE_EXTENSIONS.includes(extension)
        ) {
          handleUploadedDocumentRemove();
          showErrorBanner(
            t('checkout.purchaseOrder.uploadValidationError', {
              formats: ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED,
              size: '10MB',
            })
          );
          return;
        }

        setUploadedDocument(file);
        mutate({ file, id: uniqueId() });
      }
    },
    [showErrorBanner]
  );

  useEffect(() => {
    if (!hasPONumber && isUploadingPODocument && !isPoDocumentRequired) {
      handleUploadedDocumentRemove(); // unblock user from continuing with checkout if a document upload is in progress
    }
  }, [hasPONumber]);

  return (
    <ComponentGridContainer {...rest}>
      <ComponentHeader
        title={t('checkout.purchaseOrder.title')}
        description={t('checkout.purchaseOrder.description')}
      />
      <Grid item>
        <FormControl>
          <RadioGroup
            aria-labelledby="purchase-order-radio-buttons-group-label"
            defaultValue={PurchaseOrderChoice.Has}
            name="purchase-order-radio-buttons-group"
            value={hasPONumber ? PurchaseOrderChoice.Has : PurchaseOrderChoice.No}
            onChange={handleChange}
          >
            {!isPoDocumentRequired ? (
              <FormControlLabel
                value={PurchaseOrderChoice.Has}
                control={<Radio />}
                label={t('checkout.purchaseOrder.number.hasChoice')}
              />
            ) : null}
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="baseline"
              sx={{ marginLeft: isPoDocumentRequired ? 0 : 4, marginBottom: 2 }}
            >
              <Box display="flex" flexDirection="column">
                <TextField
                  sx={styles.inputSx}
                  color="secondary"
                  name="purchase-order-number-input"
                  variant="outlined"
                  size="small"
                  label={t('checkout.purchaseOrder.number.inputLabel')}
                  inputProps={{ 'data-testid': 'purchase-order-number-input' }}
                  disabled={!hasPONumber}
                  onChange={handlePONumberChange}
                  value={data.poNumber}
                  error={hasPONumber && !data.poNumber}
                />
                {uploadedDocument && (
                  <UploadedFile
                    data-testid={`uploaded-po-file-${uploadedDocument.name}`}
                    containerProps={{
                      sx: { mt: 1, backgroundColor: (theme: Theme) => theme.palette.grey[50] },
                    }}
                    fileName={uploadedDocument.name}
                    fileSize={uploadedDocument.size}
                    progressProps={{ value: progress }}
                    onRemove={handleUploadedDocumentRemove}
                    uploaded={!!data.poDocument}
                  />
                )}
              </Box>
              <Box
                display="flex"
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
                gap={{ xs: 1, md: 0 }}
                justifyContent="center"
                alignItems={{ xs: 'flex-start', md: 'center' }}
                alignSelf="baseline"
              >
                <Typography
                  data-testid="po-upload-and"
                  mt={{ xs: 1, md: 0 }}
                  marginX={1}
                  sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                  }}
                >
                  {t('checkout.purchaseOrder.and')}
                </Typography>
                <UploadButton
                  ref={uploadInputRef}
                  type="button"
                  inputProps={{
                    accept: ALLOWED_FILE_EXTENSIONS_STRING,
                    onChange: onFileUploadHandler,
                  }}
                  buttonProps={{
                    disabled: !hasPONumber || isUploadingPODocument || !!uploadedDocument,
                    'data-testid': 'po-upload-button',
                    variant: 'outlined',
                    color: isPoDocumentRequired && !uploadedDocument ? 'error' : 'secondary',
                    startIcon: <AttachFileIcon />,
                  }}
                >
                  {t('common.attachFile')}
                </UploadButton>
                <Box display="flex" flexDirection="column" ml={1}>
                  <Typography
                    sx={{
                      fontSize: theme => theme.typography.pxToRem(12),
                      color: theme => theme.palette.secondary.main,
                    }}
                  >
                    {t('common.fileLimitUpToMb', { limit: 10 })}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: theme => theme.typography.pxToRem(12),
                      color: theme => theme.palette.grey[500],
                    }}
                  >
                    {/* eslint-disable-next-line react/jsx-no-literals */}
                    {`(${ALLOWED_FILE_EXTENSIONS_STRING_FORMATTED})`}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {!isPoDocumentRequired ? (
              <FormControlLabel
                value={PurchaseOrderChoice.No}
                control={<Radio />}
                label={t('checkout.purchaseOrder.number.noChoice')}
              />
            ) : null}
          </RadioGroup>
        </FormControl>
      </Grid>
    </ComponentGridContainer>
  );
};

const styles = {
  inputSx: {
    width: {
      xs: '100%',
      md: '300px',
    },
  },
};
