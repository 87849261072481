import { Brand } from '@clippings/paper';

export enum AddressField {
  ShippingCompanyName = 'shippingCompanyName',
  Attention = 'attention',
  Address = 'address',
  Address2 = 'address2',
  City = 'city',
  Province = 'province',
  State = 'state',
  Zip = 'zip',
  Country = 'country',
  ContactName = 'contactName',
  Phone = 'phone',
  TrackingNotificationEmailAddress = 'trackingNotificationEmailAddress',
  LocationType = 'locationType',
  LocationTypeOther = 'locationTypeOther',
  Dock = 'dock',
  AccessDetails = 'accessDetails',
  Nickname = 'nickname',
  CompanyName = 'companyName',
  VatNumber = 'vatNumber',
  BillingEmail = 'billingEmail',
  Email = 'email',
}

type FieldsConfig = Record<Brand, Array<AddressField>>;
export const shippingAddressFieldsConfig: FieldsConfig = {
  [Brand.Fomcore]: [
    AddressField.ShippingCompanyName,
    AddressField.Attention,
    AddressField.Address,
    AddressField.Address2,
    AddressField.City,
    AddressField.Province,
    AddressField.State,
    AddressField.Zip,
    AddressField.Country,
    AddressField.ContactName,
    AddressField.Phone,
    AddressField.TrackingNotificationEmailAddress,
    AddressField.LocationType,
    AddressField.LocationTypeOther,
    AddressField.Dock,
    AddressField.AccessDetails,
    AddressField.Nickname,
  ],
  [Brand.Sossego]: [
    AddressField.Country,
    AddressField.Zip,
    AddressField.State,
    AddressField.Province,
    AddressField.City,
    AddressField.Address,
    AddressField.ContactName,
    AddressField.Phone,
    AddressField.AccessDetails,
    AddressField.Nickname,
  ],
  [Brand.Moroso]: [
    AddressField.Country,
    AddressField.Zip,
    AddressField.State,
    AddressField.Province,
    AddressField.City,
    AddressField.Address,
    AddressField.ContactName,
    AddressField.Phone,
    AddressField.AccessDetails,
    AddressField.Nickname,
  ],
};

export const billingAddressFieldsConfig: FieldsConfig = {
  [Brand.Fomcore]: [
    AddressField.Address,
    AddressField.City,
    AddressField.State,
    AddressField.Province,
    AddressField.Zip,
    AddressField.Country,
    AddressField.ContactName,
    AddressField.CompanyName,
    AddressField.VatNumber,
    AddressField.BillingEmail,
  ],
  [Brand.Sossego]: [
    AddressField.Country,
    AddressField.Zip,
    AddressField.State,
    AddressField.Province,
    AddressField.City,
    AddressField.Address,
    AddressField.ContactName,
    AddressField.CompanyName,
    AddressField.VatNumber,
    AddressField.BillingEmail,
  ],
  [Brand.Moroso]: [
    AddressField.Country,
    AddressField.Zip,
    AddressField.State,
    AddressField.Province,
    AddressField.City,
    AddressField.Address,
    AddressField.ContactName,
    AddressField.CompanyName,
    AddressField.VatNumber,
    AddressField.BillingEmail,
  ],
};

export const companyAddressFieldsConfig: FieldsConfig = {
  [Brand.Fomcore]: [
    AddressField.Address,
    AddressField.Address2,
    AddressField.City,
    AddressField.State,
    AddressField.Province,
    AddressField.Zip,
    AddressField.Country,
    AddressField.ContactName,
  ],
  [Brand.Sossego]: [
    AddressField.Country,
    AddressField.Zip,
    AddressField.State,
    AddressField.Province,
    AddressField.City,
    AddressField.Address,
    AddressField.ContactName,
  ],
  [Brand.Moroso]: [
    AddressField.Country,
    AddressField.Zip,
    AddressField.State,
    AddressField.Province,
    AddressField.City,
    AddressField.Address,
    AddressField.ContactName,
  ],
};

export const addressFieldsConfigMap = {
  shipping: shippingAddressFieldsConfig,
  billing: billingAddressFieldsConfig,
  company: companyAddressFieldsConfig,
};
