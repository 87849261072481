import { Brand, getAddressValidation, getCompanyValidation, yup } from '@clippings/paper';

export const getValidationSchemas = (brand: Brand | null, detailed = false) => {
  const companyValidation = getCompanyValidation(brand);
  const addressValidationSchema = getAddressValidation(brand, detailed);

  const shippingAddressValidationSchema = {
    country: addressValidationSchema.country,
    state: addressValidationSchema.state,
    city: addressValidationSchema.city,
    zip: addressValidationSchema.zipCode,
    province: addressValidationSchema.province,
    address: addressValidationSchema.address,
    address2: addressValidationSchema.address2,
    contactName: addressValidationSchema.contactName,
    default: yup.bool().optional(),
    phone: addressValidationSchema.phone,
    accessDetails: addressValidationSchema.accessDetails,
    nickname: addressValidationSchema.nickname,
    ...('metadata' in addressValidationSchema && {
      metadata: addressValidationSchema.metadata,
    }),
  };

  const billingAddressValidationSchema = {
    address: addressValidationSchema.address,
    address2: addressValidationSchema.address2,
    city: addressValidationSchema.city,
    province: addressValidationSchema.province,
    zip: addressValidationSchema.zipCode,
    country: addressValidationSchema.country,
    state: addressValidationSchema.state,
    contactName: addressValidationSchema.contactName,
    default: yup.bool().optional(),
    saveAddress: yup.bool().optional(),
    companyName: companyValidation.name,
    vatNumber: companyValidation.taxNumber,
    email: addressValidationSchema.email,
  };

  const customerPickupValidationSchema = {
    phone: addressValidationSchema.phone.required('common.phoneRequired').trim(),
    contactName: addressValidationSchema.contactName.required('common.contactNameRequired').trim(),
    email: addressValidationSchema.email.required('common.emailRequired').trim(),
  };

  return {
    shippingAddressValidationSchema,
    billingAddressValidationSchema,
    customerPickupValidationSchema,
  };
};

export const shippingAddressListValidationSchema = {
  shippingAddress: yup.object().shape({ id: yup.number().required() }).required(),
};

export const billingAddressListValidationSchema = {
  billingAddress: yup.object().shape({ id: yup.number().required() }).required(),
};

export const deliveryMethodValidationSchema = {
  deliveryRef: yup.string().required(),
};
