import React from 'react';
import { AddQuoteItemCatalogueProduct } from 'libs/api/quotes/types';
import { ConfiguredProduct } from '../../../SalesApp/Quotes/common/types';
import { GroupedProduct, PDPConfiguratorOverlay, useLeadTimeVisibility } from '@clippings/paper';
import { useAppConfiguration } from 'libs/providers';
import {
  useGetGroupedProducts,
  useGetProduct,
  useGetProductDiscount,
  useGetProductOptions,
  useGetProductRules,
} from 'libs/api/catalogue/hooks';
import { voidFn } from 'libs/shared';

export interface PDPContainerProps {
  configuredProduct: ConfiguredProduct | null;
  catalogueSlug: string;
  submitting: boolean;
  onClose: () => void;
  onAddToQuote: (payload: AddQuoteItemCatalogueProduct) => void;
  onChangeProduct: (product: ConfiguredProduct) => void;
  onAddSample: (id: number) => void;
}

export const PDPContainer: React.FC<PDPContainerProps> = ({
  configuredProduct,
  catalogueSlug,
  submitting,
  onClose,
  onAddToQuote,
  onChangeProduct,
  onAddSample,
}) => {
  const { instance, leadTimeFormat, is3dViewerEnabled } = useAppConfiguration();
  const { shouldRenderLeadTimeFields } = useLeadTimeVisibility(instance);
  const productId = configuredProduct?.productId;
  const existingSelection = configuredProduct?.existingSelection;
  const existingQuantity = configuredProduct?.existingQuantity;

  const productQuery = useGetProduct(catalogueSlug, productId!, {
    enabled: Boolean(productId),
  });

  const optionsQuery = useGetProductOptions(catalogueSlug, productId!, {
    enabled: Boolean(productId),
  });

  const rulesQuery = useGetProductRules(catalogueSlug, productId!, {
    enabled: Boolean(productId),
  });

  const discountQuery = useGetProductDiscount(catalogueSlug, productId!, {
    enabled: Boolean(productId),
  });

  const productGroupId = productQuery.data?.productGroupId;
  const shouldFetchGroupedProducts =
    Boolean(configuredProduct?.showGroupedProducts) && Boolean(productGroupId);

  const groupedProductsQuery = useGetGroupedProducts(catalogueSlug, productGroupId!, {
    enabled: shouldFetchGroupedProducts,
  });

  const loading =
    productQuery.isLoading ||
    optionsQuery.isLoading ||
    rulesQuery.isLoading ||
    discountQuery.isLoading ||
    !configuredProduct ||
    (shouldFetchGroupedProducts && groupedProductsQuery.isLoading);

  const handleProductChange = (product: GroupedProduct) => {
    onChangeProduct({
      isExisting: configuredProduct?.isExisting,
      quoteItemId: configuredProduct?.quoteItemId,
      productId: product.id,
      productName: product.name,
      showGroupedProducts: configuredProduct?.showGroupedProducts,
    });
  };

  const product = productQuery.data && {
    ...productQuery.data,
    defaultSelection: existingSelection ?? productQuery.data.defaultSelection,
  };

  return (
    <PDPConfiguratorOverlay
      instance={instance}
      loading={loading}
      productsList={groupedProductsQuery.data}
      isOpen={Boolean(configuredProduct)}
      isSignedIn
      showAvailability
      editingMode={Boolean(existingSelection)}
      initialQuantity={existingQuantity}
      leadTimeFormat={leadTimeFormat}
      renderLeadTimeFields={shouldRenderLeadTimeFields}
      submitting={submitting}
      product={product}
      options={optionsQuery.data}
      rules={rulesQuery.data}
      discount={discountQuery.data?.[0]?.percent ?? 0}
      imagesZipUrl={`/products/${productId}/images/export-all`}
      productName={configuredProduct?.productName || ''}
      quotes={[]}
      is3dViewerEnabled={is3dViewerEnabled}
      onChangeProduct={handleProductChange}
      onClose={onClose}
      onAddToQuoteClick={payload => onAddToQuote({ ...payload, catalogue: catalogueSlug })}
      onCreateAccountClick={voidFn}
      onSignInClick={voidFn}
      onAddSample={onAddSample}
    />
  );
};
