import React from 'react';
import { Brand, CellSelectInput, MenuItem, Typography } from '@clippings/paper';
import { CommonPaymentRuleCellProps } from '../../paymentTerms.types';
import { EventPaymentRule } from 'libs/api/common/types';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

type EventPaymentRuleCellProps = CommonPaymentRuleCellProps<EventPaymentRule>;

export function EventPaymentRuleCell({
  paymentRule,
  onChange,
  'data-testid': dataTestId,
}: EventPaymentRuleCellProps) {
  const { t } = useTranslation();
  const { instance, paymentTermsDaysOptions } = useAppConfiguration();

  if (instance === Brand.Sossego && paymentTermsDaysOptions.length === 1) {
    const option = paymentTermsDaysOptions[0];

    return (
      <Typography data-testid="event-payment-rule-cell-readonly">
        {option === 0 ? t('paymentTerms.immediately') : t('paymentTerms.netDays', { days: option })}
      </Typography>
    );
  }

  return (
    <CellSelectInput
      value={String(paymentRule.dueDays)}
      onChange={onChange}
      selectProps={{
        disableUnderline: true,
        inputProps: {
          'data-testid': dataTestId,
        },
      }}
    >
      {paymentTermsDaysOptions.map(option => (
        <MenuItem key={option} value={String(option)}>
          <Typography>
            {option === 0
              ? t('paymentTerms.immediately')
              : t('paymentTerms.netDays', { days: option })}
          </Typography>
        </MenuItem>
      ))}
    </CellSelectInput>
  );
}
