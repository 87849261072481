import React from 'react';
import { Box, Grid, Typography, UserAvatar, getUserFullName } from '@clippings/paper';
import { User } from 'libs/api/iam/types';

interface UserListItemNameProps {
  user: User;
  xs: number;
}

export const UserListItemName: React.FC<UserListItemNameProps> = ({ user, xs }) => {
  return (
    <Grid item xs={xs}>
      <Box display="flex">
        <UserAvatar user={user} />
        <Box ml={2} sx={{ overflowWrap: 'anywhere' }}>
          <Typography
            data-testid={`user-list-item-name-${user.id}`}
            variant="subtitle2"
            fontWeight="fontWeightBold"
          >
            {getUserFullName(user)}
          </Typography>
          <Typography
            data-testid={`user-list-item-jobTitle-${user.id}`}
            variant="caption"
            color="secondary"
          >
            {user.jobTitle || '\u00A0'}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
