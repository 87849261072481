import { Brand } from '@clippings/paper';

export enum TeamField {
  Name = 'name',
  PdfTemplate = 'pdfTemplateId',
}

type FieldsConfig = Record<Brand, Array<TeamField>>;

export const teamFieldsConfig: FieldsConfig = {
  [Brand.Fomcore]: [TeamField.Name],
  [Brand.Moroso]: [TeamField.Name, TeamField.PdfTemplate],
  [Brand.Sossego]: [TeamField.Name],
};
