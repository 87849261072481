import React from 'react';
import {
  CustomDeliveryDateForm,
  CustomDeliveryDateFormData,
  validationSchema,
} from './CustomDeliveryDateForm';
import { FormModal } from 'libs/Components';
import { useTranslation } from 'react-i18next';

type CustomDeliveryDateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  isLoading?: boolean;
  defaultValues?: CustomDeliveryDateFormData;
  onSubmit: (values: CustomDeliveryDateFormData) => void;
};

export function CustomDeliveryDateModal({
  isOpen,
  onClose,
  title,
  isLoading = false,
  defaultValues,
  onSubmit,
}: CustomDeliveryDateModalProps) {
  const { t } = useTranslation();

  return (
    <FormModal<CustomDeliveryDateFormData>
      fullWidth
      maxWidth="xs"
      title={title}
      open={isOpen}
      onClose={onClose}
      formKey="custom-delivery-date"
      actionProps={{
        onCancel: onClose,
        submitLabel: t('common.confirm'),
      }}
      formProps={{
        mode: 'all',
        defaultValues,
        validationSchema,
        onSubmit,
      }}
      submitProps={{
        'data-testid': 'custom-delivery-date-submit-button',
      }}
      isSubmitting={isLoading}
    >
      <CustomDeliveryDateForm />
    </FormModal>
  );
}
