import React, { useRef, useState } from 'react';
import { CatalogInfo } from './CatalogInfo/CatalogInfo';
import { CategoryFilter } from './filters/CategoryFilter';
import { CloseButton } from './CloseButton/CloseButton';
import { Column, DividerGrey, Row } from 'libs/shared';
import { FilterControls } from './filters/FilterControls';
import { LeadTimeFilter } from './filters/LeadTimeFilter';
import { PriceFilter, PriceFilterRef } from './filters/PriceFilter';
import { ProductCatalogLayout } from './ProductCatalogLayout/ProductCatalogLayout';
import { ProductHit } from 'libs/Quotes/quote.types';
import { ProductOrder } from '../../common/types';
import { ProductsList } from './products/ProductsList';
import { SearchField } from './SearchField/SearchField';
import { SortFilter } from './filters/SortFilter';
import { Title } from './Title/Title';
import { useConfigure, useInfiniteHits } from 'react-instantsearch-hooks-web';
import { useLeadTimeFilter } from '../hooks';
import { useTranslation } from 'react-i18next';

export interface ProductCatalogueProps {
  catalogueSlug: string;
  productOrder: ProductOrder;
  onProductOrderChange: (newProductOrder: ProductOrder) => void;
  onAddProduct?: (product: ProductHit) => void;
  onConfigureProduct?: (product: ProductHit) => void;
  onClose?: () => void;
}

export const ProductCatalog: React.FC<ProductCatalogueProps> = ({
  catalogueSlug,
  productOrder,
  onProductOrderChange,
  onAddProduct,
  onConfigureProduct,
  onClose,
}) => {
  const { t } = useTranslation();
  const { hits } = useInfiniteHits<ProductHit>();

  const {
    appliedLeadTimeFilter,
    setAppliedLeadTimeFilter,
    leadTimeFilterResetHandler,
    leadTimeFilterRef,
    shouldRenderLeadTimeFields,
  } = useLeadTimeFilter();

  const [appliedPriceFilter, setAppliedPriceFilter] = useState<string | null>(null);

  const priceFilterRef = useRef<PriceFilterRef>(null);
  useConfigure({
    filters: `catalogues:${catalogueSlug}`,
  });

  const handlePriceFilterReset = () => {
    if (priceFilterRef.current) {
      priceFilterRef.current.reset();
    }
  };

  const selectedProductOrderString = t(`plp.productOrder.${productOrder}`);

  return (
    <ProductCatalogLayout
      header={
        <>
          <Title />
          <Row flexGrow={1} px={3} py={2} justifyContent="space-between" alignItems="center">
            <SearchField />
            {onClose ? <CloseButton onClick={onClose} /> : null}
          </Row>
        </>
      }
    >
      <Column
        width={320}
        maxHeight="100%"
        borderRight={theme => `1px solid ${theme.palette.grey.A200}`}
        overflow="auto"
      >
        <CatalogInfo catalogueSlug={catalogueSlug} />
        <CategoryFilter />
        <DividerGrey sx={{ mx: 2 }} />
        <PriceFilter
          ref={priceFilterRef}
          catalogueSlug={catalogueSlug}
          onApply={setAppliedPriceFilter}
        />
        {shouldRenderLeadTimeFields ? (
          <>
            <DividerGrey sx={{ mx: 2 }} />
            <LeadTimeFilter
              ref={leadTimeFilterRef}
              catalogueSlug={catalogueSlug}
              onApply={setAppliedLeadTimeFilter}
            />
          </>
        ) : null}
      </Column>
      <Column width="calc(100% - 320px)">
        <Row pl={7} pr={3} pb={2}>
          <FilterControls
            appliedPriceFilter={appliedPriceFilter}
            appliedLeadTimeFilter={appliedLeadTimeFilter}
            onPriceFilterReset={handlePriceFilterReset}
            onLeadTimeFilterReset={leadTimeFilterResetHandler}
          />
          <SortFilter
            selectedProductOrder={selectedProductOrderString}
            disabled={hits.length === 0}
            onProductOrderChange={onProductOrderChange}
          />
        </Row>
        <ProductsList
          appliedLeadTimeFilter={appliedLeadTimeFilter}
          appliedPriceFilter={appliedPriceFilter}
          catalogueSlug={catalogueSlug}
          shouldRenderLeadTimeFields={shouldRenderLeadTimeFields}
          onAddProduct={onAddProduct}
          onConfigureProduct={onConfigureProduct}
        />
      </Column>
    </ProductCatalogLayout>
  );
};
