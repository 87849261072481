import React, { FC, HTMLAttributes, ReactElement, useEffect, useState } from 'react';
import { AutocompleteListBox } from './AutocompleteListBox';
import { Company } from 'libs/api/iam/types';
import {
  FormAutocomplete,
  FormAutocompleteProps,
  MenuItem,
  TextField,
  Typography,
} from '@clippings/paper';
import { getCompanyCity } from 'libs/shared';
import { listCompanies } from 'libs/api/iam/actions';
import { useInfinityTypeAhead } from 'libs/Utils/Hooks/useInfinityTypeAhead';

export type FormCompanyTypeaheadProps = Omit<
  FormAutocompleteProps<Company>,
  'options' | 'renderInput' | 'onInputChange' | 'PaperComponent'
> & {
  label: string;
  required?: boolean;
  initialInputValue?: string;
  onInputChange?: (value: string) => void;
  paperComponentRender?: (
    props: HTMLAttributes<HTMLElement>,
    inputValue?: string
  ) => ReactElement<any, any> | null;
};

export const FormCompanyTypeahead: FC<FormCompanyTypeaheadProps> = ({
  name,
  label,
  // setting an empty string as a default value, because we wan the component to always be controlled
  initialInputValue = '',
  onInputChange,
  required = false,
  paperComponentRender,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(initialInputValue);
  const { list, onScroll, onChange, isFetchingNextPage } = useInfinityTypeAhead(listCompanies);

  useEffect(() => {
    initialInputValue && onChange(initialInputValue);
  }, []);

  const inputChangeHandler = (
    _event: React.SyntheticEvent<Element, Event>,
    inputValue: string,
    _reason: 'reset' | 'input' | 'clear'
  ) => {
    onChange(inputValue);
    setInputValue(inputValue);
    onInputChange?.(inputValue);
  };

  const onOptionSelect = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.TouchEvent<HTMLLIElement>
  ) => {
    if (('button' in event && event.button !== 0) || event.type !== 'touchend') {
      return;
    }
  };

  return (
    <FormAutocomplete
      {...rest}
      name={name}
      options={list}
      openOnFocus
      clearOnBlur={false}
      onInputChange={inputChangeHandler}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          inputProps={{
            ...inputProps,
            'data-testid': 'company-input',
            autoComplete: 'none', // workaround chrome ignoring autocomplete off
            value: inputValue,
          }}
          variant="standard"
          color="secondary"
          label={label}
          required={required}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name || ''}
      renderOption={(props, option) => {
        const city = getCompanyCity(option?.billingAddresses);

        return (
          <MenuItem
            onMouseDown={onOptionSelect}
            onTouchEnd={onOptionSelect}
            data-testid={`company-item-${option.id}`}
            {...props}
            key={option.id}
          >
            {option.name}
            {city ? (
              <Typography component="span" color="secondary" ml={0.5}>
                ({city})
              </Typography>
            ) : null}
          </MenuItem>
        );
      }}
      ListboxProps={{
        // @ts-expect-error: it is ignore because the component allows custom listing  box  component but we dont have a proper way to add custom props to that component
        loading: isFetchingNextPage,
        onScroll: onScroll,
      }}
      PaperComponent={props => paperComponentRender?.(props, inputValue) ?? null}
      ListboxComponent={AutocompleteListBox as any}
      noOptionsText=""
    />
  );
};
