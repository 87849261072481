import React, { PropsWithChildren } from 'react';
import { Typography } from '@clippings/paper';

export function Label({ children }: PropsWithChildren) {
  return (
    <Typography variant="body2" fontWeight={500}>
      {children}
    </Typography>
  );
}
