import React from 'react';
import { Company } from 'libs/api/iam/types';
import { PaymentRule } from 'libs/api/common/types';
import { PaymentRulesTypes, PaymentTermsTable } from 'libs/SalesApp/Quotes/paymentTerms';
import { PaymentTermsDialogActions } from './PaymentTermsDialogActions';
import { PaymentTermsDialogContentBox } from './PaymentTermsDialogContentBox';
import { usePaymentTermsManager } from './usePaymentTermsManager';

export type PaymentTermsDialogContentProps = {
  company: Company;
  originalPaymentTerms: PaymentRule[];
  onClose: () => void;
};

export function PaymentTermsDialogContent({
  company,
  originalPaymentTerms,
  onClose,
}: PaymentTermsDialogContentProps) {
  const { isLoading, paymentTerms, handleAddingNewPaymentRule, handleApply, handleChange } =
    usePaymentTermsManager(company, originalPaymentTerms, onClose);

  return (
    <>
      <PaymentTermsDialogContentBox>
        <PaymentTermsTable
          paymentTerms={paymentTerms}
          paymentTermsTypes={paymentTermsTypes}
          onChange={handleChange}
          onAdd={handleAddingNewPaymentRule}
        />
      </PaymentTermsDialogContentBox>
      <PaymentTermsDialogActions isLoading={isLoading} onApply={handleApply} onCancel={onClose} />
    </>
  );
}

const paymentTermsTypes: PaymentRulesTypes = [
  { value: 'custom', translationKey: 'paymentTerms.custom' },
];
