import React from 'react';
import { Catalogue } from 'libs/api/catalogue/types';
import { CompanyQuoteDiscount } from 'libs/api/common/types';
import { QuoteDiscountRow } from './QuoteDiscountRow';
import { TableBody } from '@clippings/paper';

type QuoteDiscountTableBodyProps = {
  catalogues: Catalogue[];
  discounts: CompanyQuoteDiscount[];
  onChange: (discounts: CompanyQuoteDiscount[]) => void;
};

export function QuoteDiscountTableBody({
  catalogues,
  discounts,
  onChange,
}: QuoteDiscountTableBodyProps) {
  const changeHandler = (index: number, discount: CompanyQuoteDiscount | null) => {
    const newDiscounts = [...discounts];

    if (discount === null) {
      newDiscounts.splice(index, 1);
    } else {
      newDiscounts.splice(index, 1, discount);
    }

    onChange(newDiscounts);
  };

  return (
    <TableBody>
      {discounts.map((discount, index) => (
        <QuoteDiscountRow
          key={`${discount.id}`}
          discount={discount}
          catalogues={catalogues}
          onChange={newValue => changeHandler(index, newValue)}
        />
      ))}
    </TableBody>
  );
}
