import React from 'react';
import { Card } from './Card';
import { Column } from './Column';
import { Skeleton } from '@clippings/paper';

export function CardSkeleton() {
  return (
    <Card>
      <Column gap={1}>
        <Skeleton width="100%" height={40} sx={{ borderRadius: 1 }} variant="rectangular" />
        <Skeleton width="100%" height={40} sx={{ borderRadius: 1 }} variant="rectangular" />
        <Skeleton width="100%" height={40} sx={{ borderRadius: 1 }} variant="rectangular" />
        <Skeleton width="100%" height={40} sx={{ borderRadius: 1 }} variant="rectangular" />
      </Column>
    </Card>
  );
}
