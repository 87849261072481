import React from 'react';
import { Row } from 'libs/shared';
import { Typography, alpha } from '@clippings/paper';

export interface QuoteNotesTitleProps {
  title: string;
  subTitle: string;
}

export function QuoteNotesTitle({ title, subTitle }: QuoteNotesTitleProps) {
  return (
    <Row justifyContent="space-between" alignItems="center">
      <Typography
        variant="h5"
        fontSize={theme => theme.typography.pxToRem(14)}
        color={theme => alpha(theme.palette.common.black, 0.6)}
      >
        {title}
      </Typography>
      <Typography variant="body2" color={theme => alpha(theme.palette.common.black, 0.38)}>
        {subTitle}
      </Typography>
    </Row>
  );
}
