import React from 'react';
import { Nullable } from 'libs/Utils';
import { Picture } from 'libs/api/common/types';
import { getSmallProductImageUrl } from '@clippings/paper';

type ProductImageProps = {
  picture: Nullable<Picture>;
  fallback: string;
  alt: string;
};

export function ProductImage({ picture, fallback, alt }: ProductImageProps) {
  return (
    <img
      src={picture ? getSmallProductImageUrl(picture) : fallback}
      alt={alt}
      height={48}
      width={48}
    />
  );
}
