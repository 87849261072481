import { getCategories } from 'libs/api/catalogue/actions';
import { useQuery } from '@tanstack/react-query';

export function useCategoryQuery() {
  const categoryQuery = useQuery(['categories'], () => getCategories({ size: 500 }));

  const categories = categoryQuery.data ?? [];

  return { categories };
}
