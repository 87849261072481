import React from 'react';
import path from 'libs/Utils/path';
import { AddressBox } from './AddressBox';
import { Box, Grid, Skeleton, Typography } from '@clippings/paper';
import { CatalogueInfo } from 'libs/SalesApp/Companies/details/components/CompanyDetailsPanel/components/CatalogueInfo';
import { DiscountsInfo } from 'libs/SalesApp/Companies/details/discounts';
import { EditButtonProps, InfoBox } from 'libs/shared';
import { GeneralCompanyInfoBox } from '../../../../Companies/details/components/CompanyDetailsPanel/components/GeneralCompanyInfoBox';
import { NavLink } from 'react-router-dom';
import { PaymentTermsInfo } from 'libs/SalesApp/Companies/details/components/CompanyDetailsPanel/components/PaymentTermsInfo';
import { routes } from 'libs/Constants';
import {
  useGetCompany,
  useListCompanyBillingAddresses,
  useListCompanyShippingAddresses,
} from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

interface CompanyInfoBoxProps {
  companyId: number;
}

export const CompanyInfoBox: React.FC<CompanyInfoBoxProps> = ({ companyId }) => {
  const { t } = useTranslation();
  const companyQuery = useGetCompany(companyId);

  const shippingAddressQuery = useListCompanyShippingAddresses(companyId);
  const billingAddressQuery = useListCompanyBillingAddresses(companyId);

  if (companyQuery.isLoading) {
    return <Skeleton height="300px" variant="rectangular" width="100%" sx={{ marginTop: 2 }} />;
  }

  return (
    <InfoBox
      title={t('common.companyInformation')}
      editButtonProps={
        companyQuery.data?.editable
          ? ({
              component: NavLink,
              to: path(routes.salesApp.company, { companyId: companyId }),
              label: t('users.editOnCompanyProfile'),
              'aria-label': 'edit-company-info',
            } as EditButtonProps)
          : undefined
      }
      data-testid="company-info-box"
      marginBottom={3}
    >
      {companyQuery.data && (
        <>
          <GeneralCompanyInfoBox company={companyQuery.data} />

          <Box width="100%" py={2}>
            <Typography variant="h6" fontWeight="bold" my={3}>
              {t('companies.addressInformation')}
            </Typography>

            <Grid container columns={{ xs: 2 }} spacing={{ xs: 2 }}>
              <Grid item xs={1} gap={1} display="flex" flexDirection="column">
                <Typography variant="subtitle2" color="grey.500" fontWeight="fontWeightBold" mb={1}>
                  {t('common.billingAddress')}
                </Typography>

                {billingAddressQuery.data?.map(address => (
                  <AddressBox address={address} key={address.id} />
                ))}
              </Grid>
              <Grid item xs={1} gap={1} display="flex" flexDirection="column">
                <Typography variant="subtitle2" color="grey.500" fontWeight="fontWeightBold" mb={1}>
                  {t('common.shippingAddress')}
                </Typography>
                {shippingAddressQuery.data?.map(address => (
                  <AddressBox address={address} key={address.id} />
                ))}
              </Grid>
            </Grid>

            <CatalogueInfo
              company={companyQuery.data}
              boxProps={{ px: 0, py: 2, border: 'none', bgcolor: 'transparent', mt: 3 }}
              isEditable={false}
            />

            <DiscountsInfo
              company={companyQuery.data}
              boxProps={{ px: 0, py: 2, border: 'none', bgcolor: 'transparent', mt: 3 }}
              isEditable={false}
            />

            <PaymentTermsInfo
              company={companyQuery.data}
              boxProps={{ px: 0, py: 2, border: 'none', bgcolor: 'transparent', mt: 3 }}
              isEditable={false}
            />
          </Box>
        </>
      )}
    </InfoBox>
  );
};
