import React, { UIEvent } from 'react';
import { AddIcon, Box, Button, ChipMenuFilter, Skeleton, Typography } from '@clippings/paper';
import { Column, Readonly, useUserPermissions } from 'libs/shared';
import { Quote, QuoteBase } from 'libs/api/quotes/types';
import { QuoteUpdateOptions } from 'libs/Quotes/quote.types';
import { Search } from '../Search';
import { User, UserPermission } from 'libs/api/iam/types';
import { UserMenuItem } from './UserMenuItem';
import { mapBaseUser } from 'libs/SalesApp/Quotes/quoteUtils';
import { preventDefaultPropagation } from 'libs/shared/utils';
import { times } from 'lodash';
import { useQuoteCustomerSelect } from '../../hooks';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export interface QuoteCustomerSelectProps extends Readonly {
  customer: QuoteBase['customer'];
  onNewCustomerClick: () => void;
  onCustomerSelect: (data: Partial<Quote>, options?: QuoteUpdateOptions) => void;
  onToggle?: () => void;
}

export function QuoteCustomerSelect({
  onNewCustomerClick,
  customer,
  onCustomerSelect,
  onToggle: onToggleProp,
  readonly = false,
}: QuoteCustomerSelectProps) {
  const { t } = useTranslation();
  const userPermissions = useUserPermissions();
  const { updateWithoutOptimisticLoading, quote } = useQuoteProvider();
  const {
    isLoading,
    listUsersQuery: { data: customers, isSuccess, fetchNextPage, hasNextPage },
    searchChangeHandler,
    menuOpen,
    chipMenuFilterToggleHandler,
    getChipValue,
    getChipAdornmentComponent,
  } = useQuoteCustomerSelect(customer, readonly, quote.catalogue);

  function customerClickHandler(user: User): void {
    onCustomerSelect({ customer: mapBaseUser(user) }, { withOptimisticUpdate: false });
    chipMenuFilterToggleHandler();
  }

  function onToggle() {
    chipMenuFilterToggleHandler();
    onToggleProp?.();
  }

  const handleNewCustomerClick = () => {
    onToggle();
    onNewCustomerClick();
  };

  const isDisabled = readonly || updateWithoutOptimisticLoading;
  const hasCanCreateUsersPermission = userPermissions.includes(UserPermission.CanCreateUsers);

  return (
    <ChipMenuFilter
      header={{
        component: (
          <Search
            placeholder={t('quotes.searchCustomerPlaceholder')}
            data-testid="sales-quote-customer-search"
            textFieldInputProps={{
              inputRef: input => {
                if (input) {
                  // solution using timeout due to poor component composition inside ChipMenuFilter
                  setTimeout(() => {
                    input.focus();
                  });
                }
              },
            }}
            changeHandler={searchChangeHandler}
          />
        ),
      }}
      chipProps={{
        menuProps: {
          open: menuOpen,
          disableAutoFocus: true,
          onClick: preventDefaultPropagation,
        },
        adornment: getChipAdornmentComponent(),
        isOpen: menuOpen,
        value: getChipValue(),
        onToggle,
        hasArrow: !readonly,
        'data-testid': 'quote-customer-select-chip-filter',
        disabled: isDisabled,
      }}
      containerProps={{
        sx: {
          width: 360,
          minHeight: 120,
        },
      }}
      contentContainerProps={{
        sx: {
          maxHeight: 350,
          overflow: 'auto',
          paddingBottom: hasCanCreateUsersPermission ? 8 : 0,
        },
        'data-testid': 'quote-customer-select-chip-filter-content',
        onScroll: (event: UIEvent<HTMLDivElement>) => {
          /**
           * fetch more customers if the user is close to the bottom of the list
           */
          const target = event.target as HTMLDivElement;
          const threshold = 80;
          const shouldFetchMore =
            target.scrollHeight - target.clientHeight - target.scrollTop < threshold;

          if (shouldFetchMore && !isLoading && hasNextPage) {
            fetchNextPage();
          }
        },
      }}
    >
      {isSuccess
        ? customers?.pages
            ?.flat()
            ?.map(c => (
              <UserMenuItem
                key={c.id}
                user={c}
                selected={customer?.id === c.id}
                itemClickHandler={customerClickHandler}
              />
            ))
        : null}

      {isLoading ? <Loading /> : null}

      {hasCanCreateUsersPermission ? (
        <Box
          padding={2}
          display="flex"
          justifyContent="flex-end"
          position="absolute"
          bottom={0}
          width="100%"
          sx={{ background: theme => theme.palette.common.white }}
        >
          <Button
            variant="text"
            data-testid="quote-customer-select-create-new-user-button"
            onClick={handleNewCustomerClick}
          >
            <AddIcon style={{ marginRight: 10 }} />
            <Typography variant="subtitle2">{t('forms.createNewUser')}</Typography>
          </Button>
        </Box>
      ) : null}
    </ChipMenuFilter>
  );
}

function Loading() {
  return (
    <Column gap={1} px={1}>
      {times(2).map(i => (
        <Skeleton
          key={i}
          width="100%"
          height="54px"
          sx={{ borderRadius: 1 }}
          variant="rectangular"
        />
      ))}
    </Column>
  );
}
