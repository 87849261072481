import React, { FC } from 'react';
import { Divider, Typography } from '@clippings/paper';

export interface ChatQuoteSalesRepJoinedMessageProps {
  label: string;
  messageId: string;
}
export const ChatQuoteSalesRepJoinedMessage: FC<ChatQuoteSalesRepJoinedMessageProps> = ({
  label,
  messageId,
}) => (
  <Divider
    data-testid={`quote-system-message-sales-rep-join-${messageId}`}
    sx={{ marginBottom: 3 }}
    orientation="horizontal"
    flexItem
  >
    <Typography
      color="secondary"
      fontWeight="fontWeightMedium"
      sx={{
        fontSize: theme => theme.typography.pxToRem(10),
      }}
      variant="subtitle2"
    >
      {label}
    </Typography>
  </Divider>
);
