import React, { ComponentProps, FC } from 'react';
import { Box, DeleteIcon, DragIndicatorIcon, IconButton } from '@clippings/paper';
import { Column } from 'libs/shared';

type QuoteRowActionsPanelProps = ComponentProps<typeof Box> & {
  onDelete: () => void;
  disableDrag?: boolean;
  disabled?: boolean;
};

export const QuoteRowActionsPanel: FC<QuoteRowActionsPanelProps> = ({
  onDelete,
  disableDrag,
  ...rest
}) => (
  <Box padding={2}>
    <Column justifyContent="center" bgcolor="grey.A200" borderRadius={1} {...rest}>
      {!disableDrag && (
        <IconButton size="small" sx={buttonDragSx} data-testid="drag-quote-item">
          <DragIndicatorIcon color="secondary" />
        </IconButton>
      )}
      <IconButton size="small" data-testid="delete-quote-item" onClick={onDelete}>
        <DeleteIcon color="secondary" />
      </IconButton>
    </Column>
  </Box>
);

const buttonDragSx = {
  padding: 0,
  marginX: 1,
  marginY: 0.5,
};
