import i18next from 'i18next';
import { PaymentRule } from 'libs/api/common/types';
import { TranslationKey } from 'libs/types/react-i18next';
import { isBefore, isValid } from 'date-fns';

export enum PaymentRuleValidationError {
  InvalidAmount,
  DueReasonMissing,
  DueReasonTooLong,
  InvalidDate,
}

export const validatePaymentRules = (paymentRules: PaymentRule[]): PaymentRuleValidationError[] => {
  const errors = paymentRules.reduce((acc: PaymentRuleValidationError[], paymentRule) => {
    if (
      paymentRule.percentage === 0 ||
      paymentRule.percentage == null ||
      Number.isNaN(paymentRule.percentage)
    ) {
      acc.push(PaymentRuleValidationError.InvalidAmount);
    }

    if (
      paymentRule.type === 'date' &&
      // invalid date
      (!isValid(new Date(paymentRule.dueDate)) ||
        // date is in the past
        isBefore(new Date(paymentRule.dueDate), new Date()))
    ) {
      acc.push(PaymentRuleValidationError.InvalidDate);
    }

    if (paymentRule.type === 'custom' && paymentRule.dueReason.trim() === '') {
      acc.push(PaymentRuleValidationError.DueReasonMissing);
    }

    if (paymentRule.type === 'custom' && paymentRule.dueReason.length > 255) {
      acc.push(PaymentRuleValidationError.DueReasonTooLong);
    }

    return acc;
  }, []);

  return errors;
};

const translationMap: Record<PaymentRuleValidationError, TranslationKey> = {
  [PaymentRuleValidationError.InvalidAmount]: 'common.invalidAmountError',
  [PaymentRuleValidationError.DueReasonMissing]: 'common.noDueReasonError',
  [PaymentRuleValidationError.DueReasonTooLong]: 'common.dueReasonTooLong',
  [PaymentRuleValidationError.InvalidDate]: 'common.invalidDate',
};

export const translateValidationError = (err: PaymentRuleValidationError) =>
  i18next.t(translationMap[err]);
