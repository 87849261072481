import { routes } from 'libs/Constants';

export function isSalesApp(pathname: string): boolean {
  return pathname.startsWith(routes.salesApp.index);
}

export function isGuestApp(pathname: string): boolean {
  return pathname.startsWith(routes.guestApp.index);
}

export type AppType = 'sales' | 'guest' | 'customer';

export function getCurrentApp(pathname: string): AppType {
  if (isSalesApp(pathname)) {
    return 'sales';
  }

  if (isGuestApp(pathname)) {
    return 'guest';
  }

  return 'customer';
}
