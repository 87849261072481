import React from 'react';
import { AddNewEntityButton } from 'libs/shared';
import { DataGrid, FlexColumn } from '@clippings/paper';
import { PaymentRule } from 'libs/api/common/types';
import { PaymentRulesTypes } from '../../paymentTerms.types';
import { PaymentTermsTableBody } from './PaymentTermsTableBody';
import { PaymentTermsTableHead } from './PaymentTermsTableHead';
import { useTranslation } from 'react-i18next';

export interface PaymentTermsTableProps {
  paymentTerms: PaymentRule[];
  paymentTermsTypes: PaymentRulesTypes;
  onChange: (index: number, updatedPaymentRule: PaymentRule | null) => void;
  onAdd: () => void;
}

export const PaymentTermsTable: React.FC<PaymentTermsTableProps> = ({
  paymentTerms,
  paymentTermsTypes,
  onChange,
  onAdd,
}) => {
  const { t } = useTranslation();

  return (
    <FlexColumn gap={2}>
      <DataGrid data-testid="payment-terms-table">
        <PaymentTermsTableHead />
        <PaymentTermsTableBody
          paymentTerms={paymentTerms}
          paymentTermsTypes={paymentTermsTypes}
          onChange={onChange}
        />
      </DataGrid>
      <AddNewEntityButton
        data-testid="payment-rule-add-new-button"
        text={`${t('common.addNew')} ${t('paymentTerms.paymentTerms')}`}
        onClick={onAdd}
      />
    </FlexColumn>
  );
};
