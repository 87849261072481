import React, { ComponentProps, FC, useState } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  TextField,
} from '@clippings/paper';
import { QuoteShareModalScreen, ShareeSelectOption } from '../useQuoteShareUtils';
import { ShareeDetailedUserAvatar } from './ShareeDetailedUserAvatar';
import { ShareeSelectTagChip } from './ShareeSelectTagChip';
import { useQuoteShare } from '../QuoteShareProvider';
import { useTranslation } from 'react-i18next';

export type ShareeSelectProps = Omit<
  ComponentProps<typeof Autocomplete<ShareeSelectOption, true, true, true>>,
  'renderInput' | 'renderOption' | 'renderTags' | 'options'
>;

export const ShareeSelect: FC<ShareeSelectProps> = props => {
  const { t } = useTranslation();
  const {
    sharees: { selectedSharees, erronousSharees },
    modal: { screen },
    shareeSelectQuery,
    usersQuery: { isLoading },
    handlers: { shareeSelectInputChangeHandler, shareeSelectChangeHandler },
    options,
  } = useQuoteShare();
  const [open, setOpen] = useState(screen === QuoteShareModalScreen.SHARE);

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, sharee: ShareeSelectOption) => (
    <li {...props}>
      <ShareeDetailedUserAvatar
        data-testid={`sharee-select-option-${sharee.id}`}
        key={`sharee-select-option-${sharee.id}`}
        user={sharee}
        containerProps={{
          maxWidth: 'unset',
          width: '100%',
          sx: {
            p: 1,
            ':hover': {
              backgroundColor: (theme: any) => theme.palette.grey[100],
              cursor: 'pointer',
            },
          },
        }}
      />
    </li>
  );

  const renderInput = ({ inputProps, ...rest }: AutocompleteRenderInputParams) => (
    <TextField
      {...rest}
      variant="outlined"
      placeholder={t('quotes.share.addPeople')}
      autoFocus={screen === QuoteShareModalScreen.SHARE}
      inputProps={{
        ...inputProps,
        'data-testid': 'sharee-autocomplete-input',
      }}
    />
  );

  const renderTags = (tags: ShareeSelectOption[], getTagProps: AutocompleteRenderGetTagProps) =>
    tags.map((tag, index) => (
      <ShareeSelectTagChip
        key={`option-tag-${tag.id}`}
        tag={tag}
        getTagProps={getTagProps}
        index={index}
        errorMessage={
          erronousSharees.find(erronousSharee => erronousSharee.email === tag.email)?.message
        }
      />
    ));

  const getOptionLabel = (option: ShareeSelectOption | string) =>
    typeof option === 'string' ? option : option.email;

  const isOptionEqualToValue = (option: ShareeSelectOption, value: ShareeSelectOption) =>
    option.id === value.id;

  const onInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => shareeSelectInputChangeHandler(event, value, reason, options);

  return (
    <Autocomplete<ShareeSelectOption, true, true, true>
      freeSolo
      fullWidth
      disableCloseOnSelect
      multiple
      autoHighlight
      open={open}
      loading={isLoading}
      loadingText={t('common.loading')}
      sx={{ my: 2 }}
      id="sharee-select-autocomplete"
      data-testid="sharee-select-autocomplete"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      value={selectedSharees}
      inputValue={shareeSelectQuery}
      onChange={shareeSelectChangeHandler}
      onInputChange={onInputChange}
      options={options}
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={renderInput}
      {...props}
    />
  );
};
