import React, { FC, ReactNode } from 'react';
import { Box, Mask } from '@clippings/paper';

export type FooterStickyProps = {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  disabled?: boolean;
};

export const FooterSticky: FC<FooterStickyProps> = ({
  leftContent,
  rightContent,
  disabled = false,
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    flexWrap="wrap"
    alignItems="center"
    px={{ xs: 0, sm: 2 }}
    py={{ xs: 1, sm: 2 }}
    borderTop="1px solid"
    borderColor="grey.A400"
    bgcolor={theme => `${theme.palette.grey['50']}f0`}
    position="sticky"
    bottom="0"
    zIndex="999"
  >
    <Mask
      data-testid="quote-footer-mask"
      active={disabled}
      flexGrow={1}
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="center"
    >
      <Box>{leftContent}</Box>
      <Box>{rightContent}</Box>
    </Mask>
  </Box>
);
