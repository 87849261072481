import { Reducer } from 'react';

export enum ACTIONS {
  CHANGE_SCREEN = 'CHANGE_SCREEN',
  GO_BACK = 'GO_BACK',
  SELECT_ADDRESS = 'SELECT_ADDRESS',
  OPEN_DELETE_MODAL = 'OPEN_DELETE_MODAL',
  CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL',
}

export type AddressState = {
  currentScreen: string;
  previousSteps: string[];
  selectedAddress: any;
  isDeleteModalOpen: boolean;
};

export type AddressAction =
  | {
      type: typeof ACTIONS.CHANGE_SCREEN;
      payload: {
        screen: string;
        stepsAction: string;
      };
    }
  | {
      type: typeof ACTIONS.GO_BACK;
    }
  | {
      type: typeof ACTIONS.SELECT_ADDRESS;
      payload: any;
    }
  | {
      type: typeof ACTIONS.OPEN_DELETE_MODAL;
      payload: any;
    }
  | {
      type: typeof ACTIONS.CLOSE_DELETE_MODAL;
    };

export const reducer: Reducer<AddressState, AddressAction> = (
  state: AddressState,
  action: AddressAction
) => {
  switch (action.type) {
    case ACTIONS.CHANGE_SCREEN: {
      const { payload } = action;
      const previousSteps = calculatePreviousSteps(state, payload);

      return {
        ...state,
        previousSteps,
        currentScreen: action.payload.screen,
      };
    }

    case ACTIONS.GO_BACK: {
      const updatedPreviousSteps = [...state.previousSteps];
      const newStep = updatedPreviousSteps.pop();

      return {
        ...state,
        previousSteps: updatedPreviousSteps,
        currentScreen: newStep as string,
      };
    }

    case ACTIONS.SELECT_ADDRESS: {
      return { ...state, selectedAddress: action.payload };
    }

    case ACTIONS.OPEN_DELETE_MODAL: {
      return { ...state, isDeleteModalOpen: true, selectedAddress: action.payload };
    }

    case ACTIONS.CLOSE_DELETE_MODAL: {
      return { ...state, isDeleteModalOpen: false };
    }

    default:
      return state;
  }
};

function calculatePreviousSteps(state: AddressState, payload: { stepsAction: string }) {
  const steps = [...state.previousSteps];

  switch (payload.stepsAction) {
    case 'push':
      return [...steps, state.currentScreen];

    case 'pop':
      steps.pop();

      return steps;

    case 'noop':
    default:
      return steps;
  }
}
