import React from 'react';
import {
  ArrowBackIcon,
  Box,
  ChevronLeftIcon,
  Image,
  Link,
  ShowElement,
  Typography,
  useTheme,
} from '@clippings/paper';
import { HeaderNavigation } from './HeaderNavigation';
import { matchPath, useLocation } from 'react-router-dom';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import { useTranslation } from 'react-i18next';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const returnUrl = useReturnUrl();
  const qcPathMatch = matchPath(routes.quotes.checkout, location.pathname);

  const {
    images: { logo },
  } = useTheme();

  const buttonTranslation = qcPathMatch ? 'common.backToQuote' : 'common.continueShopping';
  const buttonHref = qcPathMatch
    ? path(routes.quotes.show, { number: qcPathMatch.params.number })
    : returnUrl;

  const onBack = () => {
    window.history.back();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height={{ xs: 56, md: 'unset' }}
      p={{ xs: 2, md: 3 }}
      border="1px solid"
      borderColor="grey.A200"
    >
      <ShowElement below="md">
        <Box
          onClick={onBack}
          color="secondary.main"
          display="flex"
          data-testid="back-button-mobile"
        >
          <ArrowBackIcon fontSize="medium" />
        </Box>
      </ShowElement>
      <ShowElement above="md">
        <Link href={buttonHref} underline="none" display="flex" gap={1} data-testid="back-button">
          <Typography
            variant="body2"
            color="primary"
            fontWeight="fontWeightMedium"
            display="flex"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <ChevronLeftIcon /> {t(buttonTranslation)}
          </Typography>
        </Link>
      </ShowElement>
      <Link
        href={returnUrl}
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            display: 'flex',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        })}
      >
        <Image src={logo} alt="logo" height={{ xs: 20, md: 44 }} />
      </Link>
      <HeaderNavigation />
    </Box>
  );
};
