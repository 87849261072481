import React, { ComponentProps, FC, ReactNode } from 'react';
import { Box } from '@clippings/paper';
import { Error } from './Error/Error';
import { PaymentStatus } from '../checkout.types';
import { Pending } from './Pending/Pending';
import { Success } from './Success/Success';
import { useQuoteCheckoutContext } from '../providers';

export type ConfirmProps = {
  containerProps?: ComponentProps<typeof Box>;
};
export const Confirm: FC<ConfirmProps> = ({ containerProps }) => {
  const { paymentStatus } = useQuoteCheckoutContext();

  const STATUS_MAP: Record<PaymentStatus, ReactNode> = {
    [PaymentStatus.Success]: <Success />,
    [PaymentStatus.Error]: <Error />,
    [PaymentStatus.Pending]: <Pending />,
    [PaymentStatus.Incomplete]: null,
  };

  return (
    <Box sx={{ marginTop: '4rem' }} {...containerProps}>
      {STATUS_MAP[paymentStatus]}
    </Box>
  );
};
