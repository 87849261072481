import React, { useState } from 'react';
import { DividerGrey } from 'libs/shared';
import { OrderStatus } from 'libs/api/order/types';
import { OrdersFilterStrip } from './components/OrdersFilterStrip';
import { OrdersList } from './components/OrdersList';
import { PageHeader } from 'libs/SalesApp/components/PageHeader';
import { SingleTeamUser } from '../Quotes/quoteUtils';
import { useSearch } from 'libs/SalesApp/Header/useSearch';
import { useStaffSalesReps } from '../Quotes/hooks';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const OrdersPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useSearch();
  const staffMembers = useStaffSalesReps();

  const [status, setStatus] = useState<OrderStatus | undefined>();
  const [teamsOfUser, setTeamsOfUser] = useState<SingleTeamUser | undefined>();

  useTitle(t('common.orders'));

  const filtersResetHandler = () => {
    setSearchValue('');
    setStatus(undefined);
  };

  return (
    <>
      <PageHeader
        data-testid="sa-orders-header"
        title={t('common.orders')}
        titleTestId="sa-orders-title"
      />

      <DividerGrey />
      <OrdersFilterStrip
        staffMembers={staffMembers}
        onTeamsOfUserSelect={setTeamsOfUser}
        onStatusSelect={setStatus}
        filters={{ status, teamsOfUser }}
      />
      <DividerGrey sx={{ mb: 1 }} />
      <OrdersList
        teamsOfUserFilter={teamsOfUser}
        statusFilter={status}
        searchValue={searchValue}
        filtersResetHandler={filtersResetHandler}
      />
    </>
  );
};
