import React, { FC } from 'react';
import { Grid } from '@clippings/paper';
import { QuotesTableColumn, QuotesTableSort } from 'libs/SalesApp/Quotes/types';
import { QuotesTableHeaderCell } from './QuotesTableHeaderCell';
import { useQuotesTable } from '../providers';
import { useTranslation } from 'react-i18next';

const headerCells = [
  {
    key: 'sales-quotes-table-header-col-quote-number',
    columnName: QuotesTableColumn.Quote,
    label: 'quotes.quoteNumber',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-customer',
    columnName: QuotesTableColumn.Customer,
    label: 'quotes.pdf.info.customer',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-value',
    columnName: QuotesTableColumn.Value,
    label: 'common.value',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-status',
    columnName: QuotesTableColumn.Status,
    label: 'common.status',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-recent-activity',
    columnName: QuotesTableColumn.RecentActivity,
    label: 'common.recentActivity',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-recent-assignee',
    columnName: QuotesTableColumn.Assignee,
    label: 'common.assignee',
    columns: 3,
  },
] as const;

export const QuotesTableHeader: FC = () => {
  const { sort, setSort } = useQuotesTable();
  const { t } = useTranslation();

  const onSortChange = (sort: QuotesTableSort) => {
    setSort(sort);
  };

  return (
    <>
      {headerCells.map(cell => (
        <QuotesTableHeaderCell
          {...cell}
          label={t(cell.label)}
          currentSort={sort}
          onSort={onSortChange}
        />
      ))}
      <Grid item xs={1} />
    </>
  );
};
