import React, { useState } from 'react';
import { CompaniesGrid, cellConfig } from './CompaniesGrid';
import { CompanyTypeSelect } from './CompanyTypeSelect';
import { DecodedValueMap, useQueryParams } from 'use-query-params';
import { DividerGrey, Row, makeColumns, makeQueryParamsMap, useTableSorting } from 'libs/shared';
import { useListInfiniteCompanies } from 'libs/api/iam/hooks';
import { useMemo } from 'react';
import { useSearch } from 'libs/SalesApp/Header/useSearch';

type CompaniesListProps = {
  setCreateCompanyDialogOpen: (open: boolean) => void;
};

const columns = makeColumns(cellConfig);
const queryParamsMap = makeQueryParamsMap(columns);
export type CompaniesQueryParams = Partial<DecodedValueMap<typeof queryParamsMap>>;

export function CompaniesList({ setCreateCompanyDialogOpen }: CompaniesListProps) {
  const [searchValue, setSearchValue] = useSearch();

  const [queryParams, setQueryParams] = useQueryParams(queryParamsMap);
  const { sort, updateSorting } = useTableSorting<CompaniesQueryParams>(
    queryParams,
    setQueryParams
  );

  const [selectedCompanyType, setSelectedCompanyType] = useState<string | undefined>();

  const { data, hasNextPage, isLoading, isSuccess, isFetchingNextPage, fetchNextPage } =
    useListInfiniteCompanies({
      query: searchValue,
      sortBy: sort.sort,
      sortOrder: sort.order,
      type: selectedCompanyType,
    });

  const companies = useMemo(() => {
    return (data?.pages ?? []).flat();
  }, [data]);

  const onReset = () => {
    setSelectedCompanyType('');
    setSearchValue('');
  };

  const hasFilters = !!searchValue || !!selectedCompanyType;

  return (
    <>
      <Row justifyContent="flex-end" py={1}>
        <CompanyTypeSelect
          type={selectedCompanyType}
          disabled={!isSuccess}
          onChange={setSelectedCompanyType}
        />
      </Row>
      <DividerGrey sx={{ mb: 1 }} />
      <CompaniesGrid
        companies={companies}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasFilters={hasFilters}
        hasNextPage={hasNextPage}
        sort={sort}
        onSort={updateSorting}
        handleFetchNextPage={fetchNextPage}
        onReset={onReset}
        setCreateCompanyDialogOpen={setCreateCompanyDialogOpen}
      />
    </>
  );
}
