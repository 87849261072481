import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ContentCopyIcon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@clippings/paper';
import { bankTransferData } from './bankTransferConfig';

export const BankTransfer: React.FC<{ amount: string }> = ({ amount }) => {
  const { t } = useTranslation();
  const handleCopy = (text: string) => () => navigator.clipboard.writeText(text);

  return (
    <>
      <Typography variant="body1" fontWeight="fontWeightMedium" paddingY={1}>
        {t('checkout.bankTransfer.amount', { amount })}
      </Typography>
      <Box
        sx={{ border: '1px solid', borderColor: 'grey.A200', borderRadius: 1, overflow: 'hidden' }}
      >
        <Table
          sx={{
            '& .MuiTableRow-root:last-child': {
              '.MuiTableCell-root': {
                borderBottom: 'none',
              },
            },
          }}
        >
          <TableBody>
            {bankTransferData.map(({ title, info }) => (
              <TableRow key={title}>
                <TableCell
                  align="left"
                  size="small"
                  sx={{ backgroundColor: 'grey.A200', fontWeight: 'fontWeightMedium' }}
                >
                  {t(title)}
                </TableCell>
                <TableCell
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {t(info)}
                  <IconButton aria-label="close" onClick={handleCopy(t(info))}>
                    <ContentCopyIcon data-testid="form-close" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
