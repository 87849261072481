import React, { FC } from 'react';
import { OrderPaymentView } from './OrderPaymentView';
import { PaymentStatus } from 'libs/api/common/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrder } from 'libs/api/order/hooks';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export type OrderPaymentUseParamProps = {
  number: string;
  id: string;
};

export const OrderPaymentPage: FC = () => {
  const { t } = useTranslation();
  const { number, id } = useParams() as OrderPaymentUseParamProps;
  const navigate = useNavigate();
  const { getOrderShowPath } = useOrderPaths();
  const order = useOrder(number);
  const payment = order.data?.paymentSchedule.find(x => x.id === id);

  useTitle(t('checkout.setupPayment.title'));

  if (!payment || !order.isSuccess) {
    return null;
  }

  if (payment.status === PaymentStatus.Paid) {
    navigate(getOrderShowPath(number));
  }

  return <OrderPaymentView number={number} order={order.data} payment={payment} />;
};
