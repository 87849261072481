import React from 'react';
import { Brand, Typography } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { useAppConfiguration } from 'libs/providers';

type VendorSkuProps = {
  vendorSku: Nullable<string>;
};

export function VendorSku({ vendorSku }: VendorSkuProps) {
  const { instance } = useAppConfiguration();

  if (!vendorSku) {
    return null;
  }

  const color = instance === Brand.Fomcore ? 'secondary' : 'grey.400';

  return (
    <Typography variant="subtitle2" color={color} className="product-info__sku">
      {vendorSku}
    </Typography>
  );
}
