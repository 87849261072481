import React from 'react';
import { Box, Button, Image, MenuItem, Typography } from '@clippings/paper';
import { ConfiguredProduct } from '../common/types';
import { ProductHit } from 'libs/Quotes/quote.types';
import { Quote } from 'libs/api/quotes/types';
import { getProductListingImageUrl } from '../common/utils';
import { useTranslatedProduct } from '../hooks';
import { useTranslation } from 'react-i18next';

export interface ProductAutocompleteItemProps {
  hit: ProductHit;
  quote: Quote;
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  onAddItem: (item: ProductHit) => void;
  onClose: () => void;
}

export const ProductAutocompleteItem: React.FC<ProductAutocompleteItemProps> = ({
  hit,
  quote,
  onConfigure,
  onAddItem,
  onClose,
}) => {
  const { t } = useTranslation();
  const { translateProductName } = useTranslatedProduct();
  const productName = translateProductName(hit);

  const addNonCustomProduct = () => {
    onClose();
    onAddItem(hit);
  };

  const configureNonCustomProduct = () => {
    onClose();
    onConfigure({
      productId: hit.objectID,
      productName: productName,
      showGroupedProducts: true,
    });
  };

  return (
    <MenuItem
      disableRipple
      sx={{
        cursor: 'initial',
        userSelect: 'text',
        display: 'flex',
        gap: '4px',
        '&:hover': {
          '.product-row__buttons': {
            visibility: 'visible',
          },
        },
      }}
      data-testid={`product-menu-item-${hit.objectID}`}
    >
      <Image
        sx={{ userSelect: 'none' }}
        src={getProductListingImageUrl(hit.coverRemoteId, hit.coverVersion)}
        height={48}
        width={48}
        alt={productName}
      />
      <Box display="flex" flexDirection="column" minWidth={0} flexGrow={1}>
        <Typography variant="subtitle2">{productName}</Typography>
        <Typography
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          variant="body2"
          color="secondary"
        >
          {hit.vendorSkus?.[quote.catalogue] ?? hit?.skus[quote.catalogue]}
        </Typography>
      </Box>
      <Box
        className="product-row__buttons"
        display="flex"
        visibility="hidden"
        gap={0.5}
        height={36}
      >
        {hit.type === 'product' && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={configureNonCustomProduct}
            size="small"
            data-testid={`configure-new-product-${hit.objectID}`}
          >
            {t('common.configure')}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={addNonCustomProduct}
          size="small"
          data-testid={`add-new-product-${hit.objectID}`}
        >
          {t('common.add')}
        </Button>
      </Box>
    </MenuItem>
  );
};
