import React from 'react';
import { ChipMenu, MenuItem, useTranslation } from '@clippings/paper';
import { ProductOrder } from 'libs/SalesApp/Quotes/common/types';
import { useToggle } from 'libs/shared';

type SortFilterProps = {
  selectedProductOrder: string;
  disabled?: boolean;
  onProductOrderChange: (newProductOrder: ProductOrder) => void;
};

export function SortFilter({
  selectedProductOrder,
  disabled,
  onProductOrderChange,
}: SortFilterProps) {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useToggle(false);

  const selectProductOrder = (value: ProductOrder) => {
    onProductOrderChange(value);
    setMenuOpen(false);
  };

  const sortByMostRelevant = () => selectProductOrder('mostRelevant');

  const sortByHighestPrice = () => selectProductOrder('highestPrice');

  const sortByLowestPrice = () => selectProductOrder('lowestPrice');

  return (
    <ChipMenu
      sx={{
        mt: 2,
      }}
      isOpen={menuOpen}
      onToggle={setMenuOpen}
      value={`${t('common.sortBy')} ${selectedProductOrder}`}
      disabled={disabled}
      hasArrow
    >
      <MenuItem key="mostRelevant" onClick={sortByMostRelevant}>
        {t('plp.productOrder.mostRelevant')}
      </MenuItem>
      <MenuItem key="highestPrice" onClick={sortByHighestPrice}>
        {t('plp.productOrder.highestPrice')}
      </MenuItem>
      <MenuItem key="lowestPrice" onClick={sortByLowestPrice}>
        {t('plp.productOrder.lowestPrice')}
      </MenuItem>
    </ChipMenu>
  );
}
