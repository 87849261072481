import React, { ComponentProps } from 'react';
import {
  Box,
  Brand,
  CircleIcon,
  DateTime,
  DateTimeFormat,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useIsMobile,
} from '@clippings/paper';
import { Order, OrderStatus } from 'libs/api/order/types';
import { OrderStaffDeliveryDates } from './OrderStaffDeliveryDates';
import { Row } from 'libs/shared';
import { useAppConfiguration } from 'libs/providers';
import { useOrderStatusMap } from 'libs/Orders/utils/useOrderStatusMap';
import { useTranslation } from 'react-i18next';

interface OrderDeliveryTimelineProps {
  order: Order;
}

export const OrderDeliveryTimeline: React.FC<OrderDeliveryTimelineProps> = ({ order }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isFomcore = useAppConfiguration()?.brand === Brand.Fomcore;
  const orderStatusMap = useOrderStatusMap();

  if (order.status === OrderStatus.Cancelled) {
    return null;
  }

  const { metadata } = order;

  const shouldRenderStaffDeliveryDate =
    isFomcore && (!!metadata?.staffNeedByDate || !!metadata?.staffShipByDate);
  const shouldRenderEstimatedDeliveryDate = !isFomcore && !!order.estimatedDeliveryDate;
  const shouldRenderDeliveryDate =
    shouldRenderStaffDeliveryDate || shouldRenderEstimatedDeliveryDate;
  const deliveryDatesLabel = shouldRenderStaffDeliveryDate
    ? t('common.shippingInformation')
    : t('common.estimatedDelivery');
  const stepperOrientation = isMobile ? 'vertical' : 'horizontal';
  const StepContentComponent = stepperOrientation === 'vertical' ? StepContent : Box;

  return (
    <Box
      border="1px solid"
      borderColor="grey.A200"
      borderRadius={1}
      data-testid="order-delivery-timeline"
    >
      <Box bgcolor="common.white" px={2} pb={4} pt={3}>
        <Stepper
          activeStep={order.statuses.findIndex(x => !x.isPassed) - 1}
          alternativeLabel={!isMobile}
          connector={
            <StepConnector sx={{ '& .MuiStepConnector-line': { borderColor: 'grey.A200' } }} />
          }
          orientation={stepperOrientation}
        >
          {order.statuses.map(({ date, isPassed, status }, idx) => {
            const {
              timeline: { info, labelKey, icon },
            } = orderStatusMap[status];

            return (
              <Step key={status}>
                <StepLabel
                  StepIconComponent={() => (
                    <Box mt={styles.labelIconBoxMt}>
                      {isPassed ? icon : <CircleIcon color="disabled" sx={styles.iconSx} />}
                    </Box>
                  )}
                  sx={styles.stepLabelSx}
                >
                  <Typography
                    data-testid={`order-timeline-${status}-title`}
                    sx={styles.labelTypographySx}
                  >
                    {t(labelKey)}
                  </Typography>
                </StepLabel>
                <StepContentComponent sx={styles.stepContentSx}>
                  {info && (
                    <Typography
                      data-testid={`order-timeline-${status}-subtitle`}
                      color={info.color}
                      sx={styles.infoTypographySx}
                    >
                      {t(info.labelKey)}
                    </Typography>
                  )}
                  {date && (
                    <DateTime
                      format={DateTimeFormat.Date}
                      date={date}
                      data-testid={`order-dt-status-${status}-${idx}`}
                      color="text.secondary"
                      variant="caption"
                    />
                  )}
                </StepContentComponent>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      {shouldRenderDeliveryDate && (
        <Box display="flex" flexDirection="column" p={2}>
          <Typography color="text.secondary" variant="caption">
            {deliveryDatesLabel}:
          </Typography>
          {/* The two conditions are mutually exclusive */}
          {shouldRenderStaffDeliveryDate && <OrderStaffDeliveryDates metadata={metadata} />}
          {shouldRenderEstimatedDeliveryDate && (
            <Row alignItems="center" color="success.main">
              <DateTime
                format={DateTimeFormat.LongDayMonthYear}
                date={order.estimatedDeliveryDate!.from}
                data-testid="order-dt-estimated-delivery-date-from"
                color="success.main"
                variant="caption"
                fontWeight="bold"
              />
              &nbsp;{'-'}&nbsp;
              <DateTime
                format={DateTimeFormat.LongDayMonthYear}
                date={order.estimatedDeliveryDate!.to}
                data-testid="order-dt-estimated-delivery-date-to"
                color="success.main"
                variant="caption"
                fontWeight="bold"
              />
            </Row>
          )}
        </Box>
      )}
    </Box>
  );
};

type TypographySx = ComponentProps<typeof Typography>['sx'];

const styles = {
  iconSx: {
    fontSize: 16,
  },
  stepLabelSx: {
    marginLeft: {
      xs: 0.5,
      md: 0,
    },
  },
  labelIconBoxMt: {
    xs: 1,
    md: 0.3,
  },
  labelTypographySx: {
    color: theme => theme.palette.secondary.dark,
    fontWeight: 600,
    fontSize: theme => theme.typography.pxToRem(14),
  } as TypographySx,
  infoTypographySx: {
    py: 0.5,
    fontSize: theme => theme.typography.pxToRem(12),
  } as TypographySx,
  stepContentSx: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: {
      md: 'center',
    },
    margin: {
      md: 0,
    },
    padding: {
      md: 0,
    },
    textAlign: {
      md: 'center',
    },
    border: {
      md: 'none',
    },
  },
};
