import React, { FC } from 'react';
import { Skeleton } from '@clippings/paper';
import { SummaryView } from './SummaryView';
import { useQuoteProvider } from './providers';

interface QuoteSummaryProps {
  readonly?: boolean;
  textOnly?: boolean;
  renderPaymentTerms?: boolean;
}

export const QuoteSummary: FC<QuoteSummaryProps> = ({
  readonly = false,
  textOnly = false,
  renderPaymentTerms,
}) => {
  const {
    quote,
    loading,
    paymentTermsQuery,
    addressSubmitLoading,
    updateWithoutOptimisticLoading,
    handleShippingModalOpen,
    handleQuoteUpdate,
  } = useQuoteProvider();

  if (loading) {
    return (
      <Skeleton
        sx={styles.skeletonSx}
        height="350px"
        variant="rectangular"
        data-testid="quote-summary-loader"
      />
    );
  }

  return (
    <SummaryView
      item={quote}
      paymentTerms={paymentTermsQuery.data ?? []}
      paymentTermsLoading={paymentTermsQuery.isLoading}
      addressSubmitLoading={addressSubmitLoading}
      isLoading={updateWithoutOptimisticLoading}
      readonly={readonly}
      textOnly={textOnly}
      quoteMetadata={quote.metadata}
      renderPaymentTerms={renderPaymentTerms}
      handleShippingModalOpen={handleShippingModalOpen}
      onQuoteUpdate={handleQuoteUpdate}
    />
  );
};

const styles = {
  skeletonSx: {
    width: { xs: '100%', md: '500px' },
  },
};
