import {
  QUOTES_PAGE_STATUS_MAP,
  QUOTES_TABLE_SORT_DIRECTIONS,
  QuotesFilters,
  QuotesPage,
  QuotesQueryParams,
  QuotesTableFilter,
  QuotesTableSort,
  SORT_PARAMS,
  TABLE_COLUMNS,
  defaultSort,
} from '../types';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { filterNumbers, isValidType, notEmpty } from 'libs/shared/utils';

export const parseStatusValues = (values: unknown[], page: QuotesPage): QuoteListItemStatus[] => {
  const pageStatuses = QUOTES_PAGE_STATUS_MAP[page];
  const filtered = values.filter(notEmpty);
  const statuses = filtered.filter((value): value is QuoteListItemStatus =>
    isValidType(value, pageStatuses)
  );

  return statuses;
};

/**
 * Reads, validates and returns query params for valid sort keys and values
 */
export const parseSortQueryParams = (params: QuotesQueryParams): QuotesTableSort =>
  Object.keys(params).reduce(
    (obj, key) => {
      if (isValidType(key, SORT_PARAMS)) {
        const value = params[key];
        if (isValidType(value, TABLE_COLUMNS)) {
          obj.sort = value;
        }
        if (isValidType(value, QUOTES_TABLE_SORT_DIRECTIONS)) {
          obj.order = value;
        }
      }

      return obj;
    },
    { ...defaultSort }
  );

/**
 * Returns null-safe validated values for sales app quotes table filters
 *
 * Returns only the first valid value from each query param
 *
 * (V1.1) Remove slices, adjust for multiple params
 */
export const parseFilterQueryParams = (
  params: QuotesQueryParams,
  quotesPage: QuotesPage
): QuotesFilters => ({
  [QuotesTableFilter.TeamsOfUser]: filterNumbers(params[QuotesTableFilter.TeamsOfUser] ?? []).slice(
    0,
    1
  ),
  [QuotesTableFilter.Assignee]: filterNumbers(params[QuotesTableFilter.Assignee] ?? []).slice(0, 1),
  [QuotesTableFilter.Status]: parseStatusValues(
    params[QuotesTableFilter.Status] ?? [],
    quotesPage
  ).slice(0, 1),
});
