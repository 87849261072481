import React from 'react';
import { ArrowBackIcon, Box, Button, Typography, useTranslation } from '@clippings/paper';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from 'libs/Constants';

export function BackButton() {
  const { t } = useTranslation();

  return (
    <Box borderBottom={1} borderColor="divider" py={1}>
      <Button
        component={RouterLink}
        to={routes.salesApp.companies}
        variant="text"
        aria-label="back"
        color="secondary"
        startIcon={<ArrowBackIcon />}
      >
        <Typography variant="body2">{t('common.companies')}</Typography>
      </Button>
    </Box>
  );
}
