import { Location, matchRoutes, useLocation } from 'react-router-dom';
import { MutableRefObject, useEffect } from 'react';
import { routes } from 'libs/Constants';

// NOTE: `ScrollIntoViewOptions` is not supported in Safari. `scrollIntoView` still works but ignores the passed options (https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView)
export const SCROLL_INTO_VIEW_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'smooth',
  inline: 'center',
  block: 'center',
};

// Config
const SCROLL_RESET_CUSTOMER_APP_ROUTES = [
  routes.quotes.show,
  routes.account.show,
  routes.orders.show,
  routes.orders.list,
  routes.quotes.list,
  routes.product,
] as const;

const SCROLL_RESET_SALES_APP_ROUTES = [
  routes.salesApp.quotes.show,
  routes.salesApp.userDetails,
  routes.salesApp.company,
  routes.salesApp.companies,
  routes.salesApp.orders.list,
  routes.salesApp.staffUsers,
  routes.salesApp.users,
] as const;

export const SCROLL_RESET_CONFIG = [
  ...SCROLL_RESET_CUSTOMER_APP_ROUTES,
  ...SCROLL_RESET_SALES_APP_ROUTES,
] as const;

export const shouldResetScrollPosition = (location: Location): boolean => {
  const matched = matchRoutes(
    SCROLL_RESET_CONFIG.map(x => ({ path: x })),
    location
  );
  return !!matched;
};

/**
 * Resets scroll position to the top of the layout component if current location path is in config array
 * @param ref Component ref
 */
export const useResetScroll = (ref?: MutableRefObject<HTMLDivElement | undefined>) => {
  const location = useLocation();

  useEffect(() => {
    if (ref?.current && location && shouldResetScrollPosition(location)) {
      ref.current.scrollTo({ top: 0, left: 0, ...SCROLL_INTO_VIEW_OPTIONS, behavior: 'auto' });
    }
  }, [ref, location?.pathname]);
};
