import { CLEAN_FILTER_PARAMS, QUOTES_QUERY_PARAMS_MAP, QuotesFilters } from '../../types';
import { QuotesPage } from '../../types';
import { parseFilterQueryParams } from '../quotesParamUtils';
import { useQueryParams } from 'use-query-params';
import { useState } from 'react';

export function useTableFilters(page: QuotesPage) {
  const [queryParams, setQueryParams] = useQueryParams(QUOTES_QUERY_PARAMS_MAP);
  const [filters, setFilters] = useState<QuotesFilters>(parseFilterQueryParams(queryParams, page));

  const updateFilters = (updated: QuotesFilters) => {
    setQueryParams(updated);
    setFilters(current => ({ ...current, ...updated }));
  };

  const clearFilters = () => {
    setQueryParams(CLEAN_FILTER_PARAMS);
    setFilters(CLEAN_FILTER_PARAMS);
  };

  return {
    filters,
    queryParams,
    setQueryParams,
    updateFilters,
    clearFilters,
  };
}
