import React, { FC, useMemo, useState } from 'react';
import { Address } from 'libs/api/common/types';
import { AddressContext } from '../AddressContext';
import { AddressDialogHeader } from '../AddressDialogHeader';
import { AddressList } from '../AddressList';
import { AddressTypesEnum } from '../../hooks/useAddresses';
import {
  Alert,
  Box,
  Brand,
  Checkbox,
  FieldErrors,
  LocalShippingIcon,
  getFormErrorMessage,
} from '@clippings/paper';
import { CreateShippingAddressForm } from '../Form/CreateShippingAddressForm';
import { CustomerPickupForm } from './CustomerPickupForm';
import { DeleteDialog } from '../DeleteDialog';
import { DeliveryMethods } from './DeliveryMethods';
import { FormModal, FormModalProps } from 'libs/Components';
import { ModalTitle } from '../ModalTitle';
import { Nullable } from 'libs/Utils';
import { QuoteAddressFormFields } from '../Form/QuoteAddressFormFields';
import { SCREENS } from '../hooks/useAddressScreenReducer';
import { SameAsBilling } from './SameAsBilling';
import { ShippingTabs } from './ShippingTabs';
import { SnackbarStateProps } from '../../QuoteContext';
import { TYPE_HANDLERS, usePostSubmitHandler } from '../hooks/usePostSubmitHandler';
import {
  deliveryMethodValidationSchema,
  getValidationSchemas,
  shippingAddressListValidationSchema,
} from '../Form/addressValidationSchema';
import { getEditAddressesDefaultValue } from '../utils';
import { getServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { useAddress } from 'libs/Quotes/QuoteAddresses/hooks/useAddress';
import { useAppConfiguration } from 'libs/providers';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

type DataModel = {
  content: JSX.Element;
  title: JSX.Element;
  formProps: FormModalProps<any>['formProps'];
  actionProps: FormModalProps<any>['actionProps'];
};

export const ShippingDialog: FC<SnackbarStateProps> = ({ handleShowSnackbar }) => {
  const { t } = useTranslation();
  const { brand } = useAppConfiguration();
  const [exWorksFormErrors, setExWorksFormErrors] = useState<FieldErrors | null>(null);

  const {
    quote,
    isShippingModalOpen,
    handleShippingModalClose,
    handleQuoteAddressChange,
    locationQuery,
    addressSubmitLoading,
    shippingAddress,
    paymentTermsQuery,
    updateAddressMutationLoading,
    isDetailedShippingModal,
  } = useQuoteProvider();

  const { shippingAddressValidationSchema, customerPickupValidationSchema } = useMemo(
    () => getValidationSchemas(brand, isDetailedShippingModal),
    [brand, isDetailedShippingModal]
  );
  const { onModalClose } = usePostSubmitHandler();

  const {
    currentScreen,
    handleBack,
    handleOpenCreateNewAddress,
    handleOpenShippingType,
    handleOpenSameAsBilling,
    selectedAddress,
    handleOpenEditAddress,
    isDeleteModalOpen,
    openDeleteModal,
    closeDeleteModal,
    hasBack,
    handleCreateAddress,
    handleEditAddress,
    handleMakeDefault,
    handleOpenList,
    countries,
    states,
    handleDeleteAddress,
    updateAddressFormErrors,
    createAddressFormErrors,
    handleOpenCustomerPickup,
  } = useAddress({
    currentQuoteAddress: quote.shippingAddress,
    ...shippingAddress,
    handleQuoteAddressChange,
    locationQuery,
    paymentTermsQuery,
    isDetailedShippingModal,
  });

  const handleOnAddressCreate = () => {
    handleShowSnackbar(t('common.addressUpdated'));
    handleOpenShippingType();
  };

  const {
    companyAddressQuery,
    quoteAddressQuery,
    evaluateDeliveryMethodsQuery,
    handleDeliveryMethodSelect,
  } = shippingAddress;

  //If we start serving different delivery methods based on the address, we need to update this
  const autoDeliveryMethod = evaluateDeliveryMethodsQuery.data?.items?.length === 1;

  const handleCreateSameAsBilling = () => {
    handleCreateAddress(
      {
        ...quote.billingAddress,
        country: quote.billingAddress?.country?.shortName ?? '',
        state: quote.billingAddress?.state?.id ?? '',
        nickname: quote.billingAddress?.companyName,
      },
      AddressTypesEnum.QUOTE,
      autoDeliveryMethod
        ? () => {
            onModalClose(TYPE_HANDLERS.SHIPPING);
          }
        : handleOnAddressCreate
    );
  };

  const handleSelectAddress = (data: { shippingAddress: { id: number } }) => {
    if (data.shippingAddress.id !== quote.shippingAddress?.id || quote.deliveryItems.length === 0) {
      handleQuoteAddressChange(
        data,
        autoDeliveryMethod ? () => onModalClose(TYPE_HANDLERS.SHIPPING) : handleOnAddressCreate
      );
      return;
    }

    if (autoDeliveryMethod) {
      onModalClose(TYPE_HANDLERS.SHIPPING);
      return;
    }

    handleOpenShippingType();
  };

  const handleCustomerPickup = (data: { contactName: string; phone: string; email: string }) => {
    shippingAddress.handlers.handleExWorksCreate(
      data,
      AddressTypesEnum.QUOTE,
      newAddress => {
        handleQuoteAddressChange(newAddress, () => {
          companyAddressQuery.refetch();
          quoteAddressQuery?.refetch();
          onModalClose(TYPE_HANDLERS.SHIPPING);
          handleShowSnackbar(t('common.addressUpdated'));
        });
      },
      (errors?: FieldErrors) => {
        setExWorksFormErrors(getServerFormErrors(errors));
      }
    );
  };

  const handleSelectDeliveryMethod = (data: { deliveryRef: string }) => {
    /**
     * Close the modal without sending an API request
     * if the delivery method is the same as the current one
     */
    if (data.deliveryRef === getSelectedDeliveryMethod()) {
      onModalClose(TYPE_HANDLERS.SHIPPING);
      return;
    }

    handleDeliveryMethodSelect(data, () => {
      onModalClose(TYPE_HANDLERS.SHIPPING);
      paymentTermsQuery.refetch();
    });
  };

  const getSelectedDeliveryMethod = (): string => {
    const { isLoading } = evaluateDeliveryMethodsQuery;

    if (isLoading) {
      return '';
    }

    /**
     * Not all options are returned from the /evaluate endpoint.
     * We want to check if the selected method is in the list of options and mark it.
     * Otherwise we want the value to be empty.
     *  */
    const deliveryRef = quote.deliveryItems.find(d => d.deliveryRef !== null)?.deliveryRef ?? '';
    const isDeliveryRefInMethods = evaluateDeliveryMethodsQuery.data?.items?.some(
      m => m.id === deliveryRef
    );

    return isDeliveryRefInMethods ? deliveryRef : '';
  };

  /**
   * Provide a default `string` value for the shipping address id and not `undefined`
   * For Controlled components, RHF does not allow `undefined` as a default value.
   * Using `undefined` results in an error in the console.
   * More here -> https://react-hook-form.com/api/usecontroller/controller
   */
  const addressListFormDefaultValues = useMemo(
    () => ({
      shippingAddress: { id: quote.shippingAddress?.id ? String(quote.shippingAddress?.id) : '' },
    }),
    [quote.shippingAddress?.id]
  );

  const getDialogData = (): Nullable<DataModel> => {
    if (currentScreen === SCREENS.CUSTOMER_PICKUP) {
      return {
        content: (
          <>
            <ShippingTabs value={1} onClick={handleOpenList} />
            <CustomerPickupForm />
            {exWorksFormErrors?.error && (
              <Box>
                <Alert severity="error">{getFormErrorMessage(exWorksFormErrors, 'error')}</Alert>
              </Box>
            )}
          </>
        ),
        title: (
          <AddressDialogHeader onBack={handleBack} icon={<LocalShippingIcon color="secondary" />}>
            <ModalTitle text={t('common.deliveryAddress')} />
          </AddressDialogHeader>
        ),
        formProps: {
          defaultValues: {
            email: quote?.shippingAddress?.email ?? '',
            phone: quote?.shippingAddress?.phone ?? '',
            contactName: quote?.shippingAddress?.contactName ?? '',
          },
          onSubmit: handleCustomerPickup,
          validationSchema: customerPickupValidationSchema,
        },
        actionProps: {
          submitLabel: t('common.done'),
        },
      };
    }

    const isCompanyEditable = companyAddressQuery?.data?.every((x: Address) => !!x.editable);

    if (currentScreen === SCREENS.LIST) {
      return {
        content: (
          <>
            <ShippingTabs value={0} onClick={handleOpenCustomerPickup} />
            {brand !== Brand.Fomcore && (
              <Checkbox
                onChange={() => {
                  handleOpenSameAsBilling();
                }}
                checked={false}
                label={t('quotes.addresses.sameAsBilling')}
                disabled={!quote.billingAddress?.address}
              />
            )}
            <AddressList
              type="shipping"
              name="shippingAddress[id]"
              isLoading={companyAddressQuery.isFetching || quoteAddressQuery.isFetching}
              companyAddresses={companyAddressQuery.data}
              quoteAddresses={quoteAddressQuery.data}
              quoteAddressTitle={t('quotes.addresses.quoteDeliveryAddress')}
              companyAddressTitle={t('quotes.addresses.companyDeliveryAddress')}
              defaultValues={addressListFormDefaultValues}
            />
          </>
        ),
        title: (
          <AddressDialogHeader onBack={handleBack} icon={<LocalShippingIcon color="secondary" />}>
            <ModalTitle text={t('common.deliveryAddress')} />
          </AddressDialogHeader>
        ),
        formProps: {
          defaultValues: addressListFormDefaultValues,
          onSubmit: handleSelectAddress,
          validationSchema: shippingAddressListValidationSchema,
        },
        actionProps: {
          submitLabel: t('common.next'),
        },
      };
    }

    if (currentScreen === SCREENS.DELIVERY_METHOD) {
      const defaultValues = {
        deliveryRef: getSelectedDeliveryMethod(),
      };

      return {
        content: (
          <DeliveryMethods
            evaluateDeliveryMethodsQuery={evaluateDeliveryMethodsQuery}
            formDefaultValues={defaultValues}
          />
        ),
        title: (
          <AddressDialogHeader onBack={handleBack}>
            <ModalTitle
              text={`${t('quotes.addresses.shippingTo')} ${quote.shippingAddress?.nickname}`}
            />
          </AddressDialogHeader>
        ),
        formProps: {
          onSubmit: handleSelectDeliveryMethod,
          validationSchema: deliveryMethodValidationSchema,
          defaultValues,
        },
        actionProps: {
          submitLabel: t('common.done'),
        },
      };
    }

    if (currentScreen === SCREENS.CREATE_ADDRESS) {
      return {
        content: (
          <>
            <CreateShippingAddressForm isCompanyEditable={isCompanyEditable} />
            {createAddressFormErrors.error && (
              <Box>
                <Alert severity="error">
                  {getFormErrorMessage(createAddressFormErrors, 'error')}
                </Alert>
              </Box>
            )}
          </>
        ),
        title: (
          <AddressDialogHeader onBack={handleBack} icon={<LocalShippingIcon color="secondary" />}>
            <ModalTitle text={t('common.deliveryAddress')} />
          </AddressDialogHeader>
        ),
        formProps: {
          errors: createAddressFormErrors,
          onSubmit: formData => {
            handleCreateAddress(
              formData,
              formData.saveAddress ? AddressTypesEnum.COMPANY : AddressTypesEnum.QUOTE,
              () => {
                handleShowSnackbar(t('common.addressUpdated'));
                handleOpenList('pop');
              }
            );
          },
          validationSchema: shippingAddressValidationSchema,
        },
        actionProps: {
          submitLabel: t('common.done'),
        },
      };
    }

    if (currentScreen === SCREENS.SAME_AS_BILLING) {
      return {
        content: <SameAsBilling />,
        title: (
          <AddressDialogHeader onBack={handleBack} icon={<LocalShippingIcon color="secondary" />}>
            <ModalTitle text={t('common.deliveryAddress')} />
          </AddressDialogHeader>
        ),
        formProps: {
          onSubmit: handleCreateSameAsBilling,
        },
        actionProps: {
          submitLabel: t('common.next'),
        },
      };
    }

    if (currentScreen === SCREENS.EDIT_ADDRESS && selectedAddress) {
      const defaultValues = getEditAddressesDefaultValue(selectedAddress, brand);

      return {
        content: (
          <>
            <QuoteAddressFormFields detailed={isDetailedShippingModal} />
            {updateAddressFormErrors.error && (
              <Box>
                <Alert severity="error">
                  {getFormErrorMessage(updateAddressFormErrors, 'error')}
                </Alert>
              </Box>
            )}
          </>
        ),
        title: (
          <AddressDialogHeader onBack={handleBack} icon={<LocalShippingIcon color="secondary" />}>
            <ModalTitle text={t('quotes.addresses.editAddress')} />
          </AddressDialogHeader>
        ),
        formProps: {
          errors: updateAddressFormErrors,
          defaultValues,
          validationSchema: shippingAddressValidationSchema,
          onSubmit: formData => {
            handleEditAddress(formData, isDetailedShippingModal);
          },
        },
        actionProps: {
          submitLabel: t('common.done'),
          onCancel: handleBack,
          cancelLabel: t('common.cancel'),
        },
      };
    }

    /**
     * Throw an error if the screen state is not valid instead of returning `null`
     * to avoid rendering an unwanted state of the dialog to the user.
     * The error boundary will redirect to the generic error page.
     */
    throw new Error(`${ShippingDialog.name} component - Invalid screen: ${currentScreen}`);
  };

  const data = useMemo(() => {
    return getDialogData();
  }, [currentScreen, selectedAddress, companyAddressQuery, quoteAddressQuery]);

  return (
    <AddressContext.Provider
      value={{
        handleBack,
        handleOpenCreateNewAddress,
        handleOpenShippingType,
        selectedAddress,
        handleOpenEditAddress,
        handleOpenSameAsBilling,
        addressType: 'shipping',
        isDeleteModalOpen,
        handleOpenDeleteModal: openDeleteModal,
        handleCloseDeleteModal: closeDeleteModal,
        onDelete: handleDeleteAddress,
        handleMakeDefault,
        hasBack,
        countries,
        states,
        isLoading: shippingAddress.isLoading || updateAddressMutationLoading,
      }}
    >
      <FormModal
        fullWidth
        maxWidth="sm"
        title={data?.title}
        open={isShippingModalOpen}
        onClose={handleShippingModalClose}
        actionProps={data?.actionProps ?? null}
        formProps={data?.formProps ?? null}
        formKey={`form-modal-${currentScreen}`}
        submitProps={{
          disabled: addressSubmitLoading,
          'data-testid': 'form-submit',
        }}
        isSubmitting={updateAddressMutationLoading || shippingAddress.isLoading}
      >
        {data?.content}
      </FormModal>
      {isDeleteModalOpen && <DeleteDialog />}
    </AddressContext.Provider>
  );
};
