import React, { FC } from 'react';
import { FlexColumn, LoadMoreDivider, Skeleton } from '@clippings/paper';
import { InboxRow, InboxRowItem } from 'libs/SalesApp/Inbox/InboxRow';
import { NoResultsMessage } from 'libs/SalesApp/Inbox/NoResultsMessage';
import { range } from 'lodash';
import { useTranslation } from 'react-i18next';

export type InboxSectionContentProps = {
  items: InboxRowItem[];
  loading: boolean;
  hasNextPage: boolean;
  noResultsMessage: string;
  onNextClick: () => void;
  isFetchingNextPage?: boolean;
};

const LoadingSkeleton = () => (
  <>
    {range(3).map((key, i) => (
      <Skeleton
        key={key}
        variant="rectangular"
        width="100%"
        height="82px"
        sx={{ mt: i === 0 ? 0 : 1, borderRadius: 1 }}
      />
    ))}
  </>
);

export const InboxSectionContent: FC<InboxSectionContentProps> = ({
  items,
  loading,
  noResultsMessage,
  onNextClick,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const { t } = useTranslation();
  if (loading) {
    return <LoadingSkeleton />;
  }

  if (items.length > 0) {
    return (
      <>
        <FlexColumn gap={1}>
          {items.map(item => (
            <InboxRow item={item} key={item.id} />
          ))}
        </FlexColumn>
        {isFetchingNextPage && <LoadingSkeleton />}
        <LoadMoreDivider
          className="load-more-divider"
          hasNextPage={hasNextPage}
          noMoreLabel={t('inbox.noMoreResults')}
          onClick={onNextClick}
        />
      </>
    );
  }

  return <NoResultsMessage message={noResultsMessage} />;
};
