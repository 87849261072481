import {
  ArrowBackIcon,
  Brand,
  DialogAction,
  MultiSelectValue,
  PersonIcon,
  SystemUser,
  getStaffUserValidation,
  getUserValidation,
  yup,
} from '@clippings/paper';
import { CompanyCreateForm } from 'libs/SalesApp/Companies/create';
import {
  CreateUserVariables,
  UpdateUserVariables,
  User,
  UserPermission,
  UserRole,
} from 'libs/api/iam/types';
import { CustomerUserFormValues, UserDialogForm } from '../components/userDialog/UserDialogForm';
import { MutationOptions } from 'libs/api/common/types';
import { StaffUserDialogForm } from '../staff/components/StaffUserDialogForm';
import { UserFormFieldsProps } from '../components/userDialog/UserFormFields';

export interface BaseUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  jobTitle: string;
  systemRole?: UserSystemRole | UserCustomerRole;
}

export type UserDialogFormProps<
  FormValues extends CustomerUserFormValues = CustomerUserFormValues,
  CreateVariables extends CreateUserVariables = CreateUserVariables,
  UpdateVariables extends UpdateUserVariables = UpdateUserVariables
> = {
  onCancel: () => void;
  defaultValues?: Partial<FormValues>;
  onCreateSuccess?: MutationOptions<User, CreateVariables>['onSuccess'];
  onUpdateSuccess?: MutationOptions<User, UpdateVariables>['onSuccess'];
  action: DialogAction;
  userId?: number;
} & Pick<UserFormFieldsProps, 'hiddenFields' | 'disabledFields'>;

export enum BaseUserSystemRole {
  SalesRep = 'ROLE_SALES_REP',
  SalesManager = 'ROLE_SALES_MANAGER',
  Admin = 'ROLE_ADMIN',
  BackOffice = 'ROLE_BACK_OFFICE',
}

export enum BaseCustomerRole {
  Customer = 'ROLE_BUSINESS_CUSTOMER',
}

export enum MorosoCustomerRole {
  Consolidate_Retailer = 'ROLE_CONSOLIDATE_RETAILER',
}

export enum MorosoUserSystemRole {
  A_And_D = 'ROLE_A_AND_D',
}

export enum FomcoreUserSystemRole {
  CSR = 'ROLE_CSR',
  CSRLeader = 'ROLE_CSR_LEADER',
  RegionalSalesDirector = 'ROLE_REGIONAL_SALES_DIRECTOR',
  RepGroup = 'ROLE_REP_GROUP',
}
export type UserSystemRole = BaseUserSystemRole | MorosoUserSystemRole | FomcoreUserSystemRole;
export type UserCustomerRole = BaseCustomerRole | MorosoCustomerRole;
export const baseSystemRoles = Object.values(BaseUserSystemRole);
export const baseCustomerRoles = Object.values(BaseCustomerRole);

export const fomcoreSpecificSystemRoles = Object.values(FomcoreUserSystemRole);
export const fomcoreSystemRoles = [BaseUserSystemRole.Admin, ...fomcoreSpecificSystemRoles];

export const morosoSpecificSystemRoles = Object.values(MorosoUserSystemRole);
export const morosoSpecificCustomerRoles = Object.values(MorosoCustomerRole);
export const morosoSystemRoles = [...baseSystemRoles, ...morosoSpecificSystemRoles];
export const morosoCustomerRoles = [...baseCustomerRoles, ...morosoSpecificCustomerRoles];

export const allSystemRoles = [...baseSystemRoles, ...fomcoreSystemRoles, ...morosoSystemRoles];

export const brandSystemRolesMap = {
  [Brand.Fomcore]: fomcoreSystemRoles,
  [Brand.Moroso]: morosoSystemRoles,
  [Brand.Sossego]: baseSystemRoles,
};

export const brandCustomerRolesMap = {
  [Brand.Fomcore]: baseCustomerRoles,
  [Brand.Moroso]: morosoCustomerRoles,
  [Brand.Sossego]: baseCustomerRoles,
};

export const getUserSystemRoles = (brand: Brand | null): UserSystemRole[] => {
  if (!brand) {
    return baseSystemRoles;
  }

  return brandSystemRolesMap[brand];
};

export const getUserCustomerRoles = (brand: Brand | null): UserCustomerRole[] => {
  if (!brand) {
    return baseCustomerRoles;
  }

  return brandCustomerRolesMap[brand];
};

export enum StaffUserPermission {
  /**
   * Generic permission used by all roles with access to the sales app, including ADMIN and ADAPTER
   */
  CAN_ACCESS_SALES_APP = 'PERMISSION_CAN_ACCESS_SALES_APP',
  /**
   * Permission assigned to roles dealing with sales app but are not ADMIN or ADAPTER
   */
  IS_STAFF = 'PERMISSION_IS_STAFF',
}

export const getCustomerUserValidationScheme = (brand: Brand | null) => {
  const userValidation = getUserValidation(brand);

  return {
    firstName: userValidation.firstName,
    lastName: userValidation.lastName,
    email: userValidation.email,
    company: yup.object().nullable().required('common.companyRequired'),
    phone: userValidation.phone,
    jobTitle: userValidation.jobTitle,
  };
};

const userValidationSchemaMap = {
  [SystemUser.Customer]: getCustomerUserValidationScheme,
  [SystemUser.Staff]: getStaffUserValidation,
};

export const getUserValidationSchema = (type: SystemUser, brand: Brand | null) => {
  const validationSchema = userValidationSchemaMap[type];
  return yup.object().shape(validationSchema(brand));
};

export const userRolesSystemRoleMap: Record<
  | BaseUserSystemRole
  | FomcoreUserSystemRole
  | MorosoUserSystemRole
  | MorosoCustomerRole
  | BaseCustomerRole,
  UserRole[]
> = {
  [BaseUserSystemRole.SalesRep]: [UserRole.Login, UserRole.User, UserRole.SalesRep],
  [BaseUserSystemRole.SalesManager]: [UserRole.Login, UserRole.User, UserRole.SalesManager],
  [BaseUserSystemRole.Admin]: [UserRole.Login, UserRole.User, UserRole.Admin],
  [BaseUserSystemRole.BackOffice]: [UserRole.Login, UserRole.User, UserRole.BackOffice],
  [MorosoUserSystemRole.A_And_D]: [UserRole.Login, UserRole.User, UserRole.A_And_D],
  [FomcoreUserSystemRole.CSR]: [UserRole.Login, UserRole.User, UserRole.CSR],
  [FomcoreUserSystemRole.CSRLeader]: [UserRole.Login, UserRole.User, UserRole.CSRLeader],
  [FomcoreUserSystemRole.RegionalSalesDirector]: [
    UserRole.Login,
    UserRole.User,
    UserRole.RegionalSalesDirector,
  ],
  [FomcoreUserSystemRole.RepGroup]: [UserRole.Login, UserRole.User, UserRole.RepGroup],
  [MorosoCustomerRole.Consolidate_Retailer]: [
    UserRole.Login,
    UserRole.User,
    UserRole.BusinessCustomer,
    UserRole.ConsolidateRetailer,
  ],
  [BaseCustomerRole.Customer]: [UserRole.Login, UserRole.User, UserRole.BusinessCustomer],
};

export const getStaffUserSystemRole = (roles: UserRole[]) => {
  return allSystemRoles.find(role => {
    const userSystemRoles = userRolesSystemRoleMap[role];
    return userSystemRoles.every(userRole => roles.includes(userRole));
  });
};

export const getUserRole = (user?: User) => {
  if (!user) {
    return 'ROLE_BUSINESS_CUSTOMER';
  }

  if (user.roles.includes(UserRole.ConsolidateRetailer)) {
    return 'ROLE_CONSOLIDATE_RETAILER';
  }

  return 'ROLE_BUSINESS_CUSTOMER';
};

export const getUserFormValues = (type: SystemUser, user?: User) => {
  const baseFields = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    jobTitle: user?.jobTitle ?? '',
    systemRole: (getUserRole(user) ?? 'ROLE_BUSINESS_CUSTOMER') as UserCustomerRole,
  } as const;

  if (type === SystemUser.Customer) {
    return {
      ...baseFields,
      company: user?.company,
    };
  }

  return {
    ...baseFields,
    systemRole: user?.roles?.length ? getStaffUserSystemRole(user.roles) : undefined,
    teams:
      user?.teams.map(
        x =>
          ({
            label: x.name,
            value: x.id,
            id: x.id,
          } as MultiSelectValue)
      ) ?? [],
  };
};

export const isUserAdmin = (permissions: UserPermission[]) =>
  permissions.includes(UserPermission.CanEditAdminUsers);
export const isUserAdapter = (permissions: UserPermission[]) =>
  permissions.includes(UserPermission.SkipAdapterSync);
export const isProtectedUser = (user: User) =>
  user.email === 'admin@designtechnologies.com' || user.email === 'adapter@designtechnologies.com';

// User Dialog Settings
export enum UserDialogScreen {
  User = 'user',
  CreateCompany = 'createCompany',
}

export const userDialogSettingsMap = {
  [SystemUser.Customer]: {
    form: UserDialogForm,
    editTranslation: 'forms.editUserDetails',
    createTranslation: 'forms.createNewUser',
    emailTranslation: 'forms.customerWillReceiveEmailToLogin',
    icon: PersonIcon,
  },
  [SystemUser.Staff]: {
    form: StaffUserDialogForm,
    editTranslation: 'forms.editStaffUserDetails',
    createTranslation: 'forms.createNewStaffUser',
    emailTranslation: 'forms.staffUserWillReceiveEmailToLogin',
    icon: PersonIcon,
  },
  company: {
    form: CompanyCreateForm,
    createTranslation: 'forms.createNewCompany',
    icon: ArrowBackIcon,
  },
} as const;
