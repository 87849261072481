import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames';
import { Box } from '@clippings/paper';
import './CheckoutIconBase.scss';

export const CheckoutIconBase: FC<ComponentProps<typeof Box>> = ({
  className,
  children,
  ...rest
}) => (
  <Box
    data-testid="checkout-icon-base"
    className={classNames('checkout-icon-base', className)}
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...rest}
  >
    {children}
  </Box>
);
