import React, { FC } from 'react';
import {
  DialogAction,
  FormMultiSelectField,
  FormTextField,
  MenuItem,
  SystemUser,
  useFormContext,
} from '@clippings/paper';
import { FormCompanyTypeahead } from '../../customer/components/FormCompanyTypeahead';
import { FormCompanyTypeaheadPaper } from '../../customer/components/FormCompanyTypeaheadPaper';
import { FormPhoneField, renderLoadingInput, useUserPermissions } from 'libs/shared';
import { TeamV2, UserPermission } from 'libs/api/iam/types';
import { getUserCustomerRoles, getUserSystemRoles } from '../../utils/user.utils';
import { useAppConfiguration } from 'libs/providers';
import { useCreateUserCompany } from '../../providers/CreateUserCompanyProvider';
import { useTranslation } from 'react-i18next';

export enum UserFormFieldName {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
  JobTitle = 'jobTitle',
  Company = 'company',
  SystemRole = 'systemRole',
  Teams = 'teams',
}

export type UserFormFieldsProps = {
  type: SystemUser;
  action: DialogAction;
  teams?: TeamV2[];
  hiddenFields?: UserFormFieldName[];
  disabledFields?: Partial<Record<UserFormFieldName, boolean>>;
};

export const UserFormFields: FC<UserFormFieldsProps> = ({
  type,
  action,
  teams = [],
  hiddenFields = [],
  disabledFields = {},
}) => {
  const { brand } = useAppConfiguration();
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  const { onCreateNewCompanyClick, setCompanySearchValue, companySearchValue } =
    useCreateUserCompany();

  const userPermissions = useUserPermissions();
  let roles;
  if (type === SystemUser.Customer) {
    roles = getUserCustomerRoles(brand);
  } else {
    roles = getUserSystemRoles(brand);
  }

  const createNewCompanyClickHandler = () => {
    const formValues = getValues();
    onCreateNewCompanyClick?.(formValues);
  };

  const fields: {
    component: JSX.Element;
    name: UserFormFieldName;
    form: SystemUser[];
    formAction: DialogAction[];
  }[] = [
    {
      component: (
        <FormTextField
          name={UserFormFieldName.FirstName}
          label={t('common.firstName')}
          disabled={!!disabledFields[UserFormFieldName.FirstName]}
        />
      ),
      name: UserFormFieldName.FirstName,
      form: [SystemUser.Staff, SystemUser.Customer],
      formAction: [DialogAction.Create, DialogAction.Edit],
    },
    {
      component: (
        <FormTextField
          name={UserFormFieldName.LastName}
          label={t('common.lastName')}
          disabled={!!disabledFields[UserFormFieldName.LastName]}
        />
      ),
      name: UserFormFieldName.LastName,
      form: [SystemUser.Staff, SystemUser.Customer],
      formAction: [DialogAction.Create, DialogAction.Edit],
    },
    {
      component: (
        <FormTextField
          name={UserFormFieldName.Email}
          label={t('common.email')}
          disabled={!!disabledFields[UserFormFieldName.Email]}
        />
      ),
      name: UserFormFieldName.Email,
      form: [SystemUser.Staff, SystemUser.Customer],
      formAction: [DialogAction.Create, DialogAction.Edit],
    },
    {
      component: (
        <FormPhoneField
          name={UserFormFieldName.Phone}
          label={t('common.phoneNumber')}
          disabled={!!disabledFields[UserFormFieldName.Phone]}
        />
      ),
      name: UserFormFieldName.Phone,
      form: [SystemUser.Staff, SystemUser.Customer],
      formAction: [DialogAction.Create, DialogAction.Edit],
    },
    {
      component: (
        <FormTextField
          name={UserFormFieldName.JobTitle}
          label={t(type === SystemUser.Customer ? 'common.position' : 'common.companyRole')}
          disabled={!!disabledFields[UserFormFieldName.JobTitle]}
        />
      ),
      name: UserFormFieldName.JobTitle,
      form: [SystemUser.Staff, SystemUser.Customer],
      formAction: [DialogAction.Create, DialogAction.Edit],
    },
    {
      component: (
        <FormCompanyTypeahead
          name={UserFormFieldName.Company}
          label={t('common.company')}
          initialInputValue={companySearchValue}
          onInputChange={value => setCompanySearchValue(value)}
          required
          disabled={!!disabledFields[UserFormFieldName.Company]}
          paperComponentRender={(props, inputValue) => (
            <FormCompanyTypeaheadPaper
              onCreateNewCompanyClick={createNewCompanyClickHandler}
              canCreateCompany={userPermissions.includes(UserPermission.CanCreateCompanies)}
              searchValue={inputValue}
              {...props}
            />
          )}
        />
      ),
      name: UserFormFieldName.Company,
      form: [SystemUser.Customer],
      formAction: [DialogAction.Create],
    },
    {
      component: (
        <FormTextField
          required
          name={UserFormFieldName.SystemRole}
          label={t('common.systemRole')}
          select
          disabled={!!disabledFields[UserFormFieldName.SystemRole]}
          SelectProps={{
            // @ts-expect-error prop type has no defined data-testid prop
            'data-testid': 'user-systemRole',
          }}
        >
          {roles.map(systemRole => (
            <MenuItem data-testid={`systemRole-${systemRole}`} key={systemRole} value={systemRole}>
              {t(`roles.${systemRole}`)}
            </MenuItem>
          ))}
        </FormTextField>
      ),
      name: UserFormFieldName.SystemRole,
      form: [SystemUser.Staff, SystemUser.Customer],
      formAction: [DialogAction.Create, DialogAction.Edit],
    },
    {
      component: renderLoadingInput(
        <FormMultiSelectField
          SelectProps={{
            'data-testid': 'user-teams',
          }}
          chipContainerProps={{
            sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 },
          }}
          name={UserFormFieldName.Teams}
          label={t('common.teams')}
          disabled={!!disabledFields[UserFormFieldName.Teams]}
          options={teams.map(team => ({
            label: team.name,
            value: team.id,
            id: team.id,
          }))}
        />,
        !teams.length,
        'teams-loading'
      ),
      name: UserFormFieldName.Teams,
      form: [SystemUser.Staff],
      formAction: [DialogAction.Create, DialogAction.Edit],
    },
  ];

  return (
    <>
      {fields.map(({ component, name, form, formAction }) => {
        const isHidden =
          !form.includes(type) || !formAction.includes(action) || hiddenFields.includes(name);

        return isHidden ? null : <React.Fragment key={name}>{component}</React.Fragment>;
      })}
    </>
  );
};
