import React, { useState } from 'react';
import { AddressInfo } from './components/AddressInfo';
import { CatalogueInfo } from './components/CatalogueInfo';
import { Company } from 'libs/api/iam/types';
import { DateTime, DateTimeFormat } from '@clippings/paper';
import { DiscountsInfo } from '../../discounts';
import { GeneralCompanyInfoBox } from './components/GeneralCompanyInfoBox';
import { GeneralInfoDialog } from './components/GeneralInfoDialog';
import { InfoBox, InfoLine } from 'libs/shared';
import { PaymentTermsInfo } from './components/PaymentTermsInfo';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

export interface CompanyDetailsPanelProps {
  company: Company;
  canEditCompany: boolean;
  canEditAddresses: boolean;
  showCatalogueSection?: boolean;
}

export const CompanyDetailsPanel: React.FC<CompanyDetailsPanelProps> = ({
  company,
  canEditCompany,
  canEditAddresses,
  showCatalogueSection = true,
}) => {
  const { t } = useTranslation();
  const [generalInfoDialogOpen, setGeneralInfoDialogOpen] = useState(false);
  const appConfiguration = useAppConfiguration();

  return (
    <>
      <InfoBox
        title={t('companies.generalInformation')}
        editButtonProps={
          appConfiguration.enableCompanyEditing && canEditCompany
            ? {
                onClick: () => setGeneralInfoDialogOpen(true),
                'aria-label': 'edit-general-info',
              }
            : undefined
        }
      >
        <GeneralCompanyInfoBox company={company} />
      </InfoBox>

      <AddressInfo company={company} isEditable={canEditAddresses} />

      {showCatalogueSection && <CatalogueInfo company={company} isEditable={canEditCompany} />}

      <DiscountsInfo company={company} isEditable={canEditCompany} />

      <PaymentTermsInfo company={company} isEditable={canEditCompany} />

      <InfoBox title={t('companies.systemInformation')}>
        <InfoLine
          data-testid="company-details-infoline-createdOn"
          label={t('common.createdOn')}
          value={
            company.createdAt ? (
              <DateTime
                format={DateTimeFormat.Date}
                date={company.createdAt}
                data-testid="company-created-at"
                variant="body2"
              />
            ) : null
          }
        />
        <InfoLine
          data-testid="company-details-infoline-lastModifiedOn"
          label={t('common.lastModifiedOn')}
          value={
            company.updatedAt ? (
              <DateTime
                format={DateTimeFormat.Date}
                date={company.updatedAt}
                data-testid="company-updated-at"
                variant="body2"
              />
            ) : null
          }
          divider={false}
        />
      </InfoBox>

      {/* start editors section */}
      <GeneralInfoDialog
        company={company}
        open={generalInfoDialogOpen}
        onClose={() => setGeneralInfoDialogOpen(false)}
      />
    </>
  );
};
