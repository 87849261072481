import { ProductHit } from 'libs/Quotes/quote.types';

export type CustomItemHit = {
  name: string;
  description: string;
};

export type ProductOrder = 'mostRelevant' | 'highestPrice' | 'lowestPrice';

export const isProductHit = (hit: ProductHit | CustomItemHit): hit is ProductHit => 'skus' in hit;
export const isCustomItemHit = (hit: ProductHit | CustomItemHit): hit is CustomItemHit =>
  !isProductHit(hit);

export interface ConfiguredProduct {
  productId: string;
  productName: string;
  existingSelection?: string;
  existingQuantity?: number;
  showGroupedProducts?: boolean;
  quoteItemId?: number;
  isExisting?: boolean;
}
