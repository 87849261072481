import React, { StrictMode } from 'react';
import { AccessDenied } from 'libs/Components/Errors/AccessDenied';
import { DefaultLayout } from 'libs/DefaultLayout';
import { GenericError } from 'libs/Components/Errors/GenericError';
import { GuestQuotePage } from 'libs/guest';
import { GuestRegister } from 'libs/Authentication/GuestRegister/GuestRegister';
import { OrderDetailsPage } from 'libs/Orders/OrderDetails/OrderDetailsPage';
import { OrderPaymentPage } from 'libs/Orders/OrderPayment/OrderPaymentPage';
import { OrderPdfExportPage } from 'libs/Orders';
import { QuoteCheckoutPage } from 'libs/Quotes/Checkout';
import { QuotePdfExportPage } from 'libs/Quotes/PdfExport/QuotePdfExportPage';
import { Route } from 'react-router-dom';
import { SentryRoutes } from 'libs/Components/Errors/Sentry/SentryRoutes';
import { authenticationRoutes } from 'libs/Authentication';
import { routes } from 'libs/Constants';

export function GuestRouter() {
  return (
    <StrictMode>
      <SentryRoutes>
        {authenticationRoutes}
        <Route path={routes.guestApp.auth.register} element={<GuestRegister />} />
        <Route path={routes.guestApp.quotes.pdf} element={<QuotePdfExportPage />} />
        <Route path={routes.guestApp.orders.pdf} element={<OrderPdfExportPage />} />
        <Route path={routes.home} element={<DefaultLayout />}>
          <Route path={routes.guestApp.index}>
            <Route path={routes.guestApp.quotes.show} element={<GuestQuotePage />} />
            <Route path={routes.guestApp.quotes.checkout} element={<QuoteCheckoutPage />} />
            <Route path={routes.guestApp.orders.show} element={<OrderDetailsPage />} />
            <Route path={routes.guestApp.orders.pay} element={<OrderPaymentPage />} />
          </Route>
          <Route path={routes.accessDenied} element={<AccessDenied to={routes.salesApp.index} />} />
          <Route path={routes.genericError} element={<GenericError to={routes.salesApp.index} />} />
        </Route>
      </SentryRoutes>
    </StrictMode>
  );
}
