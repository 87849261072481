import React, { useEffect, useState } from 'react';
import { ALLOWED_ACTIONS } from '../constants';
import { Box, CircularProgress, Typography } from '@clippings/paper';
import { Channel, Chat, MessageInput, MessageList, Window } from 'stream-chat-react';
import { ChatMessageSimple } from './messages/simple/ChatMessageSimple';
import { ChatSystemMessage } from './messages/system/ChatSystemMessage';
import { useChatChannel, useChatClient } from '../providers';
import { useGetSystemMessageDateTime } from 'libs/Chat/utils/chatUtils';
import { useTranslation } from 'react-i18next';
import './ChatChannel.scss';
import '@stream-io/stream-chat-css/dist/css/index.css';

export const ChatChannel = () => {
  const { t } = useTranslation();
  const { error: clientError, client, connected } = useChatClient();
  const {
    error: channelError,
    channel,
    loading: channelLoading,
    addToCurrentChannel,
  } = useChatChannel();
  const { getSystemMessageDateTime } = useGetSystemMessageDateTime();
  const [addToChatLoading, setAddToChatLoading] = useState(true);

  useEffect(() => {
    addToCurrentChannel();
  }, []);

  useEffect(() => {
    if (!channelError) {
      setAddToChatLoading(false);
    }
  }, [channelError]);

  useEffect(() => {
    if (connected) {
      setAddToChatLoading(false);
    }
  }, [connected]);

  const loading = channelLoading || addToChatLoading || !channel;

  const getErrorMessage = () => {
    if (clientError || !client) {
      return t('chat.generalError');
    }
  };

  if (loading || !connected) {
    return (
      <Box margin="auto">
        <CircularProgress data-testid="chat-loading-indicator" />
      </Box>
    );
  }

  const errorMessage = getErrorMessage();

  if (errorMessage) {
    return (
      <Box data-testid="chat-channel-error">
        <Typography textAlign="center" p={3} sx={{ color: theme => theme.palette.error.main }}>
          {errorMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <Chat client={client}>
      <Channel Message={ChatMessageSimple} MessageSystem={ChatSystemMessage} channel={channel}>
        <Window>
          <MessageList messageActions={ALLOWED_ACTIONS} formatDate={getSystemMessageDateTime} />
          <MessageInput focus />
        </Window>
      </Channel>
    </Chat>
  );
};
