import React, { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { Quote } from 'libs/api/quotes/types';
import { useProductConfigurator } from 'libs/Quotes/Components/PdpConfigurator/useProductConfigurator';

type ProductConfigurationContextType = {
  openPLP: () => void;
  closePLP: () => void;
  showPLP: boolean;
} & ReturnType<typeof useProductConfigurator>;

type ProductConfigurationProviderProps = {
  quote: Quote;
};

const ProductConfigurationContext = createContext({} as ProductConfigurationContextType);

export function ProductConfigurationProvider({
  quote,
  children,
}: PropsWithChildren<ProductConfigurationProviderProps>) {
  // TODO: the content of the provider could be reconsidered even more
  const [showPLP, setShowPLP] = useState(false);

  const openPLP = () => setShowPLP(true);
  const closePLP = () => setShowPLP(false);

  const productConfig = useProductConfigurator(quote, closePLP);

  const value = useMemo(() => {
    const context: ProductConfigurationContextType = {
      ...productConfig,
      showPLP,
      openPLP,
      closePLP,
    };

    return context;
  }, [productConfig, showPLP, openPLP, closePLP]);

  return (
    <ProductConfigurationContext.Provider value={value}>
      {children}
    </ProductConfigurationContext.Provider>
  );
}

export function useProductConfiguration() {
  const context = useContext(ProductConfigurationContext);

  if (!context) {
    throw new Error('useProductConfiguration must be used within a ProductConfigurationProvider');
  }

  return context;
}
