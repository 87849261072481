import React, { PropsWithChildren } from 'react';
import { Caption } from './layout/Caption';
import { Card } from '../Card';
import { Column, Row } from 'libs/shared';
import { Label } from './layout/Label';

export type ListCardItemBaseViewModel = {
  id: number;
  position: number;
  number: string;
  label: string;
};

export type ListItemCardProps<T> = {
  item: T;
  actions?: (item: T) => JSX.Element;
  onClick?: () => void;
};

export function ListItemCard<T extends ListCardItemBaseViewModel>({
  item,
  actions,
  onClick,
  children,
}: PropsWithChildren<ListItemCardProps<T>>) {
  return (
    <Card onClick={onClick}>
      <Column>
        <Row justifyContent="space-between" mb={2}>
          <Column>
            <Label>{item.number}</Label>
            <Caption>{item.label}</Caption>
          </Column>
          {actions ? actions(item) : null}
        </Row>
        {children}
      </Column>
    </Card>
  );
}
