import React, { FC } from 'react';
import {
  Box,
  ChipButton,
  ChipDatePicker,
  DateTimeFormat,
  LocalShippingIcon,
  ReceiptLongIcon,
} from '@clippings/paper';
import { CatalogueSelect } from '../../CatalogueSelect';
import { DetailedQuoteActionsStackLoading } from './DetailedQuoteActionsStackLoading';
import { NewQuoteTooltip, QuoteCustomerSelect, useNewQuoteTooltip } from '..';
import { QUOTE_STATUSES } from 'libs/Constants';
import { QuoteActionsStackContainer, menuStyle } from 'libs/Quotes/QuoteActionsStack';
import { Row, arrayHasEntities } from 'libs/shared';
import { ToggleQuoteProductDetailsButton } from '../ToggleQuoteProductDetailsButton';
import { UserChipFilter } from '../../QuotesTable/components';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useQuoteSalesReps } from '../../hooks/useQuoteSalesReps';
import { useTranslation } from 'react-i18next';

export type DetailedQuoteActionsStackProps = {
  onNewCustomerClick: () => void;
};

export const DetailedQuoteActionsStack: FC<DetailedQuoteActionsStackProps> = ({
  onNewCustomerClick,
}) => {
  const {
    quote,
    loading,
    addressSubmitLoading,
    optimisticLoading,
    updateWithoutOptimisticLoading,
    isReadonly,
    handleQuoteUpdate,
    handleBillingModalOpen,
    handleShippingModalOpen,
    handleNonContractualUpdate,
  } = useQuoteProvider();

  const { t } = useTranslation();
  const [fetchMembers, staffMembersQuery] = useQuoteSalesReps();

  const { showCatalogueTooltip, showCustomerTooltip, onTooltipClose } = useNewQuoteTooltip(
    quote,
    loading || optimisticLoading || updateWithoutOptimisticLoading
  );

  const handleCatalogueChange = (slug: string) => {
    handleQuoteUpdate({ catalogue: slug });
  };

  const handleExpireChange = (expireDate: Date | null | undefined) => {
    if (expireDate) {
      handleQuoteUpdate({ expiresAt: expireDate.toDateString() }, { withOptimisticUpdate: false });
    }
  };

  if (loading) {
    return <DetailedQuoteActionsStackLoading />;
  }

  const isCatalogueSelectDisabled =
    arrayHasEntities(quote.quoteItemProducts) || !quote.customerCompanyId;

  const getShippingAddressValue = () => {
    if (quote?.shippingAddress?.exWorks) {
      return t('quotes.addresses.customerPickup');
    }

    return quote?.shippingAddress?.nickname ?? '';
  };

  /**
   * Set the Quote assignee Select to Readonly mode if the user doesn't have permission to edit non-contractual data
   * and the quote is in the ORDERED or ARCHIVED status
   */
  const isQuoteAssigneeSelectReadonly =
    !quote.canEditNonContractual ||
    quote.status === QUOTE_STATUSES.ORDERED ||
    quote.status === QUOTE_STATUSES.ARCHIVED;

  return (
    <QuoteActionsStackContainer>
      <Row gap={1}>
        <NewQuoteTooltip
          data-testid="customer-select-tooltip"
          popperTestId="customer-select-tooltip-popper"
          open={showCustomerTooltip}
          onClose={onTooltipClose('customer')}
        >
          <QuoteCustomerSelect
            customer={quote.customer}
            readonly={isReadonly}
            onCustomerSelect={handleQuoteUpdate}
            onNewCustomerClick={onNewCustomerClick}
            onToggle={onTooltipClose('customer')}
          />
        </NewQuoteTooltip>

        <NewQuoteTooltip
          data-testid="catalogue-select-tooltip"
          popperTestId="catalogue-select-tooltip-popper"
          open={showCatalogueTooltip}
          onClose={onTooltipClose('catalogue')}
        >
          <CatalogueSelect
            slug={quote.catalogue}
            companyId={quote.customerCompanyId}
            disabled={isCatalogueSelectDisabled}
            readonly={isReadonly}
            onSelect={handleCatalogueChange}
            onClick={onTooltipClose('catalogue')} // needed due to clicking on the arrow down icon not propagating click event (not calling onClose from parent)
          />
        </NewQuoteTooltip>

        <ChipButton
          adornment={<ReceiptLongIcon key="billing-address" color="secondary" />}
          placeholder={t('quotes.addresses.addBillingDetails')}
          value={quote.billingAddress?.companyName}
          label={t('quotes.addresses.billTo')}
          hasArrow={!isReadonly && !!quote.billingAddress?.companyName}
          disabled={isReadonly || addressSubmitLoading}
          data-testid="quote-change-billing-address"
          sx={menuStyle}
          onClick={() => handleBillingModalOpen()}
        />

        <ChipButton
          adornment={<LocalShippingIcon key="delivery" color="secondary" />}
          placeholder={t('quotes.addresses.addDeliveryDetails')}
          label={t('common.shipTo')}
          hasArrow={!isReadonly && !!quote.shippingAddress?.nickname}
          disabled={isReadonly || addressSubmitLoading}
          data-testid="quote-change-delivery-address"
          sx={menuStyle}
          value={getShippingAddressValue()}
          onClick={() => handleShippingModalOpen()}
        />

        <ChipDatePicker
          initialDate={quote.expiresAt}
          dateFormat={DateTimeFormat.Date}
          placeholder={t('common.expiresOn')}
          label={t('common.expiresOn')}
          readonly={isReadonly || quote.expiresAt === null}
          disabled={isReadonly || addressSubmitLoading || quote.expiresAt === null}
          data-testid="quote-expires-on"
          sx={menuStyle}
          onChange={handleExpireChange}
        />
      </Row>

      <Box>
        <UserChipFilter
          data-testid="assignee-chip-filter"
          options={staffMembersQuery.data ?? []}
          readonly={isQuoteAssigneeSelectReadonly}
          selected={quote.salesRepresentative ? [quote.salesRepresentative] : []}
          onClick={fetchMembers}
          onSelect={assignee => handleNonContractualUpdate({ salesRepresentative: assignee })}
        />
        {quote?.quoteItemProducts?.length > 0 && <ToggleQuoteProductDetailsButton />}
      </Box>
    </QuoteActionsStackContainer>
  );
};
