import { OrderProductItem } from 'libs/api/order/types';
import { QuoteItemCard } from './QuoteItemCard';
import { QuoteItemViewModel } from './quoteItemCard.types';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { getQuoteItemContractFieldValue } from 'libs/shared/quote.utils';
import { makeContractFieldsValues, useQuoteTableVisibleFields } from 'libs/Quotes/QuoteTable';
import { useMemo } from 'react';

export function useQuoteItemCardRenderer(items: OrderProductItem[] | QuoteProductItem[]) {
  const contractFields = getQuoteItemContractFieldValue(items?.[0] ?? null);
  const { lineItemVisibleFields } = useQuoteTableVisibleFields(contractFields);

  const viewDataModel = useMemo(() => items.map(item => viewModelMapper(item)), [items]);

  return { QuoteItemCard, viewDataModel, lineItemVisibleFields };
}

export function viewModelMapper(item: OrderProductItem | QuoteProductItem): QuoteItemViewModel {
  const contractFieldsValues = makeContractFieldsValues(item);

  return {
    id: item.id,
    position: item.position,
    productName: item.productName ?? '',
    productId: item.productId?.toString() ?? '',
    picture: item.picture,
    sku: item.sku ?? '',
    custom: !!item.custom,
    sample: !!item.sample,
    note: item.note ?? '',
    variationName: item.variationName,
    properties: item.properties,
    leadTime: item.leadTime,
    price: item.catalogueBasePrice,
    discount: item.discountPercent,
    net: item.netPrice,
    quantity: item.quantity.toString(),
    taxRate: item.taxPercent ?? 0,
    subtotal: item.subtotal,
    contractNetPrice: contractFieldsValues.contractNetPrice,
    contractListPrice: contractFieldsValues.contractListPrice,
    contractSubTotalPrice: contractFieldsValues.contractSubTotalPrice,
  };
}
