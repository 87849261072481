import React from 'react';
import { CellConfig, SortableTable, SortingProps, useIsCurrentUserAdmin } from 'libs/shared';
import { DataTestId, useTranslation } from '@clippings/paper';
import { SortDirection } from 'libs/api/common/types';
import { StaffUserListItem } from './StaffUserListItem';
import { User } from 'libs/api/iam/types';
import { isProtectedUser } from '../../utils';

type StaffUsersListTableProps = {
  isLoading: boolean;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  users: User[];
  sort: SortingProps;
  onSort: (sort: { sort?: string; order?: SortDirection }) => void;
  onFetchNextPage: () => void;
  onUserClick: (user: User) => void;
} & DataTestId;

export function StaffUsersListTable({
  hasNextPage,
  isLoading,
  isFetchingNextPage,
  users,
  sort,
  'data-testid': dataTestId,
  onSort,
  onFetchNextPage,
  onUserClick,
}: StaffUsersListTableProps) {
  const { t } = useTranslation();
  const isAdmin = useIsCurrentUserAdmin();

  return (
    <SortableTable
      cellConfig={cellConfig}
      hasNextPage={!!hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      noMoreLabel={t('users.noMoreUsers')}
      onFetchNextPage={onFetchNextPage}
      sort={sort}
      tableHeaderColumns={19}
      rowHeight={100}
      data-testid={dataTestId}
      onSort={onSort}
    >
      {users.map(user => (
        <StaffUserListItem
          disabled={!isAdmin || isProtectedUser(user)}
          key={user.id}
          user={user}
          onClick={onUserClick}
        />
      ))}
    </SortableTable>
  );
}

export const cellConfig: CellConfig[] = [
  {
    key: 'staff-users-list-heading-name',
    columnName: 'firstName',
    label: 'common.name',
    columns: 3,
  },
  {
    key: 'staff-users-list-heading-role',
    columnName: 'role',
    label: 'users.headers.role',
    columns: 3,
  },
  {
    key: 'staff-users-list-heading-teams',
    columnName: 'teams',
    label: 'teams.teams',
    columns: 3,
  },
  {
    key: 'staff-users-list-heading-contacts',
    columnName: 'contacts',
    label: 'common.contacts',
    columns: 5,
  },
  {
    key: 'staff-users-list-heading-created-on',
    columnName: 'createdOn',
    label: 'common.createdOn',
    columns: 2,
  },
  {
    key: 'staff-users-list-heading-created-activity',
    columnName: 'activity',
    label: 'common.activity',
    columns: 2,
  },
  {
    key: 'staff-users-list-heading-status',
    columnName: 'status',
    label: 'common.status',
    columns: 2,
  },
];
