import React from 'react';
import { Box, ListItemIcon, MenuItem, TableViewIcon } from '@clippings/paper';
import { EntityDownloadMenu, PdfLinkMenuItem } from 'libs/shared';
import { noDiscountPdfVariant } from '../PdfExport/useQuotePdfViewModel';
import { path } from 'libs/Utils';
import { useExcelExport } from 'libs/Quotes/ExcelExport/useExcelExport';
import { useQuoteFooterDownloadButton } from './useQuoteFooterDownloadButton';
import { useQuoteProvider } from '../providers';
import { useTranslation } from 'react-i18next';

export const QuoteFooterDownloadButton = () => {
  const { t } = useTranslation();
  const { loading, quote, paymentTermsQuery } = useQuoteProvider();
  const { pdfRoute, shouldRenderPdfWithoutDiscount } = useQuoteFooterDownloadButton();
  const { downloadQuoteExcel } = useExcelExport(quote);

  const handleDownloadExcel = () => {
    downloadQuoteExcel(paymentTermsQuery.data ?? []);
  };

  const disabled = loading || paymentTermsQuery.isFetching;

  return (
    <EntityDownloadMenu
      disabled={disabled}
      buttonDataTestId="quote-footer-download-button"
      menuDataTestId="quote-footer-download-menu"
    >
      {({ handleClose }) => (
        <Box>
          <PdfLinkMenuItem
            to={path(pdfRoute, {
              number: quote.number,
              id: quote.id,
            })}
            label={t('common.pdf')}
            onClick={handleClose}
          />
          {shouldRenderPdfWithoutDiscount ? (
            <PdfLinkMenuItem
              to={`${path(pdfRoute, {
                number: quote.number,
                id: quote.id,
              })}?v=${noDiscountPdfVariant}`}
              label={t('quotes.pdf.pdfWithoutDiscount')}
              onClick={handleClose}
            />
          ) : null}
          <MenuItem
            onClick={() => {
              handleDownloadExcel();
              handleClose();
            }}
            data-testid="quote-footer-download-button-excel"
          >
            <ListItemIcon key="list-excel-icon">
              <TableViewIcon key="excel-icon" />
            </ListItemIcon>
            {t('common.excel')}
          </MenuItem>
        </Box>
      )}
    </EntityDownloadMenu>
  );
};
