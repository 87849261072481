import { TranslationKey } from '../../../types/react-i18next';

export const SETUP = 'setup';
export const REVIEW = 'review';
export const CONFIRM = 'confirm';

export type Step = typeof SETUP | typeof REVIEW | typeof CONFIRM;

export const STEPS: Step[] = [SETUP, REVIEW, CONFIRM];

export const STEPS_CONFIG: Record<Step, { title: TranslationKey; description: TranslationKey }> = {
  [SETUP]: {
    title: 'checkout.setupPayment.title',
    description: 'checkout.setupPayment.description',
  },
  [REVIEW]: {
    title: 'checkout.reviewOrder.title',
    description: 'checkout.reviewOrder.description',
  },
  [CONFIRM]: {
    title: 'checkout.confirmation.title',
    description: 'checkout.confirmation.description',
  },
};

export const isDisabled = (step: Step, activeStep: Step) => {
  return STEPS.indexOf(activeStep) < STEPS.indexOf(step);
};

export const isCompleted = (step: Step, activeStep: Step) => {
  return STEPS.indexOf(activeStep) > STEPS.indexOf(step);
};
export const forwardStep = (step: Step): Step => {
  return STEPS[STEPS.indexOf(step) + 1];
};

export const backStep = (step: Step): Step => {
  return STEPS[STEPS.indexOf(step) - 1];
};
