import React from 'react';
import { ComponentHeader } from './ComponentHeader';
import { Grid, GridProps } from '@clippings/paper';
import { PaymentDetails } from './paymentDetails/PaymentDetails';
import { PaymentSchedule } from './PaymentSchedule';
import { PurchaseOrder } from './PurchaseOrder';
import { useQuoteCheckoutContext } from '../providers';
import { useTranslation } from 'react-i18next';

export type SetupProps = {
  containerProps?: GridProps;
};

export const Setup: React.FC<SetupProps> = ({ containerProps }) => {
  const { t } = useTranslation();
  const { immediatePayment, amount, paymentType, hasPaymentAmount, setPaymentType } =
    useQuoteCheckoutContext();

  return (
    <Grid container {...containerProps} gap={styles.gridSx}>
      {hasPaymentAmount ? (
        <>
          <ComponentHeader
            title={t('checkout.setupPayment.title')}
            description={t('checkout.setupPayment.description')}
            mt={styles.headerMt}
          />
          <PaymentSchedule />
        </>
      ) : null}
      <PurchaseOrder />
      {immediatePayment && hasPaymentAmount ? (
        <PaymentDetails amount={amount} paymentType={paymentType} setPaymentType={setPaymentType} />
      ) : null}
    </Grid>
  );
};

const styles = {
  gridSx: {
    xs: 1,
    md: 3,
  },
  headerMt: {
    xs: 2,
    md: 6,
  },
};
