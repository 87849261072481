import React, { FC } from 'react';
import { Box, Chip, alpha } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';

export interface ChatQuoteVersionMessageProps {
  label: string;
  version: Quote['versionNumber'];
  messageId: string;
}

export const ChatQuoteVersionMessage: FC<ChatQuoteVersionMessageProps> = ({
  version,
  label,
  messageId,
}) => (
  <Box
    data-testid={`chat-quote-system-version-message-${messageId}`}
    display="flex"
    justifyContent="center"
    alignItems="center"
    marginY={2}
  >
    <Chip
      data-testid={`message-chip-${version}-${messageId}`}
      sx={{
        height: 24,
        fontWeight: 'fontWeightMedium',
        fontSize: theme => theme.typography.pxToRem(13),
        color: theme => alpha(theme.palette.common.black, 0.6),
        background: theme => alpha(theme.palette.secondary.main, 0.1),
      }}
      label={label}
    />
  </Box>
);
