import React, { FC } from 'react';
import { BaseUser } from 'libs/api/common/types';
import {
  Box,
  Button,
  PhoneIcon,
  SpeakerNotesIcon,
  Typography,
  UserAvatar,
  getUserFullName,
} from '@clippings/paper';
import { useChatVisibility } from 'libs/Chat';
import { useTranslation } from 'react-i18next';

interface OrderHelpProps {
  salesRep: BaseUser;
}

export const OrderHelp: React.FC<OrderHelpProps> = ({ salesRep }) => {
  const { t } = useTranslation();
  const { onToggle: toggleChat } = useChatVisibility();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography variant="h5" mb={1}>
          {t('common.orderHelp')}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          fontSize={theme => ({
            xs: theme.typography.pxToRem(12),
            md: theme.typography.pxToRem(16),
          })}
        >
          {t('orders.questionsHelperText')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        bgcolor="common.white"
        border="1px solid"
        borderColor="grey.A200"
        borderRadius={1}
        p={2}
        gap={2}
      >
        <Box display="flex" gap={2} alignItems="center">
          <UserAvatar user={salesRep} />
          <Typography
            variant="h6"
            fontSize={theme => ({
              xs: theme.typography.pxToRem(14),
              md: theme.typography.pxToRem(18),
            })}
          >
            {getUserFullName(salesRep)}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={3}
          ml={theme => ({
            xs: theme.typography.pxToRem(56),
            md: 0,
          })}
        >
          {salesRep.phone ? <Phone phone={salesRep.phone} /> : null}
          <Button
            variant="outlined"
            color="secondary"
            sx={{ maxWidth: 'fit-content' }}
            startIcon={<SpeakerNotesIcon />}
            onClick={toggleChat}
          >
            {t('common.sendMessage')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

interface PhoneProps {
  phone: string;
}

const Phone: FC<PhoneProps> = ({ phone }) => (
  <Box display="flex" flexDirection="row" gap={1} alignItems="center">
    <PhoneIcon color="secondary" />
    <Typography variant="body2" color="secondary" data-testid="order-help-phone">
      {phone}
    </Typography>
  </Box>
);
