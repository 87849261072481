import { QuotesTableColumn } from '../types';

export const headerCells = [
  {
    key: 'sales-quotes-table-header-col-quote-number',
    columnName: QuotesTableColumn.Quote,
    label: 'quotes.quoteNumber',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-customer',
    columnName: QuotesTableColumn.Customer,
    label: 'quotes.pdf.info.customer',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-value',
    columnName: QuotesTableColumn.Value,
    label: 'common.value',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-status',
    columnName: QuotesTableColumn.Status,
    label: 'common.status',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-recent-activity',
    columnName: QuotesTableColumn.RecentActivity,
    label: 'common.recentActivity',
    columns: 2,
  },
  {
    key: 'sales-quotes-table-header-col-recent-assignee',
    columnName: QuotesTableColumn.Assignee,
    label: 'common.assignee',
    columns: 3,
  },
] as const;
