import React from 'react';
import { AddIcon, Button } from '@clippings/paper';
import { Column, DividerGrey, useUserPermissions } from 'libs/shared';
import { CompaniesList } from './components/CompaniesList';
import { CompanyCreateDialog } from '../create';
import { CompanyCreatedSuccessfullyNotification } from './components';
import { CustomerUserDialogWrapper } from 'libs/SalesApp/Users/customer/CustomerUserDialogWrapper';
import { PageHeader } from 'libs/SalesApp/components/PageHeader';
import { UserFormFieldName } from 'libs/SalesApp/Users/components/userDialog/UserFormFields';
import { UserPermission } from 'libs/api/iam/types';
import { useAppConfiguration } from 'libs/providers';
import { useCompaniesPage } from './useCompaniesPage';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const CompaniesListPage: React.FC = () => {
  const { t } = useTranslation();
  const { enableCompanyCreation } = useAppConfiguration();
  const userPermissions = useUserPermissions();

  useTitle(t('common.companies'));

  const {
    createCompanyDialogOpen,
    newlyCreatedCompany,
    isCreateUserDialogOpen,
    companyCreateSuccessHandler,
    setIsCreateUserDialogOpen,
    setNewlyCreatedCompany,
    setCreateCompanyDialogOpen,
  } = useCompaniesPage();

  return (
    <>
      <CompanyCreatedSuccessfullyNotification
        company={newlyCreatedCompany}
        onClose={() => setNewlyCreatedCompany(undefined)}
      />
      <Column>
        <PageHeader title={t('common.companies')} titleTestId="companies-table-title">
          {enableCompanyCreation && userPermissions.includes(UserPermission.CanCreateCompanies) && (
            <Button
              data-testid="companies-create-company-button"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setCreateCompanyDialogOpen(true)}
            >
              {t('companies.newCompany')}
            </Button>
          )}
        </PageHeader>
        <DividerGrey />
        <CompaniesList setCreateCompanyDialogOpen={setCreateCompanyDialogOpen} />
        <CompanyCreateDialog
          open={createCompanyDialogOpen}
          onClose={() => setCreateCompanyDialogOpen(false)}
          onSuccess={companyCreateSuccessHandler}
        />
        <CustomerUserDialogWrapper
          isUserModalOpen={isCreateUserDialogOpen}
          setIsUserModalOpen={setIsCreateUserDialogOpen}
          disabledFields={{
            [UserFormFieldName.Company]: true,
          }}
          defaultValues={{ company: newlyCreatedCompany }}
        />
      </Column>
    </>
  );
};
