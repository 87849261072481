import React from 'react';
import { Address, AddressType } from 'libs/api/common/types';
import { AddressDialogForm } from './AddressDialogForm';
import { CloseableDialog } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { DialogTitle } from 'libs/Components/Modal/DialogTitle/DialogTitle';
import { useCountriesAndStates } from 'libs/shared';
import { useTranslation } from 'react-i18next';

export interface AddressDialogProps {
  company: Company;
  editedAddress?: Address;
  type: AddressType;
  makeDefault?: boolean;
  open: boolean;
  onClose: () => void;
}

export const AddressDialog: React.FC<AddressDialogProps> = ({
  company,
  editedAddress,
  makeDefault = false,
  type,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { countries, countriesByShortName, states } = useCountriesAndStates();
  const title = editedAddress
    ? t('quotes.addresses.editAddress')
    : {
        shipping: t('common.shippingAddress'),
        billing: t('common.billingAddress'),
      }[type];

  return (
    <CloseableDialog
      open={open}
      onClose={onClose}
      title={<DialogTitle data-testid="address-dialog-title" title={title} />}
      dialogProps={{
        PaperProps: { sx: { width: 560 } },
        'data-testid': `${editedAddress ? 'edit' : 'create'}-${type}-address-dialog`,
      }}
    >
      <AddressDialogForm
        company={company}
        editedAddress={editedAddress}
        type={type}
        makeDefault={makeDefault}
        onSuccess={onClose}
        onCancel={onClose}
        countries={countries}
        countriesByShortName={countriesByShortName}
        states={states}
      />
    </CloseableDialog>
  );
};
