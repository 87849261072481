import React from 'react';
import { BaseTeam } from 'libs/api/iam/types';
import { UserListItemSimple } from './UserListItemSimple';

interface UserListItemTeamProps {
  xs: number;
  teams: BaseTeam[];
}

export const UserListItemTeam: React.FC<UserListItemTeamProps> = ({ xs, teams }) => {
  const text = teams.map((team, index) => <div key={`${team.name}-${index}`}>{team.name}</div>);
  return <UserListItemSimple text={text} xs={xs} />;
};
