import { Money } from 'libs/api/common/types';

export type QuoteUpdateOptions = { withOptimisticUpdate: boolean; nonContractual?: boolean };

export enum ProductType {
  Product = 'product',
  Sample = 'sample',
}
export type ProductHit = {
  objectID: string;
  name: string;
  names?: Record<string, string>;
  coverRemoteId: string | null;
  coverVersion: number | null;
  skus: Record<string, string>;
  vendorSkus: Record<string, string>;
  inStock?: Record<string, boolean>;
  leadTime?: Record<string, { min: number; max: number }>;
  prices?: Record<string, Money>;
  catalogues?: string[];
  type: 'product' | 'sample';
};
