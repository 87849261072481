import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ExpandMoreIcon,
  LoadMoreDivider,
  Typography,
  useIsMobile,
} from '@clippings/paper';
import { ListCardLoader } from 'libs/shared';
import { MoreActionsButton } from 'libs/Quotes/Components/MoreActionsButton';
import { QuoteCardListRenderer } from './components/QuoteCardListRenderer';
import { QuoteListCardItemViewModel } from './components/QuoteListItemCard';
import { QuoteListContext } from './QuoteListContext';
import { QuoteListSection } from './QuoteListSection';
import { useTranslation } from 'react-i18next';
import './QuoteListArchivedQuotesSection.scss';

export const QuoteListArchivedQuotesSection = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const isMobile = useIsMobile();
  const {
    archivedQuotes,
    archivedQuotesLoading,
    handleLoadMoreArchivedQuotes,
    archivedShowLoadMoreQuotes,
    archivedLoadMoreLoading,
    disableActions,
    onArchive,
    onUnarchive,
    onDuplicate,
  } = useContext(QuoteListContext);

  if (isMobile) {
    const cardActions = (item: QuoteListCardItemViewModel) => {
      return (
        <MoreActionsButton
          disabled={disableActions}
          quote={archivedQuotes[item.position]}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          onDuplicate={onDuplicate}
        />
      );
    };
    const loadMore = archivedLoadMoreLoading ? (
      <ListCardLoader />
    ) : (
      <LoadMoreDivider
        className="load-more-divider"
        hasNextPage={archivedShowLoadMoreQuotes}
        noMoreLabel={t('quotes.noMoreQuotes')}
        onClick={handleLoadMoreArchivedQuotes}
      />
    );

    return (
      <QuoteCardListRenderer
        isLoading={archivedQuotesLoading}
        items={archivedQuotes}
        cardActions={cardActions}
        loadMore={loadMore}
      />
    );
  }

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      className="quote-list-archived-section"
    >
      <AccordionSummary
        className="quote-list-archived-section__summary"
        expandIcon={<ExpandMoreIcon />}
        data-testid="archived-accordion"
      >
        <Typography fontWeight="fontWeightMedium" color="text.primary">
          {t('common.archive')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <QuoteListSection
          disableActions={disableActions}
          data-testid="archived-quote-list-section"
          loading={archivedQuotesLoading}
          onClick={handleLoadMoreArchivedQuotes}
          hasMore={archivedShowLoadMoreQuotes}
          quotes={archivedQuotes}
        />
      </AccordionDetails>
    </Accordion>
  );
};
