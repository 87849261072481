import React from 'react';
import { DateTimeFormat, Grid, useFormatDateTime } from '@clippings/paper';
import { QuoteInfoLabel } from './QuoteInfoLabel';

interface QuoteDateGridItemProps {
  label: string;
  date: string;
}

export function QuoteDateGridItem({ label, date }: QuoteDateGridItemProps): JSX.Element {
  const formatDateTime = useFormatDateTime();

  return (
    <Grid item xs={3}>
      <QuoteInfoLabel bold text={label} />
      <QuoteInfoLabel text={date ? formatDateTime(new Date(date), DateTimeFormat.Date) : ''} />
    </Grid>
  );
}
