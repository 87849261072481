import React from 'react';
import { Box, IconButton, SystemUser, Typography } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { UserDialogScreen, userDialogSettingsMap } from '../../utils/user.utils';
import { useTranslation } from 'react-i18next';

type UserCompanyDialogTitleProps = {
  userType: SystemUser;
  currentScreen: UserDialogScreen;
  onCompanyScreenReturn: (createdCompany?: Company) => void;
};

export function UserCompanyDialogTitle({
  userType,
  currentScreen,
  onCompanyScreenReturn,
}: UserCompanyDialogTitleProps) {
  const { t } = useTranslation();

  const isCreateCompanyScreen = currentScreen === UserDialogScreen.CreateCompany;
  const UserIcon = userDialogSettingsMap[userType].icon;
  const CompanyIcon = userDialogSettingsMap.company.icon;

  const companyTitle = t(userDialogSettingsMap.company.createTranslation);
  const userTitle = t(userDialogSettingsMap[userType].createTranslation);
  const title = isCreateCompanyScreen ? companyTitle : userTitle;

  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        {isCreateCompanyScreen ? (
          <IconButton
            sx={iconButtonSx}
            data-testid="user-create-company-button-back"
            aria-label="back"
            onClick={() => onCompanyScreenReturn()}
          >
            <CompanyIcon color="secondary" />
          </IconButton>
        ) : (
          <Box padding={1} borderRadius="50%" bgcolor="grey.A200" display="flex">
            <UserIcon color="secondary" />
          </Box>
        )}
        {title}
      </Box>
      {!isCreateCompanyScreen && (
        <Typography
          data-testid="customer-dialog-email-notification"
          variant="body2"
          color="secondary"
          mt={2}
        >
          {t(userDialogSettingsMap[userType].emailTranslation)}
        </Typography>
      )}
    </Box>
  );
}

const iconButtonSx = {
  bgcolor: 'grey.A200',
};
