import React, { FC, useEffect } from 'react';
import { ErrorLayout, ErrorLayoutProps } from './ErrorLayout';
import { Navigate, useLocation } from 'react-router-dom';
import { Typography } from '@clippings/paper';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

type AccessDeniedProps = ErrorLayoutProps;

export const AccessDenied: FC<AccessDeniedProps> = ({ to }) => {
  const { t } = useTranslation();
  const location = useLocation();

  useTitle(t('common.accessDenied'));

  useEffect(() => {
    // Wipe out the state from the history entry so that refreshing the page doesn't work
    window.history.replaceState(null, '');
  }, []);

  if (!location.state?.redirectedByErrorHandling) {
    return <Navigate to={to} />;
  }

  return (
    <ErrorLayout to={to}>
      <Typography variant="h3">{t('common.accessDenied')}</Typography>
      <Typography color="secondary">{t('common.youDoNotHavePermissionsToViewThisPage')}</Typography>
    </ErrorLayout>
  );
};
