import React from 'react';
import { CustomPaymentRule, DatePaymentRule, EventPaymentRule } from 'libs/api/common/types';
import { CustomPaymentRuleCell } from './CustomPaymentRuleCell';
import { DatePaymentRuleCell } from './DatePaymentRuleCell';
import { EventPaymentRuleCell } from './EventPaymentRuleCell';
import { PaymentRuleRowCommonProps } from '../../paymentTerms.types';
import { TableCell } from '@clippings/paper';

type PaymentRuleCellRendererProps = PaymentRuleRowCommonProps;

export function PaymentRuleValueCellRenderer({
  paymentRule,
  onChange,
  index,
}: PaymentRuleCellRendererProps) {
  const handleDueDaysChange = (value: string | null) =>
    onChange({
      ...paymentRule,
      dueDays: Number(value),
    } as EventPaymentRule);

  const handleDueReasonChange = (value: string | null) =>
    onChange({
      ...paymentRule,
      dueReason: value,
    } as CustomPaymentRule);

  const dateChangeHandler = (value: string | null) => {
    onChange({
      ...paymentRule,
      dueDate: value,
    } as DatePaymentRule);
  };

  return (
    <TableCell>
      {paymentRule.type === 'event' && (
        <EventPaymentRuleCell
          paymentRule={paymentRule}
          onChange={handleDueDaysChange}
          data-testid={`due-days-input-${index}`}
        />
      )}

      {paymentRule.type === 'custom' && (
        <CustomPaymentRuleCell
          paymentRule={paymentRule}
          onChange={handleDueReasonChange}
          data-testid={`due-reason-input-${index}`}
        />
      )}

      {paymentRule.type === 'date' && (
        <DatePaymentRuleCell
          paymentRule={paymentRule}
          onChange={dateChangeHandler}
          data-testid={`date-payment-input-${index}`}
        />
      )}
    </TableCell>
  );
}
