import React from 'react';
import { ChipMenuFilter, MenuItem, Typography } from '@clippings/paper';
import { StatusCircle } from 'libs/Components';
import { UserStatus } from 'libs/api/iam/types';
import { useTranslation } from 'react-i18next';

export const userStatusMap = {
  [UserStatus.Approved]: {
    labelKey: 'common.approved',
    color: 'success',
  },
  [UserStatus.Pending]: {
    labelKey: 'common.pending',
    color: 'warning',
  },
  [UserStatus.Rejected]: {
    labelKey: 'common.rejected',
    color: 'error',
  },
} as const;

export interface UserStatusFilterProps {
  value?: UserStatus;
  onChange: (status?: UserStatus) => void;
}

export const UserStatusFilter: React.FC<UserStatusFilterProps> = ({ value, onChange }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { t } = useTranslation();

  const setSelection = (value: UserStatus | undefined) => {
    setMenuOpen(false);
    onChange(value);
  };

  return (
    <ChipMenuFilter
      chipProps={{
        isOpen: menuOpen,
        onToggle: setMenuOpen,
        adornment: (
          <StatusCircle
            data-testid="status-chip-filter-adorment"
            chipProps={value && userStatusMap[value]}
          />
        ),
        placeholder: t('common.status'),
        value: value ? t(`common.${value}`) : '',
        hasArrow: true,
        'data-testid': 'salesapp-users-status-filter',
      }}
      clearProps={{
        label: t('common.clear'),
        buttonProps: {
          onClick: () => setSelection(undefined),
        },
      }}
      contentContainerProps={{
        'data-testid': 'salesapp-users-status-dropdown',
      }}
    >
      {[UserStatus.Approved, UserStatus.Pending, UserStatus.Rejected].map(s => (
        <MenuItem
          data-testid={`user-status-filter-item-${s}`}
          key={s}
          onClick={() => setSelection(s)}
        >
          <StatusCircle chipProps={{ color: userStatusMap[s].color }} />
          <Typography ml={1}>{t(userStatusMap[s].labelKey)}</Typography>
        </MenuItem>
      ))}
    </ChipMenuFilter>
  );
};
