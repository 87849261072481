import visitor from 'libs/Globals/visitor';
import { Payment } from 'libs/api/common/types';
import { Quote } from 'libs/api/quotes/types';
import { downloadAsExcelFile } from './excelLibrary';
import { getQuoteItemContractFieldValue } from 'libs/shared';
import { useAppConfiguration } from 'libs/providers';
import { useColumns } from './columns';
import {
  useFormatDetails,
  useFormatType,
} from 'libs/Quotes/Components/TotalsSummary/paymentHelpers';
import { useQuoteTableVisibleFields } from '../QuoteTable';
import { useTranslation } from 'react-i18next';

const maxFileNameLength = 150;

export const useExcelExport = (quote: Quote) => {
  const { t } = useTranslation();

  const { leadTimeFormat } = useAppConfiguration();
  const contractFields = getQuoteItemContractFieldValue(quote.quoteItemProducts?.[0] ?? null);
  const { lineItemVisibleFields } = useQuoteTableVisibleFields(contractFields);

  const columns = useColumns(quote.total.currency);
  const { formatDetails } = useFormatDetails();
  const { formatType } = useFormatType();

  const downloadQuoteExcel = (paymentTerms: Payment[]) => {
    const data = {
      columns: columns.EXCEL_COLUMNS(leadTimeFormat, lineItemVisibleFields),
      totalColumns: columns.TOTAL_COLUMNS,
      quote,
      paymentTerms,
      creatorName: visitor.name,
    };
    const fileName = quote.name
      ? `Quote-${quote.name.slice(0, maxFileNameLength)}#${quote.number}`
      : `Quote-#${quote.number}`;

    downloadAsExcelFile(data, fileName, t, columns, formatDetails, formatType);
  };

  return { downloadQuoteExcel };
};
