import React from 'react';
import { User } from 'libs/api/iam/types';
import { UserListItemSimple } from './UserListItemSimple';

interface UserListItemContactsProps {
  xs: number;
  user: User;
  showCompanyWebsite?: boolean;
}

export const UserListItemContacts: React.FC<UserListItemContactsProps> = ({
  xs,
  user,
  showCompanyWebsite = false,
}) => {
  const text = (
    <>
      {user.email && (
        <div data-testid={`user-list-item-contacts-email-${user.id}`}>{user.email}</div>
      )}
      {user.phone && (
        <div data-testid={`user-list-item-contacts-phone-${user.id}`}>{user.phone}</div>
      )}
      {user.company?.website && showCompanyWebsite && (
        <div data-testid={`user-list-item-contacts-webiste-${user.id}`}>{user.company.website}</div>
      )}
    </>
  );
  return <UserListItemSimple text={text} xs={xs} />;
};
