import React from 'react';
import { AccessTimeIcon, CheckCircleIcon, DoDisturbOnIcon, Typography } from '@clippings/paper';
import { User, UserStatus } from 'libs/api/iam/types';
import { useTranslation } from 'react-i18next';

export interface UserApprovalStatusProps {
  user: User;
}

export const UserApprovalStatus: React.FC<UserApprovalStatusProps> = ({ user }) => {
  const { t } = useTranslation();

  switch (user.status) {
    case UserStatus.Approved:
      return (
        <Typography
          variant="body2"
          display="flex"
          gap={1}
          color="success.main"
          alignItems="center"
          data-testid="approved-user-status"
        >
          <CheckCircleIcon />
          {t('common.approved')}
        </Typography>
      );
    case UserStatus.Pending:
      return (
        <Typography
          variant="body2"
          display="flex"
          gap={1}
          color="warning.main"
          alignItems="center"
          data-testid="pending-user-status"
        >
          <AccessTimeIcon />
          {t('common.pending')}
        </Typography>
      );
    case UserStatus.Rejected:
      return (
        <Typography
          variant="body2"
          display="flex"
          gap={1}
          color="error.main"
          alignItems="center"
          data-testid="rejected-user-status"
        >
          <DoDisturbOnIcon />
          {t('common.rejected')}
        </Typography>
      );
  }
};
