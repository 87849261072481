import React from 'react';
import { ChipMenuFilter, MenuItem, Typography } from '@clippings/paper';
import { OrderStatus } from 'libs/api/order/types';
import { StatusCircle } from 'libs/Components/StatusCircle';
import { useAppConfiguration } from 'libs/providers';
import { useOrderStatusMap } from 'libs/Orders/utils/useOrderStatusMap';
import { useTranslation } from 'react-i18next';

export interface OrderStatusSelectProps {
  status?: OrderStatus;
  onChange: (status?: OrderStatus) => void;
}

export const OrderStatusSelect: React.FC<OrderStatusSelectProps> = ({ status, onChange }) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const setSelection = (value: OrderStatus | undefined) => {
    setMenuOpen(false);
    onChange(value);
  };
  const { orderStatuses } = useAppConfiguration();
  const orderStatusMap = useOrderStatusMap();

  return (
    <ChipMenuFilter
      chipProps={{
        adornment: <StatusCircle color={status && orderStatusMap[status].chip.color} />,
        isOpen: menuOpen,
        onToggle: setMenuOpen,
        placeholder: t('common.status'),
        value: status && t(orderStatusMap[status].chip.labelKey),
        hasArrow: true,
        'data-testid': 'sa-order-status-filter',
      }}
      clearProps={{
        label: t('common.clear'),
        buttonProps: {
          onClick: () => setSelection(undefined),
        },
      }}
      contentContainerProps={{
        'data-testid': 'sa-order-status-dropdown',
      }}
    >
      {orderStatuses.map((s: OrderStatus) => (
        <MenuItem
          key={s}
          onClick={() => setSelection(s)}
          data-testid={`order-status-select-item-${s}`}
        >
          <StatusCircle chipProps={orderStatusMap[s].chip} />
          <Typography data-testid={`order-status-select-${s}`} ml={1}>
            {t(orderStatusMap[s].chip.labelKey)}
          </Typography>
        </MenuItem>
      ))}
    </ChipMenuFilter>
  );
};
