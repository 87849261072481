import React, { useCallback, useMemo, useState } from 'react';
import { DEBOUNCE_TIMINGS } from 'libs/Constants';
import { InfiniteQueryOptions } from 'libs/api/common/types';
import { debounce } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useInfinityTypeAhead = <T extends any[]>(
  fetchResource: (a: { query: string; page: number }) => Promise<T>,
  options?: InfiniteQueryOptions<T>
) => {
  const [searchQuery, setSearchQuery] = useState('');

  const query = useInfiniteQuery(
    ['query', searchQuery],
    ({ pageParam }) => fetchResource({ query: searchQuery, page: pageParam ?? 1 }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length > 0) {
          return allPages.length + 1;
        }
      },
    }
  );

  const list = useMemo(() => {
    return (query?.data?.pages ?? []).flat();
  }, [query.data]);

  const onScroll = (event: React.UIEvent<HTMLElement>) => {
    const listboxNode = event.currentTarget;

    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - x <= 1) {
      query.fetchNextPage();
    }
  };

  const onChange = useCallback(
    debounce(value => {
      setSearchQuery(value);
    }, DEBOUNCE_TIMINGS.INPUT_DEBOUNCE),
    []
  );

  return {
    list,
    isFetchingNextPage: query.isFetchingNextPage,
    onChange,
    onScroll,
  };
};
