import React, { useState } from 'react';
import { ContentRow } from './ContentRow';
import { InboxRowItem } from 'libs/SalesApp/Inbox/InboxRow';
import { getQuotes } from 'libs/api/quotes/actions';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useBanner } from 'libs/Quotes/Components/AlertBanner';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useUnreadChannels } from 'libs/Chat/utils/useUnreadChannels';

const channelDisplayCount = 3;
type QuoteData = {
  price: { amount: number; currency: string };
  firstName: string;
  lastName: string;
  companyName: string;
};

type QuoteDataMap = Record<string, QuoteData>;

type MessageItems = {
  items: InboxRowItem[];
  showMore: () => void;
  hasMore: boolean;
  loading: boolean;
  channels: any[];
};

export const useMessageItems = (): MessageItems => {
  const navigate = useNavigate();
  const { channels, error } = useUnreadChannels();
  const [showAll, setShowAll] = useState(false);
  const { showErrorBanner } = useBanner();
  const showMore = () => setShowAll(true);
  const numbers = channels.map(c => c.id).join(',');
  const enabled = !!numbers && !error;
  const query = useQuery({
    queryKey: ['getQuotesForInboxMessages, numbers', numbers],
    queryFn: () =>
      getQuotes({
        numbers,
        size: channels.length,
      }),
    enabled,
  });

  if (error) {
    showErrorBanner('Failed to fetch messages');
    return {
      items: [],
      showMore,
      hasMore: false,
      loading: false,
      channels: [],
    };
  }

  const quotesMap: QuoteDataMap =
    query.data?.items.reduce((acc, quote) => {
      acc[quote.number] = {
        price: quote.total,
        firstName: quote.customer?.firstName ?? '',
        lastName: quote.customer?.lastName ?? '',
        companyName: quote.customer?.company?.name ?? '',
      };
      return acc;
    }, {} as QuoteDataMap) || {};

  const channelsToDisplay = showAll ? channels : channels.slice(0, channelDisplayCount);
  const items = channelsToDisplay
    .filter(c => !!quotesMap[c.id])
    .map(channel => ({
      id: channel.id,
      content: (
        <ContentRow number={channel.id} total={quotesMap[channel.id]?.price} channel={channel} />
      ),
      dateTime: channel?.data?.last_message_at as string,
      firstName: quotesMap[channel.id]?.firstName,
      lastName: quotesMap[channel.id]?.lastName,
      companyName: quotesMap[channel.id]?.companyName,
      onClick: () => {
        navigate(path(routes.salesApp.quotes.show, { number: channel.id }));
      },
    }));

  return {
    items,
    showMore,
    hasMore: channels.length > channelDisplayCount,
    loading: query.isLoading && enabled,
    channels,
  };
};
