import React from 'react';
import { QuoteActionsStack } from '../QuoteActionsStack';
import { QuoteDocuments } from '../Components/QuoteDocuments/QuoteDocuments';
import { QuoteHeader } from '../QuoteHeader';
import { QuoteSummary } from '../QuoteSummary';
import { QuoteTable } from '../QuoteTable/QuoteTable';
import { ViewContainer } from './ViewContainer';
import { useQuoteProvider } from '../providers';

export function QuoteViewContent() {
  const { isReadonly, loading } = useQuoteProvider();

  return (
    <>
      <QuoteHeader />
      <QuoteActionsStack />
      <QuoteTable readonly={isReadonly} />
      <ViewContainer>
        <QuoteDocuments loading={loading} readonly={isReadonly} sx={quoteDocumentsSx} />
        <QuoteSummary readonly={isReadonly} />
      </ViewContainer>
    </>
  );
}

const quoteDocumentsSx = {
  mt: 0,
};
