import React, { ComponentProps } from 'react';
import { ComponentGridContainer, DividerGrey } from 'libs/shared';
import { InputBase, Skeleton } from '@clippings/paper';
import { Note } from 'libs/notes';
import { QuoteNotesTitle } from './QuoteNotesTitle';
import { useQuoteProvider } from 'libs/Quotes/providers';

type QuoteNotesProps = {
  loading: boolean;
  readonly: boolean;
  title: string;
  subTitle: string;
  type: 'note' | 'externalNote';
  inputProps?: ComponentProps<typeof InputBase>['inputProps'];
};

export function QuoteNotes({
  loading,
  readonly,
  title,
  subTitle,
  type,
  inputProps,
}: QuoteNotesProps) {
  const { quote, handleQuoteUpdate } = useQuoteProvider();

  if (loading) {
    return (
      <ComponentGridContainer>
        <Skeleton width="100%" height="24px" variant="rounded" />
        <Skeleton width="100%" height="50px" variant="rectangular" />
      </ComponentGridContainer>
    );
  }

  function saveHandler(note: string) {
    handleQuoteUpdate({ [type]: note }, { withOptimisticUpdate: false });
  }

  const value = quote[type] || '';

  return (
    <ComponentGridContainer>
      <QuoteNotesTitle title={title} subTitle={subTitle} />
      <DividerGrey />
      <Note
        value={value}
        onSave={saveHandler}
        readonly={readonly}
        inputProps={{ ...noteInputProps, ...inputProps }}
        selectorSuffix="quote"
      />
    </ComponentGridContainer>
  );
}

const noteInputProps = { 'data-testid': 'quote-note' };
