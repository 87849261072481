import React, { FC, useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { BrowserUploadedFile } from 'libs/api/upload/types';
import { Quote } from 'libs/api/quotes/types';
import { UploadedFile, UploadedFileProps } from '@clippings/paper';
import { useQueryClient } from '@tanstack/react-query';
import { useQuoteQueryKey } from 'libs/Quotes/hooks';
import { useUploadQuoteDocument } from 'libs/api/quotes/hooks';

export type QuoteBrowserUploadedFileProps = {
  onUploadSuccess: (file: BrowserUploadedFile) => void;
  onUploadError: (e: AxiosError, file: BrowserUploadedFile) => void;
  onRemove: (file: BrowserUploadedFile) => void;
  file: BrowserUploadedFile;
  quoteNumber: string;
} & Partial<UploadedFileProps>;

export const QuoteBrowserUploadedFile: FC<QuoteBrowserUploadedFileProps> = ({
  onRemove,
  onUploadError,
  onUploadSuccess,
  file,
  quoteNumber,
  ...rest
}) => {
  const hasStartedUploading = useRef(false);

  const queryClient = useQueryClient();
  const quoteQueryKey = useQuoteQueryKey(quoteNumber);

  const { file: browserFile, id } = file;
  const { name, size } = browserFile;

  const {
    mutate: uploadFile,
    reset,
    progress,
  } = useUploadQuoteDocument(quoteNumber, {
    onSuccess: quoteDocument => {
      hasStartedUploading.current = false;
      const previousQuote = queryClient.getQueryData<Quote>(quoteQueryKey)!;

      queryClient.setQueryData<Partial<Quote>>(quoteQueryKey, old => ({
        ...old,
        documents: [...old!.documents!, quoteDocument],
      }));

      onUploadSuccess(file);

      return { previousQuote };
    },
    onError: (e, browserDoc) => {
      onUploadError(e, browserDoc);
    },
  });

  const removeBrowserUploadedDocument = () => {
    if (hasStartedUploading.current) {
      reset();
    }

    onRemove(file);
  };

  useEffect(() => {
    if (!hasStartedUploading.current) {
      uploadFile(file);
      hasStartedUploading.current = true;
    }

    return () => {
      if (hasStartedUploading.current) {
        reset();
      }
    };
  }, []);

  return (
    <UploadedFile
      data-testid={`browser-uploaded-document-${id}`}
      fileName={name}
      fileSize={size}
      progressProps={{ value: progress }}
      onRemove={removeBrowserUploadedDocument}
      loading={hasStartedUploading.current && progress === 100}
      {...rest}
    />
  );
};
