import React, { FC } from 'react';
import classNames from 'classnames';
import { CheckoutEndStatusLinks } from '../CheckoutEndStatusLinks';
import { CheckoutStatusView } from '../CheckoutStatusView/CheckoutStatusView';
import { Grid, GridProps } from '@clippings/paper';
import { PaymentDetails } from '../paymentDetails/PaymentDetails';
import { PaymentStatusIcons } from '../PaymentStatusIcons';
import { useQuoteCheckoutContext } from '../../providers';
import { useTranslation } from 'react-i18next';

export type PendingProps = {
  containerProps?: GridProps;
};

export const Pending: FC<PendingProps> = ({ containerProps }) => {
  const { t } = useTranslation();
  const { amount, paymentType, hasPaymentAmount, setPaymentType } = useQuoteCheckoutContext();

  return (
    <Grid
      container
      data-testid="checkout-pending-container"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...containerProps}
      className={classNames('checkout-pending', containerProps?.className)}
    >
      <CheckoutStatusView
        data-testid="checkout-pending-status-view"
        sx={{
          marginBottom: '3em',
        }}
        icon={PaymentStatusIcons.pending}
        title={t('checkout.pending.title')}
        subtitle={t('checkout.pending.subtitle')}
      />
      {hasPaymentAmount ? (
        <PaymentDetails
          disabled={true}
          amount={amount}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      ) : null}
      <CheckoutEndStatusLinks />
    </Grid>
  );
};
