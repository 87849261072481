import React from 'react';
import { CatalogueDialogContent } from './CatalogueDialogContent';
import { CloseableDialog } from '@clippings/paper';
import { Company } from 'libs/api/iam/types';
import { useListCatalogues } from 'libs/api/catalogue/hooks';
import { useTranslation } from 'react-i18next';

export interface CatalogueDialogProps {
  company: Company;
  open: boolean;
  onClose: () => void;
}

export const CatalogueDialog: React.FC<CatalogueDialogProps> = ({ company, open, onClose }) => {
  const { t } = useTranslation();
  const { data: catalogueList } = useListCatalogues();

  return (
    <>
      <CloseableDialog open={open} onClose={onClose} title={t('companies.catalogueAccess')}>
        <CatalogueDialogContent
          onSuccess={onClose}
          onCancel={onClose}
          company={company}
          catalogueList={catalogueList || []}
        />
      </CloseableDialog>
    </>
  );
};
