import React from 'react';
import { Address } from 'libs/api/common/types';
import { Box, Typography } from '@clippings/paper';
import { ShippingAddressMetadata } from 'libs/Components/ShippingAddressMetadata/ShippingAddressMetadata';
import { isBillingAddress, isShippingAddress } from 'libs/api/common/utils';
import { useTranslation } from 'react-i18next';

export interface AddressDetailsProps {
  address: Address;
}

export const AddressDetails: React.FC<AddressDetailsProps> = ({ address }) => {
  const { t } = useTranslation();

  const addressTitle = getAddressTitle(address);
  const addressDetails = getAddressDetails(address);
  const addressContact = `${t('common.contact')}: ${getContactDetails(address)}`;

  return (
    <Box>
      <Typography data-testid="address-details-title" variant="body1">
        {addressTitle}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Typography data-testid="address-details-details" variant="caption" color="secondary">
          {addressDetails}
        </Typography>
        <Typography data-testid="address-details-contact" variant="caption" color="secondary">
          {addressContact}
        </Typography>
        <ShippingAddressMetadata address={address} />
      </Box>
    </Box>
  );
};

export const getAddressTitle = (address: Address) =>
  isShippingAddress(address) ? address.nickname : address.companyName;

export const getAddressDetails = (address: Address) =>
  [
    address.address,
    address.address2,
    address.city,
    address.state?.name,
    address.province,
    address.country?.name,
    address.zip,
  ]
    .filter(Boolean)
    .join(', ');

export const getContactDetails = (address: Address) =>
  [
    address.contactName,
    isShippingAddress(address) && address.phone,
    isShippingAddress(address) && address.accessDetails,
    isBillingAddress(address) && address.email,
  ]
    .filter(Boolean)
    .join(', ');
