import React, { ChangeEvent } from 'react';
import {
  Box,
  DeleteIcon,
  EditIcon,
  IconButton,
  Paper,
  UploadButton,
  getSmallProductImageUrl,
} from '@clippings/paper';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { Readonly, fallbackCoverUrl } from 'libs/shared';
import { UpdateQuoteByKey } from '../types';
import { uploadPhoto } from 'libs/api/common/actions';
import { useMutation } from '@tanstack/react-query';

type QuoteItemImageProps = {
  item: QuoteProductItem;
  onUpdate: UpdateQuoteByKey;
  loading: boolean;
} & Readonly;

export const QuoteItemImage: React.FC<QuoteItemImageProps> = ({
  item,
  onUpdate,
  loading,
  readonly = false,
}) => {
  const imageUploadMutation = useMutation((data: FormData) => uploadPhoto(data));

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const data = new FormData();
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    data.append('file', file);
    imageUploadMutation.mutate(data, {
      onSuccess: data => {
        onUpdate('picture', data, item.id);
      },
    });
  };

  const disabled = imageUploadMutation.isLoading || loading;

  const handleDelete = () => {
    onUpdate('picture', null, item.id);
  };

  const buttons = !readonly ? (
    <Paper
      className="quote-item-image__buttons"
      elevation={6}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        visibility: 'hidden',
        gap: 0.5,
        position: 'absolute',
        borderRadius: '16px',
      }}
    >
      <UploadButton
        type="icon"
        inputProps={{
          id: `file-upload-${item.id}`,
          'data-testid': `file-upload-${item.id}`,
          type: 'file',
          style: { display: 'none' },
          accept: '.png,.jpeg,.jpg',
          onChange: handleImageUpload,
        }}
        buttonProps={{
          size: 'small',
          color: 'secondary',
          htmlFor: `file-upload-${item.id}`,
          disabled: disabled,
          'data-testid': `file-upload-button-${item.id}`,
        }}
      >
        <EditIcon fontSize="small" color="secondary" />
      </UploadButton>
      {item.picture !== null && !item.isCataloguePicture ? (
        <IconButton
          disabled={disabled}
          onClick={handleDelete}
          size="small"
          color="secondary"
          data-testid={`file-delete-button-${item.id}`}
        >
          <DeleteIcon fontSize="small" color="secondary" />
        </IconButton>
      ) : null}
    </Paper>
  ) : null;

  return (
    <Box display="flex" justifyContent="center">
      <img
        data-testid={`quote-item-image-${item.id}`}
        src={item.picture ? getSmallProductImageUrl(item.picture) : fallbackCoverUrl}
        alt={item.productName}
        height={40}
        width={40}
      />
      {buttons}
    </Box>
  );
};
