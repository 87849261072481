import React from 'react';
import { AppConfiguration } from 'libs/api/configuration/types';
import { Divider } from '@clippings/paper';
import { Nullable } from 'libs/Utils';

type Columns = AppConfiguration['lineItemVisibleFields'][number];

type WithDividerProps = {
  columnName: Columns;
  columnWithDivider: Nullable<Columns>;
};

export function WithDivider({ columnName, columnWithDivider }: WithDividerProps) {
  return columnName === columnWithDivider ? <DividerStyled /> : null;
}

export function DividerStyled() {
  return <Divider sx={{ my: 1 }} />;
}
