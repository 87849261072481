import React from 'react';
import { InfoBox, InfoLine } from 'libs/shared';
import { Link, localesMap } from '@clippings/paper';
import { User } from 'libs/api/iam/types';
import { useBanner } from 'libs/Components';
import { useResetPassword } from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

export interface UserDetailsProps {
  user: User;
}
export const UserDetails: React.FC<UserDetailsProps> = ({ user }) => {
  const { t, i18n } = useTranslation();
  const { showErrorBanner } = useBanner();
  const defaultLocale = i18n.language;
  const language = (localesMap as any)[user.locale ?? defaultLocale]?.nativeName;
  const location = user.billingAddresses?.at(0)?.country?.name ?? '';
  const { email } = user;

  const resetPasswordMutation = useResetPassword();
  const { showSuccessBanner } = useBanner();

  const handleResetPassword = () => {
    resetPasswordMutation.mutate(email, {
      onSuccess: () => {
        showSuccessBanner(t('common.weSentAPasswordResetLink', { email }));
      },
      onError() {
        showErrorBanner(t('common.passwordLinkWasAlreadySentPleaseTryAgain', { minutes: 5 }));
      },
    });
  };

  return (
    <InfoBox title={t('common.generalInformation')}>
      <InfoLine
        data-testid="user-details-firstName"
        label={t('common.firstName')}
        value={user.firstName}
      />
      <InfoLine
        data-testid="user-details-lastName"
        label={t('common.lastName')}
        value={user.lastName}
      />
      <InfoLine
        data-testid="user-details-position"
        label={t('common.position')}
        value={user.jobTitle}
      />
      <InfoLine
        data-testid="user-details-phoneNumber"
        label={t('common.phoneNumber')}
        value={user.phone}
      />
      <InfoLine data-testid="user-details-email" label={t('common.email')} value={user.email} />
      <InfoLine
        data-testid="user-details-password"
        label={t('common.password')}
        value={
          <Link
            component="button"
            variant="body2"
            onClick={handleResetPassword}
            data-testid="user-details-reset-password"
          >
            {t('common.resetPassword')}
          </Link>
        }
      />
      <InfoLine data-testid="user-details-language" label={t('common.language')} value={language} />
      <InfoLine
        data-testid="user-details-location"
        label={t('common.location')}
        value={location}
        divider={false}
      />
    </InfoBox>
  );
};
