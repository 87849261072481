import React, { ComponentProps } from 'react';
import { Box, DateTime, DateTimeFormat, Grid, LabelIcon, Typography } from '@clippings/paper';
import { ChipSkeleton } from './Skeletons';
import { NeedHelp } from 'libs/Orders/components/NeedHelp';
import { OrderStatusChip } from 'libs/Orders/components/OrderStatusChip';
import { useChatVisibility } from 'libs/Chat';
import { useOrder } from '../../api/order/hooks';
import { useTranslation } from 'react-i18next';

type OrderHeaderProps = {
  number: string;
  orderIdLabel: string;
} & ComponentProps<typeof Box>;

const ORDER_LABEL_MAXLENGTH = 100;

export const OrderHeader: React.FC<OrderHeaderProps> = ({ number, orderIdLabel, ...rest }) => {
  const { t } = useTranslation();
  const orderQuery = useOrder(number);
  const { onToggle: toggleChat } = useChatVisibility();
  const salesRep = orderQuery.data?.salesRepresentative;

  return (
    <Box data-testid="order-details-header" {...rest} display="flex" gap={1} flexDirection="column">
      <Grid container justifyContent="space-between" flexWrap="wrap" gap={1}>
        <Grid display="flex" gap={1} flexWrap="wrap" alignItems="center">
          <Typography data-testid="order-details-header-number" variant="h5">
            {t('orders.orderNumber')}
            {orderQuery.isFetching ? null : orderIdLabel}
          </Typography>
          {orderQuery.isSuccess ? (
            <OrderStatusChip
              data-testid={`order-item-${orderQuery.data.number}`}
              status={orderQuery.data.status}
            />
          ) : (
            <ChipSkeleton />
          )}
        </Grid>
        <NeedHelp loading={orderQuery.isFetching} toggleChat={toggleChat} salesRep={salesRep} />
      </Grid>
      {orderQuery.data?.label && (
        <Typography variant="caption" display="flex" alignItems="center" gap={0.5}>
          <Typography
            data-testid="order-details-header-chip-label"
            color="grey.A400"
            display="flex"
            alignItems="center"
          >
            <LabelIcon key="chip-label-icon" />
          </Typography>{' '}
          <Typography
            fontSize="inherit"
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth={`${ORDER_LABEL_MAXLENGTH}ch`}
            title={
              orderQuery.data?.label.length > ORDER_LABEL_MAXLENGTH
                ? orderQuery.data?.label
                : undefined
            }
          >
            {orderQuery.data?.label}
          </Typography>
        </Typography>
      )}
      {orderQuery.isFetching ? (
        <ChipSkeleton />
      ) : (
        orderQuery.data?.createdAt && (
          <DateTime
            format={DateTimeFormat.Date}
            date={orderQuery.data?.createdAt}
            data-testid="order-details-header-created-at"
            variant="caption"
          />
        )
      )}
    </Box>
  );
};
