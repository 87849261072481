import React, { FC, useCallback } from 'react';
import { BaseUser } from 'libs/api/common/types';
import { Box } from '@clippings/paper';
import { Channel } from 'stream-chat';
import { EmptyTablePlaceholder } from 'libs/Components';
import { MutationHandlerPayload } from './salesQuoteRowMutations';
import { Quote, QuoteListItem } from 'libs/api/quotes/types';
import { QuoteTableRow, QuotesTableRowSkeleton } from './components';
import { QuotesFilters, QuotesPage } from '../types';
import { SortableTable } from 'libs/shared';
import { headerCells } from './quotesTable.config';
import { notEmpty } from 'libs/shared/utils';
import { path } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { useCreateQuote } from 'libs/Quotes/hooks/useCreateQuote';
import { useNavigate } from 'react-router-dom';
import { useQuotesTable } from './providers';
import { useSearch } from '../../Header/useSearch';
import { useTranslation } from 'react-i18next';
import { useUnreadChannels } from 'libs/Chat/utils/useUnreadChannels';

export interface QuotesTableProps {
  page: QuotesPage;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  isLoading: boolean;
  quotes: QuoteListItem[];
  loadingQuotes: {
    top: QuoteListItem['number'][];
    inline: QuoteListItem['number'][];
  };
  filters: QuotesFilters;
  fetchNextPage: () => void;
  onClearFilters: () => void;
  handlers: {
    onArchive: (quote: QuoteListItem) => void;
    onDuplicate: (quote: QuoteListItem) => void;
    onRestore: (quote: QuoteListItem) => void;
    onAssigneeChange: (payload: MutationHandlerPayload) => void;
  };
}

export const QuotesTable: FC<QuotesTableProps> = ({
  page,
  isLoading,
  hasNextPage,
  handlers,
  quotes,
  loadingQuotes,
  filters,
  isFetchingNextPage,
  fetchNextPage,
  onClearFilters,
}) => {
  const navigate = useNavigate();
  const { channels } = useUnreadChannels();
  const [createQuoteMutation, isCreateQuoteLoading] = useCreateQuote();
  const [searchValue, setSearchValue] = useSearch();
  const { sort, setSort } = useQuotesTable();
  const { t } = useTranslation();

  const { onArchive, onDuplicate, onAssigneeChange, onRestore } = handlers;

  const checkHasMessages = (quoteNumber: Quote['number']) =>
    channels.map((channel: Channel) => channel.id).includes(quoteNumber);

  const onReset = () => {
    setSearchValue('');
    onClearFilters();
  };

  const onClick = useCallback((quote: QuoteListItem) => {
    navigate(path(routes.salesApp.quotes.show, { number: quote.number }));
  }, []);

  const filterValues = Object.values(filters).filter(notEmpty);
  const hasFilters = filterValues.length > 0 && filterValues.some(x => x.length > 0);

  const isEmptyAfterFilter = !quotes.length && !isLoading && (hasFilters || searchValue !== '');
  const isEmpty = !quotes.length && !isLoading && !isEmptyAfterFilter;

  const onAssigneeChangeHandler = ({ versionId, number }: QuoteListItem, assignee: BaseUser) =>
    onAssigneeChange({
      versionId,
      number,
      payload: {
        salesRepresentative: assignee,
      },
    });

  const getRow = (quote: QuoteListItem) => {
    if (loadingQuotes.inline.includes(quote.number)) {
      return <QuotesTableRowSkeleton key={`${quote.number}-loading`} />;
    }

    return (
      <QuoteTableRow
        data-testid={`sales-app-quotes-row-${quote.number}`}
        key={`quotes-table-row-${quote.number}`}
        hasMessages={checkHasMessages(quote.number)}
        quote={quote}
        onAssigneeChange={onAssigneeChangeHandler}
        onArchive={onArchive}
        onUnarchive={onRestore}
        onDuplicate={onDuplicate}
        onClick={onClick}
      />
    );
  };

  const getTableRows = () => [
    ...loadingQuotes.top.map((x, i) => <QuotesTableRowSkeleton key={`${x}-loading-${i}`} />),
    ...quotes.map(getRow),
  ];

  if (isEmpty || isEmptyAfterFilter) {
    return (
      <Box mt={4}>
        {isEmpty && (
          <EmptyTablePlaceholder
            onButtonClick={createQuoteMutation.mutate}
            title={t('quotes.noQuotes', { page })}
            buttonTitle={t('common.createQuote')}
            containerProps={{ 'data-testid': 'empty-table-placeholder-no-quotes' }}
            buttonProps={{
              disabled: isCreateQuoteLoading,
            }}
          />
        )}
        {isEmptyAfterFilter && (
          <EmptyTablePlaceholder
            onButtonClick={onReset}
            title={t('common.noResults')}
            buttonTitle={t('common.reset')}
            containerProps={{ 'data-testid': 'empty-table-placeholder-no-quotes-reset' }}
          />
        )}
      </Box>
    );
  }

  return (
    <SortableTable
      cellConfig={headerCells as any}
      hasNextPage={!!hasNextPage}
      isFetchingNextPage={!!isFetchingNextPage}
      isLoading={isLoading}
      noMoreLabel={t('quotes.noMoreQuotes')}
      onFetchNextPage={fetchNextPage}
      sort={sort}
      tableHeaderColumns={14}
      onSort={setSort as any}
      data-testid="sales-app-quotes-table-container"
    >
      {getTableRows()}
    </SortableTable>
  );
};
