import React, { FC } from 'react';
import { BaseUser } from 'libs/api/common/types';
import {
  DateTime,
  DateTimeFormat,
  Grid,
  Monetary,
  Typography,
  getUserFullName,
} from '@clippings/paper';
import { ListTableRow } from 'libs/Components';
import { MoreActionsButton } from 'libs/Quotes/Components/MoreActionsButton';
import { QuoteListItem } from 'libs/api/quotes/types';
import { StatusChip, getCompanyNameWithCity } from 'libs/shared';
import { UserChipFilter } from '../FilterStrip/components/UserChipFilter';
import { useQuoteSalesReps } from 'libs/SalesApp/Quotes/hooks/useQuoteSalesReps';
import { useTranslation } from 'react-i18next';
import './QuoteTableRow.scss';

export interface QuoteTableRowProps {
  quote: QuoteListItem;
  hasMessages: boolean;
  onAssigneeChange: (quote: QuoteListItem, assignee: BaseUser) => void;
  onDuplicate: (quote: QuoteListItem) => void;
  onArchive: (quote: QuoteListItem) => void;
  onUnarchive: (quote: QuoteListItem) => void;
  onClick: (quote: QuoteListItem) => void;
  /**
   * (TODO: v1.1) Selected boolean indicating whether row is selected
   */
  selected?: boolean;
  /**
   * (TODO: v1.1) On Row Select
   */
  onSelectClick?: (item: QuoteListItem, checked: boolean) => void;
}

export const QuoteTableRow: FC<QuoteTableRowProps> = ({
  quote,
  hasMessages,
  onAssigneeChange,
  onDuplicate,
  onArchive,
  onUnarchive,
  onClick,
}) => {
  const { t } = useTranslation();
  const { customer, total, status, latestActivityAt, number, salesRepresentative, name, project } =
    quote;
  const { amount, currency } = total ?? {};

  const [initiateFetch, usersQuery] = useQuoteSalesReps();

  const onRowClick = () => onClick(quote);
  const onValueAssigneeChange = (salesRepresentative?: BaseUser) =>
    salesRepresentative && onAssigneeChange(quote, salesRepresentative);

  const companyName = getCompanyNameWithCity(
    customer?.company?.name,
    customer?.company?.billingAddresses
  );

  return (
    <ListTableRow
      columns={14}
      data-testid={`sales-app-quote-table-row-${number}`}
      onClick={onRowClick}
    >
      <Grid item xs={2}>
        <Typography
          fontSize={theme => theme.typography.pxToRem(14)}
          fontWeight="medium"
          variant="body1"
          data-testid={`sales-quote-item-number-${number}`}
        >
          {number}
        </Typography>
        <Typography
          fontSize={theme => theme.typography.pxToRem(12)}
          variant="body1"
          color="secondary"
          data-testid={`sales-quote-item-label-${number}`}
          maxWidth="40ch"
          overflow="hidden"
          textOverflow="ellipsis"
          noWrap
        >
          {name}
        </Typography>
        <Typography
          data-testid={`quotes-table-row-cell-projectName-${number}`}
          fontSize={theme => theme.typography.pxToRem(12)}
          variant="body1"
          color="secondary"
        >
          {project?.name}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          fontSize={theme => theme.typography.pxToRem(14)}
          fontWeight="medium"
          variant="body1"
          data-testid={`sales-quote-item-customer-${number}`}
        >
          {getUserFullName(customer)}
        </Typography>
        <Typography
          data-testid={`quotes-table-row-cell-companyName-${number}`}
          fontSize={theme => theme.typography.pxToRem(12)}
          variant="body1"
          color="secondary"
        >
          {companyName}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Monetary
          fontSize="14px"
          fontWeight="medium"
          amount={amount}
          currency={currency}
          data-testid={`sales-quote-item-value-${number}`}
        />
      </Grid>
      <Grid item xs={2}>
        <StatusChip status={status} data-testid={`sales-quote-item-status-${number}`} />
      </Grid>
      <Grid item xs={2}>
        <DateTime
          format={DateTimeFormat.Date}
          date={latestActivityAt}
          data-testid={`quote-item-activity-${number}`}
          variant="body2"
          fontWeight={500}
        />
        {hasMessages && (
          <Typography
            data-testid={`quotes-table-row-cell-message-${number}`}
            variant="caption"
            className="sales-quotes-table-row__message"
          >
            {t('common.newMessage')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <UserChipFilter
          onClick={() => {
            initiateFetch();
          }}
          data-testid="quotes-table-row-cell-assignee"
          options={usersQuery?.data ?? []}
          selected={salesRepresentative ? [salesRepresentative] : []}
          onSelect={onValueAssigneeChange}
          readonly={!quote.canEditNonContractual}
        />
      </Grid>
      <Grid item xs={1}>
        <MoreActionsButton
          disabled={false}
          data-testid={`sales-quote-row-more-${number}`}
          quote={quote}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          onDuplicate={onDuplicate}
        />
      </Grid>
    </ListTableRow>
  );
};
