import React from 'react';
import { Column, TabPanel, useTabs } from 'libs/shared';
import { QuoteListActiveQuotesSection } from './QuoteListActiveQuotesSection';
import { QuoteListArchivedQuotesSection } from './QuoteListArchivedQuotesSection';
import { Tab, Tabs } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

type QuoteListMobileProps = {
  hasArchivedQuotes: boolean;
};

export function QuoteListMobile({ hasArchivedQuotes }: QuoteListMobileProps) {
  const { t } = useTranslation();
  const { selectedTabIndex, tabChangeHandler } = useTabs();

  return (
    <Column gap={2}>
      <Tabs value={selectedTabIndex} onChange={tabChangeHandler} sx={tabsSx}>
        <Tab label={t('common.quote')} />
        <Tab label={t('common.archived')} />
      </Tabs>
      <TabPanel activeTab={selectedTabIndex} idx={0}>
        <QuoteListActiveQuotesSection />
      </TabPanel>
      <TabPanel activeTab={selectedTabIndex} idx={1}>
        {hasArchivedQuotes ? <QuoteListArchivedQuotesSection /> : null}
      </TabPanel>
    </Column>
  );
}

const tabsSx = {
  borderBottom: 1,
  borderColor: 'divider',
};
