import { Brand } from '@clippings/paper';

type PurchaseOrderDocumentValidationState =
  | 'purchaseOrderDocumentRequired'
  | 'purchaseOrderDocumentNotRequired';

export const purchaseOrderDocumentConfig: Record<
  Brand & 'core',
  PurchaseOrderDocumentValidationState
> = {
  [Brand.Fomcore]: 'purchaseOrderDocumentRequired',
  [Brand.Moroso]: 'purchaseOrderDocumentNotRequired',
  [Brand.Sossego]: 'purchaseOrderDocumentNotRequired',
  core: 'purchaseOrderDocumentNotRequired',
};
