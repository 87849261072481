import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ExpandMoreIcon,
  Typography,
} from '@clippings/paper';

export interface FilterSectionProps {
  label: string;
  children?: React.ReactNode;
}

export const FilterSection: React.FC<FilterSectionProps> = ({ label, children }) => (
  <Accordion
    sx={{
      '&:before': {
        backgroundColor: 'transparent',
      },
    }}
    elevation={0}
    square
    disableGutters
    defaultExpanded
  >
    <AccordionSummary
      sx={{
        px: 2,
        py: 0,
      }}
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography variant="subtitle2">{label}</Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        p: 0,
      }}
    >
      {children}
    </AccordionDetails>
  </Accordion>
);
