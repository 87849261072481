import React from 'react';
import { ConfiguredProduct, CustomItemHit, isProductHit } from '../common/types';
import { CustomItemAutocompleteItem } from './CustomItemAutocompleteItem';
import { Hit } from 'react-instantsearch-core';
import { ProductAutocompleteItem } from './ProductAutocompleteItem';
import { ProductHit } from 'libs/Quotes/quote.types';
import { Quote } from 'libs/api/quotes/types';
import { useHits } from 'react-instantsearch-hooks-web';

export type HitsListProps = {
  onClose: () => void;
  quote: Quote;
  onAddItem: (data: ProductHit) => void;
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  onAddCustomItem: (value: string) => void;
};

export const HitsList: React.FC<HitsListProps> = ({
  onClose,
  quote,
  onConfigure,
  onAddItem,
  onAddCustomItem,
}) => {
  const { hits } = useHits<Hit<ProductHit | CustomItemHit>>();

  return (
    <>
      {hits.map(hit => {
        if (isProductHit(hit)) {
          return (
            <ProductAutocompleteItem
              hit={hit}
              quote={quote}
              key={hit.objectID}
              onConfigure={onConfigure}
              onAddItem={onAddItem}
              onClose={onClose}
            />
          );
        }

        return (
          <CustomItemAutocompleteItem
            hit={hit}
            key={hit.objectID}
            onAddItem={onAddCustomItem}
            onClose={onClose}
          />
        );
      })}
    </>
  );
};
