import React, { FC, useEffect, useState } from 'react';
import {
  Badge,
  IconButton,
  IconButtonProps,
  ShowElement,
  SpeakerNotesIcon,
  Tooltip,
  Typography,
} from '@clippings/paper';
import { BaseUser } from 'libs/api/common/types';
import { Column } from 'libs/shared';
import { User } from 'libs/api/iam/types';
import { useCurrentUser } from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';
import { useUnreadChannelMessages } from 'libs/Chat/utils/useUnreadChannelMessages';

export interface ChatButtonProps {
  onClick: () => void;
  iconButtonProps?: IconButtonProps;
  quoteSalesRep?: BaseUser;
  iconOnly?: boolean;
}

const isChatDisabled = (currentUser?: User, quoteSalesRep?: BaseUser) => {
  return !currentUser || !quoteSalesRep;
};

export const ChatButton: FC<ChatButtonProps> = ({
  onClick,
  iconButtonProps,
  quoteSalesRep,
  iconOnly = false,
}) => {
  const currentUser = useCurrentUser()?.data;
  const { t } = useTranslation();
  const { unreadMessages } = useUnreadChannelMessages();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // Disable chat if current user is not the assigned sales rep
  const isDisabled = isChatDisabled(currentUser, quoteSalesRep);

  // Controlling the input via the handler and useEffect to prevent the tooltip text from hanging in certain edge cases
  const onOpenHandler = (open: boolean) => {
    if (!isDisabled) {
      return;
    }

    setTooltipOpen(open);
  };
  useEffect(() => {
    if (!isDisabled) {
      setTooltipOpen(false);
    }
  }, [isDisabled]);

  return (
    <Tooltip
      title={t('chat.salesRepReassignmentError')}
      placement="top"
      data-testid="chat-button-tooltip"
      onOpen={() => onOpenHandler(true)}
      onClose={() => onOpenHandler(false)}
      open={tooltipOpen}
    >
      <Column alignItems="center">
        <IconButton
          color="secondary"
          aria-label="comments"
          onClick={onClick}
          data-testid="chat-drawer-open-btn"
          disabled={isDisabled}
          {...iconButtonProps}
        >
          <Badge badgeContent={unreadMessages} color="error">
            <SpeakerNotesIcon />
          </Badge>
        </IconButton>
        {!iconOnly && (
          <ShowElement above="md">
            <Typography variant="body2">{t('chat.chatWithUs')}</Typography>
          </ShowElement>
        )}
      </Column>
    </Tooltip>
  );
};
