import React, { useState } from 'react';
import {
  AlertSnackbar,
  ArrowBackIcon,
  BlockIcon,
  Box,
  Button,
  CheckIcon,
  EmailIcon,
  ListItemIcon,
  MenuItem,
  MoreActionsButton,
  Skeleton,
  Typography,
  getUserFullName,
} from '@clippings/paper';
import { ApprovedCustomer } from './details/ApprovedCustomer';
import { Navigate, Link as RouterLink, useParams } from 'react-router-dom';
import { NotApprovedCustomer } from './details/NotApprovedCustomer';
import { UpdateUserVariables, UserPermission, UserStatus } from 'libs/api/iam/types';
import { UserApprovalStatus } from 'libs/SalesApp/Users/staff/components/UserApprovalStatus';
import { UserOnlineStatus } from 'libs/SalesApp/Users/staff/components/UserOnlineStatus';
import { hasPermissions } from 'libs/Utils';
import { routes } from 'libs/Constants';
import { updateUser } from 'libs/api/iam/actions';
import { useBanner } from 'libs/Quotes/Components/AlertBanner';
import { useGetUser, useResetPassword } from 'libs/api/iam/hooks';
import { useMutation } from '@tanstack/react-query';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const CustomerUserDetailsPage: React.FC = () => {
  const { showErrorBanner } = useBanner();
  const [moreActionsOpen, setMoreActionsOpen] = React.useState(false);
  const [openResetPasswordSuccess, setOpenResetPasswordSuccess] = useState(false);
  const { t } = useTranslation();
  const userMutation = useMutation((user: UpdateUserVariables) => updateUser(user.id, user));

  const resetPasswordMutation = useResetPassword({
    onSuccess: () => {
      setOpenResetPasswordSuccess(true);
    },
    onError() {
      showErrorBanner(t('common.passwordLinkWasAlreadySentPleaseTryAgain', { minutes: 5 }));
    },
  });

  const { userId } = useParams();
  const { data: user, refetch, isLoading, isRefetching } = useGetUser(Number(userId || '0'));

  const handleUpdateUserStatus = ({ status }: { status: UserStatus }) => {
    if (!user) {
      return;
    }

    setMoreActionsOpen(false);

    userMutation.mutate(
      { id: user.id, status: status },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleResetPassword = () => {
    setMoreActionsOpen(false);

    if (user?.email) {
      resetPasswordMutation.mutate(user.email);
    }
  };

  useTitle(user ? getUserFullName(user) : t('common.users'));

  if (user && hasPermissions(user, UserPermission.CanAccessSalesApp)) {
    return <Navigate to={routes.genericError} />;
  }

  return (
    <>
      <Box borderBottom={1} borderColor="divider" py={1}>
        <Button
          component={RouterLink}
          to={routes.salesApp.users}
          variant="text"
          aria-label="back"
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          <Typography variant="body2">{t('common.users')}</Typography>
        </Button>
      </Box>

      <Box my={3} display="flex" justifyContent="space-between" alignItems="center">
        {user ? (
          <>
            <AlertSnackbar
              onClose={() => setOpenResetPasswordSuccess(false)}
              data-testid="reset-password-success-snackbar"
              open={openResetPasswordSuccess}
              message={t('common.weSentAPasswordResetLink', { email: user.email })}
            />
            <Box display="flex" flexWrap="nowrap" alignContent="center" gap={2} alignItems="center">
              <Typography
                data-testid="customer-user-details-fullname"
                variant="h5"
                fontWeight="bold"
              >
                {user.firstName} {user.lastName}
              </Typography>
              <UserApprovalStatus user={user} />
              <UserOnlineStatus user={user} />
            </Box>
            <MoreActionsButton
              data-testid="user-item-more-button"
              isOpen={moreActionsOpen}
              onToggle={setMoreActionsOpen}
              variant="contained"
              iconButtonProps={{
                size: 'small',
              }}
            >
              <MenuItem
                onClick={handleResetPassword}
                data-testid="user-item-more-reset-password-button"
              >
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                {t('users.actions.sendResetPasswordEmail')}
              </MenuItem>
              {user.status === UserStatus.Approved && (
                <MenuItem
                  data-testid="user-item-more-reject-user-button"
                  onClick={() => handleUpdateUserStatus({ status: UserStatus.Rejected })}
                >
                  <ListItemIcon>
                    <BlockIcon />
                  </ListItemIcon>
                  {t('common.rejectUser')}
                </MenuItem>
              )}
              {user.status === UserStatus.Rejected && (
                <MenuItem
                  data-testid="user-item-more-approve-user-button"
                  onClick={() => handleUpdateUserStatus({ status: UserStatus.Approved })}
                >
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  {t('common.approveUser')}
                </MenuItem>
              )}
            </MoreActionsButton>
          </>
        ) : (
          <Skeleton width="100%" height="50px" />
        )}
      </Box>
      {!isLoading && !isRefetching && user ? (
        user.status === UserStatus.Approved ? (
          <ApprovedCustomer user={user} />
        ) : (
          <NotApprovedCustomer user={user} />
        )
      ) : (
        <>
          <Skeleton width="100%" height="270px" variant="rectangular" />
          <Skeleton width="100%" height="500px" variant="rectangular" sx={{ my: 2 }} />
        </>
      )}
    </>
  );
};
