import React from 'react';
import { TableCell, TableHead, TableRow, useTranslation } from '@clippings/paper';

export function ProductDiscountTableHead() {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell width="15%">{t('companies.discountHeaderAmount')}</TableCell>
        <TableCell>{t('common.catalogues')}</TableCell>
        <TableCell>{t('common.categories')}</TableCell>
      </TableRow>
    </TableHead>
  );
}
