import { BaseAlgoliaValue } from '../components/AlgoliaCellPopoverContent';
import { QuoteDiscountType, SalesQuoteDiscount } from 'libs/api/common/types';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useQuoteSummaryState } from 'libs/SalesApp/Quotes/hooks/useQuoteSummaryState';

type DiscountHandlerOptions = {
  name: string;
  value?: number;
  type?: QuoteDiscountType;
};

export const useDiscount = () => {
  const { quote, handleQuoteUpdate } = useQuoteProvider();
  const { setInputValue, inputValue, newItemIndex } = useQuoteSummaryState();

  const discountChangeHandler = (index: number, discount: SalesQuoteDiscount | null) => {
    const newDiscounts = [...quote.discountItems];

    if (discount === null) {
      newDiscounts.splice(index, 1);
    } else {
      newDiscounts.splice(index, 1, discount);
    }

    handleQuoteUpdate({
      discountItems: newDiscounts,
    });
  };

  const discountSelectHandler = (discount: BaseAlgoliaValue<number>) => {
    const value = { ...discount, type: QuoteDiscountType.FixedAmount };
    addDiscountHandler(value);
  };

  const discountCreateHandler = (name: string) => addDiscountHandler({ name });

  const addDiscountHandler = ({
    name,
    value = 1,
    type = QuoteDiscountType.Percentage,
  }: DiscountHandlerOptions) => {
    setInputValue('');
    newItemIndex.current--;

    handleQuoteUpdate({
      discountItems: [
        ...quote.discountItems,
        {
          id: newItemIndex.current,
          name,
          type,
          value,
          price: {
            amount: 0,
            currency: quote.total.currency,
          },
        },
      ],
    });
  };

  return {
    inputValue,
    setInputValue,
    addDiscountHandler,
    discountChangeHandler,
    discountSelectHandler,
    discountCreateHandler,
  };
};
