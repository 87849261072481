import defaultExchangeRates from 'libs/Globals/exchangeRates';

export function convertPrice(
  amount: number,
  originalCurrency: string,
  desiredCurrency: string,
  exchangeRates = defaultExchangeRates
) {
  if (!amount) {
    return amount;
  }

  if (desiredCurrency === originalCurrency) {
    return amount;
  }

  const exchangeRate =
    exchangeRates[(originalCurrency + desiredCurrency) as keyof typeof exchangeRates];

  return Math.round(amount * exchangeRate * 100) / 100;
}

export const convertToPriceObject = (
  { amount, currency }: { amount: number; currency: string },
  targetCurrency = null
) => ({
  amount: convertPrice(amount, currency, targetCurrency ?? currency),
  currency: targetCurrency ?? currency,
});

const priceHelpers = {
  convertPrice,
  convertToPriceObject,
};

export default priceHelpers;
