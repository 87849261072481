import React, { ComponentProps, FC } from 'react';
import { Grid, TableSortLabel } from '@clippings/paper';
import { SortDirection } from 'libs/api/common/types';
import { SortingProps } from 'libs/shared/hooks';
import { getOrder } from 'libs/SalesApp/Quotes/quoteUtils';
import { notEmpty } from 'libs/shared/utils';

export interface SortableTableHeaderCellProps {
  labelProps?: ComponentProps<typeof TableSortLabel>;
  columnName: string;
  label: string;
  currentSort: SortingProps;
  columns: number;
  onSort: (sort: { sort?: string; order?: SortDirection }) => void;
}

export const SortableTableHeaderCell: FC<SortableTableHeaderCellProps> = ({
  labelProps,
  columnName,
  label,
  currentSort,
  columns,
  onSort,
}) => {
  const { order, sort } = currentSort;
  const isCurrentColumn = sort === columnName;
  const isNotEmpty = notEmpty(order) && notEmpty(sort);

  const direction = isCurrentColumn && isNotEmpty ? order : 'asc';
  const onSortClick = () => {
    if (isNotEmpty) {
      onSort({ sort: columnName, order: getOrder(isCurrentColumn, order) });
    }
  };

  return (
    <Grid item xs={columns} data-testid={`sales-app-header-cell-${columnName}`}>
      <TableSortLabel
        active={isCurrentColumn}
        direction={direction}
        onClick={onSortClick}
        sx={{
          fontSize: 'body2.fontSize',
          fontFamily: 'body2.fontFamily',
          color: 'text.secondary',
          fontWeight: 'medium',
          '&:hover': {
            color: 'text.primary',
          },
        }}
        {...labelProps}
      >
        {label}
      </TableSortLabel>
    </Grid>
  );
};
