import React, { useState } from 'react';
import { Catalogue } from 'libs/api/catalogue/types';
import { CatalogueSelect } from '../CatalogueSelect';
import { CellMoneyInput, CellPercentageInput, TableCell, TableRow } from '@clippings/paper';
import { CompanyQuoteDiscount } from 'libs/api/common/types';
import { RowActionsPanel } from 'libs/shared';

export interface QuoteDiscountRowProps {
  discount: CompanyQuoteDiscount;
  catalogues: Catalogue[];
  onChange: (discount: CompanyQuoteDiscount | null) => void;
}
export const QuoteDiscountRow: React.FC<QuoteDiscountRowProps> = ({
  discount,
  catalogues,
  onChange,
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const showActionPanel = (e: React.MouseEvent<HTMLElement>) => setAnchorElement(e.currentTarget);
  const hideActionPanel = () => setAnchorElement(null);

  const [percent, setPercent] = useState(discount.percent);
  const [amountAbove, setAmountAbove] = useState(discount.amountAbove);
  const [catalogueSlug, setCatalogueSlug] = useState(discount.catalogueSlug ?? '');

  const handlePercentChange = (newValue: number) => {
    setPercent(newValue);
    onChange({ ...discount, percent: newValue });
  };

  const handleAmountChange = (newValue: number) => {
    const newAmountAbove = {
      currency: getCatalogueCurrency(),
      amount: newValue,
    };
    setAmountAbove(newAmountAbove);
    onChange({ ...discount, amountAbove: newAmountAbove });
  };

  const handleCatalogueChange = (newValue: string) => {
    setCatalogueSlug(newValue);
    onChange({ ...discount, catalogueSlug: newValue });
  };

  const handleDelete = () => {
    onChange(null);
  };

  const getCatalogueCurrency = (): string => {
    const catalogue = catalogues.find(c => c.slug === catalogueSlug);
    return catalogue ? catalogue.currency : '';
  };

  return (
    <TableRow onMouseEnter={showActionPanel} onMouseLeave={hideActionPanel}>
      <TableCell>
        <CellPercentageInput
          value={percent!}
          onValueChange={handlePercentChange}
          isAllowed={({ floatValue }) =>
            floatValue === undefined || (floatValue >= 0 && floatValue < 100)
          }
          inputProps={{
            'data-testid': `quote-discount-${discount.id}-percent`,
          }}
          autoFocus
        />
      </TableCell>
      <TableCell>
        <CellMoneyInput
          value={amountAbove.amount}
          onValueChange={handleAmountChange}
          currency={getCatalogueCurrency()}
        />
      </TableCell>
      <TableCell>
        <CatalogueSelect
          value={catalogueSlug}
          catalogues={catalogues}
          onChange={handleCatalogueChange}
        />
      </TableCell>
      <RowActionsPanel anchorElement={anchorElement} onDelete={handleDelete} />
    </TableRow>
  );
};
