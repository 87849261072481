import React, { FC } from 'react';
import { Brand, Checkbox } from '@clippings/paper';
import { MakeDefaultSection } from './MakeDefaultSection';
import { QuoteAddressFormFields } from './QuoteAddressFormFields';
import { useAddressContext } from '../AddressContext';
import { useAppConfiguration } from 'libs/providers';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export interface CreateShippingAddressFormProps {
  isCompanyEditable: boolean;
}
export const CreateShippingAddressForm: FC<CreateShippingAddressFormProps> = ({
  isCompanyEditable,
}) => {
  const { handleOpenSameAsBilling } = useAddressContext();
  const { brand } = useAppConfiguration();
  const { quote } = useQuoteProvider();
  const { t } = useTranslation();

  return (
    <>
      {brand !== Brand.Fomcore && (
        <Checkbox
          onChange={() => handleOpenSameAsBilling!('noop')}
          checked={false}
          label={t('quotes.addresses.sameAsBilling')}
          disabled={!quote.billingAddress?.address}
        />
      )}

      <QuoteAddressFormFields detailed={false} />
      {isCompanyEditable && <MakeDefaultSection />}
    </>
  );
};
