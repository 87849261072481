import React, { FC, PropsWithChildren } from 'react';
import {
  CreateCustomerUserDialog,
  CreateCustomerUserDialogProps,
} from './components/CreateCustomerUserDialog';
import { UserAlertSnackbar } from './components/UserAlertSnackbar';
import { useUserUtils } from '../hooks/useUserUtils';

export type CustomerUserDialogWrapperProps = {
  isUserModalOpen: boolean;
  setIsUserModalOpen: (isOpen: boolean) => void;
} & Partial<Omit<CreateCustomerUserDialogProps, 'isOpen' | 'onCreateSuccess' | 'onClose'>>;

export const CustomerUserDialogWrapper: FC<PropsWithChildren<CustomerUserDialogWrapperProps>> = ({
  setIsUserModalOpen,
  isUserModalOpen,
  ...rest
}) => {
  const {
    newlyCreatedUser,
    newlyUpdatedUser,
    setNewlyCreatedUser,
    setNewlyUpdatedUser,
    navigateToNewlyCreatedUser,
    navigateToNewlyUpdatedUser,
    getCreatedUserSnackbarText,
    getUpdatedUserSnackbarText,
  } = useUserUtils();

  const createSnackbarProps = {
    open: !!newlyCreatedUser,
    onClose: () => setNewlyCreatedUser(null),
    message: getCreatedUserSnackbarText(),
    onActionClick: navigateToNewlyCreatedUser,
  };

  const updateSnackbarProps = {
    open: !!newlyUpdatedUser,
    onClose: () => setNewlyUpdatedUser(null),
    message: getUpdatedUserSnackbarText(),
    onActionClick: navigateToNewlyUpdatedUser,
  };

  return (
    <>
      <UserAlertSnackbar {...createSnackbarProps} />
      <UserAlertSnackbar {...updateSnackbarProps} />
      <CreateCustomerUserDialog
        {...rest}
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
        onCreateSuccess={data => {
          setIsUserModalOpen(false);
          setNewlyCreatedUser(data);
        }}
      />
    </>
  );
};
