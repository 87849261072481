import React, { useMemo } from 'react';
import { BaseTeam, UserPermission } from 'libs/api/iam/types';
import { BaseUser } from 'libs/api/common/types';
import {
  ChipMenuFilter,
  DetailedUserAvatar,
  InputAdornment,
  MenuItem,
  SearchIcon,
  TextField,
  getUserFullName,
} from '@clippings/paper';
import { DEBOUNCE_TIMINGS } from 'libs/Constants';
import { SalesRepSelectLabel } from './SalesRepSelectLabel';
import { debounce } from 'lodash';
import { useListUsers } from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

export interface SalesRepSelectProps {
  salesRep?: SalesRepOption;
  noAssigneeText?: string;
  assigneePrefix?: string;
  onChange: (user: SalesRepOption) => void;
}

export interface SalesRepOption extends BaseUser {
  team?: BaseTeam;
}

export const SalesRepSelect: React.FC<SalesRepSelectProps> = ({
  salesRep,
  noAssigneeText,
  assigneePrefix,
  onChange,
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  const inputRef = React.createRef<HTMLInputElement>();

  const { data: users, isSuccess } = useListUsers({
    permissions: UserPermission.CanAccessSalesApp,
    query: searchValue,
  });

  const handleAssignSalesRep = (user: SalesRepOption) => {
    onChange(user);
    setMenuOpen(false);
  };

  const updateSearchValue = React.useCallback(
    debounce((value: string) => {
      setSearchValue(value);
    }, DEBOUNCE_TIMINGS.INPUT_DEBOUNCE),
    []
  );

  const handleSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSearchValue(event.target.value);
  };

  const salesRepOptions: SalesRepOption[] = useMemo(() => {
    if (users) {
      return users.flatMap(user =>
        user.teams.length > 0 ? user.teams.map(team => ({ ...user, team })) : [user]
      );
    }

    return [];
  }, [users]);

  const searchComponent = (
    <TextField
      data-testid="sales-rep-select-search"
      size="small"
      inputRef={inputRef}
      placeholder={t('companies.quickFind')}
      type="search"
      onChange={handleSearchValueChanged}
      sx={{ mx: 1 }}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <ChipMenuFilter
      chipProps={{
        isOpen: menuOpen,
        onToggle: setMenuOpen,
        label: (
          <SalesRepSelectLabel
            data-testid="sales-rep-select-label"
            user={salesRep}
            noAssigneeText={noAssigneeText}
            prefix={assigneePrefix ?? t('common.assignedTo')}
          />
        ),
        value: '\u00A0',
        hasArrow: true,
      }}
      header={{
        component: searchComponent,
      }}
      contentContainerProps={{ sx: { width: 360, maxHeight: 320 } }}
    >
      {isSuccess &&
        salesRepOptions.map(user => (
          <MenuItem
            data-testid={`sales-rep-select-item-${user.id}`}
            key={`${user.id}${user.team ? '-' + user.team.name : ''}`}
            onClick={() => handleAssignSalesRep(user)}
          >
            <DetailedUserAvatar
              title={getUserFullName({ firstName: user.firstName, lastName: user.lastName })}
              user={user}
              caption={user?.team?.name}
            />
          </MenuItem>
        ))}
    </ChipMenuFilter>
  );
};
