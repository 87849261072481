import React from 'react';
import { AuthenticationImage } from '../AuthenticationImage';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { LoginForm } from '@clippings/paper';
import { getAfterLoginRedirectUrl } from '../AuthenticationUtils';
import { login } from 'libs/api/iam/actions';
import { routes } from 'libs/Constants';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const Login = ({ ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginMutation = useMutation(login, {
    onSuccess: response => {
      const redirectUrl = response.headers['x-dt-redirect'];

      window.location.assign(getAfterLoginRedirectUrl(response.data, redirectUrl));
    },
  });

  const handleSignUp = () => {
    navigate(routes.authentication.register);
  };

  const handleForgotPasswordClick = () => {
    navigate(routes.authentication.resetPassword);
  };
  useTitle(t('common.login'));

  const formLoginErrors = useServerFormErrors(loginMutation.error);

  return (
    <AuthenticationLayout
      content={
        <LoginForm
          onSubmit={loginMutation.mutate}
          loading={loginMutation.isLoading || loginMutation.isSuccess}
          serverErrors={formLoginErrors}
          onSignUpClick={handleSignUp}
          onForgotPasswordClick={handleForgotPasswordClick}
          {...rest}
        />
      }
      sidePanel={<AuthenticationImage />}
    />
  );
};
