import {
  AddQuoteItemCatalogueProduct,
  AddQuoteItemProduct,
  AddSampleToQuotePayload,
  Quote,
  QuoteProductItem,
} from 'libs/api/quotes/types';
import { ConfiguredProduct } from '../../../SalesApp/Quotes/common/types';
import { ProductHit, ProductType } from 'libs/Quotes/quote.types';
import { addProductToQuote, addSampleToQuote } from 'libs/api/quotes/actions';
import { updateQuoteVersionItem } from '../../quoteActions';
import { useAddSample } from 'libs/Quotes/samples';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePaymentTermsQuery } from 'libs/api/quotes/hooks';
import { useQuoteQueryKey, useQuoteUtils } from '../../hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useProductConfigurator = (quote: Quote, onClosePlp: () => void) => {
  const { showSuccessBanner, version, showError, setVersion } = useQuoteUtils();
  const queryClient = useQueryClient();
  const quoteQueryKey = useQuoteQueryKey(quote.number);
  const { createSampleHandler } = useAddSample(quote.versionId);
  const { t } = useTranslation();
  const [configuredProduct, setConfiguredProduct] = useState<ConfiguredProduct | null>(null);

  const paymentTermsQuery = usePaymentTermsQuery(quote.versionId, { enabled: !!quote.versionId });

  const resetConfiguredProduct = () => setConfiguredProduct(null);
  const refetchPaymentTerms = () => paymentTermsQuery.refetch();

  const addToQuoteSuccessHandler = (data: Quote) => {
    queryClient.setQueryData(quoteQueryKey, data);

    showSuccessBanner(
      t('common.addedToQuote', {
        quote: `${quote.name || t('common.quote')} (#${quote.number})`,
      })
    );

    refetchPaymentTerms();
  };

  const addToQuoteMutation = useMutation(
    ({ versionId, payload }: { versionId: number; payload: AddQuoteItemProduct }) =>
      addProductToQuote(versionId, payload),
    {
      onSuccess: addToQuoteSuccessHandler,
      onError: showError,
    }
  );

  const addSampleToQuoteMutation = useMutation(
    ({ versionId, payload }: { versionId: number; payload: AddSampleToQuotePayload }) =>
      addSampleToQuote(versionId, payload),
    {
      onSuccess: addToQuoteSuccessHandler,
      onError: showError,
    }
  );

  const updateQuoteItemMutation = useMutation(
    ({ id, ...data }: Partial<QuoteProductItem> & { id: number }) =>
      updateQuoteVersionItem(quote.versionId, id, data),
    {
      onSuccess: data => {
        setConfiguredProduct(null);
        queryClient.invalidateQueries(quoteQueryKey);
        if (quote.versionNumber && version !== data.versionNumber) {
          setVersion(data.versionNumber);
        }
      },
      onError: showError,
    }
  );

  const handleUpdateExistingProduct = (payload: AddQuoteItemCatalogueProduct) => {
    if (
      configuredProduct &&
      payload.quantity === configuredProduct.existingQuantity &&
      payload.sku === configuredProduct.existingSelection
    ) {
      resetConfiguredProduct();
    } else if (configuredProduct?.quoteItemId) {
      updateQuoteItemMutation.mutate(
        {
          id: configuredProduct.quoteItemId,
          quantity: payload.quantity,
          sku: payload.sku,
          productId: payload.productId,
        },
        { onSuccess: refetchPaymentTerms }
      );
    }
  };

  const handleAddProductFromPlp = (product: ProductHit) => {
    if (product.type === ProductType.Sample) {
      addSampleToQuoteMutation.mutate({
        versionId: quote.versionId,
        payload: {
          id: Number(product.objectID),
        },
      });

      onClosePlp();
      return;
    }

    addToQuoteMutation.mutate({
      versionId: quote.versionId,
      payload: {
        catalogue: quote.catalogue,
        productId: String(product.objectID),
        sku: product.skus[quote.catalogue],
        quantity: 1,
      },
    });

    onClosePlp();
  };

  // samples
  const addSampleHandler = (id: number) => {
    createSampleHandler(
      { id },
      {
        onSuccess: addToQuoteSuccessHandler,
        onError: showError,
      }
    );
  };

  return {
    configuredProduct,
    addToQuoteMutation,
    updateQuoteItemMutation,
    addSampleHandler,
    setConfiguredProduct,
    resetConfiguredProduct,
    handleUpdateExistingProduct,
    handleAddProductFromPlp,
  };
};
