import QuoteContext, { QuoteContextState } from 'libs/Quotes/QuoteContext';
import React, { PropsWithChildren, ReactNode, useContext } from 'react';
import { DuplicatedQuoteBanner } from 'libs/Quotes/Components/DuplicatedQuoteBanner';
import { Quote, QuoteVersion } from 'libs/api/quotes/types';
import { QuoteUpdateOptions } from 'libs/Quotes/quote.types';
import { getActiveQuoteVersion } from 'libs/shared';
import { useAddresses } from 'libs/Quotes/hooks/useAddresses';
import { useListLocations } from 'libs/api/location/hooks';
import { usePaymentTermsQuery } from 'libs/api/quotes/hooks';
import { useQuote } from 'libs/Quotes/hooks';
import { useQuoteHandlers } from 'libs/Quotes/hooks/useQuoteHandlers';
import { useQuoteHiddenDetailProducts } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';

type QuoteDetailsContextValue = QuoteContextState;

export type QuoteDetailsProviderProps = {
  quote: Quote;
  queryKey: string[];
  setVersion: (version: number) => void;
  showError: (err: unknown) => void;
  showSuccessBanner: (banner: ReactNode) => void;
  isFetching: boolean;
  modalHandlers: ReturnType<typeof useQuote>;
};

export const useQuoteDetailsProviderValue = (props: QuoteDetailsProviderProps) => {
  const { quote, queryKey, setVersion, showError, showSuccessBanner, isFetching, modalHandlers } =
    props;

  const {
    isLabelModalOpen,
    openLabelModal,
    closeLabelModal,
    isShippingModalOpen,
    openShippingModal,
    closeShippingModal,
    isBillingModalOpen,
    openBillingModal,
    closeBillingModal,
    isDetailedShippingModal,
    actionAfterSubmit,
  } = modalHandlers;

  const [hiddenDetailProducts, onProductDetailsHide, onAllProductDetailsHide] =
    useQuoteHiddenDetailProducts(quote.number, 'quote');

  const isReadonly = !quote.editable;
  const locationQuery = useListLocations('country,state', {
    enabled: isBillingModalOpen || isShippingModalOpen,
  });

  const {
    handleQuoteAddressChange,
    handleQuoteUpdate,
    handleQuoteItemQuantityUpdate,
    handleQuoteItemDeletion,
    handleUnarchiveQuoteVersion,
    handleArchiveQuote,
    handleDuplicateQuote,
    handleDeleteQuoteVersion,
    handleChangeQuoteVersion,
    handleRequestQuoteApproval,
    handleQuoteItemUpdateByKey,
    handleMakeCustom,
    handleQuoteItemUpdateDiscount,
    handleRestoreQuoteVersion,
    updateProductItemNonContractualDataHandler,
    loading: handlersLoading,
    tableLoading,
    optimisticLoading,
    updateWithoutOptimisticLoading,
    updateAddressMutationLoading,
  } = useQuoteHandlers(quote, queryKey, showError);

  const changeQuoteVersion = (data: QuoteVersion) => {
    setVersion(data.versionNumber);
    handleChangeQuoteVersion(data);
  };

  const { shippingAddress, billingAddress } = useAddresses(quote, queryKey);

  const paymentTermsQuery = usePaymentTermsQuery(quote.versionId, {
    enabled: !!quote && !!quote.customer,
  });

  const handleDuplicateQuoteAction = () => {
    handleDuplicateQuote(({ number }: { number: string }) =>
      showSuccessBanner(<DuplicatedQuoteBanner number={number} type="sales" />)
    );
  };

  const loadLatestVersionHandler = () => {
    const activeVersion =
      getActiveQuoteVersion(quote.versions) ||
      ({
        versionNumber: quote.versionNumber,
      } as QuoteVersion);

    changeQuoteVersion(activeVersion);
  };

  const addressSubmitLoading =
    shippingAddress.isLoading || billingAddress.isLoading || tableLoading;

  const handleNonContractualUpdate = (data: Partial<Quote>, options?: QuoteUpdateOptions) => {
    handleQuoteUpdate(data, {
      ...(options ?? { withOptimisticUpdate: true }),
      nonContractual: true,
    });
  };

  // @ts-expect-error: the context is incomplete it will be refactored on smaller parts
  const value: QuoteDetailsContextValue = {
    quote,
    queryKey,
    isReadonly,
    loading: isFetching || handlersLoading,
    isLabelModalOpen,
    tableLoading: tableLoading,
    optimisticLoading,
    updateWithoutOptimisticLoading,
    handleQuoteUpdate,
    handleNonContractualUpdate,
    updateProductItemNonContractualDataHandler,
    handleLabelModalClose: closeLabelModal,
    handleLabelModalOpen: openLabelModal,
    onQuoteItemQuantityUpdate: handleQuoteItemQuantityUpdate,
    onQuoteItemDelete: handleQuoteItemDeletion,
    onLoadLatestVersion: loadLatestVersionHandler,
    onUnarchiveQuoteVersion: handleUnarchiveQuoteVersion,
    onDuplicateQuote: handleDuplicateQuoteAction,
    onDeleteQuoteVersion: handleDeleteQuoteVersion,
    onQuoteItemUpdateDiscount: handleQuoteItemUpdateDiscount,
    changeQuoteVersion,
    onArchiveQuote: handleArchiveQuote,
    onQuoteItemUpdateByKey: handleQuoteItemUpdateByKey,
    onMakeCustom: handleMakeCustom,
    onRestoreQuoteVersion: handleRestoreQuoteVersion,
    isShippingModalOpen,
    handleShippingModalClose: closeShippingModal,
    handleShippingModalOpen: openShippingModal,
    isBillingModalOpen,
    handleBillingModalClose: closeBillingModal,
    handleBillingModalOpen: openBillingModal,
    handleQuoteAddressChange,
    updateAddressMutationLoading,
    locationQuery,
    addressSubmitLoading,
    shippingAddress,
    billingAddress,
    paymentTermsQuery,
    hiddenDetailProducts,
    onProductDetailsHide,
    onAllProductDetailsHide,
    isDetailedShippingModal,
    handleRequestQuoteApproval,
    actionAfterSubmit,
  };

  return value;
};

export function QuoteDetailsProvider({
  children,
  ...rest
}: PropsWithChildren<QuoteDetailsProviderProps>) {
  const value = useQuoteDetailsProviderValue(rest);
  return <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>;
}

export function useQuoteDetails() {
  const context = useContext(QuoteContext);

  if (!context) {
    throw new Error('useQuoteDetails must be used within a QuoteDetailsProvider');
  }

  return context;
}
