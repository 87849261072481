import { ApplicableDeliveryMethod, QuoteDeliveryItem } from 'libs/api/quotes/types';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useQuoteSummaryState } from 'libs/SalesApp/Quotes/hooks/useQuoteSummaryState';

export const useDelivery = () => {
  const { quote, handleQuoteUpdate } = useQuoteProvider();
  const { setInputValue, inputValue, newItemIndex } = useQuoteSummaryState();

  const deliveryItemChangeHandler = (index: number, deliveryItem: QuoteDeliveryItem | null) => {
    const newDeliveryItems = [...quote.deliveryItems];

    if (deliveryItem === null) {
      newDeliveryItems.splice(index, 1);
    } else {
      newDeliveryItems.splice(index, 1, deliveryItem);
    }

    handleQuoteUpdate(
      {
        deliveryItems: newDeliveryItems,
      },
      { withOptimisticUpdate: false }
    );
  };

  const addDeliveryItemHandler = (name: string) => {
    setInputValue('');
    newItemIndex.current--;

    handleQuoteUpdate(
      {
        deliveryItems: [
          ...quote.deliveryItems,
          {
            id: newItemIndex.current,
            deliveryRef: null,
            name,
            custom: true,
            taxPercentage: 0,
            netPrice: { amount: 0, currency: quote.total.currency },
            shippingTime: [0, 0],
            tax: { amount: 0, currency: quote.total.currency },
            type: 'manual',
          },
        ],
      },
      { withOptimisticUpdate: true }
    );
  };

  const addPredefinedHandler = (method: ApplicableDeliveryMethod) => {
    setInputValue('');
    newItemIndex.current--;

    handleQuoteUpdate(
      {
        deliveryItems: [
          ...quote.deliveryItems,
          {
            id: newItemIndex.current,
            deliveryRef: method.id,
            custom: false,
            netPrice: {
              amount: method.price.amount,
              currency: quote.total.currency,
            },
          } as QuoteDeliveryItem,
        ],
      },
      { withOptimisticUpdate: false }
    );
  };

  return {
    inputValue,
    setInputValue,
    addDeliveryItemHandler,
    deliveryItemChangeHandler,
    addPredefinedHandler,
  };
};
