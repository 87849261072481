import React from 'react';
import { DiscountsDialogContentBox } from './DiscountsDialogContentBox';
import { Skeleton } from '@clippings/paper';

export function DiscountsDialogLoading() {
  return (
    <DiscountsDialogContentBox>
      <Skeleton variant="rectangular" height="60px" sx={{ borderRadius: 1 }} />
      <Skeleton variant="rectangular" height="180px" />
      <Skeleton variant="rectangular" height="60px" sx={{ borderRadius: 1 }} />
      <Skeleton variant="rectangular" height="180px" />
    </DiscountsDialogContentBox>
  );
}
