import convertImgToDataURL from 'libs/Quotes/ExcelExport/convertImgToDataURL';
import { Column, Workbook, Worksheet, WorksheetView } from 'exceljs';
import { ColumnModel, ColumnsModel, TotalColumn, useColumns } from './columns';
import { Config, addItemsToCells, addPaymentTerms, defaultConfig } from './utils';
import { Payment } from 'libs/api/common/types';
import { Quote, QuoteProductItem } from 'libs/api/quotes/types';
import { TFunction } from 'i18next';
import { TranslationKey } from '../../types/react-i18next';
import { getSmallProductImageUrl } from '@clippings/paper';

type Data = {
  creatorName: string;
  columns: ColumnModel[];
  quote: Quote;
  totalColumns: TotalColumn[];
  paymentTerms: Payment[];
};

const convertImagesFunction = (items: Quote['quoteItemProducts']) => {
  return items.map(({ picture }) => {
    if (!picture) {
      return Promise.resolve('');
    }
    return convertImgToDataURL(getSmallProductImageUrl(picture));
  });
};

const formatRows = (worksheet: Worksheet, config: Config) => {
  worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
    if (rowNumber === 1) {
      row.font = config.headerFont;
      row.eachCell(cell => {
        cell.style.alignment = { horizontal: 'center' };
      });
      return;
    }
    row.font = config.contentFont;
    row.height = config.defaultHeight;
  });
};

const formatColumns = (columns: Data['columns'], worksheet: Worksheet, config: Config) => {
  columns.forEach((col: any, index: number) => {
    const column = worksheet.getColumn(index + 1);
    column.width = config.defaultWidth;
    column.alignment = { wrapText: true };
    Object.assign(column, col.column);
  });
};

const getWorksheet = (
  workbook: Workbook,
  config: Config,
  columns: Data['columns'],
  t: TFunction
) => {
  const worksheet = workbook.addWorksheet(config.name);
  worksheet.views = [config.view as Partial<WorksheetView>];
  worksheet.columns = columns.map((x: any) => ({
    ...x,
    header: t(x.header as TranslationKey),
  })) as Partial<Column>[];

  worksheet.autoFilter = {
    from: {
      row: 1,
      column: 1,
    },
    to: {
      row: 1,
      column: columns.length,
    },
  };

  return worksheet;
};

const mapItemsToRows = (items: Quote['quoteItemProducts'], columns: Data['columns']) =>
  items.map(item =>
    columns.reduce((accumulator: any, col: any) => {
      accumulator[col.key] = col.converter
        ? col.converter(item)
        : item[col.key as keyof QuoteProductItem];
      return accumulator;
    }, {})
  );

const addImages = async (
  workbook: Workbook,
  worksheet: Worksheet,
  columns: Data['columns'],
  items: Quote['quoteItemProducts']
) => {
  const imageIndex = columns.findIndex((col: { key: string }) => col.key === 'image');
  const startingPoint = imageIndex + 0.01;

  const images = await Promise.all(convertImagesFunction(items));

  images
    .filter(img => !!img) // filter all empty images
    .forEach((img, index) => {
      const image = workbook.addImage({
        base64: img,
        extension: 'png',
      });

      worksheet.addImage(image, {
        tl: { col: startingPoint, row: index + 1.1 } as any,
        br: { col: imageIndex + 1, row: index + 2 } as any,
        editAs: 'oneCell',
      });
    });

  return workbook.xlsx.writeBuffer();
};

const styleTotalsCell = (cell: any, config: Config, isValue: boolean) => {
  cell.font = { ...config.headerFont, bold: isValue, size: 15 };
  cell.alignment = { vertical: 'middle', horizontal: 'center' };
  cell.height = 50;
  cell.border = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
  };
};

const addTotals = (
  worksheet: Worksheet,
  quote: Quote,
  columns: Data['totalColumns'],
  config: Config,
  t: TFunction
) => {
  const { rowCount } = worksheet;
  const titleCellLetter = 'A';
  const valueCellLetter = 'B';
  const rowNumber = rowCount + 3;

  columns.forEach((column, index) => {
    const titleCellIndex = `${titleCellLetter}${rowNumber + index}`;
    const valueCellIndex = `${valueCellLetter}${rowNumber + index}`;

    const titleCell = worksheet.getCell(titleCellIndex);

    titleCell.value = t(column.header);

    const valueCell = worksheet.getCell(valueCellIndex);
    valueCell.value = column.converter(quote);

    styleTotalsCell(titleCell, config, column.key === 'total');
    styleTotalsCell(valueCell, config, true);
  });
};

const generateExcel = async (
  data: Data,
  config: Config,
  t: TFunction,
  columnsModel: ColumnsModel,
  formatDetails: any,
  formatType: any
) => {
  const { quote, columns, totalColumns: columnsTotal, paymentTerms } = data;
  const { quoteItemProducts: items } = quote;

  const ExcelGenerator = (await import(/* webpackChunkName: "exceljs" */ 'exceljs')).default;

  const workbook = new ExcelGenerator.Workbook();
  workbook.creator = config.creator;

  const worksheet = getWorksheet(workbook, config, columns, t);
  worksheet.name = quote.name ?? config.name;
  const rows = mapItemsToRows(items, columns);

  worksheet.addRows(rows);

  formatColumns(columns, worksheet, config);
  formatRows(worksheet, config);

  addTotals(worksheet, quote, columnsModel.subTotalColumnsModel, config, t);
  addItemsToCells(
    worksheet,
    quote.deliveryItems,
    columnsModel.shippingColumnsModel,
    'Shipping',
    config
  );
  addItemsToCells(
    worksheet,
    quote.discountItems,
    columnsModel.discountsColumnsModel,
    'Discounts',
    config
  );
  addTotals(worksheet, quote, columnsTotal, config, t);

  addPaymentTerms(worksheet, paymentTerms, config, t, columnsModel, formatDetails, formatType);

  if (columns.find((col: { key: string }) => col.key === 'image')) {
    return addImages(workbook, worksheet, columns, items);
  }

  return workbook.xlsx.writeBuffer();
};

export const downloadAsExcelFile = async (
  data: Data,
  fileName: string,
  t: TFunction,
  columns: ReturnType<typeof useColumns>,
  formatDetails: (paymentTerm: Payment, immediateText: TranslationKey) => string,
  formatType: (paymentTerm: Payment) => string
) => {
  const saveAs = (await import(/* webpackChunkName: "file-saver" */ 'file-saver')).default;
  const config: Config = {
    ...defaultConfig,
    name: fileName,
    creator: data.creatorName,
  };

  const excelData = await generateExcel(data, config, t, columns, formatDetails, formatType);

  const blob = new Blob([excelData], { type: 'application/octet-stream' });
  saveAs(blob, `${config.name}.xlsx`);
};
