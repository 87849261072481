import React from 'react';
import classNames from 'classnames';
import {
  CompositeDiscountCell,
  CompositeDiscountValue,
} from 'libs/SalesApp/Quotes/components/CompositeDiscountCell';
import { TableCell } from '@clippings/paper';
import { TableCellCommonProps } from './tableCells.utils';
import { isEqual } from 'lodash';

type DiscountTableCellProps = {
  onQuoteItemUpdateDiscount: (discount: CompositeDiscountValue, itemId: number) => void;
} & Pick<TableCellCommonProps, 'item' | 'readonly'>;

export function DiscountTableCell({
  item,
  readonly,
  onQuoteItemUpdateDiscount,
}: DiscountTableCellProps) {
  const handleDiscountPercentChange = (value: CompositeDiscountValue) => {
    if (isEqual(item.discountTitle, value.title)) {
      return;
    }

    onQuoteItemUpdateDiscount(value, item.id);
  };

  return (
    <TableCell
      align="right"
      className={classNames('quote-item-row__cell', 'quote-item-row__cell__discount')}
    >
      <CompositeDiscountCell
        key={`discount-${item.discountPercent}`}
        data-testid={`discount-percent-${item.id}`}
        initialValue={item.discountTitle}
        sx={compositeDiscountSx}
        disabled={readonly}
        onBlur={handleDiscountPercentChange}
      />
    </TableCell>
  );
}

const compositeDiscountSx = {
  marginTop: '9px',
  fontSize: 'inherit',
};
