import QuoteLabelButton from './QuoteLabel/QuoteLabelButton';
import React, { ComponentProps, FC } from 'react';
import { ChatButton, useChatVisibility } from 'libs/Chat';
import { Column, voidFn } from 'libs/shared';
import { FlexColumn, FlexRow, Grid, ShowElement, Typography } from '@clippings/paper';
import { IntendedUseLabel } from './QuoteLabel/IntendedUseLabel';
import { MoreActionsButton } from './Components/MoreActionsButton';
import { QuoteVersionSelect } from './QuoteVersionSelect/QuoteVersionSelect';
import { ShareModalButton } from './Components/ShareModal/components/ShareModalButton';
import { useAppConfiguration } from 'libs/providers';
import { useQuoteProvider } from './providers';
import { useQuoteUserActions } from './hooks';
import { useTranslation } from 'react-i18next';

export type QuoteHeaderProps = Omit<ComponentProps<typeof Column>, 'ref'> & {
  withActions?: boolean;
  withXmlImport?: boolean;
};

export const QuoteHeader: FC<QuoteHeaderProps> = ({
  withActions = true,
  withXmlImport = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    quote,
    loading,
    optimisticLoading,
    onDuplicateQuote,
    onUnarchiveQuoteVersion,
    onArchiveQuote,
    addressSubmitLoading,
  } = useQuoteProvider();
  const { onToggle: toggleChat } = useChatVisibility();
  const { hasQuotePermission } = useQuoteUserActions(quote?.userActions ?? []);
  const { quoteSharingEnabled } = useAppConfiguration();
  const canShareQuote = hasQuotePermission('CAN_SHARE_QUOTE');

  const shouldRenderShareButton = withActions && quoteSharingEnabled && canShareQuote;

  return (
    <>
      <Column alignItems="flex-start" gap={{ xs: 1, md: 0 }} mb={1} mt={1} {...rest}>
        <Grid container gap={1} justifyContent="space-between" flexWrap="wrap">
          <Grid display="flex" gap={1} flexWrap="wrap" alignItems="center">
            <Typography variant="h5" data-testid="quote-header-number">
              {t('common.quote')} #{quote.number.toUpperCase()}
            </Typography>
            <ShowElement above="md">
              <QuoteVersionSelect />
            </ShowElement>
            {withActions && !loading ? (
              <MoreActionsButton
                data-testid="more-actions-button"
                variant="contained"
                iconButtonProps={{
                  size: 'small',
                }}
                disabled={optimisticLoading || addressSubmitLoading}
                quote={quote}
                onArchive={onArchiveQuote}
                onUnarchive={onUnarchiveQuoteVersion}
                onDuplicate={onDuplicateQuote}
                withXmlImport={withXmlImport}
                onClick={voidFn}
              />
            ) : null}
          </Grid>

          <FlexRow gap={2}>
            {shouldRenderShareButton && (
              <ShareModalButton
                quote={quote}
                loading={loading}
                disabled={!quote.visibleForCustomer}
              />
            )}
            <ChatButton iconOnly quoteSalesRep={quote?.salesRepresentative} onClick={toggleChat} />
          </FlexRow>
        </Grid>
        <FlexColumn gap={1}>
          <QuoteLabelButton />
          <IntendedUseLabel />
        </FlexColumn>
        <ShowElement below="md">
          <QuoteVersionSelect />
        </ShowElement>
      </Column>
    </>
  );
};
