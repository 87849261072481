import React from 'react';
import { AuthenticationImage } from '../AuthenticationImage';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { NewPasswordForm } from '@clippings/paper';
import { changePassword } from 'libs/api/iam/actions';
import { getAfterLoginRedirectUrl } from '../AuthenticationUtils';
import { routes } from 'libs/Constants';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'libs/Utils/Hooks';
import { useServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { useTitle } from 'react-use';
import { useTranslation } from 'react-i18next';

export const NewPassword = () => {
  const { t } = useTranslation();
  useTitle(t('common.newPassword'));

  const navigate = useNavigate();
  const changePasswordMutation = useMutation(changePassword);
  const [{ token }] = useQueryParams();

  const handleSignIn = () => {
    navigate(routes.login);
  };

  const handleBackToHome = () => {
    window.location.assign(getAfterLoginRedirectUrl(changePasswordMutation.data!));
  };

  const formErrors = useServerFormErrors(changePasswordMutation.error);

  return (
    <AuthenticationLayout
      content={
        <NewPasswordForm
          showConfirmation={changePasswordMutation.isSuccess}
          loading={changePasswordMutation.isLoading || changePasswordMutation.isSuccess}
          serverErrors={formErrors}
          onSubmit={({ password }) => changePasswordMutation.mutate({ password, token })}
          onSignInClick={handleSignIn}
          onBackToHome={handleBackToHome}
        />
      }
      sidePanel={<AuthenticationImage />}
    />
  );
};
