import React from 'react';
import { Box, useTheme } from '@clippings/paper';
import { QuoteInfoLabel } from './info';
import { useTranslation } from 'react-i18next';

export function QuoteCompanySameRow() {
  const {
    images: { logo },
  } = useTheme();
  const { t } = useTranslation();

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap={1}>
      <Box>
        <img src={logo} height="40px" alt={t('brandData.name')} />
      </Box>
      <Box>
        <QuoteInfoLabel bold text="MOROSO GULF" />
        <QuoteInfoLabel text="PO BOX 15258 - Manama" />
        <QuoteInfoLabel text="- KINGDOM OF BAHRAIN (+)" />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={1} alignSelf="baseline">
        <>
          <QuoteInfoLabel text="Phone" />
          <QuoteInfoLabel text="+973 1759 3616 ext.505" />
        </>
        <>
          <QuoteInfoLabel text="Fax" />
          <QuoteInfoLabel text="+973 17 595 820" />
        </>
        <>
          <QuoteInfoLabel text="E-Mail" />
          <QuoteInfoLabel text="info@morosogulf.it" />
        </>
      </Box>
    </Box>
  );
}
