import React, { useContext } from 'react';
import { EmptyTablePlaceholder } from 'libs/Components';
import { Link, LoadMoreDivider, useIsMobile } from '@clippings/paper';
import { ListCardLoader } from 'libs/shared';
import { MoreActionsButton } from 'libs/Quotes/Components/MoreActionsButton';
import { QuoteCardListRenderer } from './components/QuoteCardListRenderer';
import { QuoteListCardItemViewModel } from './components/QuoteListItemCard';
import { QuoteListContext } from './QuoteListContext';
import { QuoteListSection } from './QuoteListSection';
import { useReturnUrl } from 'libs/Utils/Hooks/useReturnUrl';
import { useTranslation } from 'react-i18next';

export const QuoteListActiveQuotesSection = () => {
  const returnUrl = useReturnUrl();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const {
    activeQuotes,
    activeQuotesLoading,
    archivedQuotes,
    handleLoadMoreActiveQuotes,
    activeShowLoadMoreQuotes,
    disableActions,
    activeLoadMoreLoading,
    onArchive,
    onUnarchive,
    onDuplicate,
  } = useContext(QuoteListContext);

  if (!activeQuotesLoading && activeQuotes !== null && activeQuotes?.length === 0) {
    return (
      <EmptyTablePlaceholder
        title={
          archivedQuotes?.length === 0
            ? t('quotes.noQuotesMessage')
            : t('quotes.noActiveQuotesMessage')
        }
        buttonTitle={t('quotes.browseOurProducts')}
        buttonProps={{
          LinkComponent: Link,
          href: returnUrl,
        }}
        containerProps={{ 'data-testid': 'quote-list-empty-state' }}
      />
    );
  }

  if (isMobile) {
    const cardActions = (item: QuoteListCardItemViewModel) => {
      return (
        <MoreActionsButton
          disabled={disableActions}
          quote={activeQuotes[item.position]}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          onDuplicate={onDuplicate}
        />
      );
    };

    const loadMore = activeLoadMoreLoading ? (
      <ListCardLoader />
    ) : (
      <LoadMoreDivider
        className="load-more-divider"
        hasNextPage={activeShowLoadMoreQuotes}
        noMoreLabel={t('quotes.noMoreQuotes')}
        onClick={handleLoadMoreActiveQuotes}
      />
    );

    return (
      <QuoteCardListRenderer
        isLoading={activeQuotesLoading}
        items={activeQuotes}
        cardActions={cardActions}
        loadMore={loadMore}
      />
    );
  }

  return (
    <QuoteListSection
      hasMoreLoading={activeLoadMoreLoading}
      disableActions={disableActions}
      data-testid="active-quote-list-section"
      loading={activeQuotesLoading}
      onClick={handleLoadMoreActiveQuotes}
      hasMore={activeShowLoadMoreQuotes}
      quotes={activeQuotes || []}
    />
  );
};
