import { ContractFields, Quote } from 'libs/api/quotes/types';
import { LineItemVisibleFields } from 'libs/api/configuration/types';
import { Nullable } from 'libs/Utils';
import { Order } from 'libs/api/order/types';
import { isEmpty, some, values } from 'lodash';
import { tableContractFields } from '../config';
import { useAppConfiguration } from 'libs/providers';

export type Items = Quote['quoteItemProducts'] | Order['productItems'];

export function useQuoteTableVisibleFields(
  contractFields: Nullable<ContractFields>,
  forceRemoveFields: LineItemVisibleFields = []
) {
  const { lineItemVisibleFields: lineItemVisibleFieldsEnv } = useAppConfiguration();
  const hasValues = contractMetadataHasValue(contractFields);

  if (!hasValues) {
    return {
      lineItemVisibleFields: removeFields(
        removeContractFields(lineItemVisibleFieldsEnv),
        forceRemoveFields
      ),
    };
  }

  return {
    lineItemVisibleFields: removeFields(lineItemVisibleFieldsEnv, forceRemoveFields),
  };
}

function removeFields(
  lineItemVisibleFields: LineItemVisibleFields,
  fieldsToRemove: Partial<LineItemVisibleFields>
) {
  return [...lineItemVisibleFields].filter(f => !fieldsToRemove.includes(f));
}

function removeContractFields(lineItemVisibleFields: LineItemVisibleFields): LineItemVisibleFields {
  return removeFields(lineItemVisibleFields, tableContractFields);
}

function contractMetadataHasValue(contractFields: Nullable<ContractFields>) {
  return !isEmpty(contractFields) && some(values(contractFields), value => !!value?.amount);
}
