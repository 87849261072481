import visitor from 'libs/Globals/visitor';
import { getAvailableLocalesOptions } from 'libs/Utils/locales';
import { useAppConfiguration } from 'libs/providers';
import { useBanner } from 'libs/Quotes/Components/AlertBanner';
import { useMemo } from 'react';
import { useUpdateUser } from 'libs/api/iam/hooks';

export function useLanguageChange() {
  const { showErrorBanner } = useBanner();
  const { supportedLocales } = useAppConfiguration();
  const userMutation = useUpdateUser({
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => showErrorBanner(),
  });
  const availableLocalesOptions = useMemo(() => getAvailableLocalesOptions(supportedLocales), []);
  const handleLanguageSelect = (locale: string) =>
    userMutation.mutate({
      id: visitor.userId,
      locale: locale.replace('-', '_'),
    });

  return {
    languageSelectAvailable: availableLocalesOptions.length > 1,
    handleLanguageSelect,
  };
}
