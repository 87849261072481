import React from 'react';
import { QuoteInfoSimpleItem } from './QuoteInfoSimpleItem';
import { Title } from 'libs/shared';
import { useTranslation } from 'react-i18next';

interface QuoteTitleProps {
  quoteNumber: string;
  quoteName?: string;
}

export function QuoteTitle({ quoteNumber, quoteName }: QuoteTitleProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Title>
        {t('quotes.pdf.quoteTitle')}
        {quoteNumber}
      </Title>
      {quoteName ? (
        <QuoteInfoSimpleItem label={t('quotes.pdf.info.project')} value={quoteName} />
      ) : null}
    </>
  );
}
