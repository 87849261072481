import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Box } from '@clippings/paper';
import './TotalsSummaryRow.scss';

type TotalsSummaryRowProps = ComponentProps<typeof Box> & {
  hasBorder?: boolean;
};

export const TotalsSummaryRow: FC<PropsWithChildren<TotalsSummaryRowProps>> = ({
  children,
  hasBorder = true,
  ...rest
}) => (
  <Box className={classNames('totals-summary-row', { 'no-border': !hasBorder })} {...rest}>
    {children}
  </Box>
);
