import { QuoteProductItem } from 'libs/api/quotes/types';

export type TableCellCommonProps = {
  item: QuoteProductItem;
  readonly: boolean;
  loading: boolean;
};

export const inputProps = {
  sx: {
    marginTop: '9px',
    textAlign: 'right',
  },
};
