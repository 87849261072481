import React, { ComponentProps } from 'react';
import { Company } from 'libs/api/iam/types';
import { DiscountsDialog } from './components/discountsDialog/DiscountsDialog';
import { DiscountsInfoList } from './components';
import { Grid, Skeleton, Typography } from '@clippings/paper';
import { InfoBox } from 'libs/shared';
import { useCategoryQuery, useDialog, useDiscountsInfoList } from './hooks';
import { useGetCompanyDiscounts } from 'libs/api/iam/hooks';
import { useListCatalogues } from 'libs/api/catalogue/hooks';
import { useTranslation } from 'react-i18next';

export interface DiscountsInfoProps {
  company: Company;
  isEditable?: boolean;
  boxProps?: ComponentProps<typeof Grid>;
}

export const DiscountsInfo: React.FC<DiscountsInfoProps> = ({
  company,
  isEditable = true,
  boxProps,
}) => {
  const { t } = useTranslation();
  const { isOpen, openDialog, closeDialog } = useDialog();

  const {
    data: discounts,
    isLoading: discountsLoading,
    isSuccess: discountsSuccess,
  } = useGetCompanyDiscounts(company.id);
  const { data: catalogues, isLoading: cataloguesLoading } = useListCatalogues();
  const { categories } = useCategoryQuery();
  const { discountsListViewModel } = useDiscountsInfoList(
    discounts ?? [],
    categories,
    catalogues ?? []
  );

  if (discountsLoading || cataloguesLoading) {
    return <Skeleton height="200px" />;
  }

  return (
    <InfoBox
      title={t('common.discounts')}
      editButtonProps={
        isEditable
          ? {
              onClick: openDialog,
              'aria-label': 'edit-discounts',
            }
          : undefined
      }
      {...boxProps}
    >
      {discountsSuccess && discounts.length > 0 ? (
        <DiscountsInfoList discountsListViewModel={discountsListViewModel} />
      ) : (
        <Typography variant="body2">{t('companies.noDiscountsConfigured')}</Typography>
      )}

      <DiscountsDialog
        company={company}
        discounts={discounts ?? []}
        open={isOpen}
        categories={categories}
        onClose={closeDialog}
      />
    </InfoBox>
  );
};
