import React, { ComponentProps, useMemo } from 'react';
import { MenuItem, TextField } from '@clippings/paper';
import { getAvailableLocalesOptions, useUserLocale } from 'libs/Utils/locales';
import { useAppConfiguration } from 'libs/providers';

type LanguageSelectProps = {
  onLanguageSelected: (value: string) => void;
} & ComponentProps<typeof TextField>;

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ onLanguageSelected, ...rest }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onLanguageSelected(event.target.value);
  };
  const { supportedLocales } = useAppConfiguration();
  const { userLocale } = useUserLocale();
  const availableLocalesOptions = useMemo(() => getAvailableLocalesOptions(supportedLocales), []);

  return (
    <TextField
      data-testid="language-select"
      color="secondary"
      variant="outlined"
      select
      size="small"
      defaultValue={userLocale}
      onChange={handleChange}
      {...rest}
    >
      {availableLocalesOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
