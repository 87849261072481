import {
  BillingAddress,
  CompanyDiscount,
  InfinitePaginatedQueryOptions,
  InfiniteQueryOptions,
  MutationOptions,
  PaymentRule,
  QueryOptions,
  ReplaceDiscountsVariables,
  ShippingAddress,
} from 'libs/api/common/types';
import {
  Company,
  CreateBillingAddressVariables,
  CreateCompanyVariables,
  CreateShippingAddressVariables,
  CreateTeamVariables,
  CreateUserVariables,
  DeleteShippingAddressVariables,
  ListCompaniesParams,
  ListTeamsParams,
  ListUsersParams,
  Team,
  TeamUpdateVariables,
  TeamV2,
  UpdateBillingAddressVariables,
  UpdateCompanyVariables,
  UpdatePaymentTermsVariables,
  UpdateShippingAddressVariables,
  UpdateUserVariables,
  User,
} from './types';
import { compareDiscounts } from './utils/compareDiscounts';
import {
  createCompany,
  createCompanyShippingAddress,
  createTeam,
  createUser,
  deleteCompanyShippingAddress,
  getCompany,
  getCompanyDiscounts,
  getCompanyPaymentTerms,
  getCurrentUser,
  getTeam,
  getUser,
  listCompanies,
  listCompanyBillingAddresses,
  listCompanyShippingAddresses,
  listPdfTemplates,
  listTeams,
  listTeamsV2,
  listUsers,
  listUsersV2,
  replaceCompanyDiscounts,
  resetPassword,
  updateCompany,
  updateCompanyBillingAddress,
  updateCompanyPaymentTerms,
  updateCompanyShippingAddress,
  updateTeam,
  updateUser,
} from './actions';
import { createCompanyBillingAddress } from 'libs/Quotes/quoteActions';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const IAM_QUERY_KEYS = {
  currentUser: 'currentUser',
  infiniteTeamsV2: 'infinite-teamsV2',
  teams: 'teams',
  companies: 'companies',
  infiniteCompanies: 'infinite-companies',
  infiniteUsers: 'infinite-users',
  users: 'users',
  team: 'team',
  pdfTemplates: 'pdf-templates',
};

export const useGetCompany = (companyId: number, options?: QueryOptions<Company>) =>
  useQuery([IAM_QUERY_KEYS.companies, companyId], () => getCompany(companyId), {
    ...options,
  });

export const useCreateCompany = (options?: MutationOptions<Company, CreateCompanyVariables>) => {
  const queryClient = useQueryClient();

  return useMutation(company => createCompany(company), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([IAM_QUERY_KEYS.companies]);
      queryClient.invalidateQueries([IAM_QUERY_KEYS.infiniteCompanies]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useUpdateCompany = (options?: MutationOptions<Company, UpdateCompanyVariables>) => {
  const queryClient = useQueryClient();

  return useMutation(({ companyId, company }) => updateCompany(companyId, company), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([IAM_QUERY_KEYS.companies, variables.companyId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useListCompanyShippingAddresses = (
  companyId: number,
  options?: QueryOptions<ShippingAddress[]>
) =>
  useQuery(
    ['companies/shipping-addresses', companyId],
    () => listCompanyShippingAddresses(companyId),
    {
      ...options,
    }
  );

export const useListCompanyBillingAddresses = (
  companyId: number,
  options?: QueryOptions<BillingAddress[]>
) =>
  useQuery(
    ['companies/billing-addresses', companyId],
    () => listCompanyBillingAddresses(companyId),
    {
      ...options,
    }
  );

export const useCreateCompanyShippingAddress = (
  options?: MutationOptions<ShippingAddress, CreateShippingAddressVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation(({ companyId, address }) => createCompanyShippingAddress(companyId, address), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['companies/shipping-addresses', variables.companyId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useUpdateCompanyShippingAddress = (
  options?: MutationOptions<ShippingAddress, UpdateShippingAddressVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ companyId, addressId, address }) =>
      updateCompanyShippingAddress(companyId, addressId, address),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['companies/shipping-addresses', variables.companyId]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useCreateCompanyBillingAddress = (
  options?: MutationOptions<BillingAddress, CreateBillingAddressVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation(({ companyId, address }) => createCompanyBillingAddress(companyId, address), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['companies/billing-addresses', variables.companyId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useUpdateCompanyBillingAddress = (
  options?: MutationOptions<BillingAddress, UpdateBillingAddressVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ companyId, addressId, address }) =>
      updateCompanyBillingAddress(companyId, addressId, address),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['companies/billing-addresses', variables.companyId]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useDeleteCompanyShippingAddress = (
  options?: MutationOptions<void, DeleteShippingAddressVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ companyId, addressId }) => deleteCompanyShippingAddress(companyId, addressId),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['companies/shipping-addresses', variables.companyId]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useGetCompanyDiscounts = (
  companyId: number,
  options?: QueryOptions<CompanyDiscount[]>
) =>
  useQuery(['companies/discounts', companyId], () => getCompanyDiscounts(companyId), {
    select: data => [...data].sort(compareDiscounts),
    ...options,
  });

export const useReplaceCompanyDiscounts = (
  options?: MutationOptions<CompanyDiscount[], ReplaceDiscountsVariables>
) => {
  const queryClient = useQueryClient();

  return useMutation(({ companyId, discounts }) => replaceCompanyDiscounts(companyId, discounts), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['companies/discounts', variables.companyId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useGetCompanyPaymentTerms = (
  companyId: number,
  options?: QueryOptions<PaymentRule[]>
) =>
  useQuery(['companies/payment-terms', companyId], () => getCompanyPaymentTerms(companyId), {
    ...options,
  });

export const useUpdateCompanyPaymentTerms = (
  options?: MutationOptions<PaymentRule[], UpdatePaymentTermsVariables>
) => {
  const queries = useQueryClient();

  return useMutation(
    ({ companyId, paymentTerms }) => updateCompanyPaymentTerms(companyId, paymentTerms),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queries.invalidateQueries(['companies/payment-terms', variables.companyId]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useGetUser = (userId: number, options?: QueryOptions<User>) =>
  useQuery([IAM_QUERY_KEYS.users, userId], () => getUser(userId), {
    ...options,
  });

export const useCreateUser = (options?: MutationOptions<User, CreateUserVariables>) =>
  useMutation(user => createUser(user), options);

export const useUpdateUser = (options?: MutationOptions<User, UpdateUserVariables>) =>
  useMutation(user => updateUser(user.id, user), options);

export const useListUsers = (params?: ListUsersParams, options?: QueryOptions<User[]>) =>
  useQuery([IAM_QUERY_KEYS.users, params], () => listUsers(params), {
    ...options,
  });

export const useListInfiniteUsers = (
  params?: ListUsersParams,
  options?: InfiniteQueryOptions<User[]>
) =>
  useInfiniteQuery(
    [IAM_QUERY_KEYS.infiniteUsers, params],
    ({ pageParam }) => listUsers({ ...params, page: pageParam ?? 1 }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => (lastPage.length > 0 ? allPages.length + 1 : false),
    }
  );

export const useListInfiniteUsersV2 = (
  params?: ListUsersParams,
  options?: InfinitePaginatedQueryOptions<User>
) =>
  useInfiniteQuery(
    [IAM_QUERY_KEYS.infiniteUsers, params],
    ({ pageParam }) => listUsersV2({ ...params, page: pageParam ?? 1 }),
    {
      ...options,
      select: data => {
        return {
          pageParams: data.pageParams,
          pages: [...data.pages].map(page => page.items),
          // @ts-ignore
          totalItems: data.pages?.[0]?.totalItems,
        };
      },
      getNextPageParam: ({ currentPage, totalPages }) =>
        currentPage < totalPages ? currentPage + 1 : false,
    }
  );

export const useListInfiniteCompanies = (
  params?: ListCompaniesParams,
  options?: InfiniteQueryOptions<Company[]>
) =>
  useInfiniteQuery(
    [IAM_QUERY_KEYS.infiniteCompanies, params],
    ({ pageParam }) => listCompanies({ ...params, page: pageParam ?? 1 }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => (lastPage.length > 0 ? allPages.length + 1 : false),
    }
  );

export const useResetPassword = (options?: MutationOptions<any, string>) =>
  useMutation((mail: string) => resetPassword(mail), {
    ...options,
  });

export const useCurrentUser = (options?: QueryOptions<User>) =>
  useQuery([IAM_QUERY_KEYS.currentUser], getCurrentUser, {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

export const useListTeams = (params?: ListTeamsParams, options?: QueryOptions<Team[]>) =>
  useQuery([IAM_QUERY_KEYS.teams, params], () => listTeams(params), {
    ...options,
  });

export const useListInfiniteTeamsV2 = (
  params?: ListTeamsParams,
  options?: InfinitePaginatedQueryOptions<TeamV2>
) =>
  useInfiniteQuery(
    [IAM_QUERY_KEYS.infiniteTeamsV2, params],
    ({ pageParam }) => listTeamsV2({ ...params, page: pageParam ?? 1 }),
    {
      ...options,
      select: data => ({
        pageParams: data.pageParams,
        pages: [...data.pages].map(page => page.items),
      }),
      getNextPageParam: ({ currentPage, totalPages }) =>
        currentPage < totalPages ? currentPage + 1 : false,
    }
  );

export const useGetTeam = (teamId: number, options?: QueryOptions<Team>) =>
  useQuery([IAM_QUERY_KEYS.team, teamId], () => getTeam(teamId), {
    ...options,
  });

export const useCreateTeam = (options?: MutationOptions<Team, CreateTeamVariables>) =>
  useMutation(team => createTeam(team), options);

export const useUpdateTeam = (options?: MutationOptions<Team, TeamUpdateVariables>) =>
  useMutation(team => updateTeam(team), options);

export const useListPdfTemplates = () =>
  useQuery([IAM_QUERY_KEYS.pdfTemplates], () => listPdfTemplates());
