import React from 'react';
import { Box, Brand, Button, CloseableDialog, LoadingButton } from '@clippings/paper';
import { PaymentRule } from 'libs/api/common/types';
import { PaymentRulesType, PaymentRulesTypes } from './paymentTerms.types';
import { PaymentTermsSummary } from './components';
import { PaymentTermsTable } from 'libs/SalesApp/Quotes/paymentTerms/components/paymentTermsTable/PaymentTermsTable';
import { Quote } from 'libs/api/quotes/types';
import { isFrozenStatus } from '../quoteUtils';
import { useAppConfiguration } from 'libs/providers';
import { usePaymentTerms } from './hooks/usePaymentTerms';
import { useTranslation } from 'react-i18next';

interface PaymentTermsDialogProps {
  quote: Quote;
  originalPaymentTerms: PaymentRule[];
  open: boolean;
  onClose: () => void;
}

export const PaymentTermsDialog: React.FC<PaymentTermsDialogProps> = ({
  open,
  quote,
  originalPaymentTerms,
  onClose,
}) => {
  const { instance } = useAppConfiguration();
  const { t } = useTranslation();
  const {
    handleAddNewPayment,
    handleApply,
    handleChange,
    paymentTerms,
    setPaymentTerms,
    isLoading,
  } = usePaymentTerms(originalPaymentTerms, quote, onClose, open);

  const paymentTermsTypes =
    instance !== Brand.Sossego
      ? defaultPaymentTermsTypes
      : [...defaultPaymentTermsTypes, datePaymentTermType];
  const totalPercentage = paymentTerms.reduce((sum, x) => sum + (x.percentage || 0), 0);

  const leftDialogActionLabel = isFrozenStatus(quote.status)
    ? 'common.discardChanges'
    : 'common.resetToDefault';

  return (
    <CloseableDialog
      open={open}
      onClose={onClose}
      title={t('paymentTerms.paymentTerms')}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        'data-testid': 'quote-payment-terms-dialog',
      }}
      leftDialogActions={
        <Button
          color="secondary"
          disabled={paymentTerms === originalPaymentTerms}
          onClick={() => setPaymentTerms(originalPaymentTerms)}
          data-testid="quote-payment-terms-reset-button"
        >
          {t(leftDialogActionLabel)}
        </Button>
      }
      dialogActions={
        <LoadingButton
          loading={isLoading}
          variant="contained"
          disabled={totalPercentage !== 100}
          onClick={handleApply}
          data-testid="quote-payment-terms-apply-button"
        >
          {t('common.apply')}
        </LoadingButton>
      }
    >
      <Box pr={3}>
        <PaymentTermsTable
          paymentTerms={paymentTerms}
          paymentTermsTypes={paymentTermsTypes}
          onChange={handleChange}
          onAdd={handleAddNewPayment}
        />
        <PaymentTermsSummary
          totalAmount={quote.total.amount}
          totalPercentage={totalPercentage}
          currency={quote.total.currency}
        />
      </Box>
    </CloseableDialog>
  );
};

const defaultPaymentTermsTypes: PaymentRulesTypes = [
  { value: 'custom', translationKey: 'paymentTerms.custom' },
];

const datePaymentTermType: PaymentRulesType = {
  value: 'date',
  translationKey: 'paymentTerms.onDate',
};
