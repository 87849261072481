import React from 'react';
import { AddProductCell } from '../../../../SalesApp/Quotes/components/AddProductCell';
import { Box, TableCell, TableRow } from '@clippings/paper';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { ConfiguredProduct } from 'libs/SalesApp/Quotes/common/types';
import { fallbackCoverUrl } from 'libs/shared';
import { useAppConfiguration, useSearchProvider } from 'libs/providers';

type AddProductRowProps = {
  catalogue: string;
  isLoading?: boolean;
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  handleOpenPlp: () => void;
};

export function AddProductRow({
  isLoading,
  catalogue,
  handleOpenPlp,
  onConfigure,
}: AddProductRowProps) {
  const { excludedCategories } = useAppConfiguration();
  const { searchClient, productIndexes } = useSearchProvider();
  const excludeFilters = getExcludeFilters(excludedCategories);

  return (
    <TableRow className="quote-item-row" data-testid="table-row-add" sx={styles.tableRowSx}>
      <TableCell key={'new-quote-item-cell-order'} sx={styles.tableCellSx} />
      <TableCell>
        <Box display="flex" justifyContent="center" mt="4px">
          <img src={fallbackCoverUrl} alt="new-product-cover" height={40} width={40} />
        </Box>
      </TableCell>
      <InstantSearch searchClient={searchClient} indexName={productIndexes.mostRelevant}>
        <Configure hitsPerPage={4} filters={`catalogues:${catalogue} ${excludeFilters}`} />
        <AddProductCell
          catalogue={catalogue}
          onConfigure={onConfigure}
          onOpenPLP={handleOpenPlp}
          canAddCustom={false}
          isLoading={isLoading}
        />
      </InstantSearch>
    </TableRow>
  );
}

const styles = {
  tableRowSx: { borderBottom: '1px solid rgba(224, 224, 224, 1)' },
  tableCellSx: {
    width: '60px',
    textAlign: 'center',
  },
};

const getExcludeFilters = (excludedCategories: string[]) => {
  let excludeFilters = excludedCategories
    .map(category => `NOT category:"${category}"`)
    .join(' AND ');

  if (excludeFilters !== '') {
    excludeFilters = `AND ${excludeFilters}`;
  }

  return excludeFilters;
};
