import React from 'react';
import { ApplicableDeliveryMethod } from 'libs/api/quotes/types';
import {
  Box,
  Monetary,
  Typography,
  convertDashToUnderscore,
  convertTimeRange,
  useFormatLeadTime,
} from '@clippings/paper';
import { Translatable } from 'libs/api/common/types';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';
import { useUserLocale } from 'libs/Utils/locales';

type DeliveryMethodsListItemType = {
  deliveryMethod: ApplicableDeliveryMethod;
};

export const DeliveryMethodsListItem: React.FC<DeliveryMethodsListItemType> = ({
  deliveryMethod,
}) => {
  const { leadTimeFormat } = useAppConfiguration();
  const formatLeadTime = useFormatLeadTime();
  const { t } = useTranslation();
  const { userLocale } = useUserLocale();
  const currentUserLocale = convertDashToUnderscore(userLocale);

  const renderPrice = () => {
    if (deliveryMethod.price.amount) {
      return (
        <Monetary
          variant="body1"
          currency={deliveryMethod.price.currency}
          amount={deliveryMethod.price.amount}
        />
      );
    }

    if (deliveryMethod.type === 'manual') {
      return <Typography variant="body1">{t('common.requestQuote')}</Typography>;
    }

    if (deliveryMethod.price.amount === 0) {
      return <Typography variant="body1">{t('common.free')}</Typography>;
    }
  };
  const getTranslatable = (translatables: Translatable[]) => {
    const translatable =
      translatables.find(v => v.locale === currentUserLocale) || translatables[0];

    return translatable ? translatable.text : '';
  };

  return (
    <Box display="flex" flexDirection="column" gap={1} flexBasis="100%" p={1} width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1">{getTranslatable(deliveryMethod.names)}</Typography>
        {renderPrice()}
      </Box>
      <Typography variant="caption" color="secondary">
        {getTranslatable(deliveryMethod.descriptions)}
      </Typography>
      <Typography variant="body1">
        {deliveryMethod.type === 'automatic'
          ? formatLeadTime(
              convertTimeRange(deliveryMethod.shippingTime, 'days', leadTimeFormat),
              leadTimeFormat
            )
          : t('common.customDeliveryDates')}
      </Typography>
    </Box>
  );
};
