import React, { FC, useRef } from 'react';
import { Chat, ChatVisibilityProvider } from 'libs/Chat';
import { DeliveryTimelineSkeleton, PaymentInfoSummarySkeleton } from './Skeletons';
import { Divider, FlexColumn } from '@clippings/paper';
import { Nullable } from 'libs/Utils';
import { Order } from 'libs/api/order/types';
import { OrderDeliveryTimeline } from './OrderDeliveryTimeline';
import { OrderDetailsBreadcrumb } from './OrderDetailsBreadcrumb';
import { OrderHeader } from './OrderHeader';
import { OrderHelp } from './OrderHelp';
import { OrderItemsDetails } from './OrderItemsDetails';
import { OrderPaymentSchedule } from './OrderPaymentSchedule';
import { OrderSummary } from './OrderSummary';
import { PaymentInfoSummary } from 'libs/Quotes/Checkout/components/PaymentInfoSummary';
import { SCROLL_INTO_VIEW_OPTIONS } from 'libs/hooks/useResetScroll';
import { UseQueryResult } from '@tanstack/react-query';
import { getOrderIdLabel } from 'libs/Orders/utils';
import { pageViewContainerResponsiveStyles } from 'libs/shared';

interface OrderViewProps {
  number: string;
  orderQuery: UseQueryResult<Order>;
}

export const OrderView: FC<OrderViewProps> = ({ number, orderQuery }) => {
  const paymentScheduleRef = useRef<Nullable<HTMLDivElement>>(null);
  const viewPaymentScheduleClickHandler = () =>
    paymentScheduleRef.current?.scrollIntoView?.(SCROLL_INTO_VIEW_OPTIONS);

  const orderIdLabel = getOrderIdLabel(orderQuery.data?.vendorId ?? null);

  return (
    <FlexColumn
      height="100%"
      py={pageViewContainerResponsiveStyles.py}
      px={pageViewContainerResponsiveStyles.px}
      gap={2}
    >
      <OrderDetailsBreadcrumb
        orderNumber={number}
        isLoading={orderQuery.isFetching}
        orderIdLabel={orderIdLabel}
      />
      <Divider />
      <FlexColumn gap={4}>
        <ChatVisibilityProvider>
          <OrderHeader number={number} orderIdLabel={orderIdLabel} />
          {orderQuery.isSuccess ? (
            <PaymentInfoSummary
              hasPaymentAmount={orderQuery.data.total.amount > 0}
              onViewPaymentScheduleClick={viewPaymentScheduleClickHandler}
              item={orderQuery.data}
              payments={orderQuery.data.paymentSchedule}
              poNumber={orderQuery.data.poNumber ?? undefined}
            />
          ) : (
            <PaymentInfoSummarySkeleton />
          )}
          {orderQuery.isSuccess ? (
            <OrderDeliveryTimeline order={orderQuery.data} />
          ) : (
            <DeliveryTimelineSkeleton />
          )}
          <OrderItemsDetails number={number} />
          <OrderSummary number={number} />
          <OrderPaymentSchedule ref={paymentScheduleRef} number={number} />
          {orderQuery.data?.salesRepresentative && (
            <OrderHelp salesRep={orderQuery.data.salesRepresentative} />
          )}
          <Chat />
        </ChatVisibilityProvider>
      </FlexColumn>
    </FlexColumn>
  );
};
