import React, { StrictMode } from 'react';
import { AccessDenied } from 'libs/Components/Errors/AccessDenied';
import { CompaniesListPage, CompanyDetailsPage } from 'libs/SalesApp/Companies';
import { CustomerUserDetailsPage, CustomerUsersPage, StaffUsersPage } from 'libs/SalesApp/Users';
import { DefaultLayout } from 'libs/DefaultLayout';
import { GenericError } from 'libs/Components/Errors/GenericError';
import { Header } from 'libs/SalesApp/Header/Header';
import { InboxApp } from 'libs/SalesApp/Inbox/InboxApp';
import { Navigate, Route } from 'react-router-dom';
import { Navigation } from 'libs/SalesApp/Navigation/Navigation';
import { OrderDetailsPage } from 'libs/Orders/OrderDetails/OrderDetailsPage';
import { OrderPaymentPage } from 'libs/Orders/OrderPayment/OrderPaymentPage';
import { OrdersPage } from 'libs/SalesApp/Orders/OrdersPage';
import { PermissionBasedAccess } from 'libs/Components/PermissionBasedAccess/PermissionBasedAccess';
import { QuoteCheckoutPage } from 'libs/Quotes/Checkout';
import { QuoteDetailsPage } from 'libs/SalesApp/Quotes/QuoteDetailsPage';
import { Quotes } from 'libs/SalesApp/Quotes/Quotes';
import { QuotesPage } from 'libs/SalesApp/Quotes/types';
import { SentryRoutes } from 'libs/Components/Errors/Sentry/SentryRoutes';
import { TeamsTable } from 'libs/SalesApp/Teams/components/TeamsTable/TeamsTable';
import { UserPermission } from 'libs/api/iam/types';
import { authenticationRoutes } from 'libs/Authentication';
import { routes } from 'libs/Constants';

const layoutContentProps = {
  px: {
    xs: 2,
    md: 2,
    xl: 5,
  },
};

export const SalesRouter = () => (
  <StrictMode>
    <SentryRoutes>
      {authenticationRoutes}
      <Route
        path={routes.home}
        element={
          <DefaultLayout
            contentProps={layoutContentProps}
            header={<Header />}
            navigation={<Navigation />}
            hideSideNav
          />
        }
      >
        <Route path={routes.accessDenied} element={<AccessDenied to={routes.salesApp.index} />} />
        <Route path={routes.genericError} element={<GenericError to={routes.salesApp.index} />} />
      </Route>
      <Route
        path={routes.salesApp.index}
        element={
          <DefaultLayout
            header={<Header />}
            contentProps={layoutContentProps}
            navigation={<Navigation />}
          />
        }
      >
        <Route index element={<Navigate to={routes.salesApp.inbox} replace={true} />} />
        <Route path={routes.salesApp.quotes.active} element={<Quotes page={QuotesPage.Active} />} />
        <Route
          path={routes.salesApp.quotes.expired}
          element={<Quotes page={QuotesPage.Expired} />}
        />
        <Route
          path={routes.salesApp.quotes.archived}
          element={<Quotes page={QuotesPage.Archived} />}
        />
        <Route path={routes.salesApp.inbox} element={<InboxApp />} />
        <Route path={routes.salesApp.quotes.show} element={<QuoteDetailsPage />} />
        <Route path={routes.salesApp.quotes.checkout} element={<QuoteCheckoutPage />} />

        <Route path={routes.salesApp.users} element={<CustomerUsersPage />} />
        <Route path={routes.salesApp.userDetails} element={<CustomerUserDetailsPage />} />
        <Route
          path={routes.salesApp.staffUsers}
          element={
            <PermissionBasedAccess
              element={<StaffUsersPage />}
              redirect={routes.accessDenied}
              permission={UserPermission.CanAccessStaffUsersManagement}
            />
          }
        />
        <Route path={routes.salesApp.companies} element={<CompaniesListPage />} />
        <Route path={routes.salesApp.teams} element={<TeamsTable />} />
        <Route path={routes.salesApp.company} element={<CompanyDetailsPage />} />
        <Route path={routes.salesApp.orders.list} element={<OrdersPage />} />
        <Route path={routes.salesApp.orders.show} element={<OrderDetailsPage />} />
        <Route path={routes.salesApp.orders.pay} element={<OrderPaymentPage />} />
      </Route>
    </SentryRoutes>
  </StrictMode>
);
