import React from 'react';
import { Box } from '@clippings/paper';
import { QuoteInfoLabel } from './QuoteInfoLabel';

export const MorosoBankDetails = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" gap={1} data-testid="moroso-bank-details">
        <QuoteInfoLabel bold text="Bank details" />
        <QuoteInfoLabel text="COMPANY NAME: MOROSO SPA" />
        <QuoteInfoLabel text="PLEASE T.T. TO: BNL - BANCA NAZIONALE DEL LAVORO" />
        <QuoteInfoLabel text="VIA MERCATOVECCHIO 17/19" />
        <QuoteInfoLabel text="33100 UDINE - ITALY" />
        <QuoteInfoLabel text="EURO ACCOUNT NUMBER: 6277" />
        <QuoteInfoLabel text="ABI:01005 CAB:12300" />
        <QuoteInfoLabel text="SWIFT CODE-:BNLIITRRUDX" />
        <QuoteInfoLabel text="IBAN CODE: IT64T0100512300000000006277" />
      </Box>
    </>
  );
};
