import React, { ComponentProps, FC } from 'react';
import { Box, Button, Monetary, Typography } from '@clippings/paper';
import { DiscountRowItem } from './DiscountsRow';
import { Order } from 'libs/api/order/types';
import { Quote, QuoteListItemStatus } from 'libs/api/quotes/types';
import { Row } from 'libs/shared';
import { TotalsSummaryRow } from './TotalsSummaryRow';
import { TotalsSummaryRowLabel } from './TotalsSummaryRowLabel';
import { TotalsSummaryRowValue } from './TotalsSummaryRowValue';
import { useTranslation } from 'react-i18next';

export type SummaryViewItem = {
  discountItems: DiscountRowItem[];
  expiresAt?: string | null;
  status: Quote['status'] | Order['status'];
} & Pick<
  Quote,
  | 'shippingAddress'
  | 'tax'
  | 'subtotal'
  | 'total'
  | 'deliveryItems'
  | 'metadata'
  | 'contractItemsSubTotal'
>;

interface TotalsSummaryShippingRowProps extends ComponentProps<typeof TotalsSummaryRow> {
  item: SummaryViewItem;
  onClick?: () => void;
  disabled?: boolean;
  hideActions?: boolean;
}

export const TotalsSummaryShippingRow: FC<TotalsSummaryShippingRowProps> = ({
  item,
  onClick,
  disabled = false,
  hideActions = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { deliveryItems } = item;

  const hasDeliveryItems = deliveryItems.length > 0;
  const hasCustomDelivery =
    deliveryItems.length > 1 || deliveryItems.some(deliveryItem => deliveryItem.custom);
  const shouldRenderActions = !hideActions && onClick && !hasCustomDelivery;

  const shouldHideDeliveryPrice =
    deliveryItems[0]?.type === 'manual' &&
    deliveryItems[0]?.netPrice.amount === 0 &&
    [QuoteListItemStatus.Draft, QuoteListItemStatus.Requested].includes(item.status as any);

  return (
    <TotalsSummaryRow {...rest}>
      {!hasCustomDelivery ? (
        <Row justifyContent="space-between" alignItems="baseline" gap={1} width="100%">
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <TotalsSummaryRowLabel data-testid="summary-delivery-item-name">
              {t('quotes.addresses.shippingTo')}{' '}
              <strong style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {item?.shippingAddress?.nickname ?? ' - '} ({deliveryItems[0]?.name ?? ' - '})
              </strong>
            </TotalsSummaryRowLabel>
            {/* There's always only one item in the array if all delivery items are not custom */}
            {shouldRenderActions ? (
              <Button
                variant="text"
                onClick={onClick}
                data-testid="change-shipping-address"
                disabled={disabled}
                sx={{ m: 0, p: 0, minWidth: 'auto' }}
              >
                {t('common.change')}
              </Button>
            ) : null}
          </Box>

          {hasDeliveryItems ? (
            <TotalsSummaryRowValue>
              {shouldHideDeliveryPrice ? (
                <Typography
                  data-testid="summary-delivery-item-value-amount"
                  fontWeight={600}
                  fontSize={theme => theme.typography.pxToRem(16)}
                  // eslint-disable-next-line react/jsx-no-literals
                >
                  n/a
                </Typography>
              ) : (
                <Monetary
                  data-testid="summary-delivery-item-value-amount"
                  fontWeight={600}
                  fontSize={theme => theme.typography.pxToRem(16)}
                  amount={deliveryItems[0].netPrice.amount}
                  currency={deliveryItems[0].netPrice.currency}
                />
              )}
            </TotalsSummaryRowValue>
          ) : null}
        </Row>
      ) : (
        <Box display="flex" flexDirection="column" width="100%">
          <Row justifyContent="space-between" width="100%">
            <TotalsSummaryRowLabel data-testid="summary-delivery-item-label">
              {t('common.shipping')}
            </TotalsSummaryRowLabel>
          </Row>
          {deliveryItems.map(deliveryItem => (
            <Row
              key={deliveryItem.id}
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              gap={1}
            >
              <TotalsSummaryRowLabel
                data-testid={`summary-delivery-item-name-${deliveryItem.id}`}
                typographyProps={{ fontSize: '0.75rem', fontWeight: 400 }}
              >
                {deliveryItem?.name ?? ' - '}
              </TotalsSummaryRowLabel>
              <TotalsSummaryRowValue>
                <Monetary
                  data-testid="summary-delivery-item-value-amount"
                  fontWeight={600}
                  fontSize={theme => theme.typography.pxToRem(16)}
                  amount={deliveryItem.netPrice.amount}
                  currency={deliveryItem.netPrice.currency}
                />
              </TotalsSummaryRowValue>
            </Row>
          ))}
        </Box>
      )}
    </TotalsSummaryRow>
  );
};
