import React from 'react';
import {
  DetailedUserAvatar,
  DetailedUserAvatarProps,
  User,
  getUserFullName,
} from '@clippings/paper';
import { FC } from 'react';
import { ShareeSelectOption } from '../useQuoteShareUtils';

export const getShareeAvatarProps = (user: ShareeSelectOption) => {
  const { email } = user;
  const fullUserName = getUserFullName(user);
  const isEmailTitle = !fullUserName;

  const title = isEmailTitle ? email : fullUserName;
  const caption = isEmailTitle ? '' : email;

  const modifiedUser: User = {
    ...user,
    firstName: isEmailTitle ? email : user.firstName, // Set first name to email for credentials to be shown in the avatar
  };

  return { user: modifiedUser, title, caption };
};

export const ShareeDetailedUserAvatar: FC<
  Omit<DetailedUserAvatarProps, 'title' | 'caption'>
> = props => {
  const { user, title, caption } = getShareeAvatarProps(props.user);
  return (
    <DetailedUserAvatar
      titleProps={{
        sx: {
          fontSize: theme => theme.typography.pxToRem(14),
          fontWeight: '500',
        },
      }}
      {...props}
      user={user}
      title={title}
      caption={caption}
    />
  );
};
