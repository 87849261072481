import React from 'react';
import { CloseableDialog, DialogAction, SystemUser } from '@clippings/paper';
import { User } from 'libs/api/iam/types';
import {
  UserDialogFormProps,
  getUserFormValues,
  userDialogSettingsMap,
} from '../../utils/user.utils';
import { UserDialogTitle } from './UserDialogTitle';

export type UserDialogProps = Omit<UserDialogFormProps, 'onCancel' | 'isEditMode'> & {
  isOpen: boolean;
  onClose: () => void;
  user?: User;
  type: SystemUser;
  action: DialogAction;
};

export const UserDialog: React.FC<UserDialogProps> = ({
  isOpen,
  onClose,
  action,
  onCreateSuccess,
  onUpdateSuccess,
  type,
  user,
  defaultValues,
  userId,
}) => {
  const isEditMode = action === DialogAction.Edit;

  // Form component
  const userDialogFormProps = {
    defaultValues: {
      ...getUserFormValues(type, user),
      ...defaultValues,
    },
    userId: user?.id ?? userId,
    action,
    onCreateSuccess,
    onUpdateSuccess,
    onCancel: onClose,
  };

  const UserForm = userDialogSettingsMap[type].form;

  return (
    <CloseableDialog
      open={isOpen}
      onClose={onClose}
      title={<UserDialogTitle isEditMode={isEditMode} userType={type} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
    >
      <UserForm {...userDialogFormProps} />
    </CloseableDialog>
  );
};
