import { ACTION_AFTER_SUBMIT } from 'libs/Quotes/hooks/quoteReducer';
import { QuoteTransitionError, QuoteTransitionErrorsEnum } from 'libs/SalesApp/Quotes/types';
import { useQuoteProvider } from 'libs/Quotes/providers';

export const useRequestQuote = () => {
  const { handleBillingModalOpen, handleShippingModalOpen, handleRequestQuoteApproval } =
    useQuoteProvider();

  return async (errors: QuoteTransitionError[] = []) => {
    if (errors.some(err => err.error === QuoteTransitionErrorsEnum.MissingBillingAddress)) {
      handleBillingModalOpen(ACTION_AFTER_SUBMIT.REQUEST);
      return;
    }

    if (errors.some(err => err.error === QuoteTransitionErrorsEnum.MissingShippingAddress)) {
      handleShippingModalOpen(false, ACTION_AFTER_SUBMIT.REQUEST);
      return;
    }

    handleRequestQuoteApproval();
  };
};
