import React from 'react';
import { AddProductCell } from './components/AddProductCell';
import { Box, TableCell, TableRow } from '@clippings/paper';
import { ConfiguredProduct } from './common/types';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { LineItemVisibleFields } from 'libs/api/configuration/types';
import { Quote } from '../../api/quotes/types';
import { fallbackCoverUrl } from 'libs/shared';
import { times } from 'lodash';
import { useSearchProvider } from 'libs/providers';

interface NewProductRowProps {
  catalogue: Quote['catalogue'];
  isLoading?: boolean;
  lineItemVisibleFields: LineItemVisibleFields;
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  onOpenPLP: () => void;
}

export const NewProductRow: React.FC<NewProductRowProps> = ({
  catalogue,
  isLoading = false,
  lineItemVisibleFields,
  onConfigure,
  onOpenPLP,
}) => {
  const { searchClient, productIndexes } = useSearchProvider();
  const infoColumnExtraCell = lineItemVisibleFields.includes('info') ? 1 : 0;
  const extraCellNumber = lineItemVisibleFields.length - 3 + infoColumnExtraCell;

  return (
    <TableRow sx={{ verticalAlign: 'top' }} data-testid="quote-new-product-row">
      <TableCell />
      <TableCell sx={{ width: '48px', p: 2, textAlign: 'center' }}>
        <Box display="flex" justifyContent="center" mt="4px">
          <img src={fallbackCoverUrl} alt="new-product-cover" height={40} width={40} />
        </Box>
      </TableCell>
      <InstantSearch searchClient={searchClient} indexName={productIndexes.mostRelevant}>
        <AddProductCell
          onConfigure={onConfigure}
          onOpenPLP={onOpenPLP}
          isLoading={isLoading}
          catalogue={catalogue}
        />
      </InstantSearch>
      {times(extraCellNumber).map(v => (
        <TableCell key={v} />
      ))}
    </TableRow>
  );
};
