import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { FilterStrip } from './components';
import { QuoteSnackbar, QuoteSnackbarProps } from '../components/QuoteSnackbar';
import { QuotesPage, QuotesQueryParams } from '../types';
import { QuotesTable } from './QuotesTable';
import { QuotesTableProvider } from './providers';
import { parseSortQueryParams } from './quotesParamUtils';
import { useQuoteTableMutations, useTableFilters } from './hooks';
import { useSalesQuotes } from '../hooks/useSalesQuotes';
import { useSearch } from 'libs/SalesApp/Header/useSearch';
import { useStaffSalesReps } from '../hooks/useStaffSalesReps';
import { useTableSorting } from 'libs/shared';

export interface QuotesTableContainerProps {
  page: QuotesPage;
}

export const QuotesTableContainer: FC<QuotesTableContainerProps> = ({ page }) => {
  const staffMembers = useStaffSalesReps();
  const currentPage = useRef<QuotesPage>(page);

  const [snackbarDetails, setSnackbarDetails] = useState<QuoteSnackbarProps['details']>();
  const { loadingQuotes, setLoadingQuotes, onArchive, onDuplicate, onAssigneeChange, onRestore } =
    useQuoteTableMutations(page, setSnackbarDetails);
  const { filters, queryParams, setQueryParams, clearFilters, updateFilters } =
    useTableFilters(page);
  const { sort, updateSorting } = useTableSorting<QuotesQueryParams>(
    parseSortQueryParams(queryParams),
    setQueryParams
  );
  const [searchValue] = useSearch();
  const { isFetchingNextPage, isLoading, hasNextPage, data, fetchNextPage } = useSalesQuotes({
    sort,
    filters,
    page,
    query: searchValue,
    options: {
      onSuccess: () => {
        setLoadingQuotes({ top: [], inline: [] });
      },
    },
  });

  useLayoutEffect(() => {
    // reset filters on page change
    if (page !== currentPage.current) {
      currentPage.current = page;
      clearFilters();
    }
  }, [page]);

  const onAlertClose = () => {
    setSnackbarDetails(undefined);
  };

  const quotes = data ? data.pages.flatMap(page => page.items) : [];

  return (
    <>
      <QuoteSnackbar
        onClose={onAlertClose}
        details={snackbarDetails}
        open={Boolean(snackbarDetails)}
      />
      <QuotesTableProvider
        filters={filters}
        sort={sort}
        clearFilters={clearFilters}
        updateFilters={updateFilters}
        updateSorting={updateSorting}
      >
        <FilterStrip
          page={page}
          staffMembers={staffMembers}
          filters={filters}
          setFilters={updateFilters}
        />
        <QuotesTable
          loadingQuotes={loadingQuotes}
          quotes={quotes}
          page={page}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          filters={filters}
          fetchNextPage={fetchNextPage}
          onClearFilters={clearFilters}
          handlers={{
            onArchive,
            onRestore,
            onDuplicate,
            onAssigneeChange,
          }}
        />
      </QuotesTableProvider>
    </>
  );
};
