import { AddSampleToQuotePayload } from 'libs/api/quotes/types';
import { addSampleToQuote } from 'libs/api/quotes/actions';
import { useMutation } from '@tanstack/react-query';

export function useAddSample(quoteVersionId: number) {
  const createSampleMutation = useMutation((payload: AddSampleToQuotePayload) =>
    addSampleToQuote(quoteVersionId, payload)
  );

  function createSampleHandler(
    data: AddSampleToQuotePayload,
    mutationOptions: Parameters<typeof createSampleMutation.mutate>[1]
  ) {
    createSampleMutation.mutate(data, mutationOptions);
  }

  return { createSampleHandler };
}
