import React, { FC } from 'react';
import {
  Accordion,
  AccordionSummary,
  ExpandMoreIcon,
  FlexColumn,
  FlexRow,
  Monetary,
  Typography,
} from '@clippings/paper';
import { Channel } from 'stream-chat';
import { Message, MessagesList } from 'libs/SalesApp/Inbox/Messages/MessageList';
import { useTranslation } from 'react-i18next';
type ContentRowProps = {
  number: string;
  total: { amount: number; currency: string };
  channel: Channel;
};

export const ContentRow: FC<ContentRowProps> = ({ number, total, channel }) => {
  const { t } = useTranslation();
  const messages = channel.state.messages.slice(-channel.state.unreadCount).reverse();
  const firstMessage = messages.shift();

  return (
    <FlexColumn gap="4px">
      <FlexRow alignItems="center" gap="4px" data-testid={`requested-quote-${number}`}>
        <Typography variant="body2" color="secondary">
          {t('common.quote')}
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          #{number}
        </Typography>
        <Typography variant="body2" color="secondary">
          {t('inbox.price')}
        </Typography>
        <Monetary amount={total.amount} currency={total.currency} fontWeight="bold" />
      </FlexRow>
      <FlexColumn gap="4px">
        <Message message={firstMessage} />
        {messages.length > 0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Typography variant="body2" color="secondary">
                {t('common.messages')}:&nbsp;
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {channel.state.unreadCount}
              </Typography>
            </AccordionSummary>
            <MessagesList messages={messages} />
          </Accordion>
        )}
      </FlexColumn>
    </FlexColumn>
  );
};
