import React from 'react';
import { AddressColumn } from './AddressColumn';
import { Company } from 'libs/api/iam/types';
import { Grid, Skeleton } from '@clippings/paper';
import { InfoBox } from 'libs/shared';
import {
  useListCompanyBillingAddresses,
  useListCompanyShippingAddresses,
} from 'libs/api/iam/hooks';
import { useTranslation } from 'react-i18next';

export interface AddressInfoProps {
  company: Company;
  isEditable: boolean;
}

export const AddressInfo: React.FC<AddressInfoProps> = ({ company, isEditable }) => {
  const { t } = useTranslation();
  const billingAddressesQuery = useListCompanyBillingAddresses(company.id);
  const shippingAddressesQuery = useListCompanyShippingAddresses(company.id);

  if (!billingAddressesQuery.isSuccess || !shippingAddressesQuery.isSuccess) {
    return <Skeleton height="200px" />;
  }

  const billingAddresses = billingAddressesQuery.data;
  const hasBillingAddresses = billingAddresses.length > 0;

  return (
    <InfoBox title={t('companies.addressInformation')}>
      <Grid container display="grid" gridTemplateColumns="1fr 1fr" columnGap={4}>
        <AddressColumn
          type="billing"
          title={t('common.billingAddress')}
          company={company}
          isEditable={isEditable}
          canCreateNewAddress={isEditable && !hasBillingAddresses}
          addresses={billingAddresses}
        />
        <AddressColumn
          type="shipping"
          title={t('common.shippingAddress')}
          company={company}
          isEditable={isEditable}
          canCreateNewAddress={isEditable}
          addresses={shippingAddressesQuery.data}
        />
      </Grid>
    </InfoBox>
  );
};
