import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ExpandMoreIcon,
  Typography,
} from '@clippings/paper';
import { InboxRowItem } from 'libs/SalesApp/Inbox/InboxRow';
import { InboxSectionContent } from 'libs/SalesApp/Inbox/InboxSectionContent';

export type InboxSectionProps = {
  title: string;
  items: InboxRowItem[];
  hasNextPage: boolean;
  onNextClick: () => void;
  noResultsMessage: string;
  loading: boolean;
  isFetchingNextPage?: boolean;
};

export function InboxSection({
  title,
  items,
  hasNextPage,
  onNextClick,
  noResultsMessage,
  loading,
  isFetchingNextPage,
}: InboxSectionProps) {
  const [expanded, setExpanded] = useState(true);
  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      className="quote-list-archived-section"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography fontWeight="fontWeightMedium" color="text.primary">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <InboxSectionContent
          items={items}
          loading={loading}
          hasNextPage={hasNextPage}
          noResultsMessage={noResultsMessage}
          onNextClick={onNextClick}
          isFetchingNextPage={isFetchingNextPage}
        />
      </AccordionDetails>
    </Accordion>
  );
}
