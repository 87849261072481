import React from 'react';
import { Address } from 'libs/api/common/types';
import { AddressDetails } from '../../../../Companies/details/components/CompanyDetailsPanel/components/AddressDetails';
import { Box } from '@clippings/paper';

export interface AddressBoxProps {
  address: Address;
}

export const AddressBox: React.FC<AddressBoxProps> = ({ address }) => {
  return (
    <Box key={address.id} border={1} borderColor="grey.A400" borderRadius={2} p={1}>
      <AddressDetails address={address} />
    </Box>
  );
};
