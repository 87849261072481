import React, { FC } from 'react';
import { Monetary } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { useTranslation } from 'react-i18next';

interface TaxRowProps {
  tax: Quote['tax'];
  children: (getLabel: () => string, getTaxValue: () => JSX.Element) => JSX.Element;
}

export const TaxRow: FC<TaxRowProps> = ({ tax, children }) => {
  const { t } = useTranslation();
  const getTaxValue = () => <Monetary data-testid="tax-row-value" fontWeight={600} {...tax} />;

  const getLabel = () => t('quotes.salesTax');

  return children(getLabel, getTaxValue);
};
