import { Address, ShippingAddress } from 'libs/api/common/types';
import { Brand } from '@clippings/paper';
import { UpdateType } from 'libs/Utils';

type AddressUpdated = UpdateType<
  Address,
  | {
      state: string;
      country: string;
    }
  | Pick<Address, 'state' | 'country'>
> & {
  metadata: ShippingAddress['metadata'];
};
const fomcoreEmptyMetadata = {
  dock: '',
  attention: '',
  trackingNotificationEmailAddress: '',
  companyName: '',
  locationType: '',
  locationTypeOther: '',
};

export function getEditAddressesDefaultValue(
  address: Address,
  brand: Brand | null
): AddressUpdated {
  const defaultValues = { ...address } as AddressUpdated;

  return {
    ...defaultValues,
    province: address.province ?? '',
    state: address.state?.id.toString() ?? '',
    country: address.country?.shortName ?? '',
    metadata: {
      ...(brand === Brand.Fomcore && fomcoreEmptyMetadata),
      ...('metadata' in address && { ...address.metadata }),
    },
  };
}
