import * as Sentry from '@sentry/react';
import React from 'react';
import { BrowserTracing } from '@sentry/tracing';
import { GenericErrorView } from '../GenericErrorView';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { routes } from 'libs/Constants';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
}
export const ErrorBoundary: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={<GenericErrorView to={routes.home} />}>
    {children}
  </Sentry.ErrorBoundary>
);
