import React, { PropsWithChildren } from 'react';
import { Box, DataTestId, Typography } from '@clippings/paper';

interface PageHeaderProps extends DataTestId {
  title: string;
  titleTestId: string;
}

export const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = ({
  title,
  titleTestId,
  children,
  ...props
}) => {
  return (
    <Box py={3} display="flex" justifyContent="space-between" alignItems="center" {...props}>
      <Typography
        fontWeight="fontWeightMedium"
        variant="h5"
        lineHeight={theme => theme.typography.pxToRem(36)}
        data-testid={titleTestId}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
