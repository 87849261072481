import React from 'react';
import { AddAlgoliaItemCell } from './AddQuoteAlgoliaItem';
import { AddCustomAndPredefinedDiscount } from '../discounts';
import { AddDeliveryItemCell } from './AddDeliveryItemCell';
import { Brand, DataGrid, TableBody, TableCell, TableHead, TableRow } from '@clippings/paper';
import { DeliveryItemsTableRow } from './DeliveryItemsTableRow';
import { Readonly } from 'libs/shared';
import { useAppConfiguration, useSearchProvider } from 'libs/providers';
import { useDelivery } from '../hooks/useDelivery';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

export const DeliveryItemsTable: React.FC<Readonly> = ({ readonly = false }) => {
  const { brand } = useAppConfiguration();
  const { discountIndex } = useSearchProvider();
  const { t } = useTranslation();
  const { quote } = useQuoteProvider();
  const {
    inputValue,
    setInputValue,
    addDeliveryItemHandler,
    deliveryItemChangeHandler,
    addPredefinedHandler,
  } = useDelivery();

  if (readonly && quote.deliveryItems.length === 0) {
    return null;
  }

  const placeholder = quote.shippingAddress
    ? t('quotes.addProductPlaceholder')
    : t('quotes.addresses.addDeliveryAddressFirst');

  return (
    <DataGrid compact data-testid="delivery-items-table">
      <TableHead>
        <TableRow>
          <TableCell data-testid="delivery-items-shipping" width="35%">
            {t('quotes.shipping.table.shippingMethod')}
          </TableCell>
          <TableCell data-testid="delivery-items-shipping-time" width="30%" align="right">
            {t('quotes.shipping.table.shippingTime')}
          </TableCell>
          <TableCell data-testid="delivery-items-tax-rate" width="15%" align="right">
            {t('quotes.shipping.table.taxRate')}
          </TableCell>
          <TableCell data-testid="delivery-items-net-amount" width="20%" align="right">
            {t('quotes.shipping.table.netAmount')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {quote.deliveryItems.map((deliveryItem, index) => (
          <DeliveryItemsTableRow
            key={deliveryItem.id}
            index={index}
            readonly={readonly}
            deliveryItem={deliveryItem}
            onChange={deliveryItem => deliveryItemChangeHandler(index, deliveryItem)}
          />
        ))}
        {!readonly ? (
          <TableRow>
            {discountIndex && brand === Brand.Fomcore ? (
              <AddCustomAndPredefinedDiscount>
                <AddAlgoliaItemCell
                  placeholder={placeholder}
                  inputProps={{ 'data-testid': 'add-delivery-item-input' }}
                  infoLabel={t('quotes.searchInfoLabelNoCustom')}
                  onItemSelect={item => addDeliveryItemHandler(item.name)}
                  onItemCreate={addDeliveryItemHandler}
                  inputValueChangeHandler={setInputValue}
                  inputValue={inputValue}
                  disabled={!quote.shippingAddress}
                  inputDisabled
                />
              </AddCustomAndPredefinedDiscount>
            ) : (
              <AddDeliveryItemCell
                inputValue={inputValue}
                inputChangeHandler={setInputValue}
                placeholder={placeholder}
                onAddPredefined={addPredefinedHandler}
                onAdd={addDeliveryItemHandler}
                quote={quote}
              />
            )}
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        ) : null}
      </TableBody>
    </DataGrid>
  );
};
