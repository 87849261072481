import React, { FC } from 'react';
import { Address } from 'libs/api/common/types';
import { Typography } from '@clippings/paper';
import {
  isFomcoreShippingMetadata,
  isShippingAddress,
  valueToYesNoTranslation,
} from 'libs/api/common/utils';
import { useTranslation } from 'react-i18next';

export interface ShippingAddressMetadataProps {
  address: Address;
}

export const ShippingAddressMetadata: FC<ShippingAddressMetadataProps> = ({ address }) => {
  const { t } = useTranslation();

  if (!isShippingAddress(address) || !isFomcoreShippingMetadata(address.metadata)) {
    return null;
  }

  const { dock, locationType, trackingNotificationEmailAddress, companyName } = address.metadata;

  return (
    <>
      {companyName && (
        <Typography variant="caption" color="secondary">
          {t('common.companyName')}
          {': '}
          {companyName}
        </Typography>
      )}
      {dock && (
        <Typography variant="caption" color="secondary">
          {t('common.dock')}
          {': '}
          {t(valueToYesNoTranslation(dock))}
        </Typography>
      )}
      {locationType && (
        <Typography variant="caption" color="secondary">
          {t('quotes.addresses.locationType')}
          {': '}
          {t(`common.locationTypes.${locationType}`)}
        </Typography>
      )}
      {trackingNotificationEmailAddress && (
        <Typography variant="caption" color="secondary">
          {t('common.trackingNotificationEmailAddress')}
          {': '}
          {trackingNotificationEmailAddress}
        </Typography>
      )}
    </>
  );
};
