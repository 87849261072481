import React from 'react';
import { CellPercentageInput, TableCell } from '@clippings/paper';
import { PaymentRuleRowCommonProps } from '../../paymentTerms.types';

type PaymentRulePercentageCellRendererProps = PaymentRuleRowCommonProps;

export function PaymentRulePercentageCellRenderer({
  paymentRule,
  index,
  onChange,
}: PaymentRulePercentageCellRendererProps) {
  const handlePercentageChange = (value: number) =>
    onChange({
      ...paymentRule,
      percentage: value,
    });

  return (
    <TableCell>
      <CellPercentageInput
        value={paymentRule.percentage!}
        onValueChange={handlePercentageChange}
        autoFocus
        inputProps={{
          'data-testid': `percentage-input-${index}`,
        }}
      />
    </TableCell>
  );
}
