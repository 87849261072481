import { Location } from 'libs/api/location/types';
import { Nullable, isCountryUS } from 'libs/Utils';

export function getConditionalAddressProps(
  countriesByShortName: Record<string, Location>,
  states: Location[],
  country: string,
  state: Nullable<string> = '',
  province: Nullable<string> = ''
) {
  const isUSA = isCountryUS(country);

  return {
    state: isUSA && state ? states.find(x => x.id === Number(state)) : undefined,
    province: !isUSA ? province : undefined,
    country: countriesByShortName[country],
  };
}
