import React, { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { Catalogue } from 'libs/api/catalogue/types';
import { useListCatalogues } from 'libs/api/catalogue/hooks';

type CatalogsContextState = {
  catalogs: Catalogue[];
  isFetching: boolean;
};

const CatalogsContext = createContext<CatalogsContextState>({} as CatalogsContextState);

export function CatalogsProvider({ children }: PropsWithChildren) {
  const { data, isFetching } = useListCatalogues();

  const value: CatalogsContextState = useMemo(() => {
    return { catalogs: data ?? [], isFetching };
  }, [data, isFetching]);

  return <CatalogsContext.Provider value={value}>{children}</CatalogsContext.Provider>;
}

export function useAvailableCatalogs() {
  const context = useContext(CatalogsContext);

  if (!context) {
    throw new Error('useAvailableCatalogs must be used within a CatalogsProvider');
  }

  return context;
}
