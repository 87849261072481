import React, { ChangeEvent, ComponentProps } from 'react';
import { DataTestId } from 'libs/shared/types';
import { InputAdornment, SearchIcon, TextField } from '@clippings/paper';
import { preventDefaultPropagation } from 'libs/shared/utils';

interface SearchProps extends DataTestId {
  placeholder: string;
  textFieldInputProps?: ComponentProps<typeof TextField>['InputProps'];
  changeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function Search({
  placeholder,
  textFieldInputProps = {},
  changeHandler,
  'data-testid': testId,
}: SearchProps): JSX.Element {
  return (
    <TextField
      type="search"
      size="small"
      fullWidth
      data-testid={testId}
      placeholder={placeholder}
      sx={textFieldSx}
      onClick={preventDefaultPropagation}
      onChange={changeHandler}
      InputProps={{
        autoComplete: 'off',
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        ...textFieldInputProps,
      }}
    />
  );
}

const textFieldSx = {
  paddingX: 1,
};
