import React from 'react';
import { CompanyInfoBox } from './components/CompanyInfoBox';
import { User } from 'libs/api/iam/types';
import { UserInfoBox } from './components/UserInfoBox';

interface ApprovedCustomerProps {
  user: User;
}

export const ApprovedCustomer: React.FC<ApprovedCustomerProps> = ({ user }) => {
  return (
    <>
      <UserInfoBox user={user} isEditable={user.editable} />
      {user.company && <CompanyInfoBox companyId={user.company.id} />}
    </>
  );
};
