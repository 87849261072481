import React, { ComponentProps, FC } from 'react';
import { Monetary } from '@clippings/paper';
import { Quote } from 'libs/api/quotes/types';
import { TotalsSummaryRow } from './TotalsSummaryRow';
import { TotalsSummaryRowLabel } from './TotalsSummaryRowLabel';
import { TotalsSummaryRowValue } from './TotalsSummaryRowValue';
import { convertToPriceObject } from 'libs/Utils';
import { useTranslation } from 'react-i18next';

interface TotalsSummaryTotalRowProps extends ComponentProps<typeof TotalsSummaryRow> {
  total: Quote['total'];
}

export const TotalsSummaryTotalRow: FC<TotalsSummaryTotalRowProps> = ({ total, ...rest }) => {
  const { t } = useTranslation();

  return (
    <TotalsSummaryRow hasBorder={false} {...rest}>
      <TotalsSummaryRowLabel color="text.primary">{t('quotes.total')}</TotalsSummaryRowLabel>
      <TotalsSummaryRowValue>
        <Monetary fontWeight={600} {...convertToPriceObject(total, total.currency as any)} />
      </TotalsSummaryRowValue>
    </TotalsSummaryRow>
  );
};
