import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import { Box } from '@clippings/paper';
import './TotalsSummary.scss';

export const TotalsSummary: FC<PropsWithChildren<Partial<ComponentProps<typeof Box>>>> = ({
  children,
  ...rest
}) => (
  <Box className="totals-summary" {...rest}>
    {children}
  </Box>
);
