import { Brand } from '@clippings/paper';
import { UserPermission } from 'libs/api/iam/types';
import { useAppConfiguration } from 'libs/providers';
import { useHasPermission } from 'libs/hooks/useHasPermission';

export function useShouldRenderPdfWithoutDiscount() {
  const { instance } = useAppConfiguration();
  const isStaffUser = useHasPermission(UserPermission.CanAccessSalesApp);
  const isMoroso = instance === Brand.Moroso;
  const shouldRenderPdfWithoutDiscount = isStaffUser && isMoroso;

  return { shouldRenderPdfWithoutDiscount };
}
