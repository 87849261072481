import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { QuotesTableFilter } from 'libs/SalesApp/Quotes/types';
import { useSalesQuotes } from 'libs/SalesApp/Quotes/hooks';

export const useInboxQuotes = () => {
  return useSalesQuotes({
    filters: {
      [QuotesTableFilter.Status]: [QuoteListItemStatus.Requested],
    },
    size: 3,
  });
};
