import React, { ComponentProps, useMemo, useState } from 'react';
import { Box, Divider, Link, Monetary, Skeleton, Typography } from '@clippings/paper';
import { Column, ComponentGridContainer, DataTestId, DividerGrey, Readonly } from 'libs/shared';
import { CustomDeliveryDate } from 'libs/Quotes/customDeliveryDate';
import { DeliveryItemsTable } from './DeliveryItemsTable';
import { DiscountsTable } from '../discounts';
import { Money } from 'libs/api/common/types';
import { PaymentTermRow } from 'libs/Quotes/Components/TotalsSummary/PaymentTermRow';
import { PaymentTermsDialog } from '../paymentTerms';
import { Quote } from 'libs/api/quotes/types';
import { useQuoteProvider } from 'libs/Quotes/providers';
import { useTranslation } from 'react-i18next';

interface QuoteSummaryProps extends Readonly {
  quote?: Quote;
  loading: boolean;
}

export const QuoteSummary: React.FC<QuoteSummaryProps> = ({ quote, loading, readonly = false }) => {
  const { t } = useTranslation();
  const { paymentTermsQuery, updateWithoutOptimisticLoading, handleQuoteUpdate } =
    useQuoteProvider();
  const [isPaymentTermsDialogOpen, setIsPaymentTermsDialogOpen] = useState(false);

  const paymentTerms = useMemo(
    () => paymentTermsQuery.data?.map(x => x.paymentRule) ?? [],
    [paymentTermsQuery.data]
  );

  if (loading || (paymentTermsQuery.isLoading && !!quote && !!quote.customer)) {
    return (
      <Skeleton
        width="100%"
        height="700px"
        variant="rectangular"
        data-testid="quote-summary-loader"
        sx={{ alignSelf: 'end' }}
      />
    );
  }

  if (!quote || !paymentTermsQuery.data) {
    return null;
  }

  return (
    <>
      <ComponentGridContainer>
        <SummaryRow
          data-testid="quote-summary-items-subtotal"
          label={t('common.itemsSubTotal')}
          money={quote.subtotal}
        />
        {quote.contractItemsSubTotal && quote.contractItemsSubTotal.amount > 0 && (
          <SummaryRow
            data-testid="quote-summary-contact-items-subtotal"
            label={t('common.contractItemsSubTotal')}
            money={quote.contractItemsSubTotal}
          />
        )}
        <DividerGrey />
        <Column gap={1}>
          {readonly && quote.deliveryItems.length === 0 ? null : (
            <Typography
              data-testid={`quote-summary-shipping-${quote.number}`}
              variant="subtitle2"
              color="text.secondary"
            >
              {t('quotes.shipping.table.title')}
            </Typography>
          )}
          <DeliveryItemsTable readonly={readonly} />
          <CustomDeliveryDate
            customDeliveryDateModel={quote.metadata?.customDeliveryDate ?? null}
            isLoading={updateWithoutOptimisticLoading}
            modalTitle={t('quotes.requestCustomDeliveryDate')}
            quoteMetadata={quote.metadata}
            onUpdate={handleQuoteUpdate}
          />
        </Column>
        <Box>
          {readonly && quote.discountItems.length === 0 ? null : (
            <Typography
              data-testid={`quote-summary-row-discounts-${quote.number}`}
              variant="subtitle2"
              color="text.secondary"
              pb={1}
            >
              {t('quotes.discounts.table.title')}
            </Typography>
          )}
          <DiscountsTable readonly={readonly} />
        </Box>
        <SummaryRow
          data-testid={`quote-summary-row-subtotal-${quote.number}`}
          label={t('quotes.subTotal')}
          money={quote.totalBeforeTaxes}
        />
        <DividerGrey />
        <SummaryRow
          data-testid={`quote-summary-row-tax-${quote.number}`}
          label={t('common.tax')}
          money={quote.tax}
        />
        <Divider />
        <Box
          data-testid="quotes-summary-total-amount-container"
          display="flex"
          justifyContent="space-between"
        >
          <Typography data-testid="quotes-summary-total-amount" alignSelf="center">
            {t('quotes.total')}
          </Typography>
          <Monetary variant="h6" amount={quote.total.amount} currency={quote.total.currency} />
        </Box>
        <Divider />
        <Box>
          <Box display="flex" justifyContent="space-between" pb={1}>
            <Typography
              sx={{ fontSize: theme => theme.typography.pxToRem(16) }}
              data-testid={`quote-summary-payment-terms-${quote.number}`}
            >
              {t('paymentTerms.paymentTerms')}
            </Typography>
            {!readonly ? (
              <Link
                component="button"
                color="primary.main"
                underline="none"
                variant="subtitle2"
                data-testid="quote-edit-payment-terms-button"
                onClick={() => setIsPaymentTermsDialogOpen(true)}
              >
                {t('common.change')}
              </Link>
            ) : null}
          </Box>
          {paymentTermsQuery.data.map((paymentTerm, idx) => (
            <PaymentTermRow
              size="medium"
              key={paymentTerm.id}
              idx={idx + 1}
              paymentTerm={paymentTerm}
            />
          ))}
        </Box>
      </ComponentGridContainer>
      {!readonly ? (
        <PaymentTermsDialog
          open={isPaymentTermsDialogOpen}
          onClose={() => setIsPaymentTermsDialogOpen(false)}
          originalPaymentTerms={paymentTerms}
          quote={quote}
        />
      ) : null}
    </>
  );
};

const SummaryRow: React.FC<
  ComponentProps<typeof Box> & { label: string; money: Money } & DataTestId
> = ({ label, money, ...rest }) => (
  <Box display="flex" justifyContent="space-between" {...rest}>
    <Typography variant="subtitle2" color="text.secondary" data-testid="summary-row-label">
      {label}
    </Typography>
    <Monetary
      data-testid="summary-row-amount"
      variant="subtitle2"
      amount={money.amount}
      currency={money.currency}
    />
  </Box>
);
