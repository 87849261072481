import { Cell, Worksheet } from 'exceljs';
import { ColumnsModel, ROW_HEIGHT, WIDTH_RATION } from './columns';
import { Payment } from 'libs/api/common/types';
import { Quote } from 'libs/api/quotes/types';
import { TFunction } from 'i18next';

const colLetters = ['A', 'B', 'C', 'D'];

export type Config = typeof defaultConfig & { name: string; creator: string };

export const defaultConfig = {
  view: { state: 'frozen', ySplit: 1, xSplit: 2 },
  defaultWidth: 100 / WIDTH_RATION,
  defaultHeight: ROW_HEIGHT,
  contentFont: {
    name: 'Helvetica Neue',
    family: 4,
    size: 12,
    bold: false,
  },
  headerFont: {
    name: 'Helvetica Neue',
    family: 4,
    size: 14,
    bold: true,
  },
};

export function addTitle(title: string, worksheet: Worksheet, config: Config) {
  const { rowCount } = worksheet;
  const row = rowCount + 2;
  const idx = `${colLetters[0]}${row}`;

  const cell = worksheet.getCell(idx);
  cell.value = title;
  styleHeaderCell(cell, config);

  const siblingCell = worksheet.getCell(`${colLetters[1]}${row}`);
  styleHeaderCell(siblingCell, config);
}

// Payment Terms
export function addPaymentTerms(
  worksheet: Worksheet,
  paymentTerms: Payment[],
  config: Config,
  t: TFunction,
  columnsModel: ColumnsModel,
  formatDetails: any,
  formatType: any
) {
  addTitle('Payment Terms', worksheet, config);

  const { rowCount } = worksheet;
  const paymentTermsRows = mapItemsToRowsPaymentTerms(paymentTerms, formatDetails, formatType);

  columnsModel.paymentTermsColumnsModel.forEach((col, idx) => {
    const titleCell = worksheet.getCell(`${colLetters[idx]}${rowCount + 1}`);
    titleCell.value = t(col.header);
  });

  paymentTermsRows.forEach((row, idx) => {
    columnsModel.paymentTermsColumnsModel.forEach((_, colIdx) => {
      const valueCell = worksheet.getCell(`${colLetters[colIdx]}${rowCount + 1 + idx}`);
      valueCell.value = row[colIdx];
      styleValueCell(valueCell, config, false, 12);
    });
  });
}

function mapItemsToRowsPaymentTerms(payments: Payment[], formatDetails: any, formatType: any) {
  return payments.map(payment => {
    return [
      `${payment.paymentRule.percentage}%`,
      `${formatType(payment)} - ${formatDetails(payment)}`,
    ];
  });
}

// Shipping & Discounts

export function addItemsToCells(
  worksheet: Worksheet,
  items: Quote['deliveryItems'] | Quote['discountItems'],
  columnsModel: ColumnsModel['shippingColumnsModel'] | ColumnsModel['discountsColumnsModel'],
  title: string,
  config: Config
) {
  const titleCellLetter = colLetters[0];
  const valueCellLetter = colLetters[1];

  addTitle(title, worksheet, config);

  const { rowCount } = worksheet;
  const rows = mapItemsToRows(items, columnsModel);

  rows.forEach((row, index) => {
    const titleCell = worksheet.getCell(`${titleCellLetter}${rowCount + 1 + index}`);
    titleCell.value = Object.keys(row)[0];
    styleHeaderCell(titleCell, config, false, 12);

    const valueCell = worksheet.getCell(`${valueCellLetter}${rowCount + 1 + index}`);
    valueCell.value = Object.values(row)[0];
    styleValueCell(valueCell, config, true);
  });
}

function mapItemsToRows(items: Quote['deliveryItems'] | Quote['discountItems'], column: any) {
  return items.map(item => ({ [item.name]: column.converter(item) }));
}

function styleHeaderCell(cell: Cell, config: Config, withBorder = true, fontSize = 15) {
  cell.font = { ...config.headerFont, bold: false, size: fontSize };
  cell.alignment = { vertical: 'middle', horizontal: 'center' };

  if (withBorder) {
    cell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
    };
  }
}

function styleValueCell(cell: Cell, config: Config, bold = false, fontSize = 15) {
  cell.font = { ...config.contentFont, bold, size: fontSize };
  cell.alignment = { vertical: 'middle', horizontal: 'center' };
}
