import React, { useState } from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  getFormErrorMessage,
  getUserFullName,
} from '@clippings/paper';

import { ApprovedUserDialog } from './components/ApprovedUserDialog';
import { Company, UpdateUserVariables, User, UserStatus } from 'libs/api/iam/types';
import { CreateNewCompanyPanel } from './components/CreateNewCompanyPanel';
import { LinkExistingCompanyPanel } from './components/LinkExistingCompanyPanel';
import { RejectCustomerConfirmationDialog } from './components/RejectCustomerUserConfirmationDialog';
import { UserInfoBox } from './components/UserInfoBox';
import { getServerFormErrors } from 'libs/Utils/Form/useServerFormErrors';
import { path } from 'libs/Utils';
import { removeNullValues } from 'libs/shared/utils';
import { routes } from 'libs/Constants';
import { updateUser } from 'libs/api/iam/actions';
import { useBanner } from 'libs/Quotes/Components/AlertBanner';
import { useCreateCompany } from 'libs/api/iam/hooks';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface NotApprovedCustomerProps {
  user: User;
}
export const NotApprovedCustomer: React.FC<NotApprovedCustomerProps> = ({ user }) => {
  const navigate = useNavigate();
  const { showErrorBanner } = useBanner();
  const { t } = useTranslation();

  const [isCreateCompany, setIsCreateCompany] = useState(true);
  const [openNewlyCreatedCompanyDialog, setOpenNewlyCreatedCompanyDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);

  const userMutation = useMutation((user: UpdateUserVariables) => updateUser(user.id, user));

  const companyMutation = useCreateCompany({
    onError: errors => {
      const formErrors = getServerFormErrors(errors);
      const errorMessage = Object.keys(formErrors)
        .map(key => getFormErrorMessage(formErrors, key))
        .join(' ');

      showErrorBanner(errorMessage);
    },
  });

  const handleCloseNewlyCreatedCompanyDialog = () => {
    setOpenNewlyCreatedCompanyDialog(false);
    navigateToUsersList(userMutation.data!);
  };

  const navigateToUsersList = (updatedUser: User) => {
    navigate(routes.salesApp.users, {
      state: { user: updatedUser },
    });
  };

  const handleSetupCommercialTerms = () => {
    navigate(path(routes.salesApp.company, { companyId: companyMutation.data?.id }));
  };

  const handleSubmitRejectUser = () => {
    userMutation.mutate(
      { id: user.id, status: UserStatus.Rejected },
      {
        onSuccess: () => {
          navigateToUsersList(user);
        },
      }
    );
  };

  const handleCreateCompany = () => {
    if (user.company && user.billingAddresses) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { catalogues, ...companyValues } = removeNullValues(user.company);

      companyMutation.mutate(
        {
          ...companyValues,
          website: companyValues.website ?? '',
          registrationNumber: companyValues.registrationNumber ?? '',
          billingAddresses: [{ ...user.billingAddresses[0], default: true }],
        },
        {
          onSuccess: company => {
            userMutation.mutate(
              { id: user.id, status: UserStatus.Approved, companyId: company.id },
              {
                onSuccess: () => {
                  setOpenNewlyCreatedCompanyDialog(true);
                },
              }
            );
          },
        }
      );
    }
  };

  const handleLinkToExistingCompany = ({ company }: { company: Company }) => {
    userMutation.mutate(
      { id: user.id, status: UserStatus.Approved, companyId: company.id },
      {
        onSuccess: user => {
          navigateToUsersList(user);
        },
      }
    );
  };

  return (
    <>
      <Box>
        <UserInfoBox user={user} isEditable={false} />

        <Typography variant="h6" fontWeight="bold" my={3}>
          {isCreateCompany ? t('common.setUpCompanyProfile') : t('common.companyProfile')}
        </Typography>

        <Box sx={{ mb: 3, flexDirection: 'row', width: '100%' }}>
          <RadioGroup
            aria-labelledby="show-company-details"
            defaultValue={true}
            sx={{ flexDirection: 'row', width: '100%' }}
            onChange={(_, value) => setIsCreateCompany(value === 'true')}
          >
            <FormControlLabel
              data-testid="create-new-company"
              value={true}
              sx={{ '& .MuiFormControlLabel-label': { fontWeight: 400 } }}
              control={<Radio />}
              label={t('users.createNewCompanyProfileWithTheDetailsBelow')}
              disabled={userMutation.isLoading || companyMutation.isLoading}
            />
            <FormControlLabel
              data-testid="link-to-existing-company"
              value={false}
              sx={{ '& .MuiFormControlLabel-label': { fontWeight: 400 } }}
              control={<Radio />}
              label={t('users.LinkToExistingCompanyProfile')}
              disabled={userMutation.isLoading || companyMutation.isLoading}
            />
          </RadioGroup>
        </Box>

        {isCreateCompany && user.company ? (
          <CreateNewCompanyPanel
            canReject={user.status !== UserStatus.Rejected}
            company={user.company}
            addresses={user.billingAddresses || []}
            onReject={() => setOpenRejectDialog(true)}
            onSubmit={handleCreateCompany}
            isSubmitting={userMutation.isLoading || companyMutation.isLoading}
          />
        ) : (
          <LinkExistingCompanyPanel
            canReject={user.status !== UserStatus.Rejected}
            onReject={() => setOpenRejectDialog(true)}
            onSubmit={handleLinkToExistingCompany}
            isSubmitting={userMutation.isLoading}
            errors={userMutation.error}
          />
        )}
      </Box>
      <ApprovedUserDialog
        open={openNewlyCreatedCompanyDialog}
        onClose={handleCloseNewlyCreatedCompanyDialog}
        companyName={companyMutation.data?.name ?? ''}
        fullUserName={getUserFullName(user)}
        onSkip={handleCloseNewlyCreatedCompanyDialog}
        onSetupCommercialTerms={handleSetupCommercialTerms}
      />
      <RejectCustomerConfirmationDialog
        open={openRejectDialog}
        fullUserName={getUserFullName(user)}
        onClose={() => setOpenRejectDialog(false)}
        onSubmit={handleSubmitRejectUser}
        isSubmitting={userMutation.isLoading}
      />
    </>
  );
};
