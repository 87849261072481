import { useChatChannel } from '../providers';
import { useEffect, useState } from 'react';

export const useUnreadChannelMessages = () => {
  const { channel } = useChatChannel();
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    if (channel) {
      channel.on(() => {
        setUnreadMessages(channel.state.unreadCount);
      });

      setUnreadMessages(channel.state.unreadCount);
    }
  }, [channel]);

  return { unreadMessages };
};
