import { CellInput, CellMoneyInput, CellPercentageInput, CellSelectInput } from '@clippings/paper';
import { ComponentProps } from 'react';
import { TimeRangeCell } from '../TimeRangeCell';
import { withReadonlyLabel } from 'libs/Components';

export const CellMoneyInputWithReadonly =
  withReadonlyLabel<ComponentProps<typeof CellMoneyInput>>(CellMoneyInput);

export const CellPercentageInputWithReadonly =
  withReadonlyLabel<ComponentProps<typeof CellPercentageInput>>(CellPercentageInput);

export const CellInputWithReadonly = withReadonlyLabel<ComponentProps<typeof CellInput>>(CellInput);

export const CellSelectInputWithReadonly =
  withReadonlyLabel<ComponentProps<typeof CellSelectInput>>(CellSelectInput);

export const TimeRangeCellWithReadonly =
  withReadonlyLabel<ComponentProps<typeof TimeRangeCell>>(TimeRangeCell);
