import React from 'react';
import { Alert, Link, Typography } from '@clippings/paper';
import { Row } from 'libs/shared';
import { routes } from 'libs/Constants';
import { useTranslation } from 'react-i18next';

export function FullAccessBanner() {
  const { t } = useTranslation();

  return (
    <Alert
      severity="info"
      action={
        <Link href={routes.guestApp.auth.register} alignItems="center" p={0} underline="none">
          {t('common.signUp')}
        </Link>
      }
    >
      <Row justifyContent="space-between">
        <Typography variant="body2" fontWeight={500}>
          {t('quotes.getFullAccess')}
        </Typography>
      </Row>
    </Alert>
  );
}
