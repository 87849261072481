import React, { ComponentProps } from 'react';
import { Box, Grid, LoadMoreDivider, Skeleton } from '@clippings/paper';
import { range } from 'lodash';

export interface ListTableProps extends ComponentProps<typeof Box> {
  isLoading?: boolean;
  noMoreLabel?: string;
  onFetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  rowHeight: number;
}

export const ListTable: React.FC<ListTableProps> = ({
  children,
  isLoading = false,
  noMoreLabel,
  onFetchNextPage,
  hasNextPage,
  rowHeight,
  isFetchingNextPage,
  sx,
  ...rest
}) => (
  <Box
    sx={{
      '.list-table-row': {
        minHeight: rowHeight,
      },
      '.load-more-divider': {
        display: 'none',
      },
      '.list-table-body ~ .load-more-divider': {
        display: 'flex',
      },
      ...sx,
    }}
    {...rest}
  >
    {children}
    {(isLoading || isFetchingNextPage) && (
      <Grid container flexDirection="row" columnSpacing={1} width="100%" ml={0}>
        {range(5).map((key, i) => (
          <Skeleton
            data-testid={`list-table-body-skeleton-row-${i}`}
            key={key}
            variant="rectangular"
            width="100%"
            height={rowHeight}
            sx={{ mt: i === 0 ? 0 : 1, borderRadius: 1 }}
          />
        ))}
      </Grid>
    )}
    {!isLoading && noMoreLabel && onFetchNextPage && (
      <LoadMoreDivider
        className="load-more-divider"
        hasNextPage={hasNextPage}
        noMoreLabel={noMoreLabel}
        onClick={onFetchNextPage}
      />
    )}
  </Box>
);
