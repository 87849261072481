import React from 'react';
import { Column, Row } from 'libs/shared';
import { CustomDeliveryDateModel } from 'libs/api/quotes/types';
import { FormControlLabel, FormDatePicker, FormRadioGroup, Radio, yup } from '@clippings/paper';
import { useTranslation } from 'react-i18next';

export type CustomDeliveryDateFormData = CustomDeliveryDateModel;

export function CustomDeliveryDateForm() {
  const { t } = useTranslation();

  const typeOptions = [
    { value: 'arriveBy', label: t('common.arriveBy') },
    { value: 'shipBy', label: t('common.shipBy') },
  ];

  return (
    <Column gap="24px">
      <FormRadioGroup name="type" defaultValue={typeOptions[0].value}>
        <Row gap={4}>
          {typeOptions.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              label={label}
              control={<Radio />}
              data-testid={`custom-delivery-date-type-${value}`}
            />
          ))}
        </Row>
      </FormRadioGroup>
      <FormDatePicker
        name="date"
        minDate={new Date()}
        disablePast
        inputProps={{
          variant: 'standard',
          fullWidth: true,
          'data-testid': 'custom-delivery-date-picker',
        }}
      />
    </Column>
  );
}

export const validationSchema: Record<keyof CustomDeliveryDateFormData, yup.AnySchema> = {
  type: yup.string().required('common.typeRequired'),
  date: yup
    .date()
    .required('common.dateRequired')
    .min(new Date(), 'common.dateInTheFuture')
    .typeError('common.dateRequired'),
};
