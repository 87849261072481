import React, { ComponentProps, Fragment, MouseEvent, useState } from 'react';
import classNames from 'classnames';
import { AppConfiguration, LineItemVisibleFields } from 'libs/api/configuration/types';
import { ConfiguredProduct } from '../../../../SalesApp/Quotes/common/types';
import { ContractFieldsValues } from '../../utils';
import { InfoCellItemRenderer } from './InfoCellItemRenderer';
import {
  Monetary,
  Popper,
  QuantityInput,
  TableCell,
  TableRow,
  Typography,
  convertTimeRange,
  useFormatLeadTime,
  useFormatPercentage,
} from '@clippings/paper';
import { Note, useNoteData } from 'libs/notes';
import { OrderProductItem } from 'libs/api/order/types';
import { PRODUCT_MAX_QUANTITY } from 'libs/Utils';
import { ProductImage, fallbackCoverUrl } from 'libs/shared';
import { QuoteItemHiddenDetails } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { QuoteRowActionsPanel } from '../QuoteRowActionsPanel/QuoteRowActionsPanel';
import { UpdateQuoteByKey } from 'libs/SalesApp/Quotes/types';
import { isEqual } from 'lodash';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';
import './QuoteItemRow.scss';

// TODO: refactor - components in Quotes should not depend on SalesApp lib

interface QuoteItemRowProps extends ComponentProps<typeof TableRow> {
  item: QuoteProductItem | OrderProductItem;
  tableLoading?: boolean;
  readonly: boolean;
  textOnly?: boolean;
  detailsHidden: boolean;
  lineItemVisibleFields: LineItemVisibleFields;
  contractFieldsValues: ContractFieldsValues;
  onDeleteItem?: (id: number) => void;
  onQuantityUpdate?: (quantity: number, id: number) => void;
  onConfigure?: (configuredProduct: ConfiguredProduct) => void;
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
  onNoteSave?: UpdateQuoteByKey;
}

export function QuoteItemRow({
  item,
  tableLoading,
  readonly,
  textOnly = false,
  detailsHidden,
  lineItemVisibleFields,
  contractFieldsValues,
  onDeleteItem,
  onQuantityUpdate,
  onConfigure,
  onProductDetailsHide,
  onNoteSave,
  ...rest
}: QuoteItemRowProps) {
  const { leadTimeFormat } = useAppConfiguration();
  const formatLeadTime = useFormatLeadTime();
  const formatPercentage = useFormatPercentage();
  const { noteSaveHandler } = useNoteData(item.id, onNoteSave);
  const { t } = useTranslation();

  const [anchorElement, setAnchorElement] = useState<HTMLTableRowElement | null>(null);
  const showActionPanel = (e: MouseEvent<HTMLTableRowElement>) => {
    if (!readonly) {
      setAnchorElement(e.currentTarget);
    }
  };
  const hideActionPanel = () => setAnchorElement(null);

  function quantityChangeHandler(quantity: number) {
    if (readonly || isEqual(quantity, item.quantity)) {
      return;
    }

    onQuantityUpdate?.(quantity, item.id);
  }
  const isQuoteItemProduct = isQuoteProductItem(item);

  const cellsFieldComponentMap: Record<
    AppConfiguration['lineItemVisibleFields'][number],
    JSX.Element
  > = {
    order: (
      <TableCell
        align="center"
        key={`quote-item-cell-order-${item.id}`}
        data-testid={`quote-item-cell-order-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__order')}
      >
        <Typography
          className="quote-item-row__order"
          variant="body2"
          color={theme => theme.palette.grey[700]}
          padding="12px 0"
          sx={{
            fontSize: theme => theme.typography.pxToRem(16),
          }}
        >
          {item.position + 1}
        </Typography>
      </TableCell>
    ),
    info: (
      <Fragment key={`quote-item-cell-info-${item.id}`}>
        <TableCell sx={{ width: '48px', p: 2, textAlign: 'center' }}>
          <ProductImage
            picture={item.picture}
            fallback={fallbackCoverUrl}
            alt={item.productName ? item.productName : ''}
          />
        </TableCell>
        <TableCell
          data-testid={`quote-details-item-variation-${item.id}`}
          className="quote-item-row__cell__description-cell"
        >
          <InfoCellItemRenderer
            item={item}
            detailsHidden={detailsHidden}
            readonly={readonly}
            textOnly={textOnly}
            onConfigure={onConfigure}
            onProductDetailsHide={onProductDetailsHide}
          />
          <Note
            value={item.note ?? ''}
            withDivider={item.custom}
            readonly={readonly}
            placeholder={
              isQuoteItemProduct && item.noteRequired ? t('quotes.noteRequiredEmb') : undefined
            }
            required={isQuoteItemProduct && item.noteRequired}
            onSave={noteSaveHandler}
          />
        </TableCell>
      </Fragment>
    ),
    leadTime: (
      <TableCell
        key={`quote-item-cell-leadTime-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__leadTime')}
        align="right"
        data-testid={`lead-time-${item.id}`}
      >
        <Typography sx={typographyStyle}>
          {formatLeadTime(convertTimeRange(item.leadTime, 'days', leadTimeFormat), leadTimeFormat)}
        </Typography>
      </TableCell>
    ),
    price: (
      <TableCell
        key={`quote-item-cell-price-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__price')}
        align="right"
        data-testid={`retail-${item.id}`}
      >
        <Monetary
          currency={item.catalogueBasePrice?.currency}
          amount={item.catalogueBasePrice?.amount}
          sx={typographyStyle}
        />
      </TableCell>
    ),
    discount: (
      <TableCell
        key={`quote-item-cell-discount-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__discount')}
        align="right"
        data-testid={`discount-${item.id}`}
      >
        <Typography sx={typographyStyle}>{item.discountTitle}</Typography>
      </TableCell>
    ),
    net: (
      <TableCell
        key={`quote-item-cell-netPrice-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__net-price')}
        align="right"
        data-testid={`net-${item.id}`}
      >
        <Monetary
          currency={item.netPrice?.currency}
          amount={item.netPrice?.amount}
          sx={typographyStyle}
        />
      </TableCell>
    ),
    quantity: (
      <TableCell
        key={`quote-item-cell-quantity-${item.id}`}
        data-testid={`quote-item-quantity-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__quantity')}
        align="center"
      >
        {readonly ? (
          <Typography sx={{ ...typographyStyle, fontSize: theme => theme.typography.pxToRem(16) }}>
            {item.quantity}
          </Typography>
        ) : (
          <QuantityInput
            max={PRODUCT_MAX_QUANTITY}
            disabled={tableLoading}
            className="quote-item-row__quantity"
            initialQuantity={item.quantity}
            data-testid={`quantity-input-${item.id}`}
            onQuantityChange={quantityChangeHandler}
            inputProps={{
              sx: {
                '.MuiInputBase-input': {
                  fontSize: theme => theme.typography.pxToRem(16),
                  minWidth: '26px !important',
                },
              },
            }}
          />
        )}
      </TableCell>
    ),
    taxRate: (
      <TableCell
        key={`quote-item-cell-taxRate-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__tax-rate')}
        align="right"
        data-testid={`tax-rate-${item.id}`}
      >
        <Typography sx={typographyStyle}>{formatPercentage(item.taxPercent ?? 0)}</Typography>
      </TableCell>
    ),
    subTotal: (
      <TableCell
        key={`quote-item-cell-subTotal-${item.id}`}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__sub-total')}
        align="right"
        data-testid={`sub-total-${item.id}`}
      >
        <Monetary
          currency={item.subtotal?.currency}
          amount={item.subtotal?.amount}
          sx={{ ...typographyStyle, fontWeight: 500 }}
        />
      </TableCell>
    ),
    contractListPrice: (
      <TableCell
        key={`quote-item-cell-contractListPrice-${item.id}`}
        align="right"
        data-testid={`contract-list-price-${item.id}`}
      >
        <Monetary
          currency={contractFieldsValues.contractListPrice.currency}
          amount={contractFieldsValues.contractListPrice.amount}
          sx={{ ...typographyStyle, fontWeight: 500 }}
        />
      </TableCell>
    ),
    contractNetPrice: (
      <TableCell
        key={`quote-item-cell-contractNetPrice-${item.id}`}
        align="right"
        data-testid={`contract-net-price-${item.id}`}
      >
        <Monetary
          currency={contractFieldsValues.contractNetPrice.currency}
          amount={contractFieldsValues.contractNetPrice.amount}
          sx={{ ...typographyStyle, fontWeight: 500 }}
        />
      </TableCell>
    ),
    contractSubTotalPrice: (
      <TableCell
        key={`quote-item-cell-contractSubTotalPrice-${item.id}`}
        align="right"
        data-testid={`contract-sub-total-price-${item.id}`}
      >
        <Monetary
          currency={contractFieldsValues.contractSubTotalPrice.currency}
          amount={contractFieldsValues.contractSubTotalPrice.amount}
          sx={{ ...typographyStyle, fontWeight: 500 }}
        />
      </TableCell>
    ),
  };

  return (
    <TableRow
      className="quote-item-row"
      data-testid={`table-row-${item.id}`}
      {...rest}
      onMouseEnter={showActionPanel}
      onMouseLeave={hideActionPanel}
    >
      {lineItemVisibleFields.map(field => cellsFieldComponentMap[field])}
      <Popper
        id={`${item.id}`}
        open={!!anchorElement}
        anchorEl={anchorElement}
        placement="right"
        style={{
          zIndex: 1,
        }}
      >
        <QuoteRowActionsPanel
          aria-label="close"
          disableDrag
          disabled={tableLoading}
          data-testid={`quote-item-delete-${item.id}`}
          onDelete={() => {
            if (!readonly) {
              onDeleteItem?.(item.id);
            }
          }}
        />
      </Popper>
    </TableRow>
  );
}

const typographyStyle = {
  marginTop: '9px',
};

const isQuoteProductItem = (item: OrderProductItem | QuoteProductItem): item is QuoteProductItem =>
  'noteRequired' in item;
