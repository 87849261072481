import React from 'react';
import { FormTextField, MenuItem, Skeleton } from '@clippings/paper';
import { useListPdfTemplates } from 'libs/api/iam/hooks';

interface PdfTemplateSelectProps {
  name: string;
  'data-testid': string;
  key: string;
  label: string;
}

export const PdfTemplateSelect: React.FC<PdfTemplateSelectProps> = props => {
  const pdfTemplatesQuery = useListPdfTemplates();

  if (pdfTemplatesQuery.isLoading) {
    return <Skeleton variant="text" height={64} />;
  }

  return (
    <FormTextField color="secondary" select size="small" required {...props}>
      {pdfTemplatesQuery.data?.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </FormTextField>
  );
};
