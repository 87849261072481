import React from 'react';
import classNames from 'classnames';
import { CompositeDiscountValue } from '../../../components/CompositeDiscountCell';
import { ConfiguredProduct } from '../../../common/types';
import { ContractFieldsValues } from 'libs/Quotes/QuoteTable';
import {
  ContractValueCell,
  DiscountTableCell,
  InfoTableCell,
  NetTableCell,
  OrderTableCell,
  PriceTableCell,
  QuantityTableCell,
  SubTotalTableCell,
  TaxRateTableCell,
} from '../tableCells';
import { LeadTime } from 'libs/api/common/types';
import { LeadTimeCell } from '../../../LeadTimeCell';
import { LineItemVisibleFields } from 'libs/api/configuration/types';
import { QuoteItemHiddenDetails } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { QuoteProductItem } from 'libs/api/quotes/types';
import { Readonly, RowActionsPanel, useShouldIncludePropertyCode } from 'libs/shared';
import { TableRow } from '@clippings/paper';
import { UpdateQuoteByKey } from '../../../types';
import { isEqual } from 'lodash';
import { useActionPanelVisibility } from './useActionPanelVisibility';
import '../../../../../Quotes/QuoteTable/components/QuoteItemRow/QuoteItemRow.scss';

export type ItemRowProps = {
  item: QuoteProductItem;
  totalRows: number;
  loading: boolean;
  detailsHidden: boolean;
  lineItemVisibleFields: LineItemVisibleFields;
  contractFieldsValues: ContractFieldsValues;
  onQuantityUpdate: (quantity: number, id: number) => void;
  onUpdate: UpdateQuoteByKey;
  onMakeCustom: (variationName: string, itemId: number) => void;
  onDelete: (id: number) => void;
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  onReorder: (currentOrder: QuoteProductItem, newOrder: number) => void;
  onQuoteItemUpdateDiscount: (discount: CompositeDiscountValue, itemId: number) => void;
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
  onNoteSave?: UpdateQuoteByKey;
} & Readonly;

export const ItemRow: React.FC<ItemRowProps> = ({
  item,
  totalRows,
  loading,
  readonly = false,
  detailsHidden,
  lineItemVisibleFields,
  contractFieldsValues,
  onQuantityUpdate,
  onMakeCustom,
  onReorder,
  onUpdate,
  onDelete,
  onConfigure,
  onQuoteItemUpdateDiscount,
  onProductDetailsHide,
  onNoteSave,
}) => {
  const { anchorElement, hideActionPanel, showActionPanel } = useActionPanelVisibility(readonly);
  const { shouldIncludePropertyCode } = useShouldIncludePropertyCode();

  const handleQuoteItemDelete = () => {
    onDelete(item.id);
  };

  const handleUpdate: UpdateQuoteByKey = (key, value, itemId) => {
    if (isEqual(item[key], value)) {
      return;
    }

    onUpdate(key, value, itemId);
  };

  const handleLeadTimeChange = (valueArray: LeadTime) => {
    handleUpdate('leadTime', valueArray, item.id);
  };

  const cellsFieldComponentMap: Record<LineItemVisibleFields[number], JSX.Element> = {
    order: (
      <OrderTableCell
        key={`quote-item-cell-order-${item.id}`}
        item={item}
        readonly={readonly}
        loading={loading}
        totalRows={totalRows}
        onReorder={onReorder}
      />
    ),
    info: (
      <InfoTableCell
        key={`quote-item-cell-info-${item.id}`}
        item={item}
        readonly={readonly}
        loading={loading}
        detailsHidden={detailsHidden}
        shouldIncludePropertyCode={shouldIncludePropertyCode}
        handleUpdate={handleUpdate}
        onConfigure={onConfigure}
        onMakeCustom={onMakeCustom}
        onProductDetailsHide={onProductDetailsHide}
        onUpdate={onUpdate}
        onNoteSave={onNoteSave}
      />
    ),
    leadTime: (
      <LeadTimeCell
        key={`quote-item-cell-leadTime-${item.id}`}
        leadTime={item.leadTime}
        onBlur={handleLeadTimeChange}
        disabled={readonly}
        className={classNames('quote-item-row__cell', 'quote-item-row__cell__lead-time')}
      />
    ),
    price: (
      <PriceTableCell
        key={`quote-item-cell-price-${item.id}`}
        item={item}
        readonly={readonly}
        handleUpdate={handleUpdate}
      />
    ),
    discount: (
      <DiscountTableCell
        key={`quote-item-cell-discount-${item.id}`}
        item={item}
        readonly={readonly}
        onQuoteItemUpdateDiscount={onQuoteItemUpdateDiscount}
      />
    ),
    net: <NetTableCell key={`quote-item-cell-netPrice-${item.id}`} item={item} />,
    quantity: (
      <QuantityTableCell
        key={`quote-item-cell-quantity-${item.id}`}
        item={item}
        readonly={readonly}
        loading={loading}
        onQuantityUpdate={onQuantityUpdate}
      />
    ),
    taxRate: (
      <TaxRateTableCell
        key={`quote-item-cell-taxRate-${item.id}`}
        item={item}
        readonly={readonly}
        handleUpdate={handleUpdate}
      />
    ),
    subTotal: <SubTotalTableCell key={`quote-item-cell-subTotal-${item.id}`} item={item} />,
    contractNetPrice: (
      <ContractValueCell
        key={`quote-item-cell-contractNetPrice-${item.id}`}
        data-testid={`quote-item-cell-contractNetPrice-${item.id}`}
        value={contractFieldsValues.contractNetPrice}
      />
    ),
    contractListPrice: (
      <ContractValueCell
        key={`quote-item-cell-contractListPrice-${item.id}`}
        data-testid={`quote-item-cell-contractListPrice-${item.id}`}
        value={contractFieldsValues.contractListPrice}
      />
    ),
    contractSubTotalPrice: (
      <ContractValueCell
        key={`quote-item-cell-contractSubTotalPrice-${item.id}`}
        data-testid={`quote-item-cell-contractSubTotalPrice-${item.id}`}
        value={contractFieldsValues.contractSubTotalPrice}
      />
    ),
  };

  return (
    <TableRow
      data-testid={`quote-item-${item.id}`}
      className="item-row"
      key={item.id}
      onMouseEnter={showActionPanel}
      onMouseLeave={hideActionPanel}
      sx={tableRowSx}
    >
      {lineItemVisibleFields.map(field => cellsFieldComponentMap[field])}
      {!readonly ? (
        <RowActionsPanel anchorElement={anchorElement} onDelete={handleQuoteItemDelete} />
      ) : null}
    </TableRow>
  );
};

const tableRowSx = {
  verticalAlign: 'top',
};
