import React, { ComponentType, ReactNode } from 'react';
import { Box, Typography } from '@clippings/paper';
import { Readonly } from 'libs/shared';

type WithReadonlyLabelOwnProps = Required<Readonly> & {
  children?: ReactNode;
  textAlign?: 'left' | 'right';
};

type WithReadonlyLabelComponent = {
  labelComponent: JSX.Element;
  readonlyValue?: never;
} & WithReadonlyLabelOwnProps;

type WithReadonlyValue = {
  readonlyValue: string;
  labelComponent?: never;
} & WithReadonlyLabelOwnProps;

export function withReadonlyLabel<T>(Component: ComponentType<T>) {
  return function ComponentWithReadonly({
    readonly = false,
    readonlyValue,
    textAlign = 'right',
    labelComponent,
    children,
    ...rest
  }: T & (WithReadonlyValue | WithReadonlyLabelComponent)) {
    if (readonly) {
      return labelComponent ? (
        labelComponent
      ) : (
        <Box textAlign={textAlign} data-testid="readonly-label-container">
          <ReadOnlyLabelTypography value={readonlyValue} />
        </Box>
      );
    }

    return <Component {...(rest as any)}>{children}</Component>;
  };
}

interface ReadOnlyLabelTypographyProps {
  value: string | undefined;
}

export function ReadOnlyLabelTypography({ value }: ReadOnlyLabelTypographyProps): JSX.Element {
  return (
    <Typography
      variant="caption"
      sx={{ fontSize: 'inherit' }}
      data-testid={`readonly-label-${value}`}
    >
      {value}
    </Typography>
  );
}
