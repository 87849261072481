import React, { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { ProductOrder } from 'libs/SalesApp/Quotes/common/types';
import { SearchClient } from 'algoliasearch';
import { isEmpty } from 'lodash';

type SearchProviderContextType = {
  searchClient: SearchClient;
  productIndexes: Record<string, string>;
  discountIndex?: string;
  customItemsIndex?: string;
};

const SearchProviderContext = createContext<SearchProviderContextType>(
  {} as SearchProviderContextType
);

type SearchProviderProps = {
  searchClient: SearchClient;
} & PropsWithChildren;

export const indexes = {
  products: 'products',
  products_price_asc: 'products_price_asc',
  products_price_desc: 'products_price_desc',
  discounts: 'discounts',
  custom_items: 'custom_items',
};

export function SearchProvider({ searchClient, children }: SearchProviderProps) {
  const value = useMemo(() => {
    const productIndexesByOrder: Record<ProductOrder, string> = {
      mostRelevant: indexes.products,
      highestPrice: indexes.products_price_desc,
      lowestPrice: indexes.products_price_asc,
    };

    return {
      searchClient,
      productIndexes: productIndexesByOrder,
      customItemsIndex: indexes.custom_items,
      discountIndex: indexes.discounts,
    };
  }, []);

  return <SearchProviderContext.Provider value={value}>{children}</SearchProviderContext.Provider>;
}

export function useSearchProvider() {
  const value = useContext(SearchProviderContext);

  if (isEmpty(value)) {
    throw new Error('useSearchProvider must be used within a SearchProvider');
  }

  return value;
}
