import { Quote, QuoteProductItem } from 'libs/api/quotes/types';
import { QuoteAddressProps } from 'libs/Quotes/hooks/useAddresses';
import { axios, path } from 'libs/Utils';

export const apiPaths = {
  index: '/api/quote/v1/quotes/:number',
  create: '/api/quote/v1/quotes',
  version: '/api/quote/v1/versions/:id',
  nonContractual: '/api/quote/v1/versions/:id/non-contractual',
  items: '/api/quote/v1/versions/:id/items/:itemId',
  archive: '/api/quote/v1/versions/:id/archive',
  unarchive: '/api/quote/v1/versions/:id/unarchive',
  requestQuoteApproval: '/api/quote/v1/versions/:id/request',
  restoreQuoteVersion: '/api/quote/v1/versions/:id/restore',
  duplicate: '/api/quote/v1/versions/:id/duplicate',
  getPaymentTerms: '/api/quote/v1/versions/:id/payment-terms',
  quoteShippingAddress: '/api/quote/v1/versions/:id/shipping-address',
  quoteExWorksAddress: '/api/quote/v1/versions/:id/ex-works-address',
  quoteBillingAddress: '/api/quote/v1/versions/:id/billing-address',
  companyBillingAddresses: '/api/iam/v1/companies/:id/billing-addresses',
  companyShippingAddresses: '/api/iam/v1/companies/:id/shipping-addresses',
  editCompanyShippingAddress: '/api/iam/v1/companies/:id/shipping-addresses/:addressId',
  editCompanyBillingAddress: '/api/iam/v1/companies/:id/billing-addresses/:addressId',
  evaluateDeliveryMethods: '/api/delivery/v1/methods/evaluate',
  evaluateDeliveryMethodsForQuote: '/api/delivery/v1/methods/:id/evaluate',
  createItem: '/api/quote/v1/versions/:id/items',
  createFromXml: '/api/quote/v1/quotes/xml',
};

export const queryKeys = {
  getPaymentTerms: (quoteVersionId: number) => ['paymentTerms', quoteVersionId],
  getBillingAddress: (quoteVersionId: number, companyId: number) => [
    'billingAddress',
    quoteVersionId,
    companyId,
  ],
  getQuoteBillingAddresses: (quoteVersionId: number) => ['quoteBillingAddress', quoteVersionId],
  getShippingAddress: (quoteVersionId: number, companyId: number) => [
    'shippingAddress',
    quoteVersionId,
    companyId,
  ],
  getQuoteShippingAddresses: (quoteVersionId: number) => ['quoteShippingAddress', quoteVersionId],
  getQuoteDeliveryMethods: (quote: QuoteAddressProps) => [
    'deliveryMethods',
    quote.versionId,
    quote.shippingAddress,
    quote.quoteItemProducts,
  ],
};

export const getQuote = (number: string, params: { version: number }) =>
  axios.get(path(apiPaths.index, { number }), { params }).then(response => response.data);

export const updateQuoteVersion = (id: number, params: Partial<Quote>) => {
  return axios.patch(path(apiPaths.version, { id }), params).then(response => response.data);
};

export const updateQuoteVersionNonContractual = (id: number, params: Partial<Quote>) => {
  return axios.patch(path(apiPaths.nonContractual, { id }), params).then(response => response.data);
};

export const updateQuoteVersionItem = (
  id: number,
  itemId: number,
  params: Partial<QuoteProductItem>
) => axios.patch(path(apiPaths.items, { id, itemId }), params).then(response => response.data);

export const getQuoteVersion = (id: number) =>
  axios.get<Quote>(path(apiPaths.version, { id })).then(response => response.data);

export const deleteQuoteItem = (quoteVersion: number, quoteItemId: number) =>
  axios
    .delete(path(apiPaths.items, { id: quoteVersion, itemId: quoteItemId }))
    .then(response => response.data);

export const restoreQuoteVersion = (quoteVersion: number) =>
  axios
    .post(path(apiPaths.restoreQuoteVersion, { id: quoteVersion }))
    .then(response => response.data);

export const requestQuoteApproval = (quoteVersion: number) =>
  axios
    .post(path(apiPaths.requestQuoteApproval, { id: quoteVersion }))
    .then(response => response.data);

export const archiveQuote = (quoteVersion: number) =>
  axios.post(path(apiPaths.archive, { id: quoteVersion })).then(response => response.data);
/**
 * @param {number} quoteVersion
 */
export const unarchiveQuote = (quoteVersion: number) =>
  axios.post(path(apiPaths.unarchive, { id: quoteVersion })).then(response => response.data);

export const deleteQuoteVersion = (quoteVersion: number) =>
  axios.delete(path(apiPaths.version, { id: quoteVersion })).then(
    response => response.data,
    e => Promise.reject(e)
  );

export const duplicateQuote = (quoteVersion: number) =>
  axios.post(path(apiPaths.duplicate, { id: quoteVersion })).then(response => response.data);

export const getPaymentTerms = (quoteVersion: number) =>
  axios.get(path(apiPaths.getPaymentTerms, { id: quoteVersion })).then(x => x.data);

// Address actions
export const getQuoteBillingAddresses = (id: number) =>
  axios.get(path(apiPaths.quoteBillingAddress, { id })).then(response => response.data);

export const getQuoteShippingAddresses = (id: number) =>
  axios.get(path(apiPaths.quoteShippingAddress, { id })).then(response => response.data);

export const createQuoteShippingAddress = (id: number, data: any) =>
  axios.post(path(apiPaths.quoteShippingAddress, { id }), data).then(response => response.data);

export const createQuoteExWorksAddress = (id: number, data: any) =>
  axios.post(path(apiPaths.quoteExWorksAddress, { id }), data).then(response => response.data);

export const updateQuoteShippingAddress = (id: number, data: any) =>
  axios.put(path(apiPaths.quoteShippingAddress, { id }), data).then(response => response.data);

export const getCompanyBillingAddresses = (id: number) =>
  axios.get(path(apiPaths.companyBillingAddresses, { id })).then(response => response.data);

export const getCompanyShippingAddresses = (id: number) =>
  axios.get(path(apiPaths.companyShippingAddresses, { id })).then(response => response.data);

export const createCompanyShippingAddress = (id: number, data: any) =>
  axios.post(path(apiPaths.companyShippingAddresses, { id }), data).then(response => response.data);

export const createCompanyBillingAddress = (id: number, data: any) =>
  axios.post(path(apiPaths.companyBillingAddresses, { id }), data).then(response => response.data);

export const updateCompanyShippingAddress = (id: number, data: any) =>
  axios
    .put(path(apiPaths.editCompanyShippingAddress, { id, addressId: data.id }), data)
    .then(response => response.data);

export const deleteCompanyShippingAddress = (id: number, data: any) =>
  axios
    .delete(path(apiPaths.editCompanyShippingAddress, { id, addressId: data.id }), data)
    .then(response => response.data);

export const deleteQuoteShippingAddress = (id: number) =>
  axios.delete(path(apiPaths.quoteShippingAddress, { id })).then(response => response.data);

export const deleteCompanyBillingAddress = (id: number, data: any) =>
  axios
    .delete(path(apiPaths.editCompanyBillingAddress, { id, addressId: data.id }), data)
    .then(response => response.data);

export const updateCompanyBillingAddress = (id: number, data: any) =>
  axios
    .put(path(apiPaths.editCompanyBillingAddress, { id, addressId: data.id }), data)
    .then(response => response.data);

export const evaluateDeliveryMethods = (data: {
  catalogueRef: string;
  deliveryAddress: Partial<{
    country: string;
    state: string;
    county: string;
    city: string;
    zipCode: string;
  }>;
}) => axios.post(apiPaths.evaluateDeliveryMethods, data).then(response => response.data);

export const evaluateDeliveryMethodsForQuote = (data: {
  catalogueRef: string;
  quoteVersionId: number;
  deliveryAddress: Partial<{
    country: string;
    state: string;
    county: string;
    city: string;
    zipCode: string;
  }>;
}) =>
  axios
    .post(path(apiPaths.evaluateDeliveryMethodsForQuote, { id: data.quoteVersionId }), data)
    .then(response => response.data);
