import { fallbackCoverUrl } from 'libs/shared';
import { getSmallProductImageUrl } from '@clippings/paper';

export const getProductListingImageUrl = (
  coverRemoteId: string | null,
  coverVersion: number | null
) =>
  coverRemoteId && coverVersion
    ? getSmallProductImageUrl({
        remoteId: coverRemoteId,
        version: coverVersion,
      })
    : fallbackCoverUrl;
