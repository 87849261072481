import React from 'react';
import classNames from 'classnames';
import { Monetary, TableCell } from '@clippings/paper';
import { TableCellCommonProps } from './tableCells.utils';

type NetTableCellProps = Pick<TableCellCommonProps, 'item'>;

export function NetTableCell({ item }: NetTableCellProps) {
  return (
    <TableCell
      data-testid={`quote-item-netPrice-${item.id}`}
      align="right"
      className={classNames('quote-item-row__cell', 'quote-item-row__cell__net-price')}
    >
      <Monetary
        currency={item.netPrice.currency}
        amount={item.netPrice.amount}
        sx={monetarySx}
        color="text.secondary"
      />
    </TableCell>
  );
}

const monetarySx = {
  marginTop: '9px',
  fontSize: 'inherit',
};
