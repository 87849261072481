import React, { FC } from 'react';
import { Box, Button, Typography, useTheme } from '@clippings/paper';
import { Link } from 'react-router-dom';
import {
  QUOTE_STATUS_TRANSLATION_MAP,
  getQuoteStatusBackgroundColor,
  getQuoteSystemMessageColor,
  useGetSystemMessageDateTime,
} from 'libs/Chat/utils/chatUtils';
import { QuoteMetaData } from 'libs/Chat/types';
import { QuoteSystemStatusChangeEvent } from '../types';
import { useOrderPaths } from 'libs/shared/hooks/useOrderPaths';
import { useTranslation } from 'react-i18next';

export interface ChatQuoteStatusChangeMessageProps {
  username?: string;
  createdAt?: Date;
  metaData: QuoteMetaData<QuoteSystemStatusChangeEvent>;
  messageId: string;
}

export const ChatQuoteStatusChangeMessage: FC<ChatQuoteStatusChangeMessageProps> = ({
  createdAt,
  username,
  metaData,
  messageId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getSystemMessageDateTime } = useGetSystemMessageDateTime();
  const { type, versionNumber, number } = metaData;
  const isOrdered = type === QuoteSystemStatusChangeEvent.Ordered;
  const { getOrderShowPath } = useOrderPaths();

  const getMessageTitle = () => {
    if (isOrdered) {
      return t('common.placedOrderNumber', { number });
    }

    const typeTranslation = t(QUOTE_STATUS_TRANSLATION_MAP[type]).toLowerCase();

    return `${t('common.quote')} (${t('quotes.version')} ${versionNumber}) ${typeTranslation}`;
  };

  const textColor = getQuoteSystemMessageColor(theme, type);
  const backgroundColor = getQuoteStatusBackgroundColor(theme, type);

  return (
    <Box
      data-testid={`chat-quote-system-message-${messageId}`}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingX={1}
      paddingY={2}
      marginBottom={3}
      borderRadius={1}
      sx={{ backgroundColor }}
    >
      <Typography color={textColor} variant="subtitle2" fontWeight="fontWeightMedium">
        {createdAt && `${getSystemMessageDateTime(createdAt)} - `}
        {getMessageTitle()}
      </Typography>
      {username && (
        <Typography color={textColor} variant="caption">
          {t('common.byUser', {
            username,
          })}
        </Typography>
      )}
      {isOrdered && (
        <Button
          data-testid={`btn-order-quote-system-message-${messageId}`}
          component={Link}
          to={getOrderShowPath(number ?? '')}
          sx={{ marginTop: 1 }}
          color="success"
          variant="contained"
        >
          {t('common.trackOrder')}
        </Button>
      )}
    </Box>
  );
};
