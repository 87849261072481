import React from 'react';
import { CreateUserCompanyProvider } from '../../providers/CreateUserCompanyProvider';
import { DialogAction, SystemUser } from '@clippings/paper';
import { UserCompanyDialog, UserCompanyDialogProps } from './UserCompanyDialog';
import { UserFormFieldsProps } from '../../components/userDialog/UserFormFields';
import { useCreateUserCompanyUtils } from '../../hooks/useCreateUserCompanyUtils';

export type CreateCustomerUserDialogProps = Omit<UserCompanyDialogProps, 'type' | 'action'> &
  Pick<UserFormFieldsProps, 'hiddenFields' | 'disabledFields'>;

export const CreateCustomerUserDialog: React.FC<CreateCustomerUserDialogProps> = ({ ...rest }) => {
  const userCompanyUtils = useCreateUserCompanyUtils();

  return (
    <CreateUserCompanyProvider {...userCompanyUtils}>
      <UserCompanyDialog
        {...rest}
        defaultValues={{
          ...userCompanyUtils.userFormValues,
          ...rest.defaultValues,
        }}
        type={SystemUser.Customer}
        action={DialogAction.Create}
      />
    </CreateUserCompanyProvider>
  );
};
