import React, { ComponentProps, FC } from 'react';
import {
  AccountBalanceIcon,
  Box,
  CheckIcon,
  DriveFileRenameOutlineIcon,
  ErrorOutlineIcon,
  IconProps,
} from '@clippings/paper';
import { CheckoutIconBase } from './CheckoutIconBase';

// Types
export type CheckoutIconTypes =
  | 'success'
  | 'error'
  | 'pending-balance'
  | 'pending-check'
  | 'signature';
export type CheckoutIconProps = ComponentProps<typeof Box> & {
  type: CheckoutIconTypes;
};

export const CHECKOUT_ICON_MAP: Record<CheckoutIconTypes, React.ReactElement<FC<IconProps>>> = {
  success: <CheckIcon />,
  error: <ErrorOutlineIcon />,
  'pending-balance': <AccountBalanceIcon />,
  'pending-check': <CheckIcon />,
  signature: <DriveFileRenameOutlineIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
};

// Components
export const CheckoutIcon: FC<CheckoutIconProps> = ({ type, ...rest }) => (
  <CheckoutIconBase className={type} {...rest}>
    {CHECKOUT_ICON_MAP[type]}
  </CheckoutIconBase>
);

export const CheckoutPendingIconSet: FC<ComponentProps<typeof Box>> = props => (
  <Box display="flex" {...props}>
    <CheckoutIcon
      sx={{
        zIndex: 5,
        marginRight: '-15px',
      }}
      type="pending-check"
    />
    <CheckoutIcon type="pending-balance" />
  </Box>
);
