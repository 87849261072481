import React, { Fragment, useMemo } from 'react';
import { AppConfiguration } from 'libs/api/configuration/types';
import { Card } from '../Card';
import { Column } from '../Column';
import { DividerStyled, WithDivider } from './WithDivider';
import {
  FlexColumn,
  Typography,
  convertTimeRange,
  useFormatLeadTime,
  useFormatPercentage,
  useFormatPrice,
} from '@clippings/paper';
import { LabelContainer, RowItemValue } from './RowItemValue';
import { Note } from 'libs/notes';
import { ProductImage } from '../ProductImage';
import { QuoteItemInfoRenderer } from './QuoteItemInfoRenderer';
import { QuoteItemViewModel } from './quoteItemCard.types';
import { Row } from '../Row';
import { fallbackCoverUrl } from '../../constants';
import { useAppConfiguration } from 'libs/providers';
import { useTranslation } from 'react-i18next';

export type QuoteItemCardProps = {
  item: QuoteItemViewModel;
  lineItemVisibleFields: AppConfiguration['lineItemVisibleFields'];
  editableFields?: Partial<Record<Columns, (item: QuoteItemViewModel) => JSX.Element>>;
  actions?: (item: QuoteItemViewModel) => JSX.Element;
};

type Columns = AppConfiguration['lineItemVisibleFields'][number];

export function QuoteItemCard({
  item,
  lineItemVisibleFields,
  editableFields = {},
  actions,
}: QuoteItemCardProps) {
  const { t } = useTranslation();
  const { leadTimeFormat } = useAppConfiguration();
  const formatLeadTime = useFormatLeadTime();
  const formatPercentage = useFormatPercentage();
  const formatPrice = useFormatPrice();

  const columnNameWithDivider = getColumnWithDivider(lineItemVisibleFields);

  const itemValuesMap: Record<Columns, JSX.Element | null> = useMemo(
    () => ({
      order: null,
      info: null,
      leadTime: editableFields.leadTime ? (
        <LabelContainer label={t('quotes.table.leadTime')}>
          {editableFields.leadTime(item)}
        </LabelContainer>
      ) : (
        <RowItemValue
          label={t('quotes.table.leadTime')}
          value={formatLeadTime(
            convertTimeRange(item.leadTime, 'days', leadTimeFormat),
            leadTimeFormat
          )}
        />
      ),
      price: editableFields.price ? (
        <LabelContainer label={t('quotes.table.price')}>
          {editableFields.price(item)}
        </LabelContainer>
      ) : (
        <RowItemValue
          label={t('quotes.table.price')}
          value={formatPrice(Number(item.price.amount), item.price.currency)}
        />
      ),
      discount: editableFields.discount ? (
        <LabelContainer label={t('quotes.table.discount')}>
          {editableFields.discount(item)}
        </LabelContainer>
      ) : (
        <RowItemValue label={t('quotes.table.discount')} value={formatPercentage(item.discount)} />
      ),
      net: editableFields.net ? (
        <LabelContainer label={t('quotes.table.net')}>{editableFields.net(item)}</LabelContainer>
      ) : (
        <RowItemValue
          label={t('quotes.table.net')}
          value={formatPrice(Number(item.net.amount), item.net.currency)}
        />
      ),
      quantity: editableFields.quantity ? (
        <LabelContainer label={t('quotes.table.quantity')}>
          {editableFields.quantity(item)}
        </LabelContainer>
      ) : (
        <>
          <WithDivider columnName="quantity" columnWithDivider={columnNameWithDivider} />
          <RowItemValue label={t('quotes.table.quantity')} value={item.quantity} />
        </>
      ),
      taxRate: editableFields.taxRate ? (
        <LabelContainer label={t('quotes.table.taxRate')}>
          {editableFields.taxRate(item)}
        </LabelContainer>
      ) : (
        <>
          <WithDivider columnName="taxRate" columnWithDivider={columnNameWithDivider} />
          <RowItemValue label={t('quotes.table.taxRate')} value={formatPercentage(item.taxRate)} />
        </>
      ),
      subTotal: editableFields.subTotal ? (
        <LabelContainer label={t('quotes.table.subTotal')}>
          {editableFields.subTotal(item)}
        </LabelContainer>
      ) : (
        <>
          <WithDivider columnName="subTotal" columnWithDivider={columnNameWithDivider} />
          <RowItemValue
            label={t('quotes.table.subTotal')}
            value={formatPrice(Number(item.subtotal.amount), item.subtotal.currency)}
            valueWeight="fontWeightMedium"
          />
        </>
      ),
      contractNetPrice: (
        <>
          <WithDivider columnName="contractNetPrice" columnWithDivider={columnNameWithDivider} />
          <RowItemValue
            label={t('quotes.table.contractNetPrice')}
            value={formatPrice(item.contractNetPrice.amount, item.contractNetPrice.currency)}
            valueWeight="fontWeightMedium"
          />
        </>
      ),
      contractListPrice: (
        <>
          <WithDivider columnName="contractListPrice" columnWithDivider={columnNameWithDivider} />
          <RowItemValue
            label={t('quotes.table.contractListPrice')}
            value={formatPrice(item.contractListPrice.amount, item.contractListPrice.currency)}
            valueWeight="fontWeightMedium"
          />
        </>
      ),
      contractSubTotalPrice: (
        <>
          <WithDivider
            columnName="contractSubTotalPrice"
            columnWithDivider={columnNameWithDivider}
          />
          <RowItemValue
            label={t('quotes.table.contractSubTotalPrice')}
            value={formatPrice(
              item.contractSubTotalPrice.amount,
              item.contractSubTotalPrice.currency
            )}
            valueWeight="fontWeightMedium"
          />
        </>
      ),
    }),
    [item, editableFields]
  );

  const hasInfoColumn = !!lineItemVisibleFields.find(field => field === 'info');

  return (
    <Card>
      {hasInfoColumn ? (
        <>
          <Row gap={2}>
            <Typography variant="body2" color={theme => theme.palette.grey[700]} padding="12px 0">
              {item.position + 1}
            </Typography>
            <ProductImage
              picture={item.picture}
              fallback={fallbackCoverUrl}
              alt={item.productName}
            />
            {!item.sample ? (
              <Column>
                <Typography
                  variant="body1"
                  fontSize={theme => theme.typography.pxToRem(14)}
                  fontWeight="fontWeightMedium"
                >
                  {item.productName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontSize={theme => theme.typography.pxToRem(12)}
                  color="grey.400"
                >
                  {item.sku}
                </Typography>
              </Column>
            ) : null}
          </Row>
          <FlexColumn>
            <QuoteItemInfoRenderer item={item} />
            {item.note ? (
              <Note value={item.note} withDivider={item.custom || item.sample} readonly />
            ) : null}
          </FlexColumn>
        </>
      ) : null}
      <DividerStyled />
      <Column gap={1}>
        {lineItemVisibleFields.map(field => (
          <Fragment key={field}>{itemValuesMap[field]}</Fragment>
        ))}
        <Row gap={1}>{actions?.(item)}</Row>
      </Column>
    </Card>
  );
}

////

function getColumnWithDivider(columns: AppConfiguration['lineItemVisibleFields']) {
  if (columns.includes('quantity')) {
    return 'quantity';
  }

  if (columns.includes('taxRate')) {
    return 'taxRate';
  }

  if (columns.includes('subTotal')) {
    return 'subTotal';
  }

  return null;
}
