import React from 'react';
import { Box, InfoOutlinedIcon, SellIcon, Tooltip, Typography } from '@clippings/paper';
import { useGetCatalogue } from 'libs/api/catalogue/hooks';
import { useTranslation } from 'react-i18next';

export interface CatalogInfoProps {
  catalogueSlug: string;
}

export const CatalogInfo: React.FC<CatalogInfoProps> = ({ catalogueSlug }) => {
  const { t } = useTranslation();
  const { data: catalogue } = useGetCatalogue(catalogueSlug);

  return (
    <Box
      height={64}
      p={3}
      borderBottom={theme => `1px solid ${theme.palette.grey.A200}`}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {catalogue && (
        <>
          <Box display="flex" alignItems="center">
            <SellIcon color="action" />
            <Typography variant="body2" color="secondary" px={1}>
              {t('common.showing')}:
            </Typography>
            <Typography variant="subtitle2" color={theme => theme.palette.info.dark}>
              {catalogue.name}
            </Typography>
          </Box>
          <Tooltip title={t('plp.catalogueInfoTooltip')!} placement="top">
            <InfoOutlinedIcon color="disabled" />
          </Tooltip>
        </>
      )}
    </Box>
  );
};
