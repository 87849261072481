import { DateTimeFormat, getFormatDateTime, useFormatPercentage } from '@clippings/paper';
import { Payment, PaymentStatus } from 'libs/api/common/types';
import { TranslationKey } from 'libs/types/react-i18next';
import { useTranslation } from 'react-i18next';

export const useFormatType = () => {
  const { t } = useTranslation();

  const formatType = (paymentTerm: Payment): string => {
    const { paymentRule, status } = paymentTerm;
    const isPaid = status === PaymentStatus.Paid;

    if (isPaid) {
      return t('common.paid');
    }

    switch (paymentRule.type) {
      case 'custom':
        return t('paymentTerms.custom');
      case 'date':
        return t('paymentTerms.onDate');
      case 'event': {
        const { dueEvent } = paymentRule;
        return t(`paymentTerms.eventPaymentRuleDue.${dueEvent}`, dueEvent);
      }
      default:
        return '';
    }
  };

  return { formatType };
};

export const useFormatDetails = () => {
  const { t, i18n } = useTranslation();

  const formatDetails = (
    paymentTerm: Payment,
    immediateText: TranslationKey = 'paymentTerms.immediately'
  ): string => {
    const formatDateTime = getFormatDateTime(i18n.language);
    const { paymentRule, status, paidOn } = paymentTerm;
    const isPaid = status === PaymentStatus.Paid;

    if (isPaid) {
      return paidOn ? formatDateTime(new Date(paidOn), DateTimeFormat.Date) : '';
    }

    switch (paymentRule.type) {
      case 'custom':
        return paymentRule.dueReason;
      case 'date':
        return formatDateTime(new Date(paymentRule.dueDate), DateTimeFormat.Date);
      case 'event': {
        if (paymentTerm.isImmediate) {
          return t(immediateText);
        }

        return t('paymentTerms.netDays', {
          days: paymentRule.dueDays,
        });
      }
      default:
        return '';
    }
  };

  return { formatDetails };
};

export const useBuildPercentageLabel = () => {
  const formatPercentage = useFormatPercentage();
  const { t } = useTranslation();

  const buildPercentageLabel = (payment: Payment, paymentNumber: number): string => {
    const {
      isImmediate,
      paymentRule: { percentage },
    } = payment;
    const paymentPercentage = formatPercentage(percentage!);

    if (isImmediate) {
      return `${t('common.deposit')} (${paymentPercentage})`;
    }

    return `${t('common.payment')} ${paymentNumber} (${paymentPercentage})`;
  };

  return buildPercentageLabel;
};
