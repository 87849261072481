import React, { useCallback } from 'react';
import { AddNewLineInput } from './AddNewLineInput';
import { AddQuoteItemProduct, AddSampleToQuotePayload, Quote } from 'libs/api/quotes/types';
import {
  Box,
  CellPopover,
  ManageSearchIcon,
  MenuItem,
  Skeleton,
  TableCell,
  Typography,
} from '@clippings/paper';
import { ConfiguredProduct } from '../common/types';
import {
  PLP_SEARCH_QUERY_PARAM,
  usePlpSearchQueryParam,
} from '../productListing/hooks/usePlpSearchQueryParam';
import { ProductHit, ProductType } from 'libs/Quotes/quote.types';
import { ProductsAutocompletePopoverContent } from './ProductsAutocompletePopoverContent';
import { addProductToQuote } from 'libs/api/quotes/actions';
import { getCleanQueryParamValue } from 'libs/shared';
import { queryKeys } from 'libs/Quotes/quoteActions';
import { useAddItemSearchTerm, useQuoteProvider } from 'libs/Quotes/providers';
import { useAddSample } from 'libs/Quotes/samples';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuoteQueryKey } from '../../../Quotes/hooks';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import { useTranslation } from 'react-i18next';

interface AddProductCellProps {
  onConfigure: (configuredProduct: ConfiguredProduct) => void;
  onOpenPLP: () => void;
  canAddCustom?: boolean;
  isLoading?: boolean;
  catalogue: string;
}

export const AddProductCell: React.FC<AddProductCellProps> = ({
  onConfigure,
  onOpenPLP,
  canAddCustom = true,
  isLoading = false,
  catalogue,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const { refine } = useSearchBox();
  const { quote } = useQuoteProvider();
  const { value: inputValue, setValue: setInputValue } = useAddItemSearchTerm();
  const { t } = useTranslation();
  const quoteQueryKey = useQuoteQueryKey(quote.number);
  const createItemMutation = useMutation((payload: AddQuoteItemProduct) =>
    addProductToQuote(quote.versionId, payload)
  );
  const { createSampleHandler } = useAddSample(quote.versionId);
  const [, setPlpQueryParam] = usePlpSearchQueryParam();

  const onAddLineInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setInputValue(value);
    setPlpQueryParam(getCleanQueryParamValue(value));
    refine(value ?? '');
  };

  const handleAddItem = (data: ProductHit) => {
    if (data.type === ProductType.Sample) {
      handleCreateSample({ id: Number(data.objectID) });
      return;
    }

    handleCreateItem({
      sku: data.skus[quote.catalogue],
      productId: data.objectID,
      quantity: 1,
      catalogue: quote.catalogue,
      custom: false,
    });
  };

  const handleCreateItem = (data: AddQuoteItemProduct) => {
    createItemMutation.mutate(data, {
      onSuccess: handleSuccess,
    });
  };

  const handleCreateSample = (data: AddSampleToQuotePayload) => {
    createSampleHandler(data, {
      onSuccess: handleSuccess,
    });
  };

  const handleSuccess = (result: Quote) => {
    queryClient.setQueryData(quoteQueryKey, result);
    queryClient.invalidateQueries(queryKeys.getPaymentTerms(result.versionId));

    refine('');
    setInputValue('');
    setPlpQueryParam(null);
  };

  const addCustomProduct = (value: string) => {
    if (!canAddCustom) {
      return;
    }

    handleCreateItem({
      quantity: 1,
      variationName: value,
      custom: true,
      catalogue: quote.catalogue,
    });
  };

  const transKey = canAddCustom ? 'quotes.searchInfoLabel' : 'quotes.searchInfoLabelNoCustom';

  // customItemsIndex (dev_sossego_custom_items) currently available only for Sossego
  // custom_items index items do not appear in configurator search results
  const renderPopoverContent = useCallback(
    ({ onClose }: { onClose: () => void }) => (
      <Box width="100%">
        <ProductsAutocompletePopoverContent
          onClose={onClose}
          quote={quote}
          onAddItem={handleAddItem}
          onConfigure={onConfigure}
          onAddCustomItem={addCustomProduct}
          catalogue={catalogue}
        />
        <MenuItem
          sx={{
            display: 'flex',
            gap: '4px',
          }}
          onClick={() => {
            // Workaround using input ref, `window` and `URLSearchParams` due to performance issues with Algolia's `Index` causing flickering on search
            const currentValue = inputRef.current?.value;
            const plpQParam = new URLSearchParams(window.location.search).get(
              PLP_SEARCH_QUERY_PARAM
            );

            if (plpQParam !== currentValue) {
              setPlpQueryParam(getCleanQueryParamValue(currentValue));
            }

            onOpenPLP();
            onClose();
          }}
          data-testid="see-more"
        >
          <ManageSearchIcon sx={{ width: 48 }} />
          <Typography variant="subtitle2">{t('common.seeMore')}</Typography>
        </MenuItem>
      </Box>
    ),
    [inputRef.current, catalogue]
  );

  return (
    <CellPopover infoLabel={t(transKey)} PopoverContent={renderPopoverContent}>
      <TableCell>
        {isLoading || createItemMutation.isLoading ? (
          <Skeleton height="40px" variant="text" />
        ) : (
          <AddNewLineInput
            value={inputValue}
            placeholder={t('quotes.addProductPlaceholder')}
            disabled={createItemMutation.isLoading}
            onCreate={addCustomProduct}
            onChange={onAddLineInputChange}
            inputProps={{
              'data-testid': 'add-product-input',
              sx: {
                marginTop: '9px',
              },
              ref: inputRef,
            }}
          />
        )}
      </TableCell>
    </CellPopover>
  );
};
